import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export default function EditForeignIdsObserver({ values, setFormValues }) {
  const [debouncedSetFormValues] = useDebouncedCallback((values) => {
    setFormValues(values);
  }, 100);

  useEffect(() => {
    debouncedSetFormValues(values);
  }, [debouncedSetFormValues, values]);

  return null;
}
