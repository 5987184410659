import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';
import { parse } from 'query-string';
import { useLazyQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';

import usePrevious from 'common/hooks/usePrevious';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useAuthContext } from '~/context/AuthContext';
import { anonymousUserApolloClient } from '~/apolloClient';
import {
  FORM_MESSAGE_STATE,
  FORM_PASSWORD_ONECLICK_STATE,
  FORM_LOADING_STATE,
} from '~/constants';
import { SbmOneclickQuery, SbmEmailHashDataQuery } from '~/queries';
import gtmPushEvent from 'common/utils/gtmPushEvent';

const gtmSignupConfirmationParams = {
  event: 'complete_account_registration_flow',
};

const creationFailedFormMessages = {
  error: [],
  info: [],
  status: ['@anonymous_account_creation_failed'],
  warning: [],
};

const propTypes = {
  children: func.isRequired,
};

const SignUpHandler = ({ children }) => {
  const {
    push: historyPush,
    location: { search },
  } = useHistory();
  const { hash: oneClickUserHash } = parse(search);
  const { 'autologin-hash': autologinHash } = parse(search);

  const { autoLogin, setOfferRedirectParams } = useAuthContext();

  const [formState, setFormState] = useState(null);
  const [formMessages, setFormMessages] = useState(null);

  const [
    getOneclickData,
    { data: oneclickData, loading: getOneclickDataLoading },
  ] = useLazyQuery(SbmOneclickQuery, {
    client: anonymousUserApolloClient,
    variables: { hash: oneClickUserHash },
  });

  const [
    getEmailHashData,
    { data: emailHashData, loading: getEmailHashDataLoading },
  ] = useLazyQuery(SbmEmailHashDataQuery, {
    client: anonymousUserApolloClient,
    variables: { hash: autologinHash },
  });

  const prevEmailHashData = usePrevious(emailHashData);

  useEffect(() => {
    if (!!oneClickUserHash) {
      getOneclickData({ hash: oneClickUserHash });
    }

    if (!!autologinHash) {
      getEmailHashData({ hash: autologinHash });
    }

    if (!oneClickUserHash && !autologinHash) {
      historyPush('/');
    }
  }, [
    historyPush,
    autologinHash,
    oneClickUserHash,
    getOneclickData,
    getEmailHashData,
  ]);

  useEffect(() => {
    if (getOneclickDataLoading || getEmailHashDataLoading) {
      setFormState(FORM_LOADING_STATE);
    }
  }, [getEmailHashDataLoading, getOneclickDataLoading]);

  useEffect(() => {
    if (oneclickData && !oneclickData.getSbmOneclickData) {
      setFormMessages(creationFailedFormMessages);
      setFormState(FORM_MESSAGE_STATE);
    }

    if (
      oneclickData &&
      (oneclickData.getSbmOneclickData.messages?.error.length ||
        oneclickData.getSbmOneclickData.messages?.warning.length)
    ) {
      setFormMessages(oneclickData.getSbmOneclickData.messages);
      setFormState(FORM_MESSAGE_STATE);
    }

    if (
      oneclickData &&
      oneclickData.getSbmOneclickData &&
      !(
        oneclickData.getSbmOneclickData.messages?.error.length ||
        oneclickData.getSbmOneclickData.messages?.warning.length
      )
    ) {
      setFormState(FORM_PASSWORD_ONECLICK_STATE);
    }
  }, [oneclickData]);

  useEffect(() => {
    if (!isEqual(prevEmailHashData, emailHashData)) {
      if (emailHashData && !emailHashData.getSbmEmailHashData) {
        setFormMessages(creationFailedFormMessages);
        setFormState(FORM_MESSAGE_STATE);
      }

      if (
        emailHashData &&
        (emailHashData.getSbmEmailHashData.messages?.error.length ||
          emailHashData.getSbmEmailHashData.messages?.warning.length)
      ) {
        setFormMessages(emailHashData.getSbmEmailHashData.messages);
        setFormState(FORM_MESSAGE_STATE);
      }

      if (
        emailHashData?.getSbmEmailHashData &&
        !(
          emailHashData.getSbmEmailHashData.messages?.error.length ||
          emailHashData.getSbmEmailHashData.messages?.warning.length
        )
      ) {
        gtmPushEvent(gtmSignupConfirmationParams);

        if (
          emailHashData.getSbmEmailHashData.offerID &&
          emailHashData.getSbmEmailHashData.offerLang
        ) {
          setOfferRedirectParams({
            offerID: emailHashData.getSbmEmailHashData.offerID,
            offerLang: emailHashData.getSbmEmailHashData.offerLang,
            offerLoyaltyStatus:
              emailHashData.getSbmEmailHashData.offerLoyaltyStatus,
          });
        }

        autoLogin({
          username: emailHashData.getSbmEmailHashData.email.toLowerCase(),
          hash: autologinHash,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoLogin, autologinHash, emailHashData, prevEmailHashData]);

  if (formState === FORM_PASSWORD_ONECLICK_STATE) {
    return children({
      formState,
      signUpData: oneclickData?.getSbmOneclickData,
      isEmailDisabled: !!oneclickData?.getSbmOneclickData.email,
    });
  }

  if (formState === FORM_MESSAGE_STATE) {
    return children({ formState, formMessages });
  }

  if (formState === FORM_LOADING_STATE) {
    return children({ formState });
  }

  return null;
};

SignUpHandler.propTypes = propTypes;

export default withErrorBoundary(SignUpHandler);
