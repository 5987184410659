import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Container from 'common/components/Container';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import FormContacts from '~/scenes/FormContacts';
import ErrorFallback from '~/components/ErrorFallback';
import Panel from '~/components/Panel';
import GTM from '~/components/GTM';

import IdentityPanel from './components/IdentityPanel';
import PasswordPlaceholder from './components/PasswordPlaceholder';
import styles from './AccountInformation.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  match: shape({
    url: string,
  }).isRequired,
};

const AccountInformation = ({ match }) => {
  const { t } = useTranslation(['account-information', 'common']);

  const identityBlock = t('identityBlock', {
    returnObjects: true,
  });
  const personalInfoBlockLabel = t('personalInfoBlockLabel');
  const editLabel = t('common:edit');
  const passwordBlockLabel = t('passwordBlockLabel');

  const { language } = useLocalisationContext();
  const {
    userSbmIdentity,
    userSbmContacts,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  const isIdentityAvailable = useMemo(
    () =>
      !(
        userSbmIdentity.birthName === '' &&
        userSbmIdentity.marriedName === '' &&
        userSbmIdentity.nationalities.length === 0 &&
        userSbmIdentity.birthDate === '' &&
        userSbmIdentity.firstName === ''
      ),
    [userSbmIdentity]
  );

  return (
    <Container tag="main" className={cx('root')} width={900}>
      {loadingUserData && <PageLoader />}
      {!loadingUserData && (
        <>
          <Panel
            title={identityBlock.blockLabel}
            url={
              isFailSafeMode || !isIdentityAvailable
                ? null
                : `${match.url}/edit-identity`
            }
            urlLabel={editLabel}
          >
            {isIdentityAvailable ? (
              <IdentityPanel />
            ) : (
              <ErrorFallback mode={'friendly'} />
            )}
          </Panel>
          <Panel
            headerClassName={cx('password-header')}
            title={passwordBlockLabel}
            header={<PasswordPlaceholder />}
            url={isFailSafeMode ? null : `${match.url}/change-password`}
            urlLabel={editLabel}
          />
          <Panel title={personalInfoBlockLabel}>
            {userSbmContacts ? (
              <FormContacts
                email={userSbmContacts.email}
                phone={userSbmContacts.phone}
                address={userSbmContacts.address}
                redirectTo={`/${language}`}
              />
            ) : (
              <ErrorFallback mode={'friendly'} />
            )}
          </Panel>
        </>
      )}
      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
};

AccountInformation.propTypes = propTypes;

export default withErrorBoundary(AccountInformation);
