import React, { useState } from 'react';
import { string, array, func, arrayOf } from 'prop-types';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import { propType } from 'graphql-anywhere';

import { GTM_BOOKED_DATE_FORMAT, GENDER_MAP } from 'common/constants';
import SpinLoader from 'common/components/SpinLoader';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';
import { sbmClient } from '~/apolloClient';

import WellnessProductPriceList from '../../components/WellnessProductPriceList';

const WellnessProductBookingMutation = gql`
  mutation wellnessProductBooking($input: WellnessProductBookingInput) {
    wellnessProductBooking(input: $input)
  }
`;

const propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  date: string.isRequired,
  dayTime: array.isRequired,
  errorRequiredMessage: string.isRequired,
  errorEmailMessage: string.isRequired,
  children: func.isRequired,
  priceList: arrayOf(
    propType(
      WellnessProductPriceList.fragment.wellnessProductPriceListFieldsFragment
    ).isRequired
  ).isRequired,
};

const OfferStickyFormHandler = function ({
  errorRequiredMessage,
  errorEmailMessage,
  dayTime,
  id,
  title,
  date,
  priceList: { productPrice },
  children,
}) {
  const [mutate] = useMutation(WellnessProductBookingMutation, {
    client: sbmClient,
  });

  const {
    loadingUserData,
    userSbmContacts,
    userSbmIdentity,
    loyaltyStatus,
    profile: { firstName, birthName },
  } = useUserContext();

  const validationSchema = Yup.object().shape({
    phone: Yup.string().required(errorRequiredMessage),
    firstname: Yup.string().required(errorRequiredMessage),
    lastname: Yup.string().required(errorRequiredMessage),
    email: Yup.string().email(errorEmailMessage).required(errorRequiredMessage),
  });

  const [formStep, setFormStep] = useState(0);

  function toNextStep() {
    setFormStep(formStep + 1);
  }

  const sendGtmEventStatistics = ({ date, dayTimeLabel }) => {
    const bookingTotalRevenue = !!productPrice
      ? productPrice.filter((item) => item.price && item.price !== '0')[0].price
      : null;

    const currentDate = moment();
    const bookedDate = moment(date).format(GTM_BOOKED_DATE_FORMAT);
    const timetolead = Math.abs(moment(date).diff(currentDate, 'days'));

    const params = {
      event: 'submit_wellness_booking',
      booked_date: bookedDate,
      booked_time_of_day: dayTimeLabel.toLowerCase(),
      value: title,
      booking_total_revenue: bookingTotalRevenue,
      time_to_lead: timetolead,
    };
    gtmPushEvent(params);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const { daytime, civility, firstname, lastname, email, comment, phone } =
      values;

    const dayTimeLabel = dayTime.find(({ value }) => value === daytime).label;

    // send GTM event Click
    sendGtmEventStatistics({ date, dayTimeLabel });

    const submitFields = {
      id: id,
      date: date,
      dayTime: dayTimeLabel,
      civility: civility,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      product: title,
      comment: comment,
    };

    mutate({
      variables: { input: submitFields },
      context: {
        headers: {
          'X-Acc-Grp': loyaltyStatus,
        },
      },
    })
      .then(({ data: { wellnessProductBooking } }) => {
        setSubmitting(false);
        if (wellnessProductBooking) {
          toNextStep();
        }
      })
      .catch((error) => {
        setSubmitting(false);
      });
  };

  const initialValues = {
    daytime: dayTime[0].value,
    civility: GENDER_MAP[userSbmIdentity?.gender] || '',
    firstname: firstName,
    lastname: birthName,
    phone: userSbmContacts?.phone?.[0]?.phone || '',
    email: userSbmContacts?.email?.[0]?.email || '',
    comment: '',
  };

  return loadingUserData ? (
    <SpinLoader />
  ) : (
    children({
      handleSubmit,
      validationSchema,
      initialValues,
      formStep,
      toNextStep,
    })
  );
};

OfferStickyFormHandler.propTypes = propTypes;

export default withErrorBoundary(OfferStickyFormHandler);
