import React from 'react';
import { string, bool, func, node } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './FilterButton.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  top: string,
  middle: node,
  bottom: string,
  onClick: func,
  isActive: bool,
  className: string,
};

const defaultProps = {
  top: null,
  middle: null,
  bottom: null,
  isActive: false,
  onClick: null,
  className: null,
};

const FilterButton = ({
  top,
  middle,
  bottom,
  isActive,
  className,
  ...props
}) => {
  return (
    <button {...props} className={cx('item', className, { active: isActive })}>
      <div className={cx('top')}>
        <span className={cx('item-label')}>{top}</span>
      </div>
      <div className={cx('middle')}>
        <span className={cx('item-value-num', { active: isActive })}>
          {middle}
        </span>
      </div>
      <div className={cx('bottom')}>
        <span className={cx('item-label')}>{bottom}</span>
      </div>
    </button>
  );
};

FilterButton.propTypes = propTypes;
FilterButton.defaultProps = defaultProps;

export default FilterButton;
