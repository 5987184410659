import React, { Component } from 'react';
import { node, func, oneOf } from 'prop-types';
import hoistNonReactStatic from 'hoist-non-react-statics';
import * as Sentry from '@sentry/react';

import getComponentDisplayName from 'common/utils/getComponentDisplayName';
import ErrorFallback from '~/components/ErrorFallback';

class ErrorBoundary extends Component {
  static propTypes = {
    FallbackComponent: func,
    children: node,
    onError: func,
    mode: oneOf(['silent', 'explicit', 'friendly']),
  };

  static defaultProps = {
    FallbackComponent: ErrorFallback,
    children: null,
    onError: null,
    mode: process.env.REACT_APP_ERROR_MODE,
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, info) {
    const { onError } = this.props;

    this.setState({
      error,
      info,
    });

    if (onError) {
      onError.call(this, error, info ? info.componentStack : '');
    }

    console.error(error);

    if (info) {
      console.log('Error info:', info);
    }

    Sentry.captureException(error);
  }

  render() {
    const {
      props: { children, FallbackComponent, mode },
      state: { error, info },
    } = this;
    const componentStack = info ? info.componentStack : '';

    return error ? (
      <FallbackComponent
        componentStack={componentStack}
        error={error}
        mode={mode}
      />
    ) : (
      children
    );
  }
}

export const withErrorBoundary = (Component, FallbackComponent, onError) => {
  function WithErrorBoundary(props) {
    return (
      <ErrorBoundary FallbackComponent={FallbackComponent} onError={onError}>
        <Component {...props} />
      </ErrorBoundary>
    );
  }

  WithErrorBoundary.displayName = `withErrorBoundary(${getComponentDisplayName(
    Component
  )})`;

  hoistNonReactStatic(WithErrorBoundary, Component);

  return WithErrorBoundary;
};

export default ErrorBoundary;
