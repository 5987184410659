import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';

import { createUseContext } from 'common/lib/context';

import { anonymousUserApolloClient } from '~/apolloClient';

const CaptchaKeyQuery = gql`
  query CaptchaKeyData {
    getCaptchaKey
  }
`;

const {
  Context: SiteSettingsContext,
  ContextProvider: SiteSettingsContextProvider,
  ContextConsumer: SiteSettingsContextConsumer,
  useContext: useSiteSettingsContext,
} = createUseContext((Provider) => ({ children }) => {
  const [friendlyCaptchaKey, setFriendlyCaptchaKey] = useState('');

  const { data, error } = useQuery(CaptchaKeyQuery, {
    client: anonymousUserApolloClient,
  });

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (data?.getCaptchaKey) {
      setFriendlyCaptchaKey(data.getCaptchaKey);
    }
  }, [data]);

  return (
    <Provider
      value={{
        friendlyCaptchaKey,
      }}
    >
      {children}
    </Provider>
  );
});

export {
  SiteSettingsContextConsumer,
  SiteSettingsContextProvider,
  useSiteSettingsContext,
};

export default SiteSettingsContext;
