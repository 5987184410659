import React from 'react';
import { object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Formik, Form, Field } from 'formik';

import Radio from 'common/components/FormComponents/Radio';
import Input from 'common/components/FormComponents/Input';
import Container from 'common/components/Container';
import SpinLoader from 'common/components/SpinLoader';
import Button from 'common/components/Button';
import IconContainer from 'common/components/IconContainer';
import InformationPanel from 'common/components/InformationPanel';
import InfoMessages from '~/components/InfoMessages';
import { ReactComponent as IconArrow } from 'common/media/icons/arrow-back.icon.svg';

import BlockTitle from '~/components/BlockTitle';

import CardVerificationFormHandler from './containers/CardVerificationFormHandler';
import styles from './CardVerification.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  mymcNumberVerificationData: object.isRequired,
  setMymcNumberVerificationData: func.isRequired,
  setIsLoyaltyMembership: func.isRequired,
  refetch: func.isRequired,
};

const CardVerificationPage = ({
  mymcNumberVerificationData,
  setMymcNumberVerificationData,
  setIsLoyaltyMembership,
  refetch,
}) => {
  //Translations (i18n)
  const { t } = useTranslation(['loyalty-program', 'common']);
  const cardVerificationLabels = t('cardVerification', {
    returnObjects: true,
  });
  const infoLabels = t('messages', {
    returnObjects: true,
  });
  const cancelButtonLabel = t('common:cancel');

  const {
    formTitle,
    methodText,
    radiosBlockLabel,
    submitMethodButtonLabel,
    codeText,
    codePlaceholder,
    submitCodeButtonLabel,
    resendText,
    backText,
    messageMethodErrorLabel,
    messageCodeSuccessLabel,
  } = cardVerificationLabels;

  const onCancelClickHandler = () => {
    setMymcNumberVerificationData(null);
    setIsLoyaltyMembership(true);
  };

  return (
    <Container className={cx('root')} width={900}>
      <div className={cx('content-wrapper')}>
        <CardVerificationFormHandler
          mymcNumberVerificationData={mymcNumberVerificationData}
          setMymcNumberVerificationData={setMymcNumberVerificationData}
          refetch={refetch}
        >
          {({
            handleMethodSubmit,
            initialMethodValues,
            handleCodeSubmit,
            initialCodeValues,
            isSubmitMethodSuccess,
            setSubmitMethodSuccess,
            verificationMethods,
            activeMethodData,
            resendCodeSubmit,
            submitMethodLoading,
            submitMethodError,
            errors,
            resetMessage,
            submitCodeSuccess,
          }) =>
            !isSubmitMethodSuccess && verificationMethods.length > 1 ? (
              <>
                <BlockTitle title={formTitle} className={cx('title')} />
                <div className={cx('text')}>{methodText}</div>
                <Formik
                  initialValues={initialMethodValues}
                  onSubmit={handleMethodSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className={cx('field-wrap')}>
                        <div className={cx('legend')}>{radiosBlockLabel}</div>
                        {verificationMethods.map(({ value, label }) => (
                          <Field
                            key={value}
                            className={cx('radio')}
                            name="method"
                            component={Radio}
                            id={value}
                            label={label}
                          />
                        ))}
                      </div>
                      <div className={cx('cta')}>
                        <Button
                          className={cx('button-cancel')}
                          type="button"
                          onClick={onCancelClickHandler}
                          inverted
                        >
                          {cancelButtonLabel}
                        </Button>
                        <Button
                          type="submit"
                          className={cx('cta-button', {
                            loading: isSubmitting,
                          })}
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <SpinLoader className={cx('loader')} size={16} />
                          )}
                          <span className={cx('button-label')}>
                            {submitMethodButtonLabel}
                          </span>
                        </Button>
                      </div>
                      {submitMethodError && (
                        <InformationPanel withIcon errorMode>
                          {messageMethodErrorLabel}
                        </InformationPanel>
                      )}
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <div className={cx('text')}>
                  {codeText}{' '}
                  <span className={cx('method-source')}>
                    {activeMethodData}
                  </span>
                </div>
                <Formik
                  initialValues={initialCodeValues}
                  onSubmit={handleCodeSubmit}
                >
                  {({ values, isSubmitting }) => (
                    <Form onChange={resetMessage}>
                      <div className={cx('field-wrap')}>
                        <Field
                          name="code"
                          component={Input}
                          placeholder={codePlaceholder}
                          required
                          maxLength={128}
                          disabled={isSubmitting}
                        />
                        <div className={cx('cta')}>
                          <Button
                            className={cx('button-cancel')}
                            type="button"
                            onClick={onCancelClickHandler}
                            inverted
                          >
                            {cancelButtonLabel}
                          </Button>
                          <Button
                            type="submit"
                            className={cx('cta-button', {
                              loading: isSubmitting,
                            })}
                            disabled={!values.code || isSubmitting}
                          >
                            {isSubmitting && (
                              <SpinLoader className={cx('loader')} size={16} />
                            )}
                            <span className={cx('button-label')}>
                              {submitCodeButtonLabel}
                            </span>
                          </Button>
                        </div>
                      </div>
                      {submitCodeSuccess && (
                        <InformationPanel withIcon successMode>
                          {messageCodeSuccessLabel}
                        </InformationPanel>
                      )}
                      {!!errors && (
                        <InformationPanel
                          isComponentChildren
                          withIcon
                          errorMode
                        >
                          <InfoMessages
                            isTextMode
                            messages={errors}
                            labels={infoLabels}
                          />
                        </InformationPanel>
                      )}
                      <div className={cx('code-description')}>
                        <button
                          className={cx('resend-btn')}
                          type="button"
                          disabled={submitMethodLoading}
                          onClick={() => resendCodeSubmit()}
                        >
                          <span className={cx('resend-text')}>
                            {resendText}
                          </span>
                          {submitMethodLoading && (
                            <SpinLoader
                              className={cx('resend-loader')}
                              size={12}
                            />
                          )}
                        </button>
                      </div>
                      {verificationMethods.length === 2 && (
                        <div className={cx('change-method')}>
                          <button
                            className={cx('change-method-btn')}
                            type="button"
                            onClick={() => setSubmitMethodSuccess(false)}
                          >
                            <IconContainer
                              className={cx('icon-back')}
                              icon={IconArrow}
                            />
                            {backText}
                          </button>
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              </>
            )
          }
        </CardVerificationFormHandler>
      </div>
    </Container>
  );
};

CardVerificationPage.propTypes = propTypes;

export default CardVerificationPage;
