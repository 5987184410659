import React from 'react';
import { object, func } from 'prop-types';
import classNames from 'classnames/bind';

import Toggle from 'common/containers/Toggle';
import flatToNestedNSortedMenu from 'common/utils/flatToNestedNSortedMenu';

import styles from './SideMenu.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  footerSideMenu: object.isRequired,
  LinkComponent: func.isRequired,
};

const renderFooterSideMenu = (item, LinkComponent) => {
  return (
    <li key={item.id} className={cx('side-menu-list-item')}>
      {item.isLink ? (
        <LinkComponent to={item.url} forceNewTab={item.target === '_blank'}>
          {item.title}
        </LinkComponent>
      ) : (
        <Toggle>
          {({ toggle: toggleActive, status: isActive }) => {
            return (
              <button
                className={cx('side-menu-list-head', {
                  'side-menu-list-head-active': isActive,
                })}
                onClick={toggleActive}
              >
                {item.title}
              </button>
            );
          }}
        </Toggle>
      )}
      {item.children && (
        <ul>
          {item.children.map((el) => renderFooterSideMenu(el, LinkComponent))}
        </ul>
      )}
    </li>
  );
};

const SideMenu = ({ footerSideMenu, LinkComponent }) => {
  const footerSideMenuNested = flatToNestedNSortedMenu(
    footerSideMenu.menuItems
  );

  return (
    <div className={cx('root')}>
      <ul className={cx('side-menu-list')}>
        {footerSideMenuNested
          .slice(0, 3)
          .map((el) => renderFooterSideMenu(el, LinkComponent))}
      </ul>
    </div>
  );
};

SideMenu.propTypes = propTypes;

export default SideMenu;
