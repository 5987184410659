import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { Formik, Form, Field, FieldArray } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Container from 'common/components/Container';
import Input from 'common/components/FormComponents/Input';
import Select from 'common/components/FormComponents/Select';
import RadioButtonsContainer from 'common/components/FormComponents/RadioButtonsContainer';
import Radio from 'common/components/FormComponents/Radio';
import SelectDate from 'common/components/FormComponents/SelectDate';
import InformationPanel from 'common/components/InformationPanel';
import MultiField from 'common/components/FormComponents/MultiField';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';
import { MYACC_LANGUAGE } from 'common/constants';

import GTM from '~/components/GTM';
import Checkbox from '~/components/FormComponents/Checkbox';
import SelectProfession from '~/components/FormComponents/SelectProfession';
import Panel from '~/components/Panel';
import BlockTitle from '~/components/BlockTitle';
import FormButtons from '~/components/FormButtons';
import InfoMessages from '~/components/InfoMessages';
import InputPhone from '~/components/FormComponents/InputPhone';
import useOnNewFieldAdd from '~/scenes/FormContacts/hooks/useOnNewFieldAdd';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';
import useCountryNameKey from '~/hooks/useCountryNameKey';

import OneClickFormHandler from './containers/OneClickFormHandler';
import styles from './OneClick.module.scss';

const cx = classNames.bind(styles);

const OneClick = () => {
  const formElementRef = useRef();

  const { onNewFieldAdd, counter, setCounter } =
    useOnNewFieldAdd(formElementRef);

  const prioritisedCountries = usePrioritisedCountries();
  const countryNameKey = useCountryNameKey();

  const { t } = useTranslation(['oneclick', 'common', 'auth', 'identity-form']);
  const pageTitle = t('pageTitle');
  const formTitle = t('formTitle');
  const fieldEmailLabel = t('fieldEmailLabel');
  const fieldGenderLabel = t('fieldGenderLabel');
  const fieldNameLabel = t('fieldNameLabel');
  const fieldFamilyNameLabel = t('fieldFamilyNameLabel');
  const fieldMarriedNameLabel = t('fieldMarriedNameLabel');
  const fieldDateLabel = t('fieldDateLabel');
  const fieldMymcLabel = t('fieldMymcLabel');
  const fieldDateYearLabel = t('common:fieldDateYearLabel');
  const fieldDateMonthLabel = t('common:fieldDateMonthLabel');
  const fieldDateDayLabel = t('common:fieldDateDayLabel');
  const professionLabel = t('common:professionLabel');
  const fieldPhoneLabel = t('fieldPhoneLabel');
  const groupFieldsForeignIdsLabel = t('groupFieldsForeignIdsLabel');
  const foreignIdsSourceKeyLabel = t('foreignIdsSourceKeyLabel');
  const foreignIdsSourceNameLabel = t('foreignIdsSourceNameLabel');
  const successMessage = t('successMessage');
  const buttonAddLabel = t('buttonAddLabel');
  const buttonResetLabel = t('buttonResetLabel');
  const languageLabel = t('fieldLanguageLabel');
  const validationRequiredFieldMessage = t(
    'auth:create.validationRequiredFieldMessage'
  );
  const countryOfResidenceFieldLabel = t(
    'identity-form:countryOfResidenceFieldLabel'
  );

  const infoLabels = t('messages', {
    returnObjects: true,
  });

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Panel>
        <BlockTitle title={formTitle} />
        <OneClickFormHandler>
          {({
            foreignIdsOptions,
            gender,
            handleSubmit,
            resetForm,
            initialValues,
            validationSchema,
            submitError,
            submitErrorMessage,
            submitSuccess,
            resetMessage,
          }) => (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, formik) => {
                handleSubmit(values, formik);
              }}
            >
              {({
                values,
                setFieldValue,
                isSubmitting,
                resetForm: formikReset,
                errors,
                touched,
              }) => (
                <Form
                  autoComplete="off"
                  noValidate
                  className={cx('form')}
                  onChange={resetMessage}
                >
                  <Field
                    name="email"
                    component={Input}
                    placeholder={fieldEmailLabel}
                    required
                    maxLength={128}
                    autoComplete="username"
                  />

                  <RadioButtonsContainer
                    className={cx('radios-wrapper')}
                    label={fieldGenderLabel}
                    required
                    isError={touched['gender'] && !!errors['gender']}
                    errorMsg={validationRequiredFieldMessage}
                  >
                    {gender.map(({ value, label }) => (
                      <Field
                        key={value}
                        name="gender"
                        component={Radio}
                        styledView
                        id={value}
                        label={label}
                        className={cx('radio')}
                      />
                    ))}
                  </RadioButtonsContainer>

                  <Field
                    name="name"
                    component={Input}
                    placeholder={fieldNameLabel}
                    maxLength={30}
                    required
                  />
                  <Field
                    name="familyName"
                    component={Input}
                    placeholder={fieldFamilyNameLabel}
                    maxLength={30}
                    required
                  />
                  <Field
                    name="marriedName"
                    component={Input}
                    placeholder={fieldMarriedNameLabel}
                    maxLength={30}
                  />
                  <Field
                    name="birthDate"
                    component={SelectDate}
                    required
                    itemClassName={cx('select-item')}
                    dateFormat={'YYYY-MM-DD'}
                    placeholder={fieldDateLabel}
                    placeholderYear={fieldDateYearLabel}
                    placeholderMonth={fieldDateMonthLabel}
                    placeholderDay={fieldDateDayLabel}
                    lastAvailableDate={moment()
                      .subtract(18, 'years')
                      .format('YYYY-MM-DD')}
                    className={cx('date-input')}
                  />
                  <Field name={'phoneNumber'}>
                    {(field) => (
                      <InputPhone
                        value={values['phoneNumber']}
                        label={fieldPhoneLabel}
                        countryIso={'FR'}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field
                    name="country"
                    component={Select}
                    options={prioritisedCountries}
                    placeholder={countryOfResidenceFieldLabel}
                    customKeys={['id', countryNameKey]}
                    required
                  />
                  <Field
                    name="lang"
                    component={Select}
                    options={MYACC_LANGUAGE}
                    customKeys={['langISO3', 'title']}
                    placeholder={languageLabel}
                    classNameWrapper={cx('language-select')}
                    required
                  />
                  <Field
                    name="occupation"
                    component={SelectProfession}
                    placeholder={professionLabel}
                    required
                  />
                  <div className={cx('group-title')}>
                    {groupFieldsForeignIdsLabel}
                  </div>
                  <FieldArray name="foreignIds">
                    {({ remove, push, replace }) =>
                      values.foreignIds.length > 0 &&
                      values.foreignIds.map((_, index) => (
                        <MultiField
                          key={index}
                          rootClassName={cx('multifield-item', {
                            'shadow-highlighted': index === counter,
                          })}
                          formElementRef={formElementRef}
                          mainField={
                            <Field
                              isFieldEmpty={
                                values.foreignIds[index].sourceKey === ''
                              }
                              name={`foreignIds.${index}.sourceKey`}
                              component={Input}
                              placeholder={foreignIdsSourceKeyLabel}
                              maxLength={128}
                            />
                          }
                          secondaryField={
                            <Field
                              name={`foreignIds.${index}.sourceName`}
                              component={Select}
                              options={foreignIdsOptions}
                              placeholder={foreignIdsSourceNameLabel}
                            />
                          }
                          cta={
                            <MultiFieldCta
                              onIncrease={
                                values.foreignIds.length === index + 1
                                  ? () => {
                                      push({ sourceKey: '', sourceName: '' });
                                      onNewFieldAdd();
                                      setCounter(values.foreignIds.length);
                                      resetMessage();
                                    }
                                  : null
                              }
                              onRemove={
                                values.foreignIds.length === 1
                                  ? () => {
                                      replace(0, {
                                        sourceKey: '',
                                        sourceName: '',
                                      });
                                      resetMessage();
                                    }
                                  : () => {
                                      remove(index);
                                      resetMessage();
                                    }
                              }
                            />
                          }
                        />
                      ))
                    }
                  </FieldArray>
                  <Field
                    name="mymc"
                    label={fieldMymcLabel}
                    component={Checkbox}
                    className={cx('checkbox')}
                    textClassName={cx('checkbox-text')}
                    classNameLabel={cx('checkbox-label')}
                  />
                  {submitSuccess && (
                    <InformationPanel withIcon successMode>
                      {`${successMessage} ${values.email}!`}
                    </InformationPanel>
                  )}
                  {submitError && (
                    <InformationPanel isComponentChildren withIcon errorMode>
                      <InfoMessages
                        isTextMode
                        mode={'error'}
                        messages={submitErrorMessage}
                        labels={infoLabels}
                      />
                    </InformationPanel>
                  )}

                  <FormButtons
                    isSubmitting={isSubmitting}
                    isSubmitDisabled={isSubmitting}
                    className={cx('form-buttons')}
                    saveButtonLabel={buttonAddLabel}
                    cancelButtonLabel={buttonResetLabel}
                    onClickCancelButton={() => {
                      setFieldValue('phoneNumber', '');
                      resetForm(formikReset);
                      resetMessage();
                    }}
                  />
                </Form>
              )}
            </Formik>
          )}
        </OneClickFormHandler>
      </Panel>

      <GTM isMyCS />
    </Container>
  );
};

export default OneClick;
