import React from 'react';
import { string, bool, func, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Select.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  value: string,
  name: string,
  placeholder: string,
  onChange: func,
  onFocus: func,
  highlighted: bool,
  className: string,
  classNameLabel: string,
  disabled: bool,
  theme: oneOf(['block-theme']),
};

const defaultProps = {
  value: '',
  name: null,
  onChange: null,
  placeholder: '',
  highlighted: false,
  className: null,
  classNameLabel: null,
  disabled: false,
  theme: null,
};

const Select = ({
  value,
  name,
  placeholder,
  onChange,
  onFocus,
  children,
  highlighted,
  className,
  classNameLabel,
  disabled,
  theme,
}) => (
  <div className={cx('root', className, theme)}>
    <label
      htmlFor={name}
      className={cx('label', { small: !!value }, classNameLabel)}
    >
      {placeholder}
    </label>
    <select
      value={value}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
      className={cx('select', { highlighted })}
      disabled={disabled}
    >
      <option value="" disabled />
      {children}
    </select>
    <span className={cx('arrow')} />
  </div>
);

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
