import React from 'react';
import { object, string } from 'prop-types';
import classNames from 'classnames/bind';

import Img from 'common/components/Img';
import GenericImg from 'common/components/GenericImg';

import styles from './UniverseAttribute.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  logo: object,
  text: string,
  label: string.isRequired,
};

const defaultProps = {
  className: null,
  logo: null,
  text: null,
};

const starsStamp = '⋆★☆✯✰✩✪✭✮✫✬';

const svgRegExp = RegExp(/^.*\.svg$/im);

const renderLogoText = (text, className) => (
  <span
    className={cx(
      'text-logo',
      {
        'big-text-logo': text.length <= 3,
        'mid-text-logo': text.length > 3 && text.length <= 7,
        'small-text-logo': text.length > 7,
      },
      className
    )}
  >
    {text}
  </span>
);

const renderImageAsBackground = (url) => (
  <div className={cx('bg-image')} style={{ backgroundImage: `url(${url})` }}>
    {/*This div fix issue with incorrect svg scaling in the IE 11.0.xxx */}
  </div>
);

const renderSimpleIcon = (url, label) => {
  return svgRegExp.test(url) ? (
    renderImageAsBackground(url)
  ) : (
    <Img src={url} alt={label} />
  );
};

const renderLogoImage = (image) => {
  const {
    logoAttributesImage: {
      url: logoAttributesImageSrc,
      width: logoAttributesImageWidth,
      height: logoAttributesImageHeight,
    },
    logoAttributesImage2x: { url: logoAttributesImageSrc2x },
    alt: logoAttributesImageAlt,
  } = image;

  return logoAttributesImageWidth && logoAttributesImageHeight ? (
    <GenericImg
      src={logoAttributesImageSrc}
      sources={[
        { srcSet: `${logoAttributesImageSrc2x} 2x`, type: 'image/webp' },
      ]}
      alt={logoAttributesImageAlt}
      width={logoAttributesImageWidth}
      height={logoAttributesImageHeight}
      responsive
      lazy
    />
  ) : (
    renderImageAsBackground(logoAttributesImageSrc)
  );
};

const UniverseAttribute = ({ logo, text, label, className }) => {
  const getLogo = () => {
    if (logo !== null || text !== null) {
      if (logo === null) {
        return starsStamp.indexOf(text[0]) > 0 ? (
          <span className={cx('item-logo-stars')}>{text.substring(0, 5)}</span>
        ) : (
          <div className={cx('item-logo-text')}>
            {renderLogoText(text, className)}
          </div>
        );
      } else {
        if (logo.image) {
          return renderLogoImage(logo.image);
        } else {
          return renderSimpleIcon(logo.url, label);
        }
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <div className={cx('item-logo')}>{getLogo()}</div>
      <div className={cx('item-label')}>{label}</div>
    </>
  );
};

UniverseAttribute.propTypes = propTypes;
UniverseAttribute.defaultProps = defaultProps;

export default UniverseAttribute;
