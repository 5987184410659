import React from 'react';
import { string, node, oneOfType, object, bool } from 'prop-types';

import isUrlExternal from 'common/lib/isUrlExternal';
import { useUrlContext } from 'common/context/UrlContext';
import LinkNext from 'common-next/components/LinkNext';

const propTypes = {
  to: oneOfType([string, object]).isRequired,
  children: node.isRequired,
  forceNewTab: bool,
  target: string,
};

const defaultProps = {
  forceNewTab: false,
  target: '_blank',
};

/**
 * Link that also works for external URL's
 */
const SBMLink = ({ to, target, children, forceNewTab, ...props }) => {
  const { hostname } = useUrlContext();
  // RegExp is needed for prevent duplicated internal links (http://mydomain.com/http://mydomain.com/en)
  // Examples of filtered domains
  // https://dev.domain.com
  // http://dev.domain.com
  // www.dev.domain.com
  // localhost:3000
  const domainRegExp = new RegExp(
    '^(?:https?:\\/\\/)?(?:www\\.)?[^:/\\n]+(?::\\d*)?',
    'i'
  );

  if (typeof to === 'string') {
    if (to.startsWith('tel:')) {
      return (
        <a href={to} {...props} rel="nofollow">
          {children}
        </a>
      );
    }

    // Anchor links
    if (to.startsWith('#')) {
      return (
        <a href={to} {...props} rel="nofollow">
          {children}
        </a>
      );
    }

    // hotfix, @see commit: f3dedbee0a. Force new tab opening (#1088517)
    // @TODO: Review startsWith - why this was used to fix files links #352074
    if (
      forceNewTab ||
      isUrlExternal({ url: to, hostname }) ||
      to.startsWith('//')
    ) {
      return (
        <a target={target} rel="noopener noreferrer" href={to} {...props}>
          {children}
        </a>
      );
    }
  }

  const location = typeof to === 'string' ? to.replace(domainRegExp, '') : to;

  return location ? (
    <LinkNext to={location} {...props}>
      {children}
    </LinkNext>
  ) : (
    <span {...props}>{children}</span>
  );
};

SBMLink.propTypes = propTypes;
SBMLink.defaultProps = defaultProps;

export default SBMLink;
