import W_ICON_1 from 'common/media/meteo/icons/meteo-w-1.svg?url';
import W_ICON_2 from 'common/media/meteo/icons/meteo-w-2.svg?url';
import W_ICON_3 from 'common/media/meteo/icons/meteo-w-3.svg?url';
import W_ICON_4 from 'common/media/meteo/icons/meteo-w-4.svg?url';
import W_ICON_5 from 'common/media/meteo/icons/meteo-w-5.svg?url';
import W_ICON_6 from 'common/media/meteo/icons/meteo-w-6.svg?url';
import W_ICON_7 from 'common/media/meteo/icons/meteo-w-7.svg?url';
import W_ICON_8 from 'common/media/meteo/icons/meteo-w-8.svg?url';
import W_ICON_9 from 'common/media/meteo/icons/meteo-w-9.svg?url';
import W_ICON_10 from 'common/media/meteo/icons/meteo-w-10.svg?url';
import W_ICON_11 from 'common/media/meteo/icons/meteo-w-11.svg?url';
import W_ICON_12 from 'common/media/meteo/icons/meteo-w-12.svg?url';
import W_ICON_13 from 'common/media/meteo/icons/meteo-w-13.svg?url';
import W_ICON_14 from 'common/media/meteo/icons/meteo-w-14.svg?url';
import W_ICON_15 from 'common/media/meteo/icons/meteo-w-15.svg?url';
import W_ICON_16 from 'common/media/meteo/icons/meteo-w-16.svg?url';
import W_ICON_17 from 'common/media/meteo/icons/meteo-w-17.svg?url';
import WIND_ICON from 'common/media/meteo/icons/meteo-wind.svg?url';
import SEA_FLAG_ICON from 'common/media/meteo/icons/meteo-sea-flag.svg?url';

export default {
  W_ICON_1,
  W_ICON_2,
  W_ICON_3,
  W_ICON_4,
  W_ICON_5,
  W_ICON_6,
  W_ICON_7,
  W_ICON_8,
  W_ICON_9,
  W_ICON_10,
  W_ICON_11,
  W_ICON_12,
  W_ICON_13,
  W_ICON_14,
  W_ICON_15,
  W_ICON_16,
  W_ICON_17,
  WIND_ICON,
  SEA_FLAG_ICON,
};
