import { gql } from '@apollo/client';

const BookingCalendarQuery = gql`
  query BookingCalendarData(
    $adults: Int!
    $after: Int
    $attributes: [ID]
    $children: Int!
    $first: Int
    $fromDate: String!
    $hotel: [ID]
    $level: [ID]
    $room: [ID]
    $toDate: String!
    $roomsNumber: Int!
    $rate: String
  ) {
    calendar(
      adults: $adults
      after: $after
      attributes: $attributes
      children: $children
      first: $first
      fromDate: $fromDate
      hotel: $hotel
      level: $level
      room: $room
      toDate: $toDate
      roomsNumber: $roomsNumber
      rate: $rate
    ) {
      noResultsMessage
      dates {
        available
        date
        price
        minStay
      }
    }
  }
`;

export default BookingCalendarQuery;
