import {
  CARD_MEMBRE_IMG,
  CARD_SILVER_IMG,
  CARD_GOLD_IMG,
  CARD_PLATINUM_IMG,
} from '~/constants';

export default function getCardImg(status) {
  if (status === 10) {
    return CARD_MEMBRE_IMG;
  }

  if (status === 20) {
    return CARD_SILVER_IMG;
  }

  if (status === 30) {
    return CARD_GOLD_IMG;
  }

  if (status === 40) {
    return CARD_PLATINUM_IMG;
  }

  return null;
}
