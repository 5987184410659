import { func } from 'prop-types';

import { gql, useQuery } from '@apollo/client';
import { anonymousUserApolloClient } from '~/apolloClient';

import { useLocalisationContext } from '~/context/LocalisationContext';

const propTypes = {
  children: func.isRequired,
};

const defaultProps = {};

const OccupationFragment = gql`
  fragment OccupationFragment on Occupation {
    value: id
    label: value
  }
`;

const GetProfessionsQuery = gql`
  ${OccupationFragment}
  query SbmOfferRouteData($lang: String!) {
    occupations(language: $lang) {
      ...OccupationFragment
    }
  }
`;

const SelectDateHandler = ({ children }) => {
  const { languageISO3 } = useLocalisationContext();
  const { data } = useQuery(GetProfessionsQuery, {
    client: anonymousUserApolloClient,
    fetchPolicy: 'cache-first',
    variables: { lang: languageISO3 },
  });

  const professions = data
    ? data.occupations.map((el) => {
        return {
          value: el.value,
          label: el.label,
        };
      })
    : [];
  return children({
    professions,
  });
};

SelectDateHandler.propTypes = propTypes;
SelectDateHandler.defaultProps = defaultProps;
SelectDateHandler.fragments = { OccupationFragment };

export default SelectDateHandler;
