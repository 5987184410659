import { useEffect, useMemo } from 'react';
import { oneOfType, string, number, array, bool, object } from 'prop-types';
import isEqual from 'lodash/isEqual';

import { useEffectOnMount } from 'common/hooks';
import { GTM_LOYALTY_STATUSES, BRIDGE_LSKEY } from 'common/constants';
import usePrevious from 'common/hooks/usePrevious';
import { MYAC_MODE, MYCS_MODE } from 'common/constants';
import gtmPush from 'common/utils/gtmPush';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useAuthContext } from '~/context/AuthContext';

import {
  usePreferences,
  useIdentity,
  useLoyalty,
  useGdpr,
  useInstance,
} from './hooks';
import { UNDEFINED } from './constants';

const propTypes = {
  isMyAC: bool,
  isMyCS: bool,
  userIdentity: object,
  userPreferences: object,
  userLoyalty: object,
  loyaltyStatus: oneOfType([string, number]),
  userGdpr: array,
  loadingUserData: bool,
};

const defaultProps = {
  isMyAC: false,
  isMyCS: false,
  userIdentity: null,
  userPreferences: null,
  userLoyalty: null,
  loyaltyStatus: null,
  userGdpr: null,
  loadingUserData: false,
};

const GTM = ({
  userIdentity,
  userPreferences,
  userLoyalty,
  loyaltyStatus,
  userGdpr,
  loadingUserData,
}) => {
  const { language } = useLocalisationContext();
  const { appMode } = useAuthContext();

  const isLoggedIn = useMemo(
    () => appMode === MYAC_MODE || appMode === MYCS_MODE,
    [appMode]
  );

  const isMyAC = useMemo(() => appMode === MYAC_MODE, [appMode]);

  const instance = useInstance();
  const { userAge, uuid, userCountry } = useIdentity(
    userIdentity,
    loadingUserData
  );
  const { loyaltyId, loyaltyStatusNormalized } = useLoyalty(
    userLoyalty,
    loyaltyStatus,
    loadingUserData,
    isMyAC
  );
  const {
    userLanguage,
    userContactChannels,
    userFavoriteExperiences,
    userFrequencyOfVisits,
    userFavouriteSeason,
  } = usePreferences(userPreferences, loadingUserData);
  const { userSubscribedToNewsletter } = useGdpr(userGdpr, loadingUserData);

  const userParams = {
    siteEnvironment: instance,
    isLoggedIn: isLoggedIn,
    user_id: isMyAC ? uuid : UNDEFINED,
    userType: isLoggedIn ? (isMyAC ? 'client' : 'myCS') : UNDEFINED,
    userGender:
      isMyAC && userIdentity?.gender ? userIdentity.gender : UNDEFINED,
    userAge: isMyAC ? userAge : UNDEFINED,
    userCountry: isMyAC ? userCountry : UNDEFINED,
    userPreferredLanguage: isMyAC ? userLanguage : UNDEFINED,
    userPreferredContactChannel: isMyAC ? userContactChannels : UNDEFINED,
    userFavoriteExperiences: isMyAC ? userFavoriteExperiences : UNDEFINED,
    userFrequencyOfVisits: isMyAC ? userFrequencyOfVisits : UNDEFINED,
    userFavouriteSeason: isMyAC ? userFavouriteSeason : UNDEFINED,
    userFidID: isMyAC ? loyaltyId : UNDEFINED,
    userFidStatus: isMyAC
      ? GTM_LOYALTY_STATUSES[loyaltyStatusNormalized]
      : UNDEFINED,
    userSubscribedToNewsletter: isMyAC ? userSubscribedToNewsletter : UNDEFINED,
  };

  const gtmParams = {
    event: 'view_content',
    content_type: 'myAccount',
    content_univers: 'myAccount',
    item_id: 'myAccount',
    platform_environment: instance,
    platform_language: language,
  };

  const prevParams = usePrevious({ ...gtmParams, ...userParams });

  useEffectOnMount(() => {
    if (!userIdentity) {
      gtmPush({ ...gtmParams, ...userParams });
    }

    localStorage.setItem(BRIDGE_LSKEY.userData, JSON.stringify(userParams));
  });

  useEffect(() => {
    if (
      userIdentity &&
      !loadingUserData &&
      !isEqual({ ...gtmParams, ...userParams }, prevParams)
    ) {
      gtmPush({ ...gtmParams, ...userParams });

      localStorage.setItem(BRIDGE_LSKEY.userData, JSON.stringify(userParams));
    }
  }, [userIdentity, loadingUserData, gtmParams, userParams, prevParams]);

  return null;
};

GTM.propTypes = propTypes;
GTM.defaultProps = defaultProps;

export default withErrorBoundary(GTM);
