import React from 'react';
import { string, bool, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import logoUrl, { ReactComponent as Logo } from 'common/media/logo.svg';

import styles from './LogoUi.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  invert: bool,
  title: string,
  logoText: string.isRequired,
  className: string,
  size: oneOf(['lg', 'md']),
  itemScope: bool,
  itemType: string,
  itemProp: string,
  itemPropTitle: string,
  tagName: string,
};

const defaultProps = {
  invert: false,
  itemScope: false,
  title: null,
  className: null,
  size: 'lg',
  tagName: null,
  itemType: null,
  itemProp: null,
  itemPropTitle: null,
};

function LogoUi({
  invert,
  title,
  logoText,
  className,
  size,
  itemType,
  itemPropTitle,
  itemProp,
  itemScope,
  tagName,
}) {
  const Tag = tagName ? tagName : 'span';

  return (
    <Tag className={cx(className)} itemScope={itemScope} itemType={itemType}>
      <span className="screen-reader" itemProp={itemPropTitle}>
        {title || logoText}
      </span>
      <span className={cx('logo-wrapper', { black: invert }, size)}>
        <Logo />
        <link itemProp={itemProp} href={logoUrl} />
      </span>
    </Tag>
  );
}

LogoUi.propTypes = propTypes;
LogoUi.defaultProps = defaultProps;

export default LogoUi;
