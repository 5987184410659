import { func, array, object } from 'prop-types';

import { EMAIL_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';

const propTypes = {
  children: func.isRequired,
  formDispatch: func.isRequired,
  formValues: object,
  emailList: array,
  phoneList: array,
  addressList: array,
};

const defaultProps = {
  formValues: null,
  emailList: [],
  phoneList: [],
  addressList: [],
};

const EmailFieldsHandler = ({
  children,
  emailList,
  phoneList,
  addressList,
  formValues,
  formDispatch,
}) => {
  const onEmailAdd = (id) =>
    formDispatch({
      type: 'addEmail',
      emailList: emailList,
      phoneList: phoneList,
      addressList: addressList,
      formValues,
      newField: {
        name: `email_${id}`,
        value: '',
        catName: `email_${id}_cat`,
        catValue: EMAIL_DEFAULT_CATEGORY,
        isMutable: true,
        isDeletable: true,
      },
    });

  const onEmailRemove = (id) =>
    formDispatch({
      type: 'deleteEmail',
      phoneList: phoneList,
      emailList: emailList,
      addressList: addressList,
      formValues,
      name: id,
    });

  return children({ onEmailAdd, onEmailRemove });
};

EmailFieldsHandler.propTypes = propTypes;
EmailFieldsHandler.defaultProps = defaultProps;

export default EmailFieldsHandler;
