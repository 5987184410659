import { MOBILE_MEDIA_STR, TABLET_MEDIA_STR } from 'common/constants';

export default function getPictureSources({
  desktop,
  desktop2x,
  tablet,
  tablet2x,
  mobile,
  mobile2x,
}) {
  return [
    ...(mobile
      ? [
          {
            srcSet: `${mobile.url} 1x,  ${mobile2x.url} 2x`,
            media: MOBILE_MEDIA_STR,
            type: 'image/webp',
          },
        ]
      : []),
    ...(tablet
      ? [
          {
            srcSet: `${tablet.url} 1x, ${tablet2x.url} 2x`,
            media: TABLET_MEDIA_STR,
            type: 'image/webp',
          },
        ]
      : []),
    ...(desktop
      ? [
          {
            srcSet: `${desktop.url} 1x, ${desktop2x.url} 2x`,
            type: 'image/webp',
          },
        ]
      : []),
  ];
}
