import { useState, useCallback } from 'react';
import { func, object } from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';

const IdentityRegionsSendMutation = gql`
  mutation updateSbmIdentityMutable($value: SbmIdentityMutableInput!) {
    updateSbmIdentityMutable(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  data: object.isRequired,
  children: func.isRequired,
};

function FormIdentityHandler({ data, children }) {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { refetchUserData } = useUserContext();

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess) {
      setSubmitError(false);
      setSubmitSuccess(false);
    }
  }, [submitError, submitSuccess]);

  const [updateMutableIdentity] = useMutation(IdentityRegionsSendMutation);

  const initialValues = {
    nationalities: data?.nationalities.length ? data?.nationalities : [''],
    countryOfResidence: data?.countryOfResidence || '',
    occupation: data?.occupation || '',
  };

  const handleSubmit = (
    { nationalities, countryOfResidence, occupation },
    { setSubmitting, setFieldValue }
  ) => {
    const nationalitiesNormalized = nationalities.filter((item) => item !== '');

    setSubmitSuccess(false);

    setFieldValue(
      'nationalities',
      nationalitiesNormalized.length > 0 ? nationalitiesNormalized : ['']
    );

    const submitFields = {
      nationalities: nationalitiesNormalized,
      countryOfResidence,
      occupation,
    };

    updateMutableIdentity({
      variables: {
        value: submitFields,
      },
    })
      .then(({ data: { updateSbmIdentityMutable } }) => {
        setSubmitting(false);

        if (updateSbmIdentityMutable?.result) {
          setSubmitSuccess(true);
          refetchUserData();
        } else {
          setSubmitError(true);
        }
      })
      .catch((error) => {
        console.warn(error);
        setSubmitting(false);
        setSubmitError(true);
      });
  };

  return children({
    handleSubmit,
    initialValues,
    submitError,
    submitSuccess,
    resetMessage,
  });
}

FormIdentityHandler.propTypes = propTypes;

export default withErrorBoundary(FormIdentityHandler);
