import React, { useState } from 'react';
import { node, array, string, func, oneOfType } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './LoadMoreContainer.module.scss';
import LoadMoreButton from '../../components/LoadMoreButton';

const cx = classNames.bind(styles);

const propTypes = {
  list: array.isRequired,
  className: string,
  loadMoreButtonLabel: string.isRequired,
  children: oneOfType([func, node]).isRequired,
};

const defaultProps = {
  className: null,
};

function LoadMoreContainer({ list, className, children, loadMoreButtonLabel }) {
  const [loadMorePage, setLoadMorePage] = useState(1);
  return (
    <>
      {children({ loadMorePage })}
      {loadMorePage < list.length && (
        <div className={cx('root', className)}>
          <LoadMoreButton
            loadMoreButtonLabel={loadMoreButtonLabel}
            loadMorePage={loadMorePage}
            setLoadMorePage={setLoadMorePage}
          />
        </div>
      )}
    </>
  );
}

LoadMoreContainer.propTypes = propTypes;
LoadMoreContainer.defaultProps = defaultProps;

export default LoadMoreContainer;
