import { gql } from '@apollo/client';

export const PreferencesLanguageSendMutation = gql`
  mutation updateSbmPreferencesLanguage($value: SbmPreferencesLanguageInput!) {
    updateSbmPreferencesLanguage(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;
