import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Container from 'common/components/Container';
import GTM from '~/components/GTM';

import PageLoader from '~/components/PageLoader';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUserContext } from '~/context/UserContext';
import LoyaltyCard from '~/scenes/LoyaltyCard';
import RelativeMember from '~/components/RelativeMember';
import ErrorFallback from '~/components/ErrorFallback';
import PromoBanner from '~/components/PromoBanner';
import { useMyaccHistory } from '~/hooks';

import LoyaltyProgramBlocks from './components/LoyaltyProgramBlocks';
import styles from './LoyaltyProgram.module.scss';

const cx = classNames.bind(styles);

const loyaltyProgramDataFragment = gql`
  fragment loyaltyProgramDataFragment on SbmLoyalty {
    blocks {
      description {
        text
      }
      messages {
        messages {
          info
          status
          warning
          error
        }
      }
      joining {
        linking
        enrollment
      }
      loyalty {
        ...loyaltyCardFields
      }
      cards {
        ...relativeMemberFields
      }
      infoStatus
      infoAdvantages
    }
  }
  ${LoyaltyCard.fragment.loyaltyCardDataFragment}
  ${RelativeMember.fragment.relativeMemberFieldsFragment}
`;

const fragment = {
  loyaltyProgramDataFragment,
};

const LoyaltyProgramDataQuery = gql`
  query LoyaltyProgramData {
    getSbmLoyalty {
      ...loyaltyProgramDataFragment
    }
  }
  ${loyaltyProgramDataFragment}
`;

const LoyaltyProgram = () => {
  const { language, changeLanguage } = useLocalisationContext();
  const history = useMyaccHistory();

  const {
    location: { pathname },
  } = history;

  const {
    loading: loadingLoyaltyProgramData,
    data,
    refetch,
    startPolling,
    stopPolling,
  } = useQuery(LoyaltyProgramDataQuery, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  const { t } = useTranslation('loyalty-program');
  const pageTitle = t('pageTitle');

  useEffect(() => {
    if (language && localStorage.getItem('i18nextLng') !== language) {
      changeLanguage(language);

      history.replace(
        `/${language}${pathname.slice(3).length > 0 ? pathname.slice(3) : ''}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeLanguage, language]);

  const [isPoolingStarted, setPoolingStarted] = useState(false);

  if (
    !loadingLoyaltyProgramData &&
    data?.getSbmLoyalty?.blocks?.infoStatus === null &&
    !isPoolingStarted
  ) {
    console.log('LoyaltyProgram: Pooling started');
    setPoolingStarted(true);
    startPolling(3000);
  } else if (isPoolingStarted && data?.getSbmLoyalty?.blocks?.infoStatus) {
    console.log('LoyaltyProgram: Pooling finished');
    setPoolingStarted(false);
    stopPolling();
  }

  return (
    <>
      <PromoBanner />
      <Container tag="main" className={cx('root')} width={900}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        {(loadingLoyaltyProgramData || !data?.getSbmLoyalty) && <PageLoader />}

        {!loadingLoyaltyProgramData && !!data?.getSbmLoyalty?.blocks && (
          <LoyaltyProgramBlocks
            blocks={data?.getSbmLoyalty?.blocks}
            refetch={refetch}
          />
        )}

        {isFailSafeMode && <ErrorFallback mode={'friendly'} />}

        {!isFailSafeMode && (
          <GTM
            isMyAC
            userIdentity={userSbmIdentity}
            userPreferences={userSbmPreferences}
            userLoyalty={userSbmLoyalty}
            loyaltyStatus={loyaltyStatus}
            userGdpr={userSbmGdpr}
            loadingUserData={loadingUserData}
          />
        )}
      </Container>
    </>
  );
};

LoyaltyProgram.fragment = fragment;

export default withErrorBoundary(LoyaltyProgram);
