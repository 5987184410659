import { useMemo } from 'react';
import { func, object, bool } from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { latinLettersWithNumbersAwsRegexp } from '~/constants';

const propTypes = {
  children: func.isRequired,
  data: object.isRequired,
  oneClickSetPassword: bool,
};

const defaultProps = {
  oneClickSetPassword: false,
};

function FormSignUpOneclickHandler({ children, data, oneClickSetPassword }) {
  const { t } = useTranslation('auth');
  const validationRequiredFieldMessage = t(
    'create.validationRequiredFieldMessage'
  );
  const validationIncorrectEmailMessage = t(
    'create.validationIncorrectEmailMessage'
  );
  const validationPassCharsCountMessage = t(
    'create.validationPassCharsCountMessage'
  );
  const validationPassUppercaseMessage = t(
    'create.validationPassUppercaseMessage'
  );
  const validationPassLowercaseMessage = t(
    'create.validationPassLowercaseMessage'
  );
  const validationPassSpecialMessage = t('create.validationPassSpecialMessage');
  const validationLatinLettersOnlyMessage = t(
    'create.validationLatinLettersOnlyMessage'
  );

  const initialValues = useMemo(
    () => ({
      login: data?.email || '',
      password: '',
      ...(oneClickSetPassword && { gender: data?.gender }),
      ...(oneClickSetPassword && { name: data?.name }),
      ...(oneClickSetPassword && { familyName: data?.familyName }),
      ...(oneClickSetPassword && { marriedName: data?.marriedName }),
      ...(oneClickSetPassword && { birthDate: data?.birthDate }),
      ...(oneClickSetPassword && { nationality: data?.nationality }),
      ...(oneClickSetPassword && { phoneNumber: data?.phoneNumber }),
    }),
    [data, oneClickSetPassword]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        login: Yup.string()
          .email(validationIncorrectEmailMessage)
          .required(validationRequiredFieldMessage),
        password: Yup.string()
          .required(validationRequiredFieldMessage)
          .min(8, validationPassCharsCountMessage)
          .matches(/[A-ZЀ-ЯҐ\u4E00-\u9FFF]/, validationPassUppercaseMessage)
          .matches(/[a-zа-ўґ\u4E00-\u9FFF]/, validationPassLowercaseMessage)
          .matches(
            /[^A-ZЀ-ЯҐa-zа-ўґ\u4E00-\u9FFF0-9]/,
            validationPassSpecialMessage
          )
          .matches(
            latinLettersWithNumbersAwsRegexp,
            validationLatinLettersOnlyMessage
          ),
      }),
    [
      validationIncorrectEmailMessage,
      validationRequiredFieldMessage,
      validationPassCharsCountMessage,
      validationPassUppercaseMessage,
      validationPassLowercaseMessage,
      validationPassSpecialMessage,
      validationLatinLettersOnlyMessage,
    ]
  );

  return children({ validationSchema, initialValues });
}

FormSignUpOneclickHandler.propTypes = propTypes;
FormSignUpOneclickHandler.defaultProps = defaultProps;

export default FormSignUpOneclickHandler;
