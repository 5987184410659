import { useMemo } from 'react';
import moment from 'moment';

import { COUNTRIES } from 'common/constants';

import { UNDEFINED } from '../constants';

export function useIdentity(userIdentity, loadingUserData) {
  const userAge = useMemo(() => {
    if (userIdentity && !loadingUserData) {
      const years = moment().diff(userIdentity.birthDate, 'years');

      if (years <= 14) {
        return '0 - 14';
      }

      if (years <= 29) {
        return '15 - 29';
      }

      if (years <= 44) {
        return '30 - 44';
      }

      if (years <= 59) {
        return '45 - 59';
      }

      if (years <= 74) {
        return '60 - 74';
      }

      if (years <= 89) {
        return '75 - 89';
      }

      if (years >= 90) {
        return '90 and older';
      }

      return UNDEFINED;
    }
  }, [userIdentity, loadingUserData]);

  const userCountry = useMemo(() => {
    if (userIdentity?.countryOfResidence && !loadingUserData) {
      return COUNTRIES.find(({ id }) => id === userIdentity.countryOfResidence)
        .name;
    }

    return UNDEFINED;
  }, [userIdentity, loadingUserData]);

  return { userAge, uuid: userIdentity?.uuid, userCountry };
}
