// Provide a11y helper classes to detect source of focus event:
import focusSource from 'ally.js/amd/style/focus-source';

import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/zh-cn';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';

import MediaQueryListener from 'common/utils/MediaQueryListener';
import detectTouchevents from 'common/utils/detectTouchevents';
import {
  MYACC_LANGUAGE_WHITELIST,
  DIDOMI_COOKIES_ID_MAPPING,
} from 'common/constants';

import history from '~/history';
import initI18n from '~/i18n';
import { UserContextProvider } from '~/context/UserContext';
import PostMessageSBMListener from '~/containers/PostMessageSBMListener';
import SuspenseTemplate from '~/components/SuspenseTemplate';
import MyIDLayout from '~/scenes/MyIDLayout';
import MyAccLayout from '~/scenes/MyAccLayout';
import Maintenance from '~/pages/Maintenance';
import ContextHandler from '~/containers/ContextHandler';
import {
  AuthContextConsumer,
  LOADING_STATE,
  SIGN_IN_STATE,
  SIGNED_IN_STATE,
  MAINTENANCE_STATE,
} from '~/context/AuthContext';

const {
  REACT_APP_AWS_POOL_ID,
  REACT_APP_AWS_POOL_WEBCLIENT_ID,
  REACT_APP_MYACC_ENV,
} = process.env;

if (REACT_APP_MYACC_ENV === 'prod' || REACT_APP_MYACC_ENV === 'stage') {
  Sentry.init({
    dsn: 'https://9c7475023b47a88b35f3b8f6a4913892@o4505990784483328.ingest.sentry.io/4505990786580480',
    environment: REACT_APP_MYACC_ENV,
    sampleRate: 1.0, // 1.0 means 100% of transactions will be sent
    defaultIntegrations: false,
  });
}

// eslint-disable-next-line no-unused-vars
const [_, langChr1, langChr2] = window.location.pathname;
const initialLang = MYACC_LANGUAGE_WHITELIST.includes(langChr1 + langChr2)
  ? langChr1 + langChr2
  : localStorage.getItem('i18nextLng');
initI18n(initialLang);
focusSource();
detectTouchevents();
new MediaQueryListener();

// Didomi Cookie manager
const didomiScriptElement = document.createElement('script');
didomiScriptElement.text = `!function(e,t){e.gdprAppliesGlobally=!0,function(t,r,a,n,i){function s(t,a,n,s){if("function"==typeof n){e[r]||(e[r]=[]);var c=!1;i&&(c=i(t,s,n)),c||e[r].push({command:t,version:a,callback:n,parameter:s})}}function c(r){if(e[t]&&!0===e[t].stub&&r.data){var i,s="string"==typeof r.data;try{i=s?JSON.parse(r.data):r.data}catch(e){return}if(i[a]){var c=i[a];e[t](c.command,c.version,(function(e,t){var a={};a[n]={returnValue:e,success:t,callId:c.callId},r.source.postMessage(s?JSON.stringify(a):a,"*")}),c.parameter)}}}s.stub=!0,s.stubVersion=2,"function"!=typeof e[t]&&(e[t]=s,e.addEventListener?e.addEventListener("message",c,!1):e.attachEvent("onmessage",c))}("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn"),function r(a){if(!e.frames[a])if(t.body&&t.body.firstChild){var n=t.body,i=t.createElement("iframe");i.style.display="none",i.name=a,i.title=a,n.insertBefore(i,n.firstChild)}else setTimeout((function(){r(a)}),5)}("__tcfapiLocator"),function(r,a){var n=t.createElement("link");n.rel="preconnect",n.as="script";var i=t.createElement("link");i.rel="dns-prefetch",i.as="script";var s=t.createElement("link");s.rel="preload",s.as="script";var c=t.createElement("script");c.id="spcloader",c.type="text/javascript",c.async=!0,c.charset="utf-8";var o="https://sdk.privacy-center.org/1bf635c6-e8a7-4558-9594-b4294a0020e6/loader.js?target_type=notice&target=${DIDOMI_COOKIES_ID_MAPPING[initialLang]}";if(e.didomiConfig&&e.didomiConfig.user){var f=e.didomiConfig.user,d=f.country,p=f.region;d&&(o=o+"&country="+d,p&&(o=o+"&region="+p))}n.href="https://sdk.privacy-center.org/",i.href="https://sdk.privacy-center.org/",s.href=o,c.src=o;var l=t.getElementsByTagName("script")[0];l.parentNode.insertBefore(n,l),l.parentNode.insertBefore(i,l),l.parentNode.insertBefore(s,l),l.parentNode.insertBefore(c,l)}()}(window,document);`;
document.head.appendChild(didomiScriptElement);

// AWS initial config
Auth.configure({
  userPoolId: REACT_APP_AWS_POOL_ID,
  userPoolWebClientId: REACT_APP_AWS_POOL_WEBCLIENT_ID,
});

function App() {
  return (
    <Suspense fallback={<SuspenseTemplate />}>
      <ContextHandler>
        <PostMessageSBMListener>
          <AuthContextConsumer>
            {({ authState }) => (
              <>
                {authState === LOADING_STATE && <SuspenseTemplate />}
                {authState === MAINTENANCE_STATE && <Maintenance />}
                {authState === SIGN_IN_STATE && (
                  <Router history={history}>
                    <MyIDLayout />
                  </Router>
                )}
                {authState === SIGNED_IN_STATE && (
                  <Router history={history}>
                    <UserContextProvider>
                      <MyAccLayout />
                    </UserContextProvider>
                  </Router>
                )}
              </>
            )}
          </AuthContextConsumer>
        </PostMessageSBMListener>
      </ContextHandler>
    </Suspense>
  );
}

export default App;
