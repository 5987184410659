import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { gql } from '@apollo/client';

import HighlightedText from 'common/components/HighlightedText';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import Card from '../Card';

const { REACT_APP_MYACC_DOMAIN } = process.env;
const homeOfferCardFragment = gql`
  fragment homeOfferCardFragment on Offer {
    title
    ctaLabel
    myAccountCtaLabel
    myAccountSubtitle
    myAccountTitle {
      value
      index
    }
    url
    id
    media(first: 1, after: 0) {
      ...cardMediaFragment
    }
  }
  ${Card.fragments.cardMediaFragment}
`;

const propTypes = {
  data: propType(homeOfferCardFragment).isRequired,
  isStretch: bool,
  className: string,
};

const defaultProps = {
  isStretch: false,
  className: '',
};

const OfferCard = function({
  data: {
    title,
    myAccountTitle,
    myAccountSubtitle,
    url,
    ctaLabel,
    myAccountCtaLabel,
    media,
    id,
  },
  isStretch,
  className,
}) {
  const cardData = {
    ctaLink: `${REACT_APP_MYACC_DOMAIN}/offers/${id}`,
    ctaLabel: myAccountCtaLabel || ctaLabel,
    globalLink: true,
    ctaInverted: true,
    bgImg: media[0],
  };

  const cardTitle = useMemo(
    function() {
      return (
        myAccountTitle || {
          index: 0,
          value: title,
        }
      );
    },
    [myAccountTitle, title]
  );

  return (
    <Card key={id} data={cardData} isStretch={isStretch} className={className}>
      <h3>{myAccountSubtitle}</h3>
      <h2>
        <HighlightedText index={cardTitle.index}>
          {cardTitle.value}
        </HighlightedText>
      </h2>
    </Card>
  );
};

OfferCard.propTypes = propTypes;
OfferCard.defaultProps = defaultProps;
OfferCard.fragments = {
  homeOfferCardFragment,
};

export default withErrorBoundary(OfferCard);
