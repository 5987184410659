import React from 'react';
import { bool, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { gql } from '@apollo/client';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import Card from '../Card';

const topCardFragment = gql`
  fragment topCardFragment on MyAccountHomepage {
    topBlockButton {
      text
      url
    }
    topBlockTitle
    topBlockImage {
      ...cardMediaFragment
    }
    topBlockSubtitle
  }
  ${Card.fragments.cardMediaFragment}
`;

const propTypes = {
  data: propType(topCardFragment).isRequired,
  isStretch: bool,
  className: string,
};

const defaultProps = {
  isStretch: false,
  className: '',
};

const TopCard = ({
  data: {
    topBlockButton: { text, url },
    topBlockImage,
    topBlockSubtitle,
    topBlockTitle,
  },
  isStretch,
  className,
}) => {
  const cardData = {
    ctaLink: url,
    ctaLabel: text,
    globalLink: true,
    ctaInverted: true,
    bgImg: topBlockImage && topBlockImage[0],
  };

  return (
    <Card
      key="top-block"
      data={cardData}
      className={className}
      isStretch={isStretch}
    >
      <h3>{topBlockSubtitle}</h3>
      <h2>{topBlockTitle}</h2>
    </Card>
  );
};

TopCard.propTypes = propTypes;
TopCard.defaultProps = defaultProps;
TopCard.fragments = {
  topCardFragment,
};

export default withErrorBoundary(TopCard);
