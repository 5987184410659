import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import HighlightedText from 'common/components/HighlightedText';

import styles from './AuthFormTitle.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  title: string.isRequired,
  subtitle: string,
  index: string,
};

const defaultProps = {
  className: '',
  index: '',
  subtitle: '',
};

const AuthFormTitle = ({ className, title, index, subtitle }) => (
  <div className={cx('root')}>
    <strong className={cx('title', className)}>
      {index ? <HighlightedText index={index}>{title}</HighlightedText> : title}
    </strong>
    {subtitle && <p className={cx('subtitle')}>{subtitle}</p>}
  </div>
);

AuthFormTitle.propTypes = propTypes;
AuthFormTitle.defaultProps = defaultProps;

export default AuthFormTitle;
