import React from 'react';
import { string } from 'prop-types';

import styles from './BlockTitle.module.scss';

const propTypes = {
  title: string.isRequired,
  tag: string,
};

const defaultProps = {
  tag: 'h2',
};

const BlockTitle = ({ title, tag: Tag }) => (
  <Tag className={styles.BlockTitle}>{title}</Tag>
);

BlockTitle.propTypes = propTypes;
BlockTitle.defaultProps = defaultProps;

export default BlockTitle;
