import React from 'react';
import { oneOfType, string, bool, func, node, object, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import MyAccLink from '~/components/MyAccLink';

import styles from './Button.module.scss';

const cx = classNames.bind(styles);

Button.propTypes = {
  children: node.isRequired,
  className: string,
  disabled: bool,
  inverted: bool,
  onClick: func,
  color: oneOf(['tallow', 'light-brown', 'tallow-invert', 'tallow-black']),
  theme: oneOf(['rounded']),
  tag: oneOfType([func, string]),
  to: oneOfType([string, object]),
  // to be added if needed with next patterns: xxl, xl, l, m, s, xs, xxs
  size: oneOf(['l', 'm', 's']),
};

Button.defaultProps = {
  className: null,
  disabled: false,
  inverted: false,
  theme: null,
  tag: 'button',
  color: 'tallow',
  onClick: null,
  to: null,
  size: 'm',
};

Button.shared = {
  hovered: cx('hover-wrapper'),
};

export default function Button(props) {
  let {
    size,
    className,
    disabled,
    inverted,
    theme,
    color,
    onClick,
    tag: Tag,
    to,
    ...attributes
  } = props;

  if (to && Tag === 'button') {
    Tag = MyAccLink;
  }

  return (
    <Tag
      type={Tag === 'button' && onClick ? 'button' : undefined}
      {...(Tag === 'a' ? { href: to } : { to })}
      disabled={disabled}
      className={cx(
        className,
        'root',
        styles[`btn-${color}`],
        styles[`size-${size}`],
        {
          'root-inverted': inverted,
          [`theme-${theme}`]: theme,
        }
      )}
      onClick={onClick}
      {...attributes}
    />
  );
}
