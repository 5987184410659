import React, { useCallback } from 'react';
import { object } from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import classNames from 'classnames/bind';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';

import Select from 'common/components/FormComponents/Select';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';
import InformationPanel from 'common/components/InformationPanel';

import useCountryNameKey from '~/hooks/useCountryNameKey';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';
import FormButtons from '~/components/FormButtons';

import SelectProfession from '~/components/FormComponents/SelectProfession';
import FormIdentityRegionsHandler from '../../containers/FormIdentityRegionsHandler';

import styles from './FormIdentityRegions.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  data: object.isRequired,
};

const FormIdentityRegions = ({ data }) => {
  const countryNameKey = useCountryNameKey();
  const prioritisedCountries = usePrioritisedCountries();

  const { t } = useTranslation(['identity-form', 'common']);
  const nationalityFieldLabel = t('identity-form:nationalityFieldLabel');
  const countryOfResidenceFieldLabel = t(
    'identity-form:countryOfResidenceFieldLabel'
  );
  const messageError = t('identity-form:messageError');
  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const professionLabel = t('common:professionLabel');

  const increaseNationalitiesHandler = useCallback(
    ({ index, push, resetMessage, nationalitiesFieldsCount }) =>
      index < 2 &&
      nationalitiesFieldsCount - 1 === index &&
      nationalitiesFieldsCount < prioritisedCountries.length
        ? () => {
            resetMessage();
            push('');
          }
        : null,
    [prioritisedCountries]
  );

  const decreaseNationalitiesHandler = useCallback(
    ({
      name,
      index,
      resetMessage,
      nationalitiesFieldsCount,
      setFieldValue,
      remove,
    }) =>
      nationalitiesFieldsCount === 1
        ? () => {
            resetMessage();
            setFieldValue(name, ['']);
          }
        : () => {
            resetMessage();
            remove(index);
          },
    []
  );

  return (
    <FormIdentityRegionsHandler data={data}>
      {({
        initialValues,
        handleSubmit,
        submitError,
        validationSchema,
        submitSuccess,
        resetMessage,
      }) => (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form className={cx('form')} onChange={resetMessage}>
              <FieldArray name="nationalities">
                {({ name, push, remove }) => {
                  const nationalitiesFieldsCount = values[name].length;

                  return values[name].map((item, index) => {
                    const id = uniqueId();

                    return (
                      <div
                        className={cx('field-wrap', 'nationalities')}
                        key={id}
                      >
                        <Field
                          name={`nationalities.${index}`}
                          classNameWrapper={cx('select-field-wrap')}
                          valuesArrayName={name}
                          valuesArrayNameIndex={index}
                          component={Select}
                          options={prioritisedCountries}
                          customKeys={['id', countryNameKey]}
                          placeholder={nationalityFieldLabel}
                          isCustomChange
                        />
                        <MultiFieldCta
                          className={cx('modifiers')}
                          onIncrease={increaseNationalitiesHandler({
                            index,
                            nationalitiesFieldsCount,
                            resetMessage,
                            push,
                          })}
                          onRemove={decreaseNationalitiesHandler({
                            name,
                            remove,
                            nationalitiesFieldsCount,
                            index,
                            setFieldValue,
                            resetMessage,
                          })}
                        />
                      </div>
                    );
                  });
                }}
              </FieldArray>
              <Field
                name={'countryOfResidence'}
                component={Select}
                options={prioritisedCountries}
                placeholder={countryOfResidenceFieldLabel}
                customKeys={['id', countryNameKey]}
              />
              <Field
                name="occupation"
                component={SelectProfession}
                placeholder={professionLabel}
              />
              {submitError && (
                <InformationPanel withIcon errorMode>
                  {messageError}
                </InformationPanel>
              )}
              <FormButtons
                isSubmitting={isSubmitting}
                isSaved={submitSuccess}
                className={cx('form-buttons')}
                savedButtonLabel={savedButtonLabel}
                saveButtonLabel={saveButtonLabel}
              />
            </Form>
          )}
        </Formik>
      )}
    </FormIdentityRegionsHandler>
  );
};

FormIdentityRegions.propTypes = propTypes;

export default FormIdentityRegions;
