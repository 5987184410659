import { useCallback } from 'react';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import { CONSENT } from '~/constants';

const pushSubscription = function (id) {
  if (id === 1) {
    gtmPushEvent({
      event: 'sign_up_newsletter',
    });
  }
  if (id === 2) {
    gtmPushEvent({
      event: 'sign_up_email_offers',
    });
  }
  if (id === 3) {
    gtmPushEvent({
      event: 'sign_up_email_partner',
    });
  }
};
const pushUnsubscription = function (id) {
  if (id === 1) {
    gtmPushEvent({
      event: 'unsubscription_newsletter',
    });
  }
  if (id === 2) {
    gtmPushEvent({
      event: 'unsubscription_email_offers',
    });
  }
  if (id === 3) {
    gtmPushEvent({
      event: 'unsubscription_partner',
    });
  }
};

export default function useGtmPrivacy({ initialValues }) {
  const gtmSendSubscriptions = useCallback(
    (fields) => {
      fields.forEach(({ id, value }) => {
        if (value !== initialValues[id]) {
          if (value === CONSENT.yes) {
            pushSubscription(id);
          }
          if (value === CONSENT.no) {
            pushUnsubscription(id);
          }
        }
      });
    },
    [initialValues]
  );

  return { gtmSendSubscriptions };
}
