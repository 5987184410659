import React, { useEffect, useRef } from 'react';

const events = ['mousedown', 'touchstart'];

function useOnClickOutside(handler) {
  const ref = useRef();
  useEffect(
    () => {
      if (!(typeof handler === 'function')) {
        return;
      }

      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements or it's SSR env
        if (
          typeof window === 'undefined' ||
          !ref.current ||
          ref.current.contains(event.target)
        ) {
          return;
        }

        handler(event);
      };

      events.forEach((eventName) => {
        document.addEventListener(eventName, listener);
      });

      return () => {
        events.forEach((eventName) => {
          document.removeEventListener(eventName, listener);
        });
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
  return ref;
}

const withOnClickOutside =
  (Component) =>
  ({ handleClickOutside, disableOnClickOutside, ...props }) => {
    const clickRef = useOnClickOutside(handleClickOutside);

    if (disableOnClickOutside) return <Component {...props} />;

    return (
      <div ref={clickRef}>
        <Component {...props} />
      </div>
    );
  };

export { withOnClickOutside };
export default useOnClickOutside;
