import React, { useRef } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconEye } from 'common/media/icons/eye.icon.svg';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';

import styles from './Notification.module.scss';

const cx = classNames.bind(styles);

const NEW = 'New';
const SEEN = 'Seen';
const DELETED = 'Deleted';

const propTypes = {
  id: string.isRequired,
  status: string.isRequired,
  text: string.isRequired,
  date: string.isRequired,
  handleUpdateNotification: func.isRequired,
  loadingRefetchNotifications: bool.isRequired,
};

function Notification({
  id,
  status,
  text,
  date,
  handleUpdateNotification,
  loadingRefetchNotifications,
}) {
  const itemContainerRef = useRef(null);

  const itemContainerHeight = itemContainerRef.current?.clientHeight;
  const itemDeletionStyles = {
    height: itemContainerHeight,
    opacity: 0,
    transform: `translateY(-${itemContainerHeight}px)`,
  };

  return (
    <div
      ref={itemContainerRef}
      className={cx('item-wrapper', `status-${status.toLowerCase()}`)}
      style={status === DELETED ? itemDeletionStyles : null}
    >
      <p className={cx('text')}>
        <span dangerouslySetInnerHTML={{ __html: text }} />
      </p>
      <p className={cx('date')}>{date}</p>
      <button
        className={cx('button-close')}
        type="button"
        disabled={loadingRefetchNotifications}
        onClick={(event) =>
          handleUpdateNotification({
            event,
            id,
            status: DELETED,
          })
        }
      >
        <IconContainer icon={IconClose} />
      </button>
      {/* Mark as seen. Hidden potential functionality. */}
      {false && status === NEW && (
        <button
          className={cx('button-seen')}
          type="button"
          disabled={loadingRefetchNotifications}
          onClick={() => handleUpdateNotification({ id, status: SEEN })}
        >
          <IconContainer icon={IconEye} />
        </button>
      )}
    </div>
  );
}

Notification.propTypes = propTypes;

export default Notification;
