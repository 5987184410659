import React, { useState } from 'react';
import { object } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import RadioButtonsContainer from 'common/components/FormComponents/RadioButtonsContainer';
import Radio from 'common/components/FormComponents/Radio';
import Input from 'common/components/FormComponents/Input';
import SelectDate from 'common/components/FormComponents/SelectDate';
import InformationPanel from 'common/components/InformationPanel';
import Button from 'common/components/Button';

import FormButtons from '~/components/FormButtons';

import FormIdentityHandler from '../../containers/FormIdentityHandler';

import styles from './FormIdentity.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  data: object.isRequired,
};

function FormIdentity({ data }) {
  const { t } = useTranslation(['identity-form', 'common']);
  const firstNameFieldLabel = t('identity-form:firstNameFieldLabel');
  const middleNameFieldLabel = t('identity-form:middleNameFieldLabel');
  const birthNameFieldLabel = t('identity-form:birthNameFieldLabel');
  const marriedNameFieldLabel = t('identity-form:marriedNameFieldLabel');
  const birthDateFieldLabel = t('identity-form:birthDateFieldLabel');
  const fieldDateYearLabel = t('common:fieldDateYearLabel');
  const fieldDateMonthLabel = t('common:fieldDateMonthLabel');
  const fieldDateDayLabel = t('common:fieldDateDayLabel');
  const messageError = t('identity-form:messageError');
  const genderLabel = t('identity-form:genderLabel');
  const unmutableFieldsDescription = t(
    'identity-form:unmutableFieldsDescription'
  );
  const unmutableFieldsSubmitedDescription = t(
    'identity-form:unmutableFieldsSubmitedDescription'
  );
  const requestButtonLabel = t('identity-form:requestButtonLabel');
  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  const [isUserWarned, setUserWarned] = useState(false);

  return (
    <FormIdentityHandler data={data}>
      {({
        genderList,
        validationSchema,
        initialValues,
        handleSubmit,
        submitError,
        submitSuccess,
        resetMessage,
      }) => (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, resetForm }) => (
            <Form className={cx('form')} onChange={resetMessage}>
              <RadioButtonsContainer
                className={cx('radios-wrapper')}
                label={genderLabel}
              >
                <div className={cx('switcher')}>
                  {genderList.map(({ value, label }) => (
                    <Field
                      key={value}
                      name="gender"
                      component={Radio}
                      id={value}
                      label={label}
                      smallStyledView
                      disabled={!isUserWarned}
                    />
                  ))}
                </div>
              </RadioButtonsContainer>
              <Field
                name="firstName"
                component={Input}
                placeholder={firstNameFieldLabel}
                required
                maxLength={30}
                autoComplete="firstName"
                disabled={!isUserWarned}
              />
              <Field
                name="middleName"
                component={Input}
                placeholder={middleNameFieldLabel}
                maxLength={30}
                autoComplete="middleName"
                disabled={!isUserWarned}
              />
              <Field
                name="birthName"
                component={Input}
                placeholder={birthNameFieldLabel}
                required
                maxLength={30}
                autoComplete="birthName"
                disabled={!isUserWarned}
              />
              <Field
                name="marriedName"
                component={Input}
                placeholder={marriedNameFieldLabel}
                maxLength={30}
                autoComplete="marriedName"
                disabled={!isUserWarned}
              />
              <Field
                name="birthDate"
                component={SelectDate}
                className={cx('birthday-select-wrapper')}
                itemClassName={cx('select-item')}
                dateFormat={'YYYY-MM-DD'}
                placeholder={birthDateFieldLabel}
                placeholderYear={fieldDateYearLabel}
                placeholderMonth={fieldDateMonthLabel}
                placeholderDay={fieldDateDayLabel}
                maxLength={254}
                autoComplete="birthDate"
                lastAvailableDate={moment()
                  .subtract(18, 'years')
                  .format('YYYY-MM-DD')}
                required
                isCleanable={false}
                disabled={!isUserWarned}
              />
              {!isUserWarned && !submitSuccess && (
                <div
                  className={cx('unmutable-description')}
                  dangerouslySetInnerHTML={{
                    __html: unmutableFieldsDescription,
                  }}
                />
              )}

              {submitError && (
                <InformationPanel withIcon errorMode>
                  {messageError}
                </InformationPanel>
              )}

              {!isUserWarned && !submitSuccess && (
                <Button
                  type="button"
                  className={cx('button-request')}
                  onClick={() => {
                    setUserWarned(true);
                  }}
                >
                  <span className={cx('button-label')}>
                    {requestButtonLabel}
                  </span>
                </Button>
              )}
              {isUserWarned && !submitSuccess && (
                <FormButtons
                  isSubmitting={isSubmitting}
                  onClickCancelButton={() => {
                    setUserWarned(false);
                    resetForm();
                  }}
                  className={cx('form-buttons')}
                  savedButtonLabel={savedButtonLabel}
                  saveButtonLabel={saveButtonLabel}
                  cancelButtonLabel={cancelButtonLabel}
                />
              )}
              {isUserWarned && submitSuccess && (
                <div
                  className={cx('unmutable-submited-description')}
                  dangerouslySetInnerHTML={{
                    __html: unmutableFieldsSubmitedDescription,
                  }}
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </FormIdentityHandler>
  );
}

FormIdentity.propTypes = propTypes;

export default FormIdentity;
