import React, { useState, useEffect, useRef } from 'react';
import { bool, func, number, string, array } from 'prop-types';
import { momentObj } from 'react-moment-proptypes';
import classNames from 'classnames/bind';
import moment from 'moment';
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { START_DATE, END_DATE } from 'react-dates/constants';
import { inject } from 'mobx-react';
import flowRight from 'lodash/flowRight';
import uniqBy from 'lodash/uniqBy';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconLeftArrow } from 'common/media/icons/left-arrow.icon.svg';
import { ReactComponent as IconRightArrow } from 'common/media/icons/right-arrow.icon.svg';
import { isBeforeDay } from 'common/utils/dates';
import { withDatesContext } from 'common/context/DatesContext';

import DayContents from './DayContents';
import styles from './DayPickerRangeControllerWrapper.module.scss';

const cx = classNames.bind(styles);

const { REACT_APP_MYACC_ENV } = process.env;

const propTypes = {
  calendarData: array,
  availableOnly: bool,
  loadMore: func,
  setIsLoading: func,
  anyDateButtonLabel: string,
  applyDatesButtonLabel: string,
  datesEndRange: string,
  datesStartRange: string,
  focusedInput: string,
  initialEndDate: momentObj,
  initialStartDate: momentObj,
  isFixedRange: bool,
  numberOfMonths: number,
  toggleDatesTouchedState: func,
  onDatesChange: func,
  onDatesSelected: func,
  onFocusChange: func,
  onOutsideClick: func,
  minimumNights: number,
  className: string,
  isMobile: bool,
  minStayRequirement: string,
};

const defaultProps = {
  calendarData: [],
  availableOnly: false,
  loadMore: null,
  setIsLoading: null,
  anyDateButtonLabel: null,
  applyDatesButtonLabel: null,
  datesEndRange: null,
  datesStartRange: null,
  focusedInput: START_DATE,
  initialEndDate: null,
  initialStartDate: null,
  isFixedRange: false,
  numberOfMonths: 2,
  toggleDatesTouchedState: null,
  onDatesChange: null,
  onDatesSelected: null,
  onFocusChange: null,
  onOutsideClick: () => {},
  minimumNights: 1,
  className: null,
  isMobile: null,
  minStayRequirement: 'minimum nights',
};

const DayPickerRangeControllerWrapper = ({
  anyDateButtonLabel,
  applyDatesButtonLabel,
  datesEndRange,
  datesStartRange,
  focusedInput: focusedInputProp,
  initialEndDate,
  initialStartDate,
  isFixedRange,
  numberOfMonths,
  toggleDatesTouchedState,
  isDatesSelected,
  onDatesChange: onDatesChangeProp,
  onDatesSelected,
  onFocusChange: onFocusChangeProp,
  onOutsideClick,
  minimumNights,
  datesStore, // @todo: to remove, datesStore here is just for a backward capability
  dates,
  calendarData,
  loadMore,
  setIsLoading,
  className,
  isMobile,
  availableOnly,
  minStayRequirement,
}) => {
  const dayPickerRef = useRef(null);
  const minimumNightsTooltipRef = useRef(null);
  const minimumNightsTooltipBaseRef = useRef(null);
  const [minimumNightsTooltipStyles, setMinimumNightsTooltipStyles] = useState(
    {}
  );

  const currentMonth = moment().endOf('month');
  const isOutsideCurrentMonth = (date) => {
    return date <= currentMonth;
  };

  const [startDate, setStartDate] = useState(initialStartDate.clone());
  const [endDate, setEndDate] = useState(
    initialEndDate ? initialEndDate.clone() : startDate
  );
  const nightsCount = moment(endDate).diff(moment(startDate), 'days') || 1; // Int | NaN

  const [focusedInput, setFocusedInput] = useState(focusedInputProp);
  const [isPrevDisabled, setPrevDisabledStatus] = useState(
    isOutsideCurrentMonth(initialStartDate)
  );

  const [isMinimumNightsTooltipVisible, setMinimumNightsTooltipVisible] =
    useState(true);
  useEffect(() => {
    setMinimumNightsTooltipVisible(true);
  }, [startDate]);

  const onFocusChange = (focusedInput) => {
    if (focusedInput) {
      setFocusedInput(focusedInput);
    } else {
      setFocusedInput(
        !focusedInput || focusedInput !== START_DATE ? START_DATE : END_DATE
      );
    }

    if (onFocusChangeProp) {
      onFocusChangeProp(focusedInput);
    }
  };

  const onDatesChange = (data) => {
    const startChange = !startDate.isSame(data.startDate);
    let checkEndDate = data.endDate
      ? data.endDate
      : data.startDate.clone().add(1, 'days');
    if (isFixedRange) {
      checkEndDate = data.startDate.clone().add(minimumNights, 'days');
    }

    if (!!calendarData) {
      const fDate = data.startDate.format('YYYY-MM-DD');
      const tDate = checkEndDate.format('YYYY-MM-DD');

      const firstDay = calendarData.findIndex(({ date }) => date === fDate);
      const lastDay = calendarData.findIndex(({ date }) => date === tDate);
      // not include lastDay, bacause it can be closed (check out only)
      const selectedDates = calendarData.slice(firstDay, lastDay);
      if (selectedDates.every(({ available }) => available === true)) {
        setStartDate(data.startDate);
        setEndDate(data.endDate);
        onFocusChange(startChange ? END_DATE : undefined);
      } else if (startChange && calendarData[firstDay].available) {
        data.endDate = data.startDate.clone().add(1, 'days');
        setStartDate(data.startDate);
        setEndDate();
        onFocusChange(END_DATE);
      } else {
        return null;
      }
    } else {
      setStartDate(data.startDate);
      setEndDate(data.endDate);
      onFocusChange(startChange ? END_DATE : START_DATE);
    }

    if (onDatesSelected && endDate) {
      onDatesSelected();
    }

    if (onDatesChangeProp) {
      onDatesChangeProp({
        startDate: data.startDate,
        endDate: data.endDate,
      });
    }
  };

  const isOutsideRange = (day) => {
    return (
      isBeforeDay(day, moment()) ||
      (datesStore?.minDateFrom && isBeforeDay(day, datesStore.minDateFrom)) ||
      (dates?.minDateFrom && isBeforeDay(day, dates.minDateFrom)) ||
      (datesStartRange && isBeforeDay(day, moment(datesStartRange))) ||
      (datesEndRange && !isBeforeDay(day, moment(datesEndRange)))
    );
  };

  const isDayAvailable = (m) => {
    const day = calendarData.find(
      (item) => item.date === m.format('YYYY-MM-DD')
    );

    // Check if the date in past and not actual anymore for calendar
    const current = moment().startOf('day');
    const isDayActual = moment.duration(m.diff(current)).asDays() >= 0;

    if (!isDayActual) {
      return false;
    }

    if (!!day) {
      return day.available;
    }

    return !availableOnly;
  };

  const isDayBlocked = (m) => {
    const dayAvailable = isDayAvailable(m);

    if (dayAvailable) {
      return false;
    }

    const prevDay = moment(m).subtract(1, 'days');
    const prevDayAvailable = isDayAvailable(prevDay);
    return !prevDayAvailable;
  };

  useEffect(() => {
    if (
      dayPickerRef?.current &&
      minimumNightsTooltipRef?.current &&
      minimumNightsTooltipBaseRef?.current
    ) {
      const containerRect = dayPickerRef?.current.getBoundingClientRect();
      const toAlignRect =
        minimumNightsTooltipRef?.current.getBoundingClientRect();
      const baseRect =
        minimumNightsTooltipBaseRef?.current.getBoundingClientRect();

      // Calculate the ideal center position of the toAlign element
      let centerPosition =
        baseRect.left + baseRect.width / 2 - toAlignRect.width / 2;

      // Calculate the bounds of the container
      const containerLeft = containerRect.left;
      const containerRight = containerRect.right;

      // Determine the final position
      let finalPosition = centerPosition;

      // If the toAlign element goes outside the container bounds, adjust it
      if (finalPosition < containerLeft) {
        finalPosition = containerLeft + 6;
      } else if (finalPosition + toAlignRect.width > containerRight) {
        finalPosition = containerRight - toAlignRect.width - 6;
      }

      setMinimumNightsTooltipStyles({
        left: `${Math.round(finalPosition - baseRect.left)}px`,
        transform: 'translateX(0)',
      });
    }
  }, [moment(startDate).format('YYYY-DD-MM')]);

  const onPrevMonthClick = (date) => {
    setPrevDisabledStatus(isOutsideCurrentMonth(date));

    if (loadMore) {
      setIsLoading(true);

      const today = moment().startOf('day');
      const prevMonthDate = moment(date).clone().subtract(60, 'days');

      const fromDate = moment(prevMonthDate).isAfter(today)
        ? prevMonthDate.format('YYYY-MM-DD')
        : today.format('YYYY-MM-DD');

      const toDate = moment(date).isAfter(today)
        ? moment(date).clone().add(30, 'days').format('YYYY-MM-DD')
        : today.clone().add(30, 'days').format('YYYY-MM-DD');

      if (
        !!calendarData.length &&
        !calendarData.find((day) => day.date === fromDate)
      ) {
        loadMore({
          variables: {
            fromDate: fromDate,
            toDate: toDate,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...fetchMoreResult,
              calendar: {
                ...fetchMoreResult.calendar,
                dates: uniqBy(
                  [...fetchMoreResult.calendar.dates, ...prev.calendar.dates],
                  'date'
                ),
              },
            };
          },
        }).finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  };

  const onNextMonthClick = (date) => {
    setPrevDisabledStatus(isOutsideCurrentMonth(date));

    if (loadMore) {
      setIsLoading(true);

      const fromDate = !!calendarData.length
        ? calendarData[calendarData.length - 1].date
        : moment(date).clone().add(30, 'days').format('YYYY-MM-DD');

      const toDate = moment(date).clone().add(90, 'days').format('YYYY-MM-DD');

      if (
        !moment(toDate).isBefore(fromDate) &&
        !moment(toDate).isSame(fromDate)
      ) {
        loadMore({
          variables: {
            fromDate: fromDate,
            toDate: toDate,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return {
              ...prev,
              calendar: {
                ...prev.calendar,
                dates: [
                  ...prev.calendar.dates,
                  ...fetchMoreResult.calendar.dates,
                ],
              },
            };
          },
        }).finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    setFocusedInput(focusedInputProp);
  }, [focusedInputProp]);

  return (
    <div
      ref={dayPickerRef}
      className={cx(
        'root',
        {
          'prev-disabled': isPrevDisabled,
          'with-min-stay-notification':
            nightsCount && minimumNights > 1 && nightsCount < minimumNights,
        },
        className
      )}
    >
      <DayPickerRangeController
        keepOpenOnDateSelect={false}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        numberOfMonths={numberOfMonths}
        enableOutsideDays={false}
        onOutsideClick={onOutsideClick}
        isOutsideRange={isOutsideRange}
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        renderDayContents={(m) =>
          DayContents({
            m,
            isDayAvailable,
            isDayBlocked,
            startDate,
            calendarData,
            minimumNights,
            nightsCount,
            minimumNightsTooltipBaseRef,
            minimumNightsTooltipRef,
            minimumNightsTooltipStyles,
            minStayRequirement,
            isMinimumNightsTooltipVisible,
            setMinimumNightsTooltipVisible,
          })
        }
        daySize={isMobile ? 36 : 48}
        navPrev={<IconContainer icon={IconLeftArrow} />}
        navNext={<IconContainer icon={IconRightArrow} />}
        renderWeekHeaderElement={(day) => day[0]}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
        minimumNights={minimumNights}
        isDayBlocked={isDayBlocked}
      />
      {toggleDatesTouchedState && (
        <button
          className={cx('any-day-button')}
          onClick={toggleDatesTouchedState}
        >
          {isDatesSelected ? anyDateButtonLabel : applyDatesButtonLabel}
        </button>
      )}
    </div>
  );
};

DayPickerRangeControllerWrapper.propTypes = propTypes;
DayPickerRangeControllerWrapper.defaultProps = defaultProps;

export default flowRight(
  inject(() => (!REACT_APP_MYACC_ENV ? 'datesStore' : null)),
  withDatesContext
)(DayPickerRangeControllerWrapper);
