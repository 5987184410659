import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery, gql } from '@apollo/client';

import { ReactComponent as SocialCopyIcon } from 'common/media/icons/copy.icon.svg';
import { ReactComponent as SocialMailIcon } from 'common/media/icons/social-letter.icon.svg';
import { ReactComponent as SocialSMSIcon } from 'common/media/icons/social-sms.icon.svg';
import { ReactComponent as SocialWhatsAppIcon } from 'common/media/icons/social-whatsapp.icon.svg';
import { ReactComponent as SocialMessengerIcon } from 'common/media/icons/social-messenger.icon.svg';
import { ReactComponent as SocialFacebookIcon } from 'common/media/icons/social-facebook-rect.icon.svg';
import { UserChangeWishlistVisibilityMutation } from 'common/queries';

import { useUserContext } from '~/context/UserContext';
import { useLocalisationContext } from '~/context/LocalisationContext';

const { REACT_APP_MYACC_DOMAIN } = process.env;

const WishlistIdsDataQuery = gql`
  query WishlistIdsData {
    getWishlist {
      published
      hash
      items
    }
  }
`;

const WishlistModalHandler = ({ children }) => {
  const { t } = useTranslation('wishlist');

  const { wishlistIds, getWishlistIdsData } = useUserContext();
  const { language } = useLocalisationContext();

  const [changeWishlistVisibility] = useMutation(
    UserChangeWishlistVisibilityMutation
  );

  const [getWishlistIdsDataMutation] = useLazyQuery(WishlistIdsDataQuery, {
    fetchPolicy: 'no-cache',
  });

  const [wishlistPublished, setWishlistPublished] = useState(
    wishlistIds.published
  );
  const [wishlistPublishLoading, setWishlistPublishLoading] = useState(false);
  const [isPublishStatusUpdated, setPublishStatusUpdated] = useState(false);
  const [wishlistHash, setWishlistHash] = useState(wishlistIds.hash);

  const [sharedButtons, setSharedButtons] = useState([]);

  useEffect(() => {
    return () => {
      // Update list data on modal close if publish status changed
      if (isPublishStatusUpdated) {
        getWishlistIdsData();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishStatusUpdated]);

  const getSharedWishlistLink = useCallback(
    (newHash) => {
      if (!wishlistHash) {
        return '';
      }

      return `${REACT_APP_MYACC_DOMAIN}/${language}/wishlist/?unique_share_id=${
        newHash || wishlistHash
      }`;
    },
    [language, wishlistHash]
  );

  const handleShareClick = (type, callback) => {
    if (!wishlistPublished) {
      setWishlistPublishLoading(true);

      changeWishlistVisibility({
        variables: { published: true },
      })
        .then(() => {
          setWishlistPublished(true);
          setPublishStatusUpdated(true);

          getWishlistIdsDataMutation()
            .then(({ data }) => {
              setSharedButtons([...new Set([type, ...sharedButtons])]);
              callback(data.getWishlist.hash);
              setWishlistHash(data.getWishlist.hash);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setWishlistPublishLoading(false);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setSharedButtons([...new Set([type, ...sharedButtons])]);

      callback();
    }
  };

  const unpublishWishlist = () => {
    setWishlistPublishLoading(true);

    changeWishlistVisibility({
      variables: { published: false },
    })
      .then(() => {
        setWishlistPublished(false);
        setSharedButtons([]);
        setPublishStatusUpdated(true);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setWishlistPublishLoading(false);
      });
  };

  const shareItems = [
    {
      type: 'copy',
      label: t('shareModal.shareLinkLabel'),
      icon: SocialCopyIcon,
      onClick: (type) => {
        handleShareClick(type, (newHash) => {
          navigator.clipboard.writeText(getSharedWishlistLink(newHash));
        });
      },
    },
    {
      type: 'email',
      label: t('shareModal.shareEmailLabel'),
      icon: SocialMailIcon,
      onClick: (type) => {
        handleShareClick(type, (newHash) => {
          window.open(`mailto:?&body=${getSharedWishlistLink(newHash)}`);
        });
      },
    },
    {
      type: 'sms',
      label: t('shareModal.shareSMSLabel'),
      icon: SocialSMSIcon,
      onClick: (type) => {
        handleShareClick(type, (newHash) => {
          window.open(`sms:?&body=${getSharedWishlistLink(newHash)}`);
        });
      },
    },
    {
      type: 'whatsapp',
      label: t('shareModal.shareWhatsappLabel'),
      icon: SocialWhatsAppIcon,
      onClick: (type) => {
        handleShareClick(type, (newHash) => {
          window.open(`whatsapp://send?text=${getSharedWishlistLink(newHash)}`);
        });
      },
    },
    {
      type: 'messenger',
      label: t('shareModal.shareMessengerLabel'),
      icon: SocialMessengerIcon,
      onClick: (type) => {
        handleShareClick(type, (newHash) => {
          window.open(
            `https://www.facebook.com/dialog/send?app_id=250100865708545&link=${getSharedWishlistLink(
              newHash
            )}&redirect_uri=${REACT_APP_MYACC_DOMAIN}`
          );
        });
      },
    },
    {
      type: 'facebook',
      label: t('shareModal.shareFacebookLabel'),
      icon: SocialFacebookIcon,
      onClick: (type) => {
        handleShareClick(type, (newHash) => {
          window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${getSharedWishlistLink(
              newHash
            )}`
          );
        });
      },
    },
  ];

  return children({
    sharedButtons,
    shareItems,
    wishlistPublished,
    unpublishWishlist,
    wishlistPublishLoading,
  });
};

export default WishlistModalHandler;
