import React from 'react';
import { object, func } from 'prop-types';
import classNames from 'classnames/bind';
import { gql, useMutation } from '@apollo/client';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconBin } from 'common/media/icons/bin.icon.svg';
import { useToggle, useOnClickOutside } from 'common/hooks';

import DeleteDataModal from '../DeleteDataModal';
import styles from './DeleteUser.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  user: object.isRequired,
  setTemporaryDeletedUsers: func.isRequired,
};

const SearchDeleteAccountDataMutation = gql`
  mutation searchUpdateCustomerSbmDeleteAccountData(
    $id: String!
    $value: SbmDeleteAccountDataInput!
  ) {
    updateCustomerSbmDeleteAccountData(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

function DeleteUser({
  user: { id, firstName, lastName, email },
  setTemporaryDeletedUsers,
}) {
  const [modalOpen, toggleModalOpen] = useToggle();

  const modalRef = useOnClickOutside(toggleModalOpen);

  const [mutate] = useMutation(SearchDeleteAccountDataMutation);

  const handleDeleteAccount = () => {
    mutate({
      variables: { id, value: { deleteAccount: true } },
    })
      .then(({ data: { updateCustomerSbmDeleteAccountData } }) => {
        if (updateCustomerSbmDeleteAccountData) {
          const { result } = updateCustomerSbmDeleteAccountData;

          if (result) {
            setTemporaryDeletedUsers((prev) => [...prev, id]);
          } else {
          }
        } else {
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return (
    <div className={cx('root')}>
      {modalOpen && (
        <DeleteDataModal
          modalRef={modalRef}
          modalToggle={toggleModalOpen}
          deleteData={handleDeleteAccount}
          user={{ id, firstName, lastName, email }}
        />
      )}
      <button
        type="button"
        className={cx('delete-button')}
        onClick={toggleModalOpen}
      >
        <span className={cx('icon-wrapper')}>
          <IconContainer icon={IconBin} className={cx('icon-data')} />
        </span>
      </button>
    </div>
  );
}

DeleteUser.propTypes = propTypes;

export default DeleteUser;
