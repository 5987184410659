export const LOYALTY_STATUSES = {
  '0': 'public',
  '1': 'myac_member',
  '10': 'loyalty_member',
  '20': 'loyalty_silver',
  '30': 'loyalty_gold',
  '40': 'loyalty_platinum',
  '50': 'vip_user',
};

export const LOYALTY_CARD_CODES = {
  member: 10,
  silver: 20,
  gold: 30,
  platinum: 40,
  vip: 50,
};

export const LOYALTY_CARD_POINTS = {
  member: 0,
  silver: 5000,
  gold: 22500,
  platinum: 175000,
};

export const LOYALTY_POINTS_STATUS = {
  accurate: 'accurate',
  overestimated: 'overestimated',
  underestimated: 'underestimated',
  expired: 'expired',
};

export const GTM_LOYALTY_STATUSES = {
  '0': 'undefined',
  '1': 'non-fid',
  '10': 'fid-member',
  '20': 'fid-silver',
  '30': 'fid-gold',
  '40': 'fid-platinium',
  '50': 'fid-VIP',
};
