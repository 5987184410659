import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './SubtitleButtonSlideUpDown.module.scss';

const cx = classNames.bind(styles);

SubtitleButtonSlideUpDown.propTypes = {
  className: string,
  onClick: func,
  isOpen: bool,
  label: string.isRequired,
};

SubtitleButtonSlideUpDown.defaultProps = {
  className: null,
  onClick: null,
  isOpen: false,
};

export default function SubtitleButtonSlideUpDown({
  className,
  onClick,
  isOpen,
  label,
}) {
  return (
    <button type="button" onClick={onClick} className={cx('root', className)}>
      <span className={cx('arrow', { toggled: isOpen })} />
      <span className={cx('button-label')}>{label}</span>
    </button>
  );
}
