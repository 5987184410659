import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './FieldDescription.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node.isRequired,
  tag: string,
  className: string,
};

const defaultProps = {
  tag: 'p',
  className: null,
};

const FieldDescription = ({ tag: Tag, children, className }) => {
  return <Tag className={cx('root', className)}>{children}</Tag>;
};

FieldDescription.propTypes = propTypes;
FieldDescription.defaultProps = defaultProps;

export default FieldDescription;
