import React from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Stars.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  value: string,
  withBlank: bool,
  className: string,
};

const defaultProps = {
  value: '5',
  withBlank: false,
  className: null,
};

const Stars = ({ value, withBlank, className }) => (
  <div
    className={cx(`stars-${value}`, { 'with-blank': withBlank }, className)}
  />
);

Stars.propTypes = propTypes;
Stars.defaultProps = defaultProps;

export default Stars;
