import React, { useCallback } from 'react';
import { bool, string, shape } from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'formik';

import styles from './Switch.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
  }).isRequired,
  label: string,
  disabled: bool,
  className: string,
};

const defaultProps = {
  label: '',
  disabled: false,
  className: null,
};

const Switch = ({
  field,
  field: { name, value },
  label,
  disabled,
  className,
  formik: { setFieldTouched, setFieldValue },
}) => {
  const handleChange = useCallback(() => {
    setFieldValue(name, !value);
    setFieldTouched(name);
  }, [name, setFieldTouched, setFieldValue, value]);

  const blurChange = useCallback(() => {
    setFieldTouched(name);
  }, [name, setFieldTouched]);

  return (
    <div className={cx('root', className)}>
      {label && <span className={cx('text')}>{label}</span>}
      <input
        {...field}
        checked={value}
        type="checkbox"
        name={name}
        className={cx('checkbox')}
        id={name}
        disabled={disabled}
        onChange={handleChange}
        onBlur={blurChange}
      />
      <label className={cx('label')} htmlFor={name}>
        <span className={cx('inner')} />
        <span className={cx('switch')} />
      </label>
    </div>
  );
};

Switch.propTypes = propTypes;
Switch.defaultProps = defaultProps;

export default connect(Switch);
