import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Button from 'common/components/Button';

import { useMyaccHistory } from '~/hooks';

import styles from './FormMessage.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  messages: object.isRequired,
  labels: object.isRequired,
};

const isRichText = (text) => text.startsWith('@');
const getKey = (label) => label.substr(1);

function FormMessage({ messages, labels }) {
  const { t } = useTranslation('auth');
  const closeButtonLabel = t('create.closeButtonLabel');

  const messageTypes = ['info', 'status', 'warning', 'error'];

  const history = useMyaccHistory();

  const clickHandler = () => {
    history.push('/');
  };

  return (
    <div className={cx('root')}>
      {messageTypes.map((type) =>
        messages[type].map((message, index) =>
          isRichText(message) ? (
            labels[getKey(message)] && (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: labels[getKey(message)],
                }}
              />
            )
          ) : (
            <div key={index}>{message}</div>
          )
        )
      )}
      <Button className={cx('button')} type="button" onClick={clickHandler}>
        <span className={cx('button-label')}>{closeButtonLabel}</span>
      </Button>
    </div>
  );
}

FormMessage.propTypes = propTypes;

export default FormMessage;
