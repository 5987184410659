import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './CardGrid.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  tag: string,
};

const defaultProps = {
  tag: 'div',
};

const CardGrid = ({ tag: Tag, children, ...attrs }) => (
  <Tag className={cx('root')} {...attrs}>
    {children}
  </Tag>
);

CardGrid.propTypes = propTypes;
CardGrid.defaultProps = defaultProps;

export default CardGrid;
