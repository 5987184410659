import { useMemo } from 'react';
import moment from 'moment';

import { isBeforeDay } from 'common/utils/dates';
import {
  LOYALTY_CARD_CODES,
  LOYALTY_CARD_POINTS,
  LOYALTY_POINTS_STATUS,
} from 'common/constants';

import { useUserContext } from '~/context/UserContext';

// loyaltyPoints are hardcoded.
// They are not going to be changed.
// width calculating according to percent and next step
const cardsStatusList = [
  {
    status: LOYALTY_CARD_CODES.member,
    loyaltyPoints: LOYALTY_CARD_POINTS.member,
    percent: 0,
    width: 20,
  },
  {
    status: LOYALTY_CARD_CODES.silver,
    loyaltyPoints: LOYALTY_CARD_POINTS.silver,
    percent: 20,
    width: 30,
  },
  {
    status: LOYALTY_CARD_CODES.gold,
    loyaltyPoints: LOYALTY_CARD_POINTS.gold,
    percent: 50,
    width: 25,
  },
  {
    status: LOYALTY_CARD_CODES.platinum,
    loyaltyPoints: LOYALTY_CARD_POINTS.platinum,
    percent: 100,
    width: 25,
  },
];

const ProgressBarHandler = ({
  children,
  myPoints,
  currentStatus,
  expirationDate,
}) => {
  const { isFailSafeMode } = useUserContext();

  const nextStatusComputedIndex = useMemo(
    () =>
      cardsStatusList.findIndex(
        ({ loyaltyPoints }) => myPoints < loyaltyPoints
      ),
    [myPoints]
  );

  const currentStatusIndex = useMemo(
    () => cardsStatusList.findIndex(({ status }) => status === currentStatus),
    [currentStatus]
  );

  const isNextStatusComputedExist = useMemo(() => nextStatusComputedIndex > 0, [
    nextStatusComputedIndex,
  ]);

  const currentStatusComputedIndex = useMemo(
    () =>
      isNextStatusComputedExist
        ? nextStatusComputedIndex - 1
        : cardsStatusList.length - 1,
    [isNextStatusComputedExist, nextStatusComputedIndex]
  );

  const loyaltyStatusComputed = useMemo(
    () => cardsStatusList[currentStatusComputedIndex].status,
    [currentStatusComputedIndex]
  );

  const pointsToReach = useMemo(() => {
    if (currentStatus > loyaltyStatusComputed) {
      return cardsStatusList[currentStatusIndex].loyaltyPoints - myPoints;
    }

    if (isNextStatusComputedExist) {
      return cardsStatusList[nextStatusComputedIndex].loyaltyPoints - myPoints;
    }

    return 0;
  }, [
    currentStatus,
    currentStatusIndex,
    isNextStatusComputedExist,
    loyaltyStatusComputed,
    myPoints,
    nextStatusComputedIndex,
  ]);

  const percentStatusLine = useMemo(() => {
    if (isNextStatusComputedExist) {
      const sectionPointsToReach =
        cardsStatusList[nextStatusComputedIndex].loyaltyPoints -
        cardsStatusList[currentStatusComputedIndex].loyaltyPoints;
      const sectionPointsReached =
        myPoints - cardsStatusList[currentStatusComputedIndex].loyaltyPoints;
      const sectionPercentDelta =
        cardsStatusList[nextStatusComputedIndex].percent -
        cardsStatusList[currentStatusComputedIndex].percent;
      const sectionPercentBeforeMyPoint =
        (sectionPointsReached * sectionPercentDelta) / sectionPointsToReach;

      return (
        cardsStatusList[currentStatusComputedIndex].percent +
        sectionPercentBeforeMyPoint
      ).toFixed(4);
    }

    return 100;
  }, [
    currentStatusComputedIndex,
    isNextStatusComputedExist,
    myPoints,
    nextStatusComputedIndex,
  ]);

  const pointsStatus = useMemo(() => {
    if (
      (typeof isFailSafeMode === 'boolean' && isFailSafeMode) ||
      isBeforeDay(moment(expirationDate, 'DD/MM/YYYY'), moment())
    ) {
      return LOYALTY_POINTS_STATUS.expired;
    }

    if (loyaltyStatusComputed < currentStatus) {
      return LOYALTY_POINTS_STATUS.overestimated;
    }

    if (loyaltyStatusComputed > currentStatus) {
      return LOYALTY_POINTS_STATUS.underestimated;
    }

    return LOYALTY_POINTS_STATUS.accurate;
  }, [currentStatus, expirationDate, isFailSafeMode, loyaltyStatusComputed]);

  return children({
    percentStatusLine,
    cardsStatusList,
    pointsToReach,
    isMember: false,
    isPlatinum: false,
    pointsStatus,
    loyaltyStatusComputed,
  });
};

export default ProgressBarHandler;
