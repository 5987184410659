import React, { useRef, useState, useEffect, useCallback } from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './AccordionDropDown.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  children: node.isRequired,
  windowResized: bool.isRequired,
};

const defaultProps = {
  className: null,
};

function AccordionDropDown({ children, className, windowResized }) {
  const [isOverflow, setOverflowStatus] = useState(false);
  const [isOpen, setOpenStatus] = useState(false);
  const toggleOpen = useCallback(() => setOpenStatus((state) => !state), []);
  const textContent = useRef();
  const contentMaxHeightBlock = useRef();
  const handleOnClick = () => toggleOpen();

  useEffect(() => {
    const clientHeight = textContent.current.clientHeight;
    setOverflowStatus(
      clientHeight > contentMaxHeightBlock.current.clientHeight ||
        textContent.current.scrollHeight > clientHeight + 2 // +2 fix for IE11 issue height calculation
    );
  }, [windowResized]);

  useEffect(() => {
    if (!isOverflow && isOpen) {
      setOpenStatus(false);
    }
  }, [isOverflow, isOpen]);

  return (
    <div className={cx('root', { open: isOpen && isOverflow }, className)}>
      <div className={cx('arrow-wrappper')}>
        {isOverflow && (
          <button
            type="button"
            className={cx('arrow-button')}
            onClick={handleOnClick}
          >
            <span className={cx('arrow-icon')} />
          </button>
        )}
      </div>
      <div className={cx('content-wrapper')} ref={textContent}>
        <div className={cx('transparent-button')} ref={contentMaxHeightBlock} />
        <div className={cx('content-wrapper-inside')}>{children}</div>
        {isOverflow && (
          <button
            type="button"
            className={cx('transparent-button')}
            onClick={handleOnClick}
          >
            {' '}
          </button>
        )}
      </div>
    </div>
  );
}

AccordionDropDown.propTypes = propTypes;
AccordionDropDown.defaultProps = defaultProps;

export default AccordionDropDown;
