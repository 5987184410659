import React, { useEffect } from 'react';
import { bool, number, object, func } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import InformationPanel from 'common/components/InformationPanel';
import usePrevious from 'common/hooks/usePrevious';

import styles from './FormContactsMessages.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  submitError: bool,
  submitCount: number.isRequired,
  isAllPhonesValid: bool.isRequired,
  isValidationErrorVisible: bool.isRequired,
  setValidationErrorVisible: func.isRequired,
  errors: object.isRequired,
};

const defaultProps = {
  submitError: false,
};

const FormContactsMessages = ({
  submitError,
  submitCount,
  errors,
  isAllPhonesValid,
  isValidationErrorVisible,
  setValidationErrorVisible,
}) => {
  const prevSubmitCount = usePrevious(submitCount);

  useEffect(() => {
    if (submitCount > prevSubmitCount) {
      setValidationErrorVisible(true);
    }
  }, [setValidationErrorVisible, submitCount, prevSubmitCount]);

  const { t } = useTranslation('contacts-form');
  const validationFieldsMessage = t('validationFieldsMessage');
  const messageError = t('messageError');

  return (
    <>
      {isValidationErrorVisible && (!isEmpty(errors) || !isAllPhonesValid) && (
        <InformationPanel className={cx('info-panel')} withIcon errorMode>
          {validationFieldsMessage}
        </InformationPanel>
      )}
      {submitError && (
        <InformationPanel className={cx('info-panel')} withIcon errorMode>
          {messageError}
        </InformationPanel>
      )}
    </>
  );
};

FormContactsMessages.propTypes = propTypes;
FormContactsMessages.defaultProps = defaultProps;

export default FormContactsMessages;
