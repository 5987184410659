import React from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';

import styles from './AsyncMachine.module.scss';

import SlideDownWrapper from '../../containers/SlideDownWrapper';
import WindowResizeListenerHandler from '../../containers/WindowResizeListenerHandler';
import AsyncMachineHandler from '../../containers/AsyncMachineHandler';
import MessageByStatusColor from '../MessageByStatusColor';
import AsyncMachineMessages from '../AsyncMachineMessages';

const cx = classNames.bind(styles);

const asyncMachineDataFragment = gql`
  fragment asyncMachineFields on SbmAsyncStatus {
    status
    lastUpdateTime
    queue {
      created
      data
      locked
      endpoint
    }
    cache {
      created
      expiration
      data
    }
  }
`;

const propTypes = {
  data: object.isRequired,
  parentRef: object.isRequired,
};

const defaultProps = {
  className: null,
};

const fragment = {
  asyncMachineDataFragment,
};

function AsyncMachine({ data, parentRef }) {
  const { t } = useTranslation('edit-account');
  const asyncMachineBlock = t('asyncMachineBlock', {
    returnObjects: true,
  });
  const { asyncMachineTitle, messages } = asyncMachineBlock;
  const { status, lastUpdateTime, queue, cache } = data;

  return (
    <div className={cx('root')}>
      <AsyncMachineHandler messages={messages} lastUpdateTime={lastUpdateTime}>
        {({ messages }) => (
          <>
            <div className={cx('message-wrapper')}>
              <MessageByStatusColor messages={messages} status={status} />
            </div>
            {(!!cache || !!queue.length) && (
              <div className={cx('content-wrapper')}>
                <SlideDownWrapper
                  subtitleButtonLabel={asyncMachineTitle}
                  parentRef={parentRef}
                >
                  <WindowResizeListenerHandler>
                    {({ windowResized }) => (
                      <AsyncMachineMessages
                        queue={queue}
                        cache={cache}
                        windowResized={windowResized}
                      />
                    )}
                  </WindowResizeListenerHandler>
                </SlideDownWrapper>
              </div>
            )}
          </>
        )}
      </AsyncMachineHandler>
    </div>
  );
}

AsyncMachine.propTypes = propTypes;
AsyncMachine.defaultProps = defaultProps;
AsyncMachine.fragment = fragment;

export default AsyncMachine;
