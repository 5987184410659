import React from 'react';
import { string, object } from 'prop-types';
import classNames from 'classnames/bind';

import HighlightedText from 'common/components/HighlightedText';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './ContactUs.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  label: object,
  phone: string.isRequired,
};

const defaultProps = {
  label: null,
};

const ContactUs = ({ phone, label }) => {
  return (
    <div className={cx('root')}>
      <div className={cx('title')}>
        {label && (
          <HighlightedText index={label.index}>{label.value}</HighlightedText>
        )}
      </div>
      <div className={cx('phone')}>
        <a href={'tel:' + phone} className={cx('phone-value')}>
          {phone}
        </a>
      </div>
    </div>
  );
};

ContactUs.propTypes = propTypes;
ContactUs.defaultProps = defaultProps;

export default withErrorBoundary(ContactUs);
