import 'custom-event-polyfill';

class MediaQueryListener {
  afterElement = window.getComputedStyle
    ? window.getComputedStyle(document.body, ':after')
    : false;
  currentBreakpoint;
  lastBreakpoint;

  constructor({ updateOnInit = true } = {}) {
    if (!this.afterElement) {
      return;
    }

    if (updateOnInit) {
      this.updateCurrentBreakpoint();
    }

    this.resizeListener();
  }

  updateCurrentBreakpoint() {
    this.currentBreakpoint = this.afterElement
      .getPropertyValue('content')
      .replace(/"/g, '');

    if (this.currentBreakpoint !== this.lastBreakpoint) {
      const event = new CustomEvent('breakpoint-change', {
        detail: { currentBreakpoint: this.currentBreakpoint },
      });

      window.dispatchEvent(event);
      this.lastBreakpoint = this.currentBreakpoint;
    }
  }

  resizeListener() {
    window.addEventListener('resize', () => {
      this.updateCurrentBreakpoint();
    });

    window.addEventListener('orientationchange', () => {
      this.updateCurrentBreakpoint();
    });

    window.addEventListener('load', () => {
      this.updateCurrentBreakpoint();
    });
  }
}

export default MediaQueryListener;
