import React from 'react';

import Button from 'common/components/Button/Button';

const ButtonInverted = (props) => <Button {...props} inverted />;
const ButtonBlack = (props) => <Button {...props} color="black" />;
const ButtonBlackInverted = (props) => (
  <Button {...props} color="black" inverted />
);

// @TODO:
// More types of buttons will be added to this file
// so we are using named export type.
// This comment should be removed in future implementations.
export { ButtonInverted, ButtonBlack, ButtonBlackInverted };
export default Button;
