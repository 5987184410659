const gtmPush = (data) => {
  if (typeof window === 'undefined' || window.self !== window.top) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export default gtmPush;
