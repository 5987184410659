import React from 'react';
import { string, object, oneOf } from 'prop-types';

import classNames from 'classnames/bind';

import styles from './ErrorFallback.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  error: object.isRequired,
  componentStack: string,
  mode: oneOf(['silent', 'explicit']),
};

const defaultProps = {
  componentStack: null,
  mode: 'explicit',
};

const ErrorFallback = ({ componentStack, mode }) => {
  return mode === 'silent' ? null : (
    <div className={cx('root')}>
      <strong>
        Sorry, something went wrong, please, try again. <br />
        {componentStack && `Component stack: ${componentStack}`}
      </strong>
    </div>
  );
};

ErrorFallback.propTypes = propTypes;
ErrorFallback.defaultProps = defaultProps;

export default ErrorFallback;
