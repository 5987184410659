import React, { useCallback } from 'react';
import { string, bool, object, shape, oneOf, func, number } from 'prop-types';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';
import useWebkitAutofillFix from 'common/hooks/useWebkitAutofillFix';

import styles from './Input.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    setFieldValue: func.isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
  }).isRequired,
  isFieldEmpty: bool,
  placeholder: string,
  type: oneOf(['text', 'email', 'date', 'number']),
  required: bool,
  className: string,
  errorClassName: string,
  disabled: bool,
  autoComplete: string,
  maxLength: number,
  onKeyPress: func,
  setIsAutofilled: func,
  theme: oneOf(['block-theme']),
};

const defaultProps = {
  placeholder: '',
  isFieldEmpty: true,
  type: 'text',
  required: false,
  className: null,
  errorClassName: null,
  disabled: false,
  autoComplete: null,
  maxLength: 255,
  onKeyPress: null,
  setIsAutofilled: null,
  theme: null,
};

const Input = ({
  field,
  field: { name },
  form: { values, errors, touched, setFieldValue, handleBlur, handleChange },
  isFieldEmpty,
  type,
  placeholder,
  required,
  className,
  errorClassName,
  disabled,
  autoComplete,
  maxLength,
  onKeyPress,
  theme,
  setIsAutofilled: setIsAutofilledProp,
}) => {
  const isNumberField = type === 'number';
  const { isAutoFilled, disableWebkitAutofillFix, webkitAutofillFix } =
    useWebkitAutofillFix(values[name]);

  const setIsAutofilled = useCallback(
    (status) => {
      if (setIsAutofilledProp) {
        setIsAutofilledProp(status);
      }
    },
    [setIsAutofilledProp]
  );

  const blurHandler = (e) => {
    let value = e.target.value || '';
    if (required && !isNumberField) {
      //e.target.value 2-lines fix for email field UI update after trim (#348467)
      value = value.trim();
      e.target.value = '';
      e.target.value = value;
      setFieldValue(name, value);
    } else {
      setFieldValue(name, value);
    }

    disableWebkitAutofillFix();

    handleBlur(e);
  };

  const changeHandler = (e) => {
    setIsAutofilled(false);

    let value = e.target.value || '';

    if (isNumberField) {
      value = value.replace(/[^0-9]/g, '');
      e.target.value = '';
      e.target.value = value;
      setFieldValue(name, value);
    }

    handleChange(e);
  };

  const animationStartHandler = () => {
    webkitAutofillFix();
    setIsAutofilled(true);
  };

  return (
    <div
      className={cx('root', className, theme, {
        'error-wrapper': errors[name] && touched[name],
      })}
    >
      <input
        {...field}
        type={isNumberField ? 'text' : type}
        className={cx('input', {
          error: errors[name] && touched[name],
        })}
        disabled={disabled}
        onBlur={blurHandler}
        onChange={changeHandler}
        maxLength={maxLength}
        autoComplete={autoComplete}
        onAnimationStart={animationStartHandler}
        onKeyPress={onKeyPress}
      />

      {placeholder && (
        <label
          htmlFor={name}
          className={cx('label', {
            transformed: !!values[name] || !isFieldEmpty || isAutoFilled,
          })}
        >
          {placeholder}
          {required && <span>{' *'}</span>}
        </label>
      )}

      <ErrorMessageCustom name={name}>
        {({ msg }) => (
          <FieldError errorClassName={errorClassName}>{msg}</FieldError>
        )}
      </ErrorMessageCustom>
    </div>
  );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
