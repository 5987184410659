import React from 'react';
import { string, object, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import styles from './ErrorFallback.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  error: object,
  componentStack: string,
  mode: oneOf(['silent', 'explicit', 'friendly']),
};

const defaultProps = {
  componentStack: null,
  error: null,
  mode: 'explicit',
};

const ErrorFallback = ({ componentStack, mode }) => {
  const { t } = useTranslation('common');
  const serviceErrorMessageNotAvailable = t('serviceErrorMessageNotAvailable');

  return (
    <>
      {mode === 'silent' && null}
      {mode === 'explicit' && (
        <div className={cx('root')}>
          <strong>
            Sorry, something went wrong, please, try again. <br />
            {componentStack && `Component stack: ${componentStack}`}
          </strong>
        </div>
      )}
      {mode === 'friendly' && (
        <div className={cx('root', 'friendly')}>
          <span>{serviceErrorMessageNotAvailable}</span>
        </div>
      )}
    </>
  );
};

ErrorFallback.propTypes = propTypes;
ErrorFallback.defaultProps = defaultProps;

export default ErrorFallback;
