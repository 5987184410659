import React, { useState, useCallback } from 'react';
import { func, string, oneOfType, number, bool } from 'prop-types';
import { Observer } from 'mobx-react';

import useEffectOnUpdate from 'common/hooks/useEffectOnUpdate';

const propTypes = {
  children: func.isRequired,
  defaultActiveItemName: oneOfType([string, number]),
  activeItemName: oneOfType([string, number]),
  isClosable: bool,
  onChange: func,
};

const defaultProps = {
  defaultActiveItemName: null,
  activeItemName: null,
  onChange: null,
  isClosable: true,
};

const ToggleByName = ({
  children,
  defaultActiveItemName,
  activeItemName: activeToggleItemName,
  isClosable,
  onChange,
}) => {
  // Do not send both props activeItemName(activeToggleItemName) and defaultActiveItemName at the same time.
  const [activeItemName, setActiveItemName] = useState(
    activeToggleItemName === null ? defaultActiveItemName : activeToggleItemName
  );

  useEffectOnUpdate(() => {
    setActiveItemName(activeToggleItemName);
  }, [activeToggleItemName]);

  const toggle = useCallback(
    (name) => {
      setActiveItemName(name);

      if (typeof onChange === 'function') {
        onChange({
          activeItemName,
          toggleByName,
        });
      }
    },
    [activeItemName]
  );

  function toggleByName(name, isPrevented = true) {
    return (event) => {
      if (event && isPrevented) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (name) {
        if (activeItemName === name && isClosable) {
          toggle(null);
        } else {
          toggle(name);
        }
      } else if (isClosable) {
        toggle(null);
      }
    };
  }

  return (
    <Observer>
      {() => children({ setActiveItemName, toggleByName, activeItemName })}
    </Observer>
  );
};

ToggleByName.propTypes = propTypes;
ToggleByName.defaultProps = defaultProps;

export default ToggleByName;
