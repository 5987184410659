import React from 'react';
import { bool, func, number } from 'prop-types';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import { MD_ITEM_LIST, MD_LIST_ITEM } from 'common/constants/microdata';
import classNames from 'classnames/bind';

import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import PushLeftCol from '~/components/PushComponents/PushLeftCol';
import PushLayoutContainer, {
  PushLayoutContainerLeftCol,
  PushLayoutContainerRightCol,
} from '~/components/PushComponents/PushLayoutContainer';
import PushRightColButton from '~/components/PushComponents/PushRightColButton';
import PushRightColFooter from '~/components/PushComponents/PushRightColFooter';
import PushRightColCenter, {
  PushRightColCenterTextPart,
} from '~/components/PushComponents/PushRightColCenter';
import PushRightColTitle from '~/components/PushComponents/PushRightColTitle';
import PushRightColParentTitleAttributes from '~/components/PushComponents/PushRightColParentTitleAttributes';
import WishlistButton from '~/components/WishlistButton';

import styles from './CasinoGameCard.module.scss';

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const casinoGameCardFieldsFragment = gql`
  fragment casinoGameCardFields on CasinoGame {
    id
    title
    url
    introText(length: 256)
    casinoGameMedia: media {
      ...myaccCardMediaFields
      ...myaccCardVideoFields
    }
    casinoGamePushParentProperty: parentProperty {
      title
    }
    ctaLabel
  }
  ${PushLeftCol.fragment.myaccCardMediaFieldsFragment}
`;

const propTypes = {
  data: propType(casinoGameCardFieldsFragment).isRequired,
  isLeft: bool,
  longStyle: bool,
  sendStatClicksListingPushItemOnClick: func,
  sendStatisticPushItemOnClick: func,
  position: number,
  isUnauthorised: bool,
  onUnauthorisedCardClick: func,
};

const defaultProps = {
  longStyle: false,
  isLeft: false,
  sendStatClicksListingPushItemOnClick: null,
  sendStatisticPushItemOnClick: null,
  position: null,
  isUnauthorised: false,
  onUnauthorisedCardClick: null,
};

const fragment = {
  casinoGameCardFieldsFragment,
};

const createOnClick =
  (id, sendStatClicksListingPushItemOnClick, sendStatisticPushItemOnClick) =>
  () => {
    sendStatClicksListingPushItemOnClick &&
      sendStatClicksListingPushItemOnClick(id);
    sendStatisticPushItemOnClick && sendStatisticPushItemOnClick(id);
  };

const CasinoGameCard = ({
  data: {
    id,
    url,
    title,
    casinoGameMedia,
    introText,
    ctaLabel,
    casinoGamePushParentProperty,
  },
  isLeft,
  longStyle,
  sendStatClicksListingPushItemOnClick,
  sendStatisticPushItemOnClick,
  isUnauthorised,
  onUnauthorisedCardClick,
}) => {
  const parentPropertyArray = (() => {
    return casinoGamePushParentProperty
      ? casinoGamePushParentProperty.map(({ title: name }) => ({ name }))
      : null;
  })();

  return (
    <MyAccLink
      className={cx('root', Button.shared.hovered)}
      to={
        isUnauthorised
          ? `${REACT_APP_MYACC_DOMAIN}`
          : `${REACT_APP_SBM_DOMAIN}${url}`
      }
      onClick={(e) => {
        if (onUnauthorisedCardClick) {
          onUnauthorisedCardClick(e);
        }
      }}
    >
      <WishlistButton id={id} />

      <PushLayoutContainer
        isLeft={isLeft}
        longStyle={longStyle}
        itemProp={MD_ITEM_LIST.element}
        itemScope
        itemType={MD_LIST_ITEM.type}
      >
        <PushLayoutContainerLeftCol>
          <PushLeftCol
            longStyle={longStyle}
            data={casinoGameMedia}
            title={title}
            onClick={createOnClick(
              id,
              sendStatClicksListingPushItemOnClick,
              sendStatisticPushItemOnClick
            )}
          />
        </PushLayoutContainerLeftCol>

        <PushLayoutContainerRightCol>
          <PushRightColCenter longStyle={longStyle}>
            <PushRightColTitle
              onClick={createOnClick(
                id,
                sendStatClicksListingPushItemOnClick,
                sendStatisticPushItemOnClick
              )}
            >
              <span>{title}</span>
            </PushRightColTitle>

            <PushRightColCenterTextPart>
              <span dangerouslySetInnerHTML={{ __html: introText }} />
            </PushRightColCenterTextPart>

            <PushRightColParentTitleAttributes
              attributesList={parentPropertyArray}
            />
          </PushRightColCenter>

          <PushRightColFooter isLeft={isLeft} longStyle={longStyle}>
            <PushRightColCenter>
              <PushRightColButton
                theme={'rounded'}
                size={'m'}
                color={'tallow-invert'}
                label={ctaLabel}
              />
            </PushRightColCenter>
          </PushRightColFooter>
        </PushLayoutContainerRightCol>
      </PushLayoutContainer>
    </MyAccLink>
  );
};

CasinoGameCard.propTypes = propTypes;
CasinoGameCard.defaultProps = defaultProps;
CasinoGameCard.fragment = fragment;

export default withErrorBoundary(CasinoGameCard);
