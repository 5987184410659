const getGroupValue = ({
  key,
  fieldName,
  groupNumber,
  result,
  index,
  value,
}) => {
  if (key === `${fieldName}_${groupNumber}`) {
    result[index] = {
      ...result[index],
      [`${fieldName}`]: `${value}`,
    };
  } else if (key === `${fieldName}_${groupNumber}_cat`) {
    result[index] = {
      ...result[index],
      category: value,
    };
  }
};

const getIdDataValue = ({
  key,
  fieldName,
  groupNumber,
  result,
  index,
  localList,
}) => {
  const normalizedFieldsName = `${fieldName}_${groupNumber}`;

  if (key === normalizedFieldsName) {
    const data = localList.find(({ name }) => name === normalizedFieldsName);
    const id = data.idData ? { id: data.idData } : { id: '' };

    result[index] = {
      ...result[index],
      ...id,
    };
  }
};

const subAddressFieldKeys = [
  'address1',
  'address2',
  'zipCode',
  'city',
  'state',
  'country',
  'address',
];

export const getAddressGroups = ({
  addressList,
  addressNumberList,
  valuesList,
}) =>
  addressNumberList.reduce((result, groupNumber, index) => {
    valuesList.forEach(([key, value]) => {
      subAddressFieldKeys.forEach((subFieldKey) => {
        getGroupValue({
          key,
          groupNumber,
          fieldName: subFieldKey,
          result,
          index,
          value,
        });
      });
      getIdDataValue({
        key,
        groupNumber,
        fieldName: 'address1',
        result,
        index,
        localList: addressList,
      });
    });

    return result;
  }, []);

export const getEmailGroups = ({ emailList, emailNumberList, valuesList }) =>
  emailNumberList.reduce((result, groupNumber, index) => {
    valuesList.forEach(([key, value]) => {
      getGroupValue({
        key,
        groupNumber,
        fieldName: 'email',
        result,
        index,
        value,
      });
      getIdDataValue({
        key,
        groupNumber,
        fieldName: 'email',
        result,
        index,
        localList: emailList,
      });
    });

    return result;
  }, []);

export const getPhoneGroups = ({ phoneList, phoneNumberList, valuesList }) =>
  phoneNumberList.reduce((result, groupNumber, index) => {
    valuesList.forEach(([key, value]) => {
      getGroupValue({
        key,
        groupNumber,
        fieldName: 'phone',
        result,
        index,
        value,
      });
      getIdDataValue({
        key,
        groupNumber,
        fieldName: 'phone',
        result,
        index,
        localList: phoneList,
      });
    });
    return result;
  }, []);
