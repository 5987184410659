import React, { useState } from 'react';
import { string, bool, object, shape, func } from 'prop-types';
import { connect } from 'formik';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';

import TelephoneInput from '../TelephoneInput';

import styles from './InputPhone.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
  }).isRequired,
  form: shape({
    setFieldValue: func.isRequired,
    setFieldTouched: func.isRequired,
    values: object.isRequired,
  }).isRequired,
  label: string,
  required: bool,
  className: string,
  inputClassName: string,
  disabled: bool,
  countryIso: string,
};

const defaultProps = {
  label: '',
  required: false,
  className: null,
  inputClassName: null,
  disabled: false,
  countryIso: '',
};

const InputPhone = ({
  field: { name },
  formik: { setFieldValue, setFieldTouched, values },
  className,
  inputClassName,
  label,
  required,
  disabled,
  countryIso,
  ...props
}) => {
  const [phoneValue, setPhoneValue] = useState(values[name]);
  const [isPhoneValueValid, setPhoneValueValidityStatus] = useState(null);

  const onPhoneChange = (status, value, countryData, phoneNumber) => {
    const phoneNumberCleared = phoneNumber.replace(/-|\s/g, '');
    setPhoneValueValidityStatus(status);
    setPhoneValue(phoneNumberCleared);

    if (status) {
      setFieldValue(name, phoneNumberCleared);
    } else {
      setFieldValue(name, '');
    }
  };

  const onPhoneBlur = (status) => {
    if (!status) {
      setFieldTouched(name);
    }
  };

  return (
    <div className={cx('root', className)}>
      {label && (
        <label htmlFor={name} className={cx('label')}>
          {required ? `${label}*` : label}
        </label>
      )}
      <TelephoneInput
        name={name}
        wrpClassName={cx('phone-input-wrapper')}
        inputClassName={cx('phone-input', inputClassName, {
          error: !isPhoneValueValid,
        })}
        onPhoneNumberChange={onPhoneChange}
        userCountry={countryIso}
        defaultValue={phoneValue}
        onPhoneNumberBlur={onPhoneBlur}
        disabled={disabled}
        {...props}
      />
      <ErrorMessageCustom name={name}>
        {({ msg }) => <FieldError>{msg}</FieldError>}
      </ErrorMessageCustom>
    </div>
  );
};

InputPhone.propTypes = propTypes;
InputPhone.defaultProps = defaultProps;

export default connect(InputPhone);
