import React from 'react';

import { MYAC_MODE, MYCS_MODE } from 'common/constants';

import Routes from '~/components/Routes';
import { useAuthContext } from '~/context/AuthContext';
import { useUserContext } from '~/context/UserContext';
import Header from '~/scenes/Header';
import Footer from '~/components/Footer';
import { MYAC_ROUTES, MYCS_ROUTES } from '~/constants';

import MainGrid from './components/MainGrid';

const MyAccLayout = () => {
  const { appMode } = useAuthContext();
  const { isUserDataAvailable } = useUserContext();

  return (
    <>
      <Header isUserDataAvailable={isUserDataAvailable} isMyAC />
      <MainGrid>
        <MainGrid.Top>
          <Routes
            routesList={appMode === MYCS_MODE ? MYCS_ROUTES : MYAC_ROUTES}
            noMatchUrl="/homepage"
          />
        </MainGrid.Top>
        {appMode === MYAC_MODE && (
          <MainGrid.Bottom>
            <Footer />
          </MainGrid.Bottom>
        )}
      </MainGrid>
    </>
  );
};

export default MyAccLayout;
