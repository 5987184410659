import React, { useMemo, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames/bind';
import { string, bool } from 'prop-types';
import { NavLink } from 'react-router-dom';
import { parse } from 'query-string';

import SpinLoader from 'common/components/SpinLoader';
import { MYACC_LANGUAGE_WHITELIST } from 'common/constants';

import { useUserContext } from '~/context/UserContext';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useMyaccHistory } from '~/hooks';

import styles from './MyAccLink.module.scss';

const cx = classNames.bind(styles);

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;

const propTypes = {
  to: string.isRequired,
  className: string,
  forceNewTab: bool,
};

const defaultProps = {
  className: '',
  forceNewTab: false,
};

const MyAccLink = ({ children, to, className, forceNewTab, ...props }) => {
  const { language } = useLocalisationContext();
  const { isUserDataAvailable } = useUserContext();
  const [userDataWatchDog, setUserDataWatchDog] = useState();

  const {
    location: { search },
  } = useMyaccHistory();
  const { unique_share_id: uniqueShareId } = parse(search);

  const isSBMLink = useMemo(
    () =>
      (!to.includes(REACT_APP_MYACC_DOMAIN) && to.startsWith('/')) ||
      to.includes(REACT_APP_SBM_DOMAIN),
    [to]
  );

  const isMyAccLink = useMemo(() => to.includes(REACT_APP_MYACC_DOMAIN), [to]);

  const normalizedLink = useMemo(() => {
    // SBM urls
    if (!to.includes(REACT_APP_MYACC_DOMAIN) && to.startsWith('/'))
      return `${REACT_APP_SBM_DOMAIN}${to}`;

    // myacc urls
    if (to.includes(REACT_APP_MYACC_DOMAIN)) {
      const myaccPath = to.slice(REACT_APP_MYACC_DOMAIN.length);

      // myacc homepage
      if (to === REACT_APP_MYACC_DOMAIN || to === `${REACT_APP_MYACC_DOMAIN}/`)
        return `/${language}`;

      //"to" contains language already
      if (MYACC_LANGUAGE_WHITELIST.includes(myaccPath.split('/')[1]))
        return myaccPath;

      return `/${language}${myaccPath}`;
    }

    return to;
  }, [to, language]);

  useEffect(() => {
    if (userDataWatchDog && isUserDataAvailable) {
      window.open(normalizedLink, '_self', 'nofollow');
    }
  }, [userDataWatchDog, isUserDataAvailable, normalizedLink]);

  const sbmLinkClickHandler = useCallback(
    (e) => {
      if (!isUserDataAvailable && !userDataWatchDog) {
        e.preventDefault();
        setUserDataWatchDog(true);
      }
    },
    [isUserDataAvailable, userDataWatchDog]
  );

  if (isMyAccLink)
    return (
      <NavLink
        to={normalizedLink}
        exact={to === `/${language}`}
        className={cx('root', className)}
        {...props}
      >
        {children}
      </NavLink>
    );

  if ((isSBMLink && isUserDataAvailable) || (isSBMLink && uniqueShareId))
    return (
      <a
        href={normalizedLink}
        target={forceNewTab ? '_blank' : '_self'}
        className={cx('root', className)}
        {...props}
      >
        {children}
      </a>
    );

  // MyAcc offers special behavior
  if (isSBMLink && !isUserDataAvailable)
    return (
      <button
        type="button"
        onClick={sbmLinkClickHandler}
        className={cx('root', className)}
        {...props}
      >
        {children}
        {userDataWatchDog && <SpinLoader className={cx('loader')} size={16} />}
      </button>
    );

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      className={cx('root', className)}
      {...props}
    >
      {children}
    </a>
  );
};

MyAccLink.propTypes = propTypes;
MyAccLink.defaultProps = defaultProps;

export default MyAccLink;
