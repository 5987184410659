import React from 'react';
import { bool, array, shape, string } from 'prop-types';
import classNames from 'classnames/bind';

import HighlightedText from 'common/components/HighlightedText';

import styles from './ServicesList.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isHighlightedList: bool,
  listExpanded: bool,
  servicesList: array.isRequired,
  microdata: shape({
    amenityFeature: string,
  }),
  file: shape({ url: string.isRequired }),
};

const defaultProps = {
  isHighlightedList: null,
  listExpanded: null,
  microdata: null,
  file: null,
};

const renderRichList = (microdata) => (item, index) =>
  (
    <li
      className={cx('list-item')}
      key={index}
      dangerouslySetInnerHTML={{ __html: item }}
      itemProp={!!microdata ? microdata.amenityFeature : null}
    />
  );

const renderHighlightedList = (microdata, file) => (item, index) => {
  const fileTokenRegex = /{([^:]*):@file}/g;
  const fileToken = item.value.match(fileTokenRegex)?.[0];

  if (file && fileToken) {
    const [itemStart, itemEnd] = item.value.split(fileToken);
    const fileLabel = fileToken.replace(/{|:@file}/g, '');

    return (
      <li
        className={cx('list-item')}
        key={index}
        itemProp={!!microdata ? microdata.amenityFeature : null}
      >
        {itemStart}
        <a href={file.url} target="_blank" className={cx('list-item-file')}>
          {fileLabel}
        </a>
        {itemEnd}
      </li>
    );
  }

  return (
    <li
      className={cx('list-item')}
      key={index}
      itemProp={!!microdata ? microdata.amenityFeature : null}
    >
      <HighlightedText isFirstHighlited index={item.index}>
        {item.value}
      </HighlightedText>
    </li>
  );
};

const ServicesList = ({
  isHighlightedList,
  listExpanded,
  servicesList,
  microdata,
  file,
}) => {
  return (
    <div className={cx('root')}>
      <div className={cx('services-list', { expanded: listExpanded })}>
        <ul>
          {isHighlightedList
            ? servicesList.map(renderHighlightedList(microdata, file))
            : servicesList.map(renderRichList(microdata))}
        </ul>
      </div>
    </div>
  );
};

ServicesList.propTypes = propTypes;
ServicesList.defaultProps = defaultProps;

export default ServicesList;
