import { useEffect, useReducer } from 'react';
import { func, string } from 'prop-types';

import padStartZeros from 'common/utils/padStartZeros';
import usePrevious from 'common/hooks/usePrevious';

import {
  daysInMonth,
  getDay,
  getMonth,
  getYear,
} from '../../utils/calendarParams';
import useDays from '../../hooks/useDays';
import useMonths from '../../hooks/useMonths';
import useYears from '../../hooks/useYears';

const propTypes = {
  value: string,
  name: string.isRequired,
  lastAvailableDate: string,
  setFieldValue: func.isRequired,
  dateFormat: string.isRequired,
  children: func.isRequired,
};

const defaultProps = {
  value: '',
  lastAvailableDate: '',
};

const dateReducer = (state, action) => {
  switch (action.type) {
    case 'setDay':
      return { ...state, selectedDay: action.day };
    case 'resetDay':
      return { ...state, selectedDay: undefined };
    case 'setMonth':
      return { ...state, selectedMonth: action.month };
    case 'resetMonth':
      return { ...state, selectedMonth: undefined };
    case 'setYear':
      return { ...state, selectedYear: action.year };
    case 'resetYear':
      return { ...state, selectedYear: undefined };
    default:
      throw new Error('Unexpected action');
  }
};

const SelectDateHandler = ({
  value,
  name,
  lastAvailableDate,
  setFieldValue,
  dateFormat,
  children,
}) => {
  const [{ selectedDay, selectedMonth, selectedYear }, dispatch] = useReducer(
    dateReducer,
    {
      selectedDay: value ? getDay(value, dateFormat) : undefined,
      selectedMonth: value ? getMonth(value, dateFormat) : undefined,
      selectedYear: value ? getYear(value, dateFormat) : undefined,
    }
  );

  const prevValue = usePrevious(value);

  useEffect(() => {
    if (!value) {
      dispatch({ type: 'resetDay' });
      dispatch({ type: 'resetMonth' });
      dispatch({ type: 'resetYear' });
    } else {
      if (getDay(prevValue, dateFormat) !== getDay(value, dateFormat)) {
        dispatch({ type: 'setDay', day: getDay(value, dateFormat) });
      }
      if (getMonth(prevValue, dateFormat) !== getMonth(value, dateFormat)) {
        dispatch({ type: 'setMonth', month: getMonth(value, dateFormat) });
      }
      if (getYear(prevValue, dateFormat) !== getYear(value, dateFormat)) {
        dispatch({ type: 'setYear', year: getYear(value, dateFormat) });
      }
    }
  }, [value]);

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      if (dateFormat === 'DD/MM/YYYY') {
        setFieldValue(
          name,
          `${padStartZeros(selectedDay)}/${padStartZeros(
            selectedMonth
          )}/${selectedYear}`
        );
      } else if (dateFormat === 'DD-MM-YYYY') {
        setFieldValue(
          name,
          `${padStartZeros(selectedDay)}-${padStartZeros(
            selectedMonth
          )}-${selectedYear}`
        );
      } else if (dateFormat === 'YYYY-MM-DD') {
        setFieldValue(
          name,
          `${selectedYear}-${padStartZeros(selectedMonth)}-${padStartZeros(
            selectedDay
          )}`
        );
      }
    }
  }, [selectedDay, selectedMonth, selectedYear]);

  const { daysList } = useDays({
    lastAvailableDate,
    selectedMonth,
    selectedYear,
  });

  const { yearsList, lastAvailableYear } = useYears({ lastAvailableDate });

  const { monthsList, lastAvailableMonth } = useMonths({
    lastAvailableDate,
    selectedYear,
    lastAvailableYear,
  });

  const dayChangeHandler = (e) => {
    const targetDay = e.target.value;

    dispatch({ type: 'setDay', day: parseInt(targetDay, 10) });
  };

  const monthChangeHandler = (e) => {
    const targetMonth = e.target.value;
    const areDaysFull =
      daysInMonth(targetMonth, selectedYear) < selectedDay ||
      (lastAvailableDate &&
        lastAvailableYear === selectedYear &&
        lastAvailableMonth === parseInt(targetMonth, 10) &&
        getDay(lastAvailableDate) < selectedDay);

    dispatch({ type: 'setMonth', month: parseInt(targetMonth, 10) });

    if (areDaysFull) {
      dispatch({ type: 'resetDay' });
      setFieldValue(name, '');
    }
  };

  const yearChangeHandler = (e) => {
    const targetYear = e.target.value;
    const isLastAvailableYearSelected =
      lastAvailableYear === parseInt(targetYear, 10);
    const areDaysFull =
      (selectedMonth && daysInMonth(selectedMonth, targetYear) < selectedDay) ||
      (isLastAvailableYearSelected &&
        lastAvailableMonth === selectedMonth &&
        lastAvailableDate &&
        getDay(lastAvailableDate) < selectedDay);
    const areMonthsFull =
      isLastAvailableYearSelected &&
      lastAvailableDate &&
      getMonth(lastAvailableDate) < selectedMonth;

    dispatch({ type: 'setYear', year: parseInt(targetYear, 10) });

    if (areMonthsFull) {
      dispatch({ type: 'resetMonth' });
      setFieldValue(name, '');
    }

    if (areDaysFull) {
      dispatch({ type: 'resetDay' });
      setFieldValue(name, '');
    }
  };

  const resetSelectDate = () => {
    dispatch({ type: 'resetDay' });
    dispatch({ type: 'resetMonth' });
    dispatch({ type: 'resetYear' });
    setFieldValue(name, '');
  };

  return children({
    selectedDay,
    selectedMonth,
    selectedYear,
    daysList,
    monthsList,
    yearsList,
    dayChangeHandler,
    monthChangeHandler,
    yearChangeHandler,
    resetSelectDate,
  });
};

SelectDateHandler.propTypes = propTypes;
SelectDateHandler.defaultProps = defaultProps;

export default SelectDateHandler;
