import React, { Fragment } from 'react';
import { string, func, object } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './CloseContent.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  icon: object,
  iconClassName: string,
  label: string,
  onClose: func.isRequired,
};

const defaultProps = {
  icon: null,
  iconClassName: '',
  label: '',
};

const CloseContent = ({ label, icon: Icon, iconClassName, onClose }) => (
  <button type="button" onClick={onClose} className={cx('root')}>
    <Fragment>
      {label}
      {!!Icon && (
        <span className={`${cx('icon')} ${iconClassName}`}>
          <Icon />
        </span>
      )}
    </Fragment>
  </button>
);

CloseContent.propTypes = propTypes;
CloseContent.defaultProps = defaultProps;

export default CloseContent;
