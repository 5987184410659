import { Auth } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
  defaultDataIdFromObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const {
  REACT_APP_SBM_GRAPHQL_ENDPOINT,
  REACT_APP_AWS_GRAPHQL_ENDPOINT,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_ANON_GRAPHQL_ENDPOINT,
  REACT_APP_AWS_ANON_REGION,
  REACT_APP_AWS_ANON_APIKEY,
} = process.env;

const authenticatedLink = ApolloLink.from([
  createAuthLink({
    url: REACT_APP_AWS_GRAPHQL_ENDPOINT,
    region: REACT_APP_AWS_REGION,
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      credentials: () => Auth.currentCredentials(),
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
  }),
  createSubscriptionHandshakeLink(
    REACT_APP_AWS_GRAPHQL_ENDPOINT,
    createHttpLink({ uri: REACT_APP_AWS_GRAPHQL_ENDPOINT })
  ),
]);

const authenticatedUserApolloClient = new ApolloClient({
  link: authenticatedLink,
  cache: new InMemoryCache({
    typePolicies: {
      SbmLoyalty: {
        keyFields: [],
      },
    },
  }),
});

const anonymousLink = ApolloLink.from([
  createAuthLink({
    url: REACT_APP_AWS_ANON_GRAPHQL_ENDPOINT,
    region: REACT_APP_AWS_ANON_REGION,
    auth: {
      type: 'API_KEY',
      apiKey: REACT_APP_AWS_ANON_APIKEY,
      credentials: () => Auth.currentCredentials(),
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
  }),
  createSubscriptionHandshakeLink(
    REACT_APP_AWS_ANON_GRAPHQL_ENDPOINT,
    createHttpLink({ uri: REACT_APP_AWS_ANON_GRAPHQL_ENDPOINT })
  ),
]);

const anonymousUserApolloClient = new ApolloClient({
  link: anonymousLink,
  cache: new InMemoryCache(),
});

const sbmHttpLink = createHttpLink({
  uri: REACT_APP_SBM_GRAPHQL_ENDPOINT,
  fetch: fetch,
});

const sbmHeadersLink = setContext((rest, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Accept-Language': localStorage.getItem('i18nextLng'),
    },
  };
});

const sbmClient = new ApolloClient({
  link: sbmHeadersLink.concat(sbmHttpLink),
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      switch (responseObject.__typename) {
        case 'ListingItems':
          if (typeof responseObject.targetToSellItemId === 'undefined') {
            console.warn(
              'Apollo InMemoryCache: there is no unique ID defined in the ListingItems query. Please add "targetToSellItemId" or define another unique key.'
            );
          }
          // targetToSellItemId is a unique id concatenated from result id's, '' in case if there are no results
          return `ListingItems:${responseObject.targetToSellItemId}`;
        case 'OfferBestOffer':
          return `OfferBestOffer:${responseObject.price}_${responseObject.dateRange}_${responseObject.priceBeforeDiscount}_${responseObject.pricePoints}`;
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  }),
});

export { authenticatedUserApolloClient, anonymousUserApolloClient, sbmClient };
