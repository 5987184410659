import React from 'react';
import { oneOfType, string, bool, func, node, object, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import Link from 'common-next/components/SBMLink';

import styles from './Button.module.scss';

const cx = classNames.bind(styles);

Button.propTypes = {
  children: node.isRequired,
  className: string,
  disabled: bool,
  inverted: bool,
  onClick: func,
  color: oneOf([
    'main',
    'tallow',
    'black',
    'white',
    'full-white',
    'black-pearl',
    'blue',
    'light-blue',
  ]),
  tag: oneOfType([func, string]),
  to: oneOfType([string, object]),
  // to be added if needed with next patterns: xxl, xl, l, m, s, xs, xxs
  size: oneOf(['m', 's']),
};

Button.defaultProps = {
  className: null,
  disabled: false,
  inverted: false,
  tag: 'button',
  color: 'main',
  onClick: null,
  to: null,
  size: 'm',
};

Button.shared = {
  hovered: cx('hover-wrapper'),
};

export default function Button(props) {
  let {
    size,
    className,
    disabled,
    inverted,
    color,
    onClick,
    tag: Tag,
    to,
    ...attributes
  } = props;

  if (to && Tag === 'button' && !disabled) {
    Tag = Link;
  }

  if (disabled) {
    Tag = 'button';
  }

  return (
    <Tag
      type={Tag === 'button' && onClick ? 'button' : undefined}
      to={to}
      disabled={disabled}
      className={cx(
        'root',
        className,
        styles[`btn-${color}`],
        styles[`size-${size}`],
        {
          inverted: inverted,
        }
      )}
      onClick={onClick}
      {...attributes}
    />
  );
}
