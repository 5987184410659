import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Picture from 'common/components/Picture';

import loyaltyInfo from '~/utils/loyaltyInfo';
import { useUiContext } from 'common/context/UiContext';
import BlockTitle from '~/components/BlockTitle';

import styles from './RelativeMember.module.scss';

const cx = classNames.bind(styles);

const relativeMemberFieldsFragment = gql`
  fragment relativeMemberFields on SbmLoyaltyBlockLoyaltyCards {
    cards {
      isValid
      status
      name
      number
      expirationDate
    }
  }
`;

const fragment = {
  relativeMemberFieldsFragment,
};

const propTypes = {
  data: propType(relativeMemberFieldsFragment).isRequired,
};

function RelativeMember({ data: { cards } }) {
  const { isDesktop } = useUiContext();

  const { t } = useTranslation('loyalty-program');

  const blockTitle = t('relativeMember', {
    returnObjects: true,
  }).blockTitle;

  return (
    <div className={cx('root')}>
      <div className={cx('block-title')}>
        <BlockTitle title={blockTitle} className={cx('title')} />
      </div>
      <table className={cx('table')}>
        <tbody>
          {cards.map((card, index) => getRow(card, index, isDesktop))}
        </tbody>
      </table>
    </div>
  );
}

function getPicture(status) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('loyalty-program');

  const relativeMember = t('relativeMember', {
    returnObjects: true,
  });
  const picture = loyaltyInfo.getPicture(status, relativeMember);
  return picture ? (
    <Picture
      sources={[
        {
          srcSet: `${picture.srcSet.urlS} 1x, ${picture.srcSet.urlS2x} 2x`,
          type: 'image/webp',
        },
      ]}
      src={picture.srcSet.urlS}
      alt={picture.alt}
    />
  ) : null;
}

function getRow(
  { isValid, status, name, number, expirationDate },
  index,
  isDesktop
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation('loyalty-program');

  const relativeMember = t('relativeMember', {
    returnObjects: true,
  });

  const cellData = [
    {
      label: relativeMember.cardStatusLabel,
      value: loyaltyInfo.getCardStatus(status, relativeMember),
    },
    { label: relativeMember.nameLabel, value: name },
    { label: relativeMember.cardNumberLabel, value: number },
    {
      ...(isValid && { label: relativeMember.cardExpirationDateLabel }),
      value: isValid ? expirationDate : relativeMember.cardExpiredLabel,
    },
  ];

  return (
    <tr
      className={cx('member-row', { 'member-row-dimmed': !isValid })}
      key={index}
    >
      <td
        className={cx({
          'member-cell-card': isDesktop,
          'member-cell-mobile': !isDesktop,
        })}
      >
        {isDesktop ? (
          getPicture(status)
        ) : (
          <div className={cx('member-cell-card-mobile')}>
            {getPicture(status)}
          </div>
        )}
        {!isDesktop && (
          <div className={cx('text-mobile')}>
            {cellData.map(({ label, value }, index) => (
              <div className={cx('text-line-mobile')} key={index}>
                {label && <span className={cx('label-mobile')}>{label}</span>}
                {value}
              </div>
            ))}
          </div>
        )}
      </td>
      {isDesktop &&
        cellData.map(({ label, value }, index) => (
          <td className={cx('member-cell')} key={index}>
            {label && <div className={cx('member-cell-label')}>{label}</div>}
            {value}
          </td>
        ))}
    </tr>
  );
}

RelativeMember.fragment = fragment;
RelativeMember.propTypes = propTypes;

export default RelativeMember;
