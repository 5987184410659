import { useEffect } from 'react';
import { shape, string } from 'prop-types';

const propTypes = {
  location: shape({
    pathname: string.isRequired,
  }).isRequired,
};

function ScrollToTopOnLocationChange({ location: { pathname } }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ScrollToTopOnLocationChange.propTypes = propTypes;

export default ScrollToTopOnLocationChange;
