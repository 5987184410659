import React from 'react';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';

import PanelField from '~/components/PanelField';

const propTypes = { data: array.isRequired };
const KEYS = {
  Y: 'Yes',
  N: 'No',
  U: 'Unknown',
};

function PrivacyFields({ data }) {
  const { t } = useTranslation('edit-account');
  const privacySwitchTitleEmail = t('privacySwitchTitleEmail');
  const privacySwitchTitleData = t('privacySwitchTitleData');

  return data.map(({ sbmApiGdprId, value }) => {
    const index = uniqueId();

    return (
      <PanelField
        label={
          sbmApiGdprId === 1 ? privacySwitchTitleEmail : privacySwitchTitleData
        }
        value={KEYS[value]}
        key={index}
      />
    );
  });
}

PrivacyFields.propTypes = propTypes;

export default PrivacyFields;
