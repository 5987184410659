import statisticsCleanText from 'common/utils/statisticsCleanText';

import { statisticsPathTextWithLeftSpacer } from '~/utils/statisticsCleanPath';

const statisticsClicksWidgetDataParams = (type, title, id, buttonName) => {
  const widgetType = statisticsCleanText(type);
  const widgetTitle = statisticsPathTextWithLeftSpacer(title);
  const widgetId = statisticsPathTextWithLeftSpacer(id);
  const buttonNameNormalized = buttonName
    ? '-' + statisticsCleanText(buttonName)
    : '';

  return widgetType + widgetTitle + widgetId + buttonNameNormalized;
};

export default statisticsClicksWidgetDataParams;
