import React, { forwardRef } from 'react';
import { string, bool, number, array } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './GenericImg.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  sources: array,
  className: string,
  responsive: bool,
  forceContain: bool,
  lazy: bool,
};

const defaultProps = {
  sources: null,
  className: null,
  responsive: false,
  forceContain: false,
  lazy: false,
};

const GenericImg = forwardRef(
  (
    {
      sources,
      className,
      responsive,
      forceContain,
      width,
      height,
      children,
      lazy,
      ...restProps
    },
    ref
  ) => {
    // Please! Do not edit it, unless you really, really know what are you doing!
    const containerStyles = {
      width,
      ...(responsive ? { maxWidth: '100%' } : null),
      ...(forceContain ? { minWidth: '100%' } : null),
    };

    const getImg = () => (
      <>
        {/* eslint-disable-next-line react-app/jsx-a11y/alt-text */}
        <img
          ref={ref}
          className={cx('img', className)}
          width={width}
          height={height}
          loading={lazy ? 'lazy' : 'eager'}
          {...restProps}
        />
      </>
    );

    return (
      <div className={cx('container')} style={containerStyles}>
        <span
          style={{ paddingTop: `${(height / width).toFixed(6) * 100}%` }}
          className={cx('image-sizer')}
        />
        {Array.isArray(sources) ? (
          <picture>
            {sources.map((source, index) => (
              <source {...source} key={index} />
            ))}
            {getImg()}
          </picture>
        ) : (
          getImg()
        )}
        {children}
      </div>
    );
  }
);

GenericImg.propTypes = propTypes;
GenericImg.defaultProps = defaultProps;

export default GenericImg;
