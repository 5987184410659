import { gql } from '@apollo/client';

export const PrivacySendMutation = gql`
  mutation updateSbmGdpr($value: [SbmGdprInput!]) {
    updateSbmGdpr(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const EditPrivacyInfoSendMutation = gql`
  mutation updateCustomerSbmGdpr($id: String!, $value: [SbmGdprInput!]) {
    updateCustomerSbmGdpr(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;
