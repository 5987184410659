import { useState, useCallback, useMemo, useEffect } from 'react';
import { shape, func, string, array, bool, object } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import useEffectOnUpdate from 'common/hooks/useEffectOnUpdate';
import { MYACC_LANGUAGE } from 'common/constants';
import arrayMove from 'common/utils/arrayMove';
import usePrevious from 'common/hooks/usePrevious';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

import {
  getCheckboxGroupInitalValues,
  getRadiosGroupInitalValue,
} from '../../utils';
import { useMappings } from '../../hooks';

const gtmPreferencesParams = {
  event: 'save_preferences_settings',
};

const PreferencesSendMutation = gql`
  mutation updateSbmPreferences($value: SbmPreferencesInput!) {
    updateSbmPreferences(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const EditPreferencesSendMutation = gql`
  mutation updateCustomerSbmPreferences(
    $id: String!
    $value: SbmPreferencesInput!
  ) {
    updateCustomerSbmPreferences(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  isMyCs: bool,
  isPhoneExist: bool,
  isContactsAvailable: bool.isRequired,
  userHash: string,
  children: func.isRequired,
  preferencesData: shape({
    language: string.isRequired,
    contactChannels: array.isRequired,
    preferences: array.isRequired,
    frequency: object,
    season: array.isRequired,
  }).isRequired,
};

const defaultProps = {
  isMyCs: false,
  isPhoneExist: false,
  userHash: null,
};

function FormPreferencesHandler({
  isMyCs,
  isPhoneExist,
  isContactsAvailable,
  userHash,
  children,
  preferencesData: {
    language,
    contactChannels,
    preferences,
    frequency,
    season,
  },
}) {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { refetchUserData } = useUserContext();

  const isFormDisabled = useMemo(
    () => !isContactsAvailable,
    [isContactsAvailable]
  );

  const {
    userExperienceList,
    userFrequencyList,
    userSeasonList,
    initialContactChannelList,
  } = useMappings();

  const normalizeContactChannelList = useMemo(() => {
    if (!contactChannels) return [];

    contactChannels.forEach(({ method, value }, order) => {
      initialContactChannelList.forEach(({ name }, index) => {
        if (name === method && (name !== 'phone' || name !== 'sms')) {
          initialContactChannelList[index].value = value;
          initialContactChannelList[index].active = true;
          initialContactChannelList[index].index = order;
        }
        if (name === method && (name === 'phone' || name === 'sms')) {
          initialContactChannelList[index].value = value;
          initialContactChannelList[index].active = isPhoneExist;
          initialContactChannelList[index].index = order;
        }
      });
    });

    return sortBy(initialContactChannelList, ({ index }) => index);
  }, [contactChannels, isPhoneExist, initialContactChannelList]);

  const prevNormalizeContactChannelList = usePrevious(
    normalizeContactChannelList
  );

  const [contactChannelList, setContactChannelList] = useState(
    normalizeContactChannelList
  );

  useEffect(() => {
    // force update for i18n reasons
    if (
      !isEqual(prevNormalizeContactChannelList, normalizeContactChannelList)
    ) {
      setContactChannelList(normalizeContactChannelList);
    }
  }, [prevNormalizeContactChannelList, normalizeContactChannelList]);

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess) {
      setSubmitError(false);
      setSubmitSuccess(false);
    }
  }, [submitError, submitSuccess]);

  const onFormChange = useCallback(
    (props) => {
      resetMessage();
    },
    [resetMessage]
  );

  const userExperienceInitalValues = getCheckboxGroupInitalValues({
    customList: userExperienceList,
    queryData: preferences || [],
  });

  const userFrequencyInitalValues = getRadiosGroupInitalValue({
    customList: userFrequencyList,
    queryData: frequency,
  });

  const userSeasonInitalValues = getCheckboxGroupInitalValues({
    customList: userSeasonList,
    queryData: season || [],
  });

  const contactChannelListInitalValues = contactChannelList.reduce(
    (result, { name, value }) => {
      result[`${name}`] = value;
      return result;
    },
    {}
  );

  const onSortEnd =
    () =>
    ({ oldIndex, newIndex, name, setFieldValue }) => {
      setContactChannelList(arrayMove(contactChannelList, oldIndex, newIndex));
    };

  const languageNormalized = MYACC_LANGUAGE.find(
    ({ langISO3 }) => langISO3 === language
  );

  const initialValues = {
    language: languageNormalized ? languageNormalized.langISO3 : 'FRA',
    ...contactChannelListInitalValues,
    experiences: userExperienceInitalValues,
    frequency: userFrequencyInitalValues,
    season: userSeasonInitalValues,
  };

  useEffectOnUpdate(() => {
    setContactChannelList(normalizeContactChannelList);
  }, [contactChannels, isPhoneExist]);

  const [mutate] = useMutation(
    isMyCs ? EditPreferencesSendMutation : PreferencesSendMutation
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const { experiences, frequency, season, language } = values;

    const contactChannels = contactChannelList.reduce(
      (contactChannelField, { name }) => {
        if (values[name]) {
          contactChannelField.push(name);
        }

        return contactChannelField;
      },
      []
    );

    const submitFields = {
      contactChannels,
      preferences: experiences,
      frequency,
      season,
      language,
    };

    setSubmitSuccess(false);
    setSubmitError(false);

    if (isMyCs) {
      mutate({ variables: { id: userHash, value: submitFields } })
        .then(({ data: { updateCustomerSbmPreferences } }) => {
          setSubmitting(false);

          if (updateCustomerSbmPreferences?.result) {
            setSubmitSuccess(true);
          } else {
            setSubmitError(true);
          }
        })
        .catch((error) => {
          console.warn(error);
          setSubmitError(true);
        });
    } else {
      mutate({ variables: { value: submitFields } })
        .then(({ data: { updateSbmPreferences } }) => {
          setSubmitting(false);

          if (updateSbmPreferences?.result) {
            setSubmitSuccess(true);
            refetchUserData();
            gtmPushEvent(gtmPreferencesParams);
          } else {
            setSubmitError(true);
          }
        })
        .catch((error) => {
          console.warn(error);
          setSubmitError(true);
        });
    }
  };

  return children({
    handleSubmit,
    initialValues,
    userExperienceList,
    userFrequencyList,
    userSeasonList,
    contactChannelList,
    setContactChannelList,
    onSortEnd,
    submitError,
    submitSuccess,
    onFormChange,
    isFormDisabled,
  });
}

FormPreferencesHandler.propTypes = propTypes;
FormPreferencesHandler.defaultProps = defaultProps;

export default withErrorBoundary(FormPreferencesHandler);
