import React, { useMemo } from 'react';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Formik, Form, Field } from 'formik';

import InputPassword from 'common/components/FormComponents/InputPassword';
import InformationPanel from 'common/components/InformationPanel';
import getLastUrlSegment from 'common/utils/getLastUrlSegment';
import Container from 'common/components/Container';
import { useEffectOnMount } from 'common/hooks';
import AuthMessage from 'common/components/AuthMessage';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useAuthContext } from '~/context/AuthContext';
import { useUserContext } from '~/context/UserContext';
import PasswordCheckList from '~/components/PasswordCheckList';
import BlockTitle from '~/components/BlockTitle';
import FormButtons from '~/components/FormButtons';
import ButtonBack from '~/components/ButtonBack';
import GTM from '~/components/GTM';

import ChangePasswordFormHandler from './containers/ChangePasswordFormHandler';
import styles from './ChangePassword.module.scss';

const cx = classNames.bind(styles);

const ChangePasswordPage = ({ match: { path } }) => {
  const { errorMsg, setErrorMsg } = useAuthContext();

  const pageName = getLastUrlSegment(path);

  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  //Translations (i18n)
  const { t } = useTranslation([pageName, 'common']);

  const pageTitle = t(`${pageName}:pageTitle`);
  const currentPasswordLabel = t(`${pageName}:currentPasswordLabel`);
  const newPasswordLabel = t(`${pageName}:newPasswordLabel`);
  const changedPasswordMessage = t(`${pageName}:changedPasswordMessage`);

  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  const { language } = useLocalisationContext();

  const backLink = useMemo(
    () => `/${language}/account-information`,
    [language]
  );

  useEffectOnMount(() => setErrorMsg(''));

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>
      <div className={cx('content-wrapper')}>
        <ButtonBack to={backLink} className={cx('back-button')} />
        <BlockTitle title={pageTitle} className={cx('title')} />
        {errorMsg && (
          <AuthMessage className={cx('error')}>{errorMsg}</AuthMessage>
        )}
        <ChangePasswordFormHandler setErrorMsg={setErrorMsg}>
          {({
            handleSubmit,
            onFormChange,
            validationSchema,
            initialValues,
            isPasswordChanged,
          }) => (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, values }) => (
                <Form onChange={onFormChange}>
                  <div className={cx('field-wrap')}>
                    <Field
                      name="old_password"
                      component={InputPassword}
                      placeholder={currentPasswordLabel}
                      required
                      maxLength={128}
                      className={cx('input')}
                    />
                    <Field
                      name="password"
                      component={InputPassword}
                      placeholder={newPasswordLabel}
                      required
                      maxLength={128}
                      className={cx('input')}
                      autoComplete="new-password"
                    />
                    <PasswordCheckList
                      validationSchema={validationSchema}
                      passwordValue={values.password}
                    />
                    {isPasswordChanged && (
                      <InformationPanel
                        darkMode
                        withIcon
                        className={cx('info-pass')}
                      >
                        {changedPasswordMessage}
                      </InformationPanel>
                    )}
                    <FormButtons
                      isSubmitting={isSubmitting}
                      isSaved={isPasswordChanged}
                      to={backLink}
                      saveButtonLabel={saveButtonLabel}
                      savedButtonLabel={savedButtonLabel}
                      cancelButtonLabel={cancelButtonLabel}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </ChangePasswordFormHandler>
      </div>

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
};

export default withRouter(ChangePasswordPage);
