// NOTE: this is a specific component for calendar. You are not allowed to use any react hooks there.
import React from 'react';
import moment from 'moment';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';

import styles from './DayPickerRangeControllerWrapper.module.scss';

const cx = classNames.bind(styles);

const DATE_AVAILABLE = 'available';
const DATE_CHECKOUT_OUNLY = 'checkout-only';
const DATE_CLOSED = 'closed';

const DayContents = ({
  m,
  isDayAvailable,
  isDayBlocked,
  startDate,
  calendarData,
  minimumNights,
  nightsCount,
  minimumNightsTooltipBaseRef,
  minimumNightsTooltipRef,
  minimumNightsTooltipStyles,
  minStayRequirement,
  isMinimumNightsTooltipVisible,
  setMinimumNightsTooltipVisible,
}) => {
  const minStayLastDay = moment(startDate)
    .startOf('day')
    .add(minimumNights, 'days');

  const isAvailable = isDayAvailable(m);
  const isBlocked = isDayBlocked(m);
  const isFirstSelectedDay = moment(m).startOf('day').isSame(startDate);
  const isMinStayLastDay = moment(m).startOf('day').isSame(minStayLastDay);
  const isMinStayRangeDay = moment(m).isBetween(
    startDate,
    minStayLastDay,
    'day'
  );

  let status;
  switch (true) {
    case isAvailable: // if date blocked for calendar
      status = DATE_AVAILABLE;
      break;
    case !isBlocked: // if date not blocked for calendar, but blocked for StartDate
      status = DATE_CHECKOUT_OUNLY;
      break;
    default:
      status = DATE_CLOSED;
  }

  const day = calendarData.find((item) => item.date === m.format('YYYY-MM-DD'));

  return (
    <>
      {minimumNights > 1 &&
        nightsCount < minimumNights &&
        isFirstSelectedDay &&
        isMinimumNightsTooltipVisible && (
          <div ref={minimumNightsTooltipBaseRef} className={cx('tooltip-base')}>
            <div
              ref={minimumNightsTooltipRef}
              style={minimumNightsTooltipStyles}
              className={cx('tooltip')}
            >
              {minimumNights} {minStayRequirement}
              <IconContainer
                onClick={(e) => {
                  e.stopPropagation();
                  setMinimumNightsTooltipVisible(false);
                }}
                className={cx('icon-close')}
                icon={IconClose}
              />
            </div>
          </div>
        )}
      <div
        className={cx(status, {
          'min-stay-last-day': minimumNights > 1 && isMinStayLastDay,
          'min-stay-range-day': minimumNights > 1 && isMinStayRangeDay,
        })}
      >
        <span>{m.format('D')}</span>
        <span className={cx('price')}>
          {status === DATE_AVAILABLE && !!day && (
            <>
              {day.price}
              &euro;
            </>
          )}
          {status === DATE_CHECKOUT_OUNLY && <>*</>}
        </span>
      </div>
    </>
  );
};

export default DayContents;
