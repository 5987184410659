import { useState, useEffect } from 'react';

export default function useClient() {
  const [isClient, setClientStatus] = useState(false);

  useEffect(() => {
    setClientStatus(true);
  }, []);

  return isClient;
}
