import React, { useState, useRef } from 'react';
import { array, func, object, string } from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import uniqueId from 'lodash/uniqueId';

import MultiField from 'common/components/FormComponents/MultiField';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';
import Select from 'common/components/FormComponents/Select';
import { ReactComponent as IconPhone } from 'common/media/icons/phone.icon.svg';

import { useLocalisationContext } from '~/context/LocalisationContext';
import PanelSection from '~/components/PanelSection';
import { PHONE_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';
import InputPhone from '~/components/FormComponents/InputPhone';

import useOnNewFieldAdd from '../../hooks/useOnNewFieldAdd';
import styles from './PhoneFields.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  phoneList: array.isRequired,
  phoneCategoryList: array.isRequired,
  onPhoneAdd: func.isRequired,
  onPhoneRemove: func.isRequired,
  setFieldValue: func.isRequired,
  markDeletedFields: func.isRequired,
  values: object.isRequired,
  validationPhonePatternMessage: string.isRequired,
};

const PhoneFields = ({
  phoneList,
  phoneCategoryList,
  onPhoneAdd,
  onPhoneRemove,
  resetPhone,
  setFieldValue,
  values,
  markDeletedFields,
  validationPhonePatternMessage,
}) => {
  const {
    geoip: { countryIso },
  } = useLocalisationContext();

  const [phoneNameReset, setPhoneNameReset] = useState('');

  const formElementRef = useRef();

  const { onNewFieldAdd, counter, setCounter } = useOnNewFieldAdd(
    formElementRef,
    phoneList.length
  );

  const { t } = useTranslation('contacts-form');
  const phoneNumberFieldLabel = t('phoneNumberFieldLabel');
  const categoryFieldLabel = t('categoryFieldLabel');

  return (
    <PanelSection icon={IconPhone}>
      {phoneList.map(({ isMutable, isDeletable, name, catName }, index) => {
        const id = uniqueId();

        const onIncrease =
          index === phoneList.length - 1
            ? () => {
                setFieldValue(`phone_${id}`, '');
                setFieldValue(`phone_${id}_cat`, PHONE_DEFAULT_CATEGORY);
                onPhoneAdd(id);
                onNewFieldAdd();
                setCounter(phoneList.length);
              }
            : null;

        const onRemove = isDeletable
          ? () => {
              if (phoneList.length === 1) {
                setFieldValue(name, '');
                setFieldValue(catName, PHONE_DEFAULT_CATEGORY);
                resetPhone({ name, catName });
                setPhoneNameReset(name);
              } else {
                markDeletedFields({ setFieldValue, values, name });
                onPhoneRemove(name);
              }
            }
          : null;

        return (
          <MultiField
            key={name}
            formElementRef={formElementRef}
            rootClassName={cx({ 'shadow-highlighted': index === counter })}
            mainField={
              <Field name={name}>
                {(field) => (
                  <InputPhone
                    value={values[name]}
                    label={`${phoneNumberFieldLabel} ${
                      phoneList.length <= 1 ? '' : index + 1
                    }`}
                    countryIso={countryIso}
                    validationPhonePatternMessage={
                      validationPhonePatternMessage
                    }
                    phoneNameReset={phoneNameReset}
                    setPhoneNameReset={setPhoneNameReset}
                    {...field}
                  />
                )}
              </Field>
            }
            secondaryField={
              <Field
                name={catName}
                component={Select}
                options={phoneCategoryList}
                placeholder={categoryFieldLabel}
                disabled={!isMutable}
              />
            }
            cta={<MultiFieldCta onIncrease={onIncrease} onRemove={onRemove} />}
          />
        );
      })}
    </PanelSection>
  );
};

PhoneFields.propTypes = propTypes;

export default PhoneFields;
