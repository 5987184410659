import React, { Component } from 'react';
import hoistStatics from 'hoist-non-react-statics';

import UrlContext from './UrlContext';

import getComponentDisplayName from 'common/utils/getComponentDisplayName';

function withUrlContext(ComposedComponent) {
  const displayName = getComponentDisplayName(ComposedComponent);

  class WithUrlContextWrapper extends Component {
    static contextType = UrlContext;
    static displayName = `withUrlContext(${displayName})`;

    render() {
      const { context, props } = this;
      return <ComposedComponent url={{ ...context }} {...props} />;
    }
  }

  return hoistStatics(WithUrlContextWrapper, ComposedComponent);
}

export default withUrlContext;
