import React from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './PushLayoutContainer.module.scss';

const cx = classNames.bind(styles);

//PushLayoutContainer PropTypes
const pushLayoutContainerPropTypes = {
  className: string,
  children: node,
  isLeft: bool,
  longStyle: bool,
};

const pushLayoutContainerDefaultProps = {
  className: null,
  children: null,
  longStyle: true,
  isLeft: false,
};

// Common props for PushLayoutContainerLeftCol
const pushLayoutContainerLeftColPropTypes = {
  children: node,
};

const pushLayoutContainerLeftColDefaultProps = {
  children: null,
};

// Common props for PushLayoutContainerRightCol
const pushLayoutContainerRightColPropTypes = {
  children: node,
};

const pushLayoutContainerRightColDefaultProps = {
  children: null,
};

const PushLayoutContainer = ({
  className,
  isLeft,
  longStyle,
  children,
  ...attributes
}) => {
  return (
    <div
      className={cx(
        'root',
        { odd: isLeft },
        { 'long-style': longStyle },
        { 'short-style': !longStyle },
        className
      )}
      {...attributes}
    >
      {children}
    </div>
  );
};

const PushLayoutContainerLeftCol = ({ children }) => {
  return <div className={cx('column', 'column-1')}>{children}</div>;
};

const PushLayoutContainerRightCol = ({ children }) => {
  return <div className={cx('column', 'column-2')}>{children}</div>;
};

PushLayoutContainer.propTypes = pushLayoutContainerPropTypes;
PushLayoutContainer.defaultProps = pushLayoutContainerDefaultProps;

PushLayoutContainerLeftCol.propTypes = pushLayoutContainerLeftColPropTypes;
PushLayoutContainerLeftCol.defaultProps =
  pushLayoutContainerLeftColDefaultProps;

PushLayoutContainerRightCol.propTypes = pushLayoutContainerRightColPropTypes;
PushLayoutContainerRightCol.defaultProps =
  pushLayoutContainerRightColDefaultProps;

export { PushLayoutContainerLeftCol, PushLayoutContainerRightCol };
export default PushLayoutContainer;
