import { useEffect, useState, useMemo, useCallback } from 'react';
import { func } from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { getCookies, CANARY_KEY } from 'common/utils/cookies';
import {
  NonConfirmedUserLoginQuery,
  SyntheticStatusQuery,
} from 'common/queries';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { anonymousUserApolloClient } from '~/apolloClient';
import { useAuthContext } from '~/context/AuthContext';
import { CaptchaResultQuery } from '~/queries';
import { useFriendlyCaptcha } from '~/hooks';

const propTypes = {
  children: func.isRequired,
};

const LoginFormHandler = ({ children }) => {
  const { captchaStatus, CaptchaWidget } = useFriendlyCaptcha();

  const { solution: token } = captchaStatus;

  const [submitError, setSubmitError] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const { signIn, errorCode, setErrorCode, preFilledLogin, setSuccessMsg } =
    useAuthContext();

  useEffect(() => {
    if (errorCode === 'UserNotConfirmedException') {
      anonymousUserApolloClient
        .query({
          query: NonConfirmedUserLoginQuery,
          variables: { email: userEmail },
        })
        .then(({ data, loading, error }) => {
          if (error) {
            console.error(`data: ${data}\n`, error);
            return null;
          }

          if (loading) {
            return null;
          }

          const { informSbmNonConfirmedUserLogin } = data;

          if (informSbmNonConfirmedUserLogin) {
            console.warn('Email link was resubmitted');
          }
        })
        .catch(() => {
          console.warn('Email link was not resubmitted');
        })
        .finally(() => {
          setErrorCode(null);
        });
    }
  }, [errorCode, setErrorCode, userEmail]);

  const { t } = useTranslation('auth');
  // I18n data
  const validationRequiredFieldMessage = t(
    'login.validationRequiredFieldMessage'
  );
  const validationIncorrectEmailMessage = t(
    'login.validationIncorrectEmailMessage'
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(validationIncorrectEmailMessage)
          .required(validationRequiredFieldMessage),
        password: Yup.string().required(validationRequiredFieldMessage),
      }),
    [validationIncorrectEmailMessage, validationRequiredFieldMessage]
  );

  const resetMessage = useCallback(() => {
    if (submitError) {
      setSubmitError(false);
    }
  }, [submitError]);

  const handleSubmit = async (
    { email, password },
    { setSubmitting, resetForm }
  ) => {
    if (!token) {
      setSubmitting(false);
      resetForm();
      console.error('No captcha token generated');

      return null;
    }

    if (!getCookies(CANARY_KEY)) {
      const captchaResultQueryData = await anonymousUserApolloClient
        .query({
          query: CaptchaResultQuery,
          variables: { response: token },
        })
        .catch((error) => {
          console.warn(error);
        });

      if (!captchaResultQueryData) {
        return null;
      }

      const { data, loading, error } = captchaResultQueryData;

      if (error) {
        console.error(`data: ${data}\n`, error);
      }

      if (error || loading) {
        return null;
      }

      if (!data.getCaptchaResult) {
        setSubmitError(true);
        resetForm();
        setSubmitting(false);
        return null;
      }
    } else {
      const syntheticStatusQueryData = await anonymousUserApolloClient
        .query({
          query: SyntheticStatusQuery,
          variables: {
            token: getCookies(CANARY_KEY),
          },
        })
        .catch((error) => {
          console.warn(error);
        });

      if (
        !syntheticStatusQueryData ||
        syntheticStatusQueryData.error ||
        syntheticStatusQueryData.loading ||
        !syntheticStatusQueryData.data?.getContextStatus
      ) {
        console.warn('CANARY: You shall not pass!');
        return null;
      }
    }

    setUserEmail(email.toLowerCase());

    signIn({
      username: email.toLowerCase(),
      password,
    }).then(() => {
      setSubmitting(false);
      setSuccessMsg('');
    });
  };

  const initialValues = {
    email: preFilledLogin ?? '',
    password: '',
  };

  return children({
    handleSubmit,
    validationSchema,
    initialValues,
    submitError,
    resetMessage,
    captchaStatus,
    CaptchaWidget,
  });
};

LoginFormHandler.propTypes = propTypes;

export default withErrorBoundary(LoginFormHandler);
