import React from 'react';
import { array, string, func } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Menu.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  menu: array.isRequired,
  url: string.isRequired,
  onClick: func.isRequired,
};

function Menu({ menu, url, onClick }) {
  return (
    <ul className={cx('root')}>
      {menu.map(({ label, ref }, index) => (
        <li className={cx('menu-item')} key={index}>
          <a className={cx('menu-link')} href={url} onClick={onClick(ref)}>
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
}

Menu.propTypes = propTypes;

export default Menu;
