import React from 'react';
import { string, node, object } from 'prop-types';

import styles from './IconContainer.module.scss';

IconContainer.propTypes = {
  className: string,
  icon: object,
  children: node,
};

IconContainer.defaultProps = {
  className: '',
  icon: null,
  children: null,
};

export default function IconContainer({
  className,
  children,
  icon: Icon,
  ...restProps
}) {
  return !!children || !!Icon ? (
    <span className={`${styles.root} ${className}`} {...restProps}>
      {children || <Icon />}
    </span>
  ) : null;
}
