import React from 'react';
import { func, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from '~/context/AuthContext';
import { useMyaccHistory } from '~/hooks';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconArrowBack } from 'common/media/icons/arrow-back.icon.svg';

import styles from './BackButtons.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  resendCode: func,
  isEmailSubmitted: bool,
};

const defaultProps = {
  resendCode: null,
  isEmailSubmitted: false,
};

const BackButtons = ({ resendCode, isEmailSubmitted }) => {
  const history = useMyaccHistory();
  const { setErrorMsg } = useAuthContext();

  const onBackToLoginClick = () => {
    setErrorMsg('');
    history.push('/');
  };

  const { t } = useTranslation('auth');

  // I18n data
  const forgotPassLabelGoToLoginForm = t('login.forgotPassLabelGoToLoginForm');
  const forgotPassSubmitFormResendCode = t(
    'login.forgotPassSubmitFormResendCode'
  );

  return (
    <div>
      <div className={cx('form-bottom-item')}>
        <button
          onClick={onBackToLoginClick}
          type="button"
          className={cx('form-button-link', 'form-button-link-top-spacer')}
        >
          <IconContainer className={cx('icon-back')} icon={IconArrowBack} />
          <span>{forgotPassLabelGoToLoginForm}</span>
        </button>
      </div>
      {!!resendCode && isEmailSubmitted && (
        <div className={cx('form-bottom-item')}>
          <button
            onClick={resendCode}
            type="button"
            className={cx('form-button-link', 'form-button-link-top-spacer')}
          >
            {forgotPassSubmitFormResendCode}
          </button>
        </div>
      )}
    </div>
  );
};

BackButtons.propTypes = propTypes;
BackButtons.defaultProps = defaultProps;

export default BackButtons;
