import React, { useMemo } from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames/bind';

import Button from 'common/components/Button';
import SpinLoader from 'common/components/SpinLoader';
import { ButtonInverted } from 'common/components/Buttons';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheck } from 'common/media/icons/check.icon.svg';

import styles from './SubmitButton.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isSubmitting: bool,
  isSubmitDisabled: bool,
  isSaved: bool,
  inverted: bool,
  description: string,
  saveButtonLabel: string.isRequired,
  savedButtonLabel: string,
  cancelButtonLabel: string,
  className: string,
  onClick: func,
};

const defaultProps = {
  isSubmitDisabled: null,
  isSubmitting: false,
  isSaved: false,
  className: null,
  inverted: false,
  savedButtonLabel: null,
  cancelButtonLabel: null,
  onClick: null,
  description: null,
};

const SubmitButton = ({
  isSubmitting,
  isSubmitDisabled,
  isSaved,
  className,
  saveButtonLabel,
  savedButtonLabel,
  inverted,
  description,
  onClick,
}) => {
  const Tag = inverted ? ButtonInverted : Button;

  const savedButtonLabelNormalized = useMemo(
    () => (!!savedButtonLabel ? savedButtonLabel : saveButtonLabel),
    [savedButtonLabel, saveButtonLabel]
  );

  return (
    <div className={cx('root')}>
      <Tag
        type="submit"
        className={cx(
          'form-button',
          'button-save',
          {
            loading: isSubmitting,
          },
          { inverted: inverted },
          className
        )}
        disabled={isSubmitting || isSubmitDisabled} // @todo: should be just isSubmitDisabled, please refactor globally˝
        onClick={onClick}
      >
        {isSubmitting && (
          <SpinLoader
            className={cx('loader', { inverted: inverted })}
            size={16}
          />
        )}
        <span className={cx('button-label', { 'with-icon': isSaved })}>
          {isSaved ? (
            <>
              <IconContainer className={cx('icon-done')} icon={IconCheck} />
              {savedButtonLabelNormalized}
            </>
          ) : (
            saveButtonLabel
          )}
        </span>
      </Tag>
      {description && (
        <div className={cx('button-description')}>* {description}</div>
      )}
    </div>
  );
};
SubmitButton.propTypes = propTypes;
SubmitButton.defaultProps = defaultProps;

export default SubmitButton;
