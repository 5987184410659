import React from 'react';
import { ApolloProvider } from '@apollo/client';

import { authenticatedUserApolloClient } from '~/apolloClient';
import { LocalisationContextProvider } from '~/context/LocalisationContext';
import { SiteSettingsContextProvider } from '~/context/SiteSettingsContext';
import { UiContextProvider } from 'common/context/UiContext';
import { AuthContextProvider } from '~/context/AuthContext';
import { WebSocketContextProvider } from '~/context/WebSocketContext';

const ContextHandler = ({ children }) => {
  const client = authenticatedUserApolloClient;

  return (
    <UiContextProvider>
      <ApolloProvider client={client}>
        <WebSocketContextProvider client={client}>
          <AuthContextProvider client={client}>
            <LocalisationContextProvider>
              <SiteSettingsContextProvider>
                {children}
              </SiteSettingsContextProvider>
            </LocalisationContextProvider>
          </AuthContextProvider>
        </WebSocketContextProvider>
      </ApolloProvider>
    </UiContextProvider>
  );
};
export default ContextHandler;
