import { useRef } from 'react';
import { node, bool } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';

import useClient from 'common/hooks/useClient';

import styles from './IsClient.module.scss';

const cx = classNames.bind(styles);
const duration = 150;
const propTypes = {
  children: node.isRequired,
  transition: bool,
};

const defaultProps = {
  transition: false,
};

const IsClient = ({ children, transition }) => {
  const isClient = useClient();
  const nodeRef = useRef(null);

  if (!isClient) return null;

  if (!transition) return children;

  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={{ appear: 0, enter: duration }}
      classNames={{
        enter: cx('enter'),
        enterActive: cx('enter-active'),
        enterDone: cx('enter-done'),
      }}
      nodeRef={nodeRef}
    >
      {(state) => (
        <div ref={nodeRef} className={cx('root')}>
          {children}
        </div>
      )}
    </CSSTransition>
  );
};

IsClient.propTypes = propTypes;
IsClient.defaultProps = defaultProps;

export default IsClient;
