import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconMichelinStar } from 'common/media/icons/michelin-star.icon.svg';

import styles from './StarsMichelin.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  value: string,
  className: string,
};

const defaultProps = {
  value: '3',
  className: null,
};

const renderStars = (menuItems) => {
  let starsList = [];

  for (let i = 0; i < menuItems; i++) {
    starsList.push(
      <li className={cx('star')} key={i}>
        <IconContainer icon={IconMichelinStar} />
      </li>
    );
  }
  return starsList;
};

const StarsMichelin = ({ value, className }) => (
  <ul className={cx('root', className)}>
    {renderStars(value)}
    <meta content={value} />
  </ul>
);

StarsMichelin.propTypes = propTypes;
StarsMichelin.defaultProps = defaultProps;

export default StarsMichelin;
