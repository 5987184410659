import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClock } from 'common/media/icons/clock.icon.svg';

import styles from './OpenStatus.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  text: string.isRequired,
};

const OpenStatus = ({ text }) => (
  <div className={cx('root')}>
    <IconContainer className={cx('icon')} icon={IconClock} />
    <span className={cx('text')} dangerouslySetInnerHTML={{ __html: text }} />
  </div>
);

OpenStatus.propTypes = propTypes;

export default OpenStatus;
