import React from 'react';
import { string, object, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Picture from 'common/components/Picture';
import getPictureSources from 'common/utils/getPictureSources';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconSliderNav } from 'common/media/icons/slider-nav-button.icon.svg';
import HighlightedText from 'common/components/HighlightedText';

import { getLoginSlideBackground } from '~/utils/getLoginSlideBackground';

import styles from './BackgroundSlider.module.scss';

const cx = classNames.bind(styles);

const BackgroundSliderPropTypes = {
  className: string,
  settings: object,
  withTablet: bool,
};

const BackgroundSliderDefaultProps = {
  className: null,
  settings: {},
  withTablet: true,
};

function SliderNavArrow(props) {
  const { className, onClick, customClass } = props;
  return (
    <div className={`${className} ${customClass}`} onClick={onClick}>
      <IconContainer icon={IconSliderNav} />
    </div>
  );
}

const BackgroundSlider = ({ settings, withTablet }) => {
  const { t } = useTranslation('login-slider');
  const args = {
    returnObjects: true,
  };
  const slides = [
    t('login-slider:slide1', args),
    t('login-slider:slide2', args),
    t('login-slider:slide3', args),
  ]
    .map((slide, i) => {
      return Object.assign({}, slide, {
        img: getLoginSlideBackground(i),
      });
    })
    .filter((slide) => slide.enabled);

  const slickSettings = {
    dots: false,
    arrows: slides.length > 1,
    infinite: slides.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
    nextArrow: <SliderNavArrow customClass={settings.nextButtonClass} />,
    prevArrow: <SliderNavArrow customClass={settings.prevButtonClass} />,
    className: cx('root-slider', settings.className, {
      'with-tablet': withTablet,
      'without-tablet': !withTablet,
    }),
    dotsClass: `slick-dots ${cx('slider-dots')} ${settings.dotsClass}`,
    responsive: [
      {
        breakpoint: withTablet ? 769 : 1200,
        settings: {
          dots: slides.length > 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...slickSettings}>
      {slides.map((slide, i) => {
        return (
          <div className={cx('slide-wrap')} key={i}>
            <div className={cx('wrap')}>
              <Picture
                sources={getPictureSources(slide.img)}
                src={slide.img.desktop.url}
                alt="slide image"
              />
              {(slide.title || slide.subtitle) && (
                <div className={settings.slideTextClass}>
                  <div
                    className={cx(
                      'slide-text',
                      `vertical-${slide.verticalPosition}`,
                      `horizontal-${slide.horizontalPosition}`
                    )}
                  >
                    {slide.title && slide.title.value && (
                      <h2 className={cx('slide-title')}>
                        <HighlightedText
                          index={slide.title.color_position}
                          color={slide.color}
                        >
                          {slide.title.value}
                        </HighlightedText>
                      </h2>
                    )}
                    {slide.subtitle && (
                      <p className={cx('slide-description')}>
                        {slide.subtitle}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </Slider>
  );
};

BackgroundSlider.propTypes = BackgroundSliderPropTypes;
BackgroundSlider.defaultProps = BackgroundSliderDefaultProps;

export default BackgroundSlider;
