import { useState, useMemo, useCallback } from 'react';
import { func, string, object } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import applyNameFormat from '~/utils/applyNameFormat';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { latinLettersRegexp } from '~/constants';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';

const EditIdentitySendMutation = gql`
  mutation updateCustomerSbmIdentity($id: String!, $value: SbmIdentityInput!) {
    updateCustomerSbmIdentity(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  userHash: string,
  data: object.isRequired,
  children: func.isRequired,
};

const defaultProps = {
  userHash: null,
};

function FormIdentityInfoHandler({ userHash, data, children }) {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess) {
      setSubmitError(false);
      setSubmitSuccess(false);
    }
  }, [submitError, submitSuccess]);

  const prioritisedCountries = usePrioritisedCountries();

  const { t } = useTranslation('identity-form');
  const maleFieldLabel = t('maleFieldLabel');
  const femaleFieldLabel = t('femaleFieldLabel');
  const validationRequiredFieldMessage = t('validationRequiredFieldMessage');
  const validationLettersOnlyMessage = t('validationLettersOnlyMessage');

  const genderList = useMemo(
    () => [
      { value: 'male', label: maleFieldLabel },
      { value: 'female', label: femaleFieldLabel },
    ],
    [femaleFieldLabel, maleFieldLabel]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        gender: Yup.string(),
        firstName: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationLettersOnlyMessage),
        middleName: Yup.string().matches(
          latinLettersRegexp,
          validationLettersOnlyMessage
        ),
        birthName: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationLettersOnlyMessage),
        birthDate: Yup.string().required(validationRequiredFieldMessage),
        marriedName: Yup.string().matches(
          latinLettersRegexp,
          validationLettersOnlyMessage
        ),
      }),
    [validationLettersOnlyMessage, validationRequiredFieldMessage]
  );

  const increaseNationalitiesHandler = useCallback(
    ({ index, push, resetMessage, nationalitiesFieldsCount }) =>
      index < 2 &&
      nationalitiesFieldsCount - 1 === index &&
      nationalitiesFieldsCount < prioritisedCountries.length
        ? () => {
            resetMessage();
            push('');
          }
        : null,
    [prioritisedCountries]
  );

  const decreaseNationalitiesHandler = useCallback(
    ({
      name,
      index,
      resetMessage,
      nationalitiesFieldsCount,
      setFieldValue,
      remove,
    }) =>
      nationalitiesFieldsCount === 1
        ? () => {
            resetMessage();
            setFieldValue(name, ['']);
          }
        : () => {
            resetMessage();
            remove(index);
          },
    []
  );

  const [updateIdentityInfo] = useMutation(EditIdentitySendMutation);

  const initialValues = {
    gender: data?.gender || '',
    firstName: data?.firstName || '',
    middleName: data?.middleName || '',
    birthName: data?.birthName || '',
    marriedName: data?.marriedName || '',
    birthDate: data?.birthDate || '',
    occupation: data?.occupation || '',
    nationalities: data?.nationalities.length ? data?.nationalities : [''],
    countryOfResidence: data?.countryOfResidence || '',
  };

  const handleSubmit = (
    {
      firstName,
      birthName,
      middleName,
      marriedName,
      gender,
      birthDate,
      nationalities,
      countryOfResidence,
      occupation,
    },
    { setSubmitting, setFieldValue }
  ) => {
    const nationalitiesNormalized = nationalities.filter((item) => item !== '');

    setSubmitSuccess(false);

    setFieldValue(
      'nationalities',
      nationalitiesNormalized.length > 0 ? nationalitiesNormalized : ['']
    );

    setFieldValue('firstName', applyNameFormat(firstName));
    setFieldValue('birthName', applyNameFormat(birthName));
    setFieldValue('middleName', applyNameFormat(middleName));
    setFieldValue('marriedName', applyNameFormat(marriedName));
    setFieldValue('occupation', occupation);

    const submitFields = {
      firstName: applyNameFormat(firstName),
      birthName: applyNameFormat(birthName),
      middleName: applyNameFormat(middleName),
      marriedName: applyNameFormat(marriedName),
      gender,
      birthDate,
      nationalities: nationalitiesNormalized,
      occupation,
      countryOfResidence,
    };

    if (birthDate === '') {
      // rerender SelectDate component to errase sub-values
      setFieldValue('birthDate', '-');
      setFieldValue('birthDate', '');
    }

    updateIdentityInfo({
      variables: {
        id: userHash,
        value: submitFields,
      },
    })
      .then(({ data: { updateCustomerSbmIdentity } }) => {
        setSubmitting(false);

        if (updateCustomerSbmIdentity?.result) {
          setSubmitSuccess(true);
        } else {
          setSubmitError(true);
        }
      })
      .catch((error) => {
        console.warn(error);
        setSubmitting(false);
        setSubmitError(true);
      });
  };

  return children({
    handleSubmit,
    genderList,
    validationSchema,
    initialValues,
    submitError,
    submitSuccess,
    resetMessage,
    increaseNationalitiesHandler,
    decreaseNationalitiesHandler,
  });
}

FormIdentityInfoHandler.propTypes = propTypes;
FormIdentityInfoHandler.defaultProps = defaultProps;

export default withErrorBoundary(FormIdentityInfoHandler);
