import React, { useRef, useMemo } from 'react';
import { string, bool, array, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Panel from '~/components/Panel';
import ErrorFallback from '~/components/ErrorFallback';
import { useUserContext } from '~/context/UserContext';
import { ROLE_TOP_DOG } from '~/constants';

import PanelContent from '../PanelContent';
import Menu from '../Menu';
import IdentityFields from '../IdentityFields';
import PrivacyFields from '../PrivacyFields';
import ForeignIdsFields from '../ForeignIdsFields';

const propTypes = {
  url: string.isRequired,
  pageTopRef: object.isRequired,
  createOnClickHandler: func.isRequired,
  mainInfoPanelFields: array.isRequired,
  identityFields: object.isRequired,
  userNationalities: array.isRequired,
  isContactsEditable: bool.isRequired,
  isContactsAvailable: bool.isRequired,
  contactFields: array.isRequired,
  isPreferencesAvailable: bool.isRequired,
  preferencesFields: array.isRequired,
  sbmGdpr: array.isRequired,
  foreignIds: array.isRequired,
  isFailSafeMode: bool.isRequired,
};

const UserDataTab = ({
  url,
  pageTopRef,
  createOnClickHandler,
  mainInfoPanelFields,
  identityFields,
  userNationalities,
  isContactsEditable,
  isContactsAvailable,
  contactFields,
  isPreferencesAvailable,
  preferencesFields,
  sbmGdpr,
  foreignIds,
  isFailSafeMode,
}) => {
  const { t } = useTranslation(['edit-account', 'common']);
  const mainInfoSectionTitle = t('mainInfoSectionTitle');
  const userInfoSectionTitle = t('userInfoSectionTitle');
  const toToplinkLabel = t('toToplinkLabel');
  const editIdentityBlockLabel = t('editIdentityBlockLabel');
  const editContactsBlockLabel = t('editContactsBlockLabel');
  const preferencesBlockLabel = t('preferencesBlockLabel');
  const privacyBlockLabel = t('privacyBlockLabel');
  const foreignIdsBlockLabel = t('foreignIdsBlockLabel');
  const editButtonLabel = t('common:edit');

  const { userRole } = useUserContext();

  const mainInfoSectionRef = useRef();
  const userInfoSectionRef = useRef();

  const menu = useMemo(
    () => [
      ...(userRole === ROLE_TOP_DOG
        ? [{ label: mainInfoSectionTitle, ref: mainInfoSectionRef }]
        : []),
      { label: userInfoSectionTitle, ref: userInfoSectionRef },
    ],
    [mainInfoSectionTitle, userInfoSectionTitle, userRole]
  );

  const navLinkData = useMemo(
    () => ({
      url: url,
      onClick: createOnClickHandler(pageTopRef),
      label: toToplinkLabel,
    }),
    [url, createOnClickHandler, pageTopRef, toToplinkLabel]
  );

  const isMainInfoAvailable = useMemo(() => mainInfoPanelFields.length > 1, [
    mainInfoPanelFields,
  ]);

  const isMainInfoEmailAvailable = useMemo(
    () => mainInfoPanelFields.length === 1,
    [mainInfoPanelFields]
  );

  const isIdentityAvailable = useMemo(
    () => !!Object.keys(identityFields).length,
    [identityFields]
  );

  return (
    <div>
      <Panel>
        <Menu menu={menu} url={url} onClick={createOnClickHandler} />
      </Panel>

      {/* Main information */}
      {userRole === ROLE_TOP_DOG && (
        <Panel
          title={mainInfoSectionTitle}
          url={
            isMainInfoAvailable && !isFailSafeMode
              ? `${url}/edit-main-info`
              : null
          }
          urlLabel={editButtonLabel}
          ref={mainInfoSectionRef}
          navLinkData={navLinkData}
        >
          {(isMainInfoAvailable || isMainInfoEmailAvailable) && (
            <PanelContent panelFields={mainInfoPanelFields} />
          )}
          {(!isMainInfoAvailable || isMainInfoEmailAvailable) && (
            <ErrorFallback mode={'friendly'} />
          )}
        </Panel>
      )}

      {/* SBM API user information */}
      <Panel
        title={userInfoSectionTitle}
        ref={userInfoSectionRef}
        navLinkData={navLinkData}
      >
        <Panel
          title={editIdentityBlockLabel}
          url={
            isIdentityAvailable && !isFailSafeMode
              ? `${url}/edit-identity-info`
              : null
          }
          urlLabel={editButtonLabel}
        >
          {isIdentityAvailable ? (
            <IdentityFields
              data={identityFields}
              nationalities={userNationalities}
            />
          ) : (
            <ErrorFallback mode={'friendly'} />
          )}
        </Panel>

        <Panel
          title={editContactsBlockLabel}
          url={
            isContactsEditable && !isFailSafeMode
              ? `${url}/edit-contacts-info`
              : null
          }
          urlLabel={isContactsEditable ? editButtonLabel : null}
        >
          {isContactsAvailable ? (
            <PanelContent panelFields={contactFields} isSeparator={false} />
          ) : (
            <ErrorFallback mode={'friendly'} />
          )}
        </Panel>

        <Panel
          title={preferencesBlockLabel}
          url={
            isPreferencesAvailable && !isFailSafeMode
              ? `${url}/edit-preferences-info`
              : null
          }
          urlLabel={isPreferencesAvailable ? editButtonLabel : null}
        >
          {isPreferencesAvailable ? (
            <PanelContent panelFields={preferencesFields} />
          ) : (
            <ErrorFallback mode={'friendly'} />
          )}
        </Panel>

        <Panel
          title={privacyBlockLabel}
          url={!isFailSafeMode ? `${url}/edit-privacy-info` : null}
          urlLabel={editButtonLabel}
        >
          {!!sbmGdpr.length ? (
            <PrivacyFields data={sbmGdpr} />
          ) : (
            <ErrorFallback mode={'friendly'} />
          )}
        </Panel>

        <Panel
          title={foreignIdsBlockLabel}
          url={!isFailSafeMode ? `${url}/edit-foreign-ids` : null}
          urlLabel={editButtonLabel}
        >
          <ForeignIdsFields foreignIds={foreignIds} />
        </Panel>
      </Panel>
    </div>
  );
};

UserDataTab.propTypes = propTypes;

export default UserDataTab;
