import {
  DESKTOP_WIDE_MINSIZE_MEDIA_STR,
  DESKTOP_MINSIZE_MEDIA_STR,
  TABLET_MEDIA_STR,
  TABLET_MINSIZE_MEDIA_STR,
  MOBILE_MEDIA_STR,
  MOBILE_MINSIZE_MEDIA_STR,
} from 'common/constants';

// Image styles:
// - 1900х750 - hero_desktop_wide_responsive
// - 1440х750 - hero_desktop_wide_minsize
// - 1024х750 - hero_desktop_minsize
// ---------------------desktop-------------------
// - 1023х330 - hero_tablet_responsive
// - 768х330 - hero_tablet_minsize
// ---------------------tablet-------------------
// - 767х480 - hero_mobile_responsive
// - 530х480 - hero_mobile_minsize
// ---------------------mobile-------------------

export default function getSources({
  heroDesktopWideResponsive2x,
  heroDesktopWideResponsive,

  heroDesktopWideMinsize2x,
  heroDesktopWideMinsize,

  heroDesktopMinsize2x,
  heroDesktopMinsize,

  heroTabletResponsive2x,
  heroTabletResponsive,

  heroTabletMinsize2x,
  heroTabletMinsize,

  heroMobileResponsive2x,
  heroMobileResponsive,

  heroMobileMinsize2x,
  heroMobileMinsize,
}) {
  return [
    {
      srcSet: `${heroMobileMinsize.url} 1x, ${heroMobileMinsize2x.url} 2x`,
      type: 'image/webp',
      media: MOBILE_MINSIZE_MEDIA_STR,
    },
    {
      srcSet: `${heroMobileResponsive.url} 1x, ${heroMobileResponsive2x.url} 2x`,
      type: 'image/webp',
      media: MOBILE_MEDIA_STR,
    },
    {
      srcSet: `${heroTabletMinsize.url} 1x, ${heroTabletMinsize2x.url} 2x`,
      type: 'image/webp',
      media: TABLET_MINSIZE_MEDIA_STR,
    },
    {
      srcSet: `${heroTabletResponsive.url} 1x, ${heroTabletResponsive2x.url} 2x`,
      type: 'image/webp',
      media: TABLET_MEDIA_STR,
    },
    {
      srcSet: `${heroDesktopMinsize.url} 1x, ${heroDesktopMinsize2x.url} 2x`,
      type: 'image/webp',
      media: DESKTOP_MINSIZE_MEDIA_STR,
    },
    {
      srcSet: `${heroDesktopWideMinsize.url} 1x, ${heroDesktopWideMinsize2x.url} 2x`,
      type: 'image/webp',
      media: DESKTOP_WIDE_MINSIZE_MEDIA_STR,
    },
    {
      srcSet: `${heroDesktopWideResponsive.url} 1x, ${heroDesktopWideResponsive2x.url} 2x`,
      type: 'image/webp',
    },
  ];
}
