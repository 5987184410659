import { useEffect } from 'react';
import ReactDOM from 'react-dom';

import { MODAL_ROOT_ID } from 'common/constants';

import { useUiContext } from 'common/context/UiContext';

const MyAccModalPortal = function ({ children }) {
  const modalRoot =
    typeof window !== 'undefined'
      ? document.getElementById(MODAL_ROOT_ID)
      : null;
  const el =
    typeof window !== 'undefined' ? document.createElement('div') : null;
  const uiStore = useUiContext();

  useEffect(() => {
    // Component did mount
    modalRoot.appendChild(el);
    uiStore.lockScroll();

    return () => {
      // Component will unmount
      modalRoot.removeChild(el);
      uiStore.unlockScroll();
    };
  });

  return modalRoot ? ReactDOM.createPortal(children, el) : null;
};

export default MyAccModalPortal;
