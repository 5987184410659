import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { createUseContext } from 'common/lib/context';
import { useEffectOnMount } from 'common/hooks';
import usePrevious from 'common/hooks/usePrevious';
import {
  BRIDGE_LSKEY,
  MYID_MODE,
  MYAC_MODE,
  MYCS_MODE,
} from 'common/constants';
import {
  setCookiesCrossDomain,
  cleanUpMyIDCookies,
  getCookies,
  removeCookies,
  removeCognitoCookies,
} from 'common/utils/cookies';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import history from '~/history';
import { ROLE_TOP_DOG, ROLE_AGENT, ROLE_USER } from '~/constants';
import { useWebSocketContext } from '~/context/WebSocketContext';

import * as constants from './constants';
import * as errors from './errors';

const { REACT_APP_COOKIE_DOMAIN } = process.env;

const CHANNEL = 'auth';

const {
  Context: AuthContext,
  ContextProvider: AuthContextProvider,
  ContextConsumer: AuthContextConsumer,
  useContext: useAuthContext,
} = createUseContext((Provider) => ({ children, client }) => {
  const [authState, setAuthState] = useState(constants.LOADING_STATE);
  const [appMode, setAppMode] = useState();
  const [userRole, setUserRole] = useState();
  const [preFilledLogin, setPreFilledLogin] = useState(null);
  const { errorCode, setErrorCode, errorMsg, setErrorMsg } = useErrorMsg();
  const [successMsg, setSuccessMsg] = useState('');

  const { maintenance, sendJsonMessage } = useWebSocketContext();
  const prevMaintenance = usePrevious(maintenance);

  const [offerRedirectParams, setOfferRedirectParams] = useState({});

  const signOut = useSignOut({
    setErrorMsg,
    setAppMode,
    sendJsonMessage,
  });
  const signIn = useSignIn({
    setErrorMsg,
    setAuthState,
    setAppMode,
    setUserRole,
    setPreFilledLogin,
    signOut,
    sendJsonMessage,
  });
  const autoLogin = useAutologin({
    setErrorMsg,
    setAuthState,
    setAppMode,
    setUserRole,
    setPreFilledLogin,
    signOut,
    sendJsonMessage,
  });
  const forgotPasswordSubmit = useForgotPasswordSubmit({
    setErrorMsg,
  });
  const changePassword = useChangePassword({
    setErrorMsg,
  });

  useAuthCheck({
    setAuthState,
    setAppMode,
    setUserRole,
    setErrorMsg,
    sendJsonMessage,
  });
  useHubListener({ setAuthState, client });

  useEffect(() => {
    if (typeof maintenance === 'boolean') {
      if (maintenance === true && authState !== constants.MAINTENANCE_STATE) {
        if (authState === constants.SIGNED_IN_STATE) {
          signOut();
        }

        setAuthState(constants.MAINTENANCE_STATE);
      }
      if (maintenance === false && prevMaintenance === true) {
        setAuthState(constants.SIGN_IN_STATE);
      }
    }
  }, [authState, maintenance, prevMaintenance, signOut]);

  const clearAllMsg = () => {
    setErrorMsg('');
    setSuccessMsg('');
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState,
        appMode,
        userRole,
        signOut,
        signIn,
        autoLogin,
        errorCode,
        setErrorCode,
        errorMsg,
        setErrorMsg,
        forgotPasswordSubmit,
        changePassword,
        preFilledLogin,
        setPreFilledLogin,
        successMsg,
        setSuccessMsg,
        clearAllMsg,
        offerRedirectParams,
        setOfferRedirectParams,
      }}
    >
      {children}
    </Provider>
  );
});

function useErrorMsg() {
  const { t } = useTranslation('errors');
  const userDoesNotExist = t('userDoesNotExist');
  const incorrectUsernameOrPassword = t('incorrectUsernameOrPassword');
  const userIsDisabled = t('userIsDisabled');
  const passwordAttemptsExceeded = t('passwordAttemptsExceeded');
  const userIsNotConfirmed = t('userIsNotConfirmed');
  const usernameClientIdNotFound = t('usernameClientIdNotFound');
  const noRegisteredVerifiedEmailOrPhoneNumber = t(
    'noRegisteredVerifiedEmailOrPhoneNumber'
  );
  const invalidVerificationCode = t('invalidVerificationCode');
  const attemptLimitExceeded = t('attemptLimitExceeded');
  const anyOtherCase = t('anyOtherCase');

  const errorsMap = useMemo(
    () => ({
      UserNotFoundException: {
        [errors.USER_DOES_NOT_EXIST]: userDoesNotExist,
        [errors.USERNAME_CLIENT_ID_COMBINATION_NOT_FOUND]:
          usernameClientIdNotFound,
      },
      NotAuthorizedException: {
        [errors.INCORRECT_USERNAME_OR_PASSWORD]: incorrectUsernameOrPassword,
        [errors.USER_IS_DISABLED]: userIsDisabled,
        [errors.PASSWORD_ATTEMPTS_EXCEEDED]: passwordAttemptsExceeded,
      },
      UserNotConfirmedException: {
        [errors.USER_IS_NOT_CONFIRMED]: userIsNotConfirmed,
      },
      InvalidParameterException: {
        [errors.NO_REGISTERED_VERIFIED_EMAIL_OR_PHONE_NUMBER]:
          noRegisteredVerifiedEmailOrPhoneNumber,
      },
      CodeMismatchException: {
        [errors.INVALID_VERIFICATION_CODE]: invalidVerificationCode,
      },
      LimitExceededException: {
        [errors.LIMIT_EXCEEDED_EXCEPTION]: attemptLimitExceeded,
      },
    }),
    [
      attemptLimitExceeded,
      incorrectUsernameOrPassword,
      invalidVerificationCode,
      noRegisteredVerifiedEmailOrPhoneNumber,
      passwordAttemptsExceeded,
      userDoesNotExist,
      userIsDisabled,
      userIsNotConfirmed,
      usernameClientIdNotFound,
    ]
  );

  const [errorMsg, setErrorMsg] = useState(null);
  const [errorCode, setErrorCode] = useState(null);

  const createSetErrorMsgWrapper = useCallback(
    (error) => {
      if (typeof error === 'string') {
        setErrorMsg(error);
      } else if (!!error) {
        setErrorMsg(errorsMap?.[error.code]?.[error.message] || anyOtherCase);
        setErrorCode(error.code);
      }
    },
    [anyOtherCase, errorsMap]
  );

  return {
    errorCode,
    setErrorCode,
    errorMsg,
    setErrorMsg: createSetErrorMsgWrapper,
  };
}

const cleanUpMyIDStorage = () => {
  Object.keys(BRIDGE_LSKEY).forEach((key) => {
    if (key !== BRIDGE_LSKEY.userData) {
      localStorage.removeItem(BRIDGE_LSKEY[key]);
    }
  });
};

function useAuthCheck({
  setAuthState,
  setAppMode,
  setUserRole,
  setErrorMsg,
  sendJsonMessage,
}) {
  useEffectOnMount(() => {
    const authDate = localStorage.getItem(BRIDGE_LSKEY.authorized);

    if (
      authDate &&
      +moment().format('x') > +moment(authDate, 'x').add(7, 'days').format('x')
    ) {
      setAuthState(constants.SIGN_IN_STATE);
      setErrorMsg('');
      cleanUpMyIDStorage();
      return Auth.signOut()
        .then((info) => info)
        .catch((error) => {
          console.error(error);
          setErrorMsg(error);
        })
        .finally(() => {
          history.push('/');
          setAppMode(MYID_MODE);
        });
    }

    Auth.configure({
      cookieStorage: {
        domain: REACT_APP_COOKIE_DOMAIN,
        secure: process.env.NODE_ENV !== 'development',
      },
    });
    Auth.currentAuthenticatedUser()
      .then(() => {
        Auth.currentSession()
          .then(({ idToken }) => {
            sendJsonMessage({
              action: 'authorization',
              value: idToken.jwtToken,
            });
            const cognitoGroups = idToken.payload['cognito:groups'];
            const isCustomerService =
              !!cognitoGroups?.includes(constants.GROUP_AGENT) ||
              !!cognitoGroups?.includes(constants.GROUP_TOP_DOG);

            setAuthState(constants.SIGNED_IN_STATE);
            setAppMode(isCustomerService ? MYCS_MODE : MYAC_MODE);

            if (!!cognitoGroups?.includes(constants.GROUP_TOP_DOG)) {
              setUserRole(ROLE_TOP_DOG);
            } else if (
              !!cognitoGroups?.includes(constants.GROUP_AGENT) &&
              !cognitoGroups?.includes(constants.GROUP_TOP_DOG)
            ) {
              setUserRole(ROLE_AGENT);
            } else {
              setUserRole(ROLE_USER);
            }
          })
          .catch((error) => {
            console.error(error);
            // Just set `signIn` status to show Sing In dialog:
            setAuthState(constants.SIGN_IN_STATE);
            setAppMode(MYID_MODE);
            cleanUpMyIDStorage();
          });
      })
      .catch((error) => {
        // Just set `signIn` status to show Sing In dialog:
        setAuthState(constants.SIGN_IN_STATE);
        cleanUpMyIDStorage();
      });
  });
}

function useSignIn({
  setErrorMsg,
  setAuthState,
  signOut,
  setAppMode,
  setUserRole,
  setPreFilledLogin,
  sendJsonMessage,
}) {
  const { t } = useTranslation('auth');
  const specialUserMessage = t('login.specialUserMessage');

  const signIn = ({ username, password }) => {
    setErrorMsg('');
    setPreFilledLogin(null);

    if (getCookies(BRIDGE_LSKEY.signoutMyAccTrigger)) {
      removeCookies(BRIDGE_LSKEY.signoutMyAccTrigger);
    }

    removeCognitoCookies();

    Auth.configure({
      cookieStorage: {
        domain: REACT_APP_COOKIE_DOMAIN,
        secure: process.env.NODE_ENV !== 'development',
      },
      authenticationFlowType: 'USER_SRP_AUTH',
    });

    return Auth.signIn({
      username,
      password,
    })
      .then(() => {
        Auth.currentSession()
          .then(({ idToken }) => {
            sendJsonMessage({
              action: 'authorization',
              value: idToken.jwtToken,
            });
            const cognitoGroups = idToken.payload['cognito:groups'];
            const isCustomerService =
              !!cognitoGroups?.includes(constants.GROUP_AGENT) ||
              !!cognitoGroups?.includes(constants.GROUP_TOP_DOG);

            if (
              !isCustomerService &&
              constants.SPECIAL_USER_EMAIL_LIST.some((specialEmailDomain) =>
                username.includes(specialEmailDomain)
              )
            ) {
              signOut();
              setErrorMsg(specialUserMessage);
              return null;
            }

            setAppMode(isCustomerService ? MYCS_MODE : MYAC_MODE);
            setAuthState(constants.SIGNED_IN_STATE);
            //@todo: set expiration date in addition to boolean value
            setCookiesCrossDomain(
              BRIDGE_LSKEY.authorized,
              moment().format('x')
            );
            localStorage.setItem(BRIDGE_LSKEY.authorized, moment().format('x'));

            if (!!cognitoGroups?.includes(constants.GROUP_TOP_DOG)) {
              setUserRole(ROLE_TOP_DOG);
            } else if (
              !!cognitoGroups?.includes(constants.GROUP_AGENT) &&
              !cognitoGroups?.includes(constants.GROUP_TOP_DOG)
            ) {
              setUserRole(ROLE_AGENT);
            } else {
              setUserRole(ROLE_USER);
              gtmPushEvent({ event: 'login' });
            }
          })
          .catch((error) => {
            console.error(error);
            setErrorMsg(error);
          });
      })
      .catch((error) => {
        console.error(error);
        setErrorMsg(error);
      });
  };

  return signIn;
}

function useSignOut({ setErrorMsg, setAppMode, sendJsonMessage }) {
  const signOut = () => {
    setErrorMsg('');
    cleanUpMyIDStorage();
    cleanUpMyIDCookies();

    return Auth.signOut()
      .then(() => {
        sendJsonMessage({
          action: 'authorization',
          value: '',
        });
      })
      .catch((error) => {
        console.error(error);
        setErrorMsg(error);
      })
      .finally(() => {
        setAppMode(MYID_MODE);
        history.push('/');
      });
  };
  return signOut;
}

function useAutologin({
  setErrorMsg,
  setAuthState,
  signOut,
  setAppMode,
  setUserRole,
  setPreFilledLogin,
  sendJsonMessage,
}) {
  const { t } = useTranslation('auth');
  const specialUserMessage = t('login.specialUserMessage');

  const autoLogin = ({ username, hash }) => {
    setErrorMsg('');
    setPreFilledLogin(null);

    removeCognitoCookies();

    Auth.configure({
      cookieStorage: {
        domain: REACT_APP_COOKIE_DOMAIN,
        secure: process.env.NODE_ENV !== 'development',
      },
      authenticationFlowType: 'CUSTOM_AUTH',
    });

    return Auth.signIn({ username })
      .then((user) => {
        Auth.sendCustomChallengeAnswer(user, hash).then(() => {
          Auth.currentSession()
            .then(({ idToken }) => {
              sendJsonMessage({
                action: 'authorization',
                value: idToken.jwtToken,
              });
              const cognitoGroups = idToken.payload['cognito:groups'];
              const isCustomerService =
                !!cognitoGroups?.includes(constants.GROUP_AGENT) ||
                !!cognitoGroups?.includes(constants.GROUP_TOP_DOG);

              if (
                !isCustomerService &&
                constants.SPECIAL_USER_EMAIL_LIST.some((specialEmailDomain) =>
                  username.includes(specialEmailDomain)
                )
              ) {
                signOut();
                setErrorMsg(specialUserMessage);
                return null;
              }

              setAppMode(isCustomerService ? MYCS_MODE : MYAC_MODE);
              setAuthState(constants.SIGNED_IN_STATE);
              //@todo: set expiration date in addition to boolean value
              setCookiesCrossDomain(
                BRIDGE_LSKEY.authorized,
                moment().format('x')
              );
              localStorage.setItem(
                BRIDGE_LSKEY.authorized,
                moment().format('x')
              );

              if (!!cognitoGroups?.includes(constants.GROUP_TOP_DOG)) {
                setUserRole(ROLE_TOP_DOG);
              } else if (
                !!cognitoGroups?.includes(constants.GROUP_AGENT) &&
                !cognitoGroups?.includes(constants.GROUP_TOP_DOG)
              ) {
                setUserRole(ROLE_AGENT);
              } else {
                setUserRole(ROLE_USER);
                gtmPushEvent({ event: 'login' });
              }
            })
            .catch((error) => {
              console.error(error);
              setErrorMsg(error);
            });
        });
      })
      .catch((error) => {
        console.error(error);
        setErrorMsg(error);
      });
  };

  return autoLogin;
}

function useForgotPasswordSubmit({ setErrorMsg }) {
  const forgotPasswordSubmit = ({ username, code, password }) => {
    setErrorMsg('');

    return Auth.forgotPasswordSubmit(username, code, password)
      .then(() => 'success')
      .catch((error) => {
        console.log(error);
        setErrorMsg(error);
      });
  };

  return forgotPasswordSubmit;
}

function useHubListener({ setAuthState, client }) {
  useEffectOnMount(() => {
    const onHubCapsule = (capsule) => {
      const { channel: curChannel, payload } = capsule;

      if (CHANNEL === curChannel) {
        switch (payload.event) {
          case 'cognitoHostedUI':
            setAuthState(constants.SIGNED_IN_STATE);
            break;
          case 'cognitoHostedUI_failure':
          case 'parsingUrl_failure':
          case 'signOut':
          case 'customGreetingSignOut': {
            client.cache.reset();
            setAuthState(constants.SIGN_IN_STATE);
            break;
          }
          default:
            break;
        }
      }
    };

    Hub.listen(CHANNEL, onHubCapsule);

    return () => {
      Hub.remove(CHANNEL, onHubCapsule);
    };
  });
}

function useChangePassword({ setErrorMsg }) {
  const changePassword = ({ old_password, password }) => {
    setErrorMsg('');

    return Auth.currentAuthenticatedUser()
      .then((user) =>
        Auth.changePassword(user, old_password, password)
          .then((info) => info)
          .catch((error) => {
            console.log(error);
            setErrorMsg(error);
          })
      )
      .catch((error) => {
        console.log(error);
        setErrorMsg(error);
      });
  };

  return changePassword;
}

export { AuthContextConsumer, AuthContextProvider, useAuthContext };

export default AuthContext;
