import React from 'react';
import { shape, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { useEffectOnMount } from 'common/hooks';
import Container from 'common/components/Container';
import SpinLoader from 'common/components/SpinLoader';

import PageLoader from '~/components/PageLoader';
import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import GTM from '~/components/GTM';
import Button from '~/components/Button';
import ErrorFallback from '~/components/ErrorFallback';

import NotificationsHandler from './containers/NotificationsHandler';
import Notification from './components/Notification';
import styles from './Notifications.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  match: shape({
    path: string,
  }).isRequired,
};

function Notifications() {
  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    refetchUserData,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  useEffectOnMount(() => {
    refetchUserData();
  });

  const { t } = useTranslation('notifications');
  const pageTitle = t('pageTitle');
  const noNotifications = t('noNotifications');
  const loadMore = t('loadMore');

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <Container className={cx('root')} width={760}>
        <NotificationsHandler>
          {({
            loadingNotificationsData,
            notifications,
            handleUpdateNotification,
            handleLoadMore,
            hasNextPage,
            hasNotifications,
            loadingRefetchNotifications,
          }) => (
            <div>
              <h2 className={cx('title')}>
                <span
                  className={cx({
                    loading: loadingRefetchNotifications,
                  })}
                >
                  {pageTitle}
                </span>
                {loadingRefetchNotifications && (
                  <SpinLoader className={cx('loader')} />
                )}
              </h2>

              {loadingNotificationsData && <PageLoader />}

              {!loadingNotificationsData &&
                (hasNotifications ? (
                  <div className={cx('items-wrapper')}>
                    {notifications.map(
                      ({ id, text, date, visible, status }) =>
                        visible && (
                          <Notification
                            key={id}
                            id={id}
                            status={status}
                            text={text}
                            date={date}
                            handleUpdateNotification={handleUpdateNotification}
                            loadingRefetchNotifications={
                              loadingRefetchNotifications
                            }
                          />
                        )
                    )}
                    {hasNextPage && (
                      <div className={cx('show-more-wrap')}>
                        <Button
                          color="tallow"
                          inverted
                          onClick={handleLoadMore}
                          disabled={loadingNotificationsData}
                        >
                          {loadMore}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  <p className={cx('no-notifications')}>{noNotifications}</p>
                ))}

              {isFailSafeMode && <ErrorFallback mode={'friendly'} />}
            </div>
          )}
        </NotificationsHandler>
      </Container>

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </>
  );
}

Notifications.propTypes = propTypes;

export default withErrorBoundary(Notifications);
