import React, { useMemo } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { numberWithSpace } from '~/utils/numberUpdates';
import { useUserContext } from '~/context/UserContext';

import styles from './BestPriceBlock.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  oldPrice: string,
  price: string.isRequired,
  priceLabel: string.isRequired,
  priceEmptyLabel: string,
  currency: string.isRequired,
  points: string,
};

const defaultProps = {
  oldPrice: null,
  className: null,
  priceEmptyLabel: null,
  points: undefined,
};

const BestPriceBlock = ({
  className,
  price,
  priceLabel,
  priceEmptyLabel,
  oldPrice,
  currency,
  points,
}) => {
  const {
    userSbmLoyalty: { rewardPoints },
  } = useUserContext();
  const { t } = useTranslation('offers-info');
  const [fromText, perText] = priceLabel.split('@price');

  const pointsLabel = t('myPoints');
  const showPoints = useMemo(
    function() {
      return !!rewardPoints && !!points;
    },
    [rewardPoints, points]
  );

  return !!priceEmptyLabel && price === '0' ? (
    <p className={cx('root', className)}>{priceEmptyLabel}</p>
  ) : (
    <p className={cx('root', className)}>
      <span className={cx('from-text')}>{fromText}</span>
      <span className={cx('price-wrap')}>
        <span className={cx('price')}>
          {price}
          {currency}
        </span>
        <span>
          {oldPrice && (
            <span className={cx('old-price')}>
              {oldPrice}
              {currency}
            </span>
          )}
          {perText && <span className={cx('after-text')}>{perText}</span>}
        </span>
      </span>
      {showPoints && (
        <span className={cx('points-info')}>
          {numberWithSpace(points)} {pointsLabel}
        </span>
      )}
    </p>
  );
};

BestPriceBlock.propTypes = propTypes;
BestPriceBlock.defaultProps = defaultProps;

export default BestPriceBlock;
