import cardMembreS from '~/media/cards/membre-s.webp';
import cardGoldS from '~/media/cards/gold-s.webp';
import cardPlatinumS from '~/media/cards/platinum-s.webp';
import cardSilverS from '~/media/cards/silver-s.webp';
import cardPriveS from '~/media/cards/prive-s.webp';

import cardMembreB from '~/media/cards/membre-b.webp';
import cardSilverB from '~/media/cards/silver-b.webp';
import cardGoldB from '~/media/cards/gold-b.webp';
import cardPlatinumB from '~/media/cards/platinum-b.webp';

import cardMembreS2x from '~/media/cards/membre-s-2x.webp';
import cardGoldS2x from '~/media/cards/gold-s-2x.webp';
import cardPlatinumS2x from '~/media/cards/platinum-s-2x.webp';
import cardSilverS2x from '~/media/cards/silver-s-2x.webp';
import cardPriveS2x from '~/media/cards/prive-s-2x.webp';

import cardMembreB2x from '~/media/cards/membre-b-2x.webp';
import cardSilverB2x from '~/media/cards/silver-b-2x.webp';
import cardGoldB2x from '~/media/cards/gold-b-2x.webp';
import cardPlatinumB2x from '~/media/cards/platinum-b-2x.webp';

export const CARD_MEMBRE_IMG = {
  urlB: cardMembreB,
  urlB2x: cardMembreB2x,
  urlS: cardMembreS,
  urlS2x: cardMembreS2x,
};

export const CARD_SILVER_IMG = {
  urlB: cardSilverB,
  urlB2x: cardSilverB2x,
  urlS: cardSilverS,
  urlS2x: cardSilverS2x,
};

export const CARD_GOLD_IMG = {
  urlB: cardGoldB,
  urlB2x: cardGoldB2x,
  urlS: cardGoldS,
  urlS2x: cardGoldS2x,
};

export const CARD_PLATINUM_IMG = {
  urlB: cardPlatinumB,
  urlB2x: cardPlatinumB2x,
  urlS: cardPlatinumS,
  urlS2x: cardPlatinumS2x,
};

export const CARD_PRIVE_IMG = {
  urlS: cardPriveS,
  urlS2x: cardPriveS2x,
};
