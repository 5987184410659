import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Container from 'common/components/Container';
import ContentLoader from 'common/components/ContentLoader';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';
import { ReactComponent as IconCheck } from 'common/media/icons/check.icon.svg';

import MyAccModal from '~/components/MyAccModal';

import WishlistModalHandler from '../../containers/WishlistModalHandler';
import styles from './WishlistShareModal.module.scss';

const cx = classNames.bind(styles);

const WishlistShareModal = ({ handleModalClose }) => {
  const { t } = useTranslation('wishlist');

  return (
    <MyAccModal className={cx('modal-share')} onClose={handleModalClose}>
      <Container width={760} className={cx('modal-share-container')}>
        <WishlistModalHandler>
          {({
            shareItems,
            sharedButtons,
            wishlistPublished,
            unpublishWishlist,
            wishlistPublishLoading,
          }) => (
            <>
              <div className={cx('modal-share-header')}>
                <button
                  className={cx('button-close')}
                  onClick={handleModalClose}
                >
                  <IconContainer
                    className={cx('icon-close')}
                    icon={IconClose}
                  />
                </button>
                <h3 className={cx('title')}>
                  {t('shareModal.shareModalTitle')}
                </h3>
              </div>

              <div className={cx('modal-share-grid')}>
                {shareItems.map(({ type, icon, label, onClick }) => (
                  <button
                    role="link"
                    type="button"
                    className={cx('modal-share-grid-item')}
                    key={type}
                    onClick={() => onClick(type)}
                  >
                    <IconContainer icon={icon} className={cx('social-icon')} />
                    <span className={cx('social-label')}>{label}</span>
                    {sharedButtons.includes(type) && (
                      <IconContainer
                        icon={IconCheck}
                        className={cx('check-icon')}
                      />
                    )}
                  </button>
                ))}
              </div>

              {wishlistPublished && (
                <div className={cx('modal-share-footer')}>
                  <button
                    className={cx('button-unpublish')}
                    onClick={unpublishWishlist}
                  >
                    {t('shareModal.unpublishLabel')}
                  </button>
                </div>
              )}

              {wishlistPublishLoading && (
                <div className={cx('loader-wrapper')}>
                  <ContentLoader />
                </div>
              )}
            </>
          )}
        </WishlistModalHandler>
      </Container>
    </MyAccModal>
  );
};

export default WishlistShareModal;
