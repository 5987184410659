import React, { useState, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';

import { createUseContext } from 'common/lib/context';

const {
  Context: WebSocketContext,
  ContextProvider: WebSocketContextProvider,
  ContextConsumer: WebSocketContextConsumer,
  useContext: useWebSocketContext,
} = createUseContext((Provider) => ({ children, client }) => {
  const [maintenance, setMaintenance] = useState(false);

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(
    process.env.REACT_APP_AWS_WEBSOCKET_URL
  );

  useEffect(() => {
    if (readyState) {
      sendJsonMessage({ action: 'maintenanceMode' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState]);

  useEffect(() => {
    if (lastMessage?.data) {
      const messageData = JSON.parse(lastMessage.data);

      if (messageData?.action === 'maintenanceMode') {
        setMaintenance(messageData.value);
      }

      if (messageData?.action === 'cache' && messageData?.value === 'refresh') {
        client.queryManager.queryDeduplication = false;
        client.reFetchObservableQueries();
        client.queryManager.queryDeduplication = true;
      }
    }
  }, [client, lastMessage]);

  return (
    <Provider
      value={{
        maintenance,
        sendJsonMessage,
        lastMessage,
      }}
    >
      {children}
    </Provider>
  );
});

export {
  WebSocketContextConsumer,
  WebSocketContextProvider,
  useWebSocketContext,
};

export default WebSocketContext;
