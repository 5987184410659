import React, { useMemo, useCallback } from 'react';
import { array, string, bool } from 'prop-types';
import classNames from 'classnames/bind';
import moment from 'moment';

import chunkArrayInGroups from 'common/utils/chunkArrayInGroups';

import AccordionDropDown from '../AccordionDropDown';
import LoadMoreContainer from '../../containers/LoadMoreContainer';

import styles from './LoyaltyMachineMessages.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  log: array.isRequired,
  loadMoreButtonLabel: string.isRequired,
  className: string,
  windowResized: bool.isRequired,
};

const defaultProps = {
  className: null,
};

function ApiLogsMessages({
  log,
  loadMoreButtonLabel,
  className,
  windowResized,
}) {
  const logsGroup = useMemo(() => chunkArrayInGroups(log, 50), [log]);

  const renderLogMessage = useCallback(
    ({ time, message }, index) => {
      return (
        <div key={index} className={cx('message-wrapper')}>
          <AccordionDropDown windowResized={windowResized}>
            <div className={cx('time')}>
              {moment.unix(time / 1000).format('YYYY-MM-DD hh:mm:ss')}
            </div>
            <pre className={cx('message-text')}>{message.trim()}</pre>
          </AccordionDropDown>
        </div>
      );
    },
    [windowResized]
  );

  const renderGroups = useCallback(
    (loadMorePage) =>
      logsGroup
        .slice(0, loadMorePage)
        .map((group) =>
          group.map((item, index) => renderLogMessage(item, index))
        ),
    [logsGroup, renderLogMessage]
  );

  return (
    <div className={cx('root', className)}>
      <div className={cx('logs-wrapper')}>
        <div className={cx('logs-wrapper')}>
          <LoadMoreContainer
            list={logsGroup}
            loadMoreButtonLabel={loadMoreButtonLabel}
          >
            {({ loadMorePage }) => renderGroups(loadMorePage)}
          </LoadMoreContainer>
        </div>
      </div>
    </div>
  );
}

ApiLogsMessages.propTypes = propTypes;
ApiLogsMessages.defaultProps = defaultProps;

export default ApiLogsMessages;
