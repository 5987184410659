import React, { useState } from 'react';
import { func, object, number } from 'prop-types';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { getLoyaltyStatusLabel } from 'common/utils/userMenuUtils';

import PageLoader from '~/components/PageLoader';
import { sbmClient } from '~/apolloClient';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

const propTypes = {
  children: func.isRequired,
  query: object.isRequired,
  myPoints: number,
  loyaltyStatus: number.isRequired,
};

const OffersFiltersHandler = ({ children, loyaltyStatus, myPoints, query }) => {
  const filtersType = 'offer_theme';
  const [activeFilter, setActiveFilter] = useState({
    type: 'all',
    id: '',
  });
  const { t } = useTranslation('offers-info');

  const { loading, data } = useQuery(query, {
    client: sbmClient,
    variables: {
      type: 'offer',
      points: {
        lessThan: String(myPoints),
      },
      includePoints: !!myPoints,
      attributes: [
        {
          type: 'offer_loyalty',
          filters: [getLoyaltyStatusLabel(loyaltyStatus)],
        },
      ],
    },
    context: {
      headers: {
        'X-Acc-Grp': loyaltyStatus,
      },
    },
  });

  if (loading || !activeFilter) {
    return <PageLoader />;
  }

  const {
    items: { filters },
    myPoints: myPointsData,
    allItems: { count: allCount },
  } = data?.listing;

  const listFilters = filters?.attributes?.find(
    (filter) => filter.type === filtersType
  );

  const allFilter = {
    id: '',
    count: allCount,
    name: t('allPointsFilter'),
  };

  const pointsFilter = {
    id: 'myPoints',
    count: myPointsData?.count || 0,
    name: t('myPointsFilter'),
  };

  const onFilterToggle = function (id, type) {
    setActiveFilter({
      type,
      id,
    });
  };

  return children({
    listFilters: {
      all: [allFilter],
      [filtersType]: listFilters?.attributes || [],
      points: [pointsFilter],
    },
    activeFilter,
    onFilterToggle,
  });
};

OffersFiltersHandler.propTypes = propTypes;

export default withErrorBoundary(OffersFiltersHandler);
