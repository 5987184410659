import React, { useMemo } from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';

import SubmitButton from '~/components/SubmitButton';

import styles from './LoyaltyMachine.module.scss';
import LoyaltyMachineMessages from '../LoyaltyMachineMessages';
import SlideDownWrapper from '../../containers/SlideDownWrapper';
import WindowResizeListenerHandler from '../../containers/WindowResizeListenerHandler';
import LoyaltyMachineHandler from '../../containers/LoyaltyMachineHandler';

const cx = classNames.bind(styles);

const loyaltyMachineDataFragment = gql`
  fragment loyaltyMachineFields on SbmLoyaltyStatus {
    status
    state
    stateDescription
    log {
      time
      message
    }
  }
`;

const propTypes = {
  data: object.isRequired,
  parentRef: object.isRequired,
};

const defaultProps = {
  className: null,
};

const fragment = {
  loyaltyMachineDataFragment,
};

function LoyaltyMachine({ data, parentRef }) {
  const { t } = useTranslation('edit-account');
  const loyaltyMachineBlock = t('loyaltyMachineBlock', {
    returnObjects: true,
  });

  const {
    loyaltyMachineTitle,
    resetMachineLabel,
    resetDoneMachineLabel,
    resetMachineConfirmationMessage,
    resetMachineConfirmationYes,
    resetMachineConfirmationNo,
    resetCounterLabel,
    resetDoneCounterLabel,
    loadMoreButtonLabel,
    stateDescriptions,
    stateTitles,
  } = loyaltyMachineBlock;

  const { stateDescription: stateDescriptionKey, log, state: stateKey } = data;
  const stateDescription = useMemo(
    () => stateDescriptions[stateDescriptionKey?.substring(1)],
    [stateDescriptionKey, stateDescriptions]
  );
  const stateTitle = useMemo(() => stateTitles[stateKey?.substring(1)], [
    stateKey,
    stateTitles,
  ]);

  return (
    <div className={cx('root')}>
      <LoyaltyMachineHandler>
        {({
          resetMachineData,
          isConfirmResetMachineVisible,
          handleResetMachine,
          handleConfirmResetMachine,
          handleRejectResetMachine,
          isButtonsDisabled,
          resetCounterData,
          handleResetCounter,
        }) => (
          <>
            {!!stateTitle && (
              <div className={cx('message-wrapper')}>
                <span>{stateTitle}</span>
              </div>
            )}
            {!!stateDescription && (
              <div className={cx('message-wrapper')}>
                <div
                  dangerouslySetInnerHTML={{ __html: stateDescription.trim() }}
                />
              </div>
            )}
            {!!log.length && (
              <div className={cx('content-wrapper')}>
                <SlideDownWrapper
                  subtitleButtonLabel={loyaltyMachineTitle}
                  parentRef={parentRef}
                >
                  <WindowResizeListenerHandler>
                    {({ windowResized }) => (
                      <LoyaltyMachineMessages
                        log={log}
                        loadMoreButtonLabel={loadMoreButtonLabel}
                        windowResized={windowResized}
                      />
                    )}
                  </WindowResizeListenerHandler>
                </SlideDownWrapper>
              </div>
            )}
            <div className={cx('buttons-wrapper')}>
              <SubmitButton
                saveButtonLabel={resetCounterLabel}
                savedButtonLabel={resetDoneCounterLabel}
                onClick={handleResetCounter}
                isSubmitting={resetCounterData.isSubmitting}
                isSaved={resetCounterData.isSaved}
                isSubmitDisabled={isButtonsDisabled}
              />
            </div>
            <div className={cx('buttons-wrapper')}>
              <SubmitButton
                saveButtonLabel={resetMachineLabel}
                savedButtonLabel={resetDoneMachineLabel}
                onClick={handleResetMachine}
                isSubmitting={resetMachineData.isSubmitting}
                isSaved={resetMachineData.isSaved}
                isSubmitDisabled={isButtonsDisabled}
                inverted
              />
              {isConfirmResetMachineVisible && (
                <div className={cx('confirmation-message-wrapper')}>
                  <div className={cx('confirmation-text-wrapper')}>
                    <span className={cx('confirmation-text')}>
                      {resetMachineConfirmationMessage}
                    </span>
                  </div>
                  <div className={cx('confirmation-buttons-wrapper')}>
                    <div className={cx('confirmation-button-item')}>
                      <SubmitButton
                        saveButtonLabel={resetMachineConfirmationYes}
                        onClick={handleConfirmResetMachine}
                        inverted
                      />
                    </div>
                    <div className={cx('confirmation-button-item')}>
                      <SubmitButton
                        saveButtonLabel={resetMachineConfirmationNo}
                        onClick={handleRejectResetMachine}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </LoyaltyMachineHandler>
    </div>
  );
}

LoyaltyMachine.propTypes = propTypes;
LoyaltyMachine.defaultProps = defaultProps;
LoyaltyMachine.fragment = fragment;

export default LoyaltyMachine;
