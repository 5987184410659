import { useState, useCallback } from 'react';
import { func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import useEffectOnUpdate from 'common/hooks/useEffectOnUpdate';
import gtmPushEvent from 'common/utils/gtmPushEvent';

const propTypes = {
  children: func.isRequired,
};

const initialValue = {
  isSubmitting: false,
  isSaved: false,
};

const resetLoyaltyMachineMutation = gql`
  mutation resetLoyaltyMachine($id: String!) {
    resetLoyaltyMachine(id: $id) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const resetLoyaltyCounterMutation = gql`
  mutation resetLoyaltyCounter($id: String!) {
    resetLoyaltyCounter(id: $id) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

function LoyaltyMachineHandler({ children, match: { params } }) {
  const userId = params?.id;
  const [resetMachineData, setResetMachineData] = useState(initialValue);
  const [resetCounterData, setResetCounterData] = useState(initialValue);
  const [isButtonsDisabled, setButtonsDisabledStatus] = useState(false);
  const [isConfirmResetMachineVisible, setConfirmResetMachineVisibleStatus] =
    useState(false);

  const [mutateResetMachine] = useMutation(resetLoyaltyMachineMutation);
  const [mutateResetCounter] = useMutation(resetLoyaltyCounterMutation);

  useEffectOnUpdate(() => {
    setButtonsDisabledStatus(
      resetCounterData.isSubmitting ||
        resetMachineData.isSubmitting ||
        isConfirmResetMachineVisible
    );
  }, [
    resetCounterData.isSubmitting,
    resetMachineData.isSubmitting,
    isConfirmResetMachineVisible,
  ]);

  const resetValues = useCallback(() => {
    setResetMachineData({ ...initialValue });
    setResetCounterData({ ...initialValue });
  }, [setResetMachineData, setResetCounterData]);

  const handleResetCounter = () => {
    resetValues();
    setResetCounterData({ ...resetCounterData, isSubmitting: true });

    mutateResetCounter({
      variables: { id: userId },
      refetchQueries: ['EditAccountData'],
    })
      .then(({ data: { resetLoyaltyCounter } }) => {
        if (!!resetLoyaltyCounter) {
          setResetCounterData({
            ...resetCounterData,
            isSubmitting: false,
            isSaved: true,
          });

          gtmPushEvent({
            event: 'click_reset_link_counter',
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const handleResetMachine = () => {
    setConfirmResetMachineVisibleStatus(true);
  };

  const handleConfirmResetMachine = () => {
    setConfirmResetMachineVisibleStatus(false);
    resetValues();
    setResetMachineData({ ...resetMachineData, isSubmitting: true });

    mutateResetMachine({
      variables: { id: userId },
      refetchQueries: ['EditAccountData'],
    })
      .then(({ data: { resetLoyaltyMachine } }) => {
        if (!!resetLoyaltyMachine) {
          setResetMachineData({
            ...resetMachineData,
            isSubmitting: false,
            isSaved: true,
          });

          gtmPushEvent({
            event: 'click_reset_machine',
          });
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const handleRejectResetMachine = () => {
    setConfirmResetMachineVisibleStatus(false);
  };

  return children({
    resetMachineData,
    resetCounterData,
    isConfirmResetMachineVisible,
    handleResetMachine,
    handleConfirmResetMachine,
    handleRejectResetMachine,
    handleResetCounter,
    isButtonsDisabled,
  });
}

LoyaltyMachineHandler.propTypes = propTypes;

export default withRouter(LoyaltyMachineHandler);
