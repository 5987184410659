import { useState, useMemo, useCallback } from 'react';
import { func } from 'prop-types';
import * as Yup from 'yup';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { MYACC_LANGUAGE } from 'common/constants';

import applyNameFormat from '~/utils/applyNameFormat';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import {
  CLIENT_APP_VERSION,
  latinLettersRegexp,
  latinLettersWithNumbersEmailRegexp,
} from '~/constants';

const OneClickSendMutation = gql`
  mutation updateSbmOneclickDataInput($value: SbmOneclickDataInput!) {
    updateSbmOneclickDataInput(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  children: func.isRequired,
};

const initialValues = {
  email: '',
  gender: '',
  name: '',
  familyName: '',
  marriedName: '',
  birthDate: '',
  phoneNumber: '',
  country: '',
  lang: '',
  occupation: '',
  foreignIds: [{ sourceName: '', sourceKey: '' }],
};

const OneClickFormHandler = ({ children }) => {
  const [submitError, setSubmitError] = useState(false);
  const [submitErrorMessage, setSubmitErrorMessage] = useState();
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess) {
      setSubmitError(false);
      setSubmitSuccess(false);
    }
  }, [submitError, submitSuccess]);

  const { t } = useTranslation(['auth', 'oneclick']);
  const fieldGenderMaleLabel = t('oneclick:fieldGenderMaleLabel');
  const fieldGenderFemaleLabel = t('oneclick:fieldGenderFemaleLabel');
  const foreignIdsHotelLabel = t('oneclick:foreignIdsHotelLabel');
  const foreignIdsComptewebLabel = t('oneclick:foreignIdsComptewebLabel');
  const foreignIdsCasinoLabel = t('oneclick:foreignIdsCasinoLabel');
  const foreignIdsWellbeingLabel = t('oneclick:foreignIdsWellbeingLabel');
  const foreignIdsMappLabel = t('oneclick:foreignIdsMappLabel');
  const foreignIdsAparteLabel = t('oneclick:foreignIdsAparteLabel');
  const validationRequiredFieldMessage = t(
    'create.validationRequiredFieldMessage'
  );
  const validationCharsOnlyMessage = t('create.validationCharsOnlyMessage');
  const validationSameNamesMessage = t('create.validationSameNamesMessage');

  const foreignIdsOptions = useMemo(
    () => [
      { value: 'HOTEL', label: foreignIdsHotelLabel },
      { value: 'COMPTEWEB', label: foreignIdsComptewebLabel },
      { value: 'CASINO', label: foreignIdsCasinoLabel },
      { value: 'WELLBEING', label: foreignIdsWellbeingLabel },
      { value: 'MAPP', label: foreignIdsMappLabel },
      { value: 'APARTE', label: foreignIdsAparteLabel },
    ],
    [
      foreignIdsAparteLabel,
      foreignIdsCasinoLabel,
      foreignIdsComptewebLabel,
      foreignIdsHotelLabel,
      foreignIdsMappLabel,
      foreignIdsWellbeingLabel,
    ]
  );

  const gender = useMemo(
    () => [
      { value: 'female', label: fieldGenderFemaleLabel },
      { value: 'male', label: fieldGenderMaleLabel },
    ],
    [fieldGenderFemaleLabel, fieldGenderMaleLabel]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(validationCharsOnlyMessage)
          .matches(
            latinLettersWithNumbersEmailRegexp,
            validationCharsOnlyMessage
          )
          .required(validationRequiredFieldMessage),
        gender: Yup.string().required(validationRequiredFieldMessage),
        name: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationCharsOnlyMessage),
        familyName: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationCharsOnlyMessage)
          .test('not-same', validationSameNamesMessage, function (value) {
            return value?.toLowerCase() !== this.parent.name?.toLowerCase();
          }),
        marriedName: Yup.string().matches(
          latinLettersRegexp,
          validationCharsOnlyMessage
        ),
        birthDate: Yup.string().required(validationRequiredFieldMessage),
        country: Yup.string().required(validationRequiredFieldMessage),
        lang: Yup.string().required(validationRequiredFieldMessage),
        occupation: Yup.string().required(validationRequiredFieldMessage),
        foreignIds: Yup.array().of(
          Yup.object().shape(
            {
              sourceKey: Yup.string().when('sourceName', {
                is: (sourceNameValue) => sourceNameValue,
                then: Yup.string().required(validationRequiredFieldMessage),
                otherwise: Yup.string(),
              }),
              sourceName: Yup.string().when('sourceKey', {
                is: (sourceKeyValue) => sourceKeyValue,
                then: Yup.string().required(validationRequiredFieldMessage),
                otherwise: Yup.string(),
              }),
            },
            ['sourceName', 'sourceKey']
          )
        ),
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [mutate] = useMutation(OneClickSendMutation);

  const handleSubmit = (values, { setSubmitting, setFieldValue }) => {
    const { name, familyName, marriedName, lang, ...restValues } = values;

    const languageNormalized = MYACC_LANGUAGE.find(
      ({ langISO3 }) => langISO3 === lang
    ).langId;

    setSubmitError(false);
    setSubmitSuccess(false);

    setFieldValue('name', applyNameFormat(name));
    setFieldValue('familyName', applyNameFormat(familyName));
    setFieldValue('marriedName', applyNameFormat(marriedName));

    const submitFields = {
      ...restValues,
      lang: languageNormalized,
      name: applyNameFormat(name),
      familyName: applyNameFormat(familyName),
      marriedName: applyNameFormat(marriedName),
      foreignIds: values.foreignIds.some(({ sourceKey }) => sourceKey === '')
        ? []
        : values.foreignIds,
      clientAppVersion: CLIENT_APP_VERSION,
    };

    mutate({
      variables: {
        value: submitFields,
      },
    })
      .then(({ data: { updateSbmOneclickDataInput } }) => {
        setSubmitting(false);

        if (updateSbmOneclickDataInput?.result) {
          setSubmitSuccess(true);
        } else {
          setSubmitErrorMessage({
            messages: updateSbmOneclickDataInput.messages,
          });
          setSubmitError(true);
        }
      })
      .catch((error) => {
        console.warn(error);
        setSubmitError(true);
      });
  };

  const resetForm = (formikReset) => {
    setSubmitError(false);
    setSubmitSuccess(false);
    formikReset(initialValues);
  };

  return children({
    foreignIdsOptions,
    gender,
    handleSubmit,
    resetForm,
    initialValues,
    validationSchema,
    submitError,
    submitErrorMessage,
    submitSuccess,
    resetMessage,
  });
};

OneClickFormHandler.propTypes = propTypes;

export default withErrorBoundary(OneClickFormHandler);
