import { useMemo, useState, useEffect } from 'react';
import { func, array, string, bool } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { useDebounce, useDebouncedCallback } from 'use-debounce';

import { default as lodashValues } from 'lodash/values';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import usePrevious from 'common/hooks/usePrevious';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';
import {
  latinLettersRegexp,
  latinLettersWithNumbersRegexp,
  latinLettersWithNumbersEmailRegexp,
  zipCodeRegexp,
} from '~/constants';
import {
  stringifyFieldData,
  getEmailNumberList,
  getEmailGroups,
  getPhoneNumberList,
  getPhoneGroups,
  getAddressNumberList,
  getAddressGroups,
  markDeletedFields,
  isFieldMandatory,
  handleEmptyCategory,
  getMandatoryGroups,
  subAddressFieldKeys,
} from '~/scenes/FormContacts/utils';

import useContacts from '../../hooks/useContacts';

const gtmAccountInformationParams = {
  event: 'save_account_info_settings',
};

const EditContactsSendMutation = gql`
  mutation updateCustomerSbmContacts($id: String!, $value: SbmContactsInput!) {
    updateCustomerSbmContacts(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const PreferencesSendMutation = gql`
  mutation updateAccountInformation($value: SbmContactsInput!) {
    updateSbmContacts(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  isMyCs: bool,
  userHash: string,
  children: func.isRequired,
  email: array.isRequired,
  phone: array,
  address: array,
  validationRequiredFieldMessage: string.isRequired,
  validationIncorrectEmailMessage: string.isRequired,
  validationLettersOnlyMessage: string.isRequired,
  validationLettersAndNumbersOnlyMessage: string.isRequired,
};

const defaultProps = {
  isMyCs: false,
  userHash: null,
  phone: null,
  address: null,
};

const FormContactsHandler = ({
  isMyCs,
  userHash,
  children,
  email,
  phone,
  address,
  validationRequiredFieldMessage,
  validationIncorrectEmailMessage,
  validationLettersOnlyMessage,
  validationLettersAndNumbersOnlyMessage,
}) => {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isValidationErrorVisible, setValidationErrorVisible] = useState(false);
  const [isAllPhonesValid, setAllPhonesValid] = useState(true);

  const { refetchUserData } = useUserContext();

  const {
    emailList,
    emailCategoryList,
    phoneList,
    phoneCategoryList,
    addressList,
    addressCategoryList,
    formDispatch,
  } = useContacts({
    email,
    phone,
    address,
  });

  const clearEmptyValues = ({ setFieldValue }) =>
    formDispatch({
      type: 'clearEmptyValues',
      formValues,
      setFieldValue,
      emailList: emailList,
      phoneList: phoneList,
      addressList: addressList,
    });

  const initialValues = useMemo(() => {
    const initialMails = emailList.reduce(
      (values, item) => ({
        ...values,
        [item.name]: stringifyFieldData(item.value),
        [item.catName]: stringifyFieldData(item.catValue),
      }),
      {}
    );

    const initialPhones = phoneList.reduce(
      (values, item) => ({
        ...values,
        [item.name]: stringifyFieldData(item.value),
        [item.catName]: stringifyFieldData(item.catValue),
      }),
      {}
    );

    const initialAddresses = addressList.reduce(
      (values, item) => ({
        ...values,
        [item.name]: stringifyFieldData(item.value),
        [item.secondaryName]: stringifyFieldData(item.secondaryValue),
        [item.zipCodeName]: stringifyFieldData(item.zipCodeValue),
        [item.cityName]: stringifyFieldData(item.cityValue),
        [item.stateName]: stringifyFieldData(item.stateValue),
        [item.countryName]: stringifyFieldData(item.countryValue),
        [item.catName]: stringifyFieldData(item.catValue),
      }),
      {}
    );

    return {
      ...initialMails,
      ...initialPhones,
      ...initialAddresses,
    };
  }, [emailList, phoneList, addressList]);

  const [formValues, setFormValues] = useState(initialValues);
  const prevFieldValues = usePrevious(formValues);

  const [onFormChange] = useDebouncedCallback(() => {
    if (
      submitError ||
      submitSuccess ||
      !isAllPhonesValid ||
      !isEqual(prevFieldValues, formValues)
    ) {
      setSubmitError(false);
      setSubmitSuccess(false);
      setValidationErrorVisible(false);
    }
  }, 300);

  /*
    Address Groups mandatory fields
    Start
  */
  const filteredAddressGroup = useMemo(() => {
    const addressNumberList = getAddressNumberList(Object.entries(formValues));
    const addressGroups = getMandatoryGroups({
      addressNumberList,
      valuesList: Object.entries(formValues),
      subFieldKeysArray: subAddressFieldKeys,
    });
    return addressGroups.filter(
      (groupItem) => !lodashValues(groupItem).every(isEmpty)
    );
  }, [formValues]);

  const [mandatoryAddressGroups, setMandatoryAddressGroups] =
    useState(filteredAddressGroup);

  const [mandatoryAddressGroupsDB] = useDebounce(mandatoryAddressGroups, 300);

  useEffect(() => {
    setMandatoryAddressGroups(filteredAddressGroup);
  }, [filteredAddressGroup]);

  /*
    Address Groups mandatory fields
    End
  */

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...emailList.reduce((acc, { name, isDeletable }) => {
          let formItemEmailRule = Yup.string()
            .email(validationIncorrectEmailMessage)
            .matches(
              latinLettersWithNumbersEmailRegexp,
              validationIncorrectEmailMessage
            );

          if (!isDeletable) {
            formItemEmailRule = formItemEmailRule.required(
              validationRequiredFieldMessage
            );
          }

          acc[name] = formItemEmailRule;

          return acc;
        }, {}),
        ...addressList.reduce(
          (
            acc,
            {
              name,
              secondaryName,
              zipCodeName,
              cityName,
              stateName,
              countryName,
              catName,
            }
          ) => {
            let formItemNameRule = Yup.string().matches(
              latinLettersWithNumbersRegexp,
              validationLettersAndNumbersOnlyMessage
            );

            if (isFieldMandatory(mandatoryAddressGroupsDB, name)) {
              formItemNameRule = formItemNameRule.required(
                validationRequiredFieldMessage
              );
            }

            acc[name] = formItemNameRule;

            acc[secondaryName] = Yup.string().matches(
              latinLettersWithNumbersRegexp,
              validationLettersAndNumbersOnlyMessage
            );

            acc[stateName] = Yup.string().matches(
              latinLettersRegexp,
              validationLettersOnlyMessage
            );

            let formItemZipCodeRule = Yup.string().matches(
              zipCodeRegexp,
              validationLettersAndNumbersOnlyMessage
            );

            acc[zipCodeName] = formItemZipCodeRule;

            let formItemCityRule = Yup.string().matches(
              latinLettersRegexp,
              validationLettersOnlyMessage
            );

            if (isFieldMandatory(mandatoryAddressGroupsDB, cityName)) {
              formItemCityRule = formItemCityRule.required(
                validationRequiredFieldMessage
              );
            }
            acc[cityName] = formItemCityRule;

            let formItemCountryRule = Yup.string().matches(
              latinLettersRegexp,
              validationLettersOnlyMessage
            );

            if (isFieldMandatory(mandatoryAddressGroupsDB, countryName)) {
              formItemCountryRule = formItemCountryRule.required(
                validationRequiredFieldMessage
              );
            }

            acc[countryName] = formItemCountryRule;

            return acc;
          },
          {}
        ),
      }),
    [
      emailList,
      addressList,
      validationIncorrectEmailMessage,
      validationRequiredFieldMessage,
      validationLettersOnlyMessage,
      validationLettersAndNumbersOnlyMessage,
      mandatoryAddressGroupsDB,
    ]
  );

  const [mutate] = useMutation(
    isMyCs ? EditContactsSendMutation : PreferencesSendMutation
  );

  const handleSubmit = (values, { setFieldValue, setSubmitting }) => {
    const isAllPhonesValid =
      phoneList.find(({ validationStatus }) => !validationStatus) === undefined;

    setSubmitSuccess(false);
    setAllPhonesValid(isAllPhonesValid);
    setValidationErrorVisible(true);

    if (!isAllPhonesValid) {
      setSubmitting(false);
    } else {
      clearEmptyValues({ setFieldValue }); //@todo: to refactor this function
      setSubmitting(true);

      const valuesList = Object.entries(values);

      const emailNumberList = getEmailNumberList(valuesList);
      const phoneNumberList = getPhoneNumberList(valuesList);
      const addressNumberList = getAddressNumberList(valuesList);

      const emailGroups = getEmailGroups({
        emailList,
        emailNumberList,
        valuesList,
      }).filter(({ email }) => !!email);

      const phoneGroupsNormalized = getPhoneGroups({
        phoneList,
        phoneNumberList,
        valuesList,
      }).filter(({ phone }) => !!phone);

      const addressGroups = getAddressGroups({
        addressList,
        addressNumberList,
        valuesList,
      }).filter(
        ({ address1, city, country }) => !!(address1 || city || country)
      );

      handleEmptyCategory(emailGroups);
      handleEmptyCategory(phoneGroupsNormalized);
      handleEmptyCategory(addressGroups);

      const submitFields = {
        email: emailGroups,
        phone: phoneGroupsNormalized,
        address: addressGroups,
      };

      if (isMyCs) {
        mutate({
          variables: { id: userHash, value: submitFields },
        })
          .then(({ data: { updateCustomerSbmContacts } }) => {
            setSubmitting(false);
            if (updateCustomerSbmContacts) {
              setSubmitSuccess(true);
              setTimeout(() => setSubmitSuccess(false), 6000); // @todo: recheck this functionality
            }
          })
          .catch((error) => {
            console.warn(error);
            setSubmitError(true);
            setSubmitting(false);
          });
      } else {
        mutate({
          variables: { value: submitFields },
        })
          .then(({ data: { updateSbmContacts } }) => {
            setSubmitting(false);
            if (updateSbmContacts?.result) {
              setSubmitSuccess(true);
              refetchUserData();
              gtmPushEvent(gtmAccountInformationParams);
            } else {
              setSubmitError(true);
            }
          })
          .catch((error) => {
            console.warn(error);
            setSubmitError(true);
            setSubmitting(false);
          });
      }
    }
  };

  return children({
    handleSubmit,
    initialValues,
    validationSchema,
    emailList,
    emailCategoryList,
    phoneList,
    phoneCategoryList,
    addressList,
    addressCategoryList,
    markDeletedFields,
    submitError,
    submitSuccess,
    onFormChange,
    formValues,
    formDispatch,
    isAllPhonesValid,
    isValidationErrorVisible,
    setValidationErrorVisible,
    mandatoryAddressGroups: mandatoryAddressGroupsDB,
    setFormValues,
  });
};

FormContactsHandler.propTypes = propTypes;
FormContactsHandler.defaultProps = defaultProps;

export default withErrorBoundary(FormContactsHandler);
