import React from 'react';
import classNames from 'classnames/bind';
import { bool, func } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { gql } from '@apollo/client';

import Picture from 'common/components/Picture';
import getPictureSources from 'common/utils/getPictureSources';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import BestPriceBlock from '~/components/BestPriceBlock';
import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import OfferTags from '~/components/OfferTags';
import WishlistButton from '~/components/WishlistButton';

import styles from './OfferCard.module.scss';

const { REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const offerPictureFragment = gql`
  fragment offerPictureFragment on MediaImage {
    id
    image {
      id
      desktop: url(style: list_item_short_style_desktop_webp) {
        ...urlFields
      }
      desktop2x: url(style: list_item_short_style_desktop_2x_webp) {
        ...urlFields
      }
      tablet: url(style: list_item_common_tablet_webp) {
        ...urlFields
      }
      tablet2x: url(style: list_item_common_tablet_2x_webp) {
        ...urlFields
      }
      mobile: url(style: list_item_common_mobile_webp) {
        ...urlFields
      }
      mobile2x: url(style: list_item_common_mobile_2x_webp) {
        ...urlFields
      }
      alt
    }
  }
  fragment urlFields on ImageStyleUrl {
    width
    height
    url
  }
`;

const offerCardFragment = gql`
  fragment offerCardFragment on Offer {
    title
    id
    url
    ctaLabel
    introText(length: 200)
    media(first: 1, after: 0) {
      ...offerPictureFragment
    }
    offerEndDate
    myAccountCtaLabel
    priceLabel
    priceEmptyLabel
    currency
    isMyPointsEnabled
    bestOffer {
      price
      dateRange
      priceBeforeDiscount
      pricePoints
    }
  }
  ${offerPictureFragment}
`;

const propTypes = {
  data: propType(offerCardFragment).isRequired,
  isUnauthorised: bool,
  onUnauthorisedCardClick: func,
};

const defaultProps = {
  isUnauthorised: false,
  onUnauthorisedCardClick: null,
};

const OfferCard = function ({
  data: {
    id,
    ctaLabel,
    myAccountCtaLabel,
    title,
    introText,
    media,
    isMyPointsEnabled,
    offerEndDate,
    priceLabel,
    priceEmptyLabel,
    currency,
    bestOffer,
  },
  isUnauthorised,
  onUnauthorisedCardClick,
}) {
  const offerLink = isUnauthorised
    ? `${REACT_APP_MYACC_DOMAIN}`
    : `${REACT_APP_MYACC_DOMAIN}/offers/${id}`;

  const thumbnail = media?.[0]?.image;
  const sources = getPictureSources(thumbnail);

  return (
    <MyAccLink
      className={cx('root', Button.shared.hovered)}
      to={offerLink}
      onClick={(e) => {
        if (onUnauthorisedCardClick) {
          onUnauthorisedCardClick(e);
        }
      }}
    >
      <WishlistButton id={id} />

      <div className={cx('thumbnail-wrapper')}>
        <Picture
          sources={sources}
          className={cx('thumbnail')}
          src={thumbnail.desktop.url}
          alt={thumbnail.alt}
        />
      </div>
      <div className={cx('content-wrapper')}>
        <h2 className={cx('title')}>{title}</h2>
        <p className={cx('dates')}>{bestOffer.dateRange}</p>
        <OfferTags
          discount={!!bestOffer.priceBeforeDiscount}
          endDate={offerEndDate}
          className={cx('tag-list')}
        />
        <div
          className={cx('intro')}
          dangerouslySetInnerHTML={{ __html: introText }}
        />
      </div>
      <div className={cx('footer-wrapper')}>
        {!isUnauthorised && (
          <BestPriceBlock
            price={bestOffer.price}
            oldPrice={bestOffer.priceBeforeDiscount}
            points={isMyPointsEnabled ? bestOffer.pricePoints : null}
            priceLabel={priceLabel}
            currency={currency}
            priceEmptyLabel={priceEmptyLabel}
          />
        )}
        {(myAccountCtaLabel || ctaLabel) && (
          <Button theme="rounded" size="m" color="tallow-invert">
            {myAccountCtaLabel || ctaLabel}
          </Button>
        )}
      </div>
    </MyAccLink>
  );
};

OfferCard.propTypes = propTypes;
OfferCard.defaultProps = defaultProps;
OfferCard.fragments = {
  offerCardFragment,
};

export default withErrorBoundary(OfferCard);
