import React from 'react';
import classNames from 'classnames/bind';
import { string, bool } from 'prop-types';

import styles from './PanelField.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  isSeparator: bool,
};

const defaultProps = {
  isSeparator: true,
};

const PanelField = ({ label, value, isSeparator }) => (
  <div className={cx('root')}>
    {label && (
      <span className={cx('label', { 'label-simple': !isSeparator })}>
        {isSeparator ? `${label}:` : `${label}`}
      </span>
    )}
    <span className={cx('value')}>{value}</span>
  </div>
);

PanelField.propTypes = propTypes;
PanelField.defaultProps = defaultProps;

export default PanelField;
