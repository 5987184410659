import { useMemo } from 'react';
import { func, object } from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { latinLettersWithNumbersAwsRegexp } from '~/constants';
import { useFriendlyCaptcha } from '~/hooks';

const propTypes = {
  children: func.isRequired,
  signUpData: object,
};

const defaultProps = {
  signUpData: null,
};

function FormSignUpComboHandler({ children }) {
  const { captchaStatus, captchaReset, CaptchaWidget } = useFriendlyCaptcha();

  const { t } = useTranslation('auth');
  const fieldMaleLabel = t('create.fieldMaleLabel');
  const fieldFemaleLabel = t('create.fieldFemaleLabel');

  const validationRequiredFieldMessage = t(
    'create.validationRequiredFieldMessage'
  );
  const validationPassCharsCountMessage = t(
    'create.validationPassCharsCountMessage'
  );
  const validationPassUppercaseMessage = t(
    'create.validationPassUppercaseMessage'
  );
  const validationPassLowercaseMessage = t(
    'create.validationPassLowercaseMessage'
  );
  const validationPassSpecialMessage = t('create.validationPassSpecialMessage');
  const validationLatinLettersOnlyMessage = t(
    'create.validationLatinLettersOnlyMessage'
  );

  const genderList = useMemo(
    () => [
      { value: 'male', label: fieldMaleLabel },
      { value: 'female', label: fieldFemaleLabel },
    ],
    [fieldFemaleLabel, fieldMaleLabel]
  );

  const initialValues = useMemo(
    () => ({
      password: '',
      subscribe: false, // this is a visual part of subscribtion field. consent field will be sent after submit
      consent: '',
      mymc: false,
      countryOfResidence: '',
      gender: '',
      language: '',
      occupation: '',
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .required(validationRequiredFieldMessage)
          .min(8, validationPassCharsCountMessage)
          .matches(/[A-ZЀ-ЯҐ\u4E00-\u9FFF]/, validationPassUppercaseMessage)
          .matches(/[a-zа-ўґ\u4E00-\u9FFF]/, validationPassLowercaseMessage)
          .matches(
            /[^A-ZЀ-ЯҐa-zа-ўґ\u4E00-\u9FFF0-9]/,
            validationPassSpecialMessage
          )
          .matches(
            latinLettersWithNumbersAwsRegexp,
            validationLatinLettersOnlyMessage
          ),
        gender: Yup.string().when('mymc', {
          is: true,
          then: Yup.string().required(validationRequiredFieldMessage),
          otherwise: Yup.string(),
        }),
        countryOfResidence: Yup.string().when('mymc', {
          is: true,
          then: Yup.string().required(validationRequiredFieldMessage),
          otherwise: Yup.string(),
        }),
        occupation: Yup.string().when('mymc', {
          is: true,
          then: Yup.string().required(validationRequiredFieldMessage),
          otherwise: Yup.string(),
        }),
        language: Yup.string(),
      }),
    [
      validationRequiredFieldMessage,
      validationPassCharsCountMessage,
      validationPassUppercaseMessage,
      validationPassLowercaseMessage,
      validationPassSpecialMessage,
      validationLatinLettersOnlyMessage,
    ]
  );

  return children({
    genderList,
    initialValues,
    validationSchema,
    captchaStatus,
    captchaReset,
    CaptchaWidget,
  });
}

FormSignUpComboHandler.propTypes = propTypes;
FormSignUpComboHandler.defaultProps = defaultProps;

export default FormSignUpComboHandler;
