import React from 'react';
import { string, shape, func } from 'prop-types';
import classNames from 'classnames/bind';

import BlockTitle from '../BlockTitle';
import styles from './Subscribe.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  newsletterValues: shape({
    title: string.isRequired,
    text: string.isRequired,
    url: string,
    urlText: string,
  }).isRequired,
  LinkComponent: func.isRequired,
};

const defaultProps = {
  url: '/',
  urlText: '',
};

const Subscribe = ({
  newsletterValues: { title, text, url, urlText },
  LinkComponent,
}) => (
  <div className={cx('root')}>
    {!!title && <BlockTitle title={title} tag={'div'} />}
    <p className={cx('description')}>{text}</p>
    {url && urlText && <LinkComponent to={url}>{urlText}</LinkComponent>}
  </div>
);

Subscribe.propTypes = propTypes;
Subscribe.defaultProps = defaultProps;

export default Subscribe;
