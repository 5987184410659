import { useMemo, useCallback } from 'react';
import { func, string } from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { anonymousUserApolloClient } from '~/apolloClient';
import { ForgotPasswordMutation } from '~/queries';
import { useFriendlyCaptcha } from '~/hooks';
import { useAuthContext } from '~/context/AuthContext';

const propTypes = {
  setForgottenPassEmail: func.isRequired,
  setEmailSubmitted: func.isRequired,
  children: func.isRequired,
  forgottenPassEmail: string.isRequired,
};

const ForgotPassFormHandler = ({
  children,
  setEmailSubmitted,
  setForgottenPassEmail,
  forgottenPassEmail,
}) => {
  const { captchaReset, captchaStatus, CaptchaWidget } = useFriendlyCaptcha({
    id: 'submit-email',
  });

  const [forgotPasswordMutate] = useMutation(ForgotPasswordMutation, {
    client: anonymousUserApolloClient,
  });

  const { t } = useTranslation(['auth', 'common']);

  // I18n data
  const validationRequiredFieldMessage = t(
    'auth:login.validationRequiredFieldMessage'
  );
  const validationIncorrectEmailMessage = t(
    'auth:login.validationIncorrectEmailMessage'
  );
  const forgotPassUserNotExistMessage = t('auth:login.messages.not_found');
  const forgotPassUserNotValidatedMessage = t(
    'auth:login.messages.resend_email'
  );
  const serviceMessageError = t('common:serviceErrorMessage');

  const { errorMsg, setErrorMsg, preFilledLogin } = useAuthContext();

  const resetMessage = useCallback(() => {
    if (errorMsg) {
      setErrorMsg('');
    }
  }, [errorMsg, setErrorMsg]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(validationIncorrectEmailMessage)
          .required(validationRequiredFieldMessage),
      }),
    [validationRequiredFieldMessage, validationIncorrectEmailMessage]
  );

  const handleSubmit = ({ email }, { setSubmitting, resetForm }) => {
    if (!captchaStatus.solution) {
      setSubmitting(false);
      resetForm();
      console.error('No captcha token generated');

      return null;
    }

    const setError = (message, error) => {
      setErrorMsg(message);
      setSubmitting(false);

      if (error) {
        console.error(error);
      }
    };

    setSubmitting(true);

    const emailNormalized = email.toLowerCase();

    forgotPasswordMutate({
      variables: {
        email: email.toLowerCase(),
        captcha: captchaStatus.solution,
      },
    })
      .then(({ data: { forgotPassword } }) => {
        /**
         * reset_password - default forgot password process
         * not_found - no such user in AWS
         * resend_email - user exist but not validated yet
         * unknown_error - unknown error
         */

        setSubmitting(false);

        if (
          forgotPassword.result &&
          forgotPassword.messages.status.includes('reset_password')
        ) {
          setForgottenPassEmail(emailNormalized);
          setEmailSubmitted(true);
          gtmPushEvent({ event: 'reset_password' });
        } else if (
          !forgotPassword.result &&
          forgotPassword.messages.error.includes('not_found')
        ) {
          captchaReset();
          setError(forgotPassUserNotExistMessage);
        } else if (
          !forgotPassword.result &&
          forgotPassword.messages.info.includes('resend_email')
        ) {
          captchaReset();
          setError(forgotPassUserNotValidatedMessage);
        } else {
          captchaReset();
          setError(serviceMessageError);
        }
      })
      .catch((error) => {
        captchaReset();
        setError(serviceMessageError, error);
      });
  };

  const initialValues = {
    email: preFilledLogin || forgottenPassEmail || '',
  };

  return children({
    handleSubmit,
    validationSchema,
    initialValues,
    resetMessage,
    captchaStatus,
    CaptchaWidget,
  });
};

ForgotPassFormHandler.propTypes = propTypes;

export default withErrorBoundary(ForgotPassFormHandler);
