import { useEffect, useState, useMemo, useCallback } from 'react';
import { func } from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import usePrevious from 'common/hooks/usePrevious';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

const SbmLoyaltyLinkingContactsDataQuery = gql`
  query SbmLoyaltyLinkingContactsData($mymcNumber: String!) {
    getSbmLoyaltyLinkingContacts(mymcNumber: $mymcNumber) {
      result
      email
      phone
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  children: func.isRequired,
  setMymcNumberVerificationData: func.isRequired,
  setIsLoyaltyMembership: func.isRequired,
};

const AddMymcNumberFormHandler = ({
  children,
  setMymcNumberVerificationData,
  setIsLoyaltyMembership,
}) => {
  const [mymcNumber, setMymcNumber] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errors, setErrors] = useState(null);
  const { t } = useTranslation('loyalty-program');
  const addMymcNumberForm = t('addMymcNumberForm', {
    returnObjects: true,
  });

  const resetMessage = useCallback(() => {
    if (!!errors || submitSuccess) {
      setErrors(null);
      setSubmitSuccess(false);
    }
  }, [errors, submitSuccess]);

  const [getSbmLoyaltyLinkingContacts, { data, networkStatus }] = useLazyQuery(
    SbmLoyaltyLinkingContactsDataQuery,
    {
      fetchPolicy: 'no-cache',
      onError: (error) => {
        console.warn("Didn't get linking contacts:", error);
      },
    }
  );

  const prevNetworkStatus = usePrevious(networkStatus);

  useEffect(() => {
    if (data && !submitSuccess && networkStatus !== prevNetworkStatus) {
      setSubmitting(false);
      if (data.getSbmLoyaltyLinkingContacts?.result) {
        setSubmitSuccess(true);
        setMymcNumberVerificationData({
          data: data.getSbmLoyaltyLinkingContacts,
          mymcNumber: mymcNumber,
        });
        setIsLoyaltyMembership(false);
        setMymcNumber(null);
      } else {
        if (data.getSbmLoyaltyLinkingContacts?.messages) {
          setErrors({ messages: data.getSbmLoyaltyLinkingContacts.messages });
        }
      }
    }
  }, [
    data,
    isSubmitting,
    mymcNumber,
    networkStatus,
    prevNetworkStatus,
    setIsLoyaltyMembership,
    setMymcNumberVerificationData,
    submitSuccess,
  ]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        mymc_number: Yup.string().matches(
          new RegExp('^[0-9]*$', []),
          addMymcNumberForm.validationFieldPatternMessage
        ),
      }),
    [addMymcNumberForm.validationFieldPatternMessage]
  );

  const handleSubmit = ({ mymc_number }) => {
    setErrors(null);
    setMymcNumber(mymc_number);
    setSubmitting(true);
    getSbmLoyaltyLinkingContacts({
      variables: { mymcNumber: mymc_number },
    });
  };

  const initialValues = {
    mymc_number: '',
  };

  return children({
    handleSubmit,
    initialValues,
    validationSchema,
    submitSuccess,
    errors,
    resetMessage,
    isSubmitting,
  });
};

AddMymcNumberFormHandler.propTypes = propTypes;

export default withErrorBoundary(AddMymcNumberFormHandler);
