import React, { useRef } from 'react';
import { func, string, number } from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import MyAccModalPortal from './components/MyAccModalPortal';
import styles from './MyAccModal.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  onClose: func.isRequired,
  className: string,
  overlayOpacity: number,
};

const defaultProps = {
  className: undefined,
  overlayOpacity: 0.35,
};

const MODAL_ANIM_CLASSNAMES = {
  enter: cx('modal-transition-enter'),
  enterActive: cx('modal-transition-enter-active'),
  enterDone: cx('modal-transition-done-enter'),
  exit: cx('modal-transition-exit'),
  exitActive: cx('modal-transition-exit-active'),
};

const MyAccModal = ({ children, onClose, className, overlayOpacity }) => {
  const nodeRef = useRef(null);

  const modalClose = function () {
    onClose && onClose();
  };

  return (
    <CSSTransition
      in={true}
      timeout={300}
      classNames={MODAL_ANIM_CLASSNAMES}
      unmountOnExit
      nodeRef={nodeRef}
    >
      <MyAccModalPortal>
        <div className={cx('root')} ref={nodeRef}>
          <div
            className={cx('modal-overlay')}
            style={{ opacity: overlayOpacity }}
            onClick={modalClose}
          />
          <div className={cx('modal-content', className)}>{children}</div>
        </div>
      </MyAccModalPortal>
    </CSSTransition>
  );
};

MyAccModal.propTypes = propTypes;
MyAccModal.defaultProps = defaultProps;

export default withErrorBoundary(MyAccModal);
