import React from 'react';
import { node } from 'prop-types';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';

import Picture from 'common/components/Picture';
import heroImageFieldsFragment from 'common/queries/heroImageFieldsFragment';
import getSources from 'common/utils/getSources';
import { IMAGE_CONSTS } from 'common/constants';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './HeroImage.module.scss';

const cx = classNames.bind(styles);

const heroImageFields = gql`
  fragment heroImageFields on MediaImage {
    id
    image {
      ...heroImageFieldsFragment
    }
  }
  ${heroImageFieldsFragment}
`;

const propTypes = {
  data: propType(heroImageFields).isRequired,
  children: node,
};

const defaultProps = {
  children: null,
};

const HeroImage = function ({ data: { image }, children }) {
  return (
    <div className={cx('root')}>
      <div>
        <div className={cx('hero-slider')}>
          <div className={cx('hero-slide-image')}>
            <Picture
              sources={getSources(image)}
              src={image.heroDesktopWideResponsive.url}
              alt={image.alt}
              itemProp={IMAGE_CONSTS.itemProp}
            />
          </div>
        </div>
      </div>
      <div className={cx('hero-slider-inner')}>{children}</div>
    </div>
  );
};

HeroImage.propTypes = propTypes;
HeroImage.defaultProps = defaultProps;
HeroImage.fragments = {
  heroImageFields,
};

export default withErrorBoundary(HeroImage);
