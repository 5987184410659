export const shouldCancelDrag = (e) => {
  const isParentDraggable = e.target.parentNode.classList.contains('draggable');
  const isParentSvgDraggable = e.target.parentNode.parentNode.classList.contains(
    'draggable'
  );
  const isDraggable = e.target.classList.contains('draggable');

  return !(isParentDraggable || isDraggable || isParentSvgDraggable);
};

export const getCheckboxGroupInitalValues = ({ customList, queryData }) => {
  return customList.reduce((result, { name, subOptions }) => {
    queryData.forEach(({ option, subOptions: subOptionsData }) => {
      if (name === option) {
        result.push(name);
      }

      if (subOptions && subOptionsData) {
        subOptions.forEach(({ name: subOptionName }) => {
          subOptionsData.forEach((subOptionDataName) => {
            if (subOptionDataName === subOptionName) {
              result.push(`${subOptionName}[${name}]`);
            }
          });
        });
      }
    });

    return result;
  }, []);
};

export const getRadiosGroupInitalValue = ({ customList, queryData }) => {
  const valueFoundedObj = customList.find(
    ({ name }) => name === queryData?.option
  );
  return valueFoundedObj ? valueFoundedObj.name : '';
};
