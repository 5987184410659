import { gql } from '@apollo/client';

const myaccCardMediaFieldsFragment = gql`
  fragment myaccCardMediaFields on MediaImage {
    id
    image {
      id
      listItemLongStyleDesktop: url(style: list_item_long_style_desktop_webp) {
        ...urlFields
      }
      listItemLongStyleDesktop2x: url(
        style: list_item_long_style_desktop_2x_webp
      ) {
        ...urlFields
      }
      listItemShortStyleDesktop: url(
        style: list_item_short_style_desktop_webp
      ) {
        ...urlFields
      }
      listItemShortStyleDesktop2x: url(
        style: list_item_short_style_desktop_2x_webp
      ) {
        ...urlFields
      }
      listItemCommonTablet: url(style: list_item_common_tablet_webp) {
        ...urlFields
      }
      listItemCommonTablet2x: url(style: list_item_common_tablet_2x_webp) {
        ...urlFields
      }
      listItemCommonMobile: url(style: list_item_common_mobile_webp) {
        ...urlFields
      }
      listItemCommonMobile2x: url(style: list_item_common_mobile_2x_webp) {
        ...urlFields
      }
      listItemPlaceholder: url(style: image_placeholder_webp) {
        url
      }
      alt
    }
  }

  fragment myaccCardVideoFields on MediaWistiaVideo {
    id
    video {
      id
      listItemLongStyleDesktop: thumbnail(
        style: list_item_long_style_desktop_webp
      ) {
        ...urlFields
      }
      listItemLongStyleDesktop2x: thumbnail(
        style: list_item_long_style_desktop_2x_webp
      ) {
        ...urlFields
      }
      listItemShortStyleDesktop: thumbnail(
        style: list_item_short_style_desktop_webp
      ) {
        ...urlFields
      }
      listItemShortStyleDesktop2x: thumbnail(
        style: list_item_short_style_desktop_2x_webp
      ) {
        ...urlFields
      }
      listItemCommonTablet: thumbnail(style: list_item_common_tablet_webp) {
        ...urlFields
      }
      listItemCommonTablet2x: thumbnail(
        style: list_item_common_tablet_2x_webp
      ) {
        ...urlFields
      }
      listItemCommonMobile: thumbnail(style: list_item_common_mobile_webp) {
        ...urlFields
      }
      listItemCommonMobile2x: thumbnail(
        style: list_item_common_mobile_2x_webp
      ) {
        ...urlFields
      }
      listItemPlaceholder: thumbnail(style: image_placeholder_webp) {
        url
      }
    }
  }

  fragment urlFields on ImageStyleUrl {
    width
    height
    url
  }
`;

export default myaccCardMediaFieldsFragment;
