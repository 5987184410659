import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import { arrayOf, string, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { numberWithSpace } from '~/utils/numberUpdates';
import { useUserContext } from '~/context/UserContext';

import styles from './WellnessProductPriceList.module.scss';

const cx = classNames.bind(styles);

const wellnessProductPriceListFieldsFragment = gql`
  fragment wellnessProductPriceListFields on ProductPrice {
    id
    duration
    price
    points
    currency
    priceBeforeDiscount
  }
`;

const propTypes = {
  data: arrayOf(propType(wellnessProductPriceListFieldsFragment).isRequired)
    .isRequired,
  showPoints: bool,
  title: string,
};

const defaultProps = {
  title: null,
  showPoints: false,
};

const fragment = {
  wellnessProductPriceListFieldsFragment,
};

const WellnessProductPriceList = ({ data, title, showPoints }) => {
  const {
    userSbmLoyalty: { rewardPoints },
  } = useUserContext();
  const { t } = useTranslation('offers-info');
  const pointsLabel = t('myPoints');
  const productPriceNormalized = !!data
    ? data.filter((item) => item.price && item.price !== '0')
    : [];

  return (
    !!productPriceNormalized.length && (
      <ul className={cx('root')}>
        {title && <li className={cx('list-item', 'title')}>{title}</li>}
        {productPriceNormalized.map(
          ({ id, duration, price, points, currency, priceBeforeDiscount }) => {
            return (
              <li className={cx('list-item')} key={id}>
                <span>{duration}</span>
                <strong className={cx('price')}>{price + currency}</strong>
                {priceBeforeDiscount && (
                  <span className={cx('old-price')}>
                    {priceBeforeDiscount + currency}
                  </span>
                )}
                {showPoints && !!rewardPoints && (
                  <span className={cx('points')}>
                    {numberWithSpace(points)} {pointsLabel}
                  </span>
                )}
              </li>
            );
          }
        )}
      </ul>
    )
  );
};

WellnessProductPriceList.propTypes = propTypes;
WellnessProductPriceList.defaultProps = defaultProps;
WellnessProductPriceList.fragment = fragment;

export default withErrorBoundary(WellnessProductPriceList);
