import React, { useCallback } from 'react';
import { object, func } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import styles from './DeleteDataModal.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  modalRef: object.isRequired,
  modalToggle: func.isRequired,
  deleteData: func.isRequired,
};

const gtmDeletionParams = {
  event: 'delete_personal_data',
};

function DeleteDataModal({ modalRef, modalToggle, deleteData }) {
  const { t } = useTranslation('privacy');
  const modalText = t('modalText');
  const confirmDeleteDataButtonLabel = t('confirmDeleteDataButtonLabel');
  const cancelDeleteDataButtonLabel = t('cancelDeleteDataButtonLabel');

  const handleDeletion = useCallback(() => {
    gtmPushEvent(gtmDeletionParams);
    modalToggle();
    deleteData();
  }, [deleteData, modalToggle]);

  return (
    <div className={cx('root')}>
      <div className={cx('modal')} ref={modalRef}>
        <div className={cx('modal-text')}>
          <div dangerouslySetInnerHTML={{ __html: modalText }} />
        </div>
        <div className={cx('confirm-buttons')}>
          <button
            type="button"
            className={cx('confirm-button')}
            onClick={handleDeletion}
          >
            {confirmDeleteDataButtonLabel}
          </button>
          <button
            type="button"
            className={cx('confirm-button', 'negative')}
            onClick={modalToggle}
          >
            {cancelDeleteDataButtonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteDataModal.propTypes = propTypes;

export default DeleteDataModal;
