import React, { Fragment } from 'react';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';

import CheckboxesItem from '~/components/FormComponents/CheckboxesItem';

import styles from './Checkboxes.module.scss';

const cx = classNames.bind(styles);

const Checkboxes = ({
  options,
  colCount,
  disabled,
  setFieldValue,
  setFieldTouched,
  field: { name, value },
}) => {
  const isListContainName = ({ value, name }) =>
    value.some((item) => item.split('[')[0].includes(name));

  const parentsMap = options?.filter(({ subOptions }) => !!subOptions);

  const onChange = (isParent) => ({
    currentTarget: { name: currentTargetName, checked },
  }) => {
    if (!checked) {
      if (isParent) {
        const currentParentItem = parentsMap.find(
          ({ name }) => name === currentTargetName
        );

        const subOptionsArr = currentParentItem.subOptions.map(
          ({ name }) => name
        );

        const isSubOptionChecked = subOptionsArr.some((name) =>
          isListContainName({ value, name })
        );

        if (isSubOptionChecked) {
          return null;
        }
      }
      setFieldValue(
        name,
        value.filter((curVal) => curVal !== currentTargetName)
      );
    } else {
      setFieldValue(name, value.concat(currentTargetName));
    }

    setFieldTouched(name);
  };

  const onSubOptionChange = (parentOptionValue) => ({
    currentTarget: { name: currentTargetName, checked },
  }) => {
    if (!checked) {
      const nextValue = value.filter((curVal) => curVal !== currentTargetName);

      setFieldValue(name, nextValue);
    } else {
      const isParentOptionChecked = value.includes(parentOptionValue);
      const nextValue = value.concat(currentTargetName);
      const nextValueWithParent = nextValue.concat(parentOptionValue);

      isParentOptionChecked
        ? setFieldValue(name, nextValue)
        : setFieldValue(name, nextValueWithParent);
    }

    setFieldTouched(name);
  };

  const onBlur = () => {
    setFieldTouched(name);
  };

  return (
    <div
      className={cx('root', {
        [`columns-${colCount}`]: colCount,
      })}
    >
      {options?.map(
        ({ name: parentName, label, checked, subOptions }, index) => {
          const isParent = !!subOptions;
          return (
            <Fragment key={index}>
              <CheckboxesItem
                checked={value.includes(parentName)}
                name={parentName}
                label={label}
                onChange={onChange(isParent)}
                onBlur={onBlur}
                disabled={disabled}
                className={cx('checkbox-group-parent')}
              />
              {subOptions && (
                <div className={cx('sub-options-wrapper')}>
                  {subOptions.map(
                    ({ label, name, checked }, subOptionsIndex) => (
                      <CheckboxesItem
                        className={cx('sub-option')}
                        key={subOptionsIndex}
                        checked={isListContainName({ value, name })}
                        name={`${name}[${parentName}]`}
                        label={label}
                        onChange={onSubOptionChange(parentName)}
                        onBlur={onBlur}
                        disabled={disabled}
                      />
                    )
                  )}
                </div>
              )}
            </Fragment>
          );
        }
      )}
      <ErrorMessageCustom name={name}>
        {({ msg }) => <FieldError>{msg}</FieldError>}
      </ErrorMessageCustom>
    </div>
  );
};

export default Checkboxes;
