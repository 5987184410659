import React from 'react';
import { bool, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';

import { LOYALTY_CARD_CODES } from 'common/constants/loyaltyStatus';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';
import InfoMessages from '~/components/InfoMessages';

import Card from '../Card';
import styles from './VipCard.module.scss';

const { REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const vipCardDataFragment = gql`
  fragment vipCardMessagesFields on SbmLoyaltyBlockMessages {
    messages {
      info
      status
      warning
      error
    }
  }
`;

const propTypes = {
  data: propType(vipCardDataFragment),
  isStretch: bool,
  className: string,
};

const defaultProps = {
  isStretch: false,
  className: '',
  data: {},
};

const VipCard = ({ data, isStretch, className, ...attrs }) => {
  const { t } = useTranslation(['loyalty-program', 'loyalty-card']);
  const colorScheme = t('loyalty-card:color-scheme', {
    returnObjects: true,
  });
  const infoLabels = t('messages', {
    returnObjects: true,
  });

  const memberTitle = t('loyalty-card:title');
  const pageIntroGreeting = t('pageIntroGreeting');
  const loyaltyColorScheme = colorScheme[LOYALTY_CARD_CODES.vip];

  const {
    userSbmIdentity: { firstName },
  } = useUserContext();

  const userCardData = {
    bgColor: loyaltyColorScheme.background,
    color: loyaltyColorScheme['color-1'],
    ctaLink: `${REACT_APP_MYACC_DOMAIN}/loyalty-program`,
    ctaInverted: true,
    ctaLabel: t('loyalty-card:userCardCta'),
  };

  return (
    <Card data={userCardData} isStretch={isStretch} className={cx('user-card')}>
      <h3 className={cx('card-sub-title')}>{memberTitle}</h3>
      <h2 className={cx('card-title')}>
        {pageIntroGreeting} {firstName}
      </h2>
      {data && (
        <div style={{ color: loyaltyColorScheme['color-2'] }}>
          <InfoMessages
            className={cx('message-root')}
            messages={data}
            labels={infoLabels}
          />
        </div>
      )}
    </Card>
  );
};

VipCard.propTypes = propTypes;
VipCard.defaultProps = defaultProps;
VipCard.fragments = {
  vipCardDataFragment,
};

export default withErrorBoundary(VipCard);
