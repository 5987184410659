const { REACT_APP_MYACC_ENV, REACT_APP_MYACC_DOMAIN } = process.env;

const myaccDomain =
  REACT_APP_MYACC_ENV === 'local' || REACT_APP_MYACC_ENV === 'dev'
    ? 'https://my-dev.montecarlosbm.com'
    : REACT_APP_MYACC_DOMAIN;
const imgNamePrefix = 'my_account_background_';
const imgPathTemplate = myaccDomain + '/background/slider/' + imgNamePrefix;

const desktop = (i) => `${imgPathTemplate}desktop_webp_${i}.webp`;
const desktop2x = (i) => `${imgPathTemplate}desktop_2x_webp_${i}.webp`;
const tablet = (i) => `${imgPathTemplate}tablet_webp_${i}.webp`;
const tablet2x = (i) => `${imgPathTemplate}tablet_2x_webp_${i}.webp`;

export const getLoginSlideBackground = function (i) {
  return {
    desktop: {
      url: desktop(i),
    },
    desktop2x: {
      url: desktop2x(i),
    },
    tablet: {
      url: tablet(i),
    },
    tablet2x: {
      url: tablet2x(i),
    },
    mobile: {
      url: tablet(i),
    },
    mobile2x: {
      url: tablet2x(i),
    },
  };
};
