import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import classNames from 'classnames/bind';
import { useMutation } from '@apollo/client';
import { anonymousUserApolloClient } from '~/apolloClient';

import InformationPanel from 'common/components/InformationPanel';
import AuthMessage from 'common/components/AuthMessage';

import { ForgotPasswordMutation } from '~/queries';
import { useFriendlyCaptcha } from '~/hooks';
import SubmitButton from '~/components/SubmitButton';
import InfoMessages from '~/components/InfoMessages';

import MessageByStatusColor from '../MessageByStatusColor';
import styles from './NavMailSending.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  email: string.isRequired,
};

const defaultProps = {
  className: null,
};

function NavMailSending({ email }) {
  const { captchaReset, captchaStatus, CaptchaWidget } = useFriendlyCaptcha({
    id: 'submit-email',
  });

  const { t } = useTranslation(['edit-account', 'auth', 'common']);

  const infoLabels = t('auth:create.messages', {
    returnObjects: true,
  });
  const submitConfirmation = t('common:submitted');
  const serviceMessageError = t('common:serviceErrorMessage');

  const [submitErrorMessage, setSubmitErrorMessage] = useState(null);
  const [isEmailSubmitted, setEmailSubmitted] = useState(false);

  const [forgotPasswordMutate, { loading, error }] = useMutation(
    ForgotPasswordMutation,
    {
      client: anonymousUserApolloClient,
    }
  );

  const apiLogBlock = t('navMailSending', {
    returnObjects: true,
  });

  const { resetPass } = apiLogBlock;

  const messages = {
    red: resetPass.error,
    green: resetPass.success,
  };

  const handleSubmit = () => {
    setSubmitErrorMessage(null);
    setEmailSubmitted(false);

    forgotPasswordMutate({
      variables: {
        email,
        link: true,
        captcha: captchaStatus.solution,
      },
    })
      .then(({ data: { forgotPassword } }) => {
        if (!forgotPassword.result) {
          setSubmitErrorMessage({ messages: forgotPassword.messages });
        } else {
          setEmailSubmitted(true);
        }
        captchaReset();
      })
      .catch((error) => {
        console.error(error);
        setSubmitErrorMessage({ messages: { error: serviceMessageError } });
        captchaReset();
      });
  };

  return (
    <div className={cx('root')}>
      <>
        <div className={cx('message-wrapper')}>
          <MessageByStatusColor
            messages={messages}
            status={!!error ? 'red' : 'green'}
          />
        </div>
        <div className={cx('buttons-wrapper')}>
          <div className={cx('button-item')}>
            <form>
              {submitErrorMessage && (
                <InformationPanel isComponentChildren withIcon errorMode>
                  <InfoMessages
                    isTextMode
                    mode={'error'}
                    messages={submitErrorMessage}
                    labels={infoLabels}
                  />
                </InformationPanel>
              )}
              {isEmailSubmitted && (
                <AuthMessage isSuccessMsg>{submitConfirmation}</AuthMessage>
              )}
              <SubmitButton
                saveButtonLabel={resetPass.sendButton}
                description={resetPass.buttonDescription}
                onClick={handleSubmit}
                isSubmitting={loading || captchaStatus.loading}
                isSubmitDisabled={!captchaStatus.solution}
              />
              <CaptchaWidget className={cx('captcha')} />
            </form>
          </div>
        </div>
      </>
    </div>
  );
}

NavMailSending.propTypes = propTypes;
NavMailSending.defaultProps = defaultProps;

export default NavMailSending;
