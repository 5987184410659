import React from 'react';
import { useQuery } from '@apollo/client';
import classNames from 'classnames/bind';

import {
  default as FooterTemplate,
  SecMenu,
  BrandLogo,
} from 'common/scenes/Footer';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { sbmClient } from '~/apolloClient';
import MyAccLink from '~/components/MyAccLink';
import { useLocalisationContext } from '~/context/LocalisationContext';

import MainMenu from './components/MainMenu';

import FooterDataQuery from './FooterDataQuery';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = () => {
  const { loading, data } = useQuery(FooterDataQuery, {
    client: sbmClient,
  });

  const { language } = useLocalisationContext();

  if (loading) {
    return false;
  }

  const { footerNavMenu, footerBottomMenu } = data || {};

  return (
    <FooterTemplate className={cx('root')}>
      <BrandLogo />
      {footerNavMenu && <MainMenu footerMainMenu={footerNavMenu} />}

      {footerBottomMenu && (
        <SecMenu
          className={cx('sec-menu')}
          footerSecMenu={footerBottomMenu}
          LinkComponent={MyAccLink}
          lang={language}
        />
      )}
    </FooterTemplate>
  );
};

export default withErrorBoundary(Footer);
