import React from 'react';
import { func, bool, string } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconEye } from 'common/media/icons/eye.icon.svg';
import { ReactComponent as IconEyeOff } from 'common/media/icons/view-off.icon.svg';

import styles from './ShowTextButton.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  toggle: func.isRequired,
  isError: bool,
  isText: bool,
  className: string,
};

const defaultProps = {
  isError: null,
  isText: null,
  className: null,
};

const ShowTextButton = ({ toggle, isError, isText, className }) => (
  <button
    type={'button'}
    className={`${cx('root')} ${className}`}
    onClick={toggle}
  >
    <IconContainer
      className={cx('icon-eye', { 'is-error': isError, 'is-text': isText })}
      icon={isText ? IconEyeOff : IconEye}
    />
  </button>
);

ShowTextButton.propTypes = propTypes;
ShowTextButton.defaultProps = defaultProps;

export default ShowTextButton;
