import React from 'react';
import { string, array, object, bool, shape, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import HighlightedText from 'common/components/HighlightedText';
import UniverseAttribute from 'common/components/UniverseAttribute';
import ReadMore from 'common/containers/ReadMoreContainer';
import Wysiwyg from 'common/components/Wysiwyg';

import styles from './ProductIntro.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  title: object,
  text: string,
  size: oneOf(['l', 'xl']),
  bgColor: oneOf(['black-pearl', 'light-gray']),
  attributes: array,
  globalLabels: object,
  period: string,
  attributesSeparated: bool,
  bottomSeparated: bool,
  microdata: shape({
    amenityFeature: string,
  }),
  isExpanded: bool,
  titleBreak: bool,
  titleTag: string,
  className: string,
  descriptionClass: string,
};

const defaultProps = {
  title: null,
  size: 'l',
  text: null,
  attributes: null,
  globalLabels: null,
  period: null,
  attributesSeparated: false,
  bottomSeparated: false,
  microdata: null,
  isExpanded: false,
  bgColor: null,
  shortDescription: true,
  titleBreak: false,
  titleTag: 'strong',
  className: null,
  descriptionClass: null,
};

const ProductIntro = ({
  title,
  titleTag: TitleTag,
  text,
  attributes,
  globalLabels,
  period,
  attributesSeparated,
  bottomSeparated,
  microdata,
  isExpanded,
  bgColor,
  size,
  titleBreak,
  className,
  descriptionClass,
}) => {
  const cutTextLines = {
    l: 6,
    xl: 12,
  };
  return (
    <div
      className={cx('root', className, { 'bottom-separated': bottomSeparated })}
    >
      {!!title && (
        <TitleTag
          className={cx('title', {
            'title-break': titleBreak,
            'with-additional-info': !!period,
          })}
        >
          <HighlightedText index={title.index}>{title.value}</HighlightedText>
        </TitleTag>
      )}
      {!!period && (
        <div
          className={cx('period')}
          dangerouslySetInnerHTML={{ __html: period }}
        />
      )}
      {!!attributes && (
        <div
          className={cx('attributes', {
            'attributes-separated': attributesSeparated,
          })}
        >
          <ul className={cx('attributes-list')}>
            {attributes.map((item, index) =>
              !!item.icon ? (
                <li
                  key={index}
                  className={cx('list-item')}
                  itemProp={!!microdata ? microdata.amenityFeature : null}
                >
                  <UniverseAttribute logo={item.icon} label={item.name} />
                </li>
              ) : null
            )}
          </ul>
        </div>
      )}
      {!!text && isExpanded ? (
        <Wysiwyg text={text} className={cx('description', descriptionClass)} />
      ) : (
        <ReadMore
          colorBg={bgColor}
          labels={globalLabels}
          lines={cutTextLines[size]}
          className={cx('description', descriptionClass)}
        >
          <Wysiwyg text={text} />
        </ReadMore>
      )}
    </div>
  );
};

ProductIntro.propTypes = propTypes;
ProductIntro.defaultProps = defaultProps;

export default ProductIntro;
