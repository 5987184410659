import { useState, useMemo, useCallback } from 'react';
import { func, object } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useEffectOnMount } from 'common/hooks';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

const gtmLinkingParams = {
  event: 'complete_loyalty_program_linking',
};

const CardVerificationMethodSendMutation = gql`
  mutation updateSbmLinkingCode($value: SbmLinkingCodeInput!) {
    updateSbmLinkingCode(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const CardVerificationCodeSendMutation = gql`
  mutation updateSbmLinkingCodeChallenge(
    $value: SbmLinkingCodeChallengeInput!
  ) {
    updateSbmLinkingCodeChallenge(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  children: func.isRequired,
  mymcNumberVerificationData: object.isRequired,
  setMymcNumberVerificationData: func.isRequired,
  refetch: func.isRequired,
};

const CardVerificationFormHandler = ({
  children,
  mymcNumberVerificationData,
  setMymcNumberVerificationData,
  refetch: refetchLoyaltyPageQuery,
}) => {
  const [submitMethodMutate, { loading: submitMethodLoading }] = useMutation(
    CardVerificationMethodSendMutation
  );
  const [submitCodeMutate] = useMutation(CardVerificationCodeSendMutation);
  const [submitMethodError, setSubmitMethodError] = useState(false);
  const [errors, setErrors] = useState(null);
  const [submitCodeSuccess, setSubmitCodeSuccess] = useState(false);
  const [isSubmitMethodSuccess, setSubmitMethodSuccess] = useState(false);

  const { t } = useTranslation('loyalty-program');
  const emailFieldLabel = t('cardVerification.emailFieldLabel');
  const phoneFieldLabel = t('cardVerification.phoneFieldLabel');

  const resetMessage = useCallback(() => {
    if (!!errors || submitCodeSuccess) {
      setErrors(null);
      setSubmitCodeSuccess(false);
    }
  }, [errors, submitCodeSuccess]);

  const verificationMethods = useMemo(() => {
    let methods = [];
    if (mymcNumberVerificationData?.data?.email) {
      methods.push({
        value: 'email',
        label: emailFieldLabel,
        userData: mymcNumberVerificationData.data.email,
      });
    }
    if (mymcNumberVerificationData?.data?.phone) {
      methods.push({
        value: 'phone',
        label: phoneFieldLabel,
        userData: mymcNumberVerificationData.data.phone,
      });
    }

    return methods;
  }, [mymcNumberVerificationData, emailFieldLabel, phoneFieldLabel]);

  const [activeMethodType, setActiveMethodType] = useState(
    verificationMethods[0].method
  );
  const [activeMethodData, setActiveMethodData] = useState(
    verificationMethods[0].userData
  );

  useEffectOnMount(() => {
    if (verificationMethods.length === 1) {
      const method = verificationMethods[0].value;
      const methodData = verificationMethods[0].userData;

      submitMethodMutate({
        variables: {
          value: {
            mymcNumber: mymcNumberVerificationData.mymcNumber,
            contact: method,
          },
        },
      })
        .then(({ data: { updateSbmLinkingCode } }) => {
          if (updateSbmLinkingCode.result) {
            setSubmitMethodSuccess(true);
            setSubmitMethodError(false);
          } else {
            setSubmitMethodError(true);
          }
        })
        .catch((error) => {
          setSubmitMethodError(true);
          console.warn(error);
        });

      setActiveMethodType(method);
      setActiveMethodData(methodData);
    }
  });

  const handleMethodSubmit = ({ method }, { setSubmitting }) => {
    setSubmitting(true);
    submitMethodMutate({
      variables: {
        value: {
          mymcNumber: mymcNumberVerificationData.mymcNumber,
          contact: method,
        },
      },
    })
      .then(({ data: { updateSbmLinkingCode } }) => {
        setSubmitting(false);

        if (updateSbmLinkingCode.result) {
          setSubmitMethodSuccess(true);
          setSubmitMethodError(false);
        } else {
          setSubmitMethodError(true);
        }
      })
      .catch((error) => {
        setSubmitMethodError(true);
        console.warn(error);
      });

    setActiveMethodType(
      verificationMethods.find(({ value }) => value === method)?.value
    );
    setActiveMethodData(
      verificationMethods.find(({ value }) => value === method)?.userData
    );
  };

  const handleCodeSubmit = ({ code }, { setSubmitting }) => {
    submitCodeMutate({
      variables: {
        value: {
          mymcNumber: mymcNumberVerificationData.mymcNumber,
          code: code,
        },
      },
    })
      .then(({ data: { updateSbmLinkingCodeChallenge } }) => {
        setSubmitting(false);
        if (updateSbmLinkingCodeChallenge.result) {
          gtmPushEvent(gtmLinkingParams);
          setSubmitCodeSuccess(true);
          setMymcNumberVerificationData(null);
          setTimeout(() => {
            refetchLoyaltyPageQuery();
          }, 5000);
        } else {
          if (updateSbmLinkingCodeChallenge?.messages) {
            setErrors({
              messages: updateSbmLinkingCodeChallenge.messages,
            });
          }
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const resendCodeSubmit = () => {
    submitMethodMutate({
      variables: {
        value: {
          mymcNumber: mymcNumberVerificationData.mymcNumber,
          contact: activeMethodType,
        },
      },
    })
      .then(({ data: { updateSbmLinkingCode } }) => {
        console.log('Resubmit verification code');
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const initialMethodValues = {
    method: verificationMethods[0].value,
  };

  const initialCodeValues = {
    code: '',
  };

  return children({
    handleMethodSubmit,
    handleCodeSubmit,
    initialMethodValues,
    initialCodeValues,
    isSubmitMethodSuccess,
    setSubmitMethodSuccess,
    verificationMethods,
    activeMethodData,
    resendCodeSubmit,
    submitMethodLoading,
    submitMethodError,
    errors,
    resetMessage,
    submitCodeSuccess,
  });
};

CardVerificationFormHandler.propTypes = propTypes;

export default withErrorBoundary(CardVerificationFormHandler);
