import React, { Fragment } from 'react';
import { bool, string, object, array, oneOfType, func } from 'prop-types';
import classNames from 'classnames/bind';
import Slider from 'react-slick';

import Picture from 'common/components/Picture';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconSliderNav } from 'common/media/icons/slider-nav-button.icon.svg';
import { MD_PRODUCT } from 'common/constants';

import MyAccLink from '~/components/MyAccLink';

import myaccCardMediaFieldsFragment from './myaccCardMediaFieldsFragment';
import usePushItemPictures from './usePushItemPictures';
import styles from './PushLeftCol.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  data: oneOfType([array, object]).isRequired,
  longStyle: bool.isRequired,
  isSlider: bool,
  url: string,
  title: string.isRequired,
  onClick: func,
};

const defaultProps = {
  isSlider: false,
  onClick: null,
  url: null,
};

const fragment = {
  myaccCardMediaFieldsFragment,
};

const SliderArrow = ({ className, onClick }) => (
  <IconContainer icon={IconSliderNav} className={className} onClick={onClick} />
);

const PushLeftCol = ({
  isSlider,
  data: imageData,
  longStyle,
  url,
  title,
  onClick,
}) => {
  const pictures = usePushItemPictures({ isSlider, longStyle, imageData });

  const slickSettings = {
    dots: true,
    dotsClass: cx('slider-dots', 'js-slider-dots'),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    draggable: false,
    nextArrow: <SliderArrow />,
    prevArrow: <SliderArrow />,
    responsive: [
      {
        draggable: true,
        breakpoint: 769,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const getPicture = (sources, src, alt) => {
    return (
      <Picture
        sources={sources}
        src={src}
        alt={alt}
        itemProp={MD_PRODUCT.image}
      />
    );
  };

  return (
    <div
      className={cx(
        'push-left-col',
        { 'short-style': !longStyle },
        { 'long-style': longStyle },
        { 'slider-container': isSlider && pictures.length > 1 }
      )}
    >
      {isSlider && pictures.length > 1 ? (
        <Slider {...slickSettings}>
          {pictures.map(({ sources, src, alt }, index) => (
            <Fragment key={index}>
              {url ? (
                <MyAccLink alt={title} to={url} onClick={onClick}>
                  {getPicture(sources, src, alt)}
                </MyAccLink>
              ) : (
                getPicture(sources, src, alt)
              )}
            </Fragment>
          ))}
        </Slider>
      ) : (
        <>
          {url ? (
            <MyAccLink
              alt={title}
              to={url}
              className={cx('picture-link')}
              onClick={onClick}
            >
              {getPicture(
                pictures[0].sources,
                pictures[0].src,
                pictures[0].alt
              )}
            </MyAccLink>
          ) : (
            getPicture(pictures[0].sources, pictures[0].src, pictures[0].alt)
          )}
        </>
      )}
    </div>
  );
};

PushLeftCol.propTypes = propTypes;
PushLeftCol.defaultProps = defaultProps;
PushLeftCol.fragment = fragment;

export default PushLeftCol;
