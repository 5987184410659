import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { object, string } from 'prop-types';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheck } from 'common/media/icons/check.icon.svg';

import styles from './PasswordCheckList.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  validationSchema: object.isRequired,
  passwordValue: string,
};

const defaultProps = {
  passwordValue: null,
};

const PasswordCheckList = ({ validationSchema, passwordValue }) => {
  const passwordRequirements = validationSchema.fields.password.tests
    .filter(({ OPTIONS }) => !['required', 'matching'].includes(OPTIONS.name))
    .map(({ OPTIONS }) => OPTIONS.message);

  const [passwordCheckList, setPasswordCheckList] = useState(
    passwordRequirements
  );

  useEffect(() => {
    // catch all errors of field and apply it to checklist
    validationSchema
      .validate({ password: passwordValue }, { abortEarly: false })
      .then(() => {})
      .catch(({ errors }) => {
        setPasswordCheckList(errors);
      });
  }, [validationSchema, passwordValue]);

  return (
    <div className={cx('root')}>
      {passwordRequirements.map((point, index) => {
        const checkPassed = !passwordCheckList.includes(point);
        return (
          <div
            key={index}
            className={cx('check-list-point', {
              valid: checkPassed,
            })}
          >
            {checkPassed && (
              <IconContainer className={cx('icon-check')} icon={IconCheck} />
            )}
            <span className={cx('check-list-label')}>{point}</span>
          </div>
        );
      })}
    </div>
  );
};

PasswordCheckList.propTypes = propTypes;
PasswordCheckList.defaultProps = defaultProps;

export default withErrorBoundary(PasswordCheckList);
