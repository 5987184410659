import Url from 'url-parse';

export default function({ url, hostname } = {}) {
  if (!url || !hostname) {
    return false;
  }

  const linkHost = (function(url) {
    if (/^https?:\/\//.test(url)) {
      return new Url(url).hostname;
    } else {
      // Relative URL.
      return hostname;
    }
  })(url);

  return hostname !== linkHost;
}
