import React from 'react';
import { shape, bool, string } from 'prop-types';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheckbox } from 'common/media/icons/checkbox.icon.svg';
import { ReactComponent as IconCheckboxChecked } from 'common/media/icons/checkbox-checked.icon.svg';

import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: bool.isRequired,
  }).isRequired,
  label: string,
  textClassName: string,
  isIconHidden: bool,
  className: string,
  classNameCheckbox: string,
};

const defaultProps = {
  label: '',
  textClassName: null,
  isIconHidden: false,
  className: null,
  classNameCheckbox: null,
};

const Checkbox = ({
  field,
  field: { name, value },
  label,
  textClassName,
  isIconHidden,
  className,
  classNameCheckbox,
}) => (
  <div className={cx('root', className)}>
    <input
      {...field}
      checked={value}
      type="checkbox"
      name={name}
      className={cx('checkbox', classNameCheckbox)}
      id={name}
    />
    <label className={cx('label')} htmlFor={name}>
      {!isIconHidden && (
        <IconContainer
          className={cx('icon', { checked: value })}
          icon={value ? IconCheckboxChecked : IconCheckbox}
        />
      )}
      {label && <span className={cx('text', textClassName)}>{label}</span>}
    </label>
    <ErrorMessageCustom name={name}>
      {({ msg }) => <FieldError>{msg}</FieldError>}
    </ErrorMessageCustom>
  </div>
);

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
