import React from 'react';
import { object, func, string } from 'prop-types';
import classNames from 'classnames/bind';

import flatToNestedNSortedMenu from 'common/utils/flatToNestedNSortedMenu';

import styles from './SecMenu.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  footerSecMenu: object.isRequired,
  LinkComponent: func.isRequired,
  organisationPolicy: string,
  lang: string.isRequired,
};

const defaultProps = {
  className: null,
  organisationPolicy: null,
};

const renderSecMenu = (item, LinkComponent) => {
  return (
    <li key={item.id} className={cx('sec-menu-list-item')}>
      <LinkComponent to={item.url} forceNewTab={item.target === '_blank'}>
        {item.title}
      </LinkComponent>
    </li>
  );
};

const SecMenu = ({
  footerSecMenu,
  LinkComponent,
  organisationPolicy,
  className,
  lang,
}) => {
  const footerSecMenuNested = flatToNestedNSortedMenu(footerSecMenu.menuItems);

  const isClient = typeof window !== undefined;

  const handleDidomiCookiesClick = () => {
    if (isClient && window.Didomi) {
      window.Didomi.preferences.show();
    }
  };

  const didomiCookiesLabelMapping = {
    en: 'Cookies settings',
    fr: 'Paramètres cookies',
    it: 'Impostazioni Cookie',
    ru: 'Параметры файлов «Cookie»',
    de: 'Cookie-Einstellungen',
    'zh-hans': 'cookie 设置',
  };

  return (
    <div className={cx('root', className)} itemProp={organisationPolicy}>
      <ul className={cx('sec-menu-list')}>
        {footerSecMenuNested.map((el) => renderSecMenu(el, LinkComponent))}
        {isClient && (
          <li className={cx('sec-menu-list-item')}>
            <button type="button" onClick={handleDidomiCookiesClick}>
              {didomiCookiesLabelMapping[lang]}
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

SecMenu.propTypes = propTypes;
SecMenu.defaultProps = defaultProps;

export default SecMenu;
