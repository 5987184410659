import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames/bind';

import Button from 'common/components/Button';
import SpinLoader from 'common/components/SpinLoader';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconArrow } from 'common/media/icons/arrow-forward-xl.icon.svg';

import styles from './FormButton.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isSubmitting: bool.isRequired,
  disabled: bool.isRequired,
  buttonLabel: string.isRequired,
};

function FormButton({ isSubmitting, disabled, buttonLabel }) {
  return (
    <Button
      className={cx('root', {
        loading: isSubmitting,
      })}
      type="submit"
      disabled={disabled}
    >
      {isSubmitting && <SpinLoader className={cx('loader')} size={16} />}
      <span>{buttonLabel}</span>
      <IconContainer className={cx('icon-cta')} icon={IconArrow} />
    </Button>
  );
}

FormButton.propTypes = propTypes;

export default FormButton;
