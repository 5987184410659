import React from 'react';
import classNames from 'classnames/bind';

import styles from './StatusUser.module.scss';

const cx = classNames.bind(styles);

const StatusUser = ({ status, warnings, errors }) => {
  return (
    <>
      <span
        className={cx(
          'icon',
          status === 'CONFIRMED' ? 'confirmed' : 'not-confirmed'
        )}
      />
      {(warnings || errors) && (
        <span
          className={cx('icon', {
            warnings: warnings,
            errors: errors,
          })}
        />
      )}
    </>
  );
};

export default StatusUser;
