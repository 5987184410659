import React, { useState } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { gql, useMutation } from '@apollo/client';

import SubmitButton from '~/components/SubmitButton';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './UserNotifier.module.scss';

const cx = classNames.bind(styles);

const ResendValidationEmailMutation = gql`
  mutation resendConfirmation($id: String!) {
    resendConfirmation(id: $id) {
      result
      messages {
        info
        warning
        error
      }
    }
  }
`;

const propTypes = {
  accountStatus: string.isRequired,
  userId: string.isRequired,
};

function UserNotifier({ accountStatus, userId }) {
  const { t } = useTranslation('edit-account');

  const [isSubmitting, setSubmitting] = useState(false);
  const [isSaved, setSaved] = useState(false);

  const [mutate] = useMutation(ResendValidationEmailMutation);

  const handleResend = () => {
    setSubmitting(true);
    setSaved(false);

    mutate({
      variables: {
        id: userId,
      },
    })
      .then(({ data }) => {
        setSubmitting(false);
        setSaved(true);
      })
      .catch((error) => {
        console.warn(error);
        setSubmitting(false);
      });
  };

  return accountStatus !== 'CONFIRMED' ? (
    <div className={cx('confirmation-button-item')}>
      <SubmitButton
        isSubmitting={isSubmitting}
        isSaved={isSaved}
        saveButtonLabel={t('resendEmailLabel')}
        onClick={handleResend}
      />
    </div>
  ) : null;
}

UserNotifier.propTypes = propTypes;

export default withErrorBoundary(UserNotifier);
