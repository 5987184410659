import React from 'react';
import { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import uniqueId from 'lodash/uniqueId';
import dateFormat from '~/utils/dateFormat';

import SlideDownWrapper from '../../containers/SlideDownWrapper';
import styles from './UserLog.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  authHistory: array.isRequired,
};

function UserLog({ authHistory, parentRef }) {
  const { t } = useTranslation('edit-account');
  const logInfoSectionTitle = t('logInfoSectionTitle');
  const logDateTitle = t('logDateTitle');
  const logEventTitle = t('logEventTitle');
  const logResultTitle = t('logResultTitle');
  const logRiskLevelTitle = t('logRiskLevelTitle');
  const logRiskDecisionTitle = t('logRiskDecisionTitle');
  const logChallengeTitle = t('logChallengeTitle');
  const logIpTitle = t('logIpTitle');
  const logDeviceTitle = t('logDeviceTitle');
  const logLocationTitle = t('logLocationTitle');
  const logEventFeedbackTitle = t('logEventFeedbackTitle');

  return (
    <div className={cx('root')}>
      <SlideDownWrapper
        subtitleButtonLabel={logInfoSectionTitle}
        parentRef={parentRef}
      >
        <table className={cx('user-log')}>
          <thead>
            <tr>
              <td className={cx('table-header')}>{logDateTitle}</td>
              <td className={cx('table-header')}>{logEventTitle}</td>
              <td className={cx('table-header')}>{logResultTitle}</td>
              <td className={cx('table-header')}>{logRiskLevelTitle}</td>
              <td className={cx('table-header')}>{logRiskDecisionTitle}</td>
              <td className={cx('table-header')}>{logChallengeTitle}</td>
              <td className={cx('table-header')}>{logIpTitle}</td>
              <td className={cx('table-header')}>{logDeviceTitle}</td>
              <td className={cx('table-header')}>{logLocationTitle}</td>
              <td className={cx('table-header')}>{logEventFeedbackTitle}</td>
            </tr>
          </thead>
          <tbody>
            {authHistory.map(
              ({
                creationDate,
                eventType,
                eventResponse,
                challengeResponses,
                eventContextData: { ipAddress, deviceName, city },
                eventFeedback,
                eventRisk: { riskLevel, riskDecision },
              }) => {
                const index = uniqueId();
                return (
                  <tr className={cx('row')} key={index}>
                    <td className={cx('cell')}>{dateFormat(creationDate)}</td>
                    <td className={cx('cell')}>{eventType}</td>
                    <td className={cx('cell')}>{eventResponse}</td>
                    <td className={cx('cell')}>
                      {riskLevel === null ? '-' : riskLevel}
                    </td>
                    <td className={cx('cell')}>{riskDecision}</td>
                    <td className={cx('cell')}>
                      {challengeResponses.map(
                        ({ challengeName, challengeResponse }) =>
                          `${challengeName}: ${challengeResponse}`
                      )}
                    </td>
                    <td className={cx('cell')}>{ipAddress}</td>
                    <td className={cx('cell')}>{deviceName}</td>
                    <td className={cx('cell')}>{city}</td>
                    <td className={cx('cell')}>
                      {eventFeedback === null
                        ? '-'
                        : eventFeedback.feedbackValue}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </SlideDownWrapper>
    </div>
  );
}

UserLog.propTypes = propTypes;

export default UserLog;
