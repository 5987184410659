import React, { Fragment } from 'react';
import { bool, func, number } from 'prop-types';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';

import { MD_ITEM_LIST, MD_LIST_ITEM } from 'common/constants/microdata';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import PushLeftCol from '~/components/PushComponents/PushLeftCol';
import PushLayoutContainer, {
  PushLayoutContainerLeftCol,
  PushLayoutContainerRightCol,
} from '~/components/PushComponents/PushLayoutContainer';
import PushRightColButton from '~/components/PushComponents/PushRightColButton';
import PushRightColFooter from '~/components/PushComponents/PushRightColFooter';
import BestPriceBlock from '~/components/BestPriceBlock';
import PushRightColCenter, {
  PushRightColCenterTextPart,
} from '~/components/PushComponents/PushRightColCenter';
import PushRightColTitle from '~/components/PushComponents/PushRightColTitle';
import PushRightColParentTitleAttributes from '~/components/PushComponents/PushRightColParentTitleAttributes';
import WishlistButton from '~/components/WishlistButton';

import styles from './WellnessProductCard.module.scss';

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const wellnessProductCardFieldsFragment = gql`
  fragment wellnessProductCardFields on WellnessProduct {
    id
    title
    url
    introText(length: 256)
    pushWellnessProductMedia: media {
      ...myaccCardMediaFields
      ...myaccCardVideoFields
    }
    pushWellnessProductParentProperty: parentProperty {
      ... on WellnessProperty {
        id
        title
      }
      ... on Hotel {
        id
        title
        stars {
          label
          value
        }
      }
      ... on Restaurant {
        id
        title
        michelinStars {
          label
          value
        }
      }
      ... on Nightlife {
        id
        title
      }
    }
    wellnessProductCategory {
      name
    }
    productBestPrice {
      currency
      price
      priceLabel
    }
    onlineBooking {
      value
    }
    ctaLabel
    priceEmptyLabel
  }
  ${PushLeftCol.fragment.myaccCardMediaFieldsFragment}
`;

const propTypes = {
  data: propType(wellnessProductCardFieldsFragment).isRequired,
  isLeft: bool,
  longStyle: bool,
  sendStatClicksListingPushItemOnClick: func,
  sendStatisticPushItemOnClick: func,
  position: number,
  isUnauthorised: bool,
  onUnauthorisedCardClick: func,
};

const defaultProps = {
  longStyle: false,
  isLeft: false,
  sendStatClicksListingPushItemOnClick: null,
  sendStatisticPushItemOnClick: null,
  position: null,
  isUnauthorised: false,
  onUnauthorisedCardClick: null,
};

const fragment = {
  wellnessProductCardFieldsFragment,
};

const createOnClick =
  (id, sendStatClicksListingPushItemOnClick, sendStatisticPushItemOnClick) =>
  () => {
    sendStatClicksListingPushItemOnClick &&
      sendStatClicksListingPushItemOnClick(id);
    sendStatisticPushItemOnClick && sendStatisticPushItemOnClick(id);
  };

const WellnessProductCard = ({
  data: {
    id,
    title,
    pushWellnessProductMedia,
    introText,
    pushWellnessProductParentProperty,
    url,
    productBestPrice,
    ctaLabel,
    onlineBooking: { value: bookingStatus },
    wellnessProductCategory: productCategory,
    priceEmptyLabel,
  },
  isLeft,
  longStyle,
  position,
  sendStatClicksListingPushItemOnClick,
  sendStatisticPushItemOnClick,
  isUnauthorised,
  onUnauthorisedCardClick,
}) => {
  const parentPropertyArray = (() => {
    return pushWellnessProductParentProperty
      ? pushWellnessProductParentProperty.map((item) => item).filter(Boolean)
      : null;
  })();

  const productCategoryArray = (() => {
    return productCategory ? [{ name: productCategory.name }] : null;
  })();

  return (
    <MyAccLink
      className={cx('root', Button.shared.hovered)}
      to={
        isUnauthorised
          ? `${REACT_APP_MYACC_DOMAIN}`
          : `${REACT_APP_SBM_DOMAIN}${url}`
      }
      onClick={(e) => {
        if (onUnauthorisedCardClick) {
          onUnauthorisedCardClick(e);
        }
      }}
    >
      <WishlistButton id={id} />
      <PushLayoutContainer
        isLeft={isLeft}
        longStyle={longStyle}
        itemProp={MD_ITEM_LIST.element}
        itemScope
        itemType={MD_LIST_ITEM.type}
      >
        <meta itemProp={MD_LIST_ITEM.position} content={position} />
        <meta itemProp={MD_LIST_ITEM.url} content={url} />

        <PushLayoutContainerLeftCol>
          <PushLeftCol
            longStyle={longStyle}
            data={pushWellnessProductMedia}
            title={title}
            onClick={createOnClick(
              id,
              sendStatClicksListingPushItemOnClick,
              sendStatisticPushItemOnClick
            )}
          />
        </PushLayoutContainerLeftCol>

        <PushLayoutContainerRightCol>
          <PushRightColCenter longStyle={longStyle}>
            <PushRightColTitle
              onClick={createOnClick(
                id,
                sendStatClicksListingPushItemOnClick,
                sendStatisticPushItemOnClick
              )}
            >
              {title}
            </PushRightColTitle>

            <PushRightColCenterTextPart>
              <span dangerouslySetInnerHTML={{ __html: introText }} />
            </PushRightColCenterTextPart>

            <PushRightColParentTitleAttributes
              parentPropertyList={parentPropertyArray}
              attributesList={productCategoryArray}
              longStyle={longStyle}
            />
          </PushRightColCenter>

          <PushRightColFooter isLeft={isLeft} longStyle={longStyle}>
            {!!productBestPrice && productBestPrice.price && (
              <Fragment>
                <PushRightColCenter>
                  {!isUnauthorised && bookingStatus === '1' && (
                    <BestPriceBlock
                      className={cx('card-price')}
                      price={productBestPrice.price}
                      priceLabel={productBestPrice.priceLabel}
                      currency={productBestPrice.currency}
                      priceEmptyLabel={priceEmptyLabel}
                    />
                  )}
                  <PushRightColButton
                    theme={'rounded'}
                    size={'m'}
                    color={'tallow-invert'}
                    label={ctaLabel}
                  />
                </PushRightColCenter>
              </Fragment>
            )}
          </PushRightColFooter>
        </PushLayoutContainerRightCol>
      </PushLayoutContainer>
    </MyAccLink>
  );
};

WellnessProductCard.propTypes = propTypes;
WellnessProductCard.defaultProps = defaultProps;
WellnessProductCard.fragment = fragment;

export default withErrorBoundary(WellnessProductCard);
