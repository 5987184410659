import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Container from 'common/components/Container';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';

import MyAccModal from '~/components/MyAccModal';
import Button from '~/components/Button';
import { useLocalisationContext } from '~/context/LocalisationContext';

import styles from './WishlistUnauthorisedNotifyModal.module.scss';

const { REACT_APP_MYACC_DOMAIN } = process.env;

const cx = classNames.bind(styles);

const WishlistUnauthorisedNotifyModal = ({ handleModalClose }) => {
  const { language } = useLocalisationContext();
  const { t } = useTranslation('wishlist');

  return (
    <MyAccModal className={cx('modal-notify')} onClose={handleModalClose}>
      <Container width={760} className={cx('modal-notify-container')}>
        <div className={cx('modal-notify-header')}>
          <button className={cx('button-close')} onClick={handleModalClose}>
            <IconContainer className={cx('icon-close')} icon={IconClose} />
          </button>
          <h3 className={cx('title')}>
            {t('unauthorisedModal.unauthorisedModalTitle')}
          </h3>
        </div>

        <div className={cx('modal-notify-description')}>
          {t('unauthorisedModal.unauthorisedModalDescription')}
        </div>

        <div className={cx('modal-notify-footer')}>
          <Button
            theme="rounded"
            className={cx('button-login')}
            tag="a"
            href={`${REACT_APP_MYACC_DOMAIN}/${language}`}
          >
            {t('unauthorisedModal.unauthorisedModalLogin')}
          </Button>
          <Button
            inverted
            theme="rounded"
            tag="a"
            href={`${REACT_APP_MYACC_DOMAIN}/${language}/create-account`}
          >
            {t('unauthorisedModal.unauthorisedModalSignup')}
          </Button>
        </div>
      </Container>
    </MyAccModal>
  );
};

export default WishlistUnauthorisedNotifyModal;
