import React from 'react';
import { string, func, array, shape, bool } from 'prop-types';

import PanelField from '~/components/PanelField';
import NavLink from '~/components/NavLink';

const propTypes = {
  panelFields: array.isRequired,
  navLinkData: shape({
    url: string.isRequired,
    onClick: func.isRequired,
    className: string,
    label: string,
  }),
  isSeparator: bool,
};

const defaultProps = {
  navLinkData: null,
  isSeparator: true,
};

function PanelContent({ navLinkData, panelFields, isSeparator }) {
  return (
    <>
      {!!navLinkData && <NavLink {...navLinkData} />}
      {panelFields.map(
        ({ label, value }, index) =>
          !!value && (
            <PanelField
              label={label}
              value={value}
              key={index}
              isSeparator={isSeparator}
            />
          )
      )}
    </>
  );
}

PanelContent.propTypes = propTypes;
PanelContent.defaultProps = defaultProps;

export default PanelContent;
