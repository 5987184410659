import React from 'react';
import { Field } from 'formik';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as DragIcon } from 'common/media/icons/drag.icon.svg';

import Checkbox from '~/components/FormComponents/Checkbox';

import styles from './ContactChannelCheckbox.module.scss';

const cx = classNames.bind(styles);

const ContactChannelCheckbox = ({
  name,
  label,
  active,
  message,
  messageEnd,
  isMyCs,
}) => (
  <>
    <div
      className={cx('checkbox-wrapper', {
        'contact-channel-checkbox-inactive': !active,
      })}
    >
      <Field
        name={name}
        component={Checkbox}
        label={label}
        className={cx('contact-channel-checkbox')}
        classNameLabel={cx('contact-channel-checkbox-label')}
        disabled={!active}
      />
      <div className={cx('drag-icon-wrapper', 'draggable')}>
        <IconContainer
          className={cx('drag-icon', 'draggable')}
          icon={DragIcon}
        />
      </div>
    </div>
    {!active && message && !isMyCs && (
      <div className={cx('checkbox-message')}>
        {message}{' '}
        {messageEnd && (
          <Link
            to={'/account-information'}
            className={cx('checkbox-message-link')}
          >
            {messageEnd}
          </Link>
        )}
      </div>
    )}
  </>
);

export default ContactChannelCheckbox;
