import React, { useState } from 'react';
import { func, object, number, shape, oneOfType, string } from 'prop-types';
import { useQuery } from '@apollo/client';

import { getLoyaltyStatusLabel } from 'common/utils/userMenuUtils';

import PageLoader from '~/components/PageLoader';
import { sbmClient } from '~/apolloClient';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

const propTypes = {
  children: func.isRequired,
  query: object.isRequired,
  loyaltyStatus: number.isRequired,
  myPoints: number,
  activeFilter: shape({
    type: oneOfType([number, string]).isRequired,
    id: oneOfType([number, string]).isRequired,
  }),
};

const OffersListingHandler = ({
  children,
  loyaltyStatus,
  myPoints,
  query,
  activeFilter,
}) => {
  const filterType = 'offer_theme';
  const pointsFilterType = 'points';
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const { loading, data, fetchMore } = useQuery(query, {
    client: sbmClient,
    variables: {
      type: 'offer',
      first: 9,
      after: 0,
      attributes: [
        {
          type: 'offer_loyalty',
          filters: [getLoyaltyStatusLabel(loyaltyStatus)],
        },
        {
          type: filterType,
          filters: activeFilter.type === filterType ? [activeFilter.id] : [],
        },
      ],
      ...(activeFilter?.type === pointsFilterType && {
        points: { lessThan: String(myPoints) },
      }),
    },
    context: {
      headers: {
        'X-Acc-Grp': loyaltyStatus,
      },
    },
  });

  if (loading) {
    return <PageLoader />;
  }

  const {
    items: { results, filters, hasNextPage, loadMoreButtonLabel, endCursor },
  } = data?.listing;

  const listFilters = filters?.attributes?.find(
    (filter) => filter.type === filterType
  );

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    return {
      ...previousResult,
      listing: {
        ...previousResult.listing,
        items: {
          ...previousResult.listing.items,
          ...fetchMoreResult.listing.items,
          results: [
            ...previousResult.listing.items.results,
            ...fetchMoreResult.listing.items.results,
          ],
        },
      },
    };
  };

  const loadMore = function () {
    setFetchMoreLoading(true);
    fetchMore({
      variables: {
        after: endCursor,
      },
      updateQuery,
    })
      .then(() => {
        setFetchMoreLoading(false);
      })
      .catch(() => {
        setFetchMoreLoading(false);
      });
  };

  return children({
    loadMore,
    listFilters,
    results,
    fetchMoreLoading,
    hasNextPage,
    loadMoreButtonLabel,
  });
};

OffersListingHandler.propTypes = propTypes;

export default withErrorBoundary(OffersListingHandler);
