import React from 'react';
import { string, number } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './SpinLoader.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  size: number,
  className: string,
};

const defaultProps = {
  size: 20,
  className: null,
};

const SpinLoader = ({ className, size }) => {
  const sizeCSS = `${size / 16}rem`;

  return (
    <span
      style={{ width: sizeCSS, height: sizeCSS }}
      className={cx('loader', className)}
    />
  );
};

SpinLoader.propTypes = propTypes;
SpinLoader.defaultProps = defaultProps;

export default SpinLoader;
