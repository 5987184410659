import React from 'react';
import { bool, func, number } from 'prop-types';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import moment from 'moment';
import classNames from 'classnames/bind';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import PushLeftCol from '~/components/PushComponents/PushLeftCol';
import BestPriceBlock from '~/components/BestPriceBlock';
import PushLayoutContainer, {
  PushLayoutContainerLeftCol,
  PushLayoutContainerRightCol,
} from '~/components/PushComponents/PushLayoutContainer';
import PushRightColButton from '~/components/PushComponents/PushRightColButton';
import { PushRightColHeaderLeftCol } from '~/components/PushComponents/PushRightColHeader';
import PushRightColFooter from '~/components/PushComponents/PushRightColFooter';
import PushRightColCenter, {
  PushRightColCenterTextPart,
} from '~/components/PushComponents/PushRightColCenter';
import PushRightColTitle from '~/components/PushComponents/PushRightColTitle';
import PushRightColParentTitleAttributes from '~/components/PushComponents/PushRightColParentTitleAttributes';
import WishlistButton from '~/components/WishlistButton';

import styles from './EventCard.module.scss';

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const eventCardFieldsFragment = gql`
  fragment eventCardFields on Event {
    id
    title
    url
    introText(length: 256)
    isConcert
    isCasinoTournament
    primaryAddress
    bookingStatus {
      label
      value
    }
    onDemandMail
    pushEventMedia: media {
      ...myaccCardMediaFields
      ...myaccCardVideoFields
    }
    price
    priceLabel
    priceEmptyLabel
    currency
    pushEventDateRage: dateRange
    style
    startDate
    endDate
    approximateStartDate
    recurrenceLabel
  }
  ${PushLeftCol.fragment.myaccCardMediaFieldsFragment}
`;

const propTypes = {
  data: propType(eventCardFieldsFragment).isRequired,
  priceVisible: bool,
  isLeft: bool,
  longStyle: bool,
  isDisplayInroText: bool,
  sendStatClicksListingPushItemOnClick: func,
  sendStatisticPushItemOnClick: func,
  position: number,
  isUnauthorised: bool,
  onUnauthorisedCardClick: func,
};

const defaultProps = {
  longStyle: false,
  isDisplayInroText: true,
  priceVisible: true,
  isLeft: false,
  sendStatClicksListingPushItemOnClick: null,
  sendStatisticPushItemOnClick: null,
  position: null,
  isUnauthorised: false,
  onUnauthorisedCardClick: null,
};

const fragment = {
  eventCardFieldsFragment,
};

const createOnClick =
  (id, sendStatClicksListingPushItemOnClick, sendStatisticPushItemOnClick) =>
  () => {
    sendStatClicksListingPushItemOnClick &&
      sendStatClicksListingPushItemOnClick(id);
    sendStatisticPushItemOnClick && sendStatisticPushItemOnClick(id);
  };

const EventCard = ({
  data: {
    id,
    url,
    title,
    pushEventMedia,
    introText,
    primaryAddress,
    price,
    priceLabel,
    priceEmptyLabel,
    currency,
    pushEventDateRage,
    isConcert,
    isCasinoTournament,
    bookingStatus: {
      label: bookingStatusLabel,
      value: bookingStatusValue, // available|sold_out|passed|discover|soon_available|on_demand
    },
    onDemandMail,
    startDate,
    endDate,
    approximateStartDate,
    recurrenceLabel,
    style,
  },
  priceVisible,
  isLeft,
  isDisplayInroText,
  longStyle,
  sendStatClicksListingPushItemOnClick,
  sendStatisticPushItemOnClick,
  isUnauthorised,
  onUnauthorisedCardClick,
}) => {
  const styleArray = isConcert ? style.map((item) => ({ name: item })) : null;

  const isBookingButtonDisabled =
    bookingStatusValue === 'sold_out' ||
    bookingStatusValue === 'passed' ||
    bookingStatusValue === 'soon_available' ||
    (bookingStatusValue === 'on_demand' && !onDemandMail);

  const ctaUrl = isBookingButtonDisabled
    ? null
    : bookingStatusValue === 'on_demand' && onDemandMail
    ? `mailto:${onDemandMail}`
    : url;

  const eventDate =
    approximateStartDate ||
    (endDate && pushEventDateRage) ||
    moment(startDate).format('DD/MM/YYYY');

  return (
    <MyAccLink
      className={cx('root', Button.shared.hovered)}
      to={
        isUnauthorised
          ? `${REACT_APP_MYACC_DOMAIN}`
          : `${REACT_APP_SBM_DOMAIN}${url}`
      }
      onClick={(e) => {
        if (onUnauthorisedCardClick) {
          onUnauthorisedCardClick(e);
        }
      }}
    >
      <WishlistButton id={id} />

      <PushLayoutContainer isLeft={isLeft} longStyle={longStyle}>
        <PushLayoutContainerLeftCol>
          <PushLeftCol
            longStyle={longStyle}
            data={pushEventMedia}
            title={title}
            onClick={createOnClick(
              id,
              sendStatClicksListingPushItemOnClick,
              sendStatisticPushItemOnClick
            )}
          />
        </PushLayoutContainerLeftCol>

        <PushLayoutContainerRightCol>
          <PushRightColCenter longStyle={longStyle}>
            <PushRightColTitle
              onClick={createOnClick(
                id,
                sendStatClicksListingPushItemOnClick,
                sendStatisticPushItemOnClick
              )}
            >
              <span>{title}</span>
            </PushRightColTitle>
            <PushRightColHeaderLeftCol>
              <p>{eventDate}</p>
              {recurrenceLabel && <p>{recurrenceLabel}</p>}
            </PushRightColHeaderLeftCol>

            {isDisplayInroText && (
              <PushRightColCenterTextPart longStyle={longStyle} textShort>
                <span dangerouslySetInnerHTML={{ __html: introText }} />
              </PushRightColCenterTextPart>
            )}

            <PushRightColParentTitleAttributes
              parentTitle={primaryAddress}
              attributesList={styleArray}
            />
          </PushRightColCenter>

          <PushRightColFooter isLeft={isLeft} longStyle={longStyle}>
            <PushRightColCenter>
              {!isUnauthorised &&
                priceVisible &&
                (isConcert || isCasinoTournament) && (
                  <BestPriceBlock
                    price={price}
                    priceLabel={priceLabel}
                    currency={currency}
                    priceEmptyLabel={priceEmptyLabel}
                  />
                )}
              <PushRightColButton
                theme={'rounded'}
                size={'m'}
                color={'tallow-invert'}
                disabled={isBookingButtonDisabled}
                label={bookingStatusLabel}
                {...(!isBookingButtonDisabled && { url: ctaUrl })}
              />
            </PushRightColCenter>
          </PushRightColFooter>
        </PushLayoutContainerRightCol>
      </PushLayoutContainer>
    </MyAccLink>
  );
};

EventCard.propTypes = propTypes;
EventCard.defaultProps = defaultProps;
EventCard.fragment = fragment;

export default withErrorBoundary(EventCard);
