import React, { useCallback, useState } from 'react';
import { object, func } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import gtmPushEvent from 'common/utils/gtmPushEvent';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheckbox } from 'common/media/icons/checkbox.icon.svg';
import { ReactComponent as IconCheckboxChecked } from 'common/media/icons/checkbox-checked.icon.svg';

import styles from './DeleteDataModal.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  modalRef: object.isRequired,
  modalToggle: func.isRequired,
  deleteData: func.isRequired,
};

const gtmDeletionParams = {
  event: 'delete_personal_data',
};

function DeleteDataModal({
  modalRef,
  modalToggle,
  deleteData,
  user: { id, firstName, lastName, email },
}) {
  const { t } = useTranslation('maintenance');
  const modalText = t('deleteAccountBlock.deleteAccountText');
  const deleteAccountConfirmationLabel = t(
    'deleteAccountBlock.deleteAccountConfirmationLabel'
  );
  const confirmDeleteDataButtonLabel = t(
    'deleteAccountBlock.confirmDeleteDataButtonLabel'
  );
  const cancelDeleteDataButtonLabel = t(
    'deleteAccountBlock.cancelDeleteDataButtonLabel'
  );

  const handleDeletion = useCallback(() => {
    gtmPushEvent(gtmDeletionParams);
    modalToggle();
    deleteData();
  }, [deleteData, modalToggle]);

  const [confirmationChecked, setConfirmationChecked] = useState(false);

  return (
    <div className={cx('root')}>
      <div className={cx('modal')} ref={modalRef}>
        <div className={cx('modal-text')}>
          <div dangerouslySetInnerHTML={{ __html: modalText }} />
        </div>
        <pre className={cx('user-data')}>{`
          ${id}
          ${firstName} ${lastName}
          ${email}
        `}</pre>
        <div className={cx('confirmation-checkbox-wrapper')}>
          <input
            type="checkbox"
            name={'deletion-confirm'}
            className={cx('checkbox')}
            id={'deletion-confirm'}
            onChange={() => setConfirmationChecked(!confirmationChecked)}
          />
          <label className={cx('label')} htmlFor={'deletion-confirm'}>
            <IconContainer
              className={cx('icon', { confirmationChecked })}
              icon={confirmationChecked ? IconCheckboxChecked : IconCheckbox}
            />
            <span
              className={cx('confirmation-label')}
              dangerouslySetInnerHTML={{
                __html: deleteAccountConfirmationLabel,
              }}
            />
          </label>
        </div>
        <div className={cx('confirm-buttons')}>
          <button
            type="button"
            className={cx('confirm-button')}
            onClick={handleDeletion}
            disabled={!confirmationChecked}
          >
            {confirmDeleteDataButtonLabel}
          </button>
          <button
            type="button"
            className={cx('confirm-button', 'negative')}
            onClick={modalToggle}
          >
            {cancelDeleteDataButtonLabel}
          </button>
        </div>
      </div>
    </div>
  );
}

DeleteDataModal.propTypes = propTypes;

export default DeleteDataModal;
