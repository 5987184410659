import { useMemo } from 'react';

const { REACT_APP_MYACC_ENV: INSTANCE } = process.env;

export function useInstance() {
  const instance = useMemo(() => {
    if (INSTANCE === 'local' || INSTANCE === 'dev') {
      return 'development';
    }

    if (INSTANCE === 'stage') {
      return 'preproduction';
    }

    if (INSTANCE === 'prod') {
      return 'production';
    }

    return INSTANCE;
  }, []);

  return instance;
}
