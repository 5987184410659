import React, { useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { array, string } from 'prop-types';

import ScrollToTopOnLocationChange from 'common/components/ScrollToTopOnLocationChange';
import { MYACC_LANGUAGE_WHITELIST } from 'common/constants';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useMyaccHistory } from '~/hooks';

const propTypes = {
  routesList: array.isRequired,
  noMatchUrl: string,
};

const defaultProps = {
  noMatchUrl: null,
};

function Routes({ routesList, noMatchUrl }) {
  const history = useMyaccHistory();
  const { language } = useLocalisationContext();

  const {
    location: { pathname, search },
  } = history;

  const localizedNoMatchUrl = useMemo(
    () => `/${language}${noMatchUrl || ''}`,
    [language, noMatchUrl]
  );

  if (!MYACC_LANGUAGE_WHITELIST.includes(pathname.split('/')[1])) {
    history.push(localizedNoMatchUrl);
  }

  return (
    <>
      <Route component={ScrollToTopOnLocationChange} />
      <Switch>
        {routesList.map((listProps, index) => (
          <Route key={index} exact {...listProps} />
        ))}
        <Route
          render={() => (
            <Redirect to={{ pathname: localizedNoMatchUrl, search }} />
          )}
        />
      </Switch>
    </>
  );
}

Routes.propTypes = propTypes;
Routes.defaultProps = defaultProps;

export default Routes;
