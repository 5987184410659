import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { ButtonInverted } from 'common/components/Buttons';
import AuthFormTitle from 'common/components/AuthFormTitle';

import { useAuthContext } from '~/context/AuthContext';
import { useMyaccHistory } from '~/hooks';
import { useLocalisationContext } from '~/context/LocalisationContext';

import styles from './BottomBlock.module.scss';

const cx = classNames.bind(styles);

const BottomBlock = () => {
  const history = useMyaccHistory();
  const { setErrorMsg } = useAuthContext();

  const onCreateAccountClick = () => {
    setErrorMsg('');
    history.push({
      pathname: '/create-account',
      search: history.location.search,
    });
  };

  const { language } = useLocalisationContext();

  const { t } = useTranslation('auth');

  // I18n data
  const createAccountLabel = t('login.createAccountLabel');
  const secondaryTitle = t('login.secondaryTitle');
  const createAccountInfoLabel = t('login.createAccountInfoLabel');

  const didomiCookiesLabelMapping = {
    en: 'Cookies settings',
    fr: 'Paramètres cookies',
    it: 'Impostazioni Cookie',
  };

  const handleDidomiCookiesClick = () => {
    if (window.Didomi) {
      window.Didomi.preferences.show();
    }
  };

  return (
    <div className={cx('root')}>
      <AuthFormTitle
        title={secondaryTitle}
        subtitle={createAccountInfoLabel}
        className={cx('secondary-title')}
      />
      <ButtonInverted
        onClick={onCreateAccountClick}
        color="tallow"
        className={cx('button-create-acc')}
      >
        {createAccountLabel}
      </ButtonInverted>
      <button
        type="button"
        onClick={handleDidomiCookiesClick}
        className={cx('button-link')}
      >
        {didomiCookiesLabelMapping[language]}
      </button>
    </div>
  );
};

export default BottomBlock;
