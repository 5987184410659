import React from 'react';
import classNames from 'classnames/bind';
import { bool, func, node, string } from 'prop-types';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './StickyBlock.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isClosable: bool,
  closeBlock: func,
  children: node,
  className: string,
};

const defaultProps = {
  children: null,
  isClosable: false,
  closeBlock: null,
  className: '',
};

const StickyBlock = function ({ children, isClosable, closeBlock, className }) {
  return (
    <div className={cx('root', className)}>
      {isClosable && (
        <div className={cx('close')}>
          <button
            type="button"
            className={cx('close-btn')}
            onClick={closeBlock}
          >
            <IconContainer className={cx('close-icon')} icon={IconClose} />
          </button>
        </div>
      )}
      {children}
    </div>
  );
};

StickyBlock.propTypes = propTypes;
StickyBlock.defaultProps = defaultProps;

export default withErrorBoundary(StickyBlock);
