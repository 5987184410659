import { useState, useMemo, useCallback } from 'react';
import { object, string } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';

import applyNameFormat from '~/utils/applyNameFormat';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { latinLettersRegexp } from '~/constants';

const EditMainInfoSendMutation = gql`
  mutation updateCustomerInfo($id: String!, $value: CustomerInfoInput!) {
    updateCustomerInfo(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  userHash: string.isRequired,
  data: object,
  validationRequiredFieldMessage: string.isRequired,
  validationLettersOnlyMessage: string.isRequired,
};

const defaultProps = {
  data: null,
};

function EditMainInfoFormHandler({
  userHash,
  children,
  data,
  validationRequiredFieldMessage,
  validationLettersOnlyMessage,
}) {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess) {
      setSubmitError(false);
      setSubmitSuccess(false);
    }
  }, [submitError, submitSuccess]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        gender: Yup.string(),
        firstName: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationLettersOnlyMessage),
        lastName: Yup.string().matches(
          latinLettersRegexp,
          validationLettersOnlyMessage
        ),
        email: Yup.string().required(validationRequiredFieldMessage),
        birthDate: Yup.string().required(validationRequiredFieldMessage),
      }),
    [validationLettersOnlyMessage, validationRequiredFieldMessage]
  );

  const [updateMainInfo] = useMutation(EditMainInfoSendMutation);

  const initialValues = {
    gender: data?.gender || '',
    firstName: data?.firstName || '',
    lastName: data?.lastName || '',
    birthDate: data?.birthDate || '',
    email: data?.email || '',
    phoneNumber: data?.phoneNumber || '',
  };

  const handleSubmit = (
    { gender, firstName, lastName, birthDate, phoneNumber },
    { setSubmitting, setFieldValue }
  ) => {
    const submitFields = {
      gender,
      firstName: applyNameFormat(firstName),
      lastName: applyNameFormat(lastName),
      birthDate,
      phoneNumber,
    };

    setFieldValue('firstName', applyNameFormat(firstName));
    setFieldValue('lastName', applyNameFormat(lastName));

    updateMainInfo({
      variables: {
        id: userHash,
        value: submitFields,
      },
    })
      .then(({ data: { updateCustomerInfo } }) => {
        setSubmitting(false);

        if (updateCustomerInfo) {
          setSubmitSuccess(true);
        }
      })
      .catch((error) => {
        console.warn(error);
        setSubmitError(true);
        setSubmitting(false);
      });
  };

  return children({
    validationSchema,
    initialValues,
    handleSubmit,
    submitError,
    submitSuccess,
    resetMessage,
  });
}

EditMainInfoFormHandler.propTypes = propTypes;
EditMainInfoFormHandler.defaultProps = defaultProps;

export default withErrorBoundary(EditMainInfoFormHandler);
