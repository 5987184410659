import { TABLET_MEDIA_STR, MOBILE_MEDIA_STR } from 'common/constants';

const getPictureSources = ({ image: imageMediaData, longStyle }) => [
  {
    srcSet: `${imageMediaData.listItemCommonMobile.url} 1x, ${imageMediaData.listItemCommonMobile2x.url} 2x`,
    media: MOBILE_MEDIA_STR,
    type: 'image/webp',
  },
  {
    srcSet: `${imageMediaData.listItemCommonTablet.url} 1x, ${imageMediaData.listItemCommonTablet2x.url} 2x`,
    media: TABLET_MEDIA_STR,
    type: 'image/webp',
  },
  ...(longStyle
    ? [
        {
          srcSet: `${imageMediaData.listItemLongStyleDesktop.url} 1x, ${imageMediaData.listItemLongStyleDesktop2x.url} 2x`,
          type: 'image/webp',
        },
      ]
    : [
        {
          srcSet: `${imageMediaData.listItemShortStyleDesktop.url} 1x, ${imageMediaData.listItemShortStyleDesktop2x.url} 2x`,
          type: 'image/webp',
        },
      ]),
];

const getPictureSrc = ({ image: imageMediaData, longStyle }) =>
  longStyle
    ? imageMediaData.listItemLongStyleDesktop.url
    : imageMediaData.listItemShortStyleDesktop.url;

const getPictureHeight = ({ image: imageMediaData, longStyle }) =>
  longStyle
    ? imageMediaData.listItemLongStyleDesktop.height
    : imageMediaData.listItemShortStyleDesktop.height;

const getPictureWidth = ({ image: imageMediaData, longStyle }) =>
  longStyle
    ? imageMediaData.listItemLongStyleDesktop.width
    : imageMediaData.listItemShortStyleDesktop.width;

const getPictureAlt = ({ image: imageMediaData }) => imageMediaData.alt;

const usePushItemPictures = ({ isSlider, longStyle, imageData }) => {
  if (!isSlider || !Array.isArray(imageData) || imageData.length === 1) {
    const firstItem = Array.isArray(imageData) ? imageData[0] : imageData;
    const firstImage = !firstItem.image ? firstItem.video : firstItem.image;

    return [
      {
        sources: getPictureSources({ image: firstImage, longStyle }),
        src: getPictureSrc({ image: firstImage, longStyle }),
        height: getPictureHeight({ image: firstImage, longStyle }),
        width: getPictureWidth({ image: firstImage, longStyle }),
        alt: getPictureAlt({ image: firstImage }),
        placeholder: firstImage.listItemPlaceholder.url,
      },
    ];
  }

  const preparedData = imageData.map((image) => {
    const imageItem = !image.image ? image.video : image.image;

    return imageItem
      ? {
          sources: getPictureSources({ image: imageItem, longStyle }),
          src: getPictureSrc({ image: imageItem, longStyle }),
          height: getPictureHeight({ image: imageItem, longStyle }),
          width: getPictureWidth({ image: imageItem, longStyle }),
          alt: getPictureAlt({ image: imageItem }),
          placeholder: imageItem.listItemPlaceholder.url,
        }
      : {};
  });

  return preparedData.filter((element) => {
    return Object.keys(element).length !== 0;
  });
};

export default usePushItemPictures;
