import { gql } from '@apollo/client';

export const WarmUpQuery = gql`
  query WarmUpQueryData {
    getSbmIdentity {
      uuid
    }
  }
`;

export const UserQuery = gql`
  query UserQueryData {
    getSbmIdentity {
      uuid
      firstName
      birthName
      middleName
      marriedName
      gender
      birthDate
      countryOfResidence
      nationalities
      occupation
    }
    getSbmLoyalty {
      status
      blocks {
        loyalty {
          status
          userFid
          rewardPoints
        }
      }
    }
    getSbmContacts {
      email {
        id
        email
        category
        isDeletable
        isMutable
        isValid
        owner
      }
      phone {
        id
        category
        isDeletable
        isMutable
        isValid
        owner
        phone
      }
      address {
        id
        address1
        address2
        category
        city
        country
        isDeletable
        isMutable
        owner
        state
        validStatus
        zipCode
      }
    }
    getSbmPreferences {
      language
      contactChannels {
        method
        value
      }
      preferences {
        option
        subOptions
      }
      frequency {
        option
      }
      season {
        option
        subOptions
      }
    }
    getSbmGdpr {
      id
      value
      source
      date
    }
    getStatus {
      user # fail-safe mode. true - mean that everything is ok
    }
  }
`;

export const NotificationsDataQuery = gql`
  query NotificationsData($language: SbmPreferencesLanguage) {
    getNotifications(language: $language) {
      id
      message
      created
      status
    }
  }
`;

export const WishlistIdsDataQuery = gql`
  query WishlistIdsData {
    getWishlist {
      published
      hash
      items
    }
  }
`;
