import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Formik, Form, Field } from 'formik';

import SpinLoader from 'common/components/SpinLoader';
import Input from 'common/components/FormComponents/Input';
import InputPassword from 'common/components/FormComponents/InputPassword';
import Button from 'common/components/Button';
import InformationPanel from 'common/components/InformationPanel';
import AuthMessage from 'common/components/AuthMessage';
import AuthFormTitle from 'common/components/AuthFormTitle';

import { useMyaccHistory } from '~/hooks';
import GTM from '~/components/GTM';
import { useAuthContext } from '~/context/AuthContext';

import LoginFormHandler from './containers/LoginFormHandler';
import styles from './LoginForm.module.scss';

const cx = classNames.bind(styles);

const LoginForm = () => {
  const [isAutofilled, setIsAutofilled] = useState(false);

  const history = useMyaccHistory();
  const { errorMsg, successMsg, clearAllMsg } = useAuthContext();

  const onForgotPassClick = () => {
    clearAllMsg();
    history.push('/forgot-password');
  };

  const { t } = useTranslation('auth');

  const title = t('login.title');
  const subtitle = t('login.subtitle');
  const emailLabel = t('login.emailLabel');
  const passwordLabel = t('login.passwordLabel');
  const forgottenPasswordLabel = t('login.forgottenPasswordLabel');
  const signInLabel = t('login.signInLabel');
  const messageError = t('messageError');

  return (
    <div className={cx('root')}>
      <AuthFormTitle title={title} subtitle={subtitle} />
      {errorMsg && <AuthMessage>{errorMsg}</AuthMessage>}
      {successMsg && <AuthMessage isSuccessMsg>{successMsg}</AuthMessage>}
      <LoginFormHandler>
        {({
          handleSubmit,
          validationSchema,
          initialValues,
          submitError,
          captchaStatus,
          CaptchaWidget,
        }) => (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, values, errors }) => (
              <Form className={cx('form')}>
                <Field
                  name="email"
                  component={Input}
                  theme="block-theme"
                  type="email"
                  placeholder={emailLabel}
                  required
                  maxLength={128}
                  autoComplete="email"
                  setIsAutofilled={setIsAutofilled}
                />
                <Field
                  name="password"
                  component={InputPassword}
                  theme="block-theme"
                  placeholder={passwordLabel}
                  required
                  maxLength={128}
                  autoComplete="current-password"
                  setIsAutofilled={setIsAutofilled}
                />
                {submitError && (
                  <InformationPanel withIcon errorMode>
                    {messageError}
                  </InformationPanel>
                )}
                <Button
                  type="submit"
                  className={cx('submit-button', {
                    loading: isSubmitting || captchaStatus.loading,
                  })}
                  disabled={
                    isAutofilled
                      ? !isAutofilled
                      : !values.email ||
                        !values.password ||
                        !!errors.email ||
                        !!errors.password ||
                        isSubmitting ||
                        !captchaStatus.solution ||
                        captchaStatus.loading
                  }
                >
                  {(isSubmitting || captchaStatus.loading) && (
                    <SpinLoader className={cx('loader')} size={18} />
                  )}
                  {signInLabel}
                </Button>
                <CaptchaWidget className={cx('captcha')} />
                <div className={cx('form-bottom')}>
                  <div className={cx('form-bottom-item')}>
                    <button
                      onClick={onForgotPassClick}
                      type="button"
                      className={cx('form-button-link')}
                    >
                      {forgottenPasswordLabel}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </LoginFormHandler>
      <GTM />
    </div>
  );
};

export default LoginForm;
