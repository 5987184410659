import React from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames/bind';

import Button from '~/components/Button';

import styles from './ButtonPhone.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  phone: string.isRequired,
  label: string,
  onClick: func,
};

const defaultProps = {
  className: null,
  label: null,
  onClick: null,
};

const ButtonPhone = ({ className, phone, label, onClick }) => {
  return (
    <Button
      to={`tel:${phone}`}
      className={cx('root', 'link', className)}
      onClick={onClick}
    >
      {!!label && <span className={cx('label')}>{label}</span>}
      <span className={cx('phone', { 'no-label': !label })}>{phone}</span>
    </Button>
  );
};

ButtonPhone.propTypes = propTypes;
ButtonPhone.defaultProps = defaultProps;

export default ButtonPhone;
