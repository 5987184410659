import React from 'react';
import { object, func } from 'prop-types';

const EmptyItem = () => <div />;

const propTypes = {
  mapping: object.isRequired,
  children: func.isRequired,
};

const TypeToComponent = ({ children, mapping }) => {
  const getComponent = (name) => {
    const item = mapping[name];

    if (item) {
      return item;
    } else {
      console.warn(
        `No Component for type ${name} is specified in mapping, please review "TypeToComponent".`
      );
      return EmptyItem;
    }
  };

  return children({ getComponent });
};

TypeToComponent.propTypes = propTypes;

export default TypeToComponent;
