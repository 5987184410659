import React, { useRef, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { bool, string, node } from 'prop-types';
import { Transition } from 'react-transition-group';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconError } from 'common/media/icons/notification.icon.svg';

import styles from './AuthMessage.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isSuccessMsg: bool,
  className: string,
  children: node,
};

const defaultProps = {
  isSuccessMsg: false,
  className: null,
  children: null,
};

const AuthMessage = ({ isSuccessMsg, children, className }) => {
  const {
    el,
    timeout,
    defaultStyles,
    transitionStyles,
    textDefaultStyles,
    textTransitionStyles,
  } = useTransition();

  return (
    <Transition in={true} appear={true} timeout={timeout}>
      {(state) => (
        <div
          style={{
            ...defaultStyles,
            ...transitionStyles[state],
          }}
          className={cx('root', { success: isSuccessMsg }, className)}
          ref={el}
        >
          <div className={cx('inner')}>
            <IconContainer
              style={{
                ...textDefaultStyles,
                ...textTransitionStyles[state],
              }}
              className={cx('icon')}
              icon={IconError}
            />
            <p
              style={{
                ...textDefaultStyles,
                ...textTransitionStyles[state],
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: children }} />
          </div>
        </div>
      )}
    </Transition>
  );
};

AuthMessage.propTypes = propTypes;
AuthMessage.defaultProps = defaultProps;

export default AuthMessage;

function useTransition() {
  const duration = 160;
  const el = useRef();

  const defaultStyles = {
    transition: `transform ${duration}ms ease-out`,
  };

  const [transitionStyles, setTransitionStyles] = useState({
    entering: { maxHeight: 0, transform: 'scaleY(0)' },
    entered: { maxHeight: 0, transform: 'scaleY(0)' },
    exiting: { maxHeight: 0, transform: 'scaleY(0)' },
    exited: { maxHeight: 0, transform: 'scaleY(0)' },
  });

  const textDefaultStyles = {
    transition: `opacity ${duration}ms linear`,
  };

  const textTransitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
  };

  const timeout = { appear: 0, enter: duration, exit: duration };

  useEffect(() => {
    setTransitionStyles({
      entering: { transform: 'scaleY(0)' },
      entered: { transform: 'scaleY(1)' },
      exiting: { transform: 'scaleY(1)' },
      exited: { transform: 'scaleY(0)' },
    });
  }, []);

  return {
    el,
    timeout,
    defaultStyles,
    transitionStyles,
    textDefaultStyles,
    textTransitionStyles,
  };
}
