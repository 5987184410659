import { LOYALTY_STATUSES } from 'common/constants';

const _isOfferListLink = (url) => {
  return (
    url.slice(-7) === '/offers' ||
    url.slice(-7) === '/offres' ||
    url.slice(-9) === '/angebote' ||
    url.slice(-8) === '/offerte'
  );
};

const _isNotificationsLink = (url) => {
  return url.slice(-14) === '/notifications';
};

const getLoyaltyStatusLabel = (loyaltyStatus) =>
  LOYALTY_STATUSES[loyaltyStatus];

const isBulbVisible = (menuItems) =>
  !!menuItems.find(({ url }) => _isNotificationsLink(url));

const enhanceUserMenuItems = (menuItems) =>
  menuItems.reduce((items, item) => {
    items.push({
      ...item,
      isNotificationsItem: _isNotificationsLink(item.url),
      isOfferItem: _isOfferListLink(item.url),
    });
    return items;
  }, []);

export { isBulbVisible, enhanceUserMenuItems, getLoyaltyStatusLabel };
