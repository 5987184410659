import Cookie from 'js-cookie';
import moment from 'moment';

import { BRIDGE_LSKEY } from 'common/constants';

const CANARY_KEY = 'context';

const normalizeUrlToCrossDomain = (url) => {
  // RFC 2109 leading a dot (.montecarlosbm.com) would allow cookies to be used across subdomains
  const normalizedDomain = url.replace(
    /(^www.my.)|(^www.)|(^my-sbm-dev.)|(^b2c-sbm-dev.)|(^stage.)|(^my-stage.)|(^my.)/i,
    ''
  );
  return `.${normalizedDomain}`;
};

const convertValueToString = (value) => {
  return typeof value === 'object' ? JSON.stringify(value) : value;
};

const initialAttributes = {
  expires: moment().add(30, 'days').toDate(),
};

const setCookies = (name, value, attributes) => {
  if (value) {
    Cookie.set(name, convertValueToString(value), {
      ...initialAttributes,
      ...attributes,
    });
  }
};

const setCookiesCrossDomain = (name, value, attributes) => {
  setCookies(name, value, {
    domain: normalizeUrlToCrossDomain(window.location.hostname),
    ...initialAttributes,
    ...attributes,
  });
};

const getCookies = (name) => {
  const rawValue = Cookie.get(name);
  return rawValue && rawValue.startsWith('{') ? JSON.parse(rawValue) : rawValue;
};

const removeCookies = (name) => {
  Cookie.remove(name, {
    path: '/',
    domain: normalizeUrlToCrossDomain(window.location.hostname),
  });
};

const removeCognitoCookies = () => {
  const cognitoCookieNames = Object.keys(Cookie.get()).filter((cookieKey) =>
    cookieKey.startsWith('CognitoIdentityServiceProvider')
  );

  cognitoCookieNames.forEach((name) => {
    document.cookie = `${name}=; domain=${normalizeUrlToCrossDomain(
      window.location.hostname
    )}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
  });
};

const cleanUpMyIDCookies = () => {
  removeCookies(BRIDGE_LSKEY.authorized);
  removeCookies(BRIDGE_LSKEY.loyaltyStatus);
};

export {
  getCookies,
  setCookiesCrossDomain,
  setCookies,
  normalizeUrlToCrossDomain,
  removeCookies,
  removeCognitoCookies,
  cleanUpMyIDCookies,
  CANARY_KEY,
};
