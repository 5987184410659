import React from 'react';
import { shape, string, object } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './IntroSection.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  description: shape({
    text: string.isRequired,
  }).isRequired,
  introTitle: object,
  introText: object.isRequired,
  className: string,
};

const defaultProps = {
  introTitle: null,
  className: null,
};

const isRichText = (text) => text.startsWith('@');
const getKey = (label) => label.substr(1);

const IntroSection = ({ description: { text }, introText, className }) => {
  return (
    <div className={cx('root', className)}>
      {isRichText(text) && (
        <div
          className={cx('text')}
          dangerouslySetInnerHTML={{
            __html: introText[getKey(text)],
          }}
        />
      )}
    </div>
  );
};

IntroSection.propTypes = propTypes;
IntroSection.defaultProps = defaultProps;

export default IntroSection;
