import { useState, useMemo, useCallback } from 'react';
import { func, string } from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { useFriendlyCaptcha } from '~/hooks';
import { CaptchaResultQuery } from '~/queries';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { anonymousUserApolloClient } from '~/apolloClient';
import { useAuthContext } from '~/context/AuthContext';
import {
  numbersOnlyRegexp,
  latinLettersWithNumbersAwsRegexp,
} from '~/constants';

const propTypes = {
  children: func.isRequired,
  setPassChanged: func.isRequired,
  forgottenPassEmail: string.isRequired,
};

const ChangePassFormHandler = ({
  children,
  setPassChanged,
  forgottenPassEmail,
  verificationCode,
}) => {
  const { captchaReset, captchaStatus, CaptchaWidget } = useFriendlyCaptcha({
    id: 'change-pass',
  });

  const [loginLoading, setLoginLoading] = useState(false);

  const {
    signIn,
    errorMsg,
    setErrorMsg,
    forgotPasswordSubmit,
    setSuccessMsg,
    setPreFilledLogin,
  } = useAuthContext();

  const { t } = useTranslation('auth');

  const validationRequiredFieldMessage = t(
    'login.validationRequiredFieldMessage'
  );
  const validationIncorrectEmailMessage = t(
    'login.validationIncorrectEmailMessage'
  );
  const validationPassCharsCountMessage = t(
    'create.validationPassCharsCountMessage'
  );
  const validationPassUppercaseMessage = t(
    'create.validationPassUppercaseMessage'
  );
  const validationPassLowercaseMessage = t(
    'create.validationPassLowercaseMessage'
  );
  const validationPassSpecialMessage = t('create.validationPassSpecialMessage');
  const validationFieldPatternMessage = t(
    'create.validationFieldPatternMessage'
  );
  const forgotPassSubmitFormConfirmation = t(
    'login.forgotPassSubmitFormConfirmation'
  );
  const validationLatinLettersOnlyMessage = t(
    'create.validationLatinLettersOnlyMessage'
  );
  const captchaMessageError = t('captchaMessageError');

  const resetMessage = useCallback(() => {
    if (errorMsg) {
      setErrorMsg('');
    }
  }, [errorMsg, setErrorMsg]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .email(validationIncorrectEmailMessage)
          .required(validationRequiredFieldMessage),
        password: Yup.string()
          .required(validationRequiredFieldMessage)
          .min(8, validationPassCharsCountMessage)
          .matches(/[A-ZЀ-ЯҐ\u4E00-\u9FFF]/, validationPassUppercaseMessage)
          .matches(/[a-zа-ўґ\u4E00-\u9FFF]/, validationPassLowercaseMessage)
          .matches(
            /[^A-ZЀ-ЯҐa-zа-ўґ\u4E00-\u9FFF0-9]/,
            validationPassSpecialMessage
          )
          .matches(
            latinLettersWithNumbersAwsRegexp,
            validationLatinLettersOnlyMessage
          ),
        code: Yup.string()
          .matches(
            new RegExp(numbersOnlyRegexp, []),
            validationFieldPatternMessage
          )
          .required(validationRequiredFieldMessage),
      }),
    [
      validationIncorrectEmailMessage,
      validationRequiredFieldMessage,
      validationPassCharsCountMessage,
      validationPassUppercaseMessage,
      validationPassLowercaseMessage,
      validationPassSpecialMessage,
      validationLatinLettersOnlyMessage,
      validationFieldPatternMessage,
    ]
  );

  const handleSubmit = async (
    { email, password, code },
    { setSubmitting, resetForm }
  ) => {
    if (!captchaStatus.solution) {
      setSubmitting(false);
      resetForm();
      console.error('No captcha token generated');

      return null;
    }

    const captchaResultQueryData = await anonymousUserApolloClient
      .query({
        query: CaptchaResultQuery,
        variables: { response: captchaStatus.solution },
      })
      .catch((error) => {
        captchaReset();
        console.warn(error);
      });

    if (!captchaResultQueryData) {
      captchaReset();
      return null;
    }

    const { data, loading, error } = captchaResultQueryData;

    if (error) {
      console.error(`data: ${data}\n`, error);
    }

    if (error || loading) {
      return null;
    }

    const { getCaptchaResult } = data;

    if (!getCaptchaResult) {
      setErrorMsg(captchaMessageError);
      resetForm();
      setSubmitting(false);
      captchaReset();
      return null;
    }

    try {
      const forgotPasswordSubmitStatus = await forgotPasswordSubmit({
        username: email.toLowerCase(),
        code,
        password,
      });

      setSubmitting(false);

      if (forgotPasswordSubmitStatus === 'success') {
        setPreFilledLogin(email);
        setSuccessMsg(forgotPassSubmitFormConfirmation);
        setPassChanged(true);
        setLoginLoading(true);

        setTimeout(() => {
          signIn({
            username: email.toLowerCase(),
            password,
          })
            .then(() => {
              setLoginLoading(false);
              setSuccessMsg('');
            })
            .catch((error) => {
              console.warn(error);
            });
        }, 1500);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const initialValues = {
    email: forgottenPassEmail,
    code: verificationCode || '',
    password: '',
  };

  return children({
    handleSubmit,
    validationSchema,
    initialValues,
    resetMessage,
    loginLoading,
    captchaStatus,
    CaptchaWidget,
  });
};

ChangePassFormHandler.propTypes = propTypes;

export default withErrorBoundary(ChangePassFormHandler);
