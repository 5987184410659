/*
 * Source: https://restcountries.eu/rest/v2/all?fields=name;alpha3Code;translations
 *
  [~REST COUNTRIES ARRAY~].reduce((acc, item)=>{
    acc.push({
      name: item.name,
      nameIt: item.translations.it,
      nameFr: item.translations.fr,
      id: item.alpha3Code,
    })
    return acc;
  },[])
 * 
 */

export const COUNTRIES = [
  {
    name: 'Afghanistan',
    nameIt: 'Afghanistan',
    nameFr: 'Afghanistan',
    id: 'AFG',
  },
  {
    name: 'Åland Islands',
    nameIt: 'Isole Aland',
    nameFr: 'Åland',
    id: 'ALA',
  },
  {
    name: 'Albania',
    nameIt: 'Albania',
    nameFr: 'Albanie',
    id: 'ALB',
  },
  {
    name: 'Algeria',
    nameIt: 'Algeria',
    nameFr: 'Algérie',
    id: 'DZA',
  },
  {
    name: 'American Samoa',
    nameIt: 'Samoa Americane',
    nameFr: 'Samoa américaines',
    id: 'ASM',
  },
  {
    name: 'Andorra',
    nameIt: 'Andorra',
    nameFr: 'Andorre',
    id: 'AND',
  },
  {
    name: 'Angola',
    nameIt: 'Angola',
    nameFr: 'Angola',
    id: 'AGO',
  },
  {
    name: 'Anguilla',
    nameIt: 'Anguilla',
    nameFr: 'Anguilla',
    id: 'AIA',
  },
  {
    name: 'Antarctica',
    nameIt: 'Antartide',
    nameFr: 'Antarctique',
    id: 'ATA',
  },
  {
    name: 'Antigua and Barbuda',
    nameIt: 'Antigua e Barbuda',
    nameFr: 'Antigua-et-Barbuda',
    id: 'ATG',
  },
  {
    name: 'Argentina',
    nameIt: 'Argentina',
    nameFr: 'Argentine',
    id: 'ARG',
  },
  {
    name: 'Armenia',
    nameIt: 'Armenia',
    nameFr: 'Arménie',
    id: 'ARM',
  },
  {
    name: 'Aruba',
    nameIt: 'Aruba',
    nameFr: 'Aruba',
    id: 'ABW',
  },
  {
    name: 'Australia',
    nameIt: 'Australia',
    nameFr: 'Australie',
    id: 'AUS',
  },
  {
    name: 'Austria',
    nameIt: 'Austria',
    nameFr: 'Autriche',
    id: 'AUT',
  },
  {
    name: 'Azerbaijan',
    nameIt: 'Azerbaijan',
    nameFr: 'Azerbaïdjan',
    id: 'AZE',
  },
  {
    name: 'Bahamas',
    nameIt: 'Bahamas',
    nameFr: 'Bahamas',
    id: 'BHS',
  },
  {
    name: 'Bahrain',
    nameIt: 'Bahrein',
    nameFr: 'Bahreïn',
    id: 'BHR',
  },
  {
    name: 'Bangladesh',
    nameIt: 'Bangladesh',
    nameFr: 'Bangladesh',
    id: 'BGD',
  },
  {
    name: 'Barbados',
    nameIt: 'Barbados',
    nameFr: 'Barbade',
    id: 'BRB',
  },
  {
    name: 'Belarus',
    nameIt: 'Bielorussia',
    nameFr: 'Biélorussie',
    id: 'BLR',
  },
  {
    name: 'Belgium',
    nameIt: 'Belgio',
    nameFr: 'Belgique',
    id: 'BEL',
  },
  {
    name: 'Belize',
    nameIt: 'Belize',
    nameFr: 'Belize',
    id: 'BLZ',
  },
  {
    name: 'Benin',
    nameIt: 'Benin',
    nameFr: 'Bénin',
    id: 'BEN',
  },
  {
    name: 'Bermuda',
    nameIt: 'Bermuda',
    nameFr: 'Bermudes',
    id: 'BMU',
  },
  {
    name: 'Bhutan',
    nameIt: 'Bhutan',
    nameFr: 'Bhoutan',
    id: 'BTN',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    nameIt: 'Bolivia',
    nameFr: 'Bolivie',
    id: 'BOL',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    nameIt: 'Bonaire, Saint-Eustache e Saba',
    nameFr: 'Bonaire, Saint-Eustache et Saba',
    id: 'BES',
  },
  {
    name: 'Bosnia and Herzegovina',
    nameIt: 'Bosnia ed Erzegovina',
    nameFr: 'Bosnie-Herzégovine',
    id: 'BIH',
  },
  {
    name: 'Botswana',
    nameIt: 'Botswana',
    nameFr: 'Botswana',
    id: 'BWA',
  },
  {
    name: 'Bouvet Island',
    nameIt: 'Isola Bouvet',
    nameFr: 'Île Bouvet',
    id: 'BVT',
  },
  {
    name: 'Brazil',
    nameIt: 'Brasile',
    nameFr: 'Brésil',
    id: 'BRA',
  },
  {
    name: 'British Indian Ocean Territory',
    nameIt: "Territorio britannico dell'oceano indiano",
    nameFr: "Territoire britannique de l'océan Indien",
    id: 'IOT',
  },
  {
    name: 'United States Minor Outlying Islands',
    nameIt: "Isole minori esterne degli Stati Uniti d'America",
    nameFr: 'Îles mineures éloignées des États-Unis',
    id: 'UMI',
  },
  {
    name: 'Virgin Islands (British)',
    nameIt: 'Isole Vergini Britanniche',
    nameFr: 'Îles Vierges britanniques',
    id: 'VGB',
  },
  {
    name: 'Virgin Islands (U.S.)',
    nameIt: 'Isole Vergini americane',
    nameFr: 'Îles Vierges des États-Unis',
    id: 'VIR',
  },
  {
    name: 'Brunei Darussalam',
    nameIt: 'Brunei',
    nameFr: 'Brunei',
    id: 'BRN',
  },
  {
    name: 'Bulgaria',
    nameIt: 'Bulgaria',
    nameFr: 'Bulgarie',
    id: 'BGR',
  },
  {
    name: 'Burkina Faso',
    nameIt: 'Burkina Faso',
    nameFr: 'Burkina Faso',
    id: 'BFA',
  },
  {
    name: 'Burundi',
    nameIt: 'Burundi',
    nameFr: 'Burundi',
    id: 'BDI',
  },
  {
    name: 'Cambodia',
    nameIt: 'Cambogia',
    nameFr: 'Cambodge',
    id: 'KHM',
  },
  {
    name: 'Cameroon',
    nameIt: 'Camerun',
    nameFr: 'Cameroun',
    id: 'CMR',
  },
  {
    name: 'Canada',
    nameIt: 'Canada',
    nameFr: 'Canada',
    id: 'CAN',
  },
  {
    name: 'Cabo Verde',
    nameIt: 'Capo Verde',
    nameFr: 'Cap Vert',
    id: 'CPV',
  },
  {
    name: 'Cayman Islands',
    nameIt: 'Isole Cayman',
    nameFr: 'Îles Caïmans',
    id: 'CYM',
  },
  {
    name: 'Central African Republic',
    nameIt: 'Repubblica Centrafricana',
    nameFr: 'République centrafricaine',
    id: 'CAF',
  },
  {
    name: 'Chad',
    nameIt: 'Ciad',
    nameFr: 'Tchad',
    id: 'TCD',
  },
  {
    name: 'Chile',
    nameIt: 'Cile',
    nameFr: 'Chili',
    id: 'CHL',
  },
  {
    name: 'China',
    nameIt: 'Cina',
    nameFr: 'Chine',
    id: 'CHN',
  },
  {
    name: 'Christmas Island',
    nameIt: 'Isola di Natale',
    nameFr: 'Île Christmas',
    id: 'CXR',
  },
  {
    name: 'Cocos (Keeling) Islands',
    nameIt: 'Isole Cocos e Keeling',
    nameFr: 'Îles Cocos',
    id: 'CCK',
  },
  {
    name: 'Colombia',
    nameIt: 'Colombia',
    nameFr: 'Colombie',
    id: 'COL',
  },
  {
    name: 'Comoros',
    nameIt: 'Comore',
    nameFr: 'Comores',
    id: 'COM',
  },
  {
    name: 'Congo',
    nameIt: 'Congo',
    nameFr: 'Congo',
    id: 'COG',
  },
  {
    name: 'Congo (Democratic Republic of the)',
    nameIt: 'Congo (Rep. Dem.)',
    nameFr: 'Congo (Rép. dém.)',
    id: 'COD',
  },
  {
    name: 'Cook Islands',
    nameIt: 'Isole Cook',
    nameFr: 'Îles Cook',
    id: 'COK',
  },
  {
    name: 'Costa Rica',
    nameIt: 'Costa Rica',
    nameFr: 'Costa Rica',
    id: 'CRI',
  },
  {
    name: 'Croatia',
    nameIt: 'Croazia',
    nameFr: 'Croatie',
    id: 'HRV',
  },
  {
    name: 'Cuba',
    nameIt: 'Cuba',
    nameFr: 'Cuba',
    id: 'CUB',
  },
  {
    name: 'Curaçao',
    nameIt: 'Curaçao',
    nameFr: 'Curaçao',
    id: 'CUW',
  },
  {
    name: 'Cyprus',
    nameIt: 'Cipro',
    nameFr: 'Chypre',
    id: 'CYP',
  },
  {
    name: 'Czech Republic',
    nameIt: 'Repubblica Ceca',
    nameFr: 'République tchèque',
    id: 'CZE',
  },
  {
    name: 'Denmark',
    nameIt: 'Danimarca',
    nameFr: 'Danemark',
    id: 'DNK',
  },
  {
    name: 'Djibouti',
    nameIt: 'Gibuti',
    nameFr: 'Djibouti',
    id: 'DJI',
  },
  {
    name: 'Dominica',
    nameIt: 'Dominica',
    nameFr: 'Dominique',
    id: 'DMA',
  },
  {
    name: 'Dominican Republic',
    nameIt: 'Repubblica Dominicana',
    nameFr: 'République dominicaine',
    id: 'DOM',
  },
  {
    name: 'Ecuador',
    nameIt: 'Ecuador',
    nameFr: 'Équateur',
    id: 'ECU',
  },
  {
    name: 'Egypt',
    nameIt: 'Egitto',
    nameFr: 'Égypte',
    id: 'EGY',
  },
  {
    name: 'El Salvador',
    nameIt: 'El Salvador',
    nameFr: 'Salvador',
    id: 'SLV',
  },
  {
    name: 'Equatorial Guinea',
    nameIt: 'Guinea Equatoriale',
    nameFr: 'Guinée-Équatoriale',
    id: 'GNQ',
  },
  {
    name: 'Eritrea',
    nameIt: 'Eritrea',
    nameFr: 'Érythrée',
    id: 'ERI',
  },
  {
    name: 'Estonia',
    nameIt: 'Estonia',
    nameFr: 'Estonie',
    id: 'EST',
  },
  {
    name: 'Ethiopia',
    nameIt: 'Etiopia',
    nameFr: 'Éthiopie',
    id: 'ETH',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    nameIt: 'Isole Falkland o Isole Malvine',
    nameFr: 'Îles Malouines',
    id: 'FLK',
  },
  {
    name: 'Faroe Islands',
    nameIt: 'Isole Far Oer',
    nameFr: 'Îles Féroé',
    id: 'FRO',
  },
  {
    name: 'Fiji',
    nameIt: 'Figi',
    nameFr: 'Fidji',
    id: 'FJI',
  },
  {
    name: 'Finland',
    nameIt: 'Finlandia',
    nameFr: 'Finlande',
    id: 'FIN',
  },
  {
    name: 'France',
    nameIt: 'Francia',
    nameFr: 'France',
    id: 'FRA',
  },
  {
    name: 'French Guiana',
    nameIt: 'Guyana francese',
    nameFr: 'Guayane',
    id: 'GUF',
  },
  {
    name: 'French Polynesia',
    nameIt: 'Polinesia Francese',
    nameFr: 'Polynésie française',
    id: 'PYF',
  },
  {
    name: 'French Southern Territories',
    nameIt: 'Territori Francesi del Sud',
    nameFr: 'Terres australes et antarctiques françaises',
    id: 'ATF',
  },
  {
    name: 'Gabon',
    nameIt: 'Gabon',
    nameFr: 'Gabon',
    id: 'GAB',
  },
  {
    name: 'Gambia',
    nameIt: 'Gambia',
    nameFr: 'Gambie',
    id: 'GMB',
  },
  {
    name: 'Georgia',
    nameIt: 'Georgia',
    nameFr: 'Géorgie',
    id: 'GEO',
  },
  {
    name: 'Germany',
    nameIt: 'Germania',
    nameFr: 'Allemagne',
    id: 'DEU',
  },
  {
    name: 'Ghana',
    nameIt: 'Ghana',
    nameFr: 'Ghana',
    id: 'GHA',
  },
  {
    name: 'Gibraltar',
    nameIt: 'Gibilterra',
    nameFr: 'Gibraltar',
    id: 'GIB',
  },
  {
    name: 'Greece',
    nameIt: 'Grecia',
    nameFr: 'Grèce',
    id: 'GRC',
  },
  {
    name: 'Greenland',
    nameIt: 'Groenlandia',
    nameFr: 'Groenland',
    id: 'GRL',
  },
  {
    name: 'Grenada',
    nameIt: 'Grenada',
    nameFr: 'Grenade',
    id: 'GRD',
  },
  {
    name: 'Guadeloupe',
    nameIt: 'Guadeloupa',
    nameFr: 'Guadeloupe',
    id: 'GLP',
  },
  {
    name: 'Guam',
    nameIt: 'Guam',
    nameFr: 'Guam',
    id: 'GUM',
  },
  {
    name: 'Guatemala',
    nameIt: 'Guatemala',
    nameFr: 'Guatemala',
    id: 'GTM',
  },
  {
    name: 'Guernsey',
    nameIt: 'Guernsey',
    nameFr: 'Guernesey',
    id: 'GGY',
  },
  {
    name: 'Guinea',
    nameIt: 'Guinea',
    nameFr: 'Guinée',
    id: 'GIN',
  },
  {
    name: 'Guinea-Bissau',
    nameIt: 'Guinea-Bissau',
    nameFr: 'Guinée-Bissau',
    id: 'GNB',
  },
  {
    name: 'Guyana',
    nameIt: 'Guyana',
    nameFr: 'Guyane',
    id: 'GUY',
  },
  {
    name: 'Haiti',
    nameIt: 'Haiti',
    nameFr: 'Haïti',
    id: 'HTI',
  },
  {
    name: 'Heard Island and McDonald Islands',
    nameIt: 'Isole Heard e McDonald',
    nameFr: 'Îles Heard-et-MacDonald',
    id: 'HMD',
  },
  {
    name: 'Vatican',
    nameIt: 'Vaticano',
    nameFr: 'Vatican',
    id: 'VAT',
  },
  {
    name: 'Honduras',
    nameIt: 'Honduras',
    nameFr: 'Honduras',
    id: 'HND',
  },
  {
    name: 'Hong Kong',
    nameIt: 'Hong Kong',
    nameFr: 'Hong Kong',
    id: 'HKG',
  },
  {
    name: 'Hungary',
    nameIt: 'Ungheria',
    nameFr: 'Hongrie',
    id: 'HUN',
  },
  {
    name: 'Iceland',
    nameIt: 'Islanda',
    nameFr: 'Islande',
    id: 'ISL',
  },
  {
    name: 'India',
    nameIt: 'India',
    nameFr: 'Inde',
    id: 'IND',
  },
  {
    name: 'Indonesia',
    nameIt: 'Indonesia',
    nameFr: 'Indonésie',
    id: 'IDN',
  },
  {
    name: "Côte d'Ivoire",
    nameIt: "Costa D'Avorio",
    nameFr: "Côte d'Ivoire",
    id: 'CIV',
  },
  {
    name: 'Iran (Islamic Republic of)',
    nameIt: 'Iran',
    nameFr: 'Iran',
    id: 'IRN',
  },
  {
    name: 'Iraq',
    nameIt: 'Iraq',
    nameFr: 'Irak',
    id: 'IRQ',
  },
  {
    name: 'Ireland',
    nameIt: 'Irlanda',
    nameFr: 'Irlande',
    id: 'IRL',
  },
  {
    name: 'Isle of Man',
    nameIt: 'Isola di Man',
    nameFr: 'Île de Man',
    id: 'IMN',
  },
  {
    name: 'Israel',
    nameIt: 'Israele',
    nameFr: 'Israël',
    id: 'ISR',
  },
  {
    name: 'Italy',
    nameIt: 'Italia',
    nameFr: 'Italie',
    id: 'ITA',
  },
  {
    name: 'Jamaica',
    nameIt: 'Giamaica',
    nameFr: 'Jamaïque',
    id: 'JAM',
  },
  {
    name: 'Japan',
    nameIt: 'Giappone',
    nameFr: 'Japon',
    id: 'JPN',
  },
  {
    name: 'Jersey',
    nameIt: 'Isola di Jersey',
    nameFr: 'Jersey',
    id: 'JEY',
  },
  {
    name: 'Jordan',
    nameIt: 'Giordania',
    nameFr: 'Jordanie',
    id: 'JOR',
  },
  {
    name: 'Kazakhstan',
    nameIt: 'Kazakistan',
    nameFr: 'Kazakhstan',
    id: 'KAZ',
  },
  {
    name: 'Kenya',
    nameIt: 'Kenya',
    nameFr: 'Kenya',
    id: 'KEN',
  },
  {
    name: 'Kiribati',
    nameIt: 'Kiribati',
    nameFr: 'Kiribati',
    id: 'KIR',
  },
  {
    name: 'Kuwait',
    nameIt: 'Kuwait',
    nameFr: 'Koweït',
    id: 'KWT',
  },
  {
    name: 'Kyrgyzstan',
    nameIt: 'Kirghizistan',
    nameFr: 'Kirghizistan',
    id: 'KGZ',
  },
  {
    name: "Lao People's Democratic Republic",
    nameIt: 'Laos',
    nameFr: 'Laos',
    id: 'LAO',
  },
  {
    name: 'Latvia',
    nameIt: 'Lettonia',
    nameFr: 'Lettonie',
    id: 'LVA',
  },
  {
    name: 'Lebanon',
    nameIt: 'Libano',
    nameFr: 'Liban',
    id: 'LBN',
  },
  {
    name: 'Lesotho',
    nameIt: 'Lesotho',
    nameFr: 'Lesotho',
    id: 'LSO',
  },
  {
    name: 'Liberia',
    nameIt: 'Liberia',
    nameFr: 'Liberia',
    id: 'LBR',
  },
  {
    name: 'Libya',
    nameIt: 'Libia',
    nameFr: 'Libye',
    id: 'LBY',
  },
  {
    name: 'Liechtenstein',
    nameIt: 'Liechtenstein',
    nameFr: 'Liechtenstein',
    id: 'LIE',
  },
  {
    name: 'Lithuania',
    nameIt: 'Lituania',
    nameFr: 'Lituanie',
    id: 'LTU',
  },
  {
    name: 'Luxembourg',
    nameIt: 'Lussemburgo',
    nameFr: 'Luxembourg',
    id: 'LUX',
  },
  {
    name: 'Macao',
    nameIt: 'Macao',
    nameFr: 'Macao',
    id: 'MAC',
  },
  {
    name: 'Macedonia (the former Yugoslav Republic of)',
    nameIt: 'Macedonia',
    nameFr: 'Macédoine',
    id: 'MKD',
  },
  {
    name: 'Madagascar',
    nameIt: 'Madagascar',
    nameFr: 'Madagascar',
    id: 'MDG',
  },
  {
    name: 'Malawi',
    nameIt: 'Malawi',
    nameFr: 'Malawi',
    id: 'MWI',
  },
  {
    name: 'Malaysia',
    nameIt: 'Malesia',
    nameFr: 'Malaisie',
    id: 'MYS',
  },
  {
    name: 'Maldives',
    nameIt: 'Maldive',
    nameFr: 'Maldives',
    id: 'MDV',
  },
  {
    name: 'Mali',
    nameIt: 'Mali',
    nameFr: 'Mali',
    id: 'MLI',
  },
  {
    name: 'Malta',
    nameIt: 'Malta',
    nameFr: 'Malte',
    id: 'MLT',
  },
  {
    name: 'Marshall Islands',
    nameIt: 'Isole Marshall',
    nameFr: 'Îles Marshall',
    id: 'MHL',
  },
  {
    name: 'Martinique',
    nameIt: 'Martinica',
    nameFr: 'Martinique',
    id: 'MTQ',
  },
  {
    name: 'Mauritania',
    nameIt: 'Mauritania',
    nameFr: 'Mauritanie',
    id: 'MRT',
  },
  {
    name: 'Mauritius',
    nameIt: 'Mauritius',
    nameFr: 'Île Maurice',
    id: 'MUS',
  },
  {
    name: 'Mayotte',
    nameIt: 'Mayotte',
    nameFr: 'Mayotte',
    id: 'MYT',
  },
  {
    name: 'Mexico',
    nameIt: 'Messico',
    nameFr: 'Mexique',
    id: 'MEX',
  },
  {
    name: 'Micronesia (Federated States of)',
    nameIt: 'Micronesia',
    nameFr: 'Micronésie',
    id: 'FSM',
  },
  {
    name: 'Moldova (Republic of)',
    nameIt: 'Moldavia',
    nameFr: 'Moldavie',
    id: 'MDA',
  },
  {
    name: 'Monaco',
    nameIt: 'Principato di Monaco',
    nameFr: 'Monaco',
    id: 'MCO',
  },
  {
    name: 'Mongolia',
    nameIt: 'Mongolia',
    nameFr: 'Mongolie',
    id: 'MNG',
  },
  {
    name: 'Montenegro',
    nameIt: 'Montenegro',
    nameFr: 'Monténégro',
    id: 'MNE',
  },
  {
    name: 'Montserrat',
    nameIt: 'Montserrat',
    nameFr: 'Montserrat',
    id: 'MSR',
  },
  {
    name: 'Morocco',
    nameIt: 'Marocco',
    nameFr: 'Maroc',
    id: 'MAR',
  },
  {
    name: 'Mozambique',
    nameIt: 'Mozambico',
    nameFr: 'Mozambique',
    id: 'MOZ',
  },
  {
    name: 'Myanmar',
    nameIt: 'Birmania',
    nameFr: 'Myanmar',
    id: 'MMR',
  },
  {
    name: 'Namibia',
    nameIt: 'Namibia',
    nameFr: 'Namibie',
    id: 'NAM',
  },
  {
    name: 'Nauru',
    nameIt: 'Nauru',
    nameFr: 'Nauru',
    id: 'NRU',
  },
  {
    name: 'Nepal',
    nameIt: 'Nepal',
    nameFr: 'Népal',
    id: 'NPL',
  },
  {
    name: 'Netherlands',
    nameIt: 'Paesi Bassi',
    nameFr: 'Pays-Bas',
    id: 'NLD',
  },
  {
    name: 'New Caledonia',
    nameIt: 'Nuova Caledonia',
    nameFr: 'Nouvelle-Calédonie',
    id: 'NCL',
  },
  {
    name: 'New Zealand',
    nameIt: 'Nuova Zelanda',
    nameFr: 'Nouvelle-Zélande',
    id: 'NZL',
  },
  {
    name: 'Nicaragua',
    nameIt: 'Nicaragua',
    nameFr: 'Nicaragua',
    id: 'NIC',
  },
  {
    name: 'Niger',
    nameIt: 'Niger',
    nameFr: 'Niger',
    id: 'NER',
  },
  {
    name: 'Nigeria',
    nameIt: 'Nigeria',
    nameFr: 'Nigéria',
    id: 'NGA',
  },
  {
    name: 'Niue',
    nameIt: 'Niue',
    nameFr: 'Niue',
    id: 'NIU',
  },
  {
    name: 'Norfolk Island',
    nameIt: 'Isola Norfolk',
    nameFr: 'Île de Norfolk',
    id: 'NFK',
  },
  {
    name: "Korea (Democratic People's Republic of)",
    nameIt: 'Corea del Nord',
    nameFr: 'Corée du Nord',
    id: 'PRK',
  },
  {
    name: 'Northern Mariana Islands',
    nameIt: 'Isole Marianne Settentrionali',
    nameFr: 'Îles Mariannes du Nord',
    id: 'MNP',
  },
  {
    name: 'Norway',
    nameIt: 'Norvegia',
    nameFr: 'Norvège',
    id: 'NOR',
  },
  {
    name: 'Oman',
    nameIt: 'oman',
    nameFr: 'Oman',
    id: 'OMN',
  },
  {
    name: 'Pakistan',
    nameIt: 'Pakistan',
    nameFr: 'Pakistan',
    id: 'PAK',
  },
  {
    name: 'Palau',
    nameIt: 'Palau',
    nameFr: 'Palaos',
    id: 'PLW',
  },
  {
    name: 'Palestine, State of',
    nameIt: 'Palestina',
    nameFr: 'Palestine',
    id: 'PSE',
  },
  {
    name: 'Panama',
    nameIt: 'Panama',
    nameFr: 'Panama',
    id: 'PAN',
  },
  {
    name: 'Papua New Guinea',
    nameIt: 'Papua Nuova Guinea',
    nameFr: 'Papouasie-Nouvelle-Guinée',
    id: 'PNG',
  },
  {
    name: 'Paraguay',
    nameIt: 'Paraguay',
    nameFr: 'Paraguay',
    id: 'PRY',
  },
  {
    name: 'Peru',
    nameIt: 'Perù',
    nameFr: 'Pérou',
    id: 'PER',
  },
  {
    name: 'Philippines',
    nameIt: 'Filippine',
    nameFr: 'Philippines',
    id: 'PHL',
  },
  {
    name: 'Pitcairn',
    nameIt: 'Isole Pitcairn',
    nameFr: 'Îles Pitcairn',
    id: 'PCN',
  },
  {
    name: 'Poland',
    nameIt: 'Polonia',
    nameFr: 'Pologne',
    id: 'POL',
  },
  {
    name: 'Portugal',
    nameIt: 'Portogallo',
    nameFr: 'Portugal',
    id: 'PRT',
  },
  {
    name: 'Puerto Rico',
    nameIt: 'Porto Rico',
    nameFr: 'Porto Rico',
    id: 'PRI',
  },
  {
    name: 'Qatar',
    nameIt: 'Qatar',
    nameFr: 'Qatar',
    id: 'QAT',
  },
  {
    name: 'Republic of Kosovo',
    nameIt: 'Repubblica del Kosovo',
    nameFr: 'République du Kosovo',
    id: 'KOS',
  },
  {
    name: 'Réunion',
    nameIt: 'Riunione',
    nameFr: 'Réunion',
    id: 'REU',
  },
  {
    name: 'Romania',
    nameIt: 'Romania',
    nameFr: 'Roumanie',
    id: 'ROU',
  },
  {
    name: 'Russian Federation',
    nameIt: 'Russia',
    nameFr: 'Russie',
    id: 'RUS',
  },
  {
    name: 'Rwanda',
    nameIt: 'Ruanda',
    nameFr: 'Rwanda',
    id: 'RWA',
  },
  {
    name: 'Saint Barthélemy',
    nameIt: 'Antille Francesi',
    nameFr: 'Saint-Barthélemy',
    id: 'BLM',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    nameIt: "Sant'Elena",
    nameFr: 'Sainte-Hélène',
    id: 'SHN',
  },
  {
    name: 'Saint Kitts and Nevis',
    nameIt: 'Saint Kitts e Nevis',
    nameFr: 'Saint-Christophe-et-Niévès',
    id: 'KNA',
  },
  {
    name: 'Saint Lucia',
    nameIt: 'Santa Lucia',
    nameFr: 'Saint-Lucie',
    id: 'LCA',
  },
  {
    name: 'Saint Martin (French part)',
    nameIt: 'Saint Martin',
    nameFr: 'Saint-Martin',
    id: 'MAF',
  },
  {
    name: 'Saint Pierre and Miquelon',
    nameIt: 'Saint-Pierre e Miquelon',
    nameFr: 'Saint-Pierre-et-Miquelon',
    id: 'SPM',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    nameIt: 'Saint Vincent e Grenadine',
    nameFr: 'Saint-Vincent-et-les-Grenadines',
    id: 'VCT',
  },
  {
    name: 'Samoa',
    nameIt: 'Samoa',
    nameFr: 'Samoa',
    id: 'WSM',
  },
  {
    name: 'San Marino',
    nameIt: 'San Marino',
    nameFr: 'Saint-Marin',
    id: 'SMR',
  },
  {
    name: 'Sao Tome and Principe',
    nameIt: 'São Tomé e Príncipe',
    nameFr: 'Sao Tomé-et-Principe',
    id: 'STP',
  },
  {
    name: 'Saudi Arabia',
    nameIt: 'Arabia Saudita',
    nameFr: 'Arabie Saoudite',
    id: 'SAU',
  },
  {
    name: 'Senegal',
    nameIt: 'Senegal',
    nameFr: 'Sénégal',
    id: 'SEN',
  },
  {
    name: 'Serbia',
    nameIt: 'Serbia',
    nameFr: 'Serbie',
    id: 'SRB',
  },
  {
    name: 'Seychelles',
    nameIt: 'Seychelles',
    nameFr: 'Seychelles',
    id: 'SYC',
  },
  {
    name: 'Sierra Leone',
    nameIt: 'Sierra Leone',
    nameFr: 'Sierra Leone',
    id: 'SLE',
  },
  {
    name: 'Singapore',
    nameIt: 'Singapore',
    nameFr: 'Singapour',
    id: 'SGP',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    nameIt: 'Saint Martin (parte olandese)',
    nameFr: 'Saint Martin (partie néerlandaise)',
    id: 'SXM',
  },
  {
    name: 'Slovakia',
    nameIt: 'Slovacchia',
    nameFr: 'Slovaquie',
    id: 'SVK',
  },
  {
    name: 'Slovenia',
    nameIt: 'Slovenia',
    nameFr: 'Slovénie',
    id: 'SVN',
  },
  {
    name: 'Solomon Islands',
    nameIt: 'Isole Salomone',
    nameFr: 'Îles Salomon',
    id: 'SLB',
  },
  {
    name: 'Somalia',
    nameIt: 'Somalia',
    nameFr: 'Somalie',
    id: 'SOM',
  },
  {
    name: 'South Africa',
    nameIt: 'Sud Africa',
    nameFr: 'Afrique du Sud',
    id: 'ZAF',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    nameIt: 'Georgia del Sud e Isole Sandwich Meridionali',
    nameFr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    id: 'SGS',
  },
  {
    name: 'Korea (Republic of)',
    nameIt: 'Corea del Sud',
    nameFr: 'Corée du Sud',
    id: 'KOR',
  },
  {
    name: 'South Sudan',
    nameIt: 'Sudan del sud',
    nameFr: 'Soudan du Sud',
    id: 'SSD',
  },
  {
    name: 'Spain',
    nameIt: 'Spagna',
    nameFr: 'Espagne',
    id: 'ESP',
  },
  {
    name: 'Sri Lanka',
    nameIt: 'Sri Lanka',
    nameFr: 'Sri Lanka',
    id: 'LKA',
  },
  {
    name: 'Sudan',
    nameIt: 'Sudan',
    nameFr: 'Soudan',
    id: 'SDN',
  },
  {
    name: 'Suriname',
    nameIt: 'Suriname',
    nameFr: 'Surinam',
    id: 'SUR',
  },
  {
    name: 'Svalbard and Jan Mayen',
    nameIt: 'Svalbard e Jan Mayen',
    nameFr: 'Svalbard et Jan Mayen',
    id: 'SJM',
  },
  {
    name: 'Swaziland',
    nameIt: 'Swaziland',
    nameFr: 'Swaziland',
    id: 'SWZ',
  },
  {
    name: 'Sweden',
    nameIt: 'Svezia',
    nameFr: 'Suède',
    id: 'SWE',
  },
  {
    name: 'Switzerland',
    nameIt: 'Svizzera',
    nameFr: 'Suisse',
    id: 'CHE',
  },
  {
    name: 'Syrian Arab Republic',
    nameIt: 'Siria',
    nameFr: 'Syrie',
    id: 'SYR',
  },
  {
    name: 'Taiwan',
    nameIt: 'Taiwan',
    nameFr: 'Taïwan',
    id: 'TWN',
  },
  {
    name: 'Tajikistan',
    nameIt: 'Tagikistan',
    nameFr: 'Tadjikistan',
    id: 'TJK',
  },
  {
    name: 'Tanzania, United Republic of',
    nameIt: 'Tanzania',
    nameFr: 'Tanzanie',
    id: 'TZA',
  },
  {
    name: 'Thailand',
    nameIt: 'Tailandia',
    nameFr: 'Thaïlande',
    id: 'THA',
  },
  {
    name: 'Timor-Leste',
    nameIt: 'Timor Est',
    nameFr: 'Timor oriental',
    id: 'TLS',
  },
  {
    name: 'Togo',
    nameIt: 'Togo',
    nameFr: 'Togo',
    id: 'TGO',
  },
  {
    name: 'Tokelau',
    nameIt: 'Isole Tokelau',
    nameFr: 'Tokelau',
    id: 'TKL',
  },
  {
    name: 'Tonga',
    nameIt: 'Tonga',
    nameFr: 'Tonga',
    id: 'TON',
  },
  {
    name: 'Trinidad and Tobago',
    nameIt: 'Trinidad e Tobago',
    nameFr: 'Trinité et Tobago',
    id: 'TTO',
  },
  {
    name: 'Tunisia',
    nameIt: 'Tunisia',
    nameFr: 'Tunisie',
    id: 'TUN',
  },
  {
    name: 'Turkey',
    nameIt: 'Turchia',
    nameFr: 'Turquie',
    id: 'TUR',
  },
  {
    name: 'Turkmenistan',
    nameIt: 'Turkmenistan',
    nameFr: 'Turkménistan',
    id: 'TKM',
  },
  {
    name: 'Turks and Caicos Islands',
    nameIt: 'Isole Turks e Caicos',
    nameFr: 'Îles Turques-et-Caïques',
    id: 'TCA',
  },
  {
    name: 'Tuvalu',
    nameIt: 'Tuvalu',
    nameFr: 'Tuvalu',
    id: 'TUV',
  },
  {
    name: 'Uganda',
    nameIt: 'Uganda',
    nameFr: 'Uganda',
    id: 'UGA',
  },
  {
    name: 'Ukraine',
    nameIt: 'Ucraina',
    nameFr: 'Ukraine',
    id: 'UKR',
  },
  {
    name: 'United Arab Emirates',
    nameIt: 'Emirati Arabi Uniti',
    nameFr: 'Émirats arabes unis',
    id: 'ARE',
  },
  {
    name: 'United Kingdom of Great Britain and Northern Ireland',
    nameIt: 'Regno Unito',
    nameFr: 'Royaume-Uni',
    id: 'GBR',
  },
  {
    name: 'United States of America',
    nameIt: "Stati Uniti D'America",
    nameFr: 'États-Unis',
    id: 'USA',
  },
  {
    name: 'Uruguay',
    nameIt: 'Uruguay',
    nameFr: 'Uruguay',
    id: 'URY',
  },
  {
    name: 'Uzbekistan',
    nameIt: 'Uzbekistan',
    nameFr: 'Ouzbékistan',
    id: 'UZB',
  },
  {
    name: 'Vanuatu',
    nameIt: 'Vanuatu',
    nameFr: 'Vanuatu',
    id: 'VUT',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    nameIt: 'Venezuela',
    nameFr: 'Venezuela',
    id: 'VEN',
  },
  {
    name: 'Viet Nam',
    nameIt: 'Vietnam',
    nameFr: 'Viêt Nam',
    id: 'VNM',
  },
  {
    name: 'Wallis and Futuna',
    nameIt: 'Wallis e Futuna',
    nameFr: 'Wallis-et-Futuna',
    id: 'WLF',
  },
  {
    name: 'Western Sahara',
    nameIt: 'Sahara Occidentale',
    nameFr: 'Sahara Occidental',
    id: 'ESH',
  },
  {
    name: 'Yemen',
    nameIt: 'Yemen',
    nameFr: 'Yémen',
    id: 'YEM',
  },
  {
    name: 'Zambia',
    nameIt: 'Zambia',
    nameFr: 'Zambie',
    id: 'ZMB',
  },
  {
    name: 'Zimbabwe',
    nameIt: 'Zimbabwe',
    nameFr: 'Zimbabwe',
    id: 'ZWE',
  },
];
