import React from 'react';
import { string, number, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './CardColor.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  status: number.isRequired,
  children: node,
  shadow: bool,
};

const defaultProps = {
  className: null,
  children: null,
  shadow: false,
};

const CardColor = ({ status, className, shadow, children }) => {
  return (
    <div
      className={cx(
        { 'card-membre': !shadow && status === 10 },
        { 'card-silver': !shadow && status === 20 },
        { 'card-gold': !shadow && status === 30 },
        { 'card-platinum': !shadow && status === 40 },
        { 'card-vip': !shadow && status === 50 },
        { 'card-shadow-membre': shadow && status === 10 },
        { 'card-shadow-silver': shadow && status === 20 },
        { 'card-shadow-gold': shadow && status === 30 },
        { 'card-shadow-platinum': shadow && status === 40 },
        { 'card-shadow-vip': shadow && status === 50 },
        className
      )}
    >
      {children}
    </div>
  );
};

CardColor.propTypes = propTypes;
CardColor.defaultProps = defaultProps;

export default CardColor;
