import React, { forwardRef } from 'react';
import classNames from 'classnames/bind';
import { node, string, func, shape } from 'prop-types';
import { Link } from 'react-router-dom';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconEdit } from 'common/media/icons/edit.icon.svg';

import BlockTitle from '~/components/BlockTitle';
import PanelContainer from '~/components/PanelContainer';
import NavLink from '~/components/NavLink';

import styles from './Panel.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node,
  className: string,
  headerClassName: string,
  header: node,
  title: string,
  url: string,
  urlLabel: string,
  navLinkData: shape({
    url: string.isRequired,
    onClick: func.isRequired,
    className: string,
    label: string,
  }),
};

const defaultProps = {
  children: null,
  className: '',
  headerClassName: '',
  header: null,
  title: '',
  url: '',
  urlLabel: '',
  navLinkData: null,
};

const Panel = forwardRef(
  (
    {
      children,
      className,
      headerClassName,
      title,
      header,
      url,
      urlLabel,
      navLinkData,
    },
    ref
  ) => (
    <div className={cx('root', className)} ref={ref}>
      <PanelContainer>
        {(title || header || url) && (
          <div className={cx('header')}>
            {!!navLinkData && <NavLink {...navLinkData} />}
            {(title || header) && (
              <div className={cx('header-content', headerClassName)}>
                {title && <BlockTitle title={title} />}
                {header}
              </div>
            )}
            {url && (
              <Link to={url} className={cx('link')}>
                <IconContainer icon={IconEdit} className={cx('icon')} />
                {urlLabel}
              </Link>
            )}
          </div>
        )}
        <div className={cx('content')}>{children}</div>
      </PanelContainer>
    </div>
  )
);

Panel.propTypes = propTypes;
Panel.defaultProps = defaultProps;

export default Panel;
