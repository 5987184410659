import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import SortableItem from '../SortableItem';
import ContactChannelCheckbox from '../ContactChannelCheckbox';

const SortableList = SortableContainer(({ contactChannelList, isMyCs }) => {
  return (
    <ol>
      {contactChannelList.map(
        ({ name, label, active, message, messageEnd }, index) => {
          return active ? (
            <SortableItem
              key={`item-${name}`}
              index={index}
              value={ContactChannelCheckbox({
                name,
                label,
                active,
                message,
                messageEnd,
              })}
            />
          ) : (
            <li key={`item-${name}`}>
              {ContactChannelCheckbox({
                name,
                label,
                active,
                message,
                messageEnd,
                isMyCs,
              })}
            </li>
          );
        }
      )}
    </ol>
  );
});

export default SortableList;
