import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconEmail } from 'common/media/icons/email-outline.icon.svg';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './WellnessFormConfirmation.module.scss';

const cx = classNames.bind(styles);

const WellnessFormConfirmationFieldsFragment = gql`
  fragment WellnessFormConfirmationFields on WellnessInfo {
    confirmationMessage
    confirmationLegal
  }
`;

const propTypes = {
  data: propType(WellnessFormConfirmationFieldsFragment).isRequired,
};

const fragment = {
  WellnessFormConfirmationFieldsFragment,
};

const WellnessFormConfirmation = ({
  data: { confirmationMessage, confirmationLegal },
}) => {
  return (
    <div className={cx('root')}>
      <div className={cx('content')}>
        <div className={cx('image')}>
          <div className={cx('circle')}>
            <IconContainer className={cx('email-icon')} icon={IconEmail} />
          </div>
        </div>
        {!!confirmationMessage && (
          <div className={cx('confirmation-message')}>
            {confirmationMessage}
          </div>
        )}
      </div>
      {!!confirmationLegal && (
        <div className={cx('description')}>{confirmationLegal}</div>
      )}
    </div>
  );
};

WellnessFormConfirmation.propTypes = propTypes;
WellnessFormConfirmation.fragment = fragment;

export default withErrorBoundary(WellnessFormConfirmation);
