import React from 'react';
import { string, object, func, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Picture from 'common/components/Picture';
import { useToggle } from 'common/hooks';

import AddMymcNumberForm from '~/scenes/AddMymcNumberForm';
import Button from '~/components/Button';
import LoyaltyProgramBannerDesktop from '~/media/images/LoyaltyProgramBannerDesktop.webp';
import LoyaltyProgramBannerDesktop2x from '~/media/images/LoyaltyProgramBannerDesktop2x.webp';
import LoyaltyProgramBannerMob from '~/media/images/LoyaltyProgramBannerDesktopеMob.webp';
import LoyaltyProgramBannerMob2x from '~/media/images/LoyaltyProgramBannerDesktopеMob2x.webp';
import CardsGroup from '~/media/images/CardsGroup.webp';

import styles from './LoyaltyMembershipBlock.module.scss';

const { REACT_APP_MYACC_DOMAIN } = process.env;

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  joining: object.isRequired,
  setMymcNumberVerificationData: func.isRequired,
  setIsLoyaltyMembership: func.isRequired,
  isMyCS: bool,
};

const defaultProps = {
  className: null,
  isMyCS: false,
};

const LoyaltyMembershipBlock = ({
  className,
  joining,
  setMymcNumberVerificationData,
  setIsLoyaltyMembership,
  isMyCS,
}) => {
  const [isMymcNumberFormVisible, toggleMymcNumberForm] = useToggle(false);

  const { t } = useTranslation('loyalty-program');
  const pageMembershipBlockLabels = t('pageMembershipBlock', {
    returnObjects: true,
  });

  const { linking, enrollment } = joining;

  return (
    <div className={cx('root', className)}>
      <div className={cx('background-image-container')}>
        <Picture
          sources={[
            {
              srcSet: `${LoyaltyProgramBannerMob} 1x,  ${LoyaltyProgramBannerMob2x} 2x`,
              media: '(max-width: 530px)',
              type: 'image/webp',
            },
            {
              srcSet: `${LoyaltyProgramBannerDesktop} 1x, ${LoyaltyProgramBannerDesktop2x} 2x`,
              type: 'image/webp',
            },
          ]}
          src={LoyaltyProgramBannerDesktop}
          alt="LoyaltyMembershipBlock"
        />
      </div>
      <div className={cx('inner-wrapper')}>
        <div className={cx('inner')}>
          <div className={cx('card-img')}>
            <Picture
              sources={[
                {
                  srcSet: CardsGroup,
                  type: 'image/webp',
                },
              ]}
              src={CardsGroup}
              alt="Cards"
            />
          </div>
          <div className={cx('text-wrapper')}>
            <div
              dangerouslySetInnerHTML={{
                __html: pageMembershipBlockLabels.blockGreeting,
              }}
              className={cx('block-greeting')}
            />
            <div className={cx('buttons-wrap')}>
              {enrollment && (
                <div className={cx('buttons-item')}>
                  <Button
                    to={`${REACT_APP_MYACC_DOMAIN}/join-loyalty-program`}
                    className={cx('action-button')}
                    disabled={isMyCS}
                  >
                    {pageMembershipBlockLabels.getMembershipCardBtnLabel}
                  </Button>
                </div>
              )}
              {linking && (
                <div className={cx('buttons-item')}>
                  <button
                    type={'button'}
                    onClick={toggleMymcNumberForm}
                    className={cx('action-button', 'action-button-text')}
                  >
                    {pageMembershipBlockLabels.addCardButtonLabel}
                  </button>
                </div>
              )}
            </div>
            {linking && (
              <AddMymcNumberForm
                isMymcNumberFormVisible={isMymcNumberFormVisible}
                toggleMymcNumberForm={toggleMymcNumberForm}
                setMymcNumberVerificationData={setMymcNumberVerificationData}
                setIsLoyaltyMembership={setIsLoyaltyMembership}
                isMyCS={isMyCS}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

LoyaltyMembershipBlock.propTypes = propTypes;
LoyaltyMembershipBlock.defaultProps = defaultProps;

export default LoyaltyMembershipBlock;
