import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Container from 'common/components/Container';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import FormContacts from '~/scenes/FormContacts';
import ErrorFallback from '~/components/ErrorFallback';
import BlockTitle from '~/components/BlockTitle';
import ButtonBack from '~/components/ButtonBack';
import Panel from '~/components/Panel';
import GTM from '~/components/GTM';

import styles from './EditContactsInfo.module.scss';

const cx = classNames.bind(styles);

const EditContactsInfoDataQuery = gql`
  query EditContactsInfoData($id: String!) {
    getCustomerProfile(id: $id) {
      sbmContacts(id: $id) {
        address {
          address1
          address2
          category
          city
          country
          id
          isDeletable
          isMutable
          owner
          state
          validStatus
          zipCode
        }
        email {
          category
          email
          id
          isDeletable
          isMutable
          isValid
          owner
        }
        phone {
          category
          id
          isDeletable
          isMutable
          isValid
          owner
          phone
        }
      }
    }
  }
`;

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

function EditContactsInfo({
  match: {
    params: { id },
  },
}) {
  const { t } = useTranslation('edit-account');
  const editContactsInfoPageTitle = t('editContactsInfoPageTitle');

  const { language } = useLocalisationContext();

  const { data, loading, error } = useQuery(EditContactsInfoDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const backLink = useMemo(() => `/${language}/user/${id}`, [id, language]);

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" className={cx('root')} width={900}>
      <Helmet>
        <title>{editContactsInfoPageTitle}</title>
      </Helmet>

      {loading && !data && <PageLoader />}
      {!loading && data && !error && (
        <Panel>
          <ButtonBack to={backLink} />
          <BlockTitle
            title={editContactsInfoPageTitle}
            className={cx('title')}
          />
          {data.getCustomerProfile.sbmContacts ? (
            <FormContacts
              isMyCs
              userHash={id}
              email={data.getCustomerProfile.sbmContacts.email}
              phone={data.getCustomerProfile.sbmContacts.phone}
              address={data.getCustomerProfile.sbmContacts.address}
              redirectTo={backLink}
            />
          ) : (
            <ErrorFallback mode={'friendly'} />
          )}
        </Panel>
      )}

      <GTM isMyCS />
    </Container>
  );
}

EditContactsInfo.propTypes = propTypes;

export default EditContactsInfo;
