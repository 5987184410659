import { gql } from '@apollo/client';

export const ForgotPasswordMutation = gql`
  mutation forgotPassword($email: String!, $link: Boolean, $captcha: String) {
    forgotPassword(email: $email, link: $link, captcha: $captcha) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;
