import { useEffect } from 'react';
import { bool } from 'prop-types';

useWarnOnBeforeUnload.propTypes = {
  isDisabled: bool,
};

useWarnOnBeforeUnload.defaultProps = {
  isDisabled: false,
};

export default function useWarnOnBeforeUnload(isDisabled) {
  useEffect(() => {
    const handler = (event) => {
      var confirmationMessage =
        'Reload site? Changes you made may not be saved.';

      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener('beforeunload', handler);

    if (isDisabled) {
      window.removeEventListener('beforeunload', handler);
    }

    return () =>
      !isDisabled && window.removeEventListener('beforeunload', handler);
  }, [isDisabled]);
}
