import React from 'react';
import { bool, func } from 'prop-types';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';

import { MD_PRODUCT } from 'common/constants';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import PushLeftCol from '~/components/PushComponents/PushLeftCol';
import PushLayoutContainer, {
  PushLayoutContainerLeftCol,
  PushLayoutContainerRightCol,
} from '~/components/PushComponents/PushLayoutContainer';
import PushRightColButton from '~/components/PushComponents/PushRightColButton';
import PushRightColFooter from '~/components/PushComponents/PushRightColFooter';
import PushRightColCenter, {
  PushRightColCenterTextPart,
} from '~/components/PushComponents/PushRightColCenter';
import PushRightColTitle from '~/components/PushComponents/PushRightColTitle';
import PushRightColParentTitleAttributes from '~/components/PushComponents/PushRightColParentTitleAttributes';
import WishlistButton from '~/components/WishlistButton';

import styles from './RoomCard.module.scss';

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const hotelRoomCardFieldsFragment = gql`
  fragment hotelRoomCardFieldsFragment on RoomCategory {
    id
    title
    enTitle: title(language: en)
    url
    ctaDiscoverLabel
    bookingBestOffer {
      bookNowLabel
      url
      price
      priceLabel
      priceEmptyLabel
      currency
    }
    introText(length: 256)
    mediasSlider {
      ...myaccCardMediaFields
      ...myaccCardVideoFields
    }
    hotel {
      id
      minDate
      title
      stars {
        value
      }
    }
    roomAttributes {
      isFacet
      name
    }
    level {
      id
      name
    }
    ctaLabel
  }

  ${PushLeftCol.fragment.myaccCardMediaFieldsFragment}
`;

const fragment = {
  hotelRoomCardFieldsFragment,
};

const propTypes = {
  data: propType(hotelRoomCardFieldsFragment).isRequired,
  isLeft: bool,
  longStyle: bool,
  sendStatClicksListingPushItemOnClick: func,
  sendStatisticPushItemOnClick: func,
  isUnauthorised: bool,
  onUnauthorisedCardClick: func,
};

const defaultProps = {
  isLeft: false,
  longStyle: false,
  sendStatClicksListingPushItemOnClick: null,
  sendStatisticPushItemOnClick: null,
  isUnauthorised: false,
  onUnauthorisedCardClick: null,
};

const RoomCard = ({
  data: {
    id,
    url,
    title,
    mediasSlider,
    introText,
    ctaDiscoverLabel,
    hotel: {
      title: hotelTitle,
      stars: { value: starsValue },
    },
    roomAttributes,
  },
  isLeft,
  longStyle,
  isUnauthorised,
  onUnauthorisedCardClick,
}) => {
  return (
    <MyAccLink
      className={cx('root', Button.shared.hovered)}
      to={
        isUnauthorised
          ? `${REACT_APP_MYACC_DOMAIN}`
          : `${REACT_APP_SBM_DOMAIN}${url}`
      }
      onClick={(e) => {
        if (onUnauthorisedCardClick) {
          onUnauthorisedCardClick(e);
        }
      }}
    >
      <WishlistButton id={id} />

      <PushLayoutContainer isLeft={isLeft} longStyle={longStyle} itemScope>
        <PushLayoutContainerLeftCol>
          <PushLeftCol
            isSlider
            longStyle={longStyle}
            data={mediasSlider}
            title={title}
          />
        </PushLayoutContainerLeftCol>

        <PushLayoutContainerRightCol>
          <PushRightColCenter longStyle={longStyle}>
            <PushRightColTitle>
              <span itemProp={MD_PRODUCT.name}>{title}</span>
            </PushRightColTitle>

            {introText && (
              <PushRightColCenterTextPart
                className={cx('description-room-push')}
              >
                <span dangerouslySetInnerHTML={{ __html: introText }} />
              </PushRightColCenterTextPart>
            )}

            <PushRightColParentTitleAttributes
              parentTitle={hotelTitle}
              starsValue={starsValue}
              attributesList={roomAttributes.filter(({ isFacet }) => isFacet)}
            />
          </PushRightColCenter>

          <PushRightColFooter isLeft={isLeft} longStyle={longStyle}>
            <PushRightColCenter>
              <PushRightColButton
                theme={'rounded'}
                size={'m'}
                color={'tallow-invert'}
                label={ctaDiscoverLabel}
              />
            </PushRightColCenter>
          </PushRightColFooter>
        </PushLayoutContainerRightCol>
      </PushLayoutContainer>
    </MyAccLink>
  );
};

RoomCard.propTypes = propTypes;
RoomCard.defaultProps = defaultProps;
RoomCard.fragment = fragment;

export default withErrorBoundary(RoomCard);
