export const IMAGE_CONSTS = {
  itemProp: 'image',
};

export const GENDER_MAP = {
  male: 'mr',
  female: 'ms',
};

export const SELECT_LAYOUT = {
  default: 'default',
  styled: 'styled',
};
