import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import PanelField from '~/components/PanelField';
import useUserNationalities from '~/hooks/useUserNationalities';
import { useUserContext } from '~/context/UserContext';

import styles from './IdentityPanel.module.scss';

const cx = classNames.bind(styles);

const IdentityPanel = () => {
  const { t } = useTranslation('account-information');
  const identityBlock = t('identityBlock', {
    returnObjects: true,
  });

  const {
    userSbmIdentity: {
      firstName,
      marriedName,
      birthName,
      birthDate,
      nationalities,
    },
  } = useUserContext();

  const userNationalities = useUserNationalities(nationalities);

  return (
    <div className={cx('root')}>
      <PanelField
        label={identityBlock.nameFieldLabel}
        value={`${firstName} ${marriedName || birthName}`}
      />
      <div className={cx('multi-fields')}>
        {birthDate && (
          <PanelField
            label={identityBlock.birthDateFieldLabel}
            value={moment(birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          />
        )}
        {!!userNationalities.length && (
          <PanelField
            label={identityBlock.nationalityFieldLabel}
            value={userNationalities.join(', ')}
          />
        )}
      </div>
    </div>
  );
};

export default IdentityPanel;
