import React, { useRef, useEffect } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './ContentLoader.module.scss';

const cx = classNames.bind(styles);

const MG_COLOR_1 = [parseInt('EB', 16), parseInt('EB', 16), parseInt('EB', 16)];
const MG_COLOR_2 = [parseInt('00', 16), parseInt('00', 16), parseInt('00', 16)];
const L_COLOR_1 = [parseInt('EB', 16), parseInt('EB', 16), parseInt('EB', 16)];
const L_COLOR_2 = [parseInt('B0', 16), parseInt('A0', 16), parseInt('6C', 16)];
const FPS_INTERVAL = 1000 / 30; // 30 frames per second;
const TICKS = 80; // count of steps;
const TICK = 1; // step length;

const propTypes = {
  className: string,
};

const defaultProps = {
  className: null,
};

const ContentLoader = ({ className }) => {
  if (typeof window === 'undefined') return <>Loading</>;

  const line1 = useRef();
  const line2 = useRef();
  const line3 = useRef();
  const line4 = useRef();
  const monogramme = useRef();

  let then;
  let animationId = 0;
  let animationStep = 0;

  const fillElement = (
    el,
    startR,
    startG,
    startB,
    finalR,
    finalG,
    finalB,
    step,
    multiplier
  ) => {
    const calcCurrentColor = (startColor, finalColor) => {
      return Math.floor(
        startColor - (multiplier * step * (startColor - finalColor)) / TICKS
      );
    };
    const colorR = calcCurrentColor(startR, finalR);
    const colorG = calcCurrentColor(startG, finalG);
    const colorB = calcCurrentColor(startB, finalB);
    el.style.fill = 'rgb(' + colorR + ',' + colorG + ',' + colorB + ')';
  };

  const animate = () => {
    animationId = window.requestAnimationFrame(animate);

    if (
      !line1.current ||
      !line2.current ||
      !line3.current ||
      !line4.current ||
      !monogramme.current
    )
      return;

    // Stabilize fps
    const now = window.performance.now();
    const elapsed = now - then;

    if (elapsed > FPS_INTERVAL) {
      then = now - (elapsed % FPS_INTERVAL);
      animationStep += TICK;

      if (animationStep >= TICKS) {
        animationStep = 0;

        line1.current.style.opacity = 0;
        line2.current.style.opacity = 0;
        line3.current.style.opacity = 0;
        line4.current.style.opacity = 0;
      }

      // Coloring monogramme
      if (animationStep >= 0 && animationStep < TICKS - TICKS / 5) {
        fillElement(
          monogramme.current,
          ...MG_COLOR_1,
          ...MG_COLOR_2,
          animationStep,
          1
        );
      }

      // Coloring line 1
      if (animationStep >= 0 && animationStep < TICKS / 5) {
        line1.current.style.opacity = (5 * animationStep) / TICKS;
        fillElement(
          line1.current,
          ...L_COLOR_1,
          ...L_COLOR_2,
          animationStep,
          5
        );
      }

      // Coloring line 2
      if (animationStep >= TICKS / 5 && animationStep < (2 * TICKS) / 5) {
        line2.current.style.opacity = (5 * (animationStep - TICKS / 5)) / TICKS;
        fillElement(
          line2.current,
          ...L_COLOR_1,
          ...L_COLOR_2,
          animationStep - TICKS / 5,
          5
        );
      }

      // Coloring line 3
      if (animationStep >= (2 * TICKS) / 5 && animationStep < (3 * TICKS) / 5) {
        line3.current.style.opacity =
          (5 * (animationStep - (2 * TICKS) / 5)) / TICKS;
        fillElement(
          line3.current,
          ...L_COLOR_1,
          ...L_COLOR_2,
          animationStep - (2 * TICKS) / 5,
          5
        );
      }

      // Coloring line 4
      if (animationStep >= (3 * TICKS) / 5 && animationStep < (4 * TICKS) / 5) {
        line4.current.style.opacity =
          (5 * (animationStep - (3 * TICKS) / 5)) / TICKS;
        fillElement(
          line4.current,
          ...L_COLOR_1,
          ...L_COLOR_2,
          animationStep - (3 * TICKS) / 5,
          5
        );
      }

      // Backward coloring
      if (animationStep >= (4 * TICKS) / 5 && animationStep < TICKS) {
        fillElement(
          monogramme.current,
          ...MG_COLOR_2,
          ...MG_COLOR_1,
          animationStep - TICKS / 5,
          1
        );
        fillElement(
          line1.current,
          ...L_COLOR_2,
          ...L_COLOR_1,
          animationStep - TICKS / 5,
          1
        );
        fillElement(
          line2.current,
          ...L_COLOR_2,
          ...L_COLOR_1,
          animationStep - TICKS / 5,
          1
        );
        fillElement(
          line3.current,
          ...L_COLOR_2,
          ...L_COLOR_1,
          animationStep - TICKS / 5,
          1
        );
        fillElement(
          line4.current,
          ...L_COLOR_2,
          ...L_COLOR_1,
          animationStep - TICKS / 5,
          1
        );
      }
    }
  };

  useEffect(() => {
    then = window.performance.now();
    animationId = window.requestAnimationFrame(animate);

    // returned function will be called on component unmount
    return () => {
      window.cancelAnimationFrame(animationId);
    };
  }, []);

  return (
    <div className={cx('root', className)}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1024 1024"
      >
        <path
          ref={monogramme}
          d="M512,1008L16,511.9L512,16l496,495.9L512,1008z M82.1,511.9l429.9,430l429.9-430L512,82.1L82.1,511.9z M689.7,443.5
    c-37.9,0-63.4,29.3-63.4,68.3c0,39.1,25.5,69,63.4,69c27.1,0,42.2-12.9,53.9-30.8l33.6,23.1c-18.2,28.3-47.1,46.2-87.4,46.2
    c-60,0-107.8-45.6-107.8-107.4c0-61.9,47.7-107.1,107.8-107.1c37.9,0,65,13.6,83.8,42.8l-32.9,23.4
    C730.6,454.9,713.7,443.5,689.7,443.5L689.7,443.5z M484.5,512l36.5-36.5l36.5,36.5L521,548.5L484.5,512z M417.1,538
    c0-14.2,1.6-36.6,1.6-36.6s-12.6,18.5-22.5,29.6l-41.6,46.5h-4l-41.6-46.5c-9.9-11.1-22.5-29.6-22.5-29.6s1.5,22.5,1.5,36.6v78.5
    h-41.3V405h3.4l103.1,113.3L455,405h3.7v211.5h-41.6L417.1,538z"
        />
        <path ref={line1} d="M512,16l496,495.9l-33,33l-495.9-496L512,16z" />
        <path
          ref={line2}
          d="M479.1,975.1l462.8-463.2l33.1,33L512,1008L479.1,975.1z"
        />
        <path
          ref={line3}
          d="M49.1,478.9l462.9,463l-33,33l-463-463L49.1,478.9z"
        />
        <path
          ref={line4}
          d="M49.1,478.9L479.1,48.9L512,82.1L82.1,511.9L49.1,478.9z"
        />
      </svg>
    </div>
  );
};

ContentLoader.propTypes = propTypes;
ContentLoader.defaultProps = defaultProps;

export default ContentLoader;
