import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './BlockTitle.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  title: string.isRequired,
  className: string,
  tag: string,
};

const defaultProps = {
  className: null,
  tag: 'h3',
};

const BlockTitle = ({ tag: Tag, title, className }) => (
  <Tag className={cx('root', className)}>{title}</Tag>
);

BlockTitle.propTypes = propTypes;
BlockTitle.defaultProps = defaultProps;

export default BlockTitle;
