import React from 'react';
import { string, func } from 'prop-types';

import ContentLoader from 'common/components/ContentLoader';

import FullscreenContainer from '~/components/FullscreenContainer';

const propTypes = {
  tag: string,
  loader: func,
};

const defaultProps = {
  tag: 'main',
  loader: ContentLoader,
};

function PageLoader({ tag, loader: Loader }) {
  return (
    <FullscreenContainer tag={tag}>
      <Loader />
    </FullscreenContainer>
  );
}

PageLoader.propTypes = propTypes;
PageLoader.defaultProps = defaultProps;

export default PageLoader;
