import { useMemo, useState } from 'react';
import flowRight from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

import getLastUrlSegment from 'common/utils/getLastUrlSegment';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useAuthContext } from '~/context/AuthContext';
import { latinLettersWithNumbersAwsRegexp } from '~/constants';

const propTypes = {
  children: func.isRequired,
  setErrorMsg: func.isRequired,
};

const ChangePasswordFormHandler = ({
  children,
  setErrorMsg,
  match: { path },
}) => {
  const [isPasswordChanged, setPasswordStatus] = useState(false);
  const { changePassword } = useAuthContext();

  //Translations (i18n)
  const { t } = useTranslation(getLastUrlSegment(path));
  const validationRequiredFieldMessage = t('validationRequiredFieldMessage');
  const validationPassCharsCountMessage = t('validationPassCharsCountMessage');
  const validationPassUppercaseMessage = t('validationPassUppercaseMessage');
  const validationPassLowercaseMessage = t('validationPassLowercaseMessage');
  const validationPassSpecialMessage = t('validationPassSpecialMessage');
  const validationPassMatchMessage = t('validationPassMatchMessage');
  const validationLatinLettersOnlyMessage = t(
    'validationLatinLettersOnlyMessage'
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        old_password: Yup.string().required(validationRequiredFieldMessage),
        password: Yup.string()
          .required(validationRequiredFieldMessage)
          .min(8, validationPassCharsCountMessage)
          .matches(/[A-ZЀ-ЯҐ\u4E00-\u9FFF]/, validationPassUppercaseMessage)
          .matches(/[a-zа-ўґ\u4E00-\u9FFF]/, validationPassLowercaseMessage)
          .matches(
            /[^A-ZЀ-ЯҐa-zа-ўґ\u4E00-\u9FFF0-9]/,
            validationPassSpecialMessage
          )
          .matches(
            latinLettersWithNumbersAwsRegexp,
            validationLatinLettersOnlyMessage
          )
          .test('matching', validationPassMatchMessage, function(value) {
            if (!this.parent.old_password) {
              return true;
            }
            return this.parent.old_password !== value;
          }),
      }),
    [
      validationRequiredFieldMessage,
      validationPassCharsCountMessage,
      validationPassUppercaseMessage,
      validationPassLowercaseMessage,
      validationPassSpecialMessage,
      validationLatinLettersOnlyMessage,
      validationPassMatchMessage,
    ]
  );

  const onFormChange = () => {
    setPasswordStatus(false);
    setErrorMsg('');
  };

  const handleSubmit = ({ old_password, password }, { setSubmitting }) => {
    changePassword({
      old_password,
      password,
    }).then((status) => {
      if (status === 'SUCCESS') {
        setPasswordStatus(true);
      }
      setSubmitting(false);
    });
  };

  const initialValues = {
    old_password: '',
    password: '',
  };

  return children({
    handleSubmit,
    onFormChange,
    initialValues,
    validationSchema,
    isPasswordChanged,
  });
};

ChangePasswordFormHandler.propTypes = propTypes;

export default flowRight(
  withErrorBoundary,
  withRouter
)(ChangePasswordFormHandler);
