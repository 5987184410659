import React from 'react';
import { string, node, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Container.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  children: node,
  width: oneOf([1580, 1440, 1280, 1140, 1024, 900, 760]),
  tag: string,
  id: string,
};

const defaultProps = {
  width: 1440,
  className: null,
  children: null,
  tag: 'div',
  id: null,
};

const Container = ({
  className,
  children,
  width,
  tag: Tag,
  createRef,
  id,
  ...attributes
}) => {
  return (
    <Tag
      ref={createRef}
      className={cx(className, `container-${width}`, { anchored: id })}
      {...attributes}
    >
      {id && <div className={cx('container-anchor')} id={id} />}
      {children}
    </Tag>
  );
};

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

export default Container;
