import { func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';

import { useAuthContext } from '~/context/AuthContext';

export const PrivacyDeleteAccountDataMutation = gql`
  mutation updateSbmDeleteAccountData($value: SbmDeleteAccountDataInput!) {
    updateSbmDeleteAccountData(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  children: func.isRequired,
};

function DataButtonsHandler({ children }) {
  const { signOut } = useAuthContext();

  const [mutate] = useMutation(PrivacyDeleteAccountDataMutation);

  const handleDeleteAccountData = () => {
    mutate({
      variables: { value: { deleteAccount: true } },
    })
      .then(({ data: { updateSbmDeleteAccountData } }) => {
        if (updateSbmDeleteAccountData) {
          signOut();
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return children({
    handleDeleteAccountData,
  });
}

DataButtonsHandler.propTypes = propTypes;

export default DataButtonsHandler;
