import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './AdditionalInfo.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  text: string,
};

const defaultProps = {
  text: '',
};

const AdditionalInfo = ({ text }) => {
  return (
    <div className={cx('root')}>
      <p>{text}</p>
    </div>
  );
};

AdditionalInfo.propTypes = propTypes;
AdditionalInfo.defaultProps = defaultProps;

export default AdditionalInfo;
