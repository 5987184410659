import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { useUserContext } from '~/context/UserContext';

import styles from './PromoBanner.module.scss';

const { REACT_APP_MYACC_DOMAIN } = process.env;

const cx = classNames.bind(styles);

const PromoBanner = ({ status: statusProp }) => {
  const { loyaltyStatus, isUserDataAvailable } = useUserContext();

  const { t } = useTranslation('promo-banner');

  const promoMyacc = t('myaccount', {
    returnObjects: true,
  });
  const promoMember = t('member', {
    returnObjects: true,
  });
  const promoSilver = t('silver', {
    returnObjects: true,
  });
  const promoGold = t('gold', {
    returnObjects: true,
  });
  const promoPlatinum = t('platinum', {
    returnObjects: true,
  });
  const promoVip = t('vip', {
    returnObjects: true,
  });

  const promoBannerFields = useMemo(() => {
    switch (statusProp || loyaltyStatus) {
      case '10':
      case 10:
        return promoMember;
      case '20':
      case 20:
        return promoSilver;
      case '30':
      case 30:
        return promoGold;
      case '40':
      case 40:
        return promoPlatinum;
      case '50':
      case 50:
        return promoVip;
      default:
        return promoMyacc;
    }
  }, [
    statusProp,
    loyaltyStatus,
    promoGold,
    promoMember,
    promoMyacc,
    promoPlatinum,
    promoSilver,
    promoVip,
  ]);

  return (statusProp || isUserDataAvailable) && promoBannerFields?.enabled ? (
    <div className={cx('promo-banner')}>
      <div className={cx('content-wrapper')}>
        {promoBannerFields.title && (
          <p className={cx('title')}>{promoBannerFields.title}</p>
        )}
        {promoBannerFields.link &&
          (!(
            promoBannerFields.link.includes(REACT_APP_MYACC_DOMAIN) ||
            promoBannerFields.link.startsWith('/')
          ) ? (
            <a
              href={promoBannerFields.link}
              target={'_blank'}
              rel={'noopener noreferrer'}
              className={cx('button')}
            >
              <span className={cx('button-label')}>
                {promoBannerFields.linkLabel}
              </span>
            </a>
          ) : (
            <Link to={promoBannerFields.link} className={cx('button')}>
              <span className={cx('button-label')}>
                {promoBannerFields.linkLabel}
              </span>
            </Link>
          ))}
      </div>
    </div>
  ) : null;
};

export default PromoBanner;
