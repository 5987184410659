import { useTranslation } from 'react-i18next';

export function useMappings() {
  const { t } = useTranslation('preferences-form');
  const translations = t('formCheckboxes', { returnObjects: true });

  const userExperienceList = [
    { name: 'hotels', label: translations?.experienceHotelLabel || '' },
    {
      name: 'casinos',
      label: translations?.experienceCasinoLabel || '',
      subOptions: [
        { name: 'tables', label: translations?.experienceTablesLabel || '' },
        { name: 'slots', label: translations?.experienceSlotsLabel || '' },
      ],
    },
    {
      name: 'restaurants',
      label: translations?.experienceRestaurantsLabel || '',
    },
    { name: 'nightlife', label: translations?.experienceNightlifeLabel || '' },
    { name: 'wellness', label: translations?.experienceWellnessLabel || '' },
    {
      name: 'events',
      label: translations?.experienceEventsLabel || '',
      subOptions: [
        { name: 'sports', label: translations?.experienceSportsLabel || '' },
        { name: 'culture', label: translations?.experienceCultureLabel || '' },
      ],
    },
    { name: 'shopping', label: translations?.experienceShoppingLabel || '' },
    { name: 'shows', label: translations?.experienceShowsLabel || '' },
  ];

  const userFrequencyList = [
    { name: 'live', label: translations?.frequencyCheckboxLiveLabel || '' },
    { name: 'month', label: translations?.frequencyCheckboxMonthLabel || '' },
    {
      name: 'quarter',
      label: translations?.frequencyCheckboxQuarterLabel || '',
    },
    { name: 'year', label: translations?.frequencyCheckboxYearLabel || '' },
    {
      name: 'sometimes',
      label: translations?.frequencyCheckboxSometimesLabel || '',
    },
  ];

  const userSeasonList = [
    { name: 'spring', label: translations?.seasonCheckboxSpringLabel || '' },
    { name: 'summer', label: translations?.seasonCheckboxSummerLabel || '' },
    { name: 'autumn', label: translations?.seasonCheckboxAutumnLabel || '' },
    { name: 'winter', label: translations?.seasonCheckboxWinterLabel || '' },
  ];

  const initialContactChannelList = [
    {
      name: 'email',
      label: translations?.contactMethodEmailLabel || '',
      active: false,
      value: false,
      index: 11,
    },
    {
      name: 'mail',
      label: translations?.contactMethodAddressLabel || '',
      active: false,
      value: false,
      message: translations?.messageAddressError || '',
      messageEnd: translations?.messageAddress || '',
      index: 12,
    },
    {
      name: 'phone',
      label: translations?.contactMethodPhoneLabel || '',
      active: false,
      value: false,
      message: translations?.messagePhoneError || '',
      messageEnd: translations?.messagePhoneNumber || '',
      index: 13,
    },
    {
      name: 'sms',
      label: translations?.contactMethodSMSLabel || '',
      active: false,
      value: false,
      message: translations?.messageSmsError || '',
      messageEnd: translations?.messagePhoneNumber || '',
      index: 14,
    },
  ];

  return {
    userExperienceList,
    userFrequencyList,
    userSeasonList,
    initialContactChannelList,
  };
}
