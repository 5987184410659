export const getAddressNumberList = (valuesList) =>
  valuesList
    .filter(
      ([fullArrayKey, fullArrayValue]) =>
        fullArrayKey.startsWith('address1') && !(fullArrayValue === 'deleted')
    )
    .map(([itemName]) => {
      const itemNameSplit = itemName.split('_');
      return itemNameSplit[itemNameSplit.length - 1];
    });

export const getEmailNumberList = (valuesList) =>
  valuesList
    .filter(
      ([fullArrayKey, fullArrayValue]) =>
        fullArrayKey.startsWith('email') &&
        !fullArrayKey.endsWith('_cat') &&
        !(fullArrayValue === 'deleted')
    )
    .map(([itemName]) => {
      const itemNameSplit = itemName.split('_');
      return itemNameSplit[itemNameSplit.length - 1];
    });

export const getPhoneNumberList = (valuesList) =>
  valuesList
    .filter(
      ([fullArrayKey, fullArrayValue]) =>
        fullArrayKey.startsWith('phone') &&
        !fullArrayKey.endsWith('_cat') &&
        !(fullArrayValue === 'deleted')
    )
    .map(([itemName]) => {
      const itemNameSplit = itemName.split('_');
      return itemNameSplit[itemNameSplit.length - 1];
    });
