import { useState, useMemo } from 'react';
import flowRight from 'lodash/flowRight';
import { withRouter } from 'react-router-dom';
import { func } from 'prop-types';
import { gql, useApolloClient } from '@apollo/client';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { latinLettersWithNumbersEmailRegexp } from '~/constants';

const gtmUserSearchParams = {
  event: 'search_user',
};

const propTypes = {
  children: func.isRequired,
};

const SbmAccountDataQuery = gql`
  query SbmAccountData($filter: CustomerSearchInput) {
    customerSearch(filter: $filter) {
      items {
        id
        created
        enabled
        errors
        warnings
        firstName
        lastName
        email
        creationSource
        status
      }
      count
      csvUrl
    }
  }
`;

const MaintenanceFormHandler = ({ children }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [searchResult, setSearchResult] = useState(null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [csvReport, setCsvReport] = useState(null);

  const { t } = useTranslation('maintenance');
  const validationCharsOnlyMessage = t('validationCharsOnlyMessage');

  const initialFromDate = moment().startOf('day').subtract(1, 'days');

  const initialToDate = moment().startOf('day');

  const client = useApolloClient();

  const onDatesChange = ({ startDate, endDate }) => {
    const toDate = endDate
      ? endDate.startOf('day')
      : startDate.clone().startOf('day');

    setFromDate(startDate);
    setToDate(toDate);
  };

  const onDatesChangeMobileStartDate = (startDate) => {
    if (startDate) {
      const toDateNormalized = !toDate
        ? moment(startDate).clone().startOf('day').format('YYYY-MM-DD')
        : toDate;

      const startDateObj = moment.isMoment(startDate)
        ? startDate
        : moment(startDate);
      const toDateObj = moment.isMoment(toDateNormalized)
        ? toDateNormalized
        : moment(toDateNormalized);

      setFromDate(startDateObj);
      setToDate(toDateObj);
    }

    if (!startDate) {
      setFromDate(null);
      setToDate(null);
    }
  };

  const onDatesChangeMobileEndDate = (endDate) => {
    if (!endDate) {
      const endDatenormalized = moment(fromDate)
        .clone()
        .startOf('day')
        .format('YYYY-MM-DD');

      setToDate(endDatenormalized);
    }

    const endDateObj = moment.isMoment(endDate) ? endDate : moment(endDate);

    setToDate(endDateObj);
  };

  const resetDates = () => {
    setFromDate(null);
    setToDate(null);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setCsvReport(null);

    const { confirmed, ...formFields } = values;

    let confirmationStatus = null;

    if (confirmed === 'confirmed') {
      confirmationStatus = true;
    }
    if (confirmed === 'unconfirmed') {
      confirmationStatus = false;
    }

    const createdFrom = fromDate ? fromDate.format('YYYY-MM-DD') : '';
    const createdTo = toDate ? toDate.format('YYYY-MM-DD') : '';

    const valuesNormalized = {
      ...formFields,
      createdTo,
      createdFrom,
      ...(confirmationStatus !== null && { confirmed: confirmationStatus }),
    };
    setSubmitting(true);

    client
      .query({
        query: SbmAccountDataQuery,
        variables: {
          filter: valuesNormalized,
        },
      })
      .then(({ data: { customerSearch } }) => {
        setSearchResult(customerSearch);
        setSubmitting(false);
        setSubmitSuccess(true);
        gtmPushEvent(gtmUserSearchParams);

        setCsvReport(customerSearch.csvUrl);
      })
      .catch((error) => {
        console.warn(error);
        setSubmitSuccess(true);
        setSearchResult(null);
        setSubmitting(false);
      });
  };

  const initialValues = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    confirmed: 'all',
    warnings: false,
    errors: false,
    csv: false,
  };

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().matches(
          latinLettersWithNumbersEmailRegexp,
          validationCharsOnlyMessage
        ),
      }),

    [validationCharsOnlyMessage]
  );

  return children({
    handleSubmit,
    initialValues,
    validationSchema,
    searchResult,
    submitSuccess,
    fromDate,
    toDate,
    initialFromDate,
    initialToDate,
    onDatesChange,
    onDatesChangeMobileStartDate,
    onDatesChangeMobileEndDate,
    resetDates,
    csvReport,
  });
};

MaintenanceFormHandler.propTypes = propTypes;

export default flowRight(withErrorBoundary, withRouter)(MaintenanceFormHandler);
