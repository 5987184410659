import React from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Burger.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  onClick: func.isRequired,
  className: string,
  isOpen: bool,
};

const defaultProps = {
  className: '',
  isOpen: false,
};

const Burger = ({ className, isOpen, onClick }) => (
  <button
    className={cx('root', className, { 'is-open': isOpen })}
    aria-expanded="false"
    onClick={onClick}
  >
    <div className={cx('line-container')}>
      <span className={cx('line')} />
    </div>
    <div className={cx('text')}>Мenu</div>
  </button>
);

Burger.propTypes = propTypes;
Burger.defaultProps = defaultProps;

export default Burger;
