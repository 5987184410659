import React from 'react';
import classNames from 'classnames/bind';
import { node, string } from 'prop-types';
import styles from './PanelContainer.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node,
  className: string,
};

const defaultProps = {
  children: null,
  className: '',
};

const PanelContainer = ({ children, className }, ref) => (
  <div className={cx('root', className)}>{children}</div>
);

PanelContainer.propTypes = propTypes;
PanelContainer.defaultProps = defaultProps;

export default PanelContainer;
