import { useState, useMemo } from 'react';
import { string, array } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';

import usePrevious from 'common/hooks/usePrevious';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

const EditForeignIdsSendMutation = gql`
  mutation updateCustomerForeignIds(
    $id: String!
    $value: [CustomerForeignIdInput!]
  ) {
    updateCustomerForeignIds(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  userHash: string.isRequired,
  data: array.isRequired,
};

function EditForeignIdsFormHandler({ userHash, children, data, refetch }) {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const initialValues = useMemo(
    () => ({
      foreignIds: (!!data?.length &&
        data.reduce((acc, { sourceName, sourceKey }) => {
          acc.push({ sourceKey, sourceName });

          return acc;
        }, [])) || [{ sourceKey: '', sourceName: '' }],
    }),
    [data]
  );

  const [formValues, setFormValues] = useState(initialValues);
  const prevFieldValues = usePrevious(formValues);

  const [onFormChange] = useDebouncedCallback(() => {
    if (submitSuccess || !isEqual(prevFieldValues, formValues)) {
      setSubmitSuccess(false);
    }
  }, 300);

  const { t } = useTranslation('foreignids-form');
  const foreignIdsHotelLabel = t('foreignIdsHotelLabel');
  const foreignIdsComptewebLabel = t('foreignIdsComptewebLabel');
  const foreignIdsCasinoLabel = t('foreignIdsCasinoLabel');
  const foreignIdsWellbeingLabel = t('foreignIdsWellbeingLabel');
  const foreignIdsMappLabel = t('foreignIdsMappLabel');
  const foreignIdsAparteLabel = t('foreignIdsAparteLabel');
  const validationRequiredFieldMessage = t('validationRequiredFieldMessage');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        foreignIds: Yup.array().of(
          Yup.object().shape(
            {
              sourceKey: Yup.string().when('sourceName', {
                is: (sourceNameValue) => sourceNameValue,
                then: Yup.string().required(validationRequiredFieldMessage),
                otherwise: Yup.string(),
              }),
              sourceName: Yup.string().when('sourceKey', {
                is: (sourceKeyValue) => sourceKeyValue,
                then: Yup.string().required(validationRequiredFieldMessage),
                otherwise: Yup.string(),
              }),
            },
            ['sourceName', 'sourceKey']
          )
        ),
      }),
    [validationRequiredFieldMessage]
  );

  const foreignIdsOptions = useMemo(
    () => [
      { value: 'HOTEL', label: foreignIdsHotelLabel },
      { value: 'COMPTEWEB', label: foreignIdsComptewebLabel },
      { value: 'CASINO', label: foreignIdsCasinoLabel },
      { value: 'WELLBEING', label: foreignIdsWellbeingLabel },
      { value: 'MAPP', label: foreignIdsMappLabel },
      { value: 'APARTE', label: foreignIdsAparteLabel },
    ],
    [
      foreignIdsAparteLabel,
      foreignIdsCasinoLabel,
      foreignIdsComptewebLabel,
      foreignIdsHotelLabel,
      foreignIdsMappLabel,
      foreignIdsWellbeingLabel,
    ]
  );

  const [mutate] = useMutation(EditForeignIdsSendMutation);

  const handleSubmit = ({ foreignIds }, { setSubmitting }) => {
    const submitFields = foreignIds.reduce((acc, { sourceKey, sourceName }) => {
      if (sourceKey && sourceName) return [...acc, { sourceKey, sourceName }];

      return acc;
    }, []);

    mutate({
      variables: {
        id: userHash,
        value: submitFields,
      },
    })
      .then(({ data: { updateCustomerForeignIds } }) => {
        setSubmitting(false);

        if (updateCustomerForeignIds) {
          setSubmitSuccess(true);
          refetch();
        }
      })
      .catch((error) => {
        console.warn(error);
        setSubmitError(true);
        setSubmitting(false);
      });
  };

  return children({
    foreignIdsOptions,
    handleSubmit,
    initialValues,
    submitError,
    submitSuccess,
    setSubmitSuccess,
    onFormChange,
    setFormValues,
    validationSchema,
  });
}

EditForeignIdsFormHandler.propTypes = propTypes;

export default withErrorBoundary(EditForeignIdsFormHandler);
