export default function detectTouchevents() {
  const root = document.documentElement;
  const classNameBase = 'touchevents';

  if (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  ) {
    root.className += ` ${classNameBase}`;
  } else {
    root.className += ` no-${classNameBase}`;
  }
}
