export const MD_PRODUCT = {
  type: 'http://schema.org/Product',
  logo: 'logo',
  url: 'url',
  name: 'name',
  image: 'image',
  rating: 'aggregateRating',
};

export const MD_WEB_SITE = {
  type: 'http://schema.org/WebSite',
  action: 'potentialAction',
  url: 'url',
};

export const MD_BRAND = {
  type: 'https://schema.org/Brand',
  description: 'description',
  logo: 'logo',
};

export const MD_ORGANIZATION = {
  type: 'https://schema.org/Organization',
  legalName: 'legalName',
  brand: 'brand',
  name: 'name',
  area: 'areaServed',
  location: 'foundingLocation',
  policy: 'ethicsPolicy',
  makesOffer: 'makesOffer',
};

export const MD_SEARCH = {
  type: 'http://schema.org/SearchAction',
  input: 'query-input',
  target: 'target',
  description: 'description',
};

export const MD_PLACE = {
  type: 'http://schema.org/Place',
  logo: 'logo',
  photo: 'photo',
  address: 'address',
  name: 'name',
};

export const MD_HOTEL = {
  type: 'https://schema.org/Hotel',
  name: 'name',
  star: 'starRating',
  photo: 'photo',
  image: 'image',
  amenityFeature: 'amenityFeature',
  phone: 'telephone',
  address: 'address',
  priceRange: 'priceRange',
};

export const MD_HOTEL_ROOM = {
  type: 'https://schema.org/HotelRoom',
  name: 'name',
  photo: 'photo',
  amenityFeature: 'amenityFeature',
  phone: 'telephone',
  address: 'address',
};

export const MD_RESTAURANT = {
  type: 'https://schema.org/Restaurant',
  name: 'name',
  star: 'starRating',
  photo: 'photo',
  phone: 'telephone',
};

export const MD_CASINO = {
  type: 'https://schema.org/Casino',
  name: 'name',
  photo: 'photo',
  phone: 'telephone',
};

export const MD_NIGHTLIFE = {
  type: 'https://schema.org/BarOrPub',
  name: 'name',
  star: 'starRating',
  photo: 'photo',
  phone: 'telephone',
};

export const MD_OFFER = {
  type: 'https://schema.org/Offer',
  name: 'name',
  url: 'url',
  price: 'price',
  priceCurrency: 'priceCurrency',
  validFrom: 'validFrom',
  availability: 'availability',
};

export const MD_STORE = {
  type: 'https://schema.org/Store',
  name: 'name',
  phone: 'telephone',
  address: 'address',
};

export const MD_SPA = {
  type: 'https://schema.org/DaySpa',
  name: 'name',
  phone: 'telephone',
};

export const MD_ITEM_LIST = {
  type: 'https://schema.org/ItemList',
  name: 'name',
  element: 'itemListElement',
};

export const MD_LIST_ITEM = {
  type: 'http://schema.org/ListItem',
  item: 'item',
  name: 'name',
  position: 'position',
  url: 'url',
};

export const MD_SERVICE = {
  type: 'http://schema.org/Service',
  hasCatalog: 'hasOfferCatalog',
};

export const MD_OFFER_CATALOG = {
  type: 'http://schema.org/OfferCatalog',
};

export const MD_INFORM_ACTION = {
  type: 'https://schema.org/InformAction',
  event: 'event',
};

export const MD_EVENT = {
  type: 'http://schema.org/Event',
  location: 'location',
  startDate: 'startDate',
  endDate: 'endDate',
  url: 'url',
  name: 'name',
  description: 'description',
  offers: 'offers',
  performer: 'performer',
};
