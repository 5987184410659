import React, { useState } from 'react';
import { func, number, shape } from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames/bind';
import flowRight from 'lodash/flowRight';

import { withRoomsNumberContext } from 'common/context/RoomsNumberContext';

import styles from './BookingOfferRoomsNumberSelector.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  roomsNumberContext: shape({
    roomsNumber: number.isRequired,
    updateRoomsNumber: func.isRequired,
  }).isRequired,
  onClose: func,
};

const defaultProps = {
  onClose: null,
};

const BookingOfferRoomsNumberSelector = ({
  roomsNumberContext: { roomsNumber, updateRoomsNumber },
  onClose,
}) => {
  const [selectedRoomsNumberOption, setSelectedRoomsNumberOption] =
    useState(roomsNumber);

  const getRoomsNumbersList = (arrLength) =>
    Array.from({ length: arrLength }, (el, i) => ({
      value: i + 1,
      label: `${i + 1}`,
      disabled: false,
    }));

  let roomsNumbersList = getRoomsNumbersList(7);

  const handleRoomsNumberChange = ({ value }) => {
    setSelectedRoomsNumberOption(value);
    updateRoomsNumber(value);

    if (onClose) {
      onClose();
    }
  };

  const setSelectRoomsNumberValues = roomsNumbersList.filter(
    (option) => option.value === selectedRoomsNumberOption
  );

  return (
    <div className={cx('root')}>
      <Select
        onChange={handleRoomsNumberChange}
        value={setSelectRoomsNumberValues}
        options={roomsNumbersList}
        className={cx('select')}
        classNamePrefix="select"
        isSearchable={false} // fix bug with mobile keyboard on focus (ios)
        noOptionsMessage={() => null} // prevent default message
      />
    </div>
  );
};

BookingOfferRoomsNumberSelector.propTypes = propTypes;
BookingOfferRoomsNumberSelector.defaultProps = defaultProps;

export default flowRight(withRoomsNumberContext)(
  BookingOfferRoomsNumberSelector
);
