import React, { useRef, useMemo, useState } from 'react';
import { string, array, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Panel from '~/components/Panel';
import ErrorFallback from '~/components/ErrorFallback';
import DiagnosticAssistant from '~/scenes/DiagnosticAssistant';

import PanelContent from '../PanelContent';
import UserLog from '../UserLog';
import ApiLog from '../ApiLog';
import NavMailSending from '../NavMailSending';
import AsyncMachine from '../AsyncMachine';
import LoyaltyMachine from '../LoyaltyMachine';
import Menu from '../Menu';
import UserNotifier from '../UserNotifier';

const propTypes = {
  userId: string.isRequired,
  authHistory: array,
  accountStatus: string.isRequired,
  url: string.isRequired,
  pageTopRef: object.isRequired,
  createOnClickHandler: func.isRequired,
  technicalInfoPanelFields: array.isRequired,
  myIdStatus: string.isRequired,
  apiLogData: object.isRequired,
  loyaltyMachineData: object.isRequired,
  asyncMachineData: object.isRequired,
  email: string.isRequired,
};

const defaultProps = {
  authHistory: [],
};

const DiagnosticTab = ({
  userId,
  authHistory,
  accountStatus,
  url,
  pageTopRef,
  createOnClickHandler,
  technicalInfoPanelFields,
  myIdStatus,
  apiLogData,
  asyncMachineData,
  loyaltyMachineData,
  email,
}) => {
  const { t } = useTranslation(['edit-account', 'common']);
  const technicalInfoSectionTitle = t('technicalInfoSectionTitle');
  const toToplinkLabel = t('toToplinkLabel');
  const apiLogBlock = t('apiLogBlock', {
    returnObjects: true,
  });
  const asyncMachineBlock = t('asyncMachineBlock', {
    returnObjects: true,
  });
  const loyaltyMachineBlock = t('loyaltyMachineBlock', {
    returnObjects: true,
  });
  const mailSendingBlock = t('asyncMachineBlock', {
    returnObjects: true,
  });
  const apiLogBlockTitle = apiLogBlock?.blockTitle;
  const asyncMachineBlockTitle = asyncMachineBlock?.blockTitle;
  const loyaltyMachineBlockTitle = loyaltyMachineBlock?.blockTitle;

  const [circuitBreakerStatus, setCircuitBreakerStatus] = useState(
    apiLogData.circuitBreakerOpen
  );

  const technicalInfoSectionRef = useRef();
  const apiLogBlockSectionRef = useRef();
  const loyaltyMachineSectionRef = useRef();
  const asyncMachineBlockSectionRef = useRef();

  const menu = useMemo(
    () => [
      { label: technicalInfoSectionTitle, ref: technicalInfoSectionRef },
      { label: loyaltyMachineBlockTitle, ref: loyaltyMachineSectionRef },
      { label: asyncMachineBlockTitle, ref: asyncMachineBlockSectionRef },
      { label: apiLogBlockTitle, ref: apiLogBlockSectionRef },
    ],
    [
      technicalInfoSectionRef,
      technicalInfoSectionTitle,
      apiLogBlockSectionRef,
      apiLogBlockTitle,
      asyncMachineBlockTitle,
      asyncMachineBlockSectionRef,
      loyaltyMachineBlockTitle,
      loyaltyMachineSectionRef,
    ]
  );

  const navLinkData = useMemo(
    () => ({
      url: url,
      onClick: createOnClickHandler(pageTopRef),
      label: toToplinkLabel,
    }),
    [url, createOnClickHandler, pageTopRef, toToplinkLabel]
  );

  return (
    <div>
      <Panel>
        <DiagnosticAssistant
          myIdStatus={myIdStatus}
          apiLogStatus={circuitBreakerStatus}
          asyncMachineStatus={asyncMachineData.status}
          loyaltyMachineStatus={loyaltyMachineData.status}
        />
      </Panel>

      <Panel>
        <Menu menu={menu} url={url} onClick={createOnClickHandler} />
      </Panel>

      {/* MyID (AWS Cognito) */}
      <Panel
        title={technicalInfoSectionTitle}
        ref={technicalInfoSectionRef}
        navLinkData={navLinkData}
      >
        {!!technicalInfoPanelFields.length ? (
          <>
            <PanelContent panelFields={technicalInfoPanelFields} />
            <UserNotifier accountStatus={accountStatus} userId={userId} />
            <UserLog
              authHistory={authHistory}
              parentRef={technicalInfoSectionRef}
            />
          </>
        ) : (
          <ErrorFallback mode={'friendly'} />
        )}
      </Panel>

      <Panel
        title={loyaltyMachineBlockTitle}
        navLinkData={navLinkData}
        ref={loyaltyMachineSectionRef}
      >
        <LoyaltyMachine
          data={loyaltyMachineData}
          parentRef={loyaltyMachineSectionRef}
        />
      </Panel>

      <Panel
        title={asyncMachineBlockTitle}
        navLinkData={navLinkData}
        ref={asyncMachineBlockSectionRef}
      >
        <AsyncMachine
          data={asyncMachineData}
          parentRef={asyncMachineBlockSectionRef}
        />
      </Panel>

      {accountStatus === 'CONFIRMED' && (
        <Panel
          title={mailSendingBlock.blockTitle}
          navLinkData={navLinkData}
          ref={asyncMachineBlockSectionRef}
        >
          <NavMailSending email={email} />
        </Panel>
      )}

      <Panel
        title={apiLogBlockTitle}
        navLinkData={navLinkData}
        ref={apiLogBlockSectionRef}
      >
        <ApiLog
          data={apiLogData}
          parentRef={apiLogBlockSectionRef}
          setCircuitBreakerStatus={setCircuitBreakerStatus}
          circuitBreakerStatus={circuitBreakerStatus}
        />
      </Panel>
    </div>
  );
};

DiagnosticTab.propTypes = propTypes;
DiagnosticTab.defaultProps = defaultProps;

export default DiagnosticTab;
