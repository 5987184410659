import React, { lazy, Suspense } from 'react';
import classNames from 'classnames/bind';

import SpinLoader from 'common/components/SpinLoader';

import styles from './InputPhone.module.scss';

const cx = classNames.bind(styles);

const LazyInput = lazy(() =>
  typeof window !== 'undefined' ? import('./InputPhoneSync') : null
);

const InputPhone = (props) => {
  return (
    <Suspense
      fallback={
        <div className={cx('loader-holder')}>
          <SpinLoader className={cx('loader')} size={16} />
        </div>
      }
    >
      <LazyInput {...props} />
    </Suspense>
  );
};

export default InputPhone;
