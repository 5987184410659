import React, { useState } from 'react';
import { string } from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import IconContainer from 'common/components/IconContainer';
import { useToggle } from 'common/hooks';
import newArray from 'common/utils/newArray';

import { useUiContext } from 'common/context/UiContext';
import { ReactComponent as IconCheck } from '~/media/icons/check.icon.svg';

import AdvantagesHeader from './components/AdvantagesHeader';
import styles from './Advantages.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
};

const defaultProps = {
  className: null,
};

const renderCell = (cell, index) => (
  <td className={cx('advantages-cell')} key={index}>
    {cell === '@icon' ? (
      <IconContainer icon={IconCheck} className={cx('icon-check')} />
    ) : (
      cell
    )}
  </td>
);

const renderRow = (row, index) => {
  if (row.length === 1) {
    return (
      <tr className={cx('advantages-sub-header')} key={index}>
        <td className={cx('advantages-sub-header-cell')}>{row[0]}</td>
        {newArray(4).map((_, index) => (
          <td key={index}></td>
        ))}
      </tr>
    );
  }

  return (
    <tr className={cx('advantages-row')} key={index}>
      <td className={cx('advantages-first-cell')}>{row[0]}</td>
      {row.slice(1).map(renderCell)}
    </tr>
  );
};

const Advantages = ({ className }) => {
  const [tableVisible, toggleTableVisibility] = useToggle(false);

  const { currentBreakpoint } = useUiContext();
  const isCompact = currentBreakpoint !== 'desktop';

  const { t } = useTranslation('advantages-block');

  const advantagesHeader = t('advantagesHeader', { returnObjects: true });
  const advantagesTable = t('advantagesTable', { returnObjects: true });

  const [activeSwitch, setActiveSwitch] = useState({
    name: advantagesHeader.cardMemberLabel,
    order: 0,
  });

  const renderRowCompact = (row, index) => {
    if (row.length === 1) {
      return (
        <tr className={cx('advantages-sub-header')} key={index}>
          <td className={cx('advantages-sub-header-cell')}>{row[0]}</td>
          <td className={cx('advantages-cell')}></td>
        </tr>
      );
    }

    return (
      <tr className={cx('advantages-row')} key={index}>
        <td className={cx('advantages-first-cell')}>{row[0]}</td>
        {renderCell(row[activeSwitch.order + 1])}
      </tr>
    );
  };

  return (
    <div className={cx('root', className)}>
      <AdvantagesHeader
        tableVisible={tableVisible}
        toggleTableVisibility={toggleTableVisibility}
        advantagesHeader={advantagesHeader}
        activeSwitch={activeSwitch}
        setActiveSwitch={setActiveSwitch}
        isCompact={isCompact}
      />
      <CSSTransition
        in={tableVisible}
        timeout={150}
        classNames={{
          enter: cx('transition-enter'),
          enterActive: cx('transition-enter-active'),
          enterDone: cx('transition-done-enter'),
          exit: cx('transition-exit'),
          exitActive: cx('transition-exit-active'),
        }}
        unmountOnExit
      >
        <table className={cx('advantages-table')}>
          <tbody>
            {isCompact
              ? advantagesTable.map(renderRowCompact)
              : advantagesTable.map(renderRow)}
          </tbody>
        </table>
      </CSSTransition>
    </div>
  );
};

Advantages.propTypes = propTypes;
Advantages.defaultProps = defaultProps;

export default Advantages;
