import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Container from 'common/components/Container';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import FormPreferences from '~/scenes/FormPreferences';
import ButtonBack from '~/components/ButtonBack';
import BlockTitle from '~/components/BlockTitle';
import Panel from '~/components/Panel';
import GTM from '~/components/GTM';

import styles from './EditPreferencesInfo.module.scss';

const cx = classNames.bind(styles);

const EditPreferencesInfoDataQuery = gql`
  query EditPreferencesInfoData($id: String!) {
    getCustomerProfile(id: $id) {
      sbmContacts(id: $id) {
        phone {
          phone
        }
      }
      sbmPreferences(id: $id) {
        language
        contactChannels {
          method
          value
        }
        preferences {
          option
          subOptions
        }
        frequency {
          option
        }
        season {
          option
          subOptions
        }
      }
    }
  }
`;

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

function EditPreferencesInfo({
  match: {
    params: { id },
  },
}) {
  const { t } = useTranslation('edit-account');
  const editPreferencesInfoPageTitle = t('editPreferencesInfoPageTitle');

  const { language } = useLocalisationContext();

  const { data, loading, error } = useQuery(EditPreferencesInfoDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const backLink = useMemo(() => `/${language}/user/${id}`, [id, language]);

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{editPreferencesInfoPageTitle}</title>
      </Helmet>

      {loading && !data && <PageLoader />}
      {!loading && data && !error && (
        <Panel>
          <ButtonBack to={backLink} />
          <BlockTitle
            title={editPreferencesInfoPageTitle}
            className={cx('page-title')}
          />
          <FormPreferences
            isMyCs
            userHash={id}
            redirectTo={backLink}
            contactsData={data.getCustomerProfile.sbmContacts}
            preferencesData={data.getCustomerProfile.sbmPreferences}
          />
        </Panel>
      )}

      <GTM isMyCS />
    </Container>
  );
}

EditPreferencesInfo.propTypes = propTypes;

export default EditPreferencesInfo;
