import React, { Fragment } from 'react';
import { string, node, array, bool } from 'prop-types';
import classNames from 'classnames/bind';

import Stars from 'common/components/Stars';
import StarsMichelin from 'common/components/StarsMichelin';

import { useAuthContext, SIGNED_IN_STATE } from '~/context/AuthContext';

import styles from './PushRightColParentTitleAttributes.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  children: node,
  parentPropertyList: array,
  parentTitle: string,
  starsValue: string,
  michelinStarsValue: string,
  attributesList: array,
  longStyle: bool,
  coloredAttributes: bool,
};

const defaultProps = {
  className: null,
  children: null,
  parentPropertyList: null,
  parentTitle: null,
  starsValue: null,
  michelinStarsValue: null,
  attributesList: null,
  longStyle: true,
  coloredAttributes: false,
};

const PushRightColParentTitleAttributes = ({
  className,
  children,
  parentPropertyList,
  parentTitle,
  starsValue,
  michelinStarsValue,
  attributesList,
  longStyle,
  coloredAttributes,
  ...attributes
}) => {
  const { authState } = useAuthContext();

  const isContent = parentTitle || !!attributesList || !!parentPropertyList;
  const isParents = parentTitle || !!parentPropertyList;

  return (
    <Fragment>
      {isContent && (
        <div
          className={cx(
            'root',
            { 'long-style': longStyle },
            { 'short-style': !longStyle },
            { 'only-attributes': !isParents },
            className
          )}
          {...attributes}
        >
          {!!parentPropertyList && (
            <div className={cx('parent-property-wrapper')}>
              {parentPropertyList.map(
                ({ title, stars, michelinStars }, index) => (
                  <div className={cx('parent-title')} key={index}>
                    {title}
                    {stars && stars.value && (
                      <Fragment>
                        {' '}
                        <Stars
                          className={cx('parent-stars')}
                          value={stars.value}
                        />
                      </Fragment>
                    )}
                    {michelinStars && michelinStars.value && (
                      <Fragment>
                        {' '}
                        <StarsMichelin
                          className={cx('parent-stars-michelin')}
                          value={michelinStars.value}
                        />
                      </Fragment>
                    )}
                  </div>
                )
              )}
            </div>
          )}
          {parentTitle && (
            <div className={cx('parent-title')}>
              {parentTitle}
              {starsValue && (
                <Fragment>
                  {' '}
                  <Stars className={cx('parent-stars')} value={starsValue} />
                </Fragment>
              )}
              {michelinStarsValue && (
                <Fragment>
                  {' '}
                  <StarsMichelin
                    className={cx('parent-stars-michelin')}
                    value={michelinStarsValue}
                  />
                </Fragment>
              )}
            </div>
          )}
          {authState === SIGNED_IN_STATE && !!attributesList && (
            <ul
              className={cx('list-attributes', {
                'colored-attributes': coloredAttributes,
              })}
            >
              {attributesList.map(({ name }, index) => (
                <li key={index} className={cx('attribute-item')}>
                  <span className={cx('attribute-text')}>{name}</span>
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </Fragment>
  );
};

PushRightColParentTitleAttributes.propTypes = propTypes;
PushRightColParentTitleAttributes.defaultProps = defaultProps;

export default PushRightColParentTitleAttributes;
