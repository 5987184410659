import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Button from '~/components/Button';

import WishlistShareModal from '../WishlistShareModal';
import styles from './WishlistShare.module.scss';

const cx = classNames.bind(styles);

const WishlistShare = () => {
  const { t } = useTranslation('wishlist');

  const [modalVisible, setModalVisible] = useState(false);

  const handleModalClose = () => {
    setModalVisible(false);
  };

  return (
    <>
      <div className={cx('share-button-wrapper')}>
        <Button color="tallow" inverted onClick={() => setModalVisible(true)}>
          {t('shareButtonLabel')}
        </Button>
      </div>

      {modalVisible && (
        <WishlistShareModal handleModalClose={handleModalClose} />
      )}
    </>
  );
};

export default WishlistShare;
