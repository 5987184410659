const getMandatoryFields = ({
  key,
  fieldName,
  groupNumber,
  result,
  index,
  value,
}) => {
  if (key === `${fieldName}_${groupNumber}`) {
    result[index] = {
      ...result[index],
      [`${fieldName}_${groupNumber}`]: `${value}`,
    };
  }
};

export const subAddressFieldKeys = ['address1', 'city', 'country', 'address'];

export const getMandatoryGroups = ({
  addressNumberList,
  valuesList,
  subFieldKeysArray,
}) =>
  addressNumberList.reduce((result, groupNumber, index) => {
    valuesList.forEach(([key, value]) => {
      subFieldKeysArray.forEach((subFieldKey) => {
        getMandatoryFields({
          key,
          groupNumber,
          fieldName: subFieldKey,
          result,
          index,
          value,
        });
      });
    });

    return result;
  }, []);

export const isFieldMandatory = (mandatoryGroups, fieldName) => {
  return !!mandatoryGroups?.find(
    (groupItem) => groupItem[fieldName] !== undefined
  );
};
