import { useMemo } from 'react';

import { useLocalisationContext } from '~/context/LocalisationContext';

export default function useCountryNameKey() {
  const { language } = useLocalisationContext();

  const countryNameKey = useMemo(() => {
    switch (language) {
      case 'fr':
        return 'nameFr';
      case 'it':
        return 'nameIt';
      default:
        return 'name';
    }
  }, [language]);

  return countryNameKey;
}
