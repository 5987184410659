/**
 * Sort function to sort Array of Objects
 * by their weight property
 * @param  {Object} a Object to compare
 * @param  {Object} b Object to compare
 * @return {Number} Sorting result
 */
function byWeight(a, b) {
  return a.weight - b.weight;
}

/**
 * Helper function to structure Drupal flat menu items
 * into nested menu with children and sorted by weigh param
 * @param  {Array} flatMenu An array to sort
 * @param  {Boolean} sort Whether sorting be applied, default is `true`
 * @return {Array} Resulting array
 */
function flatToNestedNSortedMenu(flatMenu, sort = true) {
  let flatMenuCopy = flatMenu ? flatMenu.map((obj) => ({ ...obj })) : [];

  function getNestedChildren(arr, parent) {
    let result = [];

    for (const i in arr) {
      if (arr[i].parent === parent) {
        const children = getNestedChildren(arr, arr[i].id);

        if (children.length) {
          arr[i].children = sort ? children.sort(byWeight) : children;
        }

        result.push(arr[i]);
      }
    }

    return sort ? result.sort(byWeight) : result;
  }

  return getNestedChildren(flatMenuCopy, '');
}

export default flatToNestedNSortedMenu;

export { flatToNestedNSortedMenu, byWeight };
