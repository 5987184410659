// TODO: this hook should be refactored and cleaned up. Please do it when SBM will validate the new offer redirect flow.

import { /* useState ,*/ useEffect } from 'react';
import { useMyaccHistory } from '~/hooks';
import { parse } from 'query-string';
// import { gql, useLazyQuery } from '@apollo/client';

// import { sbmClient } from '~/apolloClient';

// const { REACT_APP_SBM_DOMAIN } = process.env;

// const SbmOfferRouteDataQuery = gql`
//   query SbmOfferRouteData($url: String!) {
//     route(url: $url) {
//       langId
//       parameters {
//         ... on RedirectRouteParameters {
//           destination
//         }
//       }
//     }
//   }
// `;

const useOfferRedirect = function ({
  isUserDataAvailable,
  // loyaltyStatus,
  offerRedirectParams,
}) {
  // Redirect to exact offer in case of GET params http://myaccdomain/?offerID=1397&lang=en

  const { offerID: autoLoginOfferID, offerLang: autoLoginOfferLang } =
    offerRedirectParams;

  const {
    push: historyPush,
    location: { search },
  } = useMyaccHistory();

  // const [offerRedirectLoading, setOfferRedirectLoading] = useState(false);
  const { offerID: searchOfferID, lang: searchOfferLang } = parse(search);

  // const [getOfferRouteData, { data }] = useLazyQuery(SbmOfferRouteDataQuery, {
  //   client: sbmClient,
  //   variables: {
  //     url: searchOfferLang
  //       ? `/${searchOfferLang}/node/${searchOfferID}`
  //       : `/${autoLoginOfferLang}/node/${autoLoginOfferID}`,
  //   },
  //   context: {
  //     headers: {
  //       'X-Acc-Grp': loyaltyStatus,
  //     },
  //   },
  //   onCompleted: () => {
  //     if (data?.route?.parameters?.destination) {
  //       console.log(
  //         `Go to: ${REACT_APP_SBM_DOMAIN}${data.route.parameters.destination}`
  //       );
  //       window.location.href = `${REACT_APP_SBM_DOMAIN}${data.route.parameters.destination}`;
  //     }
  //   },
  // });

  useEffect(() => {
    if (isUserDataAvailable) {
      if (searchOfferID && searchOfferLang) {
        historyPush(`/offers/${searchOfferID}`);
        // setOfferRedirectLoading(true);
        // getOfferRouteData();
      }
      if (autoLoginOfferID && autoLoginOfferLang) {
        historyPush(`/offers/${autoLoginOfferID}`);
        // if (autoLoginMyaccOffer) {
        //   historyPush(`/offers/${autoLoginOfferID}`);
        // } else {
        //   setOfferRedirectLoading(true);
        //   getOfferRouteData();
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isUserDataAvailable,
    searchOfferLang,
    searchOfferID,
    autoLoginOfferLang,
    autoLoginOfferID,
    // autoLoginMyaccOffer,
  ]);

  return { offerRedirectLoading: false };
  // return { offerRedirectLoading };
};

export default useOfferRedirect;
