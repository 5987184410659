import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';
import flowRight from 'lodash/flowRight';

import Container from 'common/components/Container';
import gtmPushEvent from 'common/utils/gtmPushEvent';
import Picture from 'common/components/Picture';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import MyAccLink from '~/components/MyAccLink';
import statisticsClicksWidgetDataParams from '~/utils/statisticsClicksWidgetDataParams';

import styles from './WidgetPromoImage.module.scss';

const cx = classNames.bind(styles);

const widgetPromoImageFieldsFragment = gql`
  fragment widgetPromoImageFields on WidgetPromoImage {
    id
    title
    media {
      title
      ... on MediaImage {
        id
        image {
          id
          promoImage: url(style: widget_promo_long_image_webp) {
            url
            width
            height
          }
          promoImagePlaceholder: url(style: image_placeholder_webp) {
            url
          }
          alt
        }
      }
    }
    link {
      url
    }
  }
`;

const propTypes = {
  data: propType(widgetPromoImageFieldsFragment).isRequired,
};

const fragment = {
  widgetPromoImageFieldsFragment,
};

const WidgetPromoImage = ({
  data: {
    id,
    title,
    link,
    media: { image },
  },
}) => {
  const createOnClick =
    ({ title, id, link }) =>
    () => {
      const generatedStatisticsClicksWidgetDataParams =
        statisticsClicksWidgetDataParams('widgetBannerText', title, id, link);

      const params = {
        event: 'click_widget',
        widget_info: generatedStatisticsClicksWidgetDataParams,
      };

      gtmPushEvent(params);
    };

  const renderImage = ({ promoImage: { url }, alt }) => (
    <Picture src={url} alt={alt} />
  );

  return (
    <div className={cx('root')}>
      <Container tag="section" width={1140}>
        <div className={cx('content-wrapper')}>
          {link?.url ? (
            <MyAccLink
              to={link.url}
              target="_blank"
              rel="noopener"
              onClick={createOnClick({
                title,
                id,
                link: link.url,
              })}
            >
              {renderImage(image)}
            </MyAccLink>
          ) : (
            renderImage(image)
          )}
        </div>
      </Container>
    </div>
  );
};

WidgetPromoImage.propTypes = propTypes;
WidgetPromoImage.fragment = fragment;

export default flowRight(withErrorBoundary)(WidgetPromoImage);
