import React from 'react';
import classNames from 'classnames/bind';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import Input from 'common/components/FormComponents/Input';
import ContentLoader from 'common/components/ContentLoader';
import Container from 'common/components/Container';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconDownload } from 'common/media/icons/download.icon.svg';

import GTM from '~/components/GTM';
import Checkbox from '~/components/FormComponents/Checkbox';
import FormButtons from '~/components/FormButtons';
import Panel from '~/components/Panel';
import SearchResults from '~/scenes/SearchResults';
import FormRadios from '~/components/FormComponents/FormRadios';
import FieldDescription from '~/components/FormComponents/FieldDescription';

import MaintenanceFormHandler from './containers/MaintenanceFormHandler';
import DateRangePicker from './components/DateRangePicker';

import styles from './Maintenance.module.scss';

const cx = classNames.bind(styles);

function Maintenance() {
  const { t } = useTranslation(['maintenance']);
  const pageTitle = t('pageTitle');
  const formTitle = t('formTitle');
  const idLabel = t('idLabel');
  const firstNameLabel = t('firstNameLabel');
  const lastNameLabel = t('lastNameLabel');
  const emailLabel = t('emailLabel');
  const buttonLabel = t('buttonLabel');
  const warningLabel = t('warningLabel');
  const errorLabel = t('errorLabel');
  const warningErrorFieldsLabel = t('warningErrorFieldsLabel');
  const confirmationFieldsLabel = t('confirmationFieldsLabel');
  const confirmationStatusAll = t('confirmationStatusAll');
  const confirmationStatusConfirmed = t('confirmationStatusConfirmed');
  const confirmationStatusUnconfirmed = t('confirmationStatusUnconfirmed');
  const reportFieldLabel = t('reportFieldLabel');
  const reportUrlLabel = t('reportUrlLabel');

  return (
    <Container tag="main" className={cx('root')} width={900}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className={cx('maintenance-form')}>
        <MaintenanceFormHandler>
          {({
            handleSubmit,
            initialValues,
            validationSchema,
            submitSuccess,
            searchResult,
            fromDate,
            toDate,
            initialFromDate,
            initialToDate,
            onDatesChange,
            onDatesChangeMobileStartDate,
            onDatesChangeMobileEndDate,
            resetDates,
            csvReport,
          }) => (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, isSubmitting }) => (
                <>
                  <Panel className={cx('form-panel-wrapper')} title={formTitle}>
                    <Form className={cx('form')}>
                      <div className={cx('full-width')}>
                        <Field
                          name="id"
                          component={Input}
                          placeholder={idLabel}
                          maxLength={254}
                        />
                      </div>
                      <div className={cx('half-width')}>
                        <Field
                          name="firstName"
                          component={Input}
                          placeholder={firstNameLabel}
                          maxLength={30}
                        />
                      </div>
                      <div className={cx('half-width')}>
                        <Field
                          name="lastName"
                          component={Input}
                          placeholder={lastNameLabel}
                          maxLength={30}
                        />
                      </div>
                      <div className={cx('full-width')}>
                        <Field
                          name="email"
                          component={Input}
                          placeholder={emailLabel}
                          maxLength={128}
                        />
                      </div>
                      <div className={cx('spacer')} />
                      <div className={cx('full-width')}>
                        <DateRangePicker
                          initialFromDate={initialFromDate}
                          initialToDate={initialToDate}
                          fromDate={fromDate}
                          toDate={toDate}
                          onDatesChange={onDatesChange}
                          onDatesChangeMobileStartDate={
                            onDatesChangeMobileStartDate
                          }
                          onDatesChangeMobileEndDate={
                            onDatesChangeMobileEndDate
                          }
                          resetDates={resetDates}
                        />
                      </div>
                      <div className={cx('full-width', 'statuses-container')}>
                        <div>
                          <FieldDescription>
                            {confirmationFieldsLabel}
                          </FieldDescription>
                          <Field
                            name="confirmed"
                            component={FormRadios}
                            options={[
                              { name: 'all', label: confirmationStatusAll },
                              {
                                name: 'confirmed',
                                label: confirmationStatusConfirmed,
                              },
                              {
                                name: 'unconfirmed',
                                label: confirmationStatusUnconfirmed,
                              },
                            ]}
                            values={values}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                          />
                        </div>
                        <div>
                          <FieldDescription>
                            {warningErrorFieldsLabel}
                          </FieldDescription>
                          <div className={cx('checkboxes')}></div>
                          <Field
                            name="warnings"
                            label={warningLabel}
                            component={Checkbox}
                            className={cx('checkbox')}
                          />
                          <Field
                            name="errors"
                            label={errorLabel}
                            component={Checkbox}
                            className={cx('checkbox')}
                          />
                        </div>
                      </div>
                      <div className={cx('full-width', 'report')}>
                        <Field
                          name="csv"
                          label={reportFieldLabel}
                          component={Checkbox}
                          className={cx('checkbox')}
                        />
                        {csvReport && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            type="application/pdf"
                            href={csvReport}
                            download={csvReport}
                            className={cx('download')}
                          >
                            <IconContainer
                              icon={IconDownload}
                              className={cx('icon-download')}
                            />
                            <span className={cx('data-cta-text')}>
                              {reportUrlLabel}
                            </span>
                          </a>
                        )}
                      </div>

                      <div className={cx('spacer')} />
                      <FormButtons
                        isSubmitting={isSubmitting}
                        saveButtonLabel={buttonLabel}
                        className={cx('form-submit-wrapper')}
                      />
                    </Form>
                  </Panel>
                  {isSubmitting && (
                    <Panel className={cx('loader-wrapper')}>
                      <ContentLoader />
                    </Panel>
                  )}
                  {submitSuccess && !isSubmitting && (
                    <Panel className={cx('loader-wrapper')}>
                      <SearchResults data={searchResult} />
                    </Panel>
                  )}
                </>
              )}
            </Formik>
          )}
        </MaintenanceFormHandler>
      </div>

      <GTM isMyCS />
    </Container>
  );
}

export default Maintenance;
