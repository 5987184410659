import React from 'react';
import { node, number, bool } from 'prop-types';
import classNames from 'classnames/bind';

import IsClient from 'common/components/IsClient';
import Container from 'common/components/Container';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import { useUiContext } from 'common/context/UiContext';

import styles from './StickyBlockContainer.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  stickyBlock: node.isRequired,
  children: node,
  containerWidth: number,
  stickyBlockHidden: bool,
};

const defaultProps = {
  containerWidth: 1140,
  children: null,
  stickyBlockHidden: false,
};

const StickyBlockContainer = ({
  stickyBlock,
  containerWidth,
  children,
  stickyBlockHidden,
}) => {
  const { currentBreakpoint } = useUiContext();

  return (
    <div className={cx('root')}>
      <IsClient>
        {!stickyBlockHidden && currentBreakpoint === 'desktop' && (
          <div className={cx('sticky-wrapper')}>
            <div style={{ height: '569px' }} />
            <Container width={containerWidth} className={cx('sticky-content')}>
              <div className={cx('universe-sticky-block-wrapper')}>
                <div className={cx('universe-sticky-block')}>{stickyBlock}</div>
              </div>
            </Container>
          </div>
        )}
      </IsClient>
      {children}
    </div>
  );
};

StickyBlockContainer.propTypes = propTypes;
StickyBlockContainer.defaultProps = defaultProps;

export default withErrorBoundary(StickyBlockContainer);
