import { gql } from '@apollo/client';

import menuFieldsFragment from 'common/queries/menuFieldsFragment';

export default gql`
  query FooterData {
    footerBottomMenu: menu(name: my_account_footer_bottom) {
      ...menuFields
    }
    footerNavMenu: menu(name: my_account_footer_navigation) {
      ...menuFields
    }
  }
  ${menuFieldsFragment}
`;
