import React from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconNotification } from 'common/media/icons/notification.icon.svg';

import styles from './InformationPanel.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node.isRequired,
  withIcon: bool,
  darkMode: bool,
  successMode: bool,
  errorMode: bool,
  className: string,
  isComponentChildren: bool,
};

const defaultProps = {
  withIcon: false,
  darkMode: false,
  successMode: false,
  errorMode: false,
  className: null,
  isComponentChildren: false,
};

const InformationPanel = ({
  children,
  withIcon,
  darkMode,
  successMode,
  errorMode,
  className,
  isComponentChildren,
}) => (
  <div
    className={cx(
      'root',
      {
        'with-icon': withIcon,
        'dark-mode': darkMode,
        'success-mode': successMode,
        'error-mode': errorMode,
      },
      className
    )}
  >
    {withIcon && (
      <IconContainer className={cx('icon')} icon={IconNotification} />
    )}
    {isComponentChildren ? (
      <div className={cx('text')}>{children}</div>
    ) : (
      <div
        className={cx('text')}
        dangerouslySetInnerHTML={{ __html: children }}
      />
    )}
  </div>
);

InformationPanel.propTypes = propTypes;
InformationPanel.defaultProps = defaultProps;

export default InformationPanel;
