import SignUp from '~/pages/myid/SignUp';
import CreateAccount from '~/pages/myid/CreateAccount';
import Authorize from '~/pages/myid/Authorize';

import LoginForm from '~/scenes/LoginForm';
import ForgotPassForm from '~/scenes/ForgotPassForm';

import ChangePassword from '~/pages/myac/ChangePassword';
import AccountInformation from '~/pages/myac/AccountInformation';
import EditIdentity from '~/pages/myac/EditIdentity';
import Preferences from '~/pages/myac/Preferences';
import LoyaltyProgram from '~/pages/myac/LoyaltyProgram';
import HomePage from '~/pages/myac/HomePage';
import Privacy from '~/pages/myac/Privacy';
import OffersList from '~/pages/myac/OffersList';
import OfferDetails from '~/pages/myac/OfferDetails';
import JoinLoyaltyProgram from '~/pages/myac/JoinLoyaltyProgram';
import Notifications from '~/pages/myac/Notifications';

import Maintenance from '~/pages/mycs/Maintenance';
import OneClick from '~/pages/mycs/OneClick';
import EditAccount from '~/pages/mycs/EditAccount';
import EditMainInfo from '~/pages/mycs/EditMainInfo';
import EditIdentityInfo from '~/pages/mycs/EditIdentityInfo';
import EditContactsInfo from '~/pages/mycs/EditContactsInfo';
import EditPreferencesInfo from '~/pages/mycs/EditPreferencesInfo';
import EditPrivacyInfo from '~/pages/mycs/EditPrivacyInfo';
import EditForeignIds from '~/pages/mycs/EditForeignIds';

import Wishlist from '~/pages/Wishlist';

const MYID_ROUTES = [
  {
    path: '/:langId/signup',
    component: SignUp,
  },
  {
    path: '/:langId/create-account',
    component: CreateAccount,
  },
  {
    path: '/:langId/wishlist',
    component: Wishlist,
  },
  {
    path: '/:langId/',
    component: Authorize,
    exact: false,
  },
];

const MYID_AUTH_ROUTES = [
  {
    path: '/:langId/',
    component: LoginForm,
  },
  {
    path: '/:langId/forgot-password',
    component: ForgotPassForm,
  },
];

const MYAC_ROUTES = [
  {
    path: '/:langId/homepage',
    component: HomePage,
  },
  {
    path: '/:langId/loyalty-program',
    component: LoyaltyProgram,
  },
  {
    path: '/:langId/join-loyalty-program',
    component: JoinLoyaltyProgram,
  },
  {
    path: '/:langId/account-information',
    component: AccountInformation,
  },
  {
    path: '/:langId/account-information/edit-identity',
    component: EditIdentity,
  },
  {
    path: '/:langId/account-information/change-password',
    component: ChangePassword,
  },
  {
    path: '/:langId/preferences',
    component: Preferences,
  },
  {
    path: '/:langId/privacy',
    component: Privacy,
  },
  {
    path: '/:langId/offers',
    component: OffersList,
  },
  {
    path: '/:langId/offers/:id',
    component: OfferDetails,
  },
  {
    path: '/:langId/wishlist',
    component: Wishlist,
  },
  {
    path: '/:langId/notifications',
    component: Notifications,
  },
];

const MYCS_ROUTES = [
  {
    path: '/:langId/homepage',
    component: Maintenance,
  },
  {
    path: '/:langId/one-click',
    component: OneClick,
  },
  {
    path: '/:langId/user/:id',
    component: EditAccount,
  },
  {
    path: '/:langId/user/:id/edit-main-info',
    component: EditMainInfo,
  },
  {
    path: '/:langId/user/:id/edit-identity-info',
    component: EditIdentityInfo,
  },
  {
    path: '/:langId/user/:id/edit-contacts-info',
    component: EditContactsInfo,
  },
  {
    path: '/:langId/user/:id/edit-preferences-info',
    component: EditPreferencesInfo,
  },
  {
    path: '/:langId/user/:id/edit-privacy-info',
    component: EditPrivacyInfo,
  },
  {
    path: '/:langId/user/:id/edit-foreign-ids',
    component: EditForeignIds,
  },
];

export { MYID_ROUTES, MYID_AUTH_ROUTES, MYAC_ROUTES, MYCS_ROUTES };
