import { useMemo } from 'react';
import moment from 'moment';

import { getMonth } from '../utils/calendarParams';

export default function useMonths({
  lastAvailableDate,
  selectedYear,
  lastAvailableYear,
}) {
  const monthsFullList = moment.months();

  const lastAvailableMonth = useMemo(() => getMonth(lastAvailableDate), [
    lastAvailableDate,
  ]);

  const monthsList = useMemo(
    () =>
      lastAvailableDate && selectedYear === lastAvailableYear
        ? monthsFullList.slice(0, lastAvailableMonth)
        : monthsFullList,
    [lastAvailableMonth, selectedYear, monthsFullList]
  );

  return { monthsList, lastAvailableMonth };
}
