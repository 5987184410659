import React, { useState } from 'react';
import { shape, string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';

import Container from 'common/components/Container';

import PageLoader from '~/components/PageLoader';
import GTM from '~/components/GTM';
import FormPrivacy from '~/scenes/FormPrivacy';
import Panel from '~/components/Panel';
import PanelContainer from '~/components/PanelContainer';
import BlockTitle from '~/components/BlockTitle';
import ErrorFallback from '~/components/ErrorFallback';
import { useUserContext } from '~/context/UserContext';

import DataButtonsHandler from './containers/DataButtonsHandler';
import DataButtons from './components/DataButtons';
import styles from './Privacy.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  history: object.isRequired,
  match: shape({
    path: string,
  }).isRequired,
};

const Privacy = () => {
  const [isPrivacyDataUpdated, setPrivacyDataUpdated] = useState(false);

  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  const { t } = useTranslation('privacy');
  const pageTitle = t('pageTitle');

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {loadingUserData && <PageLoader />}
      {!loadingUserData && (
        <>
          <Panel>
            <BlockTitle title={pageTitle} tag={'h3'} />
            {userSbmGdpr && !isFailSafeMode ? (
              <FormPrivacy
                data={userSbmGdpr}
                redirectTo={'/'}
                setPrivacyDataUpdated={setPrivacyDataUpdated}
              />
            ) : (
              <ErrorFallback mode={'friendly'} />
            )}
          </Panel>
          {userSbmGdpr && !isFailSafeMode && (
            <PanelContainer className={cx('data-buttons-container')}>
              <DataButtonsHandler>
                {({ handleDeleteAccountData }) => (
                  <DataButtons
                    onDeleteData={handleDeleteAccountData}
                    isPrivacyDataUpdated={isPrivacyDataUpdated}
                    setPrivacyDataUpdated={setPrivacyDataUpdated}
                  />
                )}
              </DataButtonsHandler>
            </PanelContainer>
          )}
        </>
      )}

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
};

Privacy.propTypes = propTypes;

export default Privacy;
