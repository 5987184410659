import React, { useRef } from 'react';
import { shape, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import Container from 'common/components/Container';

import { FullscreenContentWrapper } from '~/components/FullscreenContainer';
import PageLoader from '~/components/PageLoader';
import GTM from '~/components/GTM';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';

import EditAccountDataQuery from './queries/EditAccountDataQuery';
import EditAccountPageHandler from './containers/EditAccountPageHandler';
import DiagnosticTab from './components/DiagnosticTab';
import UserDataTab from './components/UserDataTab';
import MyMCTab from './components/MyMCTab';
import styles from './EditAccount.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  match: shape({
    url: string,
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

const createOnClickHandler = (ref) => (e) => {
  e.preventDefault();

  if (!ref) return;

  ref.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
};

function EditAccount({
  match: {
    url,
    params: { id },
  },
}) {
  const { t } = useTranslation('edit-account');
  const mainPageTitle = t('mainPageTitle');
  const userDataTabTitle = t('userDataTabTitle');
  const diagnosticTabTitle = t('diagnosticTabTitle');

  const pageTopRef = useRef();

  const { maintenanceTabIndex, setMaintenanceTabIndex, isFailSafeMode } =
    useUserContext();

  const {
    data,
    loading,
    error,
    refetch: accountDataRefetch,
  } = useQuery(EditAccountDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" width={900} className={cx('container')}>
      <Helmet>
        <title>{mainPageTitle}</title>
      </Helmet>
      {loading && !data && (
        <FullscreenContentWrapper vertical={'top'} fullWidth>
          <PageLoader />
        </FullscreenContentWrapper>
      )}
      {!loading && data && !error && (
        <div className={cx('root')} ref={pageTopRef}>
          <EditAccountPageHandler data={data}>
            {({
              technicalInfoPanelFields,
              mainInfoPanelFields,
              identityFields,
              userNationalities,
              contactFields,
              preferencesFields,
              isPreferencesAvailable,
              isContactsEditable,
              isContactsAvailable,
              sbmGdpr,
              foreignIds,
              authHistory,
              accountStatus,
              myIdStatus,
              apiLogData,
              asyncMachineData,
              loyaltyData,
              loyaltyMachineData,
              isFailSafeMode,
              email,
            }) => (
              <Tabs
                selectedIndex={maintenanceTabIndex}
                onSelect={(tabIndex) => {
                  setMaintenanceTabIndex(tabIndex);
                }}
              >
                <TabList className={cx('tabs-list')}>
                  <Tab
                    className={cx('tab')}
                    selectedClassName={cx('active-tab')}
                  >
                    <span className={cx('tab-label')}>
                      {diagnosticTabTitle}
                    </span>
                  </Tab>
                  <Tab
                    className={cx('tab')}
                    selectedClassName={cx('active-tab')}
                  >
                    <span className={cx('tab-label')}>{userDataTabTitle}</span>
                  </Tab>
                  {loyaltyData && (
                    <Tab
                      className={cx('tab')}
                      selectedClassName={cx('active-tab')}
                    >
                      <span className={cx('tab-label')}>{'MyMC'}</span>
                    </Tab>
                  )}
                </TabList>

                <TabPanel selectedClassName={cx('active-tab-panel')}>
                  <DiagnosticTab
                    userId={id}
                    technicalInfoPanelFields={technicalInfoPanelFields}
                    accountStatus={accountStatus}
                    myIdStatus={myIdStatus}
                    apiLogData={apiLogData}
                    asyncMachineData={asyncMachineData}
                    loyaltyMachineData={loyaltyMachineData}
                    authHistory={authHistory}
                    url={url}
                    email={email}
                    pageTopRef={pageTopRef}
                    createOnClickHandler={createOnClickHandler}
                  />
                </TabPanel>

                <TabPanel selectedClassName={cx('active-tab-panel')}>
                  <UserDataTab
                    mainInfoPanelFields={mainInfoPanelFields}
                    identityFields={identityFields}
                    userNationalities={userNationalities}
                    isContactsEditable={isContactsEditable}
                    isContactsAvailable={isContactsAvailable}
                    contactFields={contactFields}
                    isPreferencesAvailable={isPreferencesAvailable}
                    preferencesFields={preferencesFields}
                    sbmGdpr={sbmGdpr}
                    foreignIds={foreignIds}
                    url={url}
                    pageTopRef={pageTopRef}
                    createOnClickHandler={createOnClickHandler}
                    isFailSafeMode={isFailSafeMode}
                  />
                </TabPanel>

                {loyaltyData && (
                  <TabPanel selectedClassName={cx('active-tab-panel')}>
                    <MyMCTab
                      loyaltyData={loyaltyData}
                      identityFields={identityFields}
                      refetch={accountDataRefetch}
                    />
                  </TabPanel>
                )}
              </Tabs>
            )}
          </EditAccountPageHandler>
        </div>
      )}

      {!isFailSafeMode && <GTM isMyCS />}
    </Container>
  );
}

EditAccount.propTypes = propTypes;

export default withErrorBoundary(EditAccount);
