import React from 'react';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './GridContainer.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  col: oneOf(['3', 3, '2', 2]),
  tag: string,
};

const defaultProps = {
  col: '3',
  tag: 'div',
};

const GridContainer = ({ col, tag, className, children, ...attrs }) => {
  const Tag = tag;
  return (
    <Tag className={cx('root', `col-${col}`, className)} {...attrs}>
      {children}
    </Tag>
  );
};

GridContainer.propTypes = propTypes;
GridContainer.defaultProps = defaultProps;

export default GridContainer;
