import React from 'react';
import classNames from 'classnames/bind';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import flowRight from 'lodash/flowRight';

import Container from 'common/components/Container';

import PageLoader from '~/components/PageLoader';
import GTM from '~/components/GTM';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';
import FormPreferences from '~/scenes/FormPreferences';
import Panel from '~/components/Panel';
import BlockTitle from '~/components/BlockTitle';

import styles from './Preferences.module.scss';

const cx = classNames.bind(styles);

const Preferences = () => {
  const { t } = useTranslation('preferences');
  const pageTitle = t('pageTitle');
  const titleText = t('titleText');

  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmContacts,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {loadingUserData ? (
        <PageLoader />
      ) : (
        <Panel className={cx('panel')}>
          <BlockTitle title={titleText} />
          <FormPreferences
            contactsData={userSbmContacts}
            preferencesData={userSbmPreferences}
            redirectTo={'/'}
          />
        </Panel>
      )}

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
};

export default flowRight(withErrorBoundary, withRouter)(Preferences);
