import { useState, useCallback } from 'react';
import { func } from 'prop-types';
import { gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';

import gtmPushEvent from 'common/utils/gtmPushEvent';
import { NonConfirmedUserLoginQuery } from 'common/queries';

import applyNameFormat from '~/utils/applyNameFormat';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { anonymousUserApolloClient } from '~/apolloClient';
import {
  FORM_CHECK_EMAIL_STATE,
  FORM_PASSWORD_STATE,
  FORM_MESSAGE_STATE,
  CLIENT_APP_VERSION,
  CONSENT,
} from '~/constants';
import { CaptchaResultQuery, UpdateEmailQuery } from '~/queries';
import { useAuthContext } from '~/context/AuthContext';
import { useLocalisationContext } from '~/context/LocalisationContext';

const gtmSignupStep3Params = {
  event: 'load_account_registration_flow_step3',
};

const gtmNewsletterSubscriptionParams = {
  event: 'sign_up_newsletter',
};

const gtmLoyaltyProgramRegistrationParams = {
  event: 'sign_up_loyalty_program',
};

const SbmComboQuery = gql`
  query SbmComboData(
    $email: String!
    $firstName: String!
    $birthName: String!
    $birthDate: String!
  ) {
    getSbmComboData(
      email: $email
      firstName: $firstName
      birthName: $birthName
      birthDate: $birthDate
    ) {
      email
      action
      consent # newsletter subscription
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  children: func.isRequired,
  setDiabledWarnBeforeUnload: func.isRequired,
};

function CreateAccountHandler({ children, setDiabledWarnBeforeUnload }) {
  const { languageISO3 } = useLocalisationContext();

  const { t } = useTranslation('errors');
  const userIsNotConfirmed = t('userIsNotConfirmed');
  const userContactCustomerService = t('userContactCustomerService');

  const [formState, setFormState] = useState(FORM_CHECK_EMAIL_STATE);
  const [initialInput, setInitialInput] = useState(null);
  const [isSubscribeDisabled, setSubscribeDisabled] = useState(false);
  const [isMyMCDisabled, setMyMCDisabled] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [apiErrors, setApiErrors] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formMessages, setFormMessages] = useState(null);
  const [submitErrorMessage, setSubmitErrorMessage] = useState();
  const [dataConsent, setDataConsent] = useState(null);

  const {
    push: historyPush,
    location: { search },
  } = useHistory();
  const { offerID, lang } = parse(search);

  const { preFilledLogin, setPreFilledLogin } = useAuthContext();

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess || !!apiErrors || submitErrorMessage) {
      setApiErrors(null);
      setSubmitError(false);
      setSubmitSuccess(false);
      setSubmitErrorMessage(null);
    }
  }, [apiErrors, submitError, submitErrorMessage, submitSuccess]);

  const checkEmailHandler = (
    { login, name, familyName, birthDate },
    { setSubmitting, resetForm, setFieldValue },
    { solution: token }
  ) => {
    if (!token) {
      setSubmitting(false);
      resetForm();
      console.error('No captcha token generated');

      return null;
    }

    anonymousUserApolloClient
      .query({
        query: CaptchaResultQuery,
        variables: {
          response: token,
        },
      })
      .then(({ data, loading, error }) => {
        if (error) {
          console.error(`data: ${data}\n`, error);

          return null;
        }

        if (loading) {
          return null;
        }

        const { getCaptchaResult } = data;

        if (!getCaptchaResult) {
          setSubmitError(true);
          setSubmitting(false);
          resetForm();
          return null;
        }

        setFieldValue('name', applyNameFormat(name));
        setFieldValue('familyName', applyNameFormat(familyName));

        anonymousUserApolloClient
          .query({
            query: SbmComboQuery,
            variables: {
              email: login,
              firstName: applyNameFormat(name),
              birthName: applyNameFormat(familyName),
              birthDate: birthDate,
            },
          })
          .then(({ data, loading, error }) => {
            if (error) {
              console.error(`data: ${data}\n`, error);
              return null;
            }

            if (loading) {
              return null;
            }

            if (!data.getSbmComboData) {
              setSubmitError(true);
              setSubmitting(false);
              console.error(`Response: ${data}\n`);
            }

            const {
              getSbmComboData,
              getSbmComboData: { action, consent },
            } = data;
            setDataConsent(consent || CONSENT.undefined);

            if (action === 'login') {
              setSubmitting(false);
              setSubmitSuccess(true);
              setPreFilledLogin(login);
              setInitialInput(null);
              setTimeout(() => historyPush('/'), 4000);

              return children(defaultChildrenProps);
            } else if (action === 'user_not_allowed') {
              setSubmitting(false);
              setSubscribeDisabled(true);
              setMyMCDisabled(true);
              setInitialInput({
                email: login,
                birthDate: birthDate,
                firstName: name,
                lastName: familyName,
              });
              setFormState(FORM_PASSWORD_STATE);

              return null;
            } else if (action === 'user_has_been_found_mymc') {
              setSubmitting(false);
              setSubscribeDisabled(consent === CONSENT.yes);
              setMyMCDisabled(true);
              setInitialInput({
                email: login,
                birthDate: birthDate,
                firstName: name,
                lastName: familyName,
                consent: consent,
              });
              setFormState(FORM_PASSWORD_STATE);

              return null;
            } else if (action === 'user_has_been_found') {
              setSubmitting(false);
              setSubscribeDisabled(consent === CONSENT.yes);
              setInitialInput({
                email: login,
                birthDate: birthDate,
                firstName: name,
                lastName: familyName,
                consent: consent,
              });
              setFormState(FORM_PASSWORD_STATE);

              return null;
            } else if (action === 'user_is_unknown') {
              setSubmitting(false);
              setInitialInput({
                email: login,
                birthDate: birthDate,
                firstName: name,
                lastName: familyName,
              });
              setFormState(FORM_PASSWORD_STATE);

              return null;
            } else if (action === 'user_contact_customer_service') {
              setSubmitting(false);
              setSubscribeDisabled(true);
              setMyMCDisabled(true);
              setInitialInput({
                email: login,
                birthDate: birthDate,
                firstName: name,
                lastName: familyName,
              });
              setApiErrors({
                messages: { error: [userContactCustomerService] },
              });

              return null;
            } else if (action === 'error') {
              if (
                getSbmComboData?.messages.error?.includes(
                  'UserNotConfirmedException'
                )
              ) {
                anonymousUserApolloClient
                  .query({
                    query: NonConfirmedUserLoginQuery,
                    variables: { email: login },
                  })
                  .then(({ data, loading, error }) => {
                    if (error) {
                      console.error(`data: ${data}\n`, error);
                      return null;
                    }

                    if (loading) {
                      return null;
                    }

                    const { informSbmNonConfirmedUserLogin } = data;

                    if (informSbmNonConfirmedUserLogin) {
                      console.warn('Email link was resubmitted');
                    }
                  })
                  .catch(() => {
                    console.warn('Email link was not resubmitted');
                  });

                setSubmitting(false);

                setApiErrors({
                  messages: { error: [userIsNotConfirmed] },
                });

                setPreFilledLogin(login);
                setTimeout(() => historyPush('/'), 5000);

                return null;
              } else if (getSbmComboData?.messages) {
                setApiErrors({
                  messages: getSbmComboData.messages,
                });
                setSubmitting(false);
                setSubmitError(true);
                return null;
              }
            }
          })
          .catch((error) => {
            console.warn(error);
          });
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  const signUpComboHandler = ({
    values,
    formik: { setSubmitting, resetForm },
    captchaStatus: { solution: token },
    captchaReset,
  }) => {
    if (!token) {
      setSubmitting(false);
      resetForm();
      console.error('No captcha token generated');

      return null;
    }

    const submitFields = {
      email: initialInput.email,
      firstName: applyNameFormat(initialInput.firstName),
      lastName: applyNameFormat(initialInput.lastName),
      birthDate: initialInput.birthDate,
      password: values?.password,
      ...((isMyMCDisabled || !values?.mymc) && {
        langUI: languageISO3,
      }),
      ...(!isSubscribeDisabled && { subscription: values?.consent }),
      ...(!isMyMCDisabled && { mymc: values?.mymc }),
      ...(!isMyMCDisabled &&
        values?.mymc && {
          gender: values?.gender,
          country: values?.countryOfResidence,
          lang: values?.language,
          occupation: values?.occupation,
        }),
      clientAppVersion: CLIENT_APP_VERSION,
      captcha: token,
    };

    anonymousUserApolloClient
      .mutate({
        mutation: UpdateEmailQuery,
        variables: {
          value: {
            ...submitFields,
            ...(offerID && lang && { offerID: offerID, offerLang: lang }),
          },
        },
      })
      .then(
        ({
          data: {
            updateSbmEmailDataInput: { result, messages },
          },
        }) => {
          if (result) {
            if (!!messages) {
              setFormMessages(messages);
            }
            setSubmitSuccess(true);
            setDiabledWarnBeforeUnload(true);
            setSubmitting(false);
            gtmPushEvent(gtmSignupStep3Params);
            if (values?.subscribe) {
              gtmPushEvent(gtmNewsletterSubscriptionParams);
            }
            if (values?.mymc) {
              gtmPushEvent(gtmLoyaltyProgramRegistrationParams);
            }
            setTimeout(() => setFormState(FORM_MESSAGE_STATE), 4000);
          } else {
            setSubmitting(false);
            captchaReset();
            setSubmitErrorMessage({ messages });
          }
        }
      )
      .catch((error) => {
        captchaReset();
        console.warn(error);
      });
  };

  const defaultChildrenProps = {
    formState,
    checkEmailHandler,
    signUpComboHandler,
    submitSuccess,
    submitError,
    apiErrors,
    submitErrorMessage,
    resetMessage,
  };

  if (
    formState === FORM_CHECK_EMAIL_STATE ||
    formState === FORM_PASSWORD_STATE
  ) {
    return children({
      signUpData: initialInput,
      emailExists: !!preFilledLogin,
      isSubscribeDisabled,
      isMyMCDisabled,
      dataConsent,
      ...defaultChildrenProps,
    });
  }

  if (formState === FORM_MESSAGE_STATE) {
    return children({ formMessages, ...defaultChildrenProps });
  }

  return children();
}

CreateAccountHandler.propTypes = propTypes;

export default withErrorBoundary(CreateAccountHandler);
