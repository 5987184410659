import React, { useCallback } from 'react';
import { string, number, bool, object, shape, func, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import Toggle from 'common/containers/Toggle';
import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';
import useWebkitAutofillFix from 'common/hooks/useWebkitAutofillFix';

import ShowTextButton from './components/ShowTextButton';
import styles from './InputPassword.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({
    values: object.isRequired,
    errors: object.isRequired,
    touched: object.isRequired,
    setFieldValue: func.isRequired,
    handleBlur: func.isRequired,
  }).isRequired,
  placeholder: string,
  required: bool,
  className: string,
  autoComplete: string,
  disabled: bool,
  maxLength: number,
  onKeyPress: func,
  setIsAutofilled: func,
  theme: oneOf(['block-theme']),
};

const defaultProps = {
  placeholder: '',
  required: false,
  className: null,
  disabled: false,
  autoComplete: null,
  maxLength: 255,
  onKeyPress: null,
  setIsAutofilled: null,
  theme: null,
};

const InputPassword = ({
  field,
  field: { name, value },
  form: { values, errors, touched, setFieldValue, handleBlur, handleChange },
  placeholder,
  required,
  className,
  disabled,
  autoComplete,
  maxLength,
  onKeyPress,
  setIsAutofilled: setIsAutofilledProp,
  theme,
}) => {
  const { isAutoFilled, disableWebkitAutofillFix, webkitAutofillFix } =
    useWebkitAutofillFix(values[name]);

  const setIsAutofilled = useCallback(
    (status) => {
      if (setIsAutofilledProp) {
        setIsAutofilledProp(status);
      }
    },
    [setIsAutofilledProp]
  );

  const blurHandler = (e) => {
    let value = e.target.value || '';
    if (required) {
      //e.target.value 2-lines fix for email field UI update after trim (#348467)
      value = value.trim();
      e.target.value = '';
      e.target.value = value;
      setFieldValue(name, value);
    } else {
      setFieldValue(name, value);
    }

    disableWebkitAutofillFix();

    handleBlur(e);
  };

  const changeHandler = (e) => {
    setIsAutofilled(false);
    handleChange(e);
  };

  const animationStartHandler = () => {
    webkitAutofillFix();
    setIsAutofilled(true);
  };

  return (
    <Toggle>
      {({ status: showText, toggle: toggleText }) => (
        <div
          className={cx('root', className, theme, {
            'error-wrapper': errors[name] && touched[name],
          })}
        >
          <input
            {...field}
            autoComplete={autoComplete}
            type={showText ? 'text' : 'password'}
            className={cx(
              'input',
              {
                error: errors[name] && touched[name],
              },
              {
                'show-text': showText,
              }
            )}
            disabled={disabled}
            onBlur={blurHandler}
            onChange={changeHandler}
            maxLength={maxLength}
            onAnimationStart={animationStartHandler}
            onKeyPress={onKeyPress}
          />

          {placeholder && (
            <label
              htmlFor={name}
              className={cx('label', {
                transformed: !!values[name] || isAutoFilled,
              })}
            >
              {required ? `${placeholder}*` : placeholder}
            </label>
          )}

          <ErrorMessageCustom name={name}>
            {({ msg }) => <FieldError>{msg}</FieldError>}
          </ErrorMessageCustom>

          <ShowTextButton
            isError={touched[name] && !!errors[name]}
            className={cx('button-show')}
            toggle={toggleText}
            isText={showText}
          />
        </div>
      )}
    </Toggle>
  );
};

InputPassword.propTypes = propTypes;
InputPassword.defaultProps = defaultProps;

export default InputPassword;
