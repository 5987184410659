import { useMemo } from 'react';
import { func } from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
  latinLettersRegexp,
  latinLettersWithNumbersEmailRegexp,
} from '~/constants';
import { useFriendlyCaptcha } from '~/hooks';

const propTypes = {
  children: func.isRequired,
};

function FormCheckEmailHandler({ children }) {
  const { captchaStatus, CaptchaWidget } = useFriendlyCaptcha();

  const { t } = useTranslation('auth');
  const validationIncorrectEmailMessage = t(
    'create.validationIncorrectEmailMessage'
  );
  const validationRequiredFieldMessage = t(
    'create.validationRequiredFieldMessage'
  );
  const validationCharsOnlyMessage = t('create.validationCharsOnlyMessage');
  const validationSameNamesMessage = t('create.validationSameNamesMessage');

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        login: Yup.string()
          .email(validationIncorrectEmailMessage)
          .matches(
            latinLettersWithNumbersEmailRegexp,
            validationCharsOnlyMessage
          )
          .required(validationRequiredFieldMessage),
        name: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationCharsOnlyMessage),
        familyName: Yup.string()
          .required(validationRequiredFieldMessage)
          .matches(latinLettersRegexp, validationCharsOnlyMessage)
          .test('not-same', validationSameNamesMessage, function (value) {
            return value?.toLowerCase() !== this.parent.name?.toLowerCase();
          }),
        birthDate: Yup.string().required(validationRequiredFieldMessage),
      }),
    [
      validationCharsOnlyMessage,
      validationIncorrectEmailMessage,
      validationRequiredFieldMessage,
      validationSameNamesMessage,
    ]
  );

  let initialValues = {
    login: '',
    name: '',
    familyName: '',
    birthDate: '',
  };

  return children({
    validationSchema,
    initialValues,
    captchaStatus,
    CaptchaWidget,
  });
}

FormCheckEmailHandler.propTypes = propTypes;

export default FormCheckEmailHandler;
