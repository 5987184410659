import { useState, useMemo } from 'react';
import { object, bool, func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import flowRight from 'lodash/flowRight';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useMyaccHistory } from '~/hooks';

const gtmLoyaltyProgramRegistrationParams = {
  event: 'sign_up_loyalty_program',
};

const IdentitySendMutation = gql`
  mutation updateSbmIdentity($value: SbmIdentityInput!) {
    updateSbmIdentity(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const EnrollmentMutation = gql`
  mutation updateSbmEnrollment($value: SbmEnrollmentInput!) {
    updateSbmEnrollment(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  data: object.isRequired,
  children: func.isRequired,
  isCivility: bool.isRequired,
  isBirthDate: bool.isRequired,
  isCountryOfResidence: bool.isRequired,
  isOccupation: bool.isRequired,
};

function JoinLoyaltyProgramFormHandler({
  data,
  children,
  isCivility,
  isBirthDate,
  isCountryOfResidence,
  isOccupation,
}) {
  const history = useMyaccHistory();

  const [submitError, setSubmitError] = useState(false);
  const [submitSbmIdentity] = useMutation(IdentitySendMutation);
  const [submitSbmEnrollment] = useMutation(EnrollmentMutation);

  const { t } = useTranslation('identity-form');
  const validationRequiredFieldMessage = t('validationRequiredFieldMessage');
  const maleFieldLabel = t('maleFieldLabel');
  const femaleFieldLabel = t('femaleFieldLabel');

  const genderList = useMemo(
    () => [
      { value: 'female', label: femaleFieldLabel },
      { value: 'male', label: maleFieldLabel },
    ],
    [femaleFieldLabel, maleFieldLabel]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...(isCivility && {
          gender: Yup.string().required(validationRequiredFieldMessage),
        }),
        ...(isBirthDate && {
          birthDate: Yup.string().required(validationRequiredFieldMessage),
        }),
        ...(isCountryOfResidence && {
          countryOfResidence: Yup.string().required(
            validationRequiredFieldMessage
          ),
        }),
        terms_conditions: Yup.bool().oneOf(
          [true],
          validationRequiredFieldMessage
        ),
        ...(isOccupation && {
          occupation: Yup.string().required(validationRequiredFieldMessage),
        }),
      }),
    [
      isBirthDate,
      isCivility,
      isCountryOfResidence,
      isOccupation,
      validationRequiredFieldMessage,
    ]
  );

  const initialValues = {
    ...(isCivility && { gender: '' }),
    ...(isBirthDate && { birthDate: '' }),
    ...(isCountryOfResidence && { countryOfResidence: '' }),
    ...(isOccupation && { occupation: '' }),
    terms_conditions: false,
  };

  const handleSubmit = (
    { gender, birthDate, countryOfResidence, terms_conditions, occupation },
    { setSubmitting }
  ) => {
    const submitSbmIdentityFields = {
      firstName: data.firstName,
      birthName: data.birthName,
      middleName: data.middleName,
      marriedName: data.marriedName,
      gender: data?.gender || gender,
      birthDate: data?.birthDate || birthDate,
      countryOfResidence: data?.countryOfResidence || countryOfResidence,
      nationalities: data.nationalities,
      occupation,
    };

    const submitSbmEnrollmentFields = {
      terms_conditions: terms_conditions,
    };

    submitSbmIdentity({
      variables: { value: submitSbmIdentityFields },
    })
      .then(({ data: { updateSbmIdentity } }) => {
        if (updateSbmIdentity) {
          submitSbmEnrollment({
            variables: { value: submitSbmEnrollmentFields },
          })
            .then(({ data: { updateSbmEnrollment } }) => {
              setSubmitting(false);

              if (updateSbmEnrollment) {
                gtmPushEvent(gtmLoyaltyProgramRegistrationParams);

                history.push('/');
              }
            })
            .catch((error) => {
              console.warn(error);
              setSubmitError(true);
            });
        }
      })
      .catch((error) => {
        console.warn(error);
        setSubmitError(true);
      });
  };

  return children({
    genderList,
    validationSchema,
    initialValues,
    handleSubmit,
    submitError,
  });
}

JoinLoyaltyProgramFormHandler.propTypes = propTypes;

export default flowRight(
  withErrorBoundary,
  withRouter
)(JoinLoyaltyProgramFormHandler);
