/**
 * Function scrollLock for modals.
 * Run "scrollLock(true)" to disable or "scrollLock(false)" to enable screen scroll.
 * @param status
 */
const scrollLock = (status) => {
  const html = document.documentElement;

  if (status) {
    html.style.top = '-' + window.pageYOffset + 'px';
    html.style.position = 'fixed';
    html.style.width = '100%';
    html.style.overflowY = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  } else {
    const posTarget = html.style.top.replace(/[-A-Za-z]+/g, '');

    document.body.style.position = '';
    html.style.position = '';
    html.style.width = '';
    html.style.top = '';
    html.style.overflowY = '';
    document.body.style.width = '';
    window.scrollTo(0, posTarget);
  }
};

export default scrollLock;
