import { useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { array, object } from 'prop-types';

import usePrevious from 'common/hooks/usePrevious';
import gtmPushEvent from 'common/utils/gtmPushEvent';

const propTypes = {
  values: object.isRequired,
  contactChannelList: array.isRequired,
};

const GtmPreferencesDataHandler = ({ values, contactChannelList }) => {
  const prevValues = usePrevious(values);

  const contactChannelLabels = useMemo(
    () => contactChannelList.map(({ name }) => name),
    [contactChannelList]
  );

  useEffect(() => {
    if (prevValues && !isEqual(values, prevValues)) {
      if (values.language !== prevValues.language) {
        gtmPushEvent({
          event: 'change_preferred_language',
          value: values.language,
        });
      }

      contactChannelLabels.forEach((channelName) => {
        if (values[channelName] && !prevValues[channelName]) {
          gtmPushEvent({
            event: 'change_preferred_contact_channel',
            value: channelName,
          });
        }
      });

      values.experiences.forEach((exp) => {
        if (!prevValues.experiences.includes(exp)) {
          gtmPushEvent({
            event: 'change_favourite_experiences',
            value: exp,
          });
        }
      });

      if (values.frequency !== prevValues.frequency) {
        gtmPushEvent({
          event: 'change_frequency_of_visits',
          value: values.frequency,
        });
      }

      values.season.forEach((ssn) => {
        if (!prevValues.season.includes(ssn)) {
          gtmPushEvent({
            event: 'change_favourite_seasons',
            value: ssn,
          });
        }
      });
    }
  }, [values, prevValues, contactChannelLabels]);

  return null;
};

GtmPreferencesDataHandler.propTypes = propTypes;

export default GtmPreferencesDataHandler;
