import React from 'react';
import { string, node, func } from 'prop-types';
import classNames from 'classnames/bind';

import MyAccLink from '~/components/MyAccLink';

import styles from './PushRightColTitle.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  children: node,
  url: string,
  onClick: func,
};

const defaultProps = {
  className: null,
  children: null,
  onClick: null,
  url: null,
};

const PushRightColTitle = ({
  className,
  children,
  url,
  onClick,
  ...attributes
}) => {
  return (
    <h2 className={cx('root')} {...attributes}>
      {url ? (
        <MyAccLink className={cx('title-link')} to={url} onClick={onClick}>
          {children}
        </MyAccLink>
      ) : (
        children
      )}
    </h2>
  );
};

PushRightColTitle.propTypes = propTypes;
PushRightColTitle.defaultProps = defaultProps;

export default PushRightColTitle;
