import React from 'react';
import classNames from 'classnames/bind';

import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';
import IconContainer from 'common/components/IconContainer';

import styles from './ResetButton.module.scss';

const cx = classNames.bind(styles);

const ResetButton = ({ resetDates }) => {
  return (
    <button className={cx('root')} onClick={resetDates} type="button">
      <IconContainer icon={IconClose} className={cx('icon')} />
    </button>
  );
};

export default ResetButton;
