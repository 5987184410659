import React, { useMemo } from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './DiagnosticAssistant.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  myIdStatus: string.isRequired,
  apiLogStatus: bool.isRequired,
  asyncMachineStatus: string.isRequired,
  loyaltyMachineStatus: string.isRequired,
  className: string,
};

const defaultProps = {
  className: null,
};

// Colors token from MessageByStatusColor
const statusColor = ['red', 'green', 'orange'];
const statusColorNormalizer = (status) =>
  statusColor.includes(status) ? status : '';

const DiagnosticAssistant = ({
  className,
  myIdStatus,
  apiLogStatus,
  asyncMachineStatus,
  loyaltyMachineStatus,
}) => {
  const myIdStatusColor = useMemo(() => statusColorNormalizer(myIdStatus), [
    myIdStatus,
  ]);
  const apiLogStatusColor = useMemo(() => (apiLogStatus ? 'red' : 'green'), [
    apiLogStatus,
  ]);
  const asyncMachineStatusColor = useMemo(
    () => statusColorNormalizer(asyncMachineStatus),
    [asyncMachineStatus]
  );
  const loyaltyMachineStatusColor = useMemo(
    () => statusColorNormalizer(loyaltyMachineStatus),
    [loyaltyMachineStatus]
  );

  return (
    <div className={cx('root', className)}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 971.8 266.6"
      >
        <path
          className={cx('block', loyaltyMachineStatusColor)}
          d=" M402.8,109.2h-184c-5.9,0-10.7-4.8-10.7-10.7V13.2c0-5.9,4.8-10.7,10.7-10.7h184c5.9,0,10.7,4.8,10.7,10.7v85.3 C413.5,104.4,408.7,109.2,402.8,109.2z"
        />
        <path
          className={cx('block', myIdStatusColor)}
          d="M197.2,263.7h-184c-5.9,0-10.7-4.8-10.7-10.7v-85.3c0-5.9,4.8-10.7,10.7-10.7h184c5.9,0,10.7,4.8,10.7,10.7v85.3 C207.9,258.9,203.1,263.7,197.2,263.7z"
        />
        <path
          className={cx('block', asyncMachineStatusColor)}
          d="M608.2,263.7h-184c-5.9,0-10.7-4.8-10.7-10.7v-85.3c0-5.9,4.8-10.7,10.7-10.7h184c5.9,0,10.7,4.8,10.7,10.7v85.3 C619,258.9,614.2,263.7,608.2,263.7z"
        />
        <path
          className={cx('block', apiLogStatusColor)}
          d="M957,263.7H773c-5.9,0-10.7-4.8-10.7-10.7v-85.3c0-5.9,4.8-10.7,10.7-10.7h184c5.9,0,10.7,4.8,10.7,10.7v85.3 C967.7,258.9,962.9,263.7,957,263.7z"
        />
        <line className={cx('line')} x1="105.3" y1="55" x2="199.3" y2="55" />
        <polygon points="206.4,55.8 194.6,48 194.6,63.7" />
        <line className={cx('line')} x1="105.1" y1="55" x2="105.1" y2="147.8" />
        <polygon points="105.1,154.9 113,143.1 97.3,143.1" />
        <line
          className={cx('line')}
          x1="631.1"
          y1="210.3"
          x2="753"
          y2="210.3"
        />
        <polygon points="760.1,210.3 748.3,218.2 748.3,202.4" />
        <polygon points="621.4,210.3 633.2,218.2 633.2,202.4" />
        <line
          className={cx('line')}
          x1="220"
          y1="210.3"
          x2="401.4"
          y2="210.3"
        />
        <polygon points="411.3,210.3 399.5,218.2 399.5,202.4" />
        <polygon points="210.2,210.3 222,218.2 222,202.4" />
        <line className={cx('line')} x1="516.3" y1="55" x2="422.3" y2="55" />
        <polygon points="415.2,55.2 427,47.3 427,63" />
        <line className={cx('line')} x1="516.4" y1="55" x2="516.4" y2="147.2" />
        <polygon points="516.4,154.3 508.5,142.5 524.3,142.5" />
        <text
          className={cx('block-label')}
          transform="matrix(1 0 0 1 313.7639 51.4254)"
        >
          <tspan x="0" textAnchor="middle">
            Loyalty
          </tspan>
          <tspan x="0" dy="22" textAnchor="middle">
            Machine
          </tspan>
        </text>
        <text
          className={cx('block-label')}
          transform="matrix(1 0 0 1 105.7839 208.3053)"
        >
          <tspan x="0" textAnchor="middle">
            MyID
          </tspan>
          <tspan x="0" dy="22" textAnchor="middle">
            Machine
          </tspan>
        </text>
        <text
          className={cx('block-label')}
          transform="matrix(1 0 0 1 517.844 208.3053)"
        >
          <tspan x="0" textAnchor="middle">
            ASync
          </tspan>
          <tspan x="0" dy="22" textAnchor="middle">
            Machine
          </tspan>
        </text>
        <text
          className={cx('block-label')}
          transform="matrix(1 0 0 1 866.3235 208.3053)"
          dy="0"
        >
          <tspan x="0" textAnchor="middle">
            API
          </tspan>
          <tspan x="0" dy="22" textAnchor="middle">
            Customer
          </tspan>
        </text>
      </svg>
    </div>
  );
};

DiagnosticAssistant.propTypes = propTypes;
DiagnosticAssistant.defaultProps = defaultProps;

export default DiagnosticAssistant;
