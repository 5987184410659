import React, { useRef, useMemo } from 'react';
import { array, func, object } from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import uniqueId from 'lodash/uniqueId';

import MultiField from 'common/components/FormComponents/MultiField';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';
import Input from 'common/components/FormComponents/Input';
import Select from 'common/components/FormComponents/Select';
import { ReactComponent as IconEmail } from 'common/media/icons/email-outline.icon.svg';

import PanelSection from '~/components/PanelSection';
import { EMAIL_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';

import useOnNewFieldAdd from '../../hooks/useOnNewFieldAdd';
import styles from './EmailFields.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  emailList: array.isRequired,
  emailCategoryList: array.isRequired,
  markDeletedFields: func.isRequired,
  values: object.isRequired,
};

const EmailFields = ({
  emailList,
  emailCategoryList,
  onEmailAdd,
  onEmailRemove,
  setFieldValue,
  values,
  markDeletedFields,
}) => {
  const formElementRef = useRef();

  const { onNewFieldAdd, counter, setCounter } = useOnNewFieldAdd(
    formElementRef,
    emailList.length
  );

  const { t } = useTranslation('contacts-form');
  const emailFieldLabel = t('emailFieldLabel');
  const categoryFieldLabel = t('categoryFieldLabel');

  const emailCategoryListNoLogin = useMemo(
    () => emailCategoryList.filter((item) => item.value !== 'LO'),
    [emailCategoryList]
  );

  return (
    <PanelSection icon={IconEmail}>
      {emailList.map(({ isMutable, isDeletable, name, catName }, index) => {
        const onIncrease =
          index === emailList.length - 1
            ? () => {
                const id = uniqueId();
                setFieldValue(`email_${id}`, '');
                setFieldValue(`email_${id}_cat`, EMAIL_DEFAULT_CATEGORY);
                onEmailAdd(id);
                onNewFieldAdd();
                setCounter(emailList.length);
              }
            : null;

        const onRemove = isDeletable
          ? () => {
              markDeletedFields({ setFieldValue, values, name });
              onEmailRemove(name);
            }
          : null;

        return (
          <MultiField
            key={index}
            formElementRef={formElementRef}
            rootClassName={cx({
              'shadow-highlighted': index === counter,
            })}
            mainField={
              <Field
                name={name}
                component={Input}
                placeholder={`${emailFieldLabel} ${
                  emailList.length <= 1 ? '' : index + 1
                }`}
                required={!isDeletable}
                maxLength={128}
                disabled={!isMutable}
              />
            }
            secondaryField={
              <Field
                name={catName}
                component={Select}
                options={
                  index === 0 ? emailCategoryList : emailCategoryListNoLogin
                }
                placeholder={categoryFieldLabel}
                disabled={!isMutable}
              />
            }
            cta={<MultiFieldCta onIncrease={onIncrease} onRemove={onRemove} />}
          />
        );
      })}
    </PanelSection>
  );
};
EmailFields.propTypes = propTypes;

export default EmailFields;
