import React from 'react';
import { shape, string, bool, func } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Radio.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  id: string.isRequired,
  label: string.isRequired,
  buttonView: bool,
  styledView: bool,
  smallStyledView: bool,
  onClick: func,
  className: string,
  value: string,
  disabled: bool,
};

const defaultProps = {
  buttonView: false,
  styledView: true,
  smallStyledView: false,
  onClick: null,
  className: null,
  value: null,
  disabled: false,
};

const Radio = ({
  field,
  id,
  label,
  buttonView,
  styledView,
  smallStyledView,
  onClick,
  className,
  value,
  disabled,
}) => (
  <div
    className={cx(
      'root',
      {
        'button-view': buttonView,
        'styled-view': !(buttonView || smallStyledView) && styledView,
        'small-styled-view': smallStyledView,
        disabled: disabled,
      },
      className
    )}
  >
    <input
      {...field}
      id={`${field.name}-${id}`}
      value={!!value ? value : id}
      type="radio"
      checked={field.value === id}
      className={cx('input')}
      onClick={onClick}
      disabled={disabled}
    />
    <label className={cx('label')} htmlFor={`${field.name}-${id}`}>
      {label}
    </label>
  </div>
);

Radio.propTypes = propTypes;
Radio.defaultProps = defaultProps;

export default Radio;
