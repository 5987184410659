// Add dot in interger number as thousands separator
// 5000 transform to 5 000
// added Non-breaking space "\u00A0"
const separator = '\u00A0';

export default function numberWithSpaces(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
}

export { separator };
