import { useMemo } from 'react';

import { UNDEFINED } from '../constants';

export function useLoyalty(
  userLoyalty,
  loyaltyStatus,
  loadingUserData,
  isMyAC
) {
  const loyaltyId = useMemo(() => {
    if (userLoyalty && !loadingUserData && isMyAC) {
      return userLoyalty.userFid;
    }

    return UNDEFINED;
  }, [userLoyalty, loadingUserData, isMyAC]);

  const loyaltyStatusNormalized = useMemo(() => {
    if (loyaltyStatus && !loadingUserData && isMyAC) {
      return loyaltyStatus.toString();
    }

    if (!loyaltyStatus && !loadingUserData && isMyAC) {
      return '1';
    }

    return UNDEFINED;
  }, [loyaltyStatus, loadingUserData, isMyAC]);

  return { loyaltyId, loyaltyStatusNormalized };
}
