import React from 'react';
import { node, object, bool, oneOf, array, func } from 'prop-types';
import moment from 'moment';
import classNames from 'classnames/bind';

import { withDatesContext } from 'common/context/DatesContext';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconLeftArrow } from 'common/media/icons/left-arrow.icon.svg';
import { ReactComponent as IconRightArrow } from 'common/media/icons/right-arrow.icon.svg';

import styles from './DateControls.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node.isRequired,
  dateType: oneOf(['fromDate', 'toDate']).isRequired,
  dates: object.isRequired,
  availableOnly: bool,
  calendarData: array.isRequired,
  withBorder: bool,
  onClick: func,
};

const defaultProps = {
  withBorder: false,
  onClick: () => {},
  availableOnly: false,
};

function DateControls({
  children,
  dateType,
  dates,
  calendarData,
  withBorder,
  onClick,
  isActive,
  availableOnly,
}) {
  const fromDate = dateType === 'fromDate';
  const toDate = dateType === 'toDate';
  const initialFromDate = moment().startOf('day');
  const isFromDateDecreasable = moment(dates.fromDate).isAfter(initialFromDate);
  const isToDateDecreasable = moment(dates.toDate).isAfter(
    dates.fromDate.clone().add(1, 'days')
  );
  let isPrevFromDayAvailable = true;
  let isNextFromDayAvailable = true;
  let isPrevToDayAvailable = true;
  let isNextToDayAvailable = true;

  if (calendarData.length) {
    const nextFromDay = findNextDay(calendarData, dates.fromDate);
    const prevFromDay = findPrevDay(calendarData, dates.fromDate);
    const nextToDay = findNextDay(calendarData, dates.toDate);
    const prevToDay = findPrevDay(calendarData, dates.toDate);
    const toDay = getDateInfo(calendarData, dates.toDate);

    if (nextFromDay !== undefined) {
      isNextFromDayAvailable = nextFromDay.available;
    }

    if (prevFromDay !== undefined) {
      isPrevFromDayAvailable = prevFromDay.available;
    }

    if (nextToDay !== undefined) {
      // if current day is available, next one can be selected as checkout only
      isNextToDayAvailable = toDay.available;
    }

    if (prevToDay !== undefined) {
      isPrevToDayAvailable = prevToDay.available;
    }
  } else if (availableOnly) {
    // disable buttons if hotel choosen but we have no available days
    isPrevFromDayAvailable = false;
    isNextFromDayAvailable = false;
    isPrevToDayAvailable = false;
    isNextToDayAvailable = false;
  }

  const onDecreaseHandler = () => {
    if (fromDate && isFromDateDecreasable) {
      dates.setFromDate(moment(dates.fromDate).subtract(1, 'days'));
    }
    if (toDate && isToDateDecreasable) {
      dates.setToDate(moment(dates.toDate).subtract(1, 'days'));
    }
    onClick && onClick();
  };

  const onIncreaseHandler = () => {
    if (fromDate && isNextFromDayAvailable) {
      dates.setFromDate(moment(dates.fromDate).add(1, 'days'));

      if (moment(moment(dates.fromDate).add(1, 'days')).isSame(dates.toDate)) {
        dates.setToDate(moment(dates.toDate).add(1, 'days'));
      }
    }
    if (toDate) {
      dates.setToDate(moment(dates.toDate).add(1, 'days'));
    }
    onClick && onClick();
  };

  const isLeftButtonDisabled = fromDate
    ? !(isFromDateDecreasable && isPrevFromDayAvailable)
    : !(isToDateDecreasable && isPrevToDayAvailable);

  const isRightButtonDisabled = fromDate
    ? !isNextFromDayAvailable
    : !isNextToDayAvailable;

  return (
    <div className={cx('root', { 'is-active': isActive })}>
      <button
        type="button"
        className={cx('button')}
        onClick={onDecreaseHandler}
        disabled={isLeftButtonDisabled}
      >
        <IconContainer icon={IconLeftArrow} className={cx('icon')} />
      </button>
      {children}
      <button
        type="button"
        className={cx('button', { 'with-border': withBorder })}
        onClick={onIncreaseHandler}
        disabled={isRightButtonDisabled}
      >
        <IconContainer icon={IconRightArrow} className={cx('icon')} />
      </button>
    </div>
  );
}

function findNextDay(daysArr, date) {
  return daysArr.find(
    (item) =>
      item.date === moment(date.clone()).add(1, 'days').format('YYYY-MM-DD')
  );
}

function findPrevDay(daysArr, date) {
  return daysArr.find(
    (item) =>
      item.date ===
      moment(date.clone()).subtract(1, 'days').format('YYYY-MM-DD')
  );
}

function getDateInfo(daysArr, date) {
  return daysArr.find((item) => item.date === date.format('YYYY-MM-DD'));
}

DateControls.propTypes = propTypes;
DateControls.defaultProps = defaultProps;

export default withDatesContext(DateControls);
