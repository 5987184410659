import { useMemo } from 'react';

import newArray from 'common/utils/newArray';
import padStartZeros from 'common/utils/padStartZeros';

import { getDay, daysInMonth } from '../utils/calendarParams';

export default function useDays({
  lastAvailableDate,
  selectedMonth,
  selectedYear,
}) {
  const lastAvailableDay = useMemo(
    () =>
      lastAvailableDate &&
      `${selectedYear}-${padStartZeros(selectedMonth)}` ===
        lastAvailableDate.slice(0, 7)
        ? getDay(lastAvailableDate)
        : daysInMonth(selectedMonth, selectedYear),
    [lastAvailableDate, selectedMonth, selectedYear]
  );

  const daysList = useMemo(
    () => newArray(lastAvailableDay).map((_, index) => 1 + index),
    [lastAvailableDay]
  );

  return { daysList };
}
