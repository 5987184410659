import { node, func, oneOfType } from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import { useEffectOnMount, useToggle } from 'common/hooks';

const propTypes = {
  children: oneOfType([func, node]).isRequired,
};

function WindowResizeListenerHandler({ children }) {
  const [windowResized, toggleWindowResized] = useToggle();
  const [windowResizedHandler] = useDebouncedCallback(() => {
    toggleWindowResized();
  }, 300);

  useEffectOnMount(() => {
    window.addEventListener('resize', windowResizedHandler);
    return () => {
      window.removeEventListener('resize', windowResizedHandler);
    };
  });

  return children({
    windowResized,
  });
}

WindowResizeListenerHandler.propTypes = propTypes;

export default WindowResizeListenerHandler;
