import React from 'react';
import { shape, object, bool, array, string, number, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';

import styles from './Select.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({
    errors: object.isRequired,
    touched: object.isRequired,
  }).isRequired,
  options: array.isRequired,
  placeholder: string,
  required: bool,
  classNameWrapper: string,
  customKeys: array,
  disabled: bool,
  valuesArrayName: string,
  valuesArrayNameIndex: number,
  isCustomChange: bool,
  theme: oneOf(['block-theme']),
};

const defaultProps = {
  placeholder: null,
  required: false,
  classNameWrapper: null,
  customKeys: null,
  disabled: false,
  valuesArrayName: null,
  valuesArrayNameIndex: null,
  isCustomChange: false,
  theme: null,
};

const Select = ({
  field,
  field: { name },
  form: { values, setFieldValue, handleChange, errors, touched },
  options,
  placeholder,
  required,
  classNameWrapper,
  customKeys,
  disabled,
  valuesArrayName,
  valuesArrayNameIndex,
  isCustomChange,
  theme,
}) => {
  const renderCustomOption = (option, index) => (
    <option value={option[customKeys[0]]} key={index}>
      {option[customKeys[1]]}
    </option>
  );

  const renderOption = ({ value, label }, index) => (
    <option value={value} key={index}>
      {label}
    </option>
  );

  const changeHandler = (e) => {
    handleChange(e);

    let value = e.target.value || '';
    if (isCustomChange && values[valuesArrayName].includes(value)) {
      setFieldValue(name, '');
    } else {
      setFieldValue(name, value);
    }
  };

  return (
    <div className={cx('root', classNameWrapper, theme)}>
      {placeholder &&
        (!!values[name] ||
          (!!values[valuesArrayName] &&
            values[valuesArrayName][valuesArrayNameIndex] !== '')) && (
          <label htmlFor={name} className={cx('label')}>
            {required ? `${placeholder}*` : placeholder}
          </label>
        )}
      <select
        {...field}
        disabled={disabled}
        name={name}
        className={cx('select', {
          'select-error': errors[name] && touched[name] && values[name] === '',
          touched:
            !!values[name] ||
            (!!values[valuesArrayName] &&
              values[valuesArrayName][valuesArrayNameIndex]),
        })}
        onChange={changeHandler}
      >
        <option value="" disabled>
          {placeholder && (required ? `${placeholder}*` : placeholder)}
        </option>
        {customKeys
          ? options.map(renderCustomOption)
          : options.map(renderOption)}
      </select>
      <span className={cx('arrow', { 'arrow-disabled': disabled })} />
      <ErrorMessageCustom name={name}>
        {({ msg }) => <FieldError>{msg}</FieldError>}
      </ErrorMessageCustom>
    </div>
  );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
