import React from 'react';
import classNames from 'classnames/bind';
import { node, object, bool, func, string } from 'prop-types';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';

import styles from './ReadMore.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node.isRequired,
  labels: object.isRequired,
  isExpanded: bool.isRequired,
  isShowMoreEnabled: bool.isRequired,
  toggleExpanded: func.isRequired,
  colorBg: string,
};

const defaultProps = {
  colorBg: 'black',
};

const ReadMore = ({
  labels: { readLess, readMore },
  children,
  isExpanded,
  isShowMoreEnabled,
  toggleExpanded,
  colorBg,
}) => {
  return (
    <div
      className={cx('text-wrap', colorBg, {
        'is-expanded': isExpanded || !isShowMoreEnabled,
      })}
    >
      <div className={cx('text-wrap-animated', 'js-text-wrap-animated')}>
        <div className={cx('child-node')}>{children}</div>
      </div>

      {isShowMoreEnabled && (
        <button className={cx('toggle')} onClick={toggleExpanded}>
          {isExpanded ? (
            <span>
              <IconContainer
                className={cx('read-more-close-icon')}
                icon={IconClose}
              />{' '}
              <span className={cx('btn-text')}>{readLess}</span>
            </span>
          ) : (
            <span>{readMore}</span>
          )}
        </button>
      )}
    </div>
  );
};

ReadMore.propTypes = propTypes;
ReadMore.defaultProps = defaultProps;

export default ReadMore;
