import React, { useState, useMemo } from 'react';
import { shape, string } from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import InformationPanel from 'common/components/InformationPanel';
import ContentLoader from 'common/components/ContentLoader';
import Container from 'common/components/Container';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUserContext } from '~/context/UserContext';
import FormPrivacy from '~/scenes/FormPrivacy';
import PanelContainer from '~/components/PanelContainer';
import ErrorFallback from '~/components/ErrorFallback';
import ButtonBack from '~/components/ButtonBack';
import BlockTitle from '~/components/BlockTitle';
import GTM from '~/components/GTM';

import DeleteUser from './components/DeleteUser';
import styles from './EditPrivacyInfo.module.scss';

const cx = classNames.bind(styles);

const EditPrivacyInfoDataQuery = gql`
  query EditPrivacyInfoData($id: String!) {
    getCustomerProfile(id: $id) {
      sbmGdpr(id: $id) {
        id
        value
        source
        date
      }
    }
  }
`;

const PrivacyDeleteAccountDataMutation = gql`
  mutation updateCustomerSbmDeleteAccountData(
    $id: String!
    $value: SbmDeleteAccountDataInput!
  ) {
    updateCustomerSbmDeleteAccountData(id: $id, value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

function EditPrivacyInfo({
  match: {
    params: { id },
  },
}) {
  const { t } = useTranslation(['edit-account', 'common']);
  const editPrivacyInfoPageTitle = t('editPrivacyInfoPageTitle');
  const commonErrorMessage = t('common:serviceErrorMessage');

  const { language } = useLocalisationContext();
  const { isFailSafeMode } = useUserContext();

  const [deletionSuccess, setDeletionSuccess] = useState(false);
  const [deletionError, setDeletionError] = useState(false);
  const [deletionErrorMessage, setDeletionErrorMessage] = useState('');

  const [mutate, { loading: deletionLoading }] = useMutation(
    PrivacyDeleteAccountDataMutation
  );

  const handleDeleteAccount = () => {
    setDeletionSuccess(false);
    setDeletionError(false);
    setDeletionErrorMessage('');

    mutate({
      variables: { id, value: { deleteAccount: true } },
    })
      .then(({ data: { updateCustomerSbmDeleteAccountData } }) => {
        if (updateCustomerSbmDeleteAccountData) {
          const { result, messages } = updateCustomerSbmDeleteAccountData;

          if (result) {
            setDeletionSuccess(true);
          } else {
            setDeletionError(true);
            setDeletionErrorMessage(messages?.error?.[0] || commonErrorMessage);
          }
        } else {
          setDeletionError(true);
          setDeletionErrorMessage(commonErrorMessage);
        }
      })
      .catch((error) => {
        setDeletionError(true);
        setDeletionErrorMessage(commonErrorMessage);
        console.warn(error);
      });
  };

  const { data, loading, error } = useQuery(EditPrivacyInfoDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const backLink = useMemo(() => `/${language}/user/${id}`, [id, language]);

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{editPrivacyInfoPageTitle}</title>
      </Helmet>

      {loading && !data && <PageLoader />}
      {!loading && data && !error && (
        <>
          <div className={cx('content-wrapper')}>
            <ButtonBack to={deletionSuccess ? `/${language}` : backLink} />
            <BlockTitle
              title={editPrivacyInfoPageTitle}
              className={cx('page-title')}
            />
            {deletionSuccess && (
              <InformationPanel withIcon successMode>
                {t('privacyDeletionSuccessMessage')}
              </InformationPanel>
            )}
            {deletionError && (
              <InformationPanel withIcon errorMode>
                {deletionErrorMessage}
              </InformationPanel>
            )}
            {deletionLoading && <ContentLoader />}
            {data.getCustomerProfile && !deletionLoading && !deletionSuccess ? (
              <FormPrivacy
                isMyCs
                userHash={id}
                data={data.getCustomerProfile.sbmGdpr}
                redirectTo={backLink}
              />
            ) : (
              <ErrorFallback mode={'friendly'} />
            )}
          </div>
          {!isFailSafeMode && !deletionSuccess && (
            <PanelContainer className={cx('delete-user-container')}>
              <DeleteUser handleDeleteAccount={handleDeleteAccount} />
            </PanelContainer>
          )}
        </>
      )}

      <GTM isMyCS />
    </Container>
  );
}

EditPrivacyInfo.propTypes = propTypes;

export default EditPrivacyInfo;
