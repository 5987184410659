import React, { useRef, useMemo } from 'react';
import { shape, string } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { Formik, Form, Field, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Container from 'common/components/Container';
import Input from 'common/components/FormComponents/Input';
import Select from 'common/components/FormComponents/Select';
import InformationPanel from 'common/components/InformationPanel';
import MultiField from 'common/components/FormComponents/MultiField';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import useOnNewFieldAdd from '~/scenes/FormContacts/hooks/useOnNewFieldAdd';
import FormButtons from '~/components/FormButtons';
import BlockTitle from '~/components/BlockTitle';
import ButtonBack from '~/components/ButtonBack';
import GTM from '~/components/GTM';

import EditForeignIdsFormHandler from './containers/EditForeignIdsFormHandler';
import EditForeignIdsObserver from './containers/EditForeignIdsObserver';
import styles from './EditForeignIds.module.scss';

const cx = classNames.bind(styles);

const EditForeignIdsDataQuery = gql`
  query EditForeignIdsData($id: String!) {
    getCustomerProfile(id: $id) {
      foreignIds {
        sourceName
        sourceKey
      }
    }
  }
`;

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

function EditForeignIds({
  match: {
    params: { id },
  },
}) {
  const formElementRef = useRef();

  const { language } = useLocalisationContext();

  const { onNewFieldAdd, counter, setCounter } =
    useOnNewFieldAdd(formElementRef);

  const { t } = useTranslation(['foreignids-form', 'common']);
  const editForeignIdsPageTitle = t('editForeignIdsPageTitle');
  const foreignIdsSourceKeyLabel = t('foreignIdsSourceKeyLabel');
  const foreignIdsSourceNameLabel = t('foreignIdsSourceNameLabel');
  const messageError = t('messageError');
  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  const { data, loading, error, refetch } = useQuery(EditForeignIdsDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const backLink = useMemo(() => `/${language}/user/${id}`, [id, language]);

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{editForeignIdsPageTitle}</title>
      </Helmet>

      {loading && !data && <PageLoader />}
      {!loading && data && !error && (
        <div className={cx('content-wrapper')}>
          <ButtonBack to={backLink} />
          <BlockTitle title={editForeignIdsPageTitle} className={cx('title')} />
          <EditForeignIdsFormHandler
            userHash={id}
            data={data.getCustomerProfile?.foreignIds}
            refetch={refetch}
          >
            {({
              foreignIdsOptions,
              initialValues,
              handleSubmit,
              submitError,
              submitSuccess,
              setSubmitSuccess,
              onFormChange,
              setFormValues,
              validationSchema,
            }) => (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({ values, isSubmitting }) => (
                  <Form onChange={onFormChange} className={cx('form')}>
                    <EditForeignIdsObserver
                      setFormValues={setFormValues}
                      values={values}
                    />
                    <FieldArray name="foreignIds">
                      {({ remove, push, replace }) =>
                        values.foreignIds.length > 0 &&
                        values.foreignIds.map((_, index) => (
                          <MultiField
                            key={index}
                            rootClassName={cx('multifield-item', {
                              'shadow-highlighted': index === counter,
                            })}
                            formElementRef={formElementRef}
                            mainField={
                              <Field
                                isFieldEmpty={
                                  values.foreignIds[index].sourceKey === ''
                                }
                                name={`foreignIds.${index}.sourceKey`}
                                component={Input}
                                placeholder={foreignIdsSourceKeyLabel}
                                maxLength={128}
                              />
                            }
                            secondaryField={
                              <Field
                                name={`foreignIds.${index}.sourceName`}
                                component={Select}
                                options={foreignIdsOptions}
                                placeholder={foreignIdsSourceNameLabel}
                              />
                            }
                            cta={
                              <MultiFieldCta
                                onIncrease={
                                  values.foreignIds.length === index + 1
                                    ? () => {
                                        setSubmitSuccess(false);
                                        push({
                                          sourceKey: '',
                                          sourceName: '',
                                        });
                                        onNewFieldAdd();
                                        setCounter(values.foreignIds.length);
                                      }
                                    : null
                                }
                                onRemove={
                                  values.foreignIds.length === 1
                                    ? () => {
                                        setSubmitSuccess(false);
                                        replace(0, {
                                          sourceKey: '',
                                          sourceName: '',
                                        });
                                      }
                                    : () => {
                                        setSubmitSuccess(false);
                                        remove(index);
                                      }
                                }
                              />
                            }
                          />
                        ))
                      }
                    </FieldArray>
                    {submitError && (
                      <InformationPanel withIcon errorMode>
                        {messageError}
                      </InformationPanel>
                    )}
                    <FormButtons
                      isSubmitting={isSubmitting}
                      to={backLink}
                      className={cx('form-buttons')}
                      isSaved={submitSuccess}
                      saveButtonLabel={saveButtonLabel}
                      savedButtonLabel={savedButtonLabel}
                      cancelButtonLabel={cancelButtonLabel}
                    />
                  </Form>
                )}
              </Formik>
            )}
          </EditForeignIdsFormHandler>
        </div>
      )}

      <GTM isMyCS />
    </Container>
  );
}

EditForeignIds.propTypes = propTypes;

export default EditForeignIds;
