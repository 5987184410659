import { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { useMutation } from '@apollo/client';

import {
  UserAddToWishlistMutation,
  UserRemoveFromWishlistMutation,
} from 'common/queries';

import { useUserContext } from '~/context/UserContext';

const propTypes = {
  id: string.isRequired,
  children: func.isRequired,
};

const WishlistButtonContainer = ({ id, children }) => {
  const [addToWishlist] = useMutation(UserAddToWishlistMutation);
  const [removeFromWishlist] = useMutation(UserRemoveFromWishlistMutation);

  const { wishlistIds, setWishlistIds, setWishlistIdsPendingToUpdate } =
    useUserContext();

  const [isIncludedInWishlist, setIncludedInWishlist] = useState(
    wishlistIds?.items.includes(Number(id)) || false
  );

  useEffect(() => {
    setIncludedInWishlist(wishlistIds.items.includes(Number(id)));
  }, [wishlistIds, id]);

  const handleWishlistClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    setWishlistIdsPendingToUpdate(true);

    if (wishlistIds.items.includes(Number(id))) {
      removeFromWishlist({
        variables: { itemId: Number(id) },
      }).catch((error) => {
        console.error(error);
      });
      setWishlistIds({
        ...wishlistIds,
        items: [...wishlistIds.items].filter((listId) => listId !== Number(id)),
      });
      setIncludedInWishlist(false);
    } else {
      addToWishlist({
        variables: { itemId: Number(id) },
      }).catch((error) => {
        console.error(error);
      });
      setWishlistIds({
        ...wishlistIds,
        items: [...wishlistIds.items, Number(id)],
      });
      setIncludedInWishlist(true);
    }
  };

  return children({ isIncludedInWishlist, handleWishlistClick });
};

WishlistButtonContainer.propTypes = propTypes;

export default WishlistButtonContainer;
