import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useOnClickOutside } from 'common/hooks';

import { useUiContext } from 'common/context/UiContext';
import DayPickerRangeControllerWrapper from '~/components/DayPickerRangeControllerWrapper';

import ResetButton from '../ResetButton';
import styles from './DateRangePicker.module.scss';

const cx = classNames.bind(styles);

const DATE_FROM = 'dateFrom';
const DATE_TO = 'dateTo';

const dateToStr = (date) => {
  if (!date) return '';
  if (date && typeof date === 'string') {
    return date;
  }
  if (date && typeof date === 'object') {
    return date.format('YYYY-MM-DD');
  }
};

const DateRangePicker = ({
  fromDate,
  toDate,
  initialFromDate,
  initialToDate,
  onDatesChange,
  onDatesChangeMobileStartDate,
  onDatesChangeMobileEndDate,
  resetDates,
}) => {
  const [activeItemName, setActiveItemName] = useState(null);

  const { isDesktop } = useUiContext();

  const { t } = useTranslation('maintenance');
  const creationDateLabel = t('creationDateLabel');
  const startDateLabel = t('startDateLabel');
  const endDateLabel = t('endDateLabel');

  const closeDatePicker = () => setActiveItemName(null);

  const daypicker = useOnClickOutside(() => {
    if (!!activeItemName) {
      closeDatePicker();
    }
  });

  const toggleByName = (name) => {
    if (name && activeItemName !== name) {
      setActiveItemName(name);
    } else {
      closeDatePicker();
    }
  };

  const isStartActive = activeItemName === DATE_FROM;
  const isEndActive = activeItemName === DATE_TO;

  const dateRangeItems = [
    {
      label: startDateLabel,
      placeholder: startDateLabel,
    },
    {
      label: endDateLabel,
      placeholder: endDateLabel,
    },
  ];

  return (
    <div className={cx('root')}>
      <div className={cx('date-label')}>{creationDateLabel}</div>

      {isDesktop ? (
        <>
          <div className={cx('date-buttons-wrapper')}>
            <button
              type="button"
              onClick={() => toggleByName(DATE_FROM)}
              className={cx(
                {
                  active: activeItemName === DATE_FROM,
                },
                'date-button'
              )}
            >
              {fromDate ? fromDate.format('YYYY-MM-DD') : startDateLabel}
            </button>
            <span className={cx('button-spacer')}>-</span>
            <button
              type="button"
              onClick={() => toggleByName(DATE_TO)}
              className={cx(
                { active: activeItemName === DATE_TO },
                'date-button'
              )}
            >
              {toDate ? toDate.format('YYYY-MM-DD') : endDateLabel}
            </button>
            {fromDate && (
              <div className={cx('reset-button-wrapper')}>
                <ResetButton resetDates={resetDates} />
              </div>
            )}
          </div>
          <CSSTransition
            in={isStartActive || isEndActive}
            timeout={150}
            classNames={{
              enter: cx('transition-enter'),
              enterActive: cx('transition-enter-active'),
              enterDone: cx('transition-done-enter'),
              exit: cx('transition-exit'),
              exitActive: cx('transition-exit-active'),
            }}
            unmountOnExit
          >
            <div ref={daypicker} className={cx('daypicker')}>
              <DayPickerRangeControllerWrapper
                initialStartDate={fromDate ? fromDate : initialFromDate}
                initialEndDate={toDate ? toDate : initialToDate}
                numberOfMonths={2}
                onDatesChange={onDatesChange}
                onFocusChange={() => toggleByName(DATE_TO)}
                focusedInput={
                  activeItemName === DATE_FROM ? 'startDate' : 'endDate'
                }
                onDatesSelected={() => toggleByName()}
              />
            </div>
          </CSSTransition>
        </>
      ) : (
        <div className={cx('input-wrapper')}>
          {dateRangeItems.map(({ label, placeholder }, index) => (
            <div className={cx('input-container')} key={index}>
              <div className={cx('input-label')}>{label}</div>
              <input
                value={index === 0 ? dateToStr(fromDate) : dateToStr(toDate)}
                name={index === 0 ? DATE_FROM : DATE_TO}
                type="date"
                className={cx('input', {
                  'start-active': isStartActive && index === 0,
                  'end-active': isEndActive && index === 1,
                })}
                onChange={(e) => {
                  index === 0
                    ? onDatesChangeMobileStartDate(e.target.value)
                    : onDatesChangeMobileEndDate(e.target.value);
                }}
                disabled={index === 1 && !fromDate}
                max={moment().format('YYYY-MM-DD')}
                min={
                  index === 1 && fromDate ? fromDate.format('YYYY-MM-DD') : null
                }
                required={index === 1}
              />
            </div>
          ))}
          {fromDate && (
            <div className={cx('reset-button-wrapper')}>
              <ResetButton resetDates={resetDates} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
