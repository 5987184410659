import React, { useState, useEffect, useMemo } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { createUseContext } from 'common/lib/context';

import { BRIDGE_LSKEY } from 'common/constants';
import { useEffectOnMount } from 'common/hooks';
import {
  setCookiesCrossDomain,
  removeCookies,
  getCookies,
  cleanUpMyIDCookies,
} from 'common/utils/cookies';
import usePrevious from 'common/hooks/usePrevious';
import uuid from 'common/utils/uuid';

import { useOfferRedirect } from '~/hooks';
import { ROLE_TOP_DOG } from '~/constants';
import { useLocalisationContext } from '~/context/LocalisationContext';
import {
  useAuthContext,
  SIGN_IN_STATE,
  SIGNED_IN_STATE,
} from '~/context/AuthContext';
import SuspenseTemplate from '~/components/SuspenseTemplate';

import {
  WarmUpQuery,
  UserQuery,
  NotificationsDataQuery,
  WishlistIdsDataQuery,
} from './userContextQueries';

const fallbackProfile = {
  firstName: '',
  birthName: '',
  middleName: '',
  marriedName: '',
  gender: '',
  birthDate: '',
  countryOfResidence: '',
  nationalities: [],
};

const {
  Context: UserContext,
  ContextProvider: UserContextProvider,
  ContextConsumer: UserContextConsumer,
  useContext: useUserContext,
} = createUseContext((Provider) => ({ children }) => {
  const { userRole, signOut, authState, offerRedirectParams } =
    useAuthContext();
  const targetToSellUserId = useTargetToSellUserId();

  const [maintenanceTabIndex, setMaintenanceTabIndex] = useState(
    userRole === ROLE_TOP_DOG ? 0 : 1
  );

  const prevUserRole = usePrevious(userRole);

  useEffect(() => {
    if (userRole && userRole !== prevUserRole) {
      setMaintenanceTabIndex(userRole === ROLE_TOP_DOG ? 0 : 1);
    }
  }, [prevUserRole, userRole]);

  const [isUserDataAvailable, setUserDataAvailable] = useState();

  useEffectOnMount(() => {
    if (getCookies(BRIDGE_LSKEY.signoutMyAccTrigger)) {
      cleanUpMyIDCookies();
      removeCookies(BRIDGE_LSKEY.signoutMyAccTrigger);

      Object.keys(BRIDGE_LSKEY).forEach((key) => {
        localStorage.removeItem(BRIDGE_LSKEY[key]);
      });
      localStorage.setItem(BRIDGE_LSKEY.loyaltyStatus, 0);

      signOut();
      return;
    }
    if (authState === SIGNED_IN_STATE) {
      setCookiesCrossDomain(BRIDGE_LSKEY.loyaltyStatus, 1);
      localStorage.setItem(BRIDGE_LSKEY.loyaltyStatus, 1);
    }
  });

  const [wishlistIds, setWishlistIds] = useState();

  if (authState === SIGN_IN_STATE) {
    return (
      <Provider
        value={{
          profile: fallbackProfile,
          userSbmIdentity: fallbackProfile,
          userSbmContacts: null,
          userSbmPreferences: null,
          userSbmGdpr: null,
          userSbmLoyalty: {},
          refetchUserData: false,
          loadingUserData: false,
          maintenanceTabIndex,
          targetToSellUserId,
          setMaintenanceTabIndex,
          loyaltyStatus: null,
          isUserDataAvailable,
          isFailSafeMode: false,
          loyaltyProgramJoined: false,
          userRole,
          wishlistIds,
          setWishlistIds,
          wishlistIdsDataLoading: false,
        }}
      >
        {children}
      </Provider>
    );
  }

  const { isOneclickSuspenseLang, setOneclickSuspenseLang, languageISO3 } =
    useLocalisationContext();

  // WarmUp request is mandatory to wake up api and create cache on AWS graphql-server
  const { loading: warmUpLoading } = useQuery(WarmUpQuery, {
    onCompleted: (data) => {
      getUserData();
      getNotificationsData();
      getWishlistIdsData();
    },
  });

  const [
    getUserData,
    { loading, error, data, refetch, called: getUserDataCalled },
  ] = useLazyQuery(UserQuery, {
    onCompleted: () => {
      setUserDataAvailable(true);
    },
  });

  const [
    getNotificationsData,
    {
      data: notificationsData,
      loading: loadingNotificationsData,
      refetch: refetchNotifications,
    },
  ] = useLazyQuery(NotificationsDataQuery, {
    variables: {
      language: languageISO3,
    },
  });

  const [wishlistIdsPendingToUpdate, setWishlistIdsPendingToUpdate] =
    useState(false);

  const [getWishlistIdsData, { loading: wishlistIdsDataLoading }] =
    useLazyQuery(WishlistIdsDataQuery, {
      onCompleted: (data) => {
        setWishlistIds(data.getWishlist);
      },
      fetchPolicy: 'cache-and-network',
      context: {
        headers: {
          'X-Acc-Grp': data?.getSbmLoyalty?.status || 1,
        },
      },
    });

  const { offerRedirectLoading } = useOfferRedirect({
    isUserDataAvailable: isUserDataAvailable,
    loyaltyStatus: data?.getSbmLoyalty?.status || 1,
    offerRedirectParams,
  });

  useEffect(() => {
    if (isOneclickSuspenseLang && data) {
      setOneclickSuspenseLang(false);

      refetch();
    }
  }, [isOneclickSuspenseLang, setOneclickSuspenseLang, refetch, data]);

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  if (data?.getSbmLoyalty?.status) {
    setCookiesCrossDomain(
      BRIDGE_LSKEY.loyaltyStatus,
      data.getSbmLoyalty.status
    );
    localStorage.setItem(BRIDGE_LSKEY.loyaltyStatus, data.getSbmLoyalty.status);
  }

  if (warmUpLoading || !getUserDataCalled || offerRedirectLoading) {
    return <SuspenseTemplate />;
  }

  return (
    <Provider
      value={{
        profile: data?.getSbmIdentity || fallbackProfile,
        userSbmIdentity: data?.getSbmIdentity || fallbackProfile,
        userSbmContacts: data?.getSbmContacts,
        userSbmPreferences: data?.getSbmPreferences,
        userSbmGdpr: data?.getSbmGdpr,
        userSbmLoyalty: data?.getSbmLoyalty?.blocks?.loyalty || {},
        refetchUserData: refetch,
        loadingUserData: loading,
        maintenanceTabIndex,
        targetToSellUserId,
        setMaintenanceTabIndex,
        loyaltyStatus: data?.getSbmLoyalty?.status || 1,
        isUserDataAvailable,
        isFailSafeMode: data && !data?.getStatus.user,
        loyaltyProgramJoined: !!data?.getSbmLoyalty?.status || false,
        userRole,
        notificationsData,
        loadingNotificationsData,
        refetchNotifications,
        wishlistIds,
        setWishlistIds,
        wishlistIdsDataLoading,
        wishlistIdsPendingToUpdate,
        setWishlistIdsPendingToUpdate,
        getWishlistIdsData,
      }}
    >
      {children}
    </Provider>
  );
});

function useTargetToSellUserId() {
  const targetToSellUserId = useMemo(
    () =>
      typeof window !== 'undefined'
        ? localStorage.getItem('targetToSellUserId') || uuid()
        : null,
    []
  );

  useEffect(() => {
    if (localStorage.getItem('targetToSellUserId') !== targetToSellUserId) {
      localStorage.setItem('targetToSellUserId', targetToSellUserId);
    }
  }, [targetToSellUserId]);

  return targetToSellUserId;
}

export { UserContextConsumer, UserContextProvider, useUserContext };

export default UserContext;
