import React from 'react';
import { object } from 'prop-types';
import { gql } from '@apollo/client';

import CardGrid from '../CardGrid';
import GiftCard from '../GiftCard';
import UserCard from '../UserCard';
import VipCard from '../VipCard';
import TopCard from '../TopCard';
import OfferCard from '../OfferCard';

const propTypes = {
  loyalty: object,
  offers: object,
  info: object,
};

const defaultProps = {
  loyalty: null,
  offers: null,
  info: null,
};

const homepageInfoFragment = gql`
  fragment homepageInfoFragment on MyAccountHomepage {
    title
    ...topCardFragment
    ...giftCardFragment
  }
  ${TopCard.fragments.topCardFragment}
  ${GiftCard.fragments.giftCardFragment}
`;

const offersListFragment = gql`
  fragment offersListFragment on ListingItems {
    results {
      ... on Offer {
        ...homeOfferCardFragment
      }
    }
  }
  ${OfferCard.fragments.homeOfferCardFragment}
`;

const DataBlocks = ({ loyalty, offers, info }) => {
  const stretchOfferCards = [0];
  const isStretch = function (i) {
    return stretchOfferCards.some((item) => item === i);
  };

  const giftCardData = info
    ? {
        giftCardButton: info.giftCardButton,
        giftCardImage: info.giftCardImage[0],
        giftCardSubtitle: info.giftCardSubtitle,
        giftCardTitle: info.giftCardTitle,
        giftCardMobileImage: info.giftCardMobileImage[0],
      }
    : null;

  return (
    <CardGrid>
      {loyalty?.loyalty && <UserCard data={loyalty.loyalty} />}
      {loyalty?.messages && <VipCard data={loyalty.messages} />}

      {info && <TopCard data={info} />}

      {offers?.results.map((data, i) => (
        <OfferCard key={i} data={data} isStretch={isStretch(i)} />
      ))}

      {giftCardData && <GiftCard data={giftCardData} isStretch />}
    </CardGrid>
  );
};

DataBlocks.propTypes = propTypes;
DataBlocks.defaultProps = defaultProps;
DataBlocks.fragments = {
  homepageInfoFragment,
  offersListFragment,
};

export default DataBlocks;
