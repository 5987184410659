export default function getItem({ typeName, typeMap }) {
  const item = typeMap[typeName];

  if (item) {
    return item;
  } else {
    console.warn(`No item for type ${typeName} is specified`);

    return null;
  }
}
