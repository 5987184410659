import React from 'react';
import { array } from 'prop-types';
import uniqueId from 'lodash/uniqueId';

import PanelField from '~/components/PanelField';

const propTypes = {
  foreignIds: array.isRequired,
};

function ForeignIdsFields({ foreignIds }) {
  return foreignIds.length < 20
    ? foreignIds.map(({ sourceName, sourceKey }) => {
        const index = uniqueId();
        return <PanelField label={sourceName} value={sourceKey} key={index} />;
      })
    : foreignIds.slice(0, 20).map(({ sourceName, sourceKey }) => {
        const index = uniqueId();
        return <PanelField label={sourceName} value={sourceKey} key={index} />;
      });
}

ForeignIdsFields.propTypes = propTypes;

export default ForeignIdsFields;
