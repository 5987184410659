import React from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './PushRightColHeader.module.scss';

const cx = classNames.bind(styles);

//PushRightColHeader PropTypes
const pushRightColHeaderPropTypes = {
  className: string,
  children: node,
  shortStyle: bool.isRequired,
};

const pushRightColHeaderDefaultProps = {
  className: null,
  children: null,
};

//PushRightColHeaderLeftCol PropTypes
const pushRightColHeaderLeftColPropTypes = {
  children: node,
};

const pushRightColHeaderLeftDefaultProps = {
  children: null,
};

//PushRightColHeaderRightCol PropTypes
const pushRightColHeaderRightColPropTypes = {
  children: node,
};

const pushRightColHeaderRightColDefaultProps = {
  children: null,
};

const PushRightColHeader = ({
  className,
  children,
  shortStyle,
  isOpenStatus,
  ...attributes
}) => {
  return (
    <div
      className={cx(
        'root',
        { empty: !children },
        { 'short-style': shortStyle },
        className
      )}
      {...attributes}
    >
      {children}
    </div>
  );
};

const PushRightColHeaderLeftCol = ({ children }) => {
  return children ? (
    <div className={cx('top-col', 'top-col-1')}>{children}</div>
  ) : null;
};

const PushRightColHeaderRightCol = ({ children }) => {
  return children ? (
    <div className={cx('top-col', 'top-col-2')}>{children}</div>
  ) : null;
};

PushRightColHeader.propTypes = pushRightColHeaderPropTypes;
PushRightColHeader.defaultProps = pushRightColHeaderDefaultProps;

PushRightColHeaderLeftCol.propTypes = pushRightColHeaderLeftColPropTypes;
PushRightColHeaderLeftCol.defaultProps = pushRightColHeaderLeftDefaultProps;

PushRightColHeaderRightCol.propTypes = pushRightColHeaderRightColPropTypes;
PushRightColHeaderRightCol.defaultProps =
  pushRightColHeaderRightColDefaultProps;

export { PushRightColHeaderLeftCol, PushRightColHeaderRightCol };
export default PushRightColHeader;
