import React from 'react';
import { arrayOf, string } from 'prop-types';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';

import getItem from 'common/utils/getItem';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import WidgetPromoImage from '~/scenes/WidgetPromoImage';
import WidgetPromoImageText from '~/scenes/WidgetPromoImageText';
import WidgetBannerText from '~/scenes/WidgetBannerText';

const widgetsFieldsFragment = gql`
  fragment widgetsFields on Widget {
    __typename
    id
    ...widgetPromoImageFields
    ...widgetPromoImageTextFields
    ...widgetBannerTextFields
  }
  ${WidgetPromoImage.fragment.widgetPromoImageFieldsFragment}
  ${WidgetPromoImageText.fragment.widgetPromoImageTextFieldsFragment}
  ${WidgetBannerText.fragment.widgetBannerTextFieldsFragment}
`;

const fragment = widgetsFieldsFragment;

const propTypes = {
  targetToSellItemId: string,
  targetToSellCategoryId: string,
  data: arrayOf(propType(widgetsFieldsFragment).isRequired).isRequired,
};

const defaultProps = {
  targetToSellItemId: null,
  targetToSellCategoryId: null,
};

const typenamesToWidgetsMap = {
  WidgetPromoImage,
  WidgetPromoImageText,
  WidgetBannerText,
};

const renderWidget = ({
  props,
  targetToSellItemId,
  targetToSellCategoryId,
}) => {
  const Component = getItem({
    typeName: props.__typename,
    typeMap: typenamesToWidgetsMap,
  });

  if (Component) {
    return <Component key={props.id} data={props} />;
  } else {
    return null;
  }
};

const Widgets = ({ data }) => {
  return data.map((itemData) =>
    renderWidget({
      props: itemData,
    })
  );
};

Widgets.propTypes = propTypes;
Widgets.defaultProps = defaultProps;
Widgets.fragment = fragment;

export default withErrorBoundary(Widgets);
