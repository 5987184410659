import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';

import Toggle from 'common/containers/Toggle';
import setTravelclickUrl from 'common/utils/setTravelclickUrl';
import gtmPushEvent from 'common/utils/gtmPushEvent';
import BookingOffer from 'common/containers/BookingOffer';
import BookingOfferHotelConfigurator from 'common/scenes/StickyBlock/BookingOfferHotelConfigurator';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { HeaderBlock, ContactUs } from '~/scenes/StickyBlock';
import BestPriceBlock from '~/components/BestPriceBlock';
import Button from '~/components/Button';
import ButtonPhone from '~/components/ButtonPhone';
import { useLocalisationContext } from '~/context/LocalisationContext';
import OfferTags from '~/components/OfferTags';
import { sbmClient } from '~/apolloClient';

import PropertyDropdown from './components/PropertyDropdown';
import styles from './OfferStickyBookingBlock.module.scss';

const cx = classNames.bind(styles);

export const OfferStickyBookingBlockBottomContainer = ({ children }) =>
  children;

const offerStickyBookingFieldsFragment = gql`
  fragment offerStickyBookingFields on Offer {
    id
    enTitle: title(language: en)
    universe {
      listingType
    }
    callbackPhoneNumber
    selectPropertyLabel
    isMyPointsEnabled
    selectHotelLabel
    priceLabel
    currency
    phoneNumber
    ctaUrl {
      url
    }
    ctaLabel
    offerEndDate
    onlineBooking {
      label
      value
    }
    offerProperty {
      price
      priceBeforeDiscount
      offerType {
        label
        value
      }
      travelclickId
      travelclickPackageId
      travelclickUrlToken
      parentProperty {
        ... on Hotel {
          id
          travelclickId
          __typename
          title
          stars {
            value
          }
          bookingBestOffer {
            url
          }
        }
        ... on Restaurant {
          id
          __typename
          title
        }
        ... on Nightlife {
          id
          __typename
          title
        }
        ... on Casino {
          id
          __typename
          title
        }
        ... on WellnessProperty {
          id
          __typename
          title
        }
        ... on Shop {
          id
          __typename
          title
        }
        ... on Event {
          id
          __typename
          title
        }
      }
      ...bookingOfferFields
      offerFirstReservableDate
      offerPropertyEndDate
      offerMinimalStayDuration
      offerMaximalStayDuration
    }
    priceEmptyLabel
    bookingOptionalMessage
    isBookingAvailable
    notAllowedCtaLabel
    notAllowedCtaUrl
    notAllowedCtaIncentiveText
  }
  ${BookingOffer.fragment.bookingOfferFieldsFragment}
`;

const offerStickyBookingSiteSettingsFieldsFragment = gql`
  fragment offerStickyBookingSiteSettingsFields on SiteSettings {
    bookingBlockLabels {
      persons
      children
      checkOut
      checkIn
      adults
      dateLimitMessage
      nightFrom
      stayAt
      stayPrice
      nightSingle
      nightPlural
      noRooms
      checkoutOnly
      rooms
      roomsAdultsMessage
    }
    globalLabels {
      close
    }
    myAccountOfferStickyTitle {
      ...offerStickyBlockHeaderFields
    }
    myAccountOfferStickyPhoneTitle {
      value
      index
    }
  }
  ${HeaderBlock.fragments.offerStickyBlockHeaderFieldsFragment}
`;

const propTypes = {
  data: propType(offerStickyBookingFieldsFragment).isRequired,
  siteSettingsData: propType(offerStickyBookingSiteSettingsFieldsFragment)
    .isRequired,
};

const defaultProps = {
  children: null,
  isClosable: false,
  closeBlock: null,
};

const OfferStickyBookingBlock = function ({
  children,
  data,
  data: {
    id,
    enTitle,
    selectHotelLabel,
    selectPropertyLabel,
    priceLabel,
    currency,
    phoneNumber,
    ctaUrl,
    ctaLabel,
    offerProperty,
    onlineBooking,
    priceEmptyLabel,
    bookingOptionalMessage,
    isMyPointsEnabled,
    offerEndDate,
    callbackPhoneNumber,
    isBookingAvailable,
    notAllowedCtaLabel,
    notAllowedCtaUrl,
    notAllowedCtaIncentiveText,
  },
  siteSettingsData,
  siteSettingsData: {
    myAccountOfferStickyTitle,
    myAccountOfferStickyPhoneTitle,
  },
}) {
  const { language } = useLocalisationContext();

  const handleBookingClick = () => {
    const params = {
      event: 'click_book_now',
      click_location: window.location.href,
      item_id: id,
      item_name: enTitle,
    };

    gtmPushEvent(params);
  };

  return (
    <div className={cx('root')}>
      <HeaderBlock data={myAccountOfferStickyTitle} />
      {isBookingAvailable ? (
        <BookingOffer data={offerProperty}>
          {({
            withHotels,
            filteredProperties,
            property,
            propertyDatesStatus,
            bestOfferProperty,
            setProperty,
          }) => (
            <>
              <div className={cx('book-property')}>
                <Toggle>
                  {({ status: isVisible, toggle: toggleDropdown }) => (
                    <PropertyDropdown
                      isVisible={isVisible}
                      toggleDropdown={toggleDropdown}
                      label={
                        withHotels ? selectHotelLabel : selectPropertyLabel
                      }
                      setProperty={setProperty}
                      properties={filteredProperties}
                      property={property}
                    />
                  )}
                </Toggle>
              </div>
              <div className={cx('tags-wrap')}>
                <OfferTags
                  discount={!!bestOfferProperty.priceBeforeDiscount}
                  endDate={offerEndDate}
                  className={cx('tag-list')}
                />
              </div>
              {withHotels && onlineBooking.value === '1' ? (
                property ? (
                  <BookingOfferHotelConfigurator
                    labels={siteSettingsData}
                    hotelId={property.parentProperty.id}
                    createOnClick={handleBookingClick}
                    property={property}
                    ctaLabel={ctaLabel}
                    langId={language}
                    client={sbmClient}
                  />
                ) : (
                  <div className={cx('book-offer')}>
                    {propertyDatesStatus === 'invalid' && (
                      <p className={cx('book-message')}>
                        {bookingOptionalMessage}
                      </p>
                    )}
                    <BestPriceBlock
                      price={bestOfferProperty.price}
                      oldPrice={bestOfferProperty.priceBeforeDiscount}
                      points={
                        isMyPointsEnabled ? bestOfferProperty.pricePoints : null
                      }
                      priceLabel={priceLabel}
                      currency={currency}
                      className={cx('best-price')}
                      priceEmptyLabel={priceEmptyLabel}
                    />
                    <div className={cx('book-cta-wrapper')}>
                      <Button
                        className={cx('book-cta')}
                        disabled={
                          propertyDatesStatus === 'empty' ||
                          propertyDatesStatus === 'invalid'
                        }
                        to={setTravelclickUrl({
                          offerType: bestOfferProperty.offerType,
                          travelclickId: bestOfferProperty.travelclickId,
                          travelclickPackageId:
                            bestOfferProperty.travelclickPackageId,
                          hotelId:
                            bestOfferProperty.parentProperty.travelclickId,
                          langId: language,
                          travelclickUrlToken:
                            bestOfferProperty.travelclickUrlToken,
                        })}
                        onClick={handleBookingClick}
                      >
                        {ctaLabel}
                      </Button>
                    </div>
                  </div>
                )
              ) : (
                <div className={cx('book-offer')}>
                  <BestPriceBlock
                    price={bestOfferProperty.price}
                    oldPrice={bestOfferProperty.priceBeforeDiscount}
                    points={
                      isMyPointsEnabled ? bestOfferProperty.pricePoints : null
                    }
                    priceLabel={priceLabel}
                    currency={currency}
                    className={cx('best-price')}
                    priceEmptyLabel={priceEmptyLabel}
                  />
                  {phoneNumber ? (
                    <ButtonPhone
                      className={cx('book-cta')}
                      label={ctaLabel}
                      phone={phoneNumber}
                    />
                  ) : (
                    <Button className={cx('book-cta')} to={ctaUrl?.url}>
                      {ctaLabel}
                    </Button>
                  )}
                </div>
              )}
            </>
          )}
        </BookingOffer>
      ) : (
        <div className={cx('book-offer')}>
          {notAllowedCtaIncentiveText && (
            <p className={cx('book-message')}>{notAllowedCtaIncentiveText}</p>
          )}
          {notAllowedCtaLabel && notAllowedCtaUrl && (
            <Button
              tag="a"
              target="_blank"
              rel="noopener noreferrer"
              className={cx('book-cta')}
              to={notAllowedCtaUrl}
            >
              {notAllowedCtaLabel}
            </Button>
          )}
        </div>
      )}
      {children}
      {callbackPhoneNumber && (
        <ContactUs
          phone={callbackPhoneNumber}
          label={myAccountOfferStickyPhoneTitle}
        />
      )}
    </div>
  );
};

OfferStickyBookingBlock.defaultProps = defaultProps;
OfferStickyBookingBlock.propTypes = propTypes;
OfferStickyBookingBlock.fragment = {
  offerStickyBookingFieldsFragment,
  offerStickyBookingSiteSettingsFieldsFragment,
};

export default withErrorBoundary(OfferStickyBookingBlock);
