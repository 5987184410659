import { LOYALTY_CARD_CODES } from 'common/constants';
import {
  CARD_MEMBRE_IMG,
  CARD_SILVER_IMG,
  CARD_GOLD_IMG,
  CARD_PLATINUM_IMG,
  CARD_PRIVE_IMG,
} from '~/constants';

const getCardStatus = function(status, wordings) {
  switch (status) {
    case LOYALTY_CARD_CODES.member:
      return wordings.cardMemberStatus;
    case LOYALTY_CARD_CODES.silver:
      return wordings.cardSilverStatus;
    case LOYALTY_CARD_CODES.gold:
      return wordings.cardGoldStatus;
    case LOYALTY_CARD_CODES.platinum:
      return wordings.cardPlatinumStatus;
    case LOYALTY_CARD_CODES.vip:
      return wordings.cardVipStatus;

    default:
      console.warn('No card statuses!');
  }
};

const getPicture = function(status, wordings) {
  switch (status) {
    case LOYALTY_CARD_CODES.member:
      return {
        srcSet: CARD_MEMBRE_IMG,
        alt: wordings.cardMemberPictureAlt,
      };
    case LOYALTY_CARD_CODES.silver:
      return {
        srcSet: CARD_SILVER_IMG,
        alt: wordings.cardSilverPictureAlt,
      };
    case LOYALTY_CARD_CODES.gold:
      return {
        srcSet: CARD_GOLD_IMG,
        alt: wordings.cardGoldPictureAlt,
      };
    case LOYALTY_CARD_CODES.platinum:
      return {
        srcSet: CARD_PLATINUM_IMG,
        alt: wordings.cardPlatinumPictureAlt,
      };
    case LOYALTY_CARD_CODES.vip:
      return {
        srcSet: CARD_PRIVE_IMG,
        alt: wordings.cardVipPictureAlt,
      };

    default:
      return;
  }
};

export default {
  getCardStatus,
  getPicture,
};
