import { useMemo } from 'react';
import { func, array, string } from 'prop-types';

import { isFieldMandatory } from '~/scenes/FormContacts/utils';

const propTypes = {
  children: func.isRequired,
  cityName: string.isRequired,
  countryName: string.isRequired,
  mandatoryAddressGroups: array.isRequired,
};

const AddressFieldsMandatoryHandler = ({
  children,
  name,
  cityName,
  countryName,
  mandatoryAddressGroups,
}) => {
  const isNameMandatory = useMemo(
    () => isFieldMandatory(mandatoryAddressGroups, name),
    [mandatoryAddressGroups, name]
  );

  const isCityNameMandatory = useMemo(
    () => isFieldMandatory(mandatoryAddressGroups, cityName),
    [mandatoryAddressGroups, cityName]
  );

  const isCountryNameMandatory = useMemo(
    () => isFieldMandatory(mandatoryAddressGroups, countryName),
    [mandatoryAddressGroups, countryName]
  );

  return children({
    isNameMandatory,
    isCityNameMandatory,
    isCountryNameMandatory,
  });
};

AddressFieldsMandatoryHandler.propTypes = propTypes;

export default AddressFieldsMandatoryHandler;
