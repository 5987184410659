import React, { useCallback } from 'react';
import { array, string, object, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import AccordionDropDown from '../AccordionDropDown';

import styles from './AsyncMachineMessages.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  cache: object,
  queue: array.isRequired,
  className: string,
  windowResized: bool.isRequired,
};

const defaultProps = {
  className: null,
  cache: null,
};

function AsyncMachineMessages({ cache, queue, className, windowResized }) {
  const { t } = useTranslation('edit-account');
  const asyncMachineBlock = t('asyncMachineBlock', {
    returnObjects: true,
  });

  const {
    createdLabel,
    lockedLabel,
    endpointLabel,
    expirationLabel,
  } = asyncMachineBlock;

  const renderFieldWithLabel = useCallback(
    ({ time, field, label }) =>
      time !== undefined || field !== undefined ? (
        <div className={cx('field-wrapper')}>
          <span className={cx('field', 'field-label')}>{label}:</span>
          <span className={cx('field')}>
            {time
              ? moment.unix(time / 1000).format('YYYY-MM-DD hh:mm:ss')
              : field}
          </span>
        </div>
      ) : null,
    []
  );

  const renderMessageFields = useCallback(
    (fields) => {
      const { created, expiration, locked, endpoint, data } = fields;
      return (
        <>
          {renderFieldWithLabel({ time: created, label: createdLabel })}
          {renderFieldWithLabel({ time: expiration, label: expirationLabel })}
          {renderFieldWithLabel({ field: locked, label: lockedLabel })}
          {renderFieldWithLabel({ field: endpoint, label: endpointLabel })}
          {!!data && (
            <pre className={cx('message-text')}>
              {JSON.stringify(JSON.parse(data.trim()), null, '\t')}
            </pre>
          )}
        </>
      );
    },
    [
      renderFieldWithLabel,
      createdLabel,
      expirationLabel,
      lockedLabel,
      endpointLabel,
    ]
  );

  const renderMessage = useCallback(
    (fields, index = 0) => {
      return (
        <div key={index} className={cx('message-wrapper')}>
          <AccordionDropDown windowResized={windowResized}>
            {renderMessageFields(fields)}
          </AccordionDropDown>
        </div>
      );
    },
    [windowResized, renderMessageFields]
  );

  const renderGroups = (data) =>
    Array.isArray(data) && data.length
      ? data.map((fields, index) => renderMessage(fields, index))
      : renderMessage(data);

  return (
    <div className={cx('root', className)}>
      <div className={cx('logs-wrapper')}>
        {!!cache && renderGroups(cache)}
        {!!queue.length && renderGroups(queue)}
      </div>
    </div>
  );
}

AsyncMachineMessages.propTypes = propTypes;
AsyncMachineMessages.defaultProps = defaultProps;

export default AsyncMachineMessages;
