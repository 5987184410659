// All cases of price:
// FR, DE, RU: 123 456,78
// IT: 123.456,78
// EN, ZH: 123,456.78

const normalizePrice = (price, format) => {
  if (!price) return null;

  if (format === 'fr') {
    return price.replace(/\s/g, '').replace(/[,]/g, '.');
  }

  const priceNoSpaces = price.replace(/\s/g, '');
  let normalizedAmount = priceNoSpaces.replace(/[,.]/g, '');
  const length = priceNoSpaces.length;

  if (length > 3) {
    const decimalElement = priceNoSpaces.charAt(length - 3);
    const isDecimal = decimalElement === '.' || decimalElement === ',';

    if (isDecimal) {
      const priceWithoutDecimal = priceNoSpaces
        .slice(0, length - 3)
        .replace(/[,.]/g, '');
      const decimalPart = priceNoSpaces.slice(length - 2);

      normalizedAmount = `${priceWithoutDecimal}.${decimalPart}`;
    }
  }

  return normalizedAmount;
};

export default normalizePrice;
