import React from 'react';
import { func } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconBin } from 'common/media/icons/bin.icon.svg';
import { useToggle, useOnClickOutside } from 'common/hooks';

import DeleteDataModal from '../DeleteDataModal';
import styles from './DeleteUser.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  handleDeleteAccount: func.isRequired,
};

function DataButtons({ handleDeleteAccount }) {
  const [modalOpen, toggleModalOpen] = useToggle();

  const modalRef = useOnClickOutside(toggleModalOpen);

  const { t } = useTranslation('edit-account');
  const deleteDataButtonLabel = t('privacyDeleteDataButtonLabel');

  return (
    <div className={cx('root')}>
      {modalOpen && (
        <DeleteDataModal
          modalRef={modalRef}
          modalToggle={toggleModalOpen}
          deleteData={handleDeleteAccount}
        />
      )}
      <div className={cx('button-wrapper')}>
        <button
          type="button"
          className={cx('delete-button')}
          onClick={toggleModalOpen}
        >
          <span className={cx('icon-wrapper')}>
            <IconContainer icon={IconBin} className={cx('icon-data')} />
          </span>
          <span className={cx('cta-text')}>{deleteDataButtonLabel}</span>
        </button>
      </div>
    </div>
  );
}

DataButtons.propTypes = propTypes;

export default DataButtons;
