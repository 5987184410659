import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames/bind';
import { shape, object, array, oneOf, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './InfoMessages.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isTextMode: bool,
  messages: shape({
    messages: shape({
      info: array,
      status: array,
      warning: array,
      error: array,
    }),
  }),
  labels: object.isRequired,
  className: string,
  mode: oneOf(['info', 'status', 'warning', 'error']),
};

const defaultProps = {
  mode: null,
  isTextMode: false,
  className: null,
  messages: null,
};

const isRichText = (text) => text.startsWith('@');
const getKey = (label) => label?.substr(1);

const InfoMessages = ({
  messages: messagesData,
  labels,
  mode,
  isTextMode,
  className,
}) => {
  const messages = !!messagesData ? messagesData.messages : {};
  const messageTypes = ['info', 'status', 'warning', 'error'];
  const { t } = useTranslation('common');
  const commonMessage = t('serviceErrorMessage');

  const messageTypeByMode = useMemo(
    () =>
      messageTypes.filter((messageType) =>
        mode ? messageType === mode : true
      ),
    [mode, messageTypes]
  );

  const defaultModeLabelsValue = useCallback(
    (message, index) =>
      labels[getKey(message)] ? (
        <div
          key={index}
          dangerouslySetInnerHTML={{
            __html: labels[getKey(message)],
          }}
        />
      ) : (
        <div key={index}>{commonMessage}</div>
      ),
    [labels, commonMessage]
  );

  const defaultMode = useMemo(
    () =>
      messageTypeByMode.map(
        (type) =>
          !!messages &&
          messages[type]?.map((message, index) =>
            isRichText(message) ? (
              defaultModeLabelsValue(message, index)
            ) : (
              <div key={index}>{message}</div>
            )
          )
      ),
    [messageTypeByMode, messages, defaultModeLabelsValue]
  );

  return isTextMode ? (
    defaultMode
  ) : (
    <div className={cx('root', className)}>{defaultMode}</div>
  );
};

InfoMessages.propTypes = propTypes;
InfoMessages.defaultProps = defaultProps;

export default InfoMessages;
