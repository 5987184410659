// Desktop-first flow breakpoints and media rules for image sources
export const DESKTOP_WIDE_BREAKPOINT = 1440;
export const DESKTOP_BREAKPOINT = 1024;
export const TABLET_BREAKPOINT = 768;

export const DESKTOP_WIDE_MINSIZE_MEDIA_STR = `(max-width: ${DESKTOP_WIDE_BREAKPOINT}px)`;
export const DESKTOP_MEDIA_STR = `(max-width: ${DESKTOP_WIDE_BREAKPOINT -
  1}px)`;
export const DESKTOP_MINSIZE_MEDIA_STR = `(max-width: ${DESKTOP_BREAKPOINT}px)`;
export const TABLET_MEDIA_STR = `(max-width: ${DESKTOP_BREAKPOINT - 1}px)`;
export const TABLET_MINSIZE_MEDIA_STR = `(max-width: ${TABLET_BREAKPOINT}px)`;
export const MOBILE_MEDIA_STR = `(max-width: ${TABLET_BREAKPOINT - 1}px)`;
export const MOBILE_MINSIZE_MEDIA_STR = `(max-width: 530px)`;

// 1920                1440            1024           768           530
//  |---desktop-wide--->|<---desktop--->|<---tablet--->|<---mobile---|
//                     ^
//                     |
//                     |
//           DESKTOP_WIDE_BREAKPOINT
//
// MINSIZE media strings includes corresponding point to prepare bare
// minimum size for most usable devices with a proper aspect-ratio.

// Image styles:
// - 1900х750 - hero_desktop_wide_responsive
// - 1440х750 - hero_desktop_wide_minsize
// - 1024х750 - hero_desktop_minsize
// ---------------------desktop-------------------
// - 1023х330 - hero_tablet_responsive
// - 768х330 - hero_tablet_minsize
// ---------------------tablet-------------------
// - 767х480 - hero_mobile_responsive
// - 530х480 - hero_mobile_minsize
// ---------------------mobile-------------------
