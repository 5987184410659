import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconAccountingCoins } from 'common/media/icons/accounting-coins.icon.svg';
import Picture from 'common/components/Picture';
import numberWithSpaces from 'common/utils/numberWithSpaces';

import CardColor from '~/containers/CardColor';
import ProgressBar from '~/scenes/ProgressBar';
import getCardImg from '~/utils/getCardImg';

import styles from './LoyaltyCard.module.scss';

const cx = classNames.bind(styles);

const loyaltyCardDataFragment = gql`
  fragment loyaltyCardFields on SbmLoyaltyBlockLoyalty {
    enrollmentDate
    rewardPoints
    status
    statusPoints
    userFid
    expirationDate
  }
`;

const propTypes = {
  className: string,
  data: propType(loyaltyCardDataFragment).isRequired,
};

const defaultProps = {
  className: null,
};

const fragment = {
  loyaltyCardDataFragment,
};

const LoyaltyCard = ({
  className,
  data: {
    status: statusValue,
    statusPoints,
    rewardPoints,
    enrollmentDate,
    userFid,
    expirationDate,
  },
}) => {
  const { t } = useTranslation('loyalty-card');

  const title = t('title');
  const status = t('status', { returnObjects: true });
  const memberLabel = t('memberLabel');
  const enrollmentDateLabel = t('enrollmentDateLabel');
  const mymcIdLabel = t('mymcIdLabel');
  const loyaltyPointsLabel = t('loyaltyPointsLabel');
  const myPointsLable = t('myPointsLable');
  const restrictions = t('restrictions');

  const currentCardImg = getCardImg(statusValue);
  const memberText = `${status[`st${statusValue}`]} ${memberLabel}`;

  return (
    <div className={cx('root', className)}>
      <div className={cx('inner')}>
        <div className={cx('loyalty-card-info-block')}>
          <div className={cx('card-image-container')}>
            <CardColor className={cx('card-image')} shadow status={statusValue}>
              <Picture
                sources={[
                  {
                    srcSet: `${currentCardImg.urlB} 1x, ${currentCardImg.urlB2x} 2x`,
                    type: 'image/webp',
                  },
                ]}
                src={currentCardImg.urlB}
                alt={memberText}
              />
            </CardColor>
          </div>
          <div className={cx('description', 'description-top')}>
            <div className={cx('block-title-wrapper')}>
              {`${title} `}
              <CardColor className={cx('colored-title')} status={statusValue}>
                {memberText}
              </CardColor>
            </div>
          </div>
          <div className={cx('description', 'description-bottom')}>
            <div className={cx('enrollment-date')}>
              <span
                className={cx('enrollment-date-label')}
              >{`${enrollmentDateLabel}:`}</span>
              <span className={cx('enrollment-date-value')}>
                {enrollmentDate}
              </span>
            </div>
            <div className={cx('mymc-id')}>
              <span className={cx('mymc-id-label')}>{`${mymcIdLabel}:`}</span>
              <span className={cx('mymc-id-value')}>{userFid}</span>
            </div>
            <div className={cx('points-wrapper')}>
              <div className={cx('points-box')}>
                <div className={cx('points-box-title')}>
                  {loyaltyPointsLabel}
                </div>
                <div className={cx('points-value')}>
                  {numberWithSpaces(statusPoints)}
                </div>
              </div>
              <div className={cx('points-box')}>
                <div
                  className={cx(
                    'points-box-title',
                    'points-box-title-with-icon'
                  )}
                >
                  {myPointsLable}
                  <IconContainer
                    className={cx('points-icon')}
                    icon={IconAccountingCoins}
                  />
                </div>
                <div className={cx('points-value')}>
                  {numberWithSpaces(rewardPoints)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProgressBar
          myPoints={statusPoints}
          currentStatus={statusValue}
          expirationDate={expirationDate}
        />
      </div>
      <div
        className={cx('text')}
        dangerouslySetInnerHTML={{
          __html: restrictions,
        }}
      />
    </div>
  );
};

LoyaltyCard.propTypes = propTypes;
LoyaltyCard.defaultProps = defaultProps;
LoyaltyCard.fragment = fragment;

export default LoyaltyCard;
