import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';
import classNames from 'classnames/bind';
import moment from 'moment';

import Container from 'common/components/Container';
import RadioButtonsContainer from 'common/components/FormComponents/RadioButtonsContainer';
import Radio from 'common/components/FormComponents/Radio';
import Input from 'common/components/FormComponents/Input';
import SelectDate from 'common/components/FormComponents/SelectDate';
import Select from 'common/components/FormComponents/Select';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';
import InformationPanel from 'common/components/InformationPanel';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';
import useCountryNameKey from '~/hooks/useCountryNameKey';
import SelectProfession from '~/components/FormComponents/SelectProfession';
import FormButtons from '~/components/FormButtons';
import ButtonBack from '~/components/ButtonBack';
import BlockTitle from '~/components/BlockTitle';
import GTM from '~/components/GTM';

import FormIdentityInfoHandler from './FormIdentityInfoHandler';
import styles from './EditIdentityInfo.module.scss';

const cx = classNames.bind(styles);

const EditIdentityInfoDataQuery = gql`
  query EditIdentityInfoData($id: String!) {
    getCustomerProfile(id: $id) {
      sbmIdentity(id: $id) {
        gender
        firstName
        middleName
        birthName
        marriedName
        birthDate
        nationalities
        countryOfResidence
        occupation
      }
    }
  }
`;

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

function EditIdentityInfo({
  match: {
    params: { id },
  },
}) {
  const { t } = useTranslation(['edit-account', 'identity-form', 'common']);
  const editIdentityInfoPageTitle = t('editIdentityInfoPageTitle');
  const firstNameFieldLabel = t('identity-form:firstNameFieldLabel');
  const middleNameFieldLabel = t('identity-form:middleNameFieldLabel');
  const birthNameFieldLabel = t('identity-form:birthNameFieldLabel');
  const marriedNameFieldLabel = t('identity-form:marriedNameFieldLabel');
  const birthDateFieldLabel = t('identity-form:birthDateFieldLabel');
  const fieldDateYearLabel = t('common:fieldDateYearLabel');
  const fieldDateMonthLabel = t('common:fieldDateMonthLabel');
  const fieldDateDayLabel = t('common:fieldDateDayLabel');
  const nationalityFieldLabel = t('identity-form:nationalityFieldLabel');
  const countryOfResidenceFieldLabel = t(
    'identity-form:countryOfResidenceFieldLabel'
  );
  const messageError = t('identity-form:messageError');
  const genderLabel = t('identity-form:genderLabel');
  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');
  const professionLabel = t('common:professionLabel');

  const { language } = useLocalisationContext();

  const countryNameKey = useCountryNameKey();
  const prioritisedCountries = usePrioritisedCountries();

  const backLink = useMemo(() => `/${language}/user/${id}`, [id, language]);

  const { data, loading, error } = useQuery(EditIdentityInfoDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{editIdentityInfoPageTitle}</title>
      </Helmet>

      {loading && !data && <PageLoader />}
      {!loading && data && !error && (
        <div className={cx('content-wrapper')}>
          <ButtonBack to={backLink} />
          <BlockTitle
            title={editIdentityInfoPageTitle}
            className={cx('title')}
          />

          <FormIdentityInfoHandler
            userHash={id}
            data={data.getCustomerProfile.sbmIdentity}
          >
            {({
              genderList,
              validationSchema,
              initialValues,
              handleSubmit,
              submitError,
              submitSuccess,
              resetMessage,
              increaseNationalitiesHandler,
              decreaseNationalitiesHandler,
            }) => (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, isSubmitting, setFieldValue }) => (
                  <Form className={cx('form')} onChange={resetMessage}>
                    <RadioButtonsContainer
                      className={cx('radios-wrapper')}
                      label={genderLabel}
                    >
                      <div className={cx('switcher')}>
                        {genderList.map(({ value, label }) => (
                          <Field
                            key={value}
                            name="gender"
                            component={Radio}
                            id={value}
                            label={label}
                            smallStyledView
                          />
                        ))}
                      </div>
                    </RadioButtonsContainer>
                    <Field
                      name="firstName"
                      component={Input}
                      placeholder={firstNameFieldLabel}
                      required
                      maxLength={30}
                      autoComplete="firstName"
                    />
                    <Field
                      name="middleName"
                      component={Input}
                      placeholder={middleNameFieldLabel}
                      maxLength={30}
                      autoComplete="middleName"
                    />
                    <Field
                      name="birthName"
                      component={Input}
                      placeholder={birthNameFieldLabel}
                      required
                      maxLength={30}
                      autoComplete="birthName"
                    />
                    <Field
                      name="marriedName"
                      component={Input}
                      placeholder={marriedNameFieldLabel}
                      maxLength={30}
                      autoComplete="marriedName"
                    />
                    <Field
                      name="birthDate"
                      component={SelectDate}
                      className={cx('birthday-select-wrapper')}
                      itemClassName={cx('select-item')}
                      dateFormat={'YYYY-MM-DD'}
                      placeholder={birthDateFieldLabel}
                      placeholderYear={fieldDateYearLabel}
                      placeholderMonth={fieldDateMonthLabel}
                      placeholderDay={fieldDateDayLabel}
                      maxLength={254}
                      autoComplete="birthDate"
                      lastAvailableDate={moment()
                        .subtract(18, 'years')
                        .format('YYYY-MM-DD')}
                      required
                      isCleanable={false}
                    />
                    <FieldArray name="nationalities">
                      {({ name, push, remove }) => {
                        const nationalitiesFieldsCount = values[name].length;

                        return values[name].map((item, index) => {
                          const id = uniqueId();
                          return (
                            <div
                              className={cx('field-wrap', 'nationalities')}
                              key={id}
                            >
                              <Field
                                name={`nationalities.${index}`}
                                classNameWrapper={cx('select-field-wrap')}
                                valuesArrayName={name}
                                valuesArrayNameIndex={index}
                                component={Select}
                                options={prioritisedCountries}
                                customKeys={['id', countryNameKey]}
                                placeholder={nationalityFieldLabel}
                                isCustomChange
                              />
                              <MultiFieldCta
                                className={cx('modifiers')}
                                onIncrease={increaseNationalitiesHandler({
                                  index,
                                  nationalitiesFieldsCount,
                                  resetMessage,
                                  push,
                                })}
                                onRemove={decreaseNationalitiesHandler({
                                  name,
                                  remove,
                                  nationalitiesFieldsCount,
                                  index,
                                  setFieldValue,
                                  resetMessage,
                                })}
                              />
                            </div>
                          );
                        });
                      }}
                    </FieldArray>
                    <div className={cx('field-wrap')}>
                      <Field
                        name={'countryOfResidence'}
                        classNameWrapper={cx(
                          'country-of-residence',
                          'select-field-wrap'
                        )}
                        component={Select}
                        options={prioritisedCountries}
                        placeholder={countryOfResidenceFieldLabel}
                        customKeys={['id', countryNameKey]}
                      />
                      <MultiFieldCta
                        className={cx('modifiers')}
                        onRemove={() => {
                          resetMessage();
                          setFieldValue('countryOfResidence', '');
                        }}
                      />
                    </div>
                    <div className={cx('field-wrap')}>
                      <Field
                        name="occupation"
                        classNameWrapper={cx('occupation', 'select-field-wrap')}
                        component={SelectProfession}
                        placeholder={professionLabel}
                      />
                      <MultiFieldCta
                        className={cx('modifiers')}
                        onRemove={() => {
                          resetMessage();
                          setFieldValue('occupation', '');
                        }}
                      />
                    </div>
                    {submitError && (
                      <InformationPanel withIcon errorMode>
                        {messageError}
                      </InformationPanel>
                    )}
                    <FormButtons
                      isSubmitting={isSubmitting}
                      isSaved={submitSuccess}
                      to={backLink}
                      className={cx('form-buttons')}
                      savedButtonLabel={savedButtonLabel}
                      saveButtonLabel={saveButtonLabel}
                      cancelButtonLabel={cancelButtonLabel}
                    />
                  </Form>
                )}
              </Formik>
            )}
          </FormIdentityInfoHandler>
        </div>
      )}

      <GTM isMyCS />
    </Container>
  );
}

EditIdentityInfo.propTypes = propTypes;

export default EditIdentityInfo;
