import React from 'react';
import classNames from 'classnames/bind';
import { node, object, string } from 'prop-types';

import IconContainer from 'common/components/IconContainer';

import { useUiContext } from 'common/context/UiContext';

import styles from './PanelSection.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: node.isRequired,
  icon: object,
  className: string,
};

const defaultProps = {
  icon: null,
  className: '',
};

const PanelSection = ({ children, icon, className }) => {
  const { isDesktop } = useUiContext();

  return (
    <div className={cx('root', { className })}>
      {isDesktop && icon && (
        <IconContainer icon={icon} className={cx('icon')} />
      )}
      {children}
    </div>
  );
};

PanelSection.propTypes = propTypes;
PanelSection.defaultProps = defaultProps;

export default PanelSection;
