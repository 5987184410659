import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';

import HighlightedText from 'common/components/HighlightedText';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './HeaderBlock.module.scss';

const cx = classNames.bind(styles);

const offerStickyBlockHeaderFieldsFragment = gql`
  fragment offerStickyBlockHeaderFields on ColorText {
    index
    value
  }
`;

const propTypes = {
  data: propType(offerStickyBlockHeaderFieldsFragment).isRequired,
};

const fragments = {
  offerStickyBlockHeaderFieldsFragment,
};

const HeaderBlock = ({ data: { index, value } }) => {
  return (
    <div className={cx('book-header')}>
      <HighlightedText index={index}>{value}</HighlightedText>
    </div>
  );
};

HeaderBlock.propTypes = propTypes;
HeaderBlock.fragments = fragments;

export default withErrorBoundary(HeaderBlock);
