import images from 'widgets/scenes/WidgetMeteo/containers/MeteoContainer/images';

export const WCLASSES = {
  COMMON_CLASS: 'meteo-item',
  SUN: 'meteo-sun',
  CLOUDS: 'meteo-clouds',
  RAIN: 'meteo-rain',
  CLEAR_NIGHT: 'meteo-clear-night',
  CLOUDS_NIGHT: 'meteo-clouds-night',
  RAIN_NIGHT: 'meteo-rain-night',
};

export const METEO_MAPPINGS = {
  P1: {
    clsName: WCLASSES.SUN,
    clsNameAlt: WCLASSES.CLEAR_NIGHT,
    iconSrc: images.W_ICON_1,
  },
  P2: {
    clsName: WCLASSES.SUN,
    clsNameAlt: WCLASSES.CLEAR_NIGHT,
    iconSrc: images.W_ICON_2,
  },
  P3: {
    clsName: WCLASSES.CLOUDS,
    clsNameAlt: WCLASSES.CLOUDS_NIGHT,
    iconSrc: images.W_ICON_3,
  },
  P4: {
    clsName: WCLASSES.CLOUDS,
    clsNameAlt: WCLASSES.CLOUDS_NIGHT,
    iconSrc: images.W_ICON_4,
  },
  P5: {
    clsName: WCLASSES.CLOUDS,
    clsNameAlt: WCLASSES.CLOUDS_NIGHT,
    iconSrc: images.W_ICON_5,
  },
  P6: {
    clsName: WCLASSES.CLOUDS,
    clsNameAlt: WCLASSES.CLOUDS_NIGHT,
    iconSrc: images.W_ICON_6,
  },
  P7: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_7,
  },
  P8: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_8,
  },
  P9: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_9,
  },
  P10: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_10,
  },
  P11: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_11,
  },
  P12: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_12,
  },
  P13: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_13,
  },
  P14: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_14,
  },
  P15: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_15,
  },
  P16: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_16,
  },
  P17: {
    clsName: WCLASSES.RAIN,
    clsNameAlt: WCLASSES.RAIN_NIGHT,
    iconSrc: images.W_ICON_17,
  },
};
