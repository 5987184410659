import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';
import flowRight from 'lodash/flowRight';

import Picture from 'common/components/Picture';
import Container from 'common/components/Container';
import gtmPushEvent from 'common/utils/gtmPushEvent';
import getWidgetSources from 'common/utils/getWidgetSources';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import MyAccLink from '~/components/MyAccLink';
import { useUiContext } from 'common/context/UiContext';
import widgetImageFieldsFragment from 'common/queries/widgetImageFieldsFragment';
import statisticsClicksWidgetDataParams from '~/utils/statisticsClicksWidgetDataParams';

import styles from './WidgetBannerText.module.scss';

const cx = classNames.bind(styles);

const widgetBannerTextFieldsFragment = gql`
  fragment widgetBannerTextFields on WidgetBannerText {
    id
    title
    link {
      url
      text
    }
    description
    widgetBannerTextImage: image {
      id
      title
      ...widgetImageFields
    }
  }
  ${widgetImageFieldsFragment}
`;

const propTypes = {
  data: propType(widgetBannerTextFieldsFragment).isRequired,
};

const fragment = {
  widgetBannerTextFieldsFragment,
};

const WidgetBannerText = ({
  data: {
    id,
    title,
    widgetBannerTextImage: { image: widgetImage },
    description,
    link,
  },
}) => {
  const { currentBreakpoint } = useUiContext();

  const createOnClick =
    ({ title, id, link }) =>
    () => {
      const generatedStatisticsClicksWidgetDataParams =
        statisticsClicksWidgetDataParams(
          'widgetBannerText',
          title,
          id,
          link.url
        );

      const params = {
        event: 'click_widget',
        widget_info: generatedStatisticsClicksWidgetDataParams,
      };

      gtmPushEvent(params);
    };

  const Tag = link?.url ? MyAccLink : 'div';

  return (
    <div className={cx('root')}>
      <Container tag="section" width={1140}>
        <Tag
          className={cx('content-wrapper')}
          to={link.url}
          onClick={createOnClick({
            title,
            id,
            link,
          })}
        >
          <Picture
            sources={getWidgetSources(widgetImage)}
            src={widgetImage.widgetImageDesktop.url}
            alt={widgetImage.alt}
          />
          <div className={cx('content-inside')}>
            <h2 className={cx('title')}>{title}</h2>
            {description && currentBreakpoint !== 'tablet' && (
              <div className={cx('description')}>{description}</div>
            )}
          </div>
          {!!link && (
            <div className={cx('link')}>
              <span className={cx('link-label')}>{link.text}</span>
            </div>
          )}
        </Tag>
      </Container>
    </div>
  );
};

WidgetBannerText.propTypes = propTypes;
WidgetBannerText.fragment = fragment;

export default flowRight(withErrorBoundary)(WidgetBannerText);
