import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { gql, useLazyQuery } from '@apollo/client';
import classNames from 'classnames/bind';

import Container from 'common/components/Container';
import { getLoyaltyStatusLabel } from 'common/utils/userMenuUtils';

import PageLoader from '~/components/PageLoader';
import { useUserContext } from '~/context/UserContext';
import ErrorFallback from '~/components/ErrorFallback';
import { sbmClient } from '~/apolloClient';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import Widgets from '~/containers/Widgets';
import WidgetPromoImage from '~/scenes/WidgetPromoImage';
import WidgetPromoImageText from '~/scenes/WidgetPromoImageText';
import WidgetBannerText from '~/scenes/WidgetBannerText';
import GTM from '~/components/GTM';
import { useMyaccHistory } from '~/hooks';

import UserCard from './components/UserCard';
import VipCard from './components/VipCard';
import DataBlocks from './components/DataBlocks';

import styles from './HomePage.module.scss';

const cx = classNames.bind(styles);

const LoyaltyProgramDataQuery = gql`
  query LoyaltyProgramData {
    getSbmLoyalty {
      blocks {
        loyalty {
          ...userCardFields
        }
        messages {
          ...vipCardMessagesFields
        }
      }
    }
  }
  ${UserCard.fragments.userCardDataFragment}
  ${VipCard.fragments.vipCardDataFragment}
`;

const HPData = gql`
  query OffersList(
    $type: ListingType!
    $first: Int
    $after: Int
    $attributes: [ListingFilterInputAttributes]
  ) {
    myAccountHomepage {
      ...homepageInfoFragment
      widgets {
        ...widgetPromoImageFields
        ...widgetPromoImageTextFields
        ...widgetBannerTextFields
      }
    }
    listing(type: $type) {
      id
      items(
        myacc: true
        first: $first
        after: $after
        attributes: $attributes
      ) {
        targetToSellItemId
        ...offersListFragment
      }
    }
  }
  ${DataBlocks.fragments.homepageInfoFragment}
  ${DataBlocks.fragments.offersListFragment}
  ${WidgetPromoImage.fragment.widgetPromoImageFieldsFragment}
  ${WidgetPromoImageText.fragment.widgetPromoImageTextFieldsFragment}
  ${WidgetBannerText.fragment.widgetBannerTextFieldsFragment}
`;

const HomePage = () => {
  const history = useMyaccHistory();

  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
    loyaltyProgramJoined,
  } = useUserContext();

  const [fetchLPData, { data: loyaltyProgramData }] = useLazyQuery(
    LoyaltyProgramDataQuery,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const [fetchHomeData, { data: homeData }] = useLazyQuery(HPData, {
    client: sbmClient,
    variables: {
      type: 'offer',
      first: 3,
      after: 0,
      attributes: [
        {
          type: 'offer_loyalty',
          filters: [getLoyaltyStatusLabel(loyaltyStatus)],
        },
      ],
    },
    context: {
      headers: {
        'X-Acc-Grp': loyaltyStatus,
      },
    },
  });

  useEffect(() => {
    if (loadingUserData) return;
    if (loyaltyProgramJoined) {
      fetchLPData();
      fetchHomeData();
    } else {
      history.push('/loyalty-program');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUserData, loyaltyProgramJoined]);

  const { t } = useTranslation('loyalty-program');
  const pageTitle = t('pageTitle');

  if (loadingUserData) {
    return <PageLoader />;
  }

  return (
    <>
      <Container tag="main" className={cx('root')} width={1140}>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>

        {(!!loyaltyProgramData?.getSbmLoyalty?.blocks || homeData) && (
          <DataBlocks
            loyalty={loyaltyProgramData?.getSbmLoyalty?.blocks}
            offers={homeData?.listing?.items}
            info={homeData?.myAccountHomepage}
          />
        )}

        {isFailSafeMode && <ErrorFallback mode={'friendly'} />}
      </Container>

      {!!homeData?.myAccountHomepage?.widgets && (
        <Widgets data={homeData?.myAccountHomepage.widgets} />
      )}

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </>
  );
};

export default withErrorBoundary(HomePage);
