import React, { useMemo } from 'react';
import { shape, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import Container from 'common/components/Container';
import RadioButtonsContainer from 'common/components/FormComponents/RadioButtonsContainer';
import Radio from 'common/components/FormComponents/Radio';
import Input from 'common/components/FormComponents/Input';
import SelectDate from 'common/components/FormComponents/SelectDate';
import InformationPanel from 'common/components/InformationPanel';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import InputPhone from '~/components/FormComponents/InputPhone';
import FormButtons from '~/components/FormButtons';
import ButtonBack from '~/components/ButtonBack';
import BlockTitle from '~/components/BlockTitle';
import GTM from '~/components/GTM';

import EditMainInfoFormHandler from './containers/EditMainInfoFormHandler';
import styles from './EditMainInfo.module.scss';

const cx = classNames.bind(styles);

const EditMainInfoDataQuery = gql`
  query EditMainInfoData($id: String!) {
    getCustomerProfile(id: $id) {
      info {
        id
        gender
        firstName
        lastName
        birthDate
        email
        phoneNumber
      }
    }
  }
`;

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

function EditMainInfo({
  match: {
    params: { id },
  },
}) {
  const {
    language,
    geoip: { countryIso },
  } = useLocalisationContext();

  const { t } = useTranslation(['identity-form', 'edit-account', 'common']);
  const editMainInfoPageTitle = t('edit-account:editMainInfoPageTitle');
  const lastNameFieldLabel = t('edit-account:lastNameFieldLabel');
  const emailFieldLabel = t('edit-account:emailFieldLabel');
  const phoneNumberFieldLabel = t('edit-account:phoneNumberFieldLabel');
  const genderLabel = t('identity-form:genderLabel');
  const maleFieldLabel = t('maleFieldLabel');
  const femaleFieldLabel = t('femaleFieldLabel');
  const validationRequiredFieldMessage = t('validationRequiredFieldMessage');
  const validationLettersOnlyMessage = t('validationLettersOnlyMessage');
  const firstNameFieldLabel = t('firstNameFieldLabel');
  const birthDateFieldLabel = t('birthDateFieldLabel');
  const fieldDateYearLabel = t('common:fieldDateYearLabel');
  const fieldDateMonthLabel = t('common:fieldDateMonthLabel');
  const fieldDateDayLabel = t('common:fieldDateDayLabel');
  const messageError = t('messageError');
  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  const genderList = useMemo(
    () => [
      { value: 'male', label: maleFieldLabel },
      { value: 'female', label: femaleFieldLabel },
    ],
    [femaleFieldLabel, maleFieldLabel]
  );

  const { data, loading, error } = useQuery(EditMainInfoDataQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id },
  });

  const backLink = useMemo(() => `/${language}/user/${id}`, [id, language]);

  if (error) {
    console.error(`data: ${data}\n`, error);
  }

  return (
    <Container tag="main" className={cx('root')} width={760}>
      <Helmet>
        <title>{editMainInfoPageTitle}</title>
      </Helmet>

      {loading && !data && <PageLoader />}
      {!loading && data && !error && (
        <div className={cx('content-wrapper')}>
          <ButtonBack to={backLink} />
          <BlockTitle title={editMainInfoPageTitle} className={cx('title')} />
          <EditMainInfoFormHandler
            userHash={id}
            data={data.getCustomerProfile.info}
            validationRequiredFieldMessage={validationRequiredFieldMessage}
            validationLettersOnlyMessage={validationLettersOnlyMessage}
          >
            {({
              validationSchema,
              initialValues,
              handleSubmit,
              submitError,
              submitSuccess,
              resetMessage,
            }) => (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, isSubmitting }) => (
                  <Form className={cx('form')} onChange={resetMessage}>
                    <RadioButtonsContainer
                      className={cx('radios-wrapper')}
                      label={genderLabel}
                    >
                      <div className={cx('switcher')}>
                        {genderList.map(({ value, label }) => (
                          <Field
                            key={value}
                            name="gender"
                            component={Radio}
                            id={value}
                            label={label}
                            smallStyledView
                          />
                        ))}
                      </div>
                    </RadioButtonsContainer>
                    <Field
                      name="firstName"
                      component={Input}
                      placeholder={firstNameFieldLabel}
                      required
                      maxLength={30}
                      autoComplete="firstName"
                    />
                    <Field
                      name="lastName"
                      component={Input}
                      placeholder={lastNameFieldLabel}
                      maxLength={30}
                      autoComplete="lastName"
                    />
                    <Field
                      name="birthDate"
                      component={SelectDate}
                      itemClassName={cx('select-item')}
                      dateFormat={'YYYY-MM-DD'}
                      placeholder={birthDateFieldLabel}
                      placeholderYear={fieldDateYearLabel}
                      placeholderMonth={fieldDateMonthLabel}
                      placeholderDay={fieldDateDayLabel}
                      maxLength={254}
                      autoComplete="birthDate"
                      required
                      isCleanable={false}
                    />
                    <Field
                      name="email"
                      component={Input}
                      placeholder={emailFieldLabel}
                      disabled
                      required
                      maxLength={128}
                      autoComplete="email"
                    />
                    <Field name="phoneNumber">
                      {(field) => (
                        <InputPhone
                          value={values['phoneNumber']}
                          label={phoneNumberFieldLabel}
                          countryIso={countryIso}
                          {...field}
                        />
                      )}
                    </Field>
                    {submitError && (
                      <InformationPanel withIcon errorMode>
                        {messageError}
                      </InformationPanel>
                    )}
                    <FormButtons
                      isSubmitting={isSubmitting}
                      to={backLink}
                      isSaved={submitSuccess}
                      className={cx('form-buttons')}
                      saveButtonLabel={saveButtonLabel}
                      savedButtonLabel={savedButtonLabel}
                      cancelButtonLabel={cancelButtonLabel}
                    />
                  </Form>
                )}
              </Formik>
            )}
          </EditMainInfoFormHandler>
        </div>
      )}

      <GTM isMyCS />
    </Container>
  );
}

EditMainInfo.propTypes = propTypes;

export default withErrorBoundary(EditMainInfo);
