import React, { useEffect, useState, useCallback } from 'react';
import { string, bool } from 'prop-types';
import classNames from 'classnames/bind';

import LogoUi from 'common/components/LogoUi';
import SpinLoader from 'common/components/SpinLoader';

import { useUiContext } from 'common/context/UiContext';
import { useAuthContext, SIGNED_IN_STATE } from '~/context/AuthContext';

import LangSwitcherContainer from './components/LangSwitcherContainer';
import UserMenu from './components/UserMenu';
import UniverseMenu from './components/UniverseMenu';
import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  isUserDataAvailable: bool,
  isMyAC: bool,
};

const defaultProps = {
  className: null,
  isUserDataAvailable: false,
  isMyAC: false,
};

const Header = ({ className, isUserDataAvailable, isMyAC }) => {
  const { isDesktop } = useUiContext();
  const { authState } = useAuthContext();

  const [userDataWatchDog, setUserDataWatchDog] = useState();

  const sbmUrl = process.env.REACT_APP_MYACC_DOMAIN;

  useEffect(() => {
    if (userDataWatchDog && isUserDataAvailable) {
      window.open(sbmUrl, '_self', 'nofollow');
    }
  }, [userDataWatchDog, isUserDataAvailable, sbmUrl]);

  const logoClickHandler = useCallback(
    (e) => {
      if (!isUserDataAvailable && !userDataWatchDog && isMyAC) {
        e.preventDefault();
        setUserDataWatchDog(true);
      }
    },
    [isUserDataAvailable, userDataWatchDog, isMyAC]
  );

  return (
    <div className={cx('root', className)}>
      <UniverseMenu />

      <div className={cx('logo-wrapper')}>
        {isMyAC && !isUserDataAvailable ? (
          <div onClick={logoClickHandler} className={cx('logo-container')}>
            <LogoUi
              className={cx('logo')}
              logoText={'Société des Bains de Mer'}
              size={isDesktop ? 'md' : 'lg'}
              invert={!isDesktop}
            />
            {userDataWatchDog && (
              <SpinLoader className={cx('loader')} size={16} />
            )}
          </div>
        ) : (
          <a className={cx('logo-link')} rel="nofollow" href={sbmUrl}>
            <LogoUi
              className={cx('logo')}
              logoText={'Société des Bains de Mer'}
              size={isDesktop ? 'md' : 'lg'}
              invert={!isDesktop}
            />
          </a>
        )}
      </div>

      <div className={cx('top-bar')}>
        <div className={cx('top-bar-item')}>
          <LangSwitcherContainer />
        </div>
        {authState === SIGNED_IN_STATE && (
          <div className={cx('top-bar-item')}>
            <UserMenu />
          </div>
        )}
      </div>
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
