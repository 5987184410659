import React from 'react';
import Head from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { FullscreenContentWrapper } from '~/components/FullscreenContainer';
import Routes from '~/components/Routes';
import { MYID_AUTH_ROUTES } from '~/constants';

import BottomBlock from './components/BottomBlock';
import styles from './Authorize.module.scss';

const cx = classNames.bind(styles);

const Authorize = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <Head>
        <title>{t('login.pageTitle')}</title>
      </Head>

      <FullscreenContentWrapper
        position={'right'}
        vertical={'stretch'}
        withTablet={false}
      >
        <div className={cx('root')}>
          <div className={cx('root-content')}>
            <div className={cx('top-block')}>
              <Routes routesList={MYID_AUTH_ROUTES} />
            </div>
            <BottomBlock />
          </div>
        </div>
      </FullscreenContentWrapper>
    </>
  );
};

export default Authorize;
