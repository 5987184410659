import React, { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment';

export const ROOMS_NUMBER_INITIAL = 1;
export const ROOMS_NUMBER_MAX = 7;

const RoomsNumberContext = createContext();
const useRoomsNumberContext = () => useContext(RoomsNumberContext);
const RoomsNumberContextConsumer = RoomsNumberContext.Consumer;

const RoomsNumberContextProvider = ({ children }) => {
  const [roomsNumber, setRoomsNumber] = useState(ROOMS_NUMBER_INITIAL);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const storageInitialRoomsCount =
        localStorage.getItem('initialRoomsCount');
      const storageLastTimeFilterUpdated = localStorage.getItem(
        'lastTimeFilterUpdated'
      );

      if (
        storageInitialRoomsCount &&
        storageLastTimeFilterUpdated &&
        moment().diff(moment(Number(storageLastTimeFilterUpdated)), 'days') < 30
      ) {
        if (storageInitialRoomsCount) {
          setRoomsNumber(Number(storageInitialRoomsCount));
        }
      }
    }
  }, []);

  const isPossibleToAddRooms = roomsNumber < ROOMS_NUMBER_MAX;

  const updateRoomsNumber = (amount = ROOMS_NUMBER_INITIAL) => {
    setRoomsNumber(amount);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialRoomsCount', amount);
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const addRooms = () => {
    if (roomsNumber < ROOMS_NUMBER_MAX) {
      updateRoomsNumber(roomsNumber + 1);
    }
  };

  const removeRooms = () => {
    if (roomsNumber > ROOMS_NUMBER_INITIAL) {
      updateRoomsNumber(roomsNumber - 1);
    }
  };

  const resetStore = () => {
    updateRoomsNumber();
  };

  return (
    <RoomsNumberContext.Provider
      value={{
        roomsNumber,
        isPossibleToAddRooms,
        updateRoomsNumber,
        addRooms,
        removeRooms,
        resetStore,
      }}
    >
      {children}
    </RoomsNumberContext.Provider>
  );
};

export {
  RoomsNumberContextConsumer,
  RoomsNumberContextProvider,
  useRoomsNumberContext,
};

export default RoomsNumberContext;
