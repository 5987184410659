import { useMemo } from 'react';

import { UNDEFINED } from '../constants';

export function usePreferences(userPreferences, loadingUserData) {
  const userLanguage = useMemo(() => {
    if (userPreferences?.language && !loadingUserData) {
      return userPreferences.language;
    }

    return UNDEFINED;
  }, [userPreferences, loadingUserData]);

  const userContactChannels = useMemo(() => {
    if (userPreferences?.contactChannels && !loadingUserData) {
      const channels = userPreferences.contactChannels.filter(
        ({ value }) => value
      );

      return channels.length
        ? channels
            .map(({ method }, index) => `${index + 1}.${method}`)
            .join('/')
        : UNDEFINED;
    }

    return UNDEFINED;
  }, [userPreferences, loadingUserData]);

  const userFavoriteExperiences = useMemo(() => {
    if (userPreferences?.preferences?.length && !loadingUserData) {
      // "hotels,casinos/gaming tables,casinos/slot machines"
      const experiences = userPreferences.preferences
        .reduce((arr, exp) => {
          if (exp.subOptions.length) {
            return [
              ...arr,
              ...exp.subOptions.map((subExp) => `${exp.option}/${subExp}`),
            ];
          } else {
            return [...arr, exp.option];
          }
        }, [])
        .join(',');

      return experiences;
    }

    return UNDEFINED;
  }, [userPreferences, loadingUserData]);

  const userFrequencyOfVisits = useMemo(() => {
    if (userPreferences?.frequency && !loadingUserData) {
      // 'once a year or even less...' but we'll send just a 'year'

      return userPreferences.frequency.option;
    }

    return UNDEFINED;
  }, [userPreferences, loadingUserData]);

  const userFavouriteSeason = useMemo(() => {
    if (userPreferences?.season?.length && !loadingUserData) {
      // winter,summer

      return userPreferences.season.map(({ option }) => option).join(',');
    }

    return UNDEFINED;
  }, [userPreferences, loadingUserData]);

  return {
    userContactChannels,
    userFavoriteExperiences,
    userFrequencyOfVisits,
    userFavouriteSeason,
    userLanguage,
  };
}
