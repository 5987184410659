import React from 'react';
import { string, object, oneOf } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './MessageByStatusColor.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  messages: object.isRequired,
  status: oneOf(['red', 'green', 'orange', 'other']).isRequired,
  className: string,
};

const defaultProps = {
  className: null,
};

function UserLog({ messages, status, className }) {
  return messages[status] ? (
    <div className={cx(status, className)}>{messages[status]}</div>
  ) : (
    <div className={cx('other', className)}>{messages['other']}</div>
  );
}

UserLog.propTypes = propTypes;
UserLog.defaultProps = defaultProps;

export default UserLog;
