import statisticsCleanText from 'common/utils/statisticsCleanText';

const statisticsPathTextWithLeftSpacer = (text) => {
  const cleanText = statisticsCleanText(text);
  // add dash at the start
  const normalizeDashStarts = cleanText.startsWith('_')
    ? cleanText
    : '_' + cleanText;

  //remove dash from the end
  return normalizeDashStarts.endsWith('_')
    ? normalizeDashStarts.slice(0, normalizeDashStarts.length - 1)
    : normalizeDashStarts;
};

const statisticsCleanPath = (path) => {
  const pathTrimmed = path.trim();

  //remove slash from the start
  const normalizeSlashStarts = pathTrimmed.startsWith('/')
    ? pathTrimmed.substr(1)
    : pathTrimmed;

  //remove slash from the end
  const normalizeSlashEnds = normalizeSlashStarts.endsWith('/')
    ? normalizeSlashStarts.slice(0, normalizeSlashStarts.length - 1)
    : normalizeSlashStarts;

  //replace trailing slash and spaces from path by dashes
  return statisticsCleanText(normalizeSlashEnds);
};

export { statisticsPathTextWithLeftSpacer };
export default statisticsCleanPath;
