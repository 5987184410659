import React from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames/bind';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconToTop } from 'common/media/icons/arrow-to-top.svg';

import styles from './NavLink.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  url: string.isRequired,
  onClick: func.isRequired,
  label: string,
  className: string,
};

const defaultProps = {
  label: null,
  className: null,
};

const NavLink = ({ url, className, onClick, label }) => {
  return (
    <div className={cx('root', className)}>
      <a className={cx('nav-link')} href={url} onClick={onClick} title={label}>
        <IconContainer className={cx('icon')} icon={IconToTop} />
      </a>
    </div>
  );
};

NavLink.propTypes = propTypes;
NavLink.defaultProps = defaultProps;

export default NavLink;
