import React from 'react';
import classNames from 'classnames/bind';

import styles from './ServiceLinks.module.scss';

const cx = classNames.bind(styles);

function ServiceLinks({ data }) {
  return (
    <div
      className={cx('root')}
      dangerouslySetInnerHTML={{ __html: data }}
    ></div>
  );
}

export default ServiceLinks;
