import React, { useMemo } from 'react';
import { shape, bool, string, array, object } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Select from 'common/components/FormComponents/Select';
import InformationPanel from 'common/components/InformationPanel';
import { MYACC_LANGUAGE } from 'common/constants';

import FieldDescription from '~/components/FormComponents/FieldDescription';
import Checkboxes from '~/components/FormComponents/Checkboxes';
import FormRadios from '~/components/FormComponents/FormRadios';
import FormButtons from '~/components/FormButtons';
import ErrorFallback from '~/components/ErrorFallback';

import FormPreferencesHandler from './containers/FormPreferencesHandler';
import GtmPreferencesDataHandler from './containers/GtmPreferencesDataHandler';
import SortableList from './components/SortableList';
import { shouldCancelDrag } from './utils';
import styles from './FormPreferences.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isMyCs: bool,
  contactsData: shape({
    phone: array,
  }),
  preferencesData: shape({
    language: string.isRequired,
    contactChannels: array.isRequired,
    preferences: array.isRequired,
    frequency: object,
    season: array.isRequired,
  }),
  userHash: string,
  redirectTo: string,
};

const defaultProps = {
  isMyCs: false,
  contactsData: null,
  preferencesData: null,
  userHash: null,
  redirectTo: null,
  userFrequencyData: null,
};

function FormPreferences({
  isMyCs,
  contactsData,
  preferencesData,
  userHash,
  redirectTo,
}) {
  const { t } = useTranslation(['preferences-form', 'common']);

  const languageLabel = t('preferences-form:languageLabel');
  const contactLanguageText = t('preferences-form:contactLanguageText');
  const contactMethodText = t('preferences-form:contactMethodText');
  const experienceTitle = t('preferences-form:experienceTitle');
  const experienceSubTitle = t('preferences-form:experienceSubTitle');
  const frequencyLabel = t('preferences-form:frequencyLabel');
  const seasonLabel = t('preferences-form:seasonLabel');
  const messageError = t('preferences-form:messageError');

  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  const isPhoneExist = useMemo(
    () => contactsData?.phone.filter(({ phone }) => phone !== '').length > 0,
    [contactsData]
  );

  if (!preferencesData) return <ErrorFallback mode={'friendly'} />;

  return (
    <FormPreferencesHandler
      isMyCs={isMyCs}
      isPhoneExist={isPhoneExist}
      isContactsAvailable={!!contactsData}
      preferencesData={preferencesData}
      userHash={userHash}
    >
      {({
        handleSubmit,
        initialValues,
        submitError,
        submitSuccess,
        contactChannelList,
        userExperienceList,
        userFrequencyList,
        userSeasonList,
        onSortEnd,
        onFormChange,
        isFormDisabled,
      }) => (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, setFieldTouched, isSubmitting }) => (
            <>
              <GtmPreferencesDataHandler
                values={values}
                contactChannelList={contactChannelList}
              />
              <Form className={cx('form')} onChange={onFormChange}>
                <div className={cx('form-wrapper')}>
                  <FieldDescription>{contactLanguageText}</FieldDescription>
                  <Field
                    name="language"
                    component={Select}
                    options={MYACC_LANGUAGE}
                    customKeys={['langISO3', 'title']}
                    placeholder={languageLabel}
                    classNameWrapper={cx('language-select')}
                    disabled={isFormDisabled}
                  />

                  <FieldDescription>{contactMethodText}</FieldDescription>
                  <div className={cx('contact-channel')}>
                    <SortableList
                      isMyCs={isMyCs}
                      onSortEnd={onSortEnd({
                        name: 'contactChannels',
                        setFieldValue: setFieldValue,
                      })}
                      shouldCancelStart={(event) => shouldCancelDrag(event)}
                      contactChannelList={contactChannelList}
                    />
                  </div>

                  <FieldDescription>{experienceTitle}</FieldDescription>
                  <FieldDescription className={cx('sub-description')}>
                    {experienceSubTitle}
                  </FieldDescription>
                  <Field
                    name="experiences"
                    component={Checkboxes}
                    colCount={2}
                    options={userExperienceList}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabled={isFormDisabled}
                  />

                  <FieldDescription>{frequencyLabel}</FieldDescription>
                  <Field
                    name="frequency"
                    component={FormRadios}
                    colCount={2}
                    options={userFrequencyList}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabled={isFormDisabled}
                  />

                  <FieldDescription>{seasonLabel}</FieldDescription>
                  <Field
                    name="season"
                    component={Checkboxes}
                    colCount={2}
                    options={userSeasonList}
                    values={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    disabled={isFormDisabled}
                  />
                </div>
                {submitError && (
                  <InformationPanel
                    className={cx('info-panel')}
                    withIcon
                    errorMode
                  >
                    {messageError}
                  </InformationPanel>
                )}
                <FormButtons
                  isSubmitting={isSubmitting}
                  isSaved={submitSuccess}
                  to={redirectTo}
                  savedButtonLabel={savedButtonLabel}
                  saveButtonLabel={saveButtonLabel}
                  cancelButtonLabel={cancelButtonLabel}
                  isSubmitDisabled={isFormDisabled}
                />
              </Form>
            </>
          )}
        </Formik>
      )}
    </FormPreferencesHandler>
  );
}

FormPreferences.propTypes = propTypes;
FormPreferences.defaultProps = defaultProps;

export default FormPreferences;
