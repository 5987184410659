import React from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

import Button from 'common/components/Button';
import SpinLoader from 'common/components/SpinLoader';
import { ButtonInverted } from 'common/components/Buttons';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheck } from 'common/media/icons/check.icon.svg';

import styles from './FormButtons.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isSubmitting: bool.isRequired,
  isSubmitDisabled: bool,
  isSaved: bool,
  to: string,
  saveButtonLabel: string.isRequired,
  savedButtonLabel: string,
  cancelButtonLabel: string,
  className: string,
  onClickCancelButton: func,
};

const defaultProps = {
  isSubmitDisabled: null,
  isSaved: false,
  className: null,
  savedButtonLabel: null,
  cancelButtonLabel: null,
  onClickCancelButton: null,
  to: null,
};

const FormButtons = ({
  isSubmitting,
  isSubmitDisabled,
  isSaved,
  to,
  className,
  saveButtonLabel,
  savedButtonLabel,
  cancelButtonLabel,
  onClickCancelButton,
}) => (
  <div className={cx('root', className)}>
    {!to && cancelButtonLabel && (
      <ButtonInverted
        type="button"
        className={cx('form-button')}
        onClick={onClickCancelButton}
      >
        <span className={cx('button-label')}>{cancelButtonLabel}</span>
      </ButtonInverted>
    )}

    {to && cancelButtonLabel && (
      <Link to={to} className={cx('form-button', 'button-cancel')}>
        <span className={cx('button-label')}>{cancelButtonLabel}</span>
      </Link>
    )}

    <Button
      type="submit"
      className={cx('form-button', 'button-save', {
        loading: isSubmitting,
      })}
      disabled={isSubmitting || isSubmitDisabled} // @todo: should be just isSubmitDisabled, please refactor globally˝
    >
      <span className={cx('button-label', { 'with-icon': isSaved })}>
        {isSubmitting && <SpinLoader className={cx('loader')} size={16} />}
        {isSaved ? (
          <>
            <IconContainer className={cx('icon-done')} icon={IconCheck} />
            {savedButtonLabel}
          </>
        ) : (
          saveButtonLabel
        )}
      </span>
    </Button>
  </div>
);

FormButtons.propTypes = propTypes;
FormButtons.defaultProps = defaultProps;

export default FormButtons;
