import { useMemo } from 'react';
import { object } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { COUNTRIES } from 'common/constants';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import dateFormat from '~/utils/dateFormat';
import useUserNationalities from '~/hooks/useUserNationalities';
import useCountryNameKey from '~/hooks/useCountryNameKey';

const propTypes = {
  data: object.isRequired,
};

function EditAccountPageHandler({ children, data }) {
  const { t } = useTranslation('edit-account');
  const idFieldLabel = t('idFieldLabel');
  const creationDateFieldLabel = t('creationDateFieldLabel');
  const accountStatusFieldLabel = t('accountStatusFieldLabel');
  const accountCreationFieldLabel = t('accountCreationFieldLabel');
  const firstNameFieldLabel = t('firstNameFieldLabel');
  const lastNameFieldLabel = t('lastNameFieldLabel');
  const birthDateFieldLabel = t('birthDateFieldLabel');
  const emailFieldLabel = t('emailFieldLabel');
  const phoneNumberFieldLabel = t('phoneNumberFieldLabel');
  const addressFieldLabel = t('addressFieldLabel');
  const prefferedLangFieldLabel = t('prefferedLangFieldLabel');
  const contactChannelFieldLabel = t('contactChannelFieldLabel');

  const isCustomerProfileData = !!data?.getCustomerProfile;

  const countryNameKey = useCountryNameKey();

  const userNationalities = useUserNationalities(
    data?.getCustomerProfile?.sbmIdentity?.nationalities || []
  );

  const technicalInfoPanelFields = useMemo(() => {
    if (!isCustomerProfileData) return [];

    return [
      {
        label: idFieldLabel,
        value: data.getCustomerProfile.info.id,
      },
      {
        label: creationDateFieldLabel,
        value: dateFormat(data.getCustomerProfile.info.created),
      },
      {
        label: accountStatusFieldLabel,
        value: data.getCustomerProfile.info.status,
      },
      {
        label: accountCreationFieldLabel,
        value: data.getCustomerProfile.info.creationSource,
      },
    ];
  }, [
    accountCreationFieldLabel,
    accountStatusFieldLabel,
    creationDateFieldLabel,
    data.getCustomerProfile,
    idFieldLabel,
    isCustomerProfileData,
  ]);

  const mainInfoPanelFields = useMemo(() => {
    if (!isCustomerProfileData) return [];

    if (data.getCustomerProfile.info.firstName === '')
      return [
        {
          label: emailFieldLabel,
          value: data.getCustomerProfile.info.email,
        },
      ];

    return [
      {
        label: firstNameFieldLabel,
        value: data.getCustomerProfile.info.firstName,
      },
      {
        label: lastNameFieldLabel,
        value: data.getCustomerProfile.info.lastName,
      },
      {
        label: birthDateFieldLabel,
        value: data.getCustomerProfile.info.birthDate,
      },
      {
        label: emailFieldLabel,
        value: data.getCustomerProfile.info.email,
      },
      {
        label: phoneNumberFieldLabel,
        value: data.getCustomerProfile.info.phoneNumber,
      },
    ];
  }, [
    birthDateFieldLabel,
    data.getCustomerProfile,
    emailFieldLabel,
    firstNameFieldLabel,
    isCustomerProfileData,
    lastNameFieldLabel,
    phoneNumberFieldLabel,
  ]);

  const identityFields = useMemo(() => {
    if (
      !data?.getCustomerProfile?.sbmIdentity ||
      (data.getCustomerProfile.sbmIdentity.birthName === '' &&
        data.getCustomerProfile.sbmIdentity.marriedName === '' &&
        data.getCustomerProfile.sbmIdentity.nationalities.length === 0 &&
        data.getCustomerProfile.sbmIdentity.sbmApiBirthDate === '' &&
        data.getCustomerProfile.sbmIdentity.sbmApiFirstName === '')
    )
      return {};

    return data.getCustomerProfile.sbmIdentity;
  }, [data.getCustomerProfile]);

  const contactFields = useMemo(() => {
    const profileContacts = data?.getCustomerProfile?.sbmContacts;

    if (!profileContacts) return [];

    const profileFirstAddress = profileContacts.address?.[0];

    return [
      {
        label: emailFieldLabel,
        value: profileContacts.sbmApiEmail?.[0]?.email || '',
      },
      {
        label: phoneNumberFieldLabel,
        value: profileContacts.phone?.[0]?.phone || '',
      },
      {
        label: addressFieldLabel,
        value: profileFirstAddress?.address1 || '',
      },
      {
        label: '',
        value: profileFirstAddress?.address2 || '',
      },
      {
        label: '',
        ...(profileFirstAddress && {
          value: [
            profileFirstAddress?.city,
            profileFirstAddress?.zipCode,
            profileFirstAddress?.state,
            COUNTRIES.find(({ id }) => id === profileFirstAddress?.country)?.[
              countryNameKey
            ],
          ]
            .filter(Boolean)
            .join(', '),
        }),
      },
    ];
  }, [
    data,
    emailFieldLabel,
    phoneNumberFieldLabel,
    addressFieldLabel,
    countryNameKey,
  ]);

  const contactChannelsString = useMemo(() => {
    let arr = [];

    if (!data?.getCustomerProfile?.sbmPreferences) return '';

    data.getCustomerProfile.sbmPreferences.contactChannels.map(
      ({ method, value }) => value === true && arr.push(method)
    );

    return arr.length > 0 ? arr.join(', ') : '-';
  }, [data]);

  const preferencesFields = useMemo(() => {
    if (!data?.getCustomerProfile?.sbmPreferences) return [];

    return [
      {
        label: prefferedLangFieldLabel,
        value: data.getCustomerProfile.sbmPreferences.language,
      },
      {
        label: contactChannelFieldLabel,
        value: contactChannelsString,
      },
    ];
  }, [
    data,
    prefferedLangFieldLabel,
    contactChannelFieldLabel,
    contactChannelsString,
  ]);

  const isPreferencesAvailable = useMemo(
    () => !!data?.getCustomerProfile?.sbmPreferences,
    [data]
  );

  const isContactsEditable = useMemo(
    () =>
      !!data?.getCustomerProfile?.sbmContacts
        ? data.getCustomerProfile.sbmContacts.sbmApiEmail?.some(
            ({ isMutable }) => isMutable === true
          ) ||
          data.getCustomerProfile.sbmContacts.phone?.some(
            ({ isMutable }) => isMutable === true
          ) ||
          data.getCustomerProfile.sbmContacts.address?.some(
            ({ isMutable }) => isMutable === true
          ) ||
          data.getCustomerProfile.sbmContacts.phone.length === 0 ||
          data.getCustomerProfile.sbmContacts.address.length === 0
        : false,
    [data]
  );

  const isContactsAvailable = useMemo(
    () => !!data?.getCustomerProfile?.sbmContacts,
    [data]
  );

  const isFailSafeMode = useMemo(
    () =>
      (data?.getAsyncStatus.status === 'red' ||
        data?.getAsyncStatus.status === 'orange') &&
      data?.getApiStatus.circuitBreakerOpen,
    [data]
  );

  return children({
    technicalInfoPanelFields,
    mainInfoPanelFields,
    identityFields,
    userNationalities,
    contactFields,
    preferencesFields,
    isPreferencesAvailable,
    isContactsEditable,
    isContactsAvailable,
    email: data?.getCustomerProfile?.info?.email || [],
    sbmGdpr: data?.getCustomerProfile?.sbmGdpr || [],
    foreignIds: data?.getCustomerProfile?.foreignIds || [],
    authHistory: data?.getCustomerProfile?.authHistory || [],
    accountStatus: data?.getCustomerProfile?.info?.status || '',
    myIdStatus: data?.getMyIdStatus.status,
    apiLogData: data?.getApiStatus,
    asyncMachineData: data?.getAsyncStatus,
    loyaltyData: data?.getCustomerProfile?.sbmLoyalty,
    loyaltyMachineData: data?.getLoyaltyStatus,
    isFailSafeMode,
  });
}

EditAccountPageHandler.propTypes = propTypes;

export default withErrorBoundary(EditAccountPageHandler);
