import { func, array, object } from 'prop-types';

import { ADDRESS_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';

const propTypes = {
  children: func.isRequired,
  formDispatch: func.isRequired,
  formValues: object,
  emailList: array,
  phoneList: array,
  addressList: array,
};

const defaultProps = {
  formValues: null,
  emailList: [],
  phoneList: [],
  addressList: [],
};

const AddressFieldsHandler = ({
  children,
  emailList,
  phoneList,
  addressList,
  formValues,
  formDispatch,
}) => {
  const onAddressAdd = (id) =>
    formDispatch({
      type: 'addAddress',
      phoneList: phoneList,
      emailList: emailList,
      addressList: addressList,
      formValues,
      newField: {
        name: `address1_${id}`,
        value: '',
        secondaryName: `address2_${id}`,
        secondaryValue: '',
        zipCodeName: `zipCode_${id}`,
        zipCodeValue: '',
        cityName: `city_${id}`,
        cityValue: '',
        stateName: `state_${id}`,
        stateValue: '',
        countryName: `country_${id}`,
        countryValue: '',
        catName: `address_${id}_cat`,
        catValue: ADDRESS_DEFAULT_CATEGORY,
        isMutable: true,
        isDeletable: true,
      },
    });

  const onAddressReset = (id) =>
    formDispatch({
      type: 'resetAddress',
      addressList: addressList,
    });

  const onAddressRemove = (id) =>
    formDispatch({
      type: 'deleteAddress',
      phoneList: phoneList,
      emailList: emailList,
      addressList: addressList,
      formValues,
      name: id,
    });

  return children({ onAddressAdd, onAddressRemove, onAddressReset });
};

AddressFieldsHandler.propTypes = propTypes;
AddressFieldsHandler.defaultProps = defaultProps;

export default AddressFieldsHandler;
