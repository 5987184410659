import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';

import Container from 'common/components/Container';
import GridContainer from 'common/components/GridContainer';
import TypeToComponent from 'common/containers/TypeToComponent';

import PageLoader from '~/components/PageLoader';
import FiltersBar from '~/components/FiltersBar';
import Button from '~/components/Button';
import GTM from '~/components/GTM';
import ErrorFallback from '~/components/ErrorFallback';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';
import {
  useAuthContext,
  SIGN_IN_STATE,
  SIGNED_IN_STATE,
} from '~/context/AuthContext';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useMyaccHistory } from '~/hooks';

import WishlistHandler from './containers/WishlistHandler';
import WishlistShare from './components/WishlistShare';
import WishlistUnauthorisedNotifyModal from './components/WishlistUnauthorisedNotifyModal';
import styles from './Wishlist.module.scss';

const cx = classNames.bind(styles);

const Wishlist = () => {
  const { t } = useTranslation('wishlist');
  const pageTitle = t('pageTitle');
  const noItemsLabel = t('noItems');
  const loadMoreLabel = t('loadMore');

  const {
    push: historyPush,
    location: { search },
  } = useMyaccHistory();
  const { authState } = useAuthContext();
  const { language } = useLocalisationContext();
  const { unique_share_id: uniqueShareId } = parse(search);

  if (authState === SIGN_IN_STATE && !uniqueShareId) {
    historyPush(`/${language}`);
  }

  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
    loyaltyStatus,
    wishlistIds,
    wishlistIdsDataLoading,
  } = useUserContext();

  if (wishlistIdsDataLoading) {
    return <PageLoader />;
  }

  return (
    <Container className={cx('root')} tag="main" width={1140}>
      <Helmet>
        <title>{'Wishlist'}</title>
      </Helmet>

      <h2 className={cx('title')}>{pageTitle}</h2>

      <WishlistHandler>
        {({
          typenamesToListItemsMap,
          wishlist,
          wishlistDataLoading,
          listFilters,
          activeFilter,
          onFilterToggle,
          hasNextPage,
          handleLoadMore,
          isUnauthorisedNotifyVisible,
          closeUnauthorisedNotifyModal,
          handleUnauthorisedCardClick,
          siteSettings,
        }) =>
          wishlistDataLoading || !wishlist ? (
            <PageLoader />
          ) : (
            <>
              {authState === SIGNED_IN_STATE &&
                !uniqueShareId &&
                wishlistIds?.items.length > 0 && <WishlistShare />}

              {authState === SIGN_IN_STATE && isUnauthorisedNotifyVisible && (
                <WishlistUnauthorisedNotifyModal
                  handleModalClose={closeUnauthorisedNotifyModal}
                />
              )}

              <FiltersBar
                items={listFilters}
                active={activeFilter}
                onFilterToggle={onFilterToggle}
              />

              <GridContainer className={cx('wishlist-wrapper')}>
                {wishlist.length > 0 ? (
                  wishlist.map((wishlistItem, index) => (
                    <TypeToComponent
                      mapping={typenamesToListItemsMap}
                      key={index}
                    >
                      {({ getComponent }) => {
                        const Component = getComponent(wishlistItem.__typename);
                        return (
                          <Component
                            data={wishlistItem}
                            isUnauthorised={authState === SIGN_IN_STATE}
                            {...(authState === SIGN_IN_STATE && {
                              onUnauthorisedCardClick:
                                handleUnauthorisedCardClick,
                            })}
                            closedLabel={
                              siteSettings?.globalLabels?.closedLabel
                            }
                          />
                        );
                      }}
                    </TypeToComponent>
                  ))
                ) : (
                  <div>{noItemsLabel}</div>
                )}
              </GridContainer>

              {hasNextPage && (
                <div className={cx('show-more-wrap')}>
                  <Button color="tallow" inverted onClick={handleLoadMore}>
                    {loadMoreLabel}
                  </Button>
                </div>
              )}
            </>
          )
        }
      </WishlistHandler>

      {isFailSafeMode && <ErrorFallback mode={'friendly'} />}

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
};

export default withErrorBoundary(Wishlist);
