import gql from 'graphql-tag';

export default gql`
  fragment heroImageFieldsFragment on Image {
    id
    alt
    heroDesktopWideResponsive2x: url(
      style: hero_desktop_wide_responsive_2x_webp
    ) {
      url
    }
    heroDesktopWideResponsive: url(style: hero_desktop_wide_responsive_webp) {
      url
      width
      height
    }
    heroDesktopWideMinsize2x: url(style: hero_desktop_wide_minsize_2x_webp) {
      url
    }
    heroDesktopWideMinsize: url(style: hero_desktop_wide_minsize_webp) {
      url
    }
    heroDesktopMinsize2x: url(style: hero_desktop_minsize_2x_webp) {
      url
    }
    heroDesktopMinsize: url(style: hero_desktop_minsize_webp) {
      url
    }
    heroTabletResponsive2x: url(style: hero_tablet_responsive_2x_webp) {
      url
    }
    heroTabletResponsive: url(style: hero_tablet_responsive_webp) {
      url
    }
    heroTabletMinsize2x: url(style: hero_tablet_minsize_2x_webp) {
      url
    }
    heroTabletMinsize: url(style: hero_tablet_minsize_webp) {
      url
    }
    heroMobileResponsive2x: url(style: hero_mobile_responsive_2x_webp) {
      url
    }
    heroMobileResponsive: url(style: hero_mobile_responsive_webp) {
      url
    }
    heroMobileMinsize2x: url(style: hero_mobile_minsize_2x_webp) {
      url
    }
    heroMobileMinsize: url(style: hero_mobile_minsize_webp) {
      url
    }
  }
`;
