const checkIsScrollExist = () => {
  const rect = document.body.getBoundingClientRect();
  return rect.left + rect.right < window.innerWidth;
};

const getScrollBarWidth = () => {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'modal-scrollbar-measure';
  document.body.appendChild(scrollDiv);
  const scrollbarWidth =
    scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const setScrollBarPaddingAction = () => {
  const paddingWidth = getScrollBarWidth() + 'px';
  const header = document.getElementById('main-header');

  document.body.style.paddingRight = paddingWidth;
  if (header) {
    header.style.paddingRight = paddingWidth;
  }
};

const resetScrollBarPaddingAction = () => {
  const header = document.getElementById('main-header');

  document.body.style.paddingRight = '';
  if (header) {
    header.style.paddingRight = '';
  }
};

export { setScrollBarPaddingAction, resetScrollBarPaddingAction };

export default checkIsScrollExist;
