import React, { Fragment } from 'react';
import { string, number, bool, oneOfType } from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  children: string.isRequired,
  className: string,
  highlightedClassName: string,
  index: oneOfType([number, string]).isRequired,
  isFirstHighlited: bool,
  color: string,
};

const defaultProps = {
  className: null,
  highlightedClassName: 'is-highlighted',
  isFirstHighlited: false,
  color: undefined,
};

const HighlightedText = ({
  children,
  index,
  className,
  highlightedClassName,
  isFirstHighlited,
  color,
}) => {
  const style = {
    color,
  };

  if (index < 1) {
    return children;
  }

  return (
    <Fragment>
      {isFirstHighlited ? (
        <Fragment>
          <span
            className={classNames(className, highlightedClassName)}
            style={style}
          >
            {children.slice(0, index)}
          </span>
          {children.slice(index)}
        </Fragment>
      ) : (
        <Fragment>
          {children.slice(0, index)}
          <span
            className={classNames(className, highlightedClassName)}
            style={style}
          >
            {children.slice(index)}
          </span>
        </Fragment>
      )}
    </Fragment>
  );
};

HighlightedText.propTypes = propTypes;
HighlightedText.defaultProps = defaultProps;

export default HighlightedText;
