import React from 'react';
import { connect } from 'formik';

import Select from 'common/components/FormComponents/Select';
import SelectProfessionHandler from './containers/SelectProfessionHandler';

const propTypes = {};

const defaultProps = {};

const SelectProfession = (props) => {
  return (
    <SelectProfessionHandler>
      {({ professions }) => <Select {...props} options={professions} />}
    </SelectProfessionHandler>
  );
};

SelectProfession.propTypes = propTypes;
SelectProfession.defaultProps = defaultProps;

export default connect(SelectProfession);
