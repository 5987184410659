import React, { useCallback } from 'react';
import { shape, bool, string } from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'formik';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheckboxChecked } from 'common/media/icons/check.icon.svg';

import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: bool.isRequired,
  }).isRequired,
  label: string,
  textClassName: string,
  isIconHidden: bool,
  className: string,
  classNameCheckbox: string,
  disabled: bool,
  checked: bool,
};

const defaultProps = {
  label: '',
  textClassName: null,
  isIconHidden: false,
  className: null,
  classNameCheckbox: null,
  classNameLabel: null,
  disabled: false,
  checked: false,
};

const Checkbox = ({
  field,
  field: { name, value },
  label,
  textClassName,
  isIconHidden,
  className,
  classNameCheckbox,
  classNameLabel,
  disabled,
  formik: { setFieldTouched, setFieldValue },
}) => {
  const handleChange = useCallback(() => {
    setFieldValue(name, !value);
    setFieldTouched(name);
  }, [name, setFieldTouched, setFieldValue, value]);

  const blurChange = useCallback(() => {
    setFieldTouched(name);
  }, [name, setFieldTouched]);

  return (
    <div className={cx('root', className)}>
      <input
        {...field}
        checked={value}
        type="checkbox"
        className={cx('checkbox', classNameCheckbox)}
        id={name}
        disabled={disabled}
        onChange={handleChange}
        onBlur={blurChange}
      />
      <label className={cx('label', classNameLabel)} htmlFor={name}>
        {!isIconHidden && (
          <div className={cx('box-wrapper', { checked: value })}>
            {value && (
              <IconContainer
                className={cx('icon')}
                icon={IconCheckboxChecked}
              />
            )}
          </div>
        )}
        {label && <span className={cx('text', textClassName)}>{label}</span>}
      </label>
    </div>
  );
};

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default connect(Checkbox);
