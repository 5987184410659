import React from 'react';
import { string, object, func } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconEye } from 'common/media/icons/eye.icon.svg';
import { ReactComponent as IconEyeOff } from 'common/media/icons/view-off.icon.svg';

import styles from './ColumnHeader.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  label: string.isRequired,
  columnVisibility: object.isRequired,
  setColumnVisibility: func.isRequired,
  modifier: string.isRequired,
};

const ColumnHeader = ({
  label,
  columnVisibility,
  setColumnVisibility,
  modifier,
}) => {
  return (
    <td className={cx('table-header')}>
      {columnVisibility[modifier] ? label : '...'}
      <button
        className={cx('hide-button')}
        onClick={() => {
          setColumnVisibility({
            ...columnVisibility,
            [modifier]: !columnVisibility[modifier],
          });
        }}
      >
        <IconContainer
          className={cx('icon-eye', { checked: !columnVisibility[modifier] })}
          icon={columnVisibility[modifier] ? IconEyeOff : IconEye}
        />
      </button>
    </td>
  );
};

ColumnHeader.propTypes = propTypes;

export default ColumnHeader;
