import { useMemo } from 'react';

import { COUNTRIES } from 'common/constants';

import { useLocalisationContext } from '~/context/LocalisationContext';

//Priorities are alpha3Codes (id) from packages/common/constants/countries.js
const priorityCountryCodes = [
  'FRA',
  'USA',
  'ITA',
  'GBR',
  'MCO',
  'DEU',
  'CAN',
  'CHE',
  'RUS',
  'BEL',
];

export default function usePrioritisedCountries() {
  const { language } = useLocalisationContext();

  const sortingKey = useMemo(() => {
    if (language === 'fr') {
      return 'nameFr';
    }

    if (language === 'it') {
      return 'nameIt';
    }

    return 'name';
  }, [language]);

  const prioritisedCountries = useMemo(() => {
    const priorityCountryArray = priorityCountryCodes.map((countryCode) =>
      COUNTRIES.find(({ id }) => id === countryCode)
    );

    const restCountries = COUNTRIES.filter(
      ({ id }) => !priorityCountryCodes.includes(id)
    ).sort((a, b) => a[sortingKey].localeCompare(b[sortingKey]));

    return priorityCountryArray.concat(restCountries);
  }, [sortingKey]);

  return prioritisedCountries;
}
