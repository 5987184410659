import React, { useEffect, useRef, useState, useMemo } from 'react';
import { WidgetInstance } from 'friendly-challenge';
import { string } from 'prop-types';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useSiteSettingsContext } from '~/context/SiteSettingsContext';

const propTypes = {
  id: string,
};

const defaultProps = {
  id: null,
};

function useFriendlyCaptcha(props) {
  const captchaEl = useRef(null);
  const widget = useRef(null);

  const { friendlyCaptchaKey } = useSiteSettingsContext();
  const { language } = useLocalisationContext();

  const [captchaStatus, setCaptchaStatus] = useState({
    solution: null,
    loading: false,
    error: null,
  });

  const captchaReset = () => {
    widget.current.reset();
  };

  const startHandler = (solution) => {
    setCaptchaStatus({ solution: solution, loading: true, error: null });
  };

  const doneHandler = (solution) => {
    setCaptchaStatus({ solution: solution, loading: false, error: null });
  };

  const errorHandler = (error) => {
    console.log(error.description);
    setCaptchaStatus({ solution: null, loading: false, error: error });
  };

  useEffect(() => {
    if (friendlyCaptchaKey && !widget.current && captchaEl.current) {
      widget.current = new WidgetInstance(captchaEl.current, {
        language,
        startedCallback: startHandler,
        doneCallback: doneHandler,
        errorCallback: errorHandler,
      });
    }

    return () => {
      if (!!widget.current) {
        widget.current.destroy();
        widget.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [friendlyCaptchaKey, captchaEl]);

  const CaptchaWidget = useMemo(
    () =>
      ({ className }) =>
        friendlyCaptchaKey ? (
          <div
            ref={captchaEl}
            id={`frc-captcha-widget${props?.id ? '-' + props.id : ''}`}
            className={'frc-captcha' + (className ? ` ${className}` : '')}
            data-sitekey={friendlyCaptchaKey}
          />
        ) : null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [friendlyCaptchaKey]
  );

  if (!friendlyCaptchaKey) {
    return {
      captchaReset: () => {},
      captchaStatus,
      CaptchaWidget: () => null,
    };
  }

  return {
    captchaReset,
    captchaStatus,
    CaptchaWidget,
  };
}

useFriendlyCaptcha.propTypes = propTypes;
useFriendlyCaptcha.defaultProps = defaultProps;

export default useFriendlyCaptcha;
