import React from 'react';
import { string, func, number } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './LoadMoreButton.module.scss';
import Button from 'common/components/Button';
import SpinLoader from 'common/components/SpinLoader';
import { useToggle } from 'common/hooks';

const cx = classNames.bind(styles);

const propTypes = {
  loadMoreButtonLabel: string.isRequired,
  loadMorePage: number.isRequired,
  setLoadMorePage: func.isRequired,
  className: string,
};

const defaultProps = {
  className: null,
};

function LoadMoreButton({
  loadMoreButtonLabel,
  setLoadMorePage,
  loadMorePage,
  className,
}) {
  const [spinVisible, toggleSpinVisible] = useToggle(false);

  const onClickHandle = () => {
    toggleSpinVisible(true);

    setTimeout(function() {
      toggleSpinVisible(false);
      setLoadMorePage(loadMorePage + 1);
    }, 300);
  };

  return (
    <Button
      type="button"
      className={cx('root', className)}
      onClick={onClickHandle}
    >
      {spinVisible && <SpinLoader className={cx('loader')} size={16} />}
      <span>{loadMoreButtonLabel}</span>
    </Button>
  );
}

LoadMoreButton.propTypes = propTypes;
LoadMoreButton.defaultProps = defaultProps;

export default LoadMoreButton;
