import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { gql } from '@apollo/client';
import classNames from 'classnames/bind';

import ContentLoader from 'common/components/ContentLoader';
import Container from 'common/components/Container';
import GridContainer from 'common/components/GridContainer';
import GTM from '~/components/GTM';

import Button from '~/components/Button';
import { useUserContext } from '~/context/UserContext';
import ErrorFallback from '~/components/ErrorFallback';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import OfferCard from '~/components/OfferCard';
import PageLoader from '~/components/PageLoader';
import FiltersBar from '~/components/FiltersBar';

import OffersListingHandler from './containers/OffersListingHandler';
import OffersFiltersHandler from './containers/OffersFiltersHandler';
import styles from './OffersList.module.scss';

const cx = classNames.bind(styles);

const FiltersQuery = gql`
  query FiltersList(
    $type: ListingType!
    $points: ListingFilterInputPoints!
    $attributes: [ListingFilterInputAttributes]
    $includePoints: Boolean!
  ) {
    listing(type: $type) {
      id
      items(myacc: true, attributes: $attributes) {
        targetToSellItemId
        filters {
          attributes {
            type
            attributes {
              id
              count
              name
            }
          }
        }
      }
      myPoints: items(myacc: true, points: $points, attributes: $attributes)
        @include(if: $includePoints) {
        targetToSellItemId
        count
      }
      allItems: items(myacc: true, attributes: $attributes) {
        targetToSellItemId
        count
      }
    }
  }
`;

const OffersDataQuery = gql`
  query OffersList(
    $type: ListingType!
    $first: Int
    $after: Int
    $attributes: [ListingFilterInputAttributes]
    $points: ListingFilterInputPoints
  ) {
    listing(type: $type) {
      id
      items(
        myacc: true
        first: $first
        after: $after
        attributes: $attributes
        points: $points
      ) {
        targetToSellItemId
        count
        hasNextPage
        loadMoreButtonLabel
        endCursor
        results {
          ... on Offer {
            ...offerCardFragment
          }
        }
      }
    }
  }
  ${OfferCard.fragments.offerCardFragment}
`;

const OffersList = () => {
  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
    loyaltyStatus,
  } = useUserContext();

  const { t } = useTranslation('offers-info');
  const pageTitle = t('OffersListTitle');

  if (loadingUserData || !loyaltyStatus) {
    return <PageLoader />;
  }

  return (
    <Container tag="main" width={1140}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <OffersFiltersHandler
        query={FiltersQuery}
        loyaltyStatus={loyaltyStatus}
        myPoints={userSbmLoyalty.rewardPoints}
      >
        {({ listFilters, activeFilter, onFilterToggle }) => (
          <>
            <FiltersBar
              items={listFilters}
              active={activeFilter}
              onFilterToggle={onFilterToggle}
            />

            <OffersListingHandler
              query={OffersDataQuery}
              activeFilter={activeFilter}
              myPoints={userSbmLoyalty.rewardPoints}
              loyaltyStatus={loyaltyStatus}
            >
              {({
                loadMore,
                results,
                loadMoreButtonLabel,
                hasNextPage,
                fetchMoreLoading,
              }) => (
                <>
                  <GridContainer className={cx('offers-wrapper')}>
                    {results?.map((offer, i) => (
                      <OfferCard key={i} data={offer} />
                    ))}
                  </GridContainer>

                  {hasNextPage && !fetchMoreLoading && (
                    <div className={cx('show-more-wrap')}>
                      <Button color="tallow" inverted onClick={loadMore}>
                        {loadMoreButtonLabel}
                      </Button>
                    </div>
                  )}

                  {fetchMoreLoading && <ContentLoader />}
                </>
              )}
            </OffersListingHandler>
          </>
        )}
      </OffersFiltersHandler>

      {isFailSafeMode && <ErrorFallback mode={'friendly'} />}

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
};

export default withErrorBoundary(OffersList);
