import { UNKNOWN_CATEGORY } from '~/scenes/FormContacts/constants';

export const handleEmptyCategory = (array) => {
  if (array?.length) {
    array.forEach((item) => {
      if (!!item.category !== undefined && item.category === '') {
        item.category = UNKNOWN_CATEGORY;
      }
    });
  }
};
