import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import styles from './SearchResultsLegend.module.scss';

const cx = classNames.bind(styles);

function SearchResultsLegend() {
  const { t } = useTranslation('maintenance');

  return (
    <div className={cx('legend')}>
      <span className={cx('legend-item', 'confirmed')}>
        {t('confirmedLabel')}
      </span>
      <span className={cx('legend-item', 'not-confirmed')}>
        {t('notConfirmedLabel')}
      </span>
      <span className={cx('legend-item', 'warnings', 'font-black')}>
        {t('warningLabel')}
      </span>
      <span className={cx('legend-item', 'errors')}>{t('errorLabel')}</span>
    </div>
  );
}

export default SearchResultsLegend;
