import { useState, useCallback } from 'react';
import { bool, func, array, string } from 'prop-types';
import { useMutation } from '@apollo/client';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { CLIENT_APP_VERSION } from '~/constants';
import { useUserContext } from '~/context/UserContext';

import { PrivacySendMutation, EditPrivacyInfoSendMutation } from '../../utils';
import useGtmPrivacy from '../../hooks/useGtmPrivacy';

const gtmPrivacyParams = {
  event: 'save_privacy_settings',
};

const propTypes = {
  isMyCs: bool,
  userHash: string,
  data: array.isRequired,
  children: func,
  setPrivacyDataUpdated: func,
};

const defaultProps = {
  isMyCs: false,
  userHash: null,
  children: null,
  setPrivacyDataUpdated: null,
};

const FormPrivacyHandler = ({
  isMyCs,
  userHash,
  data,
  children,
  setPrivacyDataUpdated,
}) => {
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const { refetchUserData } = useUserContext();

  const resetMessage = useCallback(() => {
    if (submitError || submitSuccess) {
      setSubmitError(false);
      setSubmitSuccess(false);
    }
  }, [submitError, submitSuccess]);

  const initialValues = data.reduce((acc, item) => {
    acc[`consent${item.id}`] = item.value === 'Y';
    acc[item.id] = item.value;
    return acc;
  }, {});

  const { gtmSendSubscriptions } = useGtmPrivacy({ initialValues });

  const [mutate] = useMutation(
    isMyCs ? EditPrivacyInfoSendMutation : PrivacySendMutation
  );

  const handleSubmit = (values, { setSubmitting }) => {
    const submitFields = Object.keys(values)
      .filter((key) => !key.includes('consent')) // exclude switch names and leave just a converted values
      .map((key) => {
        return {
          id: Number(key),
          value: values[key],
          clientAppVersion: CLIENT_APP_VERSION,
        };
      });

    if (isMyCs) {
      // MyCS My Customer Service
      mutate({
        variables: {
          id: userHash,
          value: submitFields,
        },
      })
        .then(({ data: { updateCustomerSbmGdpr } }) => {
          setSubmitting(false);

          if (updateCustomerSbmGdpr) {
            setSubmitSuccess(true);
          }
        })
        .catch((error) => {
          console.warn(error);
          setSubmitError(true);
        });
    } else {
      // MyAC My Account
      mutate({
        variables: { value: submitFields },
      })
        .then(({ data: { updateSbmGdpr } }) => {
          setSubmitting(false);

          if (updateSbmGdpr) {
            setSubmitSuccess(true);
            gtmSendSubscriptions(submitFields);
            refetchUserData();
            gtmPushEvent(gtmPrivacyParams);
          }

          if (setPrivacyDataUpdated) {
            setPrivacyDataUpdated(true);
          }
        })
        .catch((error) => {
          console.warn(error);
          setSubmitError(true);
        });
    }
  };

  return children({
    handleSubmit,
    initialValues,
    submitError,
    submitSuccess,
    resetMessage,
  });
};

FormPrivacyHandler.propTypes = propTypes;
FormPrivacyHandler.defaultProps = defaultProps;

export default withErrorBoundary(FormPrivacyHandler);
