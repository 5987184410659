import Footer from './Footer';
import SocialShare from './components/SocialShare';
import SideMenu from './components/SideMenu';
import Telephone from './components/Telephone';
import Subscribe from './components/Subscribe';
import SecMenu from './components/SecMenu';
import AdditionalInfo from './components/AdditionalInfo';
import BrandLogo from './components/BrandLogo';

export {
  SocialShare,
  SideMenu,
  Telephone,
  Subscribe,
  SecMenu,
  AdditionalInfo,
  BrandLogo,
};

export default Footer;
