import React from 'react';
import { shape, func, bool, string, oneOf } from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'formik';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';
import { ReactComponent as IconClose } from 'common/media/icons/close.icon.svg';
import IconContainer from 'common/components/IconContainer';

import Select from './components/Select';
import SelectDateHandler from './containers/SelectDateHandler';
import styles from './SelectDate.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({
    setFieldValue: func,
    setFieldTouched: func,
  }).isRequired,
  placeholder: string,
  placeholderYear: string,
  placeholderMonth: string,
  placeholderDay: string,
  required: bool,
  disabled: bool,
  lastAvailableDate: string,
  dateFormat: oneOf(['DD/MM/YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD']),
  className: string,
  itemClassName: string,
  errorClassName: string,
  isCleanable: bool,
  theme: oneOf(['block-theme']),
};

const defaultProps = {
  placeholder: null,
  placeholderYear: 'YYYY',
  placeholderMonth: 'MM',
  placeholderDay: 'DD',
  required: false,
  disabled: false,
  lastAvailableDate: '',
  dateFormat: 'YYYY-MM-DD',
  className: null,
  itemClassName: null,
  errorClassName: null,
  isCleanable: true,
  theme: null,
};

const SelectDate = ({
  className,
  itemClassName,
  placeholder,
  placeholderYear,
  placeholderMonth,
  placeholderDay,
  required,
  disabled,
  field: { name, value },
  form: { setFieldValue, setFieldTouched, errors, touched },
  lastAvailableDate,
  dateFormat,
  errorClassName,
  isCleanable,
  theme,
}) => {
  return (
    <div className={cx('root', className, theme)}>
      {placeholder && (
        <span
          className={cx('date-label', {
            'label-error': errors[name] && touched[name],
          })}
        >
          {placeholder}
          {required && <span>{' *'}</span>}
        </span>
      )}

      <div className={cx('date-wrapper')}>
        <SelectDateHandler
          value={value}
          name={name}
          lastAvailableDate={lastAvailableDate}
          setFieldValue={setFieldValue}
          dateFormat={dateFormat}
        >
          {({
            selectedDay,
            selectedMonth,
            selectedYear,
            daysList,
            monthsList,
            yearsList,
            dayChangeHandler,
            monthChangeHandler,
            yearChangeHandler,
            resetSelectDate,
          }) => (
            <>
              <Select
                value={selectedDay && selectedDay.toString()}
                name={`${name}-select-day`}
                theme={theme}
                placeholder={placeholderDay}
                onChange={dayChangeHandler}
                onFocus={() => setFieldTouched(name)}
                highlighted={!selectedDay && !!(selectedMonth || selectedYear)}
                className={cx('select-day-item', 'select', itemClassName)}
                disabled={disabled}
              >
                {daysList.map((day, index) => (
                  <option key={index} value={day}>
                    {day}
                  </option>
                ))}
              </Select>
              <Select
                value={selectedMonth && selectedMonth.toString()}
                name={`${name}-select-month`}
                placeholder={placeholderMonth}
                theme={theme}
                onChange={monthChangeHandler}
                onFocus={() => setFieldTouched(name)}
                highlighted={!selectedMonth && !!(selectedDay || selectedYear)}
                className={cx('select-month-item', 'select', itemClassName)}
                disabled={disabled}
              >
                {monthsList.map((month, index) => (
                  <option key={index} value={index + 1}>
                    {month}
                  </option>
                ))}
              </Select>
              <Select
                value={selectedYear && selectedYear.toString()}
                name={`${name}-select-year`}
                placeholder={placeholderYear}
                theme={theme}
                onChange={yearChangeHandler}
                onFocus={() => setFieldTouched(name)}
                highlighted={!selectedYear && !!(selectedDay || selectedMonth)}
                className={cx('select-year-item', 'select', itemClassName)}
                disabled={disabled}
              >
                {yearsList.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
              {isCleanable && (
                <button
                  type="button"
                  className={cx('reset', { 'reset-disabled': disabled })}
                  onClick={disabled ? () => {} : resetSelectDate}
                >
                  <IconContainer
                    icon={IconClose}
                    className={cx('icon-close')}
                  />
                </button>
              )}
            </>
          )}
        </SelectDateHandler>
      </div>
      <ErrorMessageCustom name={name}>
        {({ msg }) => (
          <FieldError errorClassName={cx('error-message', errorClassName)}>
            {msg}
          </FieldError>
        )}
      </ErrorMessageCustom>
    </div>
  );
};

SelectDate.propTypes = propTypes;
SelectDate.defaultProps = defaultProps;

export default connect(SelectDate);
