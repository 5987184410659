import moment from 'moment';

import padStartZeros from 'common/utils/padStartZeros';

export function getDay(date, dateFormat) {
  return parseInt(moment(date, dateFormat).format('DD'), 10);
}

export function getMonth(date, dateFormat) {
  return parseInt(moment(date, dateFormat).format('MM'), 10);
}

export function getYear(date, dateFormat) {
  return parseInt(moment(date, dateFormat).format('YYYY'), 10);
}

export function daysInMonth(month, year) {
  if (!month) {
    return 31;
  }
  if (!year) {
    return moment(padStartZeros(month), 'MM').daysInMonth(); // int
  }
  return moment(`${year}-${padStartZeros(month)}`, 'YYYY-MM').daysInMonth(); // int
}
