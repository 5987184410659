import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';
import { parse } from 'query-string';

import {
  authenticatedUserApolloClient,
  anonymousUserApolloClient,
} from '~/apolloClient';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useAuthContext } from '~/context/AuthContext';
import {
  UpdateOneclickQuery,
  PreferencesLanguageSendMutation,
} from '~/queries';
import { CLIENT_APP_VERSION } from '~/constants';
import { useLocalisationContext } from '~/context/LocalisationContext';
import gtmPushEvent from 'common/utils/gtmPushEvent';

const gtmSignupConfirmationParams = {
  event: 'complete_account_registration_flow',
};

const propTypes = {
  children: func.isRequired,
};

const SetPasswordOneClickHandler = ({ children }) => {
  const {
    push: historyPush,
    location: { search },
  } = useHistory();

  const { hash: oneClickUserHash } = parse(search);

  const { signIn } = useAuthContext();

  const { languageISO3, setOneclickSuspenseLang } = useLocalisationContext();

  const setPasswordOneClickHandler = (
    {
      birthDate,
      name,
      gender,
      familyName,
      login,
      marriedName,
      password,
      phoneNumber,
    },
    { setSubmitting }
  ) => {
    anonymousUserApolloClient
      .mutate({
        mutation: UpdateOneclickQuery,
        variables: {
          value: {
            hash: oneClickUserHash,
            password,
            gender,
            name: name,
            familyName: familyName,
            marriedName,
            birthDate: birthDate,
            phoneNumber,
            clientAppVersion: CLIENT_APP_VERSION,
          },
        },
      })
      .then(
        ({
          data: {
            updateSbmOneclickDataInput: { result, messages },
          },
        }) => {
          if (result && !messages.error.length) {
            setOneclickSuspenseLang(true);

            gtmPushEvent(gtmSignupConfirmationParams);

            signIn({
              username: login.toLowerCase(),
              password,
              rememberMe: false,
            }).then(() => {
              authenticatedUserApolloClient
                .mutate({
                  mutation: PreferencesLanguageSendMutation,
                  variables: { value: { language: languageISO3 } },
                })
                .then(() => {
                  setSubmitting(false);
                  historyPush('/');
                })
                .catch((error) => {
                  console.warn(error);
                });
            });
          } else {
            console.error('Something went wrong!', messages);
          }
        }
      )
      .catch((error) => {
        console.warn(error);
      });
  };

  return children({ setPasswordOneClickHandler });
};

SetPasswordOneClickHandler.propTypes = propTypes;

export default withErrorBoundary(SetPasswordOneClickHandler);
