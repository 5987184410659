import React, { useMemo } from 'react';
import classNames from 'classnames/bind';
import { bool, string } from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './OfferTags.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  discount: bool,
  endDate: string,
  className: string,
};

const defaultProps = {
  discount: false,
  className: null,
  endDate: null,
};

const OfferTags = function ({ discount, endDate, className }) {
  const { t } = useTranslation('offers-info');
  const promotionTag = t('promotionTag');
  const lastDays = t('lastDaysTag');

  const isLastDays = useMemo(
    function () {
      if (!endDate) return false;
      const maxDuration = 7;
      const duration = moment(endDate).diff(moment(), 'days');
      return duration <= maxDuration && duration >= 0;
    },
    [endDate]
  );

  return discount || isLastDays ? (
    <ul className={cx('tag-list', className)}>
      {discount && <li className={cx('tag')}>{promotionTag}</li>}
      {isLastDays && <li className={cx('tag')}>{lastDays}</li>}
    </ul>
  ) : null;
};

OfferTags.propTypes = propTypes;
OfferTags.defaultProps = defaultProps;

export default withErrorBoundary(OfferTags);
