import React from 'react';
import { string, bool, object, shape } from 'prop-types';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';

import styles from './TextArea.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  field: shape({
    name: string.isRequired,
    value: string.isRequired,
  }).isRequired,
  form: shape({
    errors: object.isRequired,
    touched: object.isRequired,
  }).isRequired,
  required: bool,
  placeholder: string,
  disabled: bool,
  className: string,
  classNameWrapper: string,
};

const defaultProps = {
  required: false,
  placeholder: null,
  disabled: false,
  className: null,
  classNameWrapper: null,
};

const TextArea = ({
  field,
  field: { name, value },
  form: { errors, touched },
  placeholder,
  required,
  disabled,
  className,
  classNameWrapper,
}) => {
  return (
    <div className={cx('root', classNameWrapper, { disabled: disabled })}>
      <textarea
        {...field}
        className={cx('textarea', className, {
          error: errors[name] && touched[name],
        })}
        disabled={disabled}
        maxLength={1000}
      />

      {placeholder && (
        <label
          className={cx('message-placeholder', {
            required: required,
            transformed: value || (touched[name] && errors[name]),
          })}
        >
          {placeholder}
        </label>
      )}

      <ErrorMessageCustom name={name}>
        {({ msg }) => <FieldError>{msg}</FieldError>}
      </ErrorMessageCustom>
    </div>
  );
};

TextArea.propTypes = propTypes;
TextArea.defaultProps = defaultProps;

export default TextArea;
