import React from 'react';
import { bool, string, array, func } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import Switch from 'common/components/FormComponents/Switch';
import InformationPanel from 'common/components/InformationPanel';

import FieldDescription from '~/components/FormComponents/FieldDescription';
import FormButtons from '~/components/FormButtons';
import ConsentHandler from '~/components/FormComponents/ConsentHandler';

import FormPrivacyHandler from './containers/FormPrivacyHandler';
import styles from './FormPrivacy.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isMyCs: bool,
  userHash: string,
  data: array.isRequired,
  redirectTo: string,
  setPrivacyDataUpdated: func,
};

const defaultProps = {
  isMyCs: false,
  userHash: null,
  redirectTo: null,
  setPrivacyDataUpdated: null,
};

function FormPrivacy({
  isMyCs,
  userHash,
  data,
  redirectTo,
  setPrivacyDataUpdated,
}) {
  const { t } = useTranslation(['privacy', 'common']);

  const metaConsentsIds = t('privacy:_meta.consentsIds', {
    returnObjects: true,
  });
  const consents = t('privacy:consents', { returnObjects: true });
  const sourceLabel = t('privacy:sourceLabel');
  const dateLabel = t('privacy:dateLabel');
  const messageError = t('privacy:messageError');

  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  return (
    <FormPrivacyHandler
      isMyCs={isMyCs}
      userHash={userHash}
      data={data}
      setPrivacyDataUpdated={setPrivacyDataUpdated}
    >
      {({
        handleSubmit,
        initialValues,
        submitError,
        submitSuccess,
        resetMessage,
      }) => (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form onChange={resetMessage}>
              {data.map(({ id, source, date }) => {
                const consent = consents?.[metaConsentsIds[id.toString()]];

                return (
                  !!consent && (
                    <div className={cx('field-wrapper')} key={id}>
                      <FieldDescription className={cx('title')}>
                        {consent?.fieldTitle}
                      </FieldDescription>
                      <div className={cx('field')}>
                        <div className={cx('description')}>
                          <FieldDescription className={cx('text')}>
                            {consent?.fieldText}
                          </FieldDescription>
                          <div className={cx('text-highlighted')}>
                            <span className={cx('text-margin')}>
                              {sourceLabel} {source}
                            </span>
                            {date && `${dateLabel} ${date}`}
                          </div>
                        </div>
                        <Field
                          className={cx('form-switch')}
                          name={`consent${id}`}
                          component={Switch}
                        />
                        <Field
                          name={`${id}`}
                          component={ConsentHandler}
                          dataConsent={initialValues[id]}
                          handledFieldName={`consent${id}`}
                        />
                      </div>
                    </div>
                  )
                );
              })}
              {submitError && (
                <InformationPanel
                  className={cx('info-panel')}
                  withIcon
                  errorMode
                >
                  {messageError}
                </InformationPanel>
              )}
              <FormButtons
                isSubmitting={isSubmitting}
                isSaved={submitSuccess}
                to={redirectTo}
                savedButtonLabel={savedButtonLabel}
                cancelButtonLabel={cancelButtonLabel}
                saveButtonLabel={saveButtonLabel}
              />
            </Form>
          )}
        </Formik>
      )}
    </FormPrivacyHandler>
  );
}

FormPrivacy.propTypes = propTypes;
FormPrivacy.defaultProps = defaultProps;

export default FormPrivacy;
