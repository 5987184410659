import React from 'react';
import classNames from 'classnames/bind';

import { FullscreenContentWrapper } from '~/components/FullscreenContainer';
import PageLoader from '~/components/PageLoader';

import styles from './SuspenseTemplate.module.scss';

const cx = classNames.bind(styles);

const SuspenseTemplate = () => (
  <div className={cx('root')}>
    <div className={cx('header')}>
      <div className={cx('burger')}>
        <div className={cx('line-container')}>
          <span className={cx('line')} />
        </div>
        <div className={cx('burger-text')}>Мenu</div>
      </div>
      <div className={cx('logo')} />
    </div>
    <div className={cx('main-grid')}>
      <div className={cx('content')}>
        <FullscreenContentWrapper vertical={'top'}>
          <PageLoader />
        </FullscreenContentWrapper>
      </div>
    </div>
  </div>
);

export default SuspenseTemplate;
