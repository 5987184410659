import React from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as HeartIcon } from 'common/media/icons/heart.icon.svg';

import { useAuthContext, SIGN_IN_STATE } from '~/context/AuthContext';

import WishlistButtonContainer from './containers/WishlistButtonContainer';
import styles from './WishlistButton.module.scss';

const propTypes = {
  id: string.isRequired,
};

const cx = classNames.bind(styles);

const WishlistButton = ({ id }) => {
  const { authState } = useAuthContext();

  if (authState === SIGN_IN_STATE) {
    return null;
  }

  return (
    <WishlistButtonContainer id={id}>
      {({ isIncludedInWishlist, handleWishlistClick }) => {
        return (
          <button
            className={cx('root')}
            type="button"
            onClick={handleWishlistClick}
          >
            <IconContainer
              icon={HeartIcon}
              className={cx('icon', { active: isIncludedInWishlist })}
            />
          </button>
        );
      }}
    </WishlistButtonContainer>
  );
};

WishlistButton.propTypes = propTypes;

export default WishlistButton;
