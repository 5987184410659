import { useState, useCallback } from 'react';

export default function useWebkitAutofillFix(value) {
  const [isAutoFilled, setIsAutoFilled] = useState(false);

  const disableWebkitAutofillFix = useCallback(() => {
    if (!value && isAutoFilled) {
      setIsAutoFilled(false);
    }
  }, [isAutoFilled, value]);

  const webkitAutofillFix = useCallback(() => {
    if (!value) {
      setIsAutoFilled(true);
    }
  }, [value]);

  return {
    isAutoFilled,
    disableWebkitAutofillFix,
    webkitAutofillFix,
  };
}
