import React from 'react';
import { object, func } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconFacebook } from 'common/media/icons/social-facebook.icon.svg';
import { ReactComponent as IconInstagram } from 'common/media/icons/social-instagram.icon.svg';
import { ReactComponent as IconTwitter } from 'common/media/icons/social-twitter.icon.svg';
import { ReactComponent as IconTiktok } from 'common/media/icons/social-tiktok.icon.svg';
import { ReactComponent as IconYoutube } from 'common/media/icons/social-youtube.icon.svg';
import { ReactComponent as IconLinkedin } from 'common/media/icons/social-linkedin.icon.svg';
import { ReactComponent as IconWechat } from 'common/media/icons/social-wechat.icon.svg';

import styles from './SocialShare.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  facebookLink: object,
  instagramLink: object,
  linkedinLink: object,
  twitterLink: object,
  tiktokLink: object,
  youtubeLink: object,
  wechatLink: object,
  LinkComponent: func.isRequired,
};

const defaultProps = {
  facebookLink: null,
  instagramLink: null,
  linkedinLink: null,
  twitterLink: null,
  tiktokLink: null,
  youtubeLink: null,
  wechatLink: null,
};

const SocialShare = ({
  facebookLink,
  instagramLink,
  linkedinLink,
  twitterLink,
  tiktokLink,
  youtubeLink,
  wechatLink,
  LinkComponent,
}) => {
  const socialLinks = [
    { link: facebookLink, icon: IconFacebook },
    { link: instagramLink, icon: IconInstagram },
    { link: twitterLink, icon: IconTwitter },
    { link: tiktokLink, icon: IconTiktok },
    { link: youtubeLink, icon: IconYoutube },
    { link: linkedinLink, icon: IconLinkedin },
    { link: wechatLink, icon: IconWechat },
  ];

  return (
    <div className={cx('root')}>
      <ul className={cx('share-list')}>
        {socialLinks.map(({ link, icon }, index) =>
          link ? (
            <li key={index}>
              <LinkComponent
                className={cx('share-item')}
                to={link.url}
                rel="nofollow"
                target="_blank"
              >
                <span className="screen-reader">{link.text}</span>
                <IconContainer icon={icon} />
              </LinkComponent>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

SocialShare.propTypes = propTypes;
SocialShare.defaultProps = defaultProps;

export default SocialShare;
