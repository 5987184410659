import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import HighlightedText from 'common/components/HighlightedText';
import Panel from '~/components/Panel';
import PromoBanner from '~/components/PromoBanner';
import InfoMessages from '~/components/InfoMessages';
import IntroSection from '~/components/IntroSection';
import LoyaltyCard from '~/scenes/LoyaltyCard';
import LoyaltyMembershipBlock from '~/scenes/LoyaltyMembershipBlock';
import CardVerification from '~/scenes/CardVerification';
import RelativeMember from '~/components/RelativeMember';
import ExclusiveAdvantages from '~/components/ExclusiveAdvantages';
import Advantages from '~/components/Advantages';

import styles from './MyMCTab.module.scss';

const cx = classNames.bind(styles);

const MyMCTab = ({ loyaltyData, identityFields, refetch }) => {
  const [isLoyaltyMembership, setIsLoyaltyMembership] = useState(true);
  const [mymcNumberVerificationData, setMymcNumberVerificationData] = useState(
    null
  );
  const loyaltyStatus = useMemo(() => loyaltyData.status || '1', [loyaltyData]);

  const { t } = useTranslation('loyalty-program');
  const infoLabels = t('messages', {
    returnObjects: true,
  });
  const pageIntroGreeting = t('pageIntroGreeting');
  const pageIntroText = t('pageIntroText', {
    returnObjects: true,
  });

  const {
    description,
    messages,
    joining,
    loyalty,
    cards,
    infoStatus,
    infoAdvantages,
  } = loyaltyData.blocks;

  return (
    <div>
      <PromoBanner status={loyaltyStatus} />
      <Panel>
        <h3 className={cx('title')}>
          <HighlightedText
            index={pageIntroGreeting.length}
            className={cx('hightlighted-text')}
          >{`${pageIntroGreeting} ${
            identityFields.sbmApiFirstName
          } ${identityFields.marriedName ||
            identityFields.birthName}`}</HighlightedText>
        </h3>
        {messages && <InfoMessages messages={messages} labels={infoLabels} />}
        {description && (
          <IntroSection
            description={description}
            introText={pageIntroText}
            className={cx('intro-section')}
          />
        )}
        {isLoyaltyMembership && (joining?.linking || joining?.enrollment) && (
          <LoyaltyMembershipBlock
            joining={joining}
            setMymcNumberVerificationData={setMymcNumberVerificationData}
            setIsLoyaltyMembership={setIsLoyaltyMembership}
            isMyCS
          />
        )}
        {loyalty && <LoyaltyCard data={loyalty} />}
        {!!mymcNumberVerificationData && (
          <CardVerification
            mymcNumberVerificationData={mymcNumberVerificationData}
            setMymcNumberVerificationData={setMymcNumberVerificationData}
            setIsLoyaltyMembership={setIsLoyaltyMembership}
            refetch={refetch}
          />
        )}
        {cards && <RelativeMember data={cards} />}
        {infoAdvantages && <ExclusiveAdvantages />}
        {infoStatus && <Advantages className={cx('advantages-block')} />}
      </Panel>
    </div>
  );
};

export default MyMCTab;
