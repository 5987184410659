import { TABLET_MEDIA_STR, MOBILE_MEDIA_STR } from 'common/constants';

export default function getWidgetSources({
  widgetImageDesktop,
  widgetImageDesktop2x,

  widgetImageTablet,
  widgetImageTablet2x,

  widgetImageMobile,
  widgetImageMobile2x,
}) {
  return [
    {
      srcSet: `${widgetImageMobile.url} 1x, ${widgetImageMobile2x.url} 2x`,
      media: MOBILE_MEDIA_STR,
      type: 'image/webp',
    },
    {
      srcSet: `${widgetImageTablet.url} 1x, ${widgetImageTablet2x.url} 2x`,
      media: TABLET_MEDIA_STR,
      type: 'image/webp',
    },
    {
      srcSet: `${widgetImageDesktop.url} 1x, ${widgetImageDesktop2x.url} 2x`,
      type: 'image/webp',
    },
  ];
}
