import React from 'react';
import { bool, func, number, string } from 'prop-types';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';

import { MD_ITEM_LIST, MD_LIST_ITEM } from 'common/constants/microdata';
import OpenStatus from 'common/components/OpenStatus';
import checkIfOpenStatus from 'common/utils/checkIfOpenStatus';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import PushLeftCol from '~/components/PushComponents/PushLeftCol';
import PushLayoutContainer, {
  PushLayoutContainerLeftCol,
  PushLayoutContainerRightCol,
} from '~/components/PushComponents/PushLayoutContainer';
import PushRightColHeader, {
  PushRightColHeaderRightCol,
} from '~/components/PushComponents/PushRightColHeader';
import PushRightColButton from '~/components/PushComponents/PushRightColButton';
import PushRightColFooter from '~/components/PushComponents/PushRightColFooter';
import PushRightColCenter, {
  PushRightColCenterTextPart,
} from '~/components/PushComponents/PushRightColCenter';
import PushRightColTitle from '~/components/PushComponents/PushRightColTitle';
import WishlistButton from '~/components/WishlistButton';

import styles from './CasinoCard.module.scss';

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const casinoCardFieldsFragment = gql`
  fragment casinoCardFields on Casino {
    id
    title
    url
    introText(length: 256)
    mediasSlider(first: 1) {
      ...myaccCardMediaFields
      ...myaccCardVideoFields
    }
    closed
    estimatedDateReopening
    closureReason {
      name
    }
    openingHours {
      days {
        hours {
          end
          start
        }
        open
        day
      }
      periodEnd
      periodStart
    }
    openStatusLabel
    ctaLabel
  }
  ${PushLeftCol.fragment.myaccCardMediaFieldsFragment}
`;

const propTypes = {
  data: propType(casinoCardFieldsFragment).isRequired,
  isLeft: bool,
  longStyle: bool,
  closedLabel: string,
  sendStatClicksListingPushItemOnClick: func,
  sendStatisticPushItemOnClick: func,
  position: number,
  isUnauthorised: bool,
  onUnauthorisedCardClick: func,
};

const defaultProps = {
  longStyle: false,
  isLeft: false,
  closedLabel: null,
  sendStatClicksListingPushItemOnClick: null,
  sendStatisticPushItemOnClick: null,
  position: null,
  isUnauthorised: false,
  onUnauthorisedCardClick: null,
};

const fragment = {
  casinoCardFieldsFragment,
};

const createOnClick =
  (id, sendStatClicksListingPushItemOnClick, sendStatisticPushItemOnClick) =>
  () => {
    sendStatClicksListingPushItemOnClick &&
      sendStatClicksListingPushItemOnClick(id);
    sendStatisticPushItemOnClick && sendStatisticPushItemOnClick(id);
  };

const CasinoCard = ({
  data: {
    id,
    url,
    title,
    mediasSlider,
    introText,
    ctaLabel,
    closed,
    estimatedDateReopening,
    closureReason,
    openingHours,
    openStatusLabel,
  },
  isLeft,
  longStyle,
  sendStatClicksListingPushItemOnClick,
  sendStatisticPushItemOnClick,
  isUnauthorised,
  onUnauthorisedCardClick,
  closedLabel: closedLabelProp,
}) => {
  const isOpenStatus = checkIfOpenStatus(openingHours);

  const estimatedReopeningLabel = estimatedDateReopening
    ? `<br />${estimatedDateReopening}`
    : '';
  const closedLabel = closureReason?.name
    ? closureReason.name + estimatedReopeningLabel
    : closedLabelProp + estimatedReopeningLabel;

  return (
    <MyAccLink
      className={cx('root', Button.shared.hovered)}
      to={
        isUnauthorised
          ? `${REACT_APP_MYACC_DOMAIN}`
          : `${REACT_APP_SBM_DOMAIN}${url}`
      }
      onClick={(e) => {
        if (onUnauthorisedCardClick) {
          onUnauthorisedCardClick(e);
        }
      }}
    >
      <WishlistButton id={id} />

      <PushLayoutContainer
        isLeft={isLeft}
        longStyle={longStyle}
        itemProp={MD_ITEM_LIST.element}
        itemScope
        itemType={MD_LIST_ITEM.type}
      >
        <PushLayoutContainerLeftCol>
          <PushLeftCol
            longStyle={longStyle}
            data={mediasSlider}
            title={title}
            onClick={createOnClick(
              id,
              sendStatClicksListingPushItemOnClick,
              sendStatisticPushItemOnClick
            )}
          />
        </PushLayoutContainerLeftCol>

        <PushLayoutContainerRightCol>
          <PushRightColHeader shortStyle={!longStyle}>
            {(closed || isOpenStatus) && (
              <PushRightColHeaderRightCol>
                <OpenStatus text={closed ? closedLabel : openStatusLabel} />
              </PushRightColHeaderRightCol>
            )}
          </PushRightColHeader>

          <PushRightColCenter longStyle={longStyle}>
            <PushRightColTitle
              onClick={createOnClick(
                id,
                sendStatClicksListingPushItemOnClick,
                sendStatisticPushItemOnClick
              )}
            >
              <span>{title}</span>
            </PushRightColTitle>

            <PushRightColCenterTextPart>
              <span dangerouslySetInnerHTML={{ __html: introText }} />
            </PushRightColCenterTextPart>
          </PushRightColCenter>

          <PushRightColFooter isLeft={isLeft} longStyle={longStyle}>
            <PushRightColCenter>
              <PushRightColButton
                theme={'rounded'}
                size={'m'}
                color={'tallow-invert'}
                label={ctaLabel}
              />
            </PushRightColCenter>
          </PushRightColFooter>
        </PushLayoutContainerRightCol>
      </PushLayoutContainer>
    </MyAccLink>
  );
};

CasinoCard.propTypes = propTypes;
CasinoCard.defaultProps = defaultProps;
CasinoCard.fragment = fragment;

export default withErrorBoundary(CasinoCard);
