export const LOADING_STATE = 'loading';
export const SIGN_IN_STATE = 'signIn';
export const SIGNED_IN_STATE = 'signedIn';
export const MAINTENANCE_STATE = 'maintenance';
export const SPECIAL_USER_EMAIL_LIST = [
  '@sbm.mc',
  '@montecarlobay.mc',
  '@larascasse.mc',
  '@jimmyz.mc',
];
export const GROUP_AGENT = 'customer_service';
export const GROUP_TOP_DOG = 'top_dog';
