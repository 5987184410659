import React, { useState, useMemo } from 'react';
import classNames from 'classnames/bind';
import { filter } from 'graphql-anywhere';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import { Formik, Form } from 'formik';
import moment from 'moment';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import Button from '~/components/Button';
import { HeaderBlock, ContactUs } from '~/scenes/StickyBlock';
import OfferTags from '~/components/OfferTags';

import WellnessProductPriceList from './components/WellnessProductPriceList';
import WellnessFormConfirmation from './components/WellnessFormConfirmation';
import WellnessForm from './components/WellnessForm';
import WellnessDateParams from './components/WellnessDateParams';
import OfferStickyFormHandler from './containers/OfferStickyFormHandler';
import styles from './WellnessStickyBlock.module.scss';

const cx = classNames.bind(styles);

const offerStickyBlockSSFragment = gql`
  fragment offerStickyBlockSSFragment on SiteSettings {
    globalLabels {
      next
      close
    }
    myAccountOfferStickyPhoneTitle {
      value
      index
    }
  }
`;

const offerStickyBlockFragment = gql`
  fragment offerStickyBlockFragment on Offer {
    id
    title
    isMyPointsEnabled
    offerEndDate
    wellnessBookingPhone
    productPrice {
      ...wellnessProductPriceListFields
    }
    wellnessBookingTitle {
      ...offerStickyBlockHeaderFields
    }
    offerProperty {
      parentProperty {
        ... on Hotel {
          id
          title
        }
        ... on Restaurant {
          id
          title
        }
        ... on Nightlife {
          id
          title
        }
        ... on Casino {
          id
          title
        }
        ... on WellnessProperty {
          id
          title
        }
        ... on Shop {
          id
          title
        }
        ... on Event {
          id
          title
        }
      }
    }
  }
  ${HeaderBlock.fragments.offerStickyBlockHeaderFieldsFragment}
  ${WellnessProductPriceList.fragment.wellnessProductPriceListFieldsFragment}
`;

const wellnessStickyBlockFragment = gql`
  fragment wellnessStickyBlock on WellnessInfo {
    dayTime {
      ...dayTimeListFields
    }
    durationTitle
    dateLabel
    dayTimeLabel
    validationRequiredField
    validationIncorrectEmail
    ...WellnessFormFields
    ...WellnessFormConfirmationFields
  }
  ${WellnessDateParams.fragments.dayTimeListFieldsFragment}
  ${WellnessForm.fragment.WellnessFormFieldsFragment}
  ${WellnessFormConfirmation.fragment.WellnessFormConfirmationFieldsFragment}
`;

const propTypes = {
  data: propType(offerStickyBlockFragment).isRequired,
  labels: propType(offerStickyBlockSSFragment).isRequired,
  wellnessInfo: propType(wellnessStickyBlockFragment).isRequired,
};

const OfferStickyBlock = function ({
  labels: { globalLabels, myAccountOfferStickyPhoneTitle },
  wellnessInfo,
  wellnessInfo: {
    dayTime,
    dateLabel,
    dayTimeLabel,
    validationRequiredField,
    validationIncorrectEmail,
    durationTitle,
  },
  data: {
    id,
    title,
    offerEndDate,
    wellnessBookingPhone,
    productPrice,
    wellnessBookingTitle,
    isMyPointsEnabled,
    offerProperty,
  },
}) {
  const [selectedDate, setSelectedDate] = useState(moment());
  const parentTitle = useMemo(
    function () {
      return offerProperty?.[0]?.parentProperty?.title;
    },
    [offerProperty]
  );

  const discount = useMemo(
    function () {
      if (!productPrice) return false;
      return productPrice.some((el) => el.priceBeforeDiscount);
    },
    [productPrice]
  );

  return (
    <div className={cx('root')}>
      {wellnessBookingTitle && (
        <HeaderBlock
          data={filter(
            HeaderBlock.fragments.offerStickyBlockHeaderFieldsFragment,
            wellnessBookingTitle
          )}
        />
      )}
      {parentTitle && <p className={cx('parent-title')}>{parentTitle}</p>}
      <OfferTags
        discount={discount}
        endDate={offerEndDate}
        className={cx('tag-list')}
      />
      <OfferStickyFormHandler
        id={id}
        title={title}
        priceList={productPrice}
        dayTime={dayTime}
        date={moment(selectedDate).format('YYYY-MM-DD')}
        errorRequiredMessage={validationRequiredField}
        errorEmailMessage={validationIncorrectEmail}
      >
        {({
          handleSubmit,
          validationSchema,
          initialValues,
          formStep,
          toNextStep,
        }) => (
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, errors, isSubmitting, setFieldValue }) => (
              <Form>
                {/* STEP 0: Date & DateTime selector */}
                {/* STEP 1: Form */}
                {/* STEP 2: Confirmation */}
                {formStep === 0 ? (
                  <>
                    <WellnessDateParams
                      initialDate={selectedDate}
                      setFieldValue={setFieldValue}
                      dateLabel={dateLabel}
                      dayTimeLabel={dayTimeLabel}
                      closeLabel={globalLabels.close}
                      dayTime={dayTime}
                      dayTimeValue={values.daytime}
                      updateDate={setSelectedDate}
                    />
                    <WellnessProductPriceList
                      title={durationTitle}
                      showPoints={isMyPointsEnabled}
                      data={productPrice}
                    />
                    <div className={cx('book-cta')}>
                      <Button
                        onClick={toNextStep}
                        className={cx('book-cta-btn')}
                      >
                        {globalLabels.next}
                      </Button>
                    </div>
                  </>
                ) : formStep === 1 ? (
                  <WellnessForm
                    toNextStep={toNextStep}
                    isSubmitting={isSubmitting}
                    errors={errors}
                    data={filter(
                      WellnessForm.fragment.WellnessFormFieldsFragment,
                      wellnessInfo
                    )}
                  />
                ) : (
                  <WellnessFormConfirmation
                    data={filter(
                      WellnessFormConfirmation.fragment
                        .WellnessFormConfirmationFieldsFragment,
                      wellnessInfo
                    )}
                  />
                )}
              </Form>
            )}
          </Formik>
        )}
      </OfferStickyFormHandler>
      {wellnessBookingPhone && (
        <ContactUs
          phone={wellnessBookingPhone}
          label={myAccountOfferStickyPhoneTitle}
        />
      )}
    </div>
  );
};

OfferStickyBlock.propTypes = propTypes;
OfferStickyBlock.fragments = {
  offerStickyBlockFragment,
  offerStickyBlockSSFragment,
  wellnessStickyBlockFragment,
};

export default withErrorBoundary(OfferStickyBlock);
