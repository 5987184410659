import { gql } from '@apollo/client';

export default gql`
  fragment widgetImageFields on MediaImage {
    id
    image {
      id
      widgetImageDesktop: url(style: widget_banner_text_image_desktop_webp) {
        url
        height
        width
      }
      widgetImageDesktop2x: url(
        style: widget_banner_text_image_desktop_2x_webp
      ) {
        url
      }
      widgetImageTablet: url(style: widget_banner_text_image_tablet_webp) {
        url
      }
      widgetImageTablet2x: url(style: widget_banner_text_image_tablet_2x_webp) {
        url
      }
      widgetImageMobile: url(style: widget_banner_text_image_mobile_webp) {
        url
      }
      widgetImageMobile2x: url(style: widget_banner_text_image_mobile_2x_webp) {
        url
      }
      alt
    }
  }
`;
