import React, { useMemo } from 'react';
import { string, number, arrayOf } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';
import classNames from 'classnames/bind';

import GridContainer from 'common/components/GridContainer';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import OfferCard from '~/components/OfferCard';

import styles from './RelatedOffers.module.scss';

const cx = classNames.bind(styles);

const RelatedOffersQueryFragment = gql`
  fragment relatedOffersQueryFragment on Offer {
    id
    ...offerCardFragment
  }
  ${OfferCard.fragments.offerCardFragment}
`;

const propTypes = {
  data: arrayOf(propType(RelatedOffersQueryFragment).isRequired).isRequired,
  id: string.isRequired,
  count: number,
};

const defaultProps = {
  count: 3,
};

const RelatedOffers = ({ data, id, count }) => {
  const { t } = useTranslation('offer-page');
  const relatedOffersHeadline = t('relatedOffersTitle');
  const relatedOffers = useMemo(
    function() {
      return data.filter((el) => el.id !== id).slice(0, count);
    },
    [data, id, count]
  );

  return (
    <>
      <h2 className={cx('headline')}>{relatedOffersHeadline}</h2>
      <GridContainer>
        {relatedOffers?.map((offer, i) => (
          <OfferCard key={i} data={offer} />
        ))}
      </GridContainer>
    </>
  );
};

RelatedOffers.propTypes = propTypes;
RelatedOffers.defaultProps = defaultProps;
RelatedOffers.fragments = {
  RelatedOffersQueryFragment,
};

export default withErrorBoundary(RelatedOffers);
