import React, { useMemo, useCallback } from 'react';
import classNames from 'classnames/bind';
import { propType } from 'graphql-anywhere';
import {
  func,
  objectOf,
  arrayOf,
  shape,
  oneOfType,
  number,
  string,
  bool,
} from 'prop-types';
import { gql } from '@apollo/client';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './FiltersBar.module.scss';

const cx = classNames.bind(styles);

const filterFragment = gql`
  fragment filterFragment on ListingItemsAttribute {
    id
    count
    name
  }
`;

const propTypes = {
  items: objectOf(arrayOf(propType(filterFragment))).isRequired,
  active: shape({
    type: oneOfType([number, string]).isRequired,
    id: oneOfType([number, string]).isRequired,
  }),
  onFilterToggle: func.isRequired,
  showEmpty: bool,
};

const defaultProps = {
  active: null,
  showEmpty: false,
};

const FiltersBar = function ({ items, onFilterToggle, active, showEmpty }) {
  const filterToggle = useCallback(
    (id, type) => {
      if (onFilterToggle) {
        onFilterToggle(id, type);
      }
    },
    [onFilterToggle]
  );

  const filters = useMemo(() => {
    return Object.keys(items).reduce((reducer, itemType) => {
      let values = items[itemType];
      values = values.map((item) => ({
        ...item,
        type: itemType,
      }));
      if (!showEmpty) {
        values = values.filter((item) => item.count);
      }
      return reducer.concat(values);
    }, []);
  }, [items, showEmpty]);

  const filterItem = ({ name, count, id, type }) => {
    const elId = `${type}:${id}`;
    const isActive = !!active && `${active.type}:${active.id}` === elId;

    return (
      <label className={cx('item')} key={elId}>
        <input
          type="radio"
          name="filterBar"
          id={elId}
          defaultChecked={isActive}
          onClick={() => filterToggle(id, type)}
        />
        <span className={cx('item-description')}>
          {name}
          <span className={cx('count')}>{count}</span>
        </span>
      </label>
    );
  };

  return (
    <ul className={cx('root')}>
      {filters.map((filter) => filterItem(filter))}
    </ul>
  );
};

FiltersBar.propTypes = propTypes;
FiltersBar.defaultProps = defaultProps;

export default withErrorBoundary(FiltersBar);
