import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { parse } from 'query-string';

import Input from 'common/components/FormComponents/Input';
import InputPassword from 'common/components/FormComponents/InputPassword';
import SpinLoader from 'common/components/SpinLoader';
import Button from 'common/components/Button';
import ContentLoader from 'common/components/ContentLoader';
import AuthFormTitle from 'common/components/AuthFormTitle';
import AuthMessage from 'common/components/AuthMessage';

import GTM from '~/components/GTM';
import { useAuthContext } from '~/context/AuthContext';
import PasswordCheckList from '~/components/PasswordCheckList';

import ForgotPassFormHandler from './containers/ForgotPassFormHandler';
import ChangePassFormHandler from './containers/ChangePassFormHandler';
import BackButtons from './components/BackButtons';
import styles from './ForgotPassForm.module.scss';

const cx = classNames.bind(styles);

const ForgotPassForm = () => {
  const {
    location: { search },
  } = useHistory();

  const { code: initCode, email: initEmail } = parse(search);
  const { errorMsg, setErrorMsg, successMsg } = useAuthContext();
  const [forgottenPassEmail, setForgottenPassEmail] = useState(initEmail || '');
  const [isEmailSubmitted, setEmailSubmitted] = useState(!!initEmail);
  const [isPassChanged, setPassChanged] = useState(false);
  const [changePassFormSubmitCount, setChangePassFormSubmitCount] = useState(0);

  const { t } = useTranslation('auth');

  const forgotPassTitle = t('login.forgotPassTitle');
  const forgotPassFormConfirmation = t('login.messages.reset_password');
  const forgotPassSubmitTitle = t('login.forgotPassSubmitTitle');
  const emailLabel = t('login.emailLabel');
  const forgotPassSubmitLabel = t('login.forgotPassSubmitLabel');
  const newPasswordLabel = t('login.newPasswordLabel');
  const forgotPassSubmitFormCodeLabel = t(
    'login.forgotPassSubmitFormCodeLabel'
  );
  const forgotPassSubmitFormSubmitLabel = t(
    'login.forgotPassSubmitFormSubmitLabel'
  );

  return (
    <div className={cx('root')}>
      <AuthFormTitle
        title={isEmailSubmitted ? forgotPassSubmitTitle : forgotPassTitle}
      />
      {errorMsg && <AuthMessage>{errorMsg}</AuthMessage>}
      {!errorMsg && isEmailSubmitted && changePassFormSubmitCount < 1 && (
        <AuthMessage isSuccessMsg>{forgotPassFormConfirmation}</AuthMessage>
      )}
      {successMsg && <AuthMessage isSuccessMsg>{successMsg}</AuthMessage>}

      {!isEmailSubmitted && (
        <ForgotPassFormHandler
          setEmailSubmitted={setEmailSubmitted}
          setForgottenPassEmail={setForgottenPassEmail}
          forgottenPassEmail={forgottenPassEmail}
        >
          {({
            handleSubmit,
            validationSchema,
            initialValues,
            resetMessage,
            captchaStatus: captchaStatusSubmitEmail,
            CaptchaWidget: CaptchaWidgetSubmitEmail,
          }) => (
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              {({ values, errors, isSubmitting }) => (
                <Form className={cx('form')} onChange={resetMessage} noValidate>
                  <Field
                    name="email"
                    component={Input}
                    theme="block-theme"
                    type="email"
                    placeholder={emailLabel}
                    required
                    maxLength={128}
                    autoComplete="username"
                  />
                  <Button
                    type="submit"
                    className={cx('submit-button', {
                      loading: isSubmitting || captchaStatusSubmitEmail.loading,
                    })}
                    disabled={
                      !values.email ||
                      !!errors.email ||
                      isSubmitting ||
                      !captchaStatusSubmitEmail.solution ||
                      captchaStatusSubmitEmail.loading
                    }
                  >
                    {(isSubmitting || captchaStatusSubmitEmail.loading) && (
                      <SpinLoader className={cx('loader')} size={18} />
                    )}
                    {forgotPassSubmitLabel}
                  </Button>
                  <CaptchaWidgetSubmitEmail className={cx('captcha')} />
                </Form>
              )}
            </Formik>
          )}
        </ForgotPassFormHandler>
      )}

      {isEmailSubmitted && (
        <ChangePassFormHandler
          forgottenPassEmail={forgottenPassEmail}
          verificationCode={initCode}
          setPassChanged={setPassChanged}
        >
          {({
            handleSubmit,
            validationSchema,
            initialValues,
            resetMessage,
            loginLoading,
            captchaStatus: captchaStatusChangePass,
            CaptchaWidget: CaptchaWidgetChangePass,
          }) => {
            return loginLoading ? (
              <ContentLoader />
            ) : (
              <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={handleSubmit}
              >
                {({ errors, values, isSubmitting, submitCount }) => (
                  <Form
                    className={cx('form')}
                    onChange={resetMessage}
                    noValidate
                  >
                    <Field
                      name="email"
                      component={Input}
                      theme="block-theme"
                      type="email"
                      placeholder={emailLabel}
                      required
                      disabled
                      maxLength={128}
                      autoComplete="username"
                    />
                    <Field
                      name="code"
                      component={Input}
                      theme="block-theme"
                      type="text"
                      placeholder={forgotPassSubmitFormCodeLabel}
                      required
                      maxLength={254}
                    />
                    <Field
                      name="password"
                      component={InputPassword}
                      theme="block-theme"
                      placeholder={newPasswordLabel}
                      required
                      maxLength={128}
                      autoComplete="off"
                    />
                    <PasswordCheckList
                      validationSchema={validationSchema}
                      passwordValue={values.password}
                    />
                    <Button
                      type="submit"
                      className={cx('submit-button', {
                        loading:
                          isSubmitting || captchaStatusChangePass.loading,
                      })}
                      disabled={
                        !values.code ||
                        !values.password ||
                        Object.keys(errors).length !== 0 ||
                        isSubmitting ||
                        isPassChanged ||
                        !captchaStatusChangePass.solution ||
                        captchaStatusChangePass.loading
                      }
                      onClick={() => setChangePassFormSubmitCount(submitCount)}
                    >
                      {(isSubmitting || captchaStatusChangePass.loading) && (
                        <SpinLoader className={cx('loader')} size={18} />
                      )}
                      {forgotPassSubmitFormSubmitLabel}
                    </Button>
                    <CaptchaWidgetChangePass className={cx('captcha')} />
                  </Form>
                )}
              </Formik>
            );
          }}
        </ChangePassFormHandler>
      )}
      <BackButtons
        isEmailSubmitted={isEmailSubmitted}
        resendCode={() => {
          setEmailSubmitted(false);
          setErrorMsg('');
        }}
      />
      <GTM />
    </div>
  );
};

export default ForgotPassForm;
