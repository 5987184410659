const { REACT_APP_MYACC_ENV, REACT_APP_MYACC_DOMAIN } = process.env;

const myaccDomain =
  REACT_APP_MYACC_ENV === 'local' || REACT_APP_MYACC_ENV === 'dev'
    ? 'https://my-dev.montecarlosbm.com'
    : REACT_APP_MYACC_DOMAIN;

const imgPathTemplate = '/background/my_account_background_';

const desktop = `${myaccDomain}${imgPathTemplate}desktop_webp.webp`;
const desktop2x = `${myaccDomain}${imgPathTemplate}desktop_2x_webp.webp`;
const tablet = `${myaccDomain}${imgPathTemplate}tablet_webp.webp`;
const tablet2x = `${myaccDomain}${imgPathTemplate}tablet_2x_webp.webp`;

export const LOGIN_BACKGROUND = {
  desktop: { url: desktop }, // my_account_background_desktop_webp image style name
  desktop2x: { url: desktop2x }, // my_account_background_desktop_2x_webp
  tablet: { url: tablet }, // my_account_background_tablet_webp
  tablet2x: { url: tablet2x }, // my_account_background_tablet_2x_webp
};
