export const MYACC_LANGUAGE = [
  {
    title: 'English',
    shortTitle: 'en',
    langId: 'en',
    langISO3: 'ENG',
  },
  {
    title: 'Français',
    shortTitle: 'fr',
    langId: 'fr',
    langISO3: 'FRA',
  },
  {
    title: 'Italiano',
    shortTitle: 'it',
    langId: 'it',
    langISO3: 'ITA',
  },
];

export const MYACC_LANGUAGE_WHITELIST = ['en', 'fr', 'it'];
