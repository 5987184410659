import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { array, func, object } from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import uniqueId from 'lodash/uniqueId';

import MultiField from 'common/components/FormComponents/MultiField';
import MultiFieldCta from 'common/components/FormComponents/MultiFieldCta';
import Input from 'common/components/FormComponents/Input';
import Select from 'common/components/FormComponents/Select';
import { ReactComponent as IconGeo } from 'common/media/icons/geo-outline.icon.svg';

import PanelSection from '~/components/PanelSection';
import useCountryNameKey from '~/hooks/useCountryNameKey';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';
import AddressFieldsMandatoryHandler from '~/scenes/FormContacts/containers/AddressFieldsMandatoryHandler';
import { ADDRESS_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';

import useOnNewFieldAdd from '../../hooks/useOnNewFieldAdd';
import styles from './AddressFields.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  addressList: array.isRequired,
  addressCategoryList: array.isRequired,
  onAddressAdd: func.isRequired,
  onAddressRemove: func.isRequired,
  onAddressReset: func.isRequired,
  setFieldValue: func.isRequired,
  setFieldTouched: func.isRequired,
  setFieldError: func.isRequired,
  markDeletedFields: func.isRequired,
  values: object.isRequired,
  mandatoryAddressGroups: array.isRequired,
  validateForm: func.isRequired,
  setValidationErrorVisible: func.isRequired,
};

const AddressFields = ({
  addressList,
  addressCategoryList,
  onAddressAdd,
  onAddressRemove,
  onAddressReset,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  values,
  markDeletedFields,
  mandatoryAddressGroups,
  validateForm,
  setValidationErrorVisible,
}) => {
  useEffect(() => {
    validateForm();
    setValidationErrorVisible(false);
  }, [mandatoryAddressGroups, validateForm, setValidationErrorVisible]);

  const countryNameKey = useCountryNameKey();
  const prioritisedCountries = usePrioritisedCountries();

  const formElementRef = useRef();

  const { onNewFieldAdd, counter, setCounter } = useOnNewFieldAdd(
    formElementRef,
    addressList.length
  );

  const { t } = useTranslation('contacts-form');
  const addressFieldLabel = t('addressFieldLabel');
  const zipCodeFieldLabel = t('zipCodeFieldLabel');
  const cityFieldLabel = t('cityFieldLabel');
  const stateFieldLabel = t('stateFieldLabel');
  const countryFieldLabel = t('countryFieldLabel');
  const categoryFieldLabel = t('categoryFieldLabel');

  return (
    <PanelSection icon={IconGeo} className={cx('root')}>
      {addressList.map(
        (
          {
            isMutable,
            isDeletable,
            name,
            secondaryName,
            zipCodeName,
            cityName,
            stateName,
            countryName,
            catName,
          },
          index
        ) => {
          const onIncrease =
            index === addressList.length - 1
              ? () => {
                  const id = uniqueId();

                  setFieldValue(`address1_${id}`, '');
                  setFieldValue(`address2_${id}`, '');
                  setFieldValue(`zipCode_${id}`, '');
                  setFieldValue(`city_${id}`, '');
                  setFieldValue(`state_${id}`, '');
                  setFieldValue(`country_${id}`, '');
                  setFieldValue(`address_${id}_cat`, ADDRESS_DEFAULT_CATEGORY);
                  onAddressAdd(id);
                  onNewFieldAdd();
                  setCounter(addressList.length);
                }
              : null;

          const onRemove = isDeletable
            ? () => {
                if (addressList.length === 1) {
                  const namesArray = [
                    name,
                    secondaryName,
                    zipCodeName,
                    cityName,
                    stateName,
                    countryName,
                  ];
                  namesArray.forEach((fieldName) => {
                    setFieldValue(fieldName, '');
                    setFieldTouched(fieldName, false);
                    setFieldError(fieldName, '');
                  });

                  setFieldValue(catName, ADDRESS_DEFAULT_CATEGORY);
                  setFieldTouched(catName, false);
                  setFieldError(catName, '');

                  onAddressReset(name);
                }
                if (addressList.length > 1) {
                  markDeletedFields({ setFieldValue, values, name });
                  onAddressRemove(name);
                }
              }
            : null;

          return (
            <MultiField
              key={index}
              formElementRef={formElementRef}
              rootClassName={cx({ 'shadow-highlighted': index === counter })}
              mainField={
                <>
                  <AddressFieldsMandatoryHandler
                    name={name}
                    zipCodeName={zipCodeName}
                    cityName={cityName}
                    countryName={countryName}
                    mandatoryAddressGroups={mandatoryAddressGroups}
                  >
                    {({
                      isNameMandatory,
                      isCityNameMandatory,
                      isCountryNameMandatory,
                    }) => (
                      <>
                        <Field
                          name={name}
                          component={Input}
                          placeholder={`${addressFieldLabel} ${
                            addressList.length <= 1 ? '' : index + 1
                          }`}
                          maxLength={250}
                          disabled={!isMutable}
                          className={cx('address-first')}
                          required={isNameMandatory}
                        />
                        <Field
                          name={secondaryName}
                          component={Input}
                          maxLength={255}
                          disabled={!isMutable}
                          className={cx('address-second')}
                        />
                        <Field
                          name={zipCodeName}
                          component={Input}
                          placeholder={zipCodeFieldLabel}
                          maxLength={50}
                          disabled={!isMutable}
                          className={cx('sub-field')}
                        />
                        <Field
                          name={cityName}
                          component={Input}
                          placeholder={cityFieldLabel}
                          maxLength={200}
                          disabled={!isMutable}
                          className={cx('sub-field')}
                          required={isCityNameMandatory}
                        />
                        <Field
                          name={stateName}
                          component={Input}
                          placeholder={stateFieldLabel}
                          maxLength={50}
                          disabled={!isMutable}
                        />
                        <Field
                          name={countryName}
                          component={Select}
                          options={prioritisedCountries}
                          placeholder={countryFieldLabel}
                          customKeys={['id', countryNameKey]}
                          disabled={!isMutable}
                          required={isCountryNameMandatory}
                        />
                      </>
                    )}
                  </AddressFieldsMandatoryHandler>
                </>
              }
              secondaryField={
                <Field
                  name={catName}
                  component={Select}
                  options={addressCategoryList}
                  placeholder={categoryFieldLabel}
                  disabled={!isMutable}
                />
              }
              cta={
                <MultiFieldCta onIncrease={onIncrease} onRemove={onRemove} />
              }
            />
          );
        }
      )}
    </PanelSection>
  );
};

AddressFields.propTypes = propTypes;

export default AddressFields;
