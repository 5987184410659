import React from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './PushRightColCenter.module.scss';

const cx = classNames.bind(styles);

const pushRightColCenterPropTypes = {
  className: string,
  children: node,
  longStyle: bool,
};

const pushRightColCenterDefaultProps = {
  className: null,
  children: null,
  longStyle: true,
};

const pushRightColCenterTextPartPropTypes = {
  className: string,
  children: node,
  longStyle: bool,
  textShort: bool,
};

const pushRightColCenterTextPartDefaultProps = {
  className: null,
  children: null,
  longStyle: true,
  textShort: false,
};

const pushRightColCenterBottomTextPartPropTypes = {
  className: string,
  children: node,
};

const pushRightColCenterBottomTextPartDefaultProps = {
  className: null,
  children: null,
};

const PushRightColCenter = ({
  className,
  children,
  longStyle,
  ...attributes
}) => {
  return (
    <div
      className={cx(
        'root',
        { 'long-style': longStyle },
        { 'short-style': !longStyle },
        className
      )}
      {...attributes}
    >
      {children}
    </div>
  );
};

const PushRightColCenterTextPart = ({
  children,
  longStyle,
  textShort,
  className,
}) => {
  return (
    <div
      className={cx(
        'description-trim',
        { 'text-short': textShort && !longStyle },
        { description: !className },
        className
      )}
    >
      <div className={cx('inner-wrap')}>{children}</div>
    </div>
  );
};

const PushRightColCenterBottomTextPart = ({ children, className }) => {
  return <div className={cx('bottom-text', className)}>{children}</div>;
};

PushRightColCenter.propTypes = pushRightColCenterPropTypes;
PushRightColCenter.defaultProps = pushRightColCenterDefaultProps;

PushRightColCenterTextPart.propTypes = pushRightColCenterTextPartPropTypes;
PushRightColCenterTextPart.defaultProps =
  pushRightColCenterTextPartDefaultProps;

PushRightColCenterBottomTextPart.propTypes =
  pushRightColCenterBottomTextPartPropTypes;
PushRightColCenterBottomTextPart.defaultProps =
  pushRightColCenterBottomTextPartDefaultProps;

export { PushRightColCenterTextPart, PushRightColCenterBottomTextPart };
export default PushRightColCenter;
