import React from 'react';
import { func, bool } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconBin } from 'common/media/icons/bin.icon.svg';
import { useToggle, useOnClickOutside } from 'common/hooks';

import DownloadInfoButton from '../DownloadInfoButton';
import DeleteDataModal from '../DeleteDataModal';
import styles from './DataButtons.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  onDeleteData: func.isRequired,
  isPrivacyDataUpdated: bool.isRequired,
  setPrivacyDataUpdated: func.isRequired,
};

function DataButtons({
  onDeleteData,
  isPrivacyDataUpdated,
  setPrivacyDataUpdated,
}) {
  const [modalOpen, toggleModalOpen] = useToggle();

  const modalRef = useOnClickOutside(toggleModalOpen);

  const { t } = useTranslation('privacy');
  const deleteDataButtonLabel = t('deleteDataButtonLabel');

  return (
    <div className={cx('root')}>
      {modalOpen && (
        <DeleteDataModal
          modalRef={modalRef}
          modalToggle={toggleModalOpen}
          deleteData={onDeleteData}
        />
      )}
      <div className={cx('button-wrapper')}>
        <DownloadInfoButton
          isPrivacyDataUpdated={isPrivacyDataUpdated}
          setPrivacyDataUpdated={setPrivacyDataUpdated}
        />
      </div>
      <div className={cx('button-wrapper')}>
        <button
          type="button"
          className={cx('delete-button')}
          onClick={toggleModalOpen}
        >
          <span className={cx('icon-wrapper')}>
            <IconContainer icon={IconBin} className={cx('icon-data')} />
          </span>
          <span className={cx('cta-text')}>{deleteDataButtonLabel}</span>
        </button>
      </div>
    </div>
  );
}

DataButtons.propTypes = propTypes;

export default DataButtons;
