import React from 'react';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';
import flowRight from 'lodash/flowRight';

import Container from 'common/components/Container';
import GenericImg from 'common/components/GenericImg';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import statisticsClicksWidgetDataParams from '~/utils/statisticsClicksWidgetDataParams';
import MyAccLink from '~/components/MyAccLink';

import styles from './WidgetPromoImageText.module.scss';

const cx = classNames.bind(styles);

const widgetPromoImageTextFieldsFragment = gql`
  fragment widgetPromoImageTextFields on WidgetPromoImageText {
    id
    title
    description
    media {
      title
      ... on MediaImage {
        id
        image {
          id
          promoImage: url(style: widget_promo_short_image_webp) {
            url
            width
            height
          }
          alt
        }
      }
    }
    link {
      text
      url
    }
  }
`;

const propTypes = {
  data: propType(widgetPromoImageTextFieldsFragment).isRequired,
};

const fragment = {
  widgetPromoImageTextFieldsFragment,
};

const WidgetPromoImageText = ({
  data: { id, title, description, media, link },
}) => {
  const createOnClick =
    ({ title, id, link }) =>
    () => {
      const generatedStatisticsClicksWidgetDataParams =
        statisticsClicksWidgetDataParams(
          'widgetPromoImageText',
          title,
          id,
          link.url
        );

      const params = {
        event: 'click_widget',
        widget_info: generatedStatisticsClicksWidgetDataParams,
      };

      gtmPushEvent(params);
    };
  const Tag = link?.url ? MyAccLink : 'div';

  return (
    <div className={cx('root')}>
      <Container tag="section" width={1140}>
        <Tag
          className={cx('content-wrapper')}
          to={link.url}
          onClick={createOnClick({
            title,
            id,
            link,
          })}
        >
          <div className={cx('image-content')}>
            <GenericImg
              src={media.image.promoImage.url}
              alt={media.image.alt}
              width={media.image.promoImage.width}
              height={media.image.promoImage.height}
              responsive
              forceContain
            />
          </div>
          <div className={cx('content')}>
            <h3>{title}</h3>
            {description && <p>{description}</p>}
            {!!link && (
              <div className={cx('link', 'reverse')}>
                <span className={cx('link-label')}>{link.text}</span>
              </div>
            )}
          </div>
        </Tag>
      </Container>
    </div>
  );
};

WidgetPromoImageText.propTypes = propTypes;
WidgetPromoImageText.fragment = fragment;

export default flowRight(withErrorBoundary)(WidgetPromoImageText);
