import React, { useCallback, useRef, useMemo } from 'react';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import IconContainer from 'common/components/IconContainer';
import Bulb from 'common/components/Bulb';
import { ReactComponent as IconUserSolid } from 'common/media/icons/user-solid.icon.svg';
import { ReactComponent as IconLogout } from 'common/media/icons/logout.svg';
import { useOnClickOutside } from 'common/hooks';
import { useToggle } from 'common/hooks';
import { LOYALTY_STATUSES } from 'common/constants';
import SpinLoader from 'common/components/SpinLoader';

import { useUiContext } from 'common/context/UiContext';
import { useUserContext } from '~/context/UserContext';
import { LINK_SBM } from '~/constants';

import UserMenuLink from './components/UserMenuLink';
import UserMenuDataHandler from './containers/UserMenuDataHandler';
import styles from './UserMenu.module.scss';

const cx = classNames.bind(styles);
const { REACT_APP_MYACC_DOMAIN } = process.env;

const UserMenu = () => {
  const { t } = useTranslation('common');
  const signOutLabel = t('signOut');

  const [isMenuOpen, toggleMenuOpen] = useToggle();
  const { isDesktop } = useUiContext();
  const {
    loyaltyStatus,
    profile: { firstName },
    isUserDataAvailable,
    loadingUserData,
    loyaltyProgramJoined,
  } = useUserContext();
  const bulbRef = useRef();

  const isLoyaltyProgramJoined = useMemo(() => {
    return !loadingUserData && loyaltyProgramJoined;
  }, [loadingUserData, loyaltyProgramJoined]);

  const handleClickOutside = useCallback(() => {
    if (isMenuOpen) {
      return toggleMenuOpen();
    }
  }, [toggleMenuOpen, isMenuOpen]);

  const userMenuList = useOnClickOutside(handleClickOutside);

  return (
    <div className={cx('root')} ref={userMenuList}>
      <UserMenuDataHandler>
        {({
          menuItems,
          menuDataLoading,
          isNotificationsBulbVisible,
          notificationsCount,
          signOutHandler,
        }) => (
          <>
            <button
              type="button"
              className={cx('user-menu-trigger')}
              onClick={toggleMenuOpen}
            >
              <span
                className={cx('user-icon-wrapper')}
                style={{
                  marginRight:
                    bulbRef && bulbRef.current
                      ? bulbRef.current.clientWidth - 12
                      : 0,
                }}
              >
                <IconContainer
                  className={cx('user-icon')}
                  icon={IconUserSolid}
                />
                {isNotificationsBulbVisible && !!notificationsCount && (
                  <Bulb
                    bulbRef={bulbRef}
                    className={cx('user-icon-bulb')}
                    value={notificationsCount}
                  />
                )}
              </span>
              {isDesktop && firstName}
            </button>

            <CSSTransition
              in={isMenuOpen}
              timeout={100}
              classNames={{
                enter: cx('transition-enter'),
                enterActive: cx('transition-enter-active'),
                enterDone: cx('transition-done-enter'),
                exit: cx('transition-exit'),
                exitActive: cx('transition-exit-active'),
              }}
              unmountOnExit
            >
              <div className={cx('user-menu-wrapper')}>
                {isDesktop && (
                  <div
                    className={cx('user-menu-header')}
                    onClick={toggleMenuOpen}
                  >
                    <IconContainer
                      className={cx('user-icon')}
                      icon={IconUserSolid}
                    />
                    {firstName}
                  </div>
                )}
                <div className={cx('user-menu-list')}>
                  {menuDataLoading && <SpinLoader size={16} />}
                  <ul className={cx('user-menu-items')}>
                    {menuItems.length > 0 &&
                      menuItems.map(
                        ({
                          id,
                          title,
                          isLink,
                          url,
                          isOfferItem,
                          isNotificationsItem,
                          linkType,
                        }) =>
                          isLink ? (
                            <li
                              key={id}
                              className={cx('user-menu-item', {
                                'with-item-bulb':
                                  isNotificationsBulbVisible &&
                                  isNotificationsItem,
                              })}
                              onClick={
                                !isUserDataAvailable && linkType === LINK_SBM
                                  ? null
                                  : toggleMenuOpen
                              }
                            >
                              {isNotificationsItem && !!notificationsCount && (
                                <Bulb
                                  value={notificationsCount}
                                  className={cx('menu-item-bulb')}
                                />
                              )}
                              {!isOfferItem && (
                                <UserMenuLink path={url} text={title} />
                              )}
                              {isOfferItem && (
                                <UserMenuLink
                                  path={
                                    isLoyaltyProgramJoined
                                      ? `${REACT_APP_MYACC_DOMAIN}/offers`
                                      : `${url}?offer_loyalty=${LOYALTY_STATUSES[loyaltyStatus]}`
                                  }
                                  text={title}
                                />
                              )}
                            </li>
                          ) : null
                      )}
                  </ul>
                  <div className={cx('user-logout-item')}>
                    <button onClick={signOutHandler}>
                      <IconContainer
                        className={cx('logout-icon')}
                        icon={IconLogout}
                      />
                      {signOutLabel}
                    </button>
                  </div>
                </div>
              </div>
            </CSSTransition>
          </>
        )}
      </UserMenuDataHandler>
    </div>
  );
};

export default UserMenu;
