import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames/bind';
import { gql, useQuery } from '@apollo/client';

import SpinLoader from 'common/components/SpinLoader';
import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconDownload } from 'common/media/icons/download.icon.svg';
import { useEffectOnMount } from 'common/hooks';

import gtmPushEvent from 'common/utils/gtmPushEvent';

import styles from './DownloadInfoButton.module.scss';

const cx = classNames.bind(styles);

const gtmDownloadParams = {
  event: 'download_personal_data',
};

const propTypes = {
  className: string,
  isPrivacyDataUpdated: bool.isRequired,
  setPrivacyDataUpdated: func.isRequired,
};

const defaultProps = {
  className: null,
};

const SbmAccountDataQuery = gql`
  query SbmAccountData {
    getSbmAccountData {
      pdfUrl
      pdfName
    }
  }
`;

const DownloadInfoButton = ({
  isPrivacyDataUpdated,
  setPrivacyDataUpdated,
}) => {
  const { loading, data, refetch } = useQuery(SbmAccountDataQuery);
  const [linkData, setLinkData] = useState('');
  const [refetchLoading, setRefetchLoading] = useState(false); // because of bug in apollo ver <= 3.1.5

  const { t } = useTranslation('privacy');
  const downloadAllInfo = t('downloadAllInfo');
  const pdfNameDefault = 'Account information PDF';

  useEffect(() => {
    if (!loading) {
      setLinkData(data?.getSbmAccountData?.pdfUrl);
    }
  }, [loading, data]);

  const refetchPdfData = useCallback(() => {
    setRefetchLoading(true);
    refetch().then(() => {
      setRefetchLoading(false);
      setPrivacyDataUpdated(false);
    });
  }, [refetch, setPrivacyDataUpdated]);

  useEffect(() => {
    if (isPrivacyDataUpdated) {
      refetchPdfData();
    }
  }, [isPrivacyDataUpdated, refetchPdfData]);

  useEffectOnMount(() => {
    if (!loading) {
      refetchPdfData();
    }
  });

  const onDownloadClick = useCallback(() => {
    gtmPushEvent(gtmDownloadParams);
  }, []);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      type="application/pdf"
      className={cx('root', { disabled: loading || refetchLoading })}
      href={linkData}
      download={data?.pdfName ? data?.pdfName : pdfNameDefault}
      onClick={!(loading || refetchLoading) ? onDownloadClick : null}
    >
      {loading || refetchLoading ? (
        <SpinLoader className={cx('loader')} size={20} />
      ) : (
        <IconContainer icon={IconDownload} className={cx('icon-download')} />
      )}
      <span className={cx('data-cta-text')}>{downloadAllInfo}</span>
    </a>
  );
};

DownloadInfoButton.propTypes = propTypes;
DownloadInfoButton.defaultProps = defaultProps;

export default DownloadInfoButton;
