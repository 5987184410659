import { useEffect, useRef } from 'react';

export default function useEffectOnUpdate(
  update = () => {},
  dependecies = null
) {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!isFirstRun.current) {
      update();
    } else {
      isFirstRun.current = false;
    }
  }, dependecies);
}
