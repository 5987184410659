import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Wysiwyg.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  text: string,
  className: string,
};

const defaultProps = {
  className: null,
  text: null,
};

const Wysiwyg = ({ text, className }) => {
  if (!text) return null;

  return (
    <div
      className={cx('wysiwyg', className)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

Wysiwyg.propTypes = propTypes;
Wysiwyg.defaultProps = defaultProps;

export default Wysiwyg;
