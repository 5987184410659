import React from 'react';
import classNames from 'classnames/bind';

import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = ({ children, className, ...props }) => {
  return (
    <footer id="footer" className={cx('root', className)} {...props}>
      {children}
    </footer>
  );
};

export default Footer;
