import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';
import { filter } from 'graphql-anywhere';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import classNames from 'classnames/bind';

import Toggle from 'common/containers/Toggle';
import Container from 'common/components/Container';
import OfferMainInfo from 'common/scenes/OfferMainInfo';
import OfferHero from 'common/scenes/OfferHero';
import { getLoyaltyStatusLabel } from 'common/utils/userMenuUtils';
import { DatesContextProvider } from 'common/context/DatesContext';
import { PersonsContextProvider } from 'common/context/PersonsContext';
import { RoomsNumberContextProvider } from 'common/context/RoomsNumberContext';

import PageLoader from '~/components/PageLoader';
import { useUiContext } from 'common/context/UiContext';
import WellnessStickyBlock from '~/scenes/WellnessStickyBlock';
import OfferStickyBookingBlock from '~/scenes/OfferStickyBookingBlock';
import StickyBlock, { StickyBlockContainer } from '~/scenes/StickyBlock';
import HeroImage from '~/components/HeroImage';
import { sbmClient } from '~/apolloClient';
import { useAuthContext } from '~/context/AuthContext';
import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

import RelatedOffers from './components/RelatedOffers';
import styles from './OfferDetails.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

const offerMainInfoFieldsFragment = gql`
  fragment offerMainInfoFields on Offer {
    validityPeriod
    introTitle {
      index
      value
    }
    introText
    offerAttributes {
      id
      icon {
        url
      }
      name
    }
    serviceTitle {
      index
      value
    }
    services
    moreServicesLabel
    lessServicesLabel
    conditionsLabel
    conditionsTitle
    conditionsText
  }
`;

const offerMainInfoSiteSettingFieldsFragment = gql`
  fragment offerMainInfoSiteSettingFields on SiteSettings {
    globalLabels {
      readMore
      readLess
      close
    }
  }
`;

const OfferPageQuery = gql`
  query OfferPage(
    $id: ID!
    $type: ListingType!
    $first: Int
    $after: Int
    $attributes: [ListingFilterInputAttributes]
  ) {
    offer(id: $id) {
      id
      title
      subtitle
      isWellnessOffer
      ...offerMainInfoFields
      ...offerStickyBookingFields
      offerMedia: media {
        ...heroImageFields
      }
      ...offerStickyBlockFragment
      wellnessInfo {
        ...wellnessStickyBlock
      }
      isBookingAvailable
    }
    siteSettings {
      ...offerStickyBookingSiteSettingsFields
      ...offerMainInfoSiteSettingFields
      ...offerStickyBlockSSFragment
    }
    listing(type: $type) {
      id
      items(
        myacc: true
        first: $first
        after: $after
        attributes: $attributes
      ) {
        targetToSellItemId
        results {
          ... on Offer {
            ...relatedOffersQueryFragment
          }
        }
      }
    }
  }
  ${HeroImage.fragments.heroImageFields}
  ${offerMainInfoFieldsFragment}
  ${offerMainInfoSiteSettingFieldsFragment}
  ${RelatedOffers.fragments.RelatedOffersQueryFragment}
  ${WellnessStickyBlock.fragments.offerStickyBlockFragment}
  ${WellnessStickyBlock.fragments.offerStickyBlockSSFragment}
  ${WellnessStickyBlock.fragments.wellnessStickyBlockFragment}
  ${OfferStickyBookingBlock.fragment
    .offerStickyBookingSiteSettingsFieldsFragment}
  ${OfferStickyBookingBlock.fragment.offerStickyBookingFieldsFragment}
`;

const OfferDetails = ({
  match: {
    params: { id },
  },
}) => {
  const { loyaltyStatus, isUserDataAvailable } = useUserContext();
  const { currentBreakpoint } = useUiContext();

  const { offerRedirectParams, setOfferRedirectParams } = useAuthContext();

  useEffect(() => {
    // this function is called when user is redirected from auto-login,
    // so we need to clear the params to avoid redirecting again
    return () => {
      setOfferRedirectParams({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, data } = useQuery(OfferPageQuery, {
    client: sbmClient,
    variables: {
      id,
      type: 'offer',
      first: 4,
      after: 0,
      attributes: [
        {
          type: 'offer_loyalty',
          filters: [
            getLoyaltyStatusLabel(
              offerRedirectParams?.offerLoyaltyStatus || loyaltyStatus
            ),
          ],
        },
      ],
    },
    context: {
      headers: {
        'X-Acc-Grp': offerRedirectParams?.offerLoyaltyStatus || loyaltyStatus,
      },
    },
  });

  if (loading || !isUserDataAvailable || !data.offer) {
    return <PageLoader />;
  }

  const {
    offer,
    offer: {
      title,
      subtitle,
      isWellnessOffer,
      offerMedia,
      wellnessInfo,
      isBookingAvailable,
    },
    siteSettings,
    listing: {
      items: { results: relatedOffers },
    },
  } = data;

  let bookingBlock = null;

  if (!isBookingAvailable || !isWellnessOffer) {
    bookingBlock = (
      <StickyBlock className={cx('sidebar')}>
        <OfferStickyBookingBlock
          data={filter(
            OfferStickyBookingBlock.fragment.offerStickyBookingFieldsFragment,
            offer
          )}
          siteSettingsData={filter(
            OfferStickyBookingBlock.fragment
              .offerStickyBookingSiteSettingsFieldsFragment,
            siteSettings
          )}
        />
      </StickyBlock>
    );
  } else if (isWellnessOffer) {
    bookingBlock = (
      <StickyBlock className={cx('sidebar')}>
        <WellnessStickyBlock
          data={filter(
            WellnessStickyBlock.fragments.offerStickyBlockFragment,
            offer
          )}
          wellnessInfo={filter(
            WellnessStickyBlock.fragments.wellnessStickyBlockFragment,
            wellnessInfo
          )}
          labels={filter(
            WellnessStickyBlock.fragments.offerStickyBlockSSFragment,
            siteSettings
          )}
        />
      </StickyBlock>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Toggle>
        {() => (
          <StickyBlockContainer stickyBlock={bookingBlock}>
            {offerMedia && (
              <HeroImage data={offerMedia[0]}>
                <OfferHero title={title} subtitle={subtitle} />
              </HeroImage>
            )}
            <OfferMainInfo
              data={filter(offerMainInfoFieldsFragment, offer)}
              bgColor="light-gray"
              dataSettings={filter(
                offerMainInfoSiteSettingFieldsFragment,
                siteSettings
              )}
            />
          </StickyBlockContainer>
        )}
      </Toggle>
      <Container tag="main" width={1140}>
        {currentBreakpoint !== 'desktop' && (
          <div className={cx('sidebar-wrapper')}>{bookingBlock}</div>
        )}
        <RelatedOffers id={id} data={relatedOffers} />
      </Container>
    </>
  );
};

OfferDetails.propTypes = propTypes;

// @TODO: maybe it make sense to extend these context to the app. To recheck and refactor.
const FilterExtendedOffer = (props) => {
  return (
    <DatesContextProvider>
      <PersonsContextProvider>
        <RoomsNumberContextProvider>
          <OfferDetails {...props} />
        </RoomsNumberContextProvider>
      </PersonsContextProvider>
    </DatesContextProvider>
  );
};

export default withErrorBoundary(FilterExtendedOffer);
