import gql from 'graphql-tag';

export default gql`
  fragment menuFields on Menu {
    menuItems {
      id
      title
      isLink
      url
      target
      parent
      weight
      description
      relatedContent {
        ... on Listing {
          id
          listingType {
            value
            label
          }
        }
      }
    }
  }
`;
