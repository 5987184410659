import React, { useState } from 'react';
import { object, func, bool, shape } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import HighlightedText from 'common/components/HighlightedText';

import { useUserContext } from '~/context/UserContext';
import InfoMessages from '~/components/InfoMessages';
import IntroSection from '~/components/IntroSection';
import LoyaltyMembershipBlock from '~/scenes/LoyaltyMembershipBlock';
import LoyaltyCard from '~/scenes/LoyaltyCard';
import CardVerification from '~/scenes/CardVerification';
import RelativeMember from '~/components/RelativeMember';
import ExclusiveAdvantages from '~/components/ExclusiveAdvantages';
import Advantages from '~/components/Advantages';

import styles from './LoyaltyProgramBlocks.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  blocks: shape({
    description: object,
    messages: object,
    joining: object,
    loyalty: object,
    cards: object,
    infoStatus: bool,
    infoAdvantages: bool,
  }).isRequired,
  refetch: func.isRequired,
};

const LoyaltyProgramBlocks = ({ blocks, refetch }) => {
  const [isLoyaltyMembership, setIsLoyaltyMembership] = useState(true);
  const [mymcNumberVerificationData, setMymcNumberVerificationData] = useState(
    null
  );

  const {
    description,
    messages,
    joining,
    loyalty,
    cards,
    infoStatus,
    infoAdvantages,
  } = blocks;

  const {
    userSbmIdentity: { firstName, birthName, marriedName },
  } = useUserContext();

  const { t } = useTranslation('loyalty-program');
  const infoLabels = t('messages', {
    returnObjects: true,
  });
  const pageIntroGreeting = t('pageIntroGreeting');
  const pageIntroText = t('pageIntroText', {
    returnObjects: true,
  });

  return (
    <>
      <h3 className={cx('title')}>
        <HighlightedText
          index={pageIntroGreeting.length}
          className={cx('hightlighted-text')}
        >{`${pageIntroGreeting} ${firstName} ${marriedName ||
          birthName}`}</HighlightedText>
      </h3>
      {messages && <InfoMessages messages={messages} labels={infoLabels} />}
      {description && (
        <IntroSection
          description={description}
          introText={pageIntroText}
          className={cx('intro-section')}
        />
      )}
      {isLoyaltyMembership && (joining?.linking || joining?.enrollment) && (
        <LoyaltyMembershipBlock
          joining={joining}
          setMymcNumberVerificationData={setMymcNumberVerificationData}
          setIsLoyaltyMembership={setIsLoyaltyMembership}
        />
      )}
      {loyalty && <LoyaltyCard data={loyalty} />}
      {!!mymcNumberVerificationData && (
        <CardVerification
          mymcNumberVerificationData={mymcNumberVerificationData}
          setMymcNumberVerificationData={setMymcNumberVerificationData}
          setIsLoyaltyMembership={setIsLoyaltyMembership}
          refetch={refetch}
        />
      )}
      {cards && <RelativeMember data={cards} />}
      {infoAdvantages && <ExclusiveAdvantages />}
      {infoStatus && <Advantages className={cx('advantages-block')} />}
    </>
  );
};

LoyaltyProgramBlocks.propTypes = propTypes;

export default LoyaltyProgramBlocks;
