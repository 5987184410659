import React, { useMemo } from 'react';
import { bool, object } from 'prop-types';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';
import { Field } from 'formik';

import Input from 'common/components/FormComponents/Input';
import SpinLoader from 'common/components/SpinLoader';
import Radio from 'common/components/FormComponents/Radio';
import TextArea from 'common/components/FormComponents/TextArea';
import InputPhone from 'common/components/FormComponents/InputPhone';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUiContext } from 'common/context/UiContext';
import Button from '~/components/Button';

import styles from './WellnessForm.module.scss';

const cx = classNames.bind(styles);

const WellnessFormFieldsFragment = gql`
  fragment WellnessFormFields on WellnessInfo {
    submitText
    fieldCivility {
      value
      label
    }
    fieldEmailLabel
    fieldFirstnameLabel
    fieldLastnameLabel
    fieldPhoneLabel
    fieldCommentLabel
    validationRequiredField
    validationIncorrectEmail
    noteRequiredField
  }
`;

const propTypes = {
  isSubmitting: bool.isRequired,
  errors: object.isRequired,
  data: propType(WellnessFormFieldsFragment).isRequired,
};

const fragment = {
  WellnessFormFieldsFragment,
};
const WellnessForm = ({
  isSubmitting,
  errors,
  data: {
    submitText,
    fieldCivility,
    fieldEmailLabel,
    fieldFirstnameLabel,
    fieldLastnameLabel,
    fieldPhoneLabel,
    fieldCommentLabel,
    noteRequiredField,
  },
}) => {
  const { isDesktop } = useUiContext();

  // Default isValid formic value can't be used for the form.
  // Formic update isValid value depending on validation schema rules only
  // for dirty form (if at least one of form fields was updated)
  const isValid = useMemo(() => !Object.keys(errors).length, [errors]);

  return (
    <div className={cx('root')}>
      <fieldset className={cx('fieldset', 'general-fields')}>
        <div className={cx('inline-radios')}>
          {fieldCivility.map(({ value, label }) => (
            <Field
              key={value}
              name="civility"
              component={Radio}
              id={value}
              label={label}
            />
          ))}
        </div>
        <Field
          name="firstname"
          component={Input}
          placeholder={fieldFirstnameLabel}
          required
          maxLength={255}
        />
        <Field
          name="lastname"
          component={Input}
          placeholder={fieldLastnameLabel}
          required
          maxLength={255}
        />
        <Field
          name="email"
          component={Input}
          type="email"
          placeholder={fieldEmailLabel}
          required
          maxLength={255}
        />
        <div className={cx('phone-wrapper')}>
          <Field name={'phone'}>
            {(field) => (
              <InputPhone
                className={cx('phone-input')}
                wrpClassName={cx('phone-input-wrapper')}
                label={fieldPhoneLabel}
                countryIso={'FR'}
                toTop={!isDesktop}
                {...field}
              />
            )}
          </Field>
        </div>
        <Field
          name="comment"
          component={TextArea}
          className={cx('comments')}
          placeholder={fieldCommentLabel}
        />
      </fieldset>
      <div className={cx('newsletter-cta')}>
        <Button
          type="submit"
          disabled={!isValid || isSubmitting}
          className={cx('newsletter-submit', { loading: isSubmitting })}
        >
          {isSubmitting && <SpinLoader className={cx('loader')} size={16} />}
          {submitText}
        </Button>
      </div>
      <div className={cx('requirements')}>*{noteRequiredField}</div>
    </div>
  );
};

WellnessForm.propTypes = propTypes;
WellnessForm.fragment = fragment;

export default withErrorBoundary(WellnessForm);
