import React from 'react';
import classNames from 'classnames/bind';
import { node, string, object } from 'prop-types';

import styles from './MultiField.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  formElementRef: object,
  mainField: node.isRequired,
  secondaryField: node,
  cta: node,
  className: string,
  rootClassName: string,
};

const defaultProps = {
  formElementRef: null,
  secondaryField: null,
  cta: null,
  className: null,
  rootClassName: null,
};

const MultiField = ({
  mainField,
  secondaryField,
  cta,
  className,
  rootClassName,
  formElementRef,
}) => (
  <div className={cx('root', rootClassName)} ref={formElementRef}>
    <div className={cx('fields-wrapper', className)}>
      <div className={cx('main-field')}>{mainField}</div>
      {secondaryField && (
        <div className={cx('secondary-field')}>{secondaryField}</div>
      )}
    </div>
    {cta && <div className={cx('cta')}>{cta}</div>}
  </div>
);

MultiField.propTypes = propTypes;
MultiField.defaultProps = defaultProps;

export default MultiField;
