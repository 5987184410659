import React, { useMemo } from 'react';
import { object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';

import { useUserContext } from '~/context/UserContext';
import { ROLE_TOP_DOG } from '~/constants';
import SubmitButton from '~/components/SubmitButton';
import gtmPushEvent from 'common/utils/gtmPushEvent';

import styles from './ApiLog.module.scss';
import ApiLogHandler from '../../containers/ApiLogHandler';
import SlideDownWrapper from '../../containers/SlideDownWrapper';
import WindowResizeListenerHandler from '../../containers/WindowResizeListenerHandler';
import ApiLogsMessages from '../ApiLogsMessages';
import MessageByStatusColor from '../MessageByStatusColor';

const cx = classNames.bind(styles);

const apiLogDataFragment = gql`
  fragment apiLogFields on SbmApiStatus {
    circuitBreakerOpen
    log {
      time
      message
    }
  }
`;

const propTypes = {
  circuitBreakerStatus: bool.isRequired,
  setCircuitBreakerStatus: func.isRequired,
  data: object.isRequired,
  parentRef: object.isRequired,
};

const defaultProps = {
  className: null,
};

const fragment = {
  apiLogDataFragment,
};

function ApiLog({
  data,
  parentRef,
  circuitBreakerStatus,
  setCircuitBreakerStatus,
}) {
  const { t } = useTranslation('edit-account');

  const { userRole } = useUserContext();

  const { log } = data;

  const reversedLog = useMemo(() => [...log].reverse(), [log]);

  const apiLogBlock = t('apiLogBlock', {
    returnObjects: true,
  });

  const {
    forceOpenCircuitLabel,
    forceOpenCircuitDescription,
    forceDoneOpenCircuitLabel,
    forceCloseCircuitLabel,
    forceCloseCircuitDescription,
    forceDoneCloseCircuitLabel,
    apiLogTitle,
    loadMoreButtonLabel,
    errorMsgBreakerOpen,
    everythingOkMsg,
  } = apiLogBlock;

  const messages = useMemo(() => {
    return {
      red: errorMsgBreakerOpen,
      green: everythingOkMsg,
    };
  }, [errorMsgBreakerOpen, everythingOkMsg]);

  return (
    <div className={cx('root')}>
      <ApiLogHandler setCircuitBreakerStatus={setCircuitBreakerStatus}>
        {({ handleToggleCircuitBreaker, submitCloseData, submitOpenData }) => (
          <>
            <div className={cx('message-wrapper')}>
              <MessageByStatusColor
                messages={messages}
                status={circuitBreakerStatus ? 'red' : 'green'}
              />
            </div>
            {userRole === ROLE_TOP_DOG && (
              <div className={cx('buttons-wrapper')}>
                <div className={cx('button-item')}>
                  <SubmitButton
                    saveButtonLabel={forceOpenCircuitLabel}
                    savedButtonLabel={forceDoneOpenCircuitLabel}
                    description={forceOpenCircuitDescription}
                    onClick={() => {
                      handleToggleCircuitBreaker(true);

                      gtmPushEvent({
                        event: 'click_force_opening_circuit',
                      });
                    }}
                    isSubmitting={submitOpenData?.isSubmitting}
                    isSaved={submitOpenData?.isSaved}
                    inverted
                  />
                </div>
                <div className={cx('button-item')}>
                  <SubmitButton
                    saveButtonLabel={forceCloseCircuitLabel}
                    savedButtonLabel={forceDoneCloseCircuitLabel}
                    description={forceCloseCircuitDescription}
                    onClick={() => {
                      handleToggleCircuitBreaker(false);

                      gtmPushEvent({
                        event: 'click_force_closing_circuit',
                      });
                    }}
                    isSubmitting={submitCloseData?.isSubmitting}
                    isSaved={submitCloseData?.isSaved}
                  />
                </div>
              </div>
            )}
            {!!log.length && (
              <div className={cx('logs-wrapper')}>
                <SlideDownWrapper
                  subtitleButtonLabel={apiLogTitle}
                  parentRef={parentRef}
                >
                  <WindowResizeListenerHandler>
                    {({ windowResized }) => (
                      <ApiLogsMessages
                        log={reversedLog}
                        loadMoreButtonLabel={loadMoreButtonLabel}
                        windowResized={windowResized}
                      />
                    )}
                  </WindowResizeListenerHandler>
                </SlideDownWrapper>
              </div>
            )}
          </>
        )}
      </ApiLogHandler>
    </div>
  );
}

ApiLog.propTypes = propTypes;
ApiLog.defaultProps = defaultProps;
ApiLog.fragment = fragment;

export default ApiLog;
