import { useCallback, useState, useEffect } from 'react';
import { func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';

import usePrevious from 'common/hooks/usePrevious';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

const UpdateNotificationStatusMutation = gql`
  mutation updateNotificationStatus($id: String, $status: NotificationStatus) {
    updateNotificationStatus(id: $id, status: $status) {
      result
      messages {
        info
        error
      }
    }
  }
`;

const propTypes = {
  children: func.isRequired,
};

const PAGINATION_COUNT = 10;
const DELETED = 'Deleted';

function NotificationsHandler({ children }) {
  const { languageISO3 } = useLocalisationContext();
  const prevLanguageISO3 = usePrevious(languageISO3);
  const { notificationsData, refetchNotifications, loadingNotificationsData } =
    useUserContext();

  useEffect(() => {
    if (prevLanguageISO3 && prevLanguageISO3 !== languageISO3) {
      refetchNotifications();
    }
  }, [languageISO3, prevLanguageISO3, refetchNotifications]);

  const [updateNotificationStatus] = useMutation(
    UpdateNotificationStatusMutation
  );

  const [visibleCount, setVisibleCount] = useState(PAGINATION_COUNT);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loadingRefetchNotifications, setLoadingRefetchNotifications] =
    useState(false);

  useEffect(() => {
    if (notificationsData?.getNotifications?.length > 0) {
      const normalizedData = notificationsData.getNotifications
        .map(({ id, message, created, ...rest }, index) => {
          return {
            id,
            text: message,
            date: moment(Number(created)).format('DD MMMM YYYY'),
            visible: index < visibleCount,
            ...rest,
          };
        })
        .filter(Boolean);

      setNotifications(normalizedData);

      setHasNextPage(normalizedData.length > PAGINATION_COUNT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData]);

  useEffect(() => {
    setHasNotifications(
      notifications.filter(({ status }) => status !== DELETED).length > 0
    );
  }, [notifications]);

  const handleUpdateNotification = useCallback(
    ({ event, id, status }) => {
      if (event) {
        event.stopPropagation();
      }

      const normalizedNotifications = notifications.map(
        ({ id: itemId, ...rest }) => {
          return {
            id: itemId,
            ...rest,
            ...(id === itemId && { status }),
          };
        }
      );

      setNotifications(normalizedNotifications);

      if (
        notifications.find((item) => item.id === id && item.status !== status)
      ) {
        setLoadingRefetchNotifications(true);

        updateNotificationStatus({
          variables: { id, status },
        })
          .then(() => {
            refetchNotifications().then(() => {
              setLoadingRefetchNotifications(false);
            });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications, updateNotificationStatus]
  );

  const handleLoadMore = useCallback(() => {
    const normalizedNotifications = notifications.map(({ ...rest }, index) => {
      return {
        ...rest,
        visible: index < visibleCount + PAGINATION_COUNT,
      };
    });

    if (normalizedNotifications[normalizedNotifications.length - 1].visible) {
      setHasNextPage(false);
    }

    setVisibleCount(visibleCount + PAGINATION_COUNT);
    setNotifications(normalizedNotifications);
  }, [notifications, visibleCount]);

  return children({
    loadingNotificationsData,
    notifications,
    handleUpdateNotification,
    handleLoadMore,
    hasNextPage,
    hasNotifications,
    loadingRefetchNotifications,
  });
}

NotificationsHandler.propTypes = propTypes;

export default withErrorBoundary(NotificationsHandler);
