import React from 'react';
import classNames from 'classnames/bind';

import ErrorMessageCustom from 'common/components/FormComponents/ErrorMessageCustom';
import FieldError from 'common/components/FormComponents/FieldError';
import Radio from 'common/components/FormComponents/Radio';

import styles from './FormRadios.module.scss';

const cx = classNames.bind(styles);

const FormRadios = ({
  options,
  colCount,
  disabled,
  field: { name: fieldName },
  field,
}) => {
  return (
    <div className={cx('root', { [`columns-${colCount}`]: colCount })}>
      {options.map(({ name: value, label }, index) => (
        <Radio
          key={index}
          smallStyledView
          id={value}
          value={value}
          label={label}
          field={field}
          disabled={disabled}
          className={cx('radio-item')}
        />
      ))}
      <ErrorMessageCustom name={fieldName}>
        {({ msg }) => <FieldError>{msg}</FieldError>}
      </ErrorMessageCustom>
    </div>
  );
};

export default FormRadios;
