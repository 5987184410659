import React from 'react';
import { string, bool, node } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './RadioButtonsContainer.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  label: string,
  className: string,
  errorClassName: string,
  required: bool,
  isError: bool,
  errorMsg: string,
  children: node,
};

const defaultProps = {
  label: null,
  className: null,
  errorClassName: null,
  required: false,
  error: false,
  errorMsg: null,
  children: null,
};

const RadioButtonsContainer = ({
  label,
  required,
  isError,
  errorMsg,
  children,
  className,
  errorClassName,
}) => (
  <>
    {!!label ? (
      <>
        <span
          className={cx('label', {
            'label-error': isError && errorMsg && required,
          })}
        >
          {label}
          {required && <span>{' *'}</span>}
        </span>
        <div className={cx('wrapper', className)}>
          {children}
          {isError && errorMsg && required && (
            <div className={cx('error', errorClassName)}>{errorMsg}</div>
          )}
        </div>
      </>
    ) : (
      <>
        {children}
        {isError && errorMsg && required && (
          <div className={cx('error', errorClassName)}>{errorMsg}</div>
        )}
      </>
    )}
  </>
);

RadioButtonsContainer.propTypes = propTypes;
RadioButtonsContainer.defaultProps = defaultProps;

export default RadioButtonsContainer;
