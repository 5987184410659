import React from 'react';
import { string, bool, number } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import IconContainer from 'common/components/IconContainer';
import numberWithSpaces from 'common/utils/numberWithSpaces';
import { LOYALTY_CARD_CODES } from 'common/constants';

import CardColor from '~/containers/CardColor';
import { ReactComponent as IconStar } from '~/media/icons/star-rounded.icon.svg';

import styles from './ProgressBarPoint.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  status: number.isRequired,
  width: number.isRequired,
  active: bool,
  loyaltyPoints: number.isRequired,
};

const defaultProps = {
  className: null,
  active: false,
};

const ProgressBarPoint = ({
  className,
  active,
  status,
  loyaltyPoints,
  width,
  children,
}) => {
  const { t } = useTranslation('loyalty-card');

  const statusObj = t('status', { returnObjects: true });

  return (
    <div
      className={cx('root-container', className)}
      style={{
        width: `${width}%`,
      }}
    >
      <div className={cx('root')}>
        <div
          className={cx('progress-bar-point-text', 'progress-bar-point-top')}
        >
          <CardColor className={cx('colored-status')} status={status}>
            {`${statusObj[`st${status}`]}`}
          </CardColor>
          {status !== LOYALTY_CARD_CODES.member && (
            <div className={cx('points-value')}>
              {numberWithSpaces(loyaltyPoints)}
            </div>
          )}
        </div>

        <div className={cx('progress-bar-point', { active: active })}>
          <IconContainer icon={IconStar} className={cx('icon-star')} />
        </div>

        <div
          className={cx('progress-bar-point-text', 'progress-bar-point-bottom')}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

ProgressBarPoint.propTypes = propTypes;
ProgressBarPoint.defaultProps = defaultProps;

export default ProgressBarPoint;
