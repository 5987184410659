import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { useAuthContext } from '~/context/AuthContext';
import { useMyaccHistory } from '~/hooks';

import styles from './BackButton.module.scss';

const cx = classNames.bind(styles);

function BackButton() {
  const history = useMyaccHistory();
  const { setErrorMsg } = useAuthContext();

  const { t } = useTranslation('auth');
  const backText = t('create.backText');
  const backButtonLabel = t('create.backButtonLabel');

  const onBackButtonClick = () => {
    setErrorMsg('');
    history.push({
      pathname: '/',
      search: history.location.search,
    });
  };

  return (
    <div className={cx('root')}>
      <span className={cx('back-text')}>{backText}</span>
      <button className={cx('back-button')} onClick={onBackButtonClick}>
        {backButtonLabel}
      </button>
    </div>
  );
}

export default BackButton;
