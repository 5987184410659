import React, { useState } from 'react';
import { func, number, string, shape } from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames/bind';
import flowRight from 'lodash/flowRight';

import { withPersonsContext } from 'common/context/PersonsContext';
import { withRoomsNumberContext } from 'common/context/RoomsNumberContext';

import styles from './BookingOfferPersonsSelector.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  adultLabel: string.isRequired,
  childLabel: string.isRequired,
  maxPeople: number,
  personsContext: shape({
    adultsAmount: number.isRequired,
    childrenAmount: number.isRequired,
    updateAdults: func.isRequired,
    updateChildren: func.isRequired,
  }).isRequired,
  roomsNumberContext: shape({
    roomsNumber: number.isRequired,
  }).isRequired,
  onClose: func,
};

const defaultProps = {
  maxPeople: null,
  onClose: null,
};

const BookingOfferPersonsSelector = ({
  adultLabel,
  childLabel,
  maxPeople,
  personsContext: {
    adultsAmount,
    childrenAmount,
    updateAdults,
    updateChildren,
  },
  roomsNumberContext: { roomsNumber },
  onClose,
}) => {
  const [selectedAdultsOption, setSelectedAdultsOption] =
    useState(adultsAmount);
  const [selectedChildrenOption, setSelectedChildrenOption] =
    useState(childrenAmount);

  const getPeople = (adults, arrLength, disabledThreshold) =>
    Array.from({ length: arrLength }, (el, i) => ({
      value: adults ? i + 1 : i,
      label: `${adults ? i + 1 : i} ${adults ? adultLabel : childLabel}`,
      disabled: i !== 0 && i >= disabledThreshold,
    }));

  let adults = [];
  let children = [];

  if (maxPeople) {
    adults = getPeople(
      true,
      7,
      maxPeople * roomsNumber - selectedChildrenOption
    );
    children = getPeople(
      false,
      4,
      maxPeople * roomsNumber - selectedAdultsOption + 1
    );
  } else {
    adults = getPeople(true, 7, 7);
    children = getPeople(false, 4, 4);
  }

  const handleAdultsChange = ({ value }) => {
    setSelectedAdultsOption(value);
    updateAdults(value);

    if (onClose) {
      onClose();
    }
  };

  const handleChildrenChange = ({ value }) => {
    setSelectedChildrenOption(value);
    updateChildren(value);

    if (onClose) {
      onClose();
    }
  };

  const handleDisabledOptions = (option) => option.disabled;

  const setSelectAdultsValues = adults.filter(
    (option) => option.value === selectedAdultsOption
  );

  const setSelectChildrenValues = children.filter(
    (option) => option.value === selectedChildrenOption
  );

  return (
    <div className={cx('root')}>
      <Select
        onChange={handleAdultsChange}
        value={setSelectAdultsValues}
        options={adults}
        isOptionDisabled={handleDisabledOptions}
        className={cx('select')}
        classNamePrefix="select"
        isSearchable={false} // fix bug with mobile keyboard on focus (ios)
        noOptionsMessage={() => null} // prevent default message
      />
      <Select
        onChange={handleChildrenChange}
        value={setSelectChildrenValues}
        options={children}
        isOptionDisabled={handleDisabledOptions}
        className={cx('select')}
        classNamePrefix="select"
        isSearchable={false} // fix bug with mobile keyboard on focus (ios)
        noOptionsMessage={() => null} // prevent default message
      />
    </div>
  );
};

BookingOfferPersonsSelector.propTypes = propTypes;
BookingOfferPersonsSelector.defaultProps = defaultProps;

export default flowRight(
  withRoomsNumberContext,
  withPersonsContext
)(BookingOfferPersonsSelector);
