import React, { useMemo } from 'react';
import { string, number } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import numberWithSpaces from 'common/utils/numberWithSpaces';
import { LOYALTY_POINTS_STATUS, LOYALTY_CARD_CODES } from 'common/constants';

import styles from './PointSubLabel.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  pointsStatus: string.isRequired,
  status: number,
  loyaltyStatusComputed: number,
  currentStatus: number.isRequired,
  expirationDate: string.isRequired,
  pointsToReach: number,
};

const defaultProps = {
  status: null,
  loyaltyStatusComputed: null,
  pointsToReach: null,
};

const PointSubLabel = ({
  pointsStatus,
  status,
  loyaltyStatusComputed,
  currentStatus,
  expirationDate,
  pointsToReach,
}) => {
  const { t } = useTranslation('loyalty-card');

  const progressBarLabels = t('progressBar', { returnObjects: true });
  const statusLabels = t('status', { returnObjects: true });

  const capitalizedStatusLabel = useMemo(
    () =>
      statusLabels[`st${currentStatus}`][0].toUpperCase() +
      statusLabels[`st${currentStatus}`].slice(1),
    [currentStatus, statusLabels]
  );

  const expirationDateStringified = useMemo(() => {
    return moment(expirationDate, 'DD/MM/YYYY').format(`Do MMMM YYYY`);
  }, [expirationDate]);

  if (
    pointsStatus === LOYALTY_POINTS_STATUS.expired &&
    status === currentStatus
  ) {
    return (
      <div className={cx('root')}>{progressBarLabels.updatingMessage}</div>
    );
  }

  if (
    pointsStatus === LOYALTY_POINTS_STATUS.underestimated &&
    status === loyaltyStatusComputed
  ) {
    return (
      <div className={cx('root')}>{progressBarLabels.updatingMessage}</div>
    );
  }

  if (
    pointsStatus === LOYALTY_POINTS_STATUS.overestimated &&
    status === currentStatus
  ) {
    return (
      <div className={cx('root')}>
        {progressBarLabels.overestimatedMessage
          .split(/(@count|@date|@pts|@status)/gi)
          .map((part, index) => {
            if (part === '@count') {
              return (
                <span key={index} className={cx('highlighted')}>
                  {numberWithSpaces(pointsToReach)}
                </span>
              );
            }
            if (part === '@date') return expirationDateStringified;
            if (part === '@pts') return progressBarLabels.pts;
            if (part === '@status') return capitalizedStatusLabel;
            return part;
          })}
      </div>
    );
  }

  if (pointsStatus === LOYALTY_POINTS_STATUS.accurate) {
    if (
      status === currentStatus &&
      currentStatus !== LOYALTY_CARD_CODES.member
    ) {
      return (
        <div className={cx('root')}>
          {progressBarLabels.currentAccurateMessage
            .split(/(@date|@status)/gi)
            .map((part, index) => {
              if (part === '@date') return expirationDateStringified;
              if (part === '@status') {
                return (
                  <span key={index} className={cx('highlighted')}>
                    {capitalizedStatusLabel}
                  </span>
                );
              }
              return part;
            })}
        </div>
      );
    }

    if (
      currentStatus + 10 <= LOYALTY_CARD_CODES.platinum &&
      status === currentStatus + 10
    ) {
      return (
        <div className={cx('root')}>
          {progressBarLabels.nextGoalMessage
            .split(/(@count|@pts|@status)/gi)
            .map((part, index) => {
              if (part === '@count') {
                return (
                  <span key={index} className={cx('highlighted')}>
                    {numberWithSpaces(pointsToReach)}
                  </span>
                );
              }
              if (part === '@pts') {
                return (
                  <span key={index} className={cx('highlighted')}>
                    {progressBarLabels.pts}
                  </span>
                );
              }
              if (part === '@status')
                return (
                  statusLabels[`st${currentStatus + 10}`][0].toUpperCase() +
                  statusLabels[`st${currentStatus + 10}`].slice(1)
                );
              return part;
            })}
        </div>
      );
    }
  }

  return null;
};

PointSubLabel.propTypes = propTypes;
PointSubLabel.defaultProps = defaultProps;

export default PointSubLabel;
