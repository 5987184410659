import React, { useMemo } from 'react';
import { shape, bool, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';

import Picture from 'common/components/Picture';
import getPictureSources from 'common/utils/getPictureSources';

import Button from '~/components/Button';
import MyAccLink from '~/components/MyAccLink';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './Card.module.scss';

const cx = classNames.bind(styles);

const cardMediaFragment = gql`
  fragment cardMediaFragment on MediaImage {
    id
    title
    image {
      id
      listItemLongStyleDesktop: url(style: list_item_long_style_desktop_webp) {
        ...urlFields
      }
      listItemLongStyleDesktop2x: url(
        style: list_item_long_style_desktop_2x_webp
      ) {
        ...urlFields
      }
      listItemShortStyleDesktop: url(
        style: list_item_short_style_desktop_webp
      ) {
        ...urlFields
      }
      listItemShortStyleDesktop2x: url(
        style: list_item_short_style_desktop_2x_webp
      ) {
        ...urlFields
      }
      tablet: url(style: list_item_common_tablet_webp) {
        ...urlFields
      }
      tablet2x: url(style: list_item_common_tablet_2x_webp) {
        ...urlFields
      }
      mobile: url(style: list_item_common_mobile_webp) {
        ...urlFields
      }
      mobile2x: url(style: list_item_common_mobile_2x_webp) {
        ...urlFields
      }
      alt
    }
  }
  fragment urlFields on ImageStyleUrl {
    width
    height
    url
  }
`;

const propTypes = {
  data: shape({
    bgImg: propType(cardMediaFragment),
    bgColor: string,
    color: string,
    ctaLink: string,
    globalLink: bool,
    ctaLabel: string,
    ctaInverted: bool,
  }).isRequired,
  isStretch: bool,
  className: string,
};

const defaultProps = {
  isStretch: false,
  className: '',
};

const Card = ({
  data: { bgImg, bgColor, color, ctaLink, ctaLabel, ctaInverted, globalLink },
  isStretch,
  className,
  children,
  ...attrs
}) => {
  const cardBgInfo = useMemo(() => {
    if (!bgImg) return {};

    const { image } = bgImg;
    const desktopSources = isStretch
      ? {
          desktop: image.listItemLongStyleDesktop,
          desktop2x: image.listItemLongStyleDesktop2x,
        }
      : {
          desktop: image.listItemShortStyleDesktop,
          desktop2x: image.listItemShortStyleDesktop2x,
        };

    const sources = getPictureSources(Object.assign({}, image, desktopSources));

    return {
      sources,
      src: desktopSources.desktop.url,
      alt: bgImg.alt,
    };
  }, [bgImg, isStretch]);

  const Tag = globalLink ? MyAccLink : 'div';

  return (
    <Tag
      to={ctaLink}
      className={cx(
        'root',
        { stretch: isStretch },
        className,
        Button.shared.hovered
      )}
      {...attrs}
      style={{
        backgroundColor: bgColor,
        color: color,
      }}
    >
      {bgImg?.image && (
        <div className={cx('bg-wrapper')}>
          <Picture
            sources={cardBgInfo.sources}
            src={cardBgInfo.src}
            alt={cardBgInfo.alt}
          />
        </div>
      )}
      <div className={cx('text-wrapper')}>{children}</div>
      {ctaLink && (
        <Button
          to={globalLink ? null : ctaLink}
          className={cx('link')}
          theme="rounded"
          size="m"
          inverted={ctaInverted}
          color="tallow-black"
        >
          {ctaLabel}
        </Button>
      )}
    </Tag>
  );
};

Card.propTypes = propTypes;
Card.defaultProps = defaultProps;
Card.fragments = {
  cardMediaFragment,
};

export default withErrorBoundary(Card);
