import React, { useEffect, useState, useRef } from 'react';
import { string } from 'prop-types';

import listenForOutsideClicks from 'common/utils/listenForOutsideClicks';

import LangSwitcher from '../LangSwitcher';

const propTypes = {
  className: string,
};

const defaultProps = {
  className: '',
};

const LangSwitcherContainer = ({ className }) => {
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(
    listenForOutsideClicks(listening, setListening, menuRef, () =>
      setIsOpen(false)
    )
  );

  return (
    <LangSwitcher
      ref={menuRef}
      className={className}
      disableOnClickOutside={!isOpen}
      handleClickOutside={setIsOpen}
      isOpen={isOpen}
      onButtonClick={toggle}
    />
  );
};

LangSwitcherContainer.propTypes = propTypes;
LangSwitcherContainer.defaultProps = defaultProps;

export default LangSwitcherContainer;
