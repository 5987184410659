import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './FieldError.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  children: string.isRequired,
  errorClassName: string,
};

const defaultProps = {
  errorClassName: null,
};

function FieldError({ children, errorClassName }) {
  return (
    <div className={cx('root', 'field-error-global', errorClassName)}>
      {children}
    </div>
  );
}

FieldError.propTypes = propTypes;
FieldError.defaultProps = defaultProps;

export default FieldError;
