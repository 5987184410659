import React from 'react';
import { string, bool, node, oneOf, shape } from 'prop-types';
import classNames from 'classnames/bind';

import Picture from 'common/components/Picture';
import getPictureSources from 'common/utils/getPictureSources';

import BackgroundSlider from './components/BackgroundSlider';
import styles from './FullscreenContainer.module.scss';

const cx = classNames.bind(styles);

const fullscreenContainerPropTypes = {
  className: string,
  children: node,
  tag: string,
  background: shape({
    desktop: shape({
      url: string,
    }),
    desktop2x: shape({
      url: string,
    }),
    tablet: shape({
      url: string,
    }),
    tablet2x: shape({
      url: string,
    }),
  }),
  backgroundTitle: string,
  backgroundSubtitle: string,
  withDefaultSlider: bool,
  withTablet: bool,
};

const fullscreenContainerDefaultProps = {
  className: null,
  children: null,
  tag: 'div',
  background: null,
  backgroundTitle: null,
  backgroundSubtitle: null,
  withDefaultSlider: false,
  withTablet: true,
};

// PushRightColFooterRightCol PropTypes
const fullscreenContentWrapperPropTypes = {
  children: node,
  position: oneOf(['left', 'center', 'right']),
  vertical: oneOf(['top', 'center', 'stretch']),
  fullWidth: bool,
  className: string,
  withTablet: bool,
};

const fullscreenContentWrapperDefaultProps = {
  children: null,
  position: 'center',
  vertical: 'top',
  fullWidth: false,
  className: null,
  withTablet: true,
};

const FullscreenContentWrapper = ({
  children,
  position,
  vertical,
  fullWidth,
  className,
  withTablet,
}) => {
  return (
    <div
      className={cx(
        `wrapper-root`,
        `position-${position}`,
        `vertical-${vertical}`,
        {
          'full-width': fullWidth,
          'with-tablet': withTablet,
          'without-tablet': !withTablet,
        },
        className
      )}
    >
      <div className={cx('content-wrapper')}>{children}</div>
    </div>
  );
};

const FullscreenContainer = ({
  className,
  children,
  tag: Tag,
  background,
  backgroundTitle,
  backgroundSubtitle,
  withDefaultSlider,
  withTablet,
  ...attributes
}) => {
  const sliderSettings = {
    className: cx('auth-slider', {
      'with-tablet': withTablet,
      'without-tablet': !withTablet,
    }),
    prevButtonClass: cx('slick-arrow-prev'),
    nextButtonClass: cx('slick-arrow-next'),
    slideTextClass: cx('slide-text-wrapper'),
    dotsClass: '',
  };

  return (
    <Tag
      className={cx(
        className,
        {
          'with-tablet': withTablet,
          'without-tablet': !withTablet,
        },
        'root'
      )}
      {...attributes}
    >
      {background && (
        <div className={cx('background-container')}>
          <Picture
            sources={getPictureSources(background)}
            src={background.desktop.url}
            alt="background image"
          />
          {(backgroundTitle || backgroundSubtitle) && (
            <div className={cx('background-text')}>
              {backgroundTitle && (
                <h2 className={cx('background-title')}>{backgroundTitle}</h2>
              )}
              {backgroundSubtitle && (
                <p className={cx('background-description')}>
                  {backgroundSubtitle}
                </p>
              )}
            </div>
          )}
        </div>
      )}
      {withDefaultSlider && (
        <div className={cx('slider-container')}>
          <BackgroundSlider withTablet={withTablet} settings={sliderSettings} />
        </div>
      )}
      {children}
    </Tag>
  );
};

FullscreenContainer.propTypes = fullscreenContainerPropTypes;
FullscreenContainer.defaultProps = fullscreenContainerDefaultProps;

FullscreenContentWrapper.propTypes = fullscreenContentWrapperPropTypes;
FullscreenContentWrapper.defaultProps = fullscreenContentWrapperDefaultProps;

export { FullscreenContentWrapper };
export default FullscreenContainer;
