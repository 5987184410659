import React, { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { parse } from 'query-string';
import { MobXProviderContext } from 'mobx-react';

const DatesContext = createContext();
const useDatesContext = () => useContext(DatesContext);
const DatesContextConsumer = DatesContext.Consumer;

const DatesContextProvider = ({ children }) => {
  const { datesStore } = useContext(MobXProviderContext);

  const [fromDate, setFromDateBasic] = useState(moment().startOf('day'));
  const [toDate, setToDateBasic] = useState(
    moment().startOf('day').add(1, 'days')
  );
  const [initialFromDate, setInitialFromDate] = useState(
    moment().startOf('day')
  );
  const [initialToDate, setInitialToDate] = useState(
    moment().startOf('day').add(1, 'days')
  );

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const { from_date: urlFromDate, to_date: urlToDate } = parse(
        window.location.search
      );

      let storageInitialFromDate = localStorage.getItem('initialFromDate');
      let storageInitialToDate = localStorage.getItem('initialToDate');

      let storageLastTimeFilterUpdated = localStorage.getItem(
        'lastTimeFilterUpdated'
      );

      if (urlFromDate) {
        storageInitialFromDate = moment(urlFromDate, 'YYYY-MM-DD')
          .startOf('day')
          .valueOf();
        storageInitialToDate =
          moment(urlToDate, 'YYYY-MM-DD').startOf('day').valueOf() ||
          moment().startOf('day').add(1, 'days').valueOf();
        storageLastTimeFilterUpdated = moment().valueOf();

        localStorage.setItem('initialFromDate', Number(storageInitialFromDate));
        localStorage.setItem('initialToDate', Number(storageInitialToDate));
        localStorage.setItem(
          'lastTimeFilterUpdated',
          Number(storageLastTimeFilterUpdated)
        );

        datesStore.onDatesChange({
          startDate: moment(Number(storageInitialFromDate)),
          endDate: moment(Number(storageInitialToDate)),
        });
      }

      if (
        storageInitialFromDate &&
        storageLastTimeFilterUpdated &&
        storageInitialToDate &&
        moment().diff(moment(Number(storageLastTimeFilterUpdated)), 'days') <
          30 &&
        moment(Number(storageInitialToDate)).isAfter(
          moment(Number(storageInitialFromDate))
        ) &&
        moment(Number(storageInitialFromDate)).isAfter(moment())
      ) {
        setInitialFromDate(moment(Number(storageInitialFromDate)));
        setInitialToDate(moment(Number(storageInitialToDate)));

        setFromDateBasic(moment(Number(storageInitialFromDate)));
        setToDateBasic(moment(Number(storageInitialToDate)));
      }
    } else {
      setFromDateBasic(initialFromDate);
      setToDateBasic(initialToDate);
    }
  }, []);

  const onDatesChange = ({ startDate, endDate }) => {
    setFromDateBasic(startDate.startOf('day'));
    setToDateBasic(
      endDate
        ? endDate.startOf('day')
        : startDate.clone().startOf('day').add(1, 'day')
    );

    setFromDate(startDate.startOf('day'));
    setToDate(
      endDate
        ? endDate.startOf('day')
        : startDate.clone().startOf('day').add(1, 'day')
    );
  };

  const getStorageFromDate = () => {
    let initialFromDate = moment().startOf('day');

    if (typeof localStorage !== 'undefined') {
      const storageFromDate = localStorage.getItem('initialFromDate');
      const storageLastTimeFilterUpdated = localStorage.getItem(
        'lastTimeFilterUpdated'
      );

      if (
        storageFromDate &&
        storageLastTimeFilterUpdated &&
        moment().diff(moment(Number(storageLastTimeFilterUpdated)), 'days') <
          30 &&
        moment(Number(storageFromDate)).isAfter(moment())
      ) {
        initialFromDate = moment(Number(storageFromDate));
      }
    }

    return initialFromDate;
  };

  const getStorageToDate = () => {
    let initialToDate = moment().startOf('day').add(1, 'days');

    if (typeof localStorage !== 'undefined') {
      const storageToDate = localStorage.getItem('initialToDate');
      const storageLastTimeFilterUpdated = localStorage.getItem(
        'lastTimeFilterUpdated'
      );

      if (
        storageToDate &&
        storageLastTimeFilterUpdated &&
        moment().diff(moment(Number(storageLastTimeFilterUpdated)), 'days') <
          30 &&
        moment(Number(storageToDate)).isAfter(moment())
      ) {
        initialToDate = moment(Number(storageToDate));
      }
    }

    return initialToDate;
  };

  const setStorageFromDate = () => {
    setFromDateBasic(getStorageFromDate());
  };

  const setStorageToDate = () => {
    setToDateBasic(getStorageToDate());
  };

  const setFromDate = (date) => {
    setFromDateBasic(date);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialFromDate', date.valueOf());
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const setToDate = (date) => {
    setToDateBasic(date);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialToDate', date.valueOf());
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const [minDateFrom, setMinDateFrom] = useState(null);

  const resetMinDate = () => {
    setMinDateFrom(null);
  };

  return (
    <DatesContext.Provider
      value={{
        fromDate,
        setFromDateBasic,
        toDate,
        setToDateBasic,
        initialFromDate,
        initialToDate,
        onDatesChange,
        getStorageFromDate,
        getStorageToDate,
        setStorageFromDate,
        setStorageToDate,
        setFromDate,
        setToDate,
        minDateFrom,
        setMinDateFrom,
        resetMinDate,
      }}
    >
      {children}
    </DatesContext.Provider>
  );
};

export { DatesContextConsumer, DatesContextProvider, useDatesContext };

export default DatesContext;
