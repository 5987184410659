import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { string } from 'prop-types';

import IconContainer from 'common/components/IconContainer';

import { ReactComponent as IconWifi } from '~/media/icons/wifi.icon.svg';
import { ReactComponent as IconStar } from '~/media/icons/star.icon.svg';
import { ReactComponent as IconDumbbell } from '~/media/icons/dumbbell.icon.svg';
import { ReactComponent as IconCard } from '~/media/icons/card.icon.svg';

import styles from './ExclusiveAdvantages.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
};

const defaultProps = {
  className: null,
};

function ExclusiveAdvantages({ className }) {
  const { t } = useTranslation('exclusive-advantages');
  const title = t('title');

  const tiles = useMemo(
    () => [
      { icon: IconStar, label: t('iconPointsLabel'), className: 'icon-star' },
      {
        icon: IconDumbbell,
        label: t('iconWellnessLabel'),
        className: 'icon-dumbbell',
      },
      { icon: IconWifi, label: t('iconWifiLabel'), className: 'icon-wifi' },
      { icon: IconCard, label: t('iconCasinoLabel'), className: 'icon-card' },
    ],
    [t]
  );

  return (
    <div className={cx('root', className)}>
      <div
        className={cx('title')}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <div className={cx('items')}>
        {tiles.map(({ icon, label, className }, index) => (
          <div className={cx('item')} key={index}>
            <div className={cx('icon-wrap')}>
              <IconContainer icon={icon} className={cx('icon', className)} />
            </div>
            <div
              className={cx('label')}
              dangerouslySetInnerHTML={{ __html: label }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

ExclusiveAdvantages.propTypes = propTypes;
ExclusiveAdvantages.defaultProps = defaultProps;

export default ExclusiveAdvantages;
