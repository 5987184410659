import { useMemo, useEffect } from 'react';
import { connect } from 'formik';
import { string, object, shape, func } from 'prop-types';

import { useEffectOnMount } from 'common/hooks';
import usePrevious from 'common/hooks/usePrevious';

import { CONSENT } from '~/constants';

const propTypes = {
  handledFieldName: string.isRequired,
  dataConsent: string,
  field: shape({
    name: string.isRequired,
  }).isRequired,
  formik: shape({
    touched: object.isRequired,
    values: object.isRequired,
    setFieldValue: func.isRequired,
  }).isRequired,
};

const defaultProps = {
  dataConsent: null,
};

const ConsentHandler = ({
  handledFieldName,
  dataConsent,
  field: { name },
  formik: { touched, values, setFieldValue },
}) => {
  const handledFieldValue = useMemo(() => values[handledFieldName], [
    handledFieldName,
    values,
  ]);
  const prevHandledFieldValue = usePrevious(handledFieldValue);

  useEffectOnMount(() => {
    setFieldValue(name, dataConsent);
  });

  useEffect(() => {
    if (
      touched[handledFieldName] &&
      prevHandledFieldValue !== handledFieldValue
    ) {
      setFieldValue(name, values[handledFieldName] ? CONSENT.yes : CONSENT.no);
    }
  }, [
    handledFieldName,
    handledFieldValue,
    name,
    prevHandledFieldValue,
    setFieldValue,
    touched,
    values,
  ]);

  return null;
};

ConsentHandler.propTypes = propTypes;
ConsentHandler.defaultProps = defaultProps;

export default connect(ConsentHandler);
