import { func, object, string } from 'prop-types';

const propTypes = {
  messages: object.isRequired,
  lastUpdateTime: string.isRequired,
  children: func.isRequired,
};

function AsyncMachine({ messages, lastUpdateTime, children }) {
  const orangeMessageNormalized = messages.orange.replace(
    '@lastUpdateTime',
    lastUpdateTime
  );
  messages = { ...messages, orange: orangeMessageNormalized };

  return children({
    messages,
  });
}

AsyncMachine.propTypes = propTypes;

export default AsyncMachine;
