import { useMemo, useState } from 'react';
import { func, arrayOf } from 'prop-types';
import moment from 'moment';
import { gql } from '@apollo/client';
import { propType } from 'graphql-anywhere';

import { EUROPE_PARIS_TIME_ZONE_NAME } from 'common/constants';
import momentWithTimeZonesData from 'common/momentWithTimeZonesData';
import normalizePrice from 'common/utils/normalizePrice';
import { withErrorBoundary } from 'common/containers/ErrorBoundary';

const bookingOfferFieldsFragment = gql`
  fragment bookingOfferFields on OfferProperty {
    price
    priceBeforeDiscount
    pricePoints
    priceFr: price(format: fr)
    parentProperty {
      ... on Hotel {
        id
        travelclickId
        __typename
      }
      ... on Restaurant {
        id
        __typename
      }
      ... on Nightlife {
        id
        __typename
      }
      ... on Casino {
        id
        __typename
      }
      ... on WellnessProperty {
        id
        __typename
      }
      ... on Shop {
        id
        __typename
      }
      ... on Event {
        id
        __typename
      }
    }
    offerFirstReservableDate
    offerPropertyEndDate
  }
`;

const propTypes = {
  data: arrayOf(propType(bookingOfferFieldsFragment).isRequired).isRequired,
  children: func.isRequired,
};

const BookingOffer = function ({ data, children }) {
  const [property, setProperty] = useState(data?.length === 1 ? data[0] : null);

  const propertiesHotel = data.filter(
    (item) => item.parentProperty.__typename === 'Hotel'
  );
  const withHotels = !!propertiesHotel.length;
  const filteredProperties = withHotels ? propertiesHotel : data;

  const bestOfferProperty = useMemo(() => {
    let bestOffer = filteredProperties[0];

    filteredProperties.forEach((item) => {
      if (
        +parseFloat(normalizePrice(item.priceFr, 'fr')) <
        +parseFloat(normalizePrice(bestOffer.priceFr, 'fr'))
      ) {
        bestOffer = item;
      }
    });

    return bestOffer;
  }, [filteredProperties]);

  const areDatesValid = function (endDate, minStay) {
    if (!endDate) return true;

    const today = momentWithTimeZonesData.tz(EUROPE_PARIS_TIME_ZONE_NAME);

    if (today.isBefore(moment(endDate))) {
      return minStay
        ? today.add(Number(minStay), 'days').isBefore(endDate)
        : true;
    } else {
      return false;
    }
  };

  const propertyDatesInfo = useMemo(() => {
    return property
      ? {
          id: property.parentProperty.id,
          minimumNights: property.offerMinimalStayDuration
            ? Number(property.offerMinimalStayDuration)
            : null,
          maximumNights: property.offerMaximalStayDuration
            ? Number(property.offerMaximalStayDuration)
            : null,
          datesStartRange: property.offerFirstReservableDate
            ? property.offerFirstReservableDate
            : null,
          datesEndRange: property.offerPropertyEndDate
            ? moment(property.offerPropertyEndDate)
                .add(1, 'day')
                .format('YYYY-MM-DD')
            : null,
        }
      : {};
  }, [property]);

  const propertyDatesStatus = useMemo(() => {
    if (Object.keys(propertyDatesInfo).length) {
      if (
        areDatesValid(
          propertyDatesInfo.datesEndRange,
          propertyDatesInfo.minimumNights
        )
      ) {
        if (
          propertyDatesInfo.datesStartRange &&
          propertyDatesInfo.datesEndRange &&
          propertyDatesInfo.minimumNights &&
          moment(propertyDatesInfo.datesEndRange).diff(
            moment(propertyDatesInfo.datesStartRange),
            'days'
          ) -
            1 ===
            propertyDatesInfo.minimumNights
        ) {
          return 'read-only';
        }

        if (
          propertyDatesInfo.minimumNights &&
          propertyDatesInfo.maximumNights &&
          propertyDatesInfo.minimumNights === propertyDatesInfo.maximumNights
        ) {
          return 'fixed-range';
        }

        return 'valid';
      } else {
        return 'invalid';
      }
    } else {
      return 'empty';
    }
  }, [propertyDatesInfo]);

  return children({
    withHotels,
    filteredProperties,
    property,
    propertyDatesInfo,
    propertyDatesStatus,
    bestOfferProperty: property || bestOfferProperty,
    setProperty,
  });
};

BookingOffer.propTypes = propTypes;
BookingOffer.fragment = {
  bookingOfferFieldsFragment,
};

export default withErrorBoundary(BookingOffer);
