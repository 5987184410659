import { useState, useCallback } from 'react';
import { func } from 'prop-types';
import { gql, useMutation } from '@apollo/client';

export const ToggleCircuitBreakerMutation = gql`
  mutation toggleCircuitBreaker($open: Boolean!) {
    toggleCircuitBreaker(open: $open) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

const propTypes = {
  setCircuitBreakerStatus: func.isRequired,
  children: func.isRequired,
};

const initialValue = {
  isSubmitting: false,
  isSaved: false,
};

function ApiLogHandler({ setCircuitBreakerStatus, children }) {
  const [submitOpenData, setSubmitOpenData] = useState(initialValue);
  const [submitCloseData, setSubmitCloseData] = useState(initialValue);

  const setIsSubmtting = useCallback(
    (status, value) =>
      status
        ? setSubmitOpenData({ ...submitOpenData, isSubmitting: value })
        : setSubmitCloseData({ ...submitCloseData, isSubmitting: value }),
    [submitOpenData, submitCloseData, setSubmitOpenData, setSubmitCloseData]
  );

  const setIsSaved = useCallback(
    (status, value) =>
      status
        ? setSubmitOpenData({ ...submitOpenData, isSaved: value })
        : setSubmitCloseData({ ...submitCloseData, isSaved: value }),
    [submitOpenData, submitCloseData, setSubmitOpenData, setSubmitCloseData]
  );

  const resetValues = useCallback(() => {
    setSubmitOpenData({ ...initialValue });
    setSubmitCloseData({ ...initialValue });
  }, [setSubmitOpenData, setSubmitCloseData]);

  const [mutate] = useMutation(ToggleCircuitBreakerMutation);

  const handleToggleCircuitBreaker = (status) => {
    resetValues();
    setIsSubmtting(status, true);

    mutate({
      variables: { open: status },
    })
      .then(({ data: { toggleCircuitBreaker } }) => {
        if (!!toggleCircuitBreaker) {
          setIsSubmtting(status, false);
          setIsSaved(status, true);
          setCircuitBreakerStatus(status);
        }
      })
      .catch((error) => {
        console.warn(error);
      });
  };

  return children({
    handleToggleCircuitBreaker,
    submitCloseData,
    submitOpenData,
  });
}

ApiLogHandler.propTypes = propTypes;

export default ApiLogHandler;
