import React from 'react';
import { func, bool, string } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconCheckboxChecked } from 'common/media/icons/check.icon.svg';

import styles from './CheckboxesItem.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  label: string,
  textClassName: string,
  isIconHidden: bool,
  className: string,
  classNameCheckbox: string,
  disabled: bool,
  checked: bool,
  onChange: func,
  onBlur: func,
};

const defaultProps = {
  label: '',
  textClassName: null,
  isIconHidden: false,
  className: null,
  classNameCheckbox: null,
  classNameLabel: null,
  disabled: false,
  checked: false,
  onChange: null,
  onBlur: null,
};

const CheckboxesItem = ({
  label,
  textClassName,
  isIconHidden,
  className,
  classNameCheckbox,
  classNameLabel,
  disabled,
  checked,
  onBlur,
  onChange,
  name,
}) => (
  <div className={cx('root', className)}>
    <input
      type="checkbox"
      name={name}
      className={cx('checkbox', classNameCheckbox)}
      id={name}
      checked={checked}
      onBlur={onBlur}
      onChange={onChange}
      disabled={disabled}
    />
    <label
      className={cx('label', classNameLabel, { disabled: disabled })}
      htmlFor={name}
    >
      {!isIconHidden && (
        <div className={cx('box-wrapper', { checked: checked })}>
          {checked && (
            <IconContainer className={cx('icon')} icon={IconCheckboxChecked} />
          )}
        </div>
      )}
      {label && <span className={cx('text', textClassName)}>{label}</span>}
    </label>
  </div>
);

CheckboxesItem.propTypes = propTypes;
CheckboxesItem.defaultProps = defaultProps;

export default CheckboxesItem;
