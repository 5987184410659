import React, { useMemo } from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames/bind';

import Picture from 'common/components/Picture';

import CardColor from '~/containers/CardColor';
import {
  CARD_MEMBRE_IMG,
  CARD_SILVER_IMG,
  CARD_GOLD_IMG,
  CARD_PLATINUM_IMG,
} from '~/constants';

import styles from './AdvantagesHeader.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  tableVisible: bool.isRequired,
  toggleTableVisibility: func.isRequired,
  advantagesHeader: object.isRequired,
  isCompact: bool,
  activeSwitch: object,
  setActiveSwitch: func,
};

const defaultProps = {
  isCompact: false,
  activeSwitch: null,
  setActiveSwitch: null,
};

function AdvantagesHeader({
  tableVisible,
  toggleTableVisibility,
  advantagesHeader,
  isCompact,
  activeSwitch: { name: activeSwitchName },
  setActiveSwitch,
}) {
  const buttonSwitchLabels = useMemo(
    () => [
      advantagesHeader.cardMemberLabel,
      advantagesHeader.cardSilverLabel,
      advantagesHeader.cardGoldLabel,
      advantagesHeader.cardPlatinumLabel,
    ],
    [advantagesHeader]
  );

  return isCompact ? (
    <div className={cx('header-compact')}>
      <button
        className={cx('description', 'description-compact')}
        onClick={toggleTableVisibility}
      >
        {advantagesHeader.description}
        <span
          className={cx('triangle-icon-down', {
            'triangle-icon-up': tableVisible,
          })}
        ></span>
      </button>
      {tableVisible && (
        <div className={cx('button-wrapper')}>
          {buttonSwitchLabels.map((item, index) => (
            <div key={index} className={cx('button-switch-wrapper')}>
              <button
                className={cx('button-switch', {
                  active: activeSwitchName === item,
                })}
                onClick={() => setActiveSwitch({ name: item, order: index })}
              >
                {item}
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  ) : (
    <div
      className={cx('header', {
        'header-widen': tableVisible,
      })}
    >
      <button className={cx('description')} onClick={toggleTableVisibility}>
        {advantagesHeader.description}
        <span
          className={cx('triangle-icon-down', {
            'triangle-icon-up': tableVisible,
          })}
        ></span>
      </button>
      <div
        className={cx('card-list', {
          'card-list-widen': tableVisible,
        })}
      >
        <div className={cx('card-item-wrapper')}>
          <CardColor className={cx('card-item')} shadow status={10}>
            <Picture
              sources={[
                {
                  srcSet: `${CARD_MEMBRE_IMG.urlS} 1x, ${CARD_MEMBRE_IMG.urlS2x} 2x`,
                  type: 'image/webp',
                },
              ]}
              src={CARD_MEMBRE_IMG.urlS}
              alt={advantagesHeader.cardMemberPictureAlt}
            />
          </CardColor>
          {tableVisible && (
            <div className={cx('card-status', 'card-status-member')}>
              {advantagesHeader.cardMemberLabel}
            </div>
          )}
        </div>
        <div className={cx('card-item-wrapper')}>
          <CardColor className={cx('card-item')} shadow status={20}>
            <Picture
              sources={[
                {
                  srcSet: `${CARD_SILVER_IMG.urlS} 1x, ${CARD_SILVER_IMG.urlS2x} 2x`,
                  type: 'image/webp',
                },
              ]}
              src={CARD_SILVER_IMG.urlS}
              alt={advantagesHeader.cardSilverPictureAlt}
            />
          </CardColor>
          {tableVisible && (
            <div className={cx('card-status', 'card-status-silver')}>
              {advantagesHeader.cardSilverLabel}
            </div>
          )}
        </div>
        <div className={cx('card-item-wrapper')}>
          <CardColor className={cx('card-item')} shadow status={30}>
            <Picture
              sources={[
                {
                  srcSet: `${CARD_GOLD_IMG.urlS} 1x, ${CARD_GOLD_IMG.urlS2x} 2x`,
                  type: 'image/webp',
                },
              ]}
              src={CARD_GOLD_IMG.urlS}
              alt={advantagesHeader.cardGoldPictureAlt}
            />
          </CardColor>
          {tableVisible && (
            <div className={cx('card-status', 'card-status-gold')}>
              {advantagesHeader.cardGoldLabel}
            </div>
          )}
        </div>
        <div className={cx('card-item-wrapper')}>
          <CardColor className={cx('card-item')} shadow status={40}>
            <Picture
              sources={[
                {
                  srcSet: `${CARD_PLATINUM_IMG.urlS} 1x, ${CARD_PLATINUM_IMG.urlS2x} 2x`,
                  type: 'image/webp',
                },
              ]}
              src={CARD_PLATINUM_IMG.urlS}
              alt={advantagesHeader.cardPlatinumPictureAlt}
            />
          </CardColor>
          {tableVisible && (
            <div className={cx('card-status', 'card-status-platinum')}>
              {advantagesHeader.cardPlatinumLabel}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

AdvantagesHeader.propTypes = propTypes;
AdvantagesHeader.defaultProps = defaultProps;

export default AdvantagesHeader;
