import React, { useState } from 'react';
import { string, node, object, oneOfType, func, bool } from 'prop-types';
import { useQuery } from '@apollo/client';
import Router from 'next/router';

const propTypes = {
  to: oneOfType([string, object]).isRequired,
  children: node.isRequired,
  scroll: bool,
  prefetchRoute: bool,
  prefetchRouteOnHover: bool,
  onClick: func,
  onError: func,
  itemProp: string,
};

const defaultProps = {
  scroll: true,
  prefetchRoute: false,
  prefetchRouteOnHover: true,
  onClick: null,
  onError: null,
  itemProp: null,
};

const LinkNext = ({
  to,
  children,
  innerRef,
  className,
  title,
  target,
  rel,
  scroll,
  prefetchRouteOnHover,
  onClick: onClickProp,
  onError: onErrorProp,
  itemProp,
}) => {
  const [skip, setSkip] = useState(true);

  const onMouseEnter = () => {
    if (prefetchRouteOnHover && skip) {
      setSkip(false);
    }
  };

  const onLinkClick = (event, data) => {
    event.preventDefault();

    if (typeof onClickProp === 'function') {
      onClickProp();
    }

    // Custom Router function.
    // Check lib/extendRouter if function doesn't exist.
    Router.fetchAndPush(to, data)
      .then((success) => {
        if (!success) return;

        if (scroll) {
          window.scrollTo(0, 0);
          document.body.focus();
        }
      })
      .catch((err) => {
        if (typeof onErrorProp === 'function') {
          onErrorProp(err);
        }
      });
  };

  const { data } = useQuery(Router.routeQuery, {
    variables: { url: to },
    skip,
    fetchPolicy: 'no-cache',
  });

  return (
    <a
      href={to}
      ref={innerRef}
      onClick={(e) => onLinkClick(e, data?.route)}
      className={className}
      title={title}
      target={target}
      rel={rel}
      onMouseEnter={onMouseEnter}
      itemProp={itemProp}
    >
      {children}
    </a>
  );
};

LinkNext.propTypes = propTypes;
LinkNext.defaultProps = defaultProps;

export default LinkNext;
