import React, { useState, useEffect, useRef } from 'react';
import { node, string, object, number } from 'prop-types';

import { withErrorBoundary } from 'common/containers/ErrorBoundary';
import ReadMore from 'common/components/ReadMore';

const propTypes = {
  className: string.isRequired,
  labels: object.isRequired,
  children: node.isRequired,
  colorBg: string,
  lines: number,
};

const defaultProps = {
  colorBg: null,
  lines: 6,
};

const ReadMoreContainer = ({ children, className, labels, colorBg, lines }) => {
  const textContainer = useRef(null);
  const [isExpanded, setExpanded] = useState(false);
  const [isShowMoreEnabled, setShowMoreEnabledStatus] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  let textWrapEl;
  let textLineHeight;
  let textHeight;

  // Re-calculate each time, so we do not know if children will be updated
  useEffect(() => {
    textWrapEl = textContainer.current.querySelector('.js-text-wrap-animated');
    textLineHeight = parseInt(
      document.defaultView
        .getComputedStyle(textWrapEl, null)
        .getPropertyValue('line-height'),
      10
    );
    textHeight = textWrapEl.children[0].clientHeight;

    const isShowMoreEnabledCurrent = textHeight / textLineHeight > lines;

    if (isShowMoreEnabledCurrent !== isShowMoreEnabled) {
      setShowMoreEnabledStatus(isShowMoreEnabledCurrent);
    }
  });

  useEffect(() => {
    if (isShowMoreEnabled && !isExpanded) {
      textWrapEl.style.height = lines * textLineHeight + 'px';
    } else if (isShowMoreEnabled && isExpanded) {
      textWrapEl.style.height = textHeight + 'px';
    } else {
      textWrapEl.style.height = 'auto';
    }
  }, [isExpanded, isShowMoreEnabled]);

  return (
    <div className={className} ref={textContainer}>
      <ReadMore
        labels={labels}
        isExpanded={isExpanded}
        isShowMoreEnabled={isShowMoreEnabled}
        toggleExpanded={toggleExpanded}
        colorBg={colorBg}
      >
        {children}
      </ReadMore>
    </div>
  );
};

ReadMoreContainer.propTypes = propTypes;
ReadMoreContainer.defaultProps = defaultProps;

export default withErrorBoundary(ReadMoreContainer);
