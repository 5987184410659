import React from 'react';
import { bool, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { gql } from '@apollo/client';

import Picture from 'common/components/Picture';
import numberWithSufix from 'common/utils/numberWithSufix';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUserContext } from '~/context/UserContext';
import getCardImg from '~/utils/getCardImg';
import loyaltyInfo from '~/utils/loyaltyInfo';

import Card from '../Card';
import styles from './UserCard.module.scss';

const { REACT_APP_MYACC_DOMAIN } = process.env;
const cx = classNames.bind(styles);

const userCardDataFragment = gql`
  fragment userCardFields on SbmLoyaltyBlockLoyalty {
    rewardPoints
    status
    statusPoints
  }
`;

const propTypes = {
  data: propType(userCardDataFragment).isRequired,
  isStretch: bool,
  className: string,
};

const defaultProps = {
  isStretch: false,
  className: '',
};

const UserCard = ({
  data: { status: statusCode, statusPoints, rewardPoints },
  isStretch,
  className,
  ...attrs
}) => {
  const { t } = useTranslation(['loyalty-program', 'loyalty-card', 'common']);
  const loyaltyPointsLabel = t('loyalty-card:loyaltyPointsLabel');
  const myPointsLable = t('loyalty-card:myPointsLable');
  const memberTitle = t('loyalty-card:title');
  const colorScheme = t('loyalty-card:color-scheme', {
    returnObjects: true,
  });
  const wordings = t('relativeMember', {
    returnObjects: true,
  });
  const numberSufixes = t('common:number-sufixes', {
    returnObjects: true,
  });
  const pageIntroGreeting = t('pageIntroGreeting');
  const loyaltyColorScheme = colorScheme[statusCode];
  const {
    userSbmIdentity: { firstName },
  } = useUserContext();
  const status = loyaltyInfo.getCardStatus(statusCode, wordings);

  const userCardData = {
    bgColor: loyaltyColorScheme.background,
    color: loyaltyColorScheme['color-1'],
    ctaLink: `${REACT_APP_MYACC_DOMAIN}/loyalty-program`,
    globalLink: true,
    ctaInverted: true,
    ctaLabel: t('loyalty-card:userCardCta'),
  };

  const sufixes = {
    million: numberSufixes.million,
    billion: numberSufixes.billion,
    trillion: numberSufixes.trillion,
  };

  const CardImage = function () {
    const picture = getCardImg(statusCode);
    return (
      <Picture
        sources={[
          {
            srcSet: `${picture.urlB} 1x, ${picture.urlB2x} 2x`,
            type: 'image/webp',
          },
        ]}
        src={picture.urlB}
        alt={status}
      />
    );
  };

  return (
    <Card data={userCardData} isStretch={isStretch} className={cx('user-card')}>
      <div className={cx('card-image')}>
        <CardImage />
      </div>
      <h3 className={cx('card-sub-title')}>{memberTitle}</h3>
      <h2 className={cx('card-title')}>
        {pageIntroGreeting} {firstName}
      </h2>
      <p
        className={cx('card-status')}
        style={{ color: loyaltyColorScheme['color-2'] }}
      >
        {status}
      </p>
      <div className={cx('card-statistic')}>
        <div className={cx('statistic-wrap')}>
          <p
            className={cx('statistic-title')}
            style={{ color: loyaltyColorScheme['color-3'] }}
          >
            {loyaltyPointsLabel}
          </p>
          <p
            className={cx('statistic-info')}
            style={{ color: loyaltyColorScheme['color-4'] }}
          >
            {numberWithSufix(statusPoints, sufixes)}
          </p>
        </div>
        <div className={cx('statistic-wrap')}>
          <p
            className={cx('statistic-title')}
            style={{ color: loyaltyColorScheme['color-3'] }}
          >
            {myPointsLable}
          </p>
          <p
            className={cx('statistic-info')}
            style={{ color: loyaltyColorScheme['color-4'] }}
          >
            {numberWithSufix(rewardPoints, sufixes)}
          </p>
        </div>
      </div>
    </Card>
  );
};

UserCard.propTypes = propTypes;
UserCard.defaultProps = defaultProps;
UserCard.fragments = {
  userCardDataFragment,
};

export default withErrorBoundary(UserCard);
