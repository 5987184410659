import React from 'react';
import { bool, string, array } from 'prop-types';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import PanelSection from '~/components/PanelSection';
import FormButtons from '~/components/FormButtons';

import FormContactsObserver from './containers/FormContactsObserver';
import FormContactsHandler from './containers/FormContactsHandler';
import EmailFieldsHandler from './containers/EmailFieldsHandler';
import PhoneFieldsHandler from './containers/PhoneFieldsHandler';
import AddressFieldsHandler from './containers/AddressFieldsHandler';
import EmailFields from './components/EmailFields';
import PhoneFields from './components/PhoneFields';
import AddressFields from './components/AddressFields';
import FormContactsMessages from './components/FormContactsMessages';

const propTypes = {
  isMyCs: bool,
  userHash: string,
  email: array.isRequired,
  phone: array.isRequired,
  address: array.isRequired,
  redirectTo: string,
};

const defaultProps = {
  isMyCs: false,
  userHash: null,
  redirectTo: null,
};

function FormContacts({ isMyCs, userHash, email, phone, address, redirectTo }) {
  const { t } = useTranslation(['contacts-form', 'common']);

  const validationRequiredFieldMessage = t(
    'contacts-form:validationRequiredFieldMessage'
  );
  const validationIncorrectEmailMessage = t(
    'contacts-form:validationIncorrectEmailMessage'
  );
  const validationPhonePatternMessage = t(
    'contacts-form:validationPhonePatternMessage'
  );
  const validationLettersOnlyMessage = t(
    'contacts-form:validationLettersOnlyMessage'
  );
  const validationLettersAndNumbersOnlyMessage = t(
    'contacts-form:validationLettersAndNumbersOnlyMessage'
  );

  const saveButtonLabel = t('common:save');
  const savedButtonLabel = t('common:saved');
  const cancelButtonLabel = t('common:cancel');

  return (
    <FormContactsHandler
      isMyCs={isMyCs}
      userHash={userHash}
      email={email}
      phone={phone}
      address={address}
      validationRequiredFieldMessage={validationRequiredFieldMessage}
      validationIncorrectEmailMessage={validationIncorrectEmailMessage}
      validationLettersOnlyMessage={validationLettersOnlyMessage}
      validationLettersAndNumbersOnlyMessage={
        validationLettersAndNumbersOnlyMessage
      }
    >
      {({
        initialValues,
        validationSchema,
        emailList,
        emailCategoryList,
        phoneList,
        phoneCategoryList,
        addressList,
        addressCategoryList,
        handleSubmit,
        submitError,
        submitSuccess,
        markDeletedFields,
        onFormChange,
        formValues,
        formDispatch,
        isAllPhonesValid,
        isValidationErrorVisible,
        setValidationErrorVisible,
        mandatoryAddressGroups,
        setFormValues,
      }) => (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            submitCount,
            values,
            errors,
            validateForm,
          }) => (
            <Form onChange={onFormChange}>
              <FormContactsObserver
                setFormValues={setFormValues}
                values={values}
              />
              <EmailFieldsHandler
                emailList={emailList}
                phoneList={phoneList}
                addressList={addressList}
                formValues={formValues}
                formDispatch={formDispatch}
              >
                {({ onEmailAdd, onEmailRemove }) => (
                  <EmailFields
                    emailList={emailList}
                    emailCategoryList={emailCategoryList}
                    onEmailAdd={onEmailAdd}
                    onEmailRemove={onEmailRemove}
                    setFieldValue={setFieldValue}
                    values={values}
                    markDeletedFields={markDeletedFields}
                  />
                )}
              </EmailFieldsHandler>

              <PhoneFieldsHandler
                emailList={emailList}
                phoneList={phoneList}
                addressList={addressList}
                formValues={formValues}
                formDispatch={formDispatch}
              >
                {({ onPhoneAdd, onPhoneRemove, resetPhone }) => (
                  <PhoneFields
                    phoneList={phoneList}
                    phoneCategoryList={phoneCategoryList}
                    onPhoneAdd={onPhoneAdd}
                    onPhoneRemove={onPhoneRemove}
                    resetPhone={resetPhone}
                    setFieldValue={setFieldValue}
                    values={values}
                    markDeletedFields={markDeletedFields}
                    validationPhonePatternMessage={
                      validationPhonePatternMessage
                    }
                  />
                )}
              </PhoneFieldsHandler>

              <AddressFieldsHandler
                emailList={emailList}
                phoneList={phoneList}
                addressList={addressList}
                formValues={formValues}
                formDispatch={formDispatch}
              >
                {({ onAddressAdd, onAddressRemove, onAddressReset }) => (
                  <AddressFields
                    addressList={addressList}
                    addressCategoryList={addressCategoryList}
                    onAddressAdd={onAddressAdd}
                    onAddressRemove={onAddressRemove}
                    onAddressReset={onAddressReset}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    values={values}
                    markDeletedFields={markDeletedFields}
                    mandatoryAddressGroups={mandatoryAddressGroups}
                    setFieldError={setFieldError}
                    validateForm={validateForm}
                    setValidationErrorVisible={setValidationErrorVisible}
                  />
                )}
              </AddressFieldsHandler>

              <FormContactsMessages
                submitCount={submitCount}
                submitError={submitError}
                errors={errors}
                isAllPhonesValid={isAllPhonesValid}
                isValidationErrorVisible={isValidationErrorVisible}
                setValidationErrorVisible={setValidationErrorVisible}
              />

              <PanelSection>
                <FormButtons
                  isSubmitting={isSubmitting}
                  isSaved={submitSuccess}
                  to={redirectTo}
                  savedButtonLabel={savedButtonLabel}
                  saveButtonLabel={saveButtonLabel}
                  cancelButtonLabel={cancelButtonLabel}
                />
              </PanelSection>
            </Form>
          )}
        </Formik>
      )}
    </FormContactsHandler>
  );
}

FormContacts.propTypes = propTypes;
FormContacts.defaultProps = defaultProps;

export default FormContacts;
