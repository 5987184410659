import React from 'react';
import { object, array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import moment from 'moment';

import PanelField from '~/components/PanelField';

import styles from './IdentityFields.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  data: object.isRequired,
  nationalities: array.isRequired,
};

function IdentityFields({ data, nationalities }) {
  const { t } = useTranslation('identity-form');
  const nameFieldLabel = t('nameFieldLabel');
  const birthDateFieldLabel = t('birthDateFieldLabel');
  const nationalityFieldLabel = t('nationalityFieldLabel');

  const { sbmApiFirstName, marriedName, birthName, sbmApiBirthDate } = data;

  return (
    <>
      <PanelField
        label={nameFieldLabel}
        value={`${sbmApiFirstName} ${marriedName || birthName}`}
      />
      <div className={cx('inline-fields')}>
        {sbmApiBirthDate && (
          <PanelField
            label={birthDateFieldLabel}
            value={moment(sbmApiBirthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          />
        )}
        {nationalities.length > 0 && (
          <PanelField
            label={nationalityFieldLabel}
            value={nationalities.join(', ')}
          />
        )}
      </div>
    </>
  );
}

IdentityFields.propTypes = propTypes;

export default IdentityFields;
