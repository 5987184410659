import { ADDRESS_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';

const listWithNewValues = ({ list, formValues }) => {
  if (!list?.length) return [];

  const setItem = (fieldName, fieldValue, item) => {
    if (fieldName && (formValues[fieldName] || formValues[fieldName] === '')) {
      item[fieldValue] = formValues[fieldName];
    }
  };

  list.forEach((item) => {
    setItem(item.name, 'value', item);
    setItem(item.secondaryName, 'secondaryValue', item);
    setItem(item.catName, 'catValue', item);
    setItem(item.zipCodeName, 'zipCodeValue', item);
    setItem(item.cityName, 'cityValue', item);
    setItem(item.stateName, 'stateValue', item);
    setItem(item.countryName, 'countryValue', item);
  });

  return list;
};

const getAllNormalizedLists = (action) => {
  const emailListNormalized = listWithNewValues({
    list: action.emailList,
    formValues: action.formValues,
  });

  const phoneListNormalized = listWithNewValues({
    list: action.phoneList,
    formValues: action.formValues,
  });

  const addressListNormalized = listWithNewValues({
    list: action.addressList,
    formValues: action.formValues,
  });

  return {
    emailListNormalized,
    phoneListNormalized,
    addressListNormalized,
  };
};

const getEmptyAddressList = (list) =>
  list.reduce((cleanedList, item) => {
    let cleanItem = {};
    for (let key in item) {
      cleanItem[key] =
        (key.includes('Value') || key.includes('value')) && key !== 'catValue'
          ? ''
          : item[key];
      cleanItem['catValue'] = ADDRESS_DEFAULT_CATEGORY;
    }
    cleanedList.push(cleanItem);

    return cleanedList;
  }, []);

export const contactsReducer = (state, action) => {
  switch (action.type) {
    case 'addEmail': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        emailList: [...emailListNormalized, action.newField],
        phoneList: phoneListNormalized,
        addressList: addressListNormalized,
      };
    }
    case 'deleteEmail': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        emailList: [
          ...emailListNormalized.filter(({ name }) => action.name !== name),
        ],
        phoneList: phoneListNormalized,
        addressList: addressListNormalized,
      };
    }
    case 'addPhone': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        emailList: emailListNormalized,
        phoneList: [...phoneListNormalized, action.newField],
        addressList: addressListNormalized,
      };
    }
    case 'deletePhone': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        emailList: emailListNormalized,
        phoneList: [
          ...phoneListNormalized.filter(({ name }) => action.name !== name),
        ],
        addressList: addressListNormalized,
      };
    }
    case 'resetPhone':
      return {
        ...state,
        phoneList: [action.newField],
      };
    case 'addAddress': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        phoneList: phoneListNormalized,
        emailList: emailListNormalized,
        addressList: [...addressListNormalized, action.newField],
      };
    }
    case 'resetAddress': {
      return {
        ...state,
        addressList: getEmptyAddressList(action.addressList),
      };
    }
    case 'deleteAddress': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        phoneList: phoneListNormalized,
        emailList: emailListNormalized,
        addressList: [
          ...addressListNormalized.filter(({ name }) => action.name !== name),
        ],
      };
    }
    case 'updateState': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      return {
        ...state,
        phoneList: phoneListNormalized,
        emailList: emailListNormalized,
        addressList: addressListNormalized,
      };
    }
    case 'clearEmptyValues': {
      const {
        emailListNormalized,
        phoneListNormalized,
        addressListNormalized,
      } = getAllNormalizedLists(action);

      const emailList = emailListNormalized.reduce((list, field, index) => {
        if (
          !!field.value ||
          (index + 1 >= emailListNormalized.length && list.length === 0)
        ) {
          list.push(field);
        }

        return list;
      }, []);

      const phoneList = phoneListNormalized.reduce((list, field, index) => {
        if (
          !!field.value ||
          (index + 1 >= phoneListNormalized.length && list.length === 0)
        ) {
          list.push(field);
        }

        return list;
      }, []);

      const addressList = addressListNormalized.reduce((list, field, index) => {
        if (!!(field.value || field.cityValue || field.countryValue)) {
          list.push(field);
        } else if (
          index + 1 >= addressListNormalized.length &&
          list.length === 0
        ) {
          field.secondaryValue = '';
          field.zipCodeValue = '';
          field.stateValue = '';
          action.setFieldValue(field.secondaryName, '');
          action.setFieldValue(field.zipCodeName, '');
          action.setFieldValue(field.stateName, '');

          list.push(field);
        }

        return list;
      }, []);

      return {
        ...state,
        emailList: [...emailList],
        phoneList: [...phoneList],
        addressList: [...addressList],
      };
    }
    default:
      throw new Error('Unexpected action');
  }
};
