import React from 'react';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import moment from 'moment';

import Container from 'common/components/Container';
import HighlightedText from 'common/components/HighlightedText';
import RadioButtonsContainer from 'common/components/FormComponents/RadioButtonsContainer';
import Radio from 'common/components/FormComponents/Radio';
import SelectDate from 'common/components/FormComponents/SelectDate';
import Select from 'common/components/FormComponents/Select';
import Checkbox from 'common/components/FormComponents/Checkbox';
import InformationPanel from 'common/components/InformationPanel';
import Button from 'common/components/Button';
import SpinLoader from 'common/components/SpinLoader';

import PageLoader from '~/components/PageLoader';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import usePrioritisedCountries from '~/hooks/usePrioritisedCountries';
import useCountryNameKey from '~/hooks/useCountryNameKey';
import ExclusiveAdvantages from '~/components/ExclusiveAdvantages';
import SelectProfession from '~/components/FormComponents/SelectProfession';
import ButtonBack from '~/components/ButtonBack';
import GTM from '~/components/GTM';

import JoinLoyaltyProgramFormHandler from './containers/JoinLoyaltyProgramFormHandler';
import styles from './JoinLoyaltyProgram.module.scss';

const cx = classNames.bind(styles);

const JoinLoyaltyProgramDataQuery = gql`
  query JoinLoyaltyProgramData {
    getSbmLoyalty {
      blocks {
        joining {
          enrollment
          linking
        }
        infoAdvantages
      }
    }
  }
`;

function JoinLoyaltyProgram() {
  const countryNameKey = useCountryNameKey();
  const prioritisedCountries = usePrioritisedCountries();

  const { language } = useLocalisationContext();
  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  const { loading: loadingLoyaltyData, data } = useQuery(
    JoinLoyaltyProgramDataQuery,
    {
      fetchPolicy: 'cache-and-network',
    }
  );

  const { gender, birthDate, countryOfResidence, occupation } = userSbmIdentity;

  const { t } = useTranslation([
    'join-loyalty-program',
    'identity-form',
    'common',
  ]);
  const pageTitle = t('pageTitle');
  const pageTitleColorVariable = t('pageTitleColorVariable');
  const descriptionIfThereAreFieldsToFill = t(
    'descriptionIfThereAreFieldsToFill'
  );
  const descriptionIfAllFieldsAreAlreadyFilled = t(
    'descriptionIfAllFieldsAreAlreadyFilled'
  );
  const genderLabel = t('identity-form:genderLabel');
  const birthDateFieldLabel = t('identity-form:birthDateFieldLabel');
  const fieldDateYearLabel = t('common:fieldDateYearLabel');
  const fieldDateMonthLabel = t('common:fieldDateMonthLabel');
  const fieldDateDayLabel = t('common:fieldDateDayLabel');
  const professionLabel = t('common:professionLabel');
  const countryOfResidenceFieldLabel = t(
    'identity-form:countryOfResidenceFieldLabel'
  );
  const termsFieldLabel = t('termsFieldLabel');
  const validationRequiredFieldMessage = t('validationRequiredFieldMessage');
  const messageError = t('messageError');
  const enrollButtonLabel = t('enrollButtonLabel');

  return (
    <Container tag="main" className={cx('root')} width={900}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {(loadingUserData || loadingLoyaltyData || !data) && <PageLoader />}
      {!(loadingUserData || loadingLoyaltyData) &&
        !!data.getSbmLoyalty?.blocks?.joining && (
          <>
            <JoinLoyaltyProgramFormHandler
              data={userSbmIdentity}
              isCivility={!gender}
              isBirthDate={!birthDate}
              isCountryOfResidence={!countryOfResidence}
              isOccupation={!occupation}
            >
              {({
                genderList,
                validationSchema,
                initialValues,
                handleSubmit,
                submitError,
              }) => (
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form>
                      <div className={cx('form-wrap')}>
                        <ButtonBack
                          to={`/${language}`}
                          className={cx('back-button')}
                        />
                        <h3 className={cx('title')}>
                          <HighlightedText
                            index={Number(pageTitleColorVariable)}
                          >
                            {pageTitle}
                          </HighlightedText>
                        </h3>
                        <div
                          className={cx('description', {
                            'description-margin': !gender || !birthDate,
                          })}
                          dangerouslySetInnerHTML={{
                            __html:
                              !gender || !birthDate || !countryOfResidence
                                ? descriptionIfThereAreFieldsToFill
                                : descriptionIfAllFieldsAreAlreadyFilled,
                          }}
                        />
                        {!gender && (
                          <RadioButtonsContainer
                            className={cx('radios-wrapper')}
                            label={genderLabel}
                            required
                            isError={touched['gender'] && !!errors['gender']}
                            errorMsg={validationRequiredFieldMessage}
                          >
                            {genderList.map(({ value, label }) => (
                              <Field
                                key={value}
                                name="gender"
                                component={Radio}
                                id={value}
                                label={label}
                              />
                            ))}
                          </RadioButtonsContainer>
                        )}
                        {!birthDate && (
                          <Field
                            name="birthDate"
                            component={SelectDate}
                            required
                            itemClassName={cx('select-item')}
                            dateFormat={'YYYY-MM-DD'}
                            placeholder={birthDateFieldLabel}
                            placeholderYear={fieldDateYearLabel}
                            placeholderMonth={fieldDateMonthLabel}
                            placeholderDay={fieldDateDayLabel}
                            maxLength={254}
                            autoComplete="birthDate"
                            lastAvailableDate={moment()
                              .subtract(18, 'years')
                              .format('YYYY-MM-DD')}
                          />
                        )}
                        {!countryOfResidence && (
                          <Field
                            name="countryOfResidence"
                            component={Select}
                            options={prioritisedCountries}
                            placeholder={countryOfResidenceFieldLabel}
                            customKeys={['id', countryNameKey]}
                            required
                          />
                        )}
                        {!occupation && (
                          <Field
                            name="occupation"
                            component={SelectProfession}
                            placeholder={professionLabel}
                            required
                          />
                        )}
                      </div>
                      <Field
                        name="terms_conditions"
                        className={cx('form-checkbox')}
                        textClassName={cx('form-checkbox-text')}
                        component={Checkbox}
                        label={termsFieldLabel}
                        required
                      />
                      {submitError && (
                        <InformationPanel withIcon errorMode>
                          {messageError}
                        </InformationPanel>
                      )}
                      <Button
                        type="submit"
                        className={cx('button-save', {
                          loading: isSubmitting,
                        })}
                        disabled={
                          Object.entries(values).some((item) =>
                            item.includes('')
                          ) ||
                          values['terms_conditions'] === false ||
                          Object.entries(errors).length > 0 ||
                          isSubmitting
                        }
                      >
                        {isSubmitting && (
                          <SpinLoader className={cx('loader')} size={16} />
                        )}
                        <span className={cx('button-label')}>
                          {enrollButtonLabel}
                        </span>
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </JoinLoyaltyProgramFormHandler>
            {data.getSbmLoyalty.blocks.infoAdvantages && (
              <ExclusiveAdvantages
                className={cx('exclusive-advantages-block')}
              />
            )}
          </>
        )}
      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </Container>
  );
}

export { JoinLoyaltyProgramDataQuery };
export default withErrorBoundary(JoinLoyaltyProgram);
