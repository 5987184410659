import { useMemo } from 'react';

import { COUNTRIES } from 'common/constants';

import useCountryNameKey from '~/hooks/useCountryNameKey';

export default function useUserNationalities(nationalities) {
  const countryNameKey = useCountryNameKey();

  const userNationalities = useMemo(
    () =>
      nationalities.map(
        (idValue) =>
          COUNTRIES.find(({ id }) => id === idValue)?.[countryNameKey] || ''
      ),
    [nationalities, countryNameKey]
  );

  return userNationalities;
}
