import React from 'react';
import classNames from 'classnames/bind';
import { string, arrayOf, shape } from 'prop-types';

import gtmPushEvent from 'common/utils/gtmPushEvent';
import { withErrorBoundary } from 'common/containers/ErrorBoundary';

import styles from './Telephone.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  phones: arrayOf(
    shape({
      label: string.isRequired,
      phone: string.isRequired,
    })
  ).isRequired,
};

const Telephone = ({ phones }) => {
  const handlePhoneClick = () => {
    gtmPushEvent({
      event: 'click_phone',
    });
  };

  return (
    <address className={cx('root')}>
      {phones.map(({ label, phone }) => (
        <p className={cx('telephone-item')} key={label}>
          <span className={cx('telephone-label')}>{label}</span>
          <a rel="nofollow" href={`tel:${phone}`} onClick={handlePhoneClick}>
            {phone}
          </a>
        </p>
      ))}
    </address>
  );
};

Telephone.propTypes = propTypes;

export default withErrorBoundary(Telephone);
