function getStayData(dates, fromDate, toDate) {
  const fDate = fromDate.format('YYYY-MM-DD');
  const tDate = toDate.format('YYYY-MM-DD');

  const firstDay = dates.findIndex(({ date }) => date === fDate);
  const lastDay = dates.findIndex(({ date }) => date === tDate);
  const selectedDates = dates.slice(firstDay, lastDay);
  const staySum = selectedDates.reduce((acc, { available, price }) => {
    if (available) {
      return (acc = acc + Number(price));
    }
    return acc;
  }, 0);
  const staySumRounded = Math.ceil(staySum * 100) / 100;
  const stayNights = selectedDates.length;

  return { staySum: staySumRounded, stayNights };
}

export default getStayData;
