// Messages for postMessage method between SBM and MyAcc
export const BRIDGE_MSG = {
  objPrefix: 'sbmMsgObj_',
  gtmObjPrefix: 'sbmGtmMsgObj_',
  signout: 'sbmMsg_signout',
  signedin: 'sbmMsg_signedin',
};

// Localstorage keys for storing acc info between SBM and MyAcc
export const BRIDGE_LSKEY = {
  authorized: 'sbmID_authorized',
  loyaltyStatus: 'sbmID_loyaltyStatus',
  userData: 'sbmID_userData',
  signoutMyAccTrigger: 'sbmID_signoutMyAccTrigger',
  signoutSBMTrigger: 'sbmID_signoutSBMTrigger',
};
