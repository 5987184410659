import React from 'react';
import { string, bool, func, node } from 'prop-types';
import classNames from 'classnames/bind';

import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { useUiContext } from 'common/context/UiContext';

import styles from './TopFiltersItem.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  name: string,
  tagName: string,
  top: string,
  middle: node,
  bottom: string,
  // not related to actual middle field value,
  // but affects styling
  middleIsNum: bool,
  labelOnlyOnDesktop: bool,
  onClick: func,
  withArrow: bool,
  isActive: bool,
  isSelected: bool,
  // The logic behind this prop is related to design complexity:
  // on desktop version we need keep layout - same size for all items,
  // and on mobile version it's true only for specific items
  sameSize: bool,
};

const defaultProps = {
  className: null,
  top: null,
  middle: null,
  bottom: null,
  tagName: 'button',
  middleIsNum: false,
  labelOnlyOnDesktop: false,
  withArrow: false,
  isActive: false,
  isSelected: true,
  name: null,
  sameSize: true,
  onClick: null,
};

const TopFiltersItem = ({
  className,
  tagName: Tag,
  name,
  top,
  middle,
  bottom,
  middleIsNum,
  labelOnlyOnDesktop,
  withArrow,
  isActive,
  isSelected,
  sameSize,
  ...props
}) => {
  const { currentBreakpoint } = useUiContext();
  let attributes;

  if (Tag === 'button') {
    attributes = {
      type: 'button',
    };
  }

  const arrowClasses = {
    arrow: withArrow,
    'arrow-down': withArrow && !isActive,
    'arrow-up': withArrow && isActive,
  };
  const itemClassName = cx(
    className,
    'item',
    name ? `item-${name}` : null,
    { 'item-active': isActive },
    currentBreakpoint !== 'desktop' && arrowClasses
  );
  const middleClassName = cx(
    'middle',
    currentBreakpoint === 'desktop' && arrowClasses,
    { 'is-selected': isSelected }
  );
  const isIcon = React.isValidElement(middle);
  const valueClassName = cx({
    'item-value-text': !middleIsNum && !isIcon,
    'item-value-num': middleIsNum && !isIcon,
    'item-value-icon': isIcon,
  });

  return (
    <Tag {...props} {...attributes} className={itemClassName}>
      {currentBreakpoint === 'desktop' || !labelOnlyOnDesktop ? (
        <div className={cx('top')}>
          <span className={cx('item-label')}>{top}</span>
        </div>
      ) : null}
      <div className={middleClassName}>
        <span className={valueClassName}>{middle}</span>
      </div>
      {sameSize || bottom ? (
        <div className={cx('bottom')}>
          <span className={cx('item-label')}>{bottom}</span>
        </div>
      ) : null}
    </Tag>
  );
};

TopFiltersItem.propTypes = propTypes;
TopFiltersItem.defaultProps = defaultProps;

export default withErrorBoundary(TopFiltersItem);
