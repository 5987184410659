import React, { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment';

export const ADULTS_INITIAL = 2;
export const ADULTS_MAX = 7;
export const ADULTS_MIN = 1;
export const CHILDREN_INITIAL = 0;
export const CHILDREN_MAX = 3;
export const CHILDREN_MIN = 0;

const PersonsContext = createContext();
const usePersonsContext = () => useContext(PersonsContext);
const PersonsContextConsumer = PersonsContext.Consumer;

const PersonsContextProvider = ({ children }) => {
  const [adultsAmount, setAdultsAmount] = useState(ADULTS_INITIAL);
  const [childrenAmount, setChildrenAmount] = useState(CHILDREN_INITIAL);

  useEffect(() => {
    if (typeof localStorage !== 'undefined') {
      const storageInitialAdultsCount =
        localStorage.getItem('initialAdultsCount');
      const storageInitialChildrenCount = localStorage.getItem(
        'initialChildrenCount'
      );
      const storageLastTimeFilterUpdated = localStorage.getItem(
        'lastTimeFilterUpdated'
      );

      if (
        (storageInitialAdultsCount || storageInitialChildrenCount) &&
        storageLastTimeFilterUpdated &&
        moment().diff(moment(Number(storageLastTimeFilterUpdated)), 'days') < 30
      ) {
        if (storageInitialAdultsCount) {
          setAdultsAmount(Number(storageInitialAdultsCount));
        }
        if (storageInitialChildrenCount) {
          setChildrenAmount(Number(storageInitialChildrenCount));
        }
      }
    }
  }, []);

  const personsAmount = adultsAmount + childrenAmount;
  const isPossibleToAddAdult = adultsAmount < ADULTS_MAX;
  const isPossibleToAddChild = childrenAmount < CHILDREN_MAX;

  const updateAdults = (amount = ADULTS_INITIAL) => {
    setAdultsAmount(amount);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialAdultsCount', amount);
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const updateChildren = (amount = CHILDREN_INITIAL) => {
    setChildrenAmount(amount);
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('initialChildrenCount', amount);
      localStorage.setItem('lastTimeFilterUpdated', moment().valueOf());
    }
  };

  const addAdults = () => {
    if (adultsAmount < ADULTS_MAX) {
      updateAdults(adultsAmount + 1);
    }
  };

  const removeAdults = () => {
    if (adultsAmount > ADULTS_MIN) {
      updateAdults(adultsAmount - 1);
    }
  };

  const addChildren = () => {
    if (childrenAmount < CHILDREN_MAX) {
      updateChildren(childrenAmount + 1);
    }
  };

  const removeChildren = () => {
    if (childrenAmount > CHILDREN_MIN) {
      updateChildren(childrenAmount - 1);
    }
  };

  const resetStore = () => {
    updateAdults();
    updateChildren();
  };

  return (
    <PersonsContext.Provider
      value={{
        adultsAmount,
        childrenAmount,
        personsAmount,
        isPossibleToAddAdult,
        isPossibleToAddChild,
        updateAdults,
        updateChildren,
        addAdults,
        removeAdults,
        addChildren,
        removeChildren,
        resetStore,
      }}
    >
      {children}
    </PersonsContext.Provider>
  );
};

export { PersonsContextConsumer, PersonsContextProvider, usePersonsContext };

export default PersonsContext;
