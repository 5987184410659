import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';

import FullscreenContainer from '~/components/FullscreenContainer';
import Routes from '~/components/Routes';
import Header from '~/scenes/Header';
import MainGrid from '~/scenes/MyAccLayout/components/MainGrid';
import { MYID_ROUTES } from '~/constants';
import { useMyaccHistory } from '~/hooks';
import { UserContextProvider } from '~/context/UserContext';
import { parse } from 'query-string';
import { sbmClient } from '~/apolloClient';

const MyIDOfferDataQuery = gql`
  query MyIDOfferData($id: ID!) {
    offer(id: $id) {
      title
      subtitle
      loginBackgroundTitle
      loginBackgroundSubtitle
      loginBackgroundImage {
        ...offerLoginBackgroundFragment
      }
      media(first: 1, after: 0) {
        ...offerLoginBackgroundFragment
      }
    }
  }

  fragment offerLoginBackgroundFragment on MediaImage {
    id
    image {
      id
      desktop: url(style: my_account_background_desktop_webp) {
        url
      }
      desktop2x: url(style: my_account_background_desktop_2x_webp) {
        url
      }
      tablet: url(style: my_account_background_tablet_webp) {
        url
      }
      tablet2x: url(style: my_account_background_tablet_2x_webp) {
        url
      }
      alt
    }
  }
`;

const MyIDLayout = () => {
  const {
    location: { pathname, search },
  } = useMyaccHistory();
  const currentRoute = pathname.split('/')[2];
  const { offerID } = parse(search);

  const [getOfferData, { data: myIDOfferData, loading: myIDOfferDataLoading }] =
    useLazyQuery(MyIDOfferDataQuery, {
      variables: {
        id: offerID,
      },
      client: sbmClient,
    });

  useEffect(() => {
    if (offerID) {
      getOfferData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerID]);

  const myIDOffer = myIDOfferData?.offer;

  const offerBackground =
    myIDOffer?.loginBackgroundImage?.image || myIDOffer?.media?.[0]?.image;

  const backgroundTitle = myIDOffer?.loginBackgroundTitle || myIDOffer?.title;

  const backgroundSubtitle =
    myIDOffer?.loginBackgroundSubtitle || myIDOffer?.subtitle;

  return (
    <>
      {currentRoute === 'wishlist' ? (
        <UserContextProvider>
          <Header />
          <MainGrid>
            <MainGrid.Top>
              <Routes routesList={MYID_ROUTES} />
            </MainGrid.Top>
          </MainGrid>
        </UserContextProvider>
      ) : (
        <FullscreenContainer
          tag={'main'}
          withDefaultSlider={
            !offerID || (!myIDOfferDataLoading && !offerBackground)
          }
          background={offerBackground}
          backgroundTitle={backgroundTitle}
          backgroundSubtitle={backgroundSubtitle}
          withTablet={false}
        >
          <Header />
          <Routes routesList={MYID_ROUTES} />
        </FullscreenContainer>
      )}
    </>
  );
};

export default MyIDLayout;
