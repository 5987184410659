import React from 'react';
import classNames from 'classnames/bind';
import { func, string } from 'prop-types';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconBin } from 'common/media/icons/bin.icon.svg';
import { ReactComponent as IconPlus } from 'common/media/icons/plus.icon.svg';

import styles from './MultiFieldCta.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  onRemove: func,
  onIncrease: func,
  className: string,
};

const defaultProps = {
  onRemove: null,
  onIncrease: null,
  className: null,
};

const MultiFieldCta = ({ onRemove, onIncrease, className }) => (
  <div className={cx('root', className)}>
    {onRemove && (
      <button type="button" onClick={onRemove} className={cx('button')}>
        <IconContainer icon={IconBin} className={cx('icon')} />
      </button>
    )}
    {onIncrease && (
      <button type="button" onClick={onIncrease} className={cx('button')}>
        <IconContainer icon={IconPlus} className={cx('icon')} />
      </button>
    )}
  </div>
);

MultiFieldCta.propTypes = propTypes;
MultiFieldCta.defaultProps = defaultProps;

export default MultiFieldCta;
