import { gql } from '@apollo/client';

import ApiLog from '~/pages/mycs/EditAccount/components/ApiLog';
import AsyncMachine from '~/pages/mycs/EditAccount/components/AsyncMachine';
import LoyaltyMachine from '~/pages/mycs/EditAccount/components/LoyaltyMachine';
import LoyaltyProgram from '~/pages/myac/LoyaltyProgram/LoyaltyProgram';

const EditAccountDataQuery = gql`
  query EditAccountData($id: String!) {
    getCustomerProfile(id: $id) {
      info {
        id
        created
        status
        creationSource
        firstName
        lastName
        birthDate
        email
        phoneNumber
      }
      sbmLoyalty(id: $id) {
        status
        ...loyaltyProgramDataFragment
      }
      sbmIdentity(id: $id) {
        sbmApiFirstName: firstName
        birthName
        marriedName
        sbmApiBirthDate: birthDate
        nationalities
      }
      sbmContacts(id: $id) {
        sbmApiEmail: email {
          email
          isMutable
        }
        phone {
          phone
          isMutable
        }
        address {
          address1
          address2
          city
          zipCode
          state
          country
          isMutable
        }
      }
      sbmPreferences(id: $id) {
        language
        contactChannels {
          value
          method
        }
      }
      sbmGdpr(id: $id) {
        date
        sbmApiGdprId: id
        value
        source
      }
      foreignIds {
        sourceName
        sourceKey
      }
      authHistory {
        creationDate
        eventType
        eventResponse
        challengeResponses {
          challengeName
          challengeResponse
        }
        eventContextData {
          ipAddress
          deviceName
          city
        }
        eventFeedback {
          feedbackValue
        }
        eventRisk {
          riskLevel
          riskDecision
        }
      }
    }
    getMyIdStatus(id: $id) {
      status
    }
    getApiStatus(userId: $id) {
      ...apiLogFields
    }
    getAsyncStatus(id: $id) {
      ...asyncMachineFields
    }
    getLoyaltyStatus(id: $id) {
      status
      ...loyaltyMachineFields
    }
  }
  ${ApiLog.fragment.apiLogDataFragment}
  ${AsyncMachine.fragment.asyncMachineDataFragment}
  ${LoyaltyMachine.fragment.loyaltyMachineDataFragment}
  ${LoyaltyProgram.fragment.loyaltyProgramDataFragment}
`;

export default EditAccountDataQuery;
