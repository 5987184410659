import React, { useMemo } from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames/bind';
import { propType } from 'graphql-anywhere';
import { gql } from '@apollo/client';

import Picture from 'common/components/Picture';
import getPictureSources from 'common/utils/getPictureSources';

import { withErrorBoundary } from '~/containers/ErrorBoundary';

import Card from '../Card';
import styles from './GiftCard.module.scss';

const cx = classNames.bind(styles);

const imageDesktopFragment = gql`
  fragment imageDesktopFragment on MediaImage {
    id
    title
    image {
      id
      desktop: url(style: list_item_long_style_desktop_webp) {
        ...urlFields
      }
      desktop2x: url(style: list_item_long_style_desktop_2x_webp) {
        ...urlFields
      }
      tablet: url(style: list_item_common_tablet_webp) {
        ...urlFields
      }
      tablet2x: url(style: list_item_common_tablet_2x_webp) {
        ...urlFields
      }
    }
  }
  fragment urlFields on ImageStyleUrl {
    width
    height
    url
  }
`;

const imageMobileFragment = gql`
  fragment imageMobileFragment on MediaImage {
    id
    title
    image {
      id
      mobile: url(style: emag_slider_square_image_mobile_webp) {
        url
      }
      mobile2x: url(style: emag_slider_square_image_mobile_2x_webp) {
        url
      }
      alt
    }
  }
`;

const giftCardFragment = gql`
  fragment giftCardFragment on MyAccountHomepage {
    giftCardButton {
      text
      url
    }
    giftCardTitle
    giftCardSubtitle
    giftCardImage {
      ...imageDesktopFragment
    }
    giftCardMobileImage {
      ...imageMobileFragment
    }
  }
  ${imageDesktopFragment}
  ${imageMobileFragment}
`;

const propTypes = {
  data: propType(giftCardFragment).isRequired,
  isStretch: bool,
  className: string,
};

const defaultProps = {
  isStretch: false,
  className: '',
};

const GiftCard = ({
  data: {
    giftCardButton,
    giftCardImage,
    giftCardMobileImage,
    giftCardSubtitle,
    giftCardTitle,
  },
  isStretch,
}) => {
  const { url, text } = giftCardButton || {};
  const BG_COLOR = '#eae7de';

  const cardData = {
    bgColor: BG_COLOR,
    ctaLink: url,
    ctaLabel: text,
    globalLink: true,
  };

  const CardImage = useMemo(
    () =>
      ({ image, className }) => {
        const sources = getPictureSources(Object.assign({}, image));

        return (
          <Picture
            sources={sources}
            className={className}
            src={image.desktop ? image.desktop.url : image.mobile.url}
            alt={giftCardImage.alt}
          />
        );
      },
    [giftCardImage]
  );

  return (
    <Card data={cardData} isStretch={isStretch}>
      <div className={cx('card-body')}>
        <h3 className={cx('card-sub-title')}>{giftCardSubtitle}</h3>
        <h2 className={cx('card-title')}>{giftCardTitle}</h2>
      </div>
      <div className={cx('card-image')}>
        {giftCardImage && (
          <CardImage
            key="desk"
            className={cx('image-desktop')}
            image={giftCardImage.image}
          />
        )}
        {giftCardMobileImage && (
          <CardImage
            key="mob"
            className={cx('image-mobile')}
            image={giftCardMobileImage.image}
          />
        )}
      </div>
    </Card>
  );
};

GiftCard.propTypes = propTypes;
GiftCard.defaultProps = defaultProps;
GiftCard.fragments = {
  giftCardFragment,
};

export default withErrorBoundary(GiftCard);
