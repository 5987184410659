import { gql } from '@apollo/client';

export const SbmOneclickQuery = gql`
  query SbmOneclickData($hash: String!) {
    getSbmOneclickData(hash: $hash) {
      email
      gender
      name
      familyName
      marriedName
      birthDate
      nationality
      phoneNumber
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const UpdateOneclickQuery = gql`
  mutation UpdateOneclickData($value: SbmOneclickDataInput!) {
    updateSbmOneclickDataInput(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const UpdateEmailQuery = gql`
  mutation UpdateEmailData($value: SbmEmailDataInput!) {
    updateSbmEmailDataInput(value: $value) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const SbmEmailHashDataQuery = gql`
  query SbmEmailHashData($hash: String!) {
    getSbmEmailHashData(hash: $hash) {
      email
      password
      offerID
      offerLang
      offerLoyaltyStatus
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const CaptchaResultQuery = gql`
  query getCaptchaResult($response: String!) {
    getCaptchaResult(response: $response)
  }
`;

export const NonConfirmedUserLoginQuery = gql`
  query informSbmNonConfirmedUserLogin($email: String!) {
    informSbmNonConfirmedUserLogin(email: $email) {
      result
    }
  }
`;
