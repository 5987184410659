import React from 'react';
import { func, bool, object } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import moment from 'moment';

import Input from 'common/components/FormComponents/Input';
import SelectDate from 'common/components/FormComponents/SelectDate';
import InformationPanel from 'common/components/InformationPanel';
import { useEffectOnMount } from 'common/hooks';
import gtmPushEvent from 'common/utils/gtmPushEvent';
import AuthFormTitle from 'common/components/AuthFormTitle';

import FormButton from '~/components/MyidComponents/FormButton';
import BackButton from '~/components/MyidComponents/BackButton';
import ServiceLinks from '~/components/MyidComponents/ServiceLinks';
import InfoMessages from '~/components/InfoMessages';

import FormCheckEmailHandler from './containers/FormCheckEmailHandler';
import styles from './FormCheckEmail.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  submit: func.isRequired,
  submitSuccess: bool.isRequired,
  submitError: bool.isRequired,
  apiErrors: object,
  emailExists: bool,
};

const defaultProps = {
  emailExists: false,
  apiErrors: null,
};

const gtmSignupStep1Params = {
  event: 'start_account_registration_flow',
};

function FormCheckEmail({
  submit,
  emailExists,
  submitSuccess,
  submitError,
  apiErrors,
  resetMessage,
}) {
  useEffectOnMount(() => {
    gtmPushEvent(gtmSignupStep1Params);
  });
  const { t } = useTranslation(['auth', 'common']);
  const titleHighlight = t('create.titleHighlight');
  const formIntro = t('create.formIntro');
  const serviceLinks = t('create.serviceLinks');
  const title = t('create.title');
  const loginLabel = t('create.fieldLoginLabel');
  const fieldNameLabel = t('create.fieldNameLabel');
  const fieldFamilyNameLabel = t('create.fieldFamilyNameLabel');
  const fieldBirthDateLabel = t('create.fieldBirthDateLabel');
  const fieldDateYearLabel = t('common:fieldDateYearLabel');
  const fieldDateMonthLabel = t('common:fieldDateMonthLabel');
  const fieldDateDayLabel = t('common:fieldDateDayLabel');
  const accountAlreadyExistsMessage = t('create.accountAlreadyExistsMessage');
  const messageSuccess = t('create.messageSuccess');
  const messageError = t('auth:messageError');
  const formButtonLabel = t('create.formButtonLabel');
  const infoLabels = t('create.messages', {
    returnObjects: true,
  });

  return (
    <FormCheckEmailHandler>
      {({ validationSchema, initialValues, captchaStatus, CaptchaWidget }) => (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, formik) => {
            submit(values, formik, captchaStatus);
          }}
        >
          {({ values, errors, isSubmitting }) => {
            if (submitSuccess || submitError) {
              isSubmitting = false;
            }

            return (
              <Form
                className={cx('form')}
                autoComplete="off"
                onChange={resetMessage}
                noValidate
              >
                <AuthFormTitle
                  title={title}
                  index={titleHighlight}
                  subtitle={formIntro}
                />
                <div className={cx('form-content')}>
                  <Field
                    name="login"
                    component={Input}
                    theme="block-theme"
                    placeholder={loginLabel}
                    required
                    maxLength={128}
                    className={cx('input')}
                    autoComplete="username"
                  />
                  <Field
                    name="name"
                    component={Input}
                    theme="block-theme"
                    placeholder={fieldNameLabel}
                    maxLength={30}
                    required
                  />
                  <Field
                    name="familyName"
                    component={Input}
                    theme="block-theme"
                    placeholder={fieldFamilyNameLabel}
                    maxLength={30}
                    required
                  />
                  <Field
                    name="birthDate"
                    component={SelectDate}
                    theme="block-theme"
                    itemClassName={cx('select-item')}
                    dateFormat={'YYYY-MM-DD'}
                    placeholder={fieldBirthDateLabel}
                    placeholderYear={fieldDateYearLabel}
                    placeholderMonth={fieldDateMonthLabel}
                    placeholderDay={fieldDateDayLabel}
                    maxLength={254}
                    autoComplete="birthDate"
                    lastAvailableDate={moment()
                      .subtract(18, 'years')
                      .format('YYYY-MM-DD')}
                    isCleanable={false}
                    required
                  />
                  {submitSuccess && (
                    <InformationPanel withIcon successMode>
                      {emailExists
                        ? accountAlreadyExistsMessage
                        : messageSuccess}
                    </InformationPanel>
                  )}
                  {submitError && (
                    <InformationPanel withIcon errorMode>
                      {messageError}
                    </InformationPanel>
                  )}
                  {!!apiErrors && (
                    <InformationPanel isComponentChildren withIcon errorMode>
                      <InfoMessages
                        isTextMode
                        messages={apiErrors}
                        labels={infoLabels}
                      />
                    </InformationPanel>
                  )}
                  <FormButton
                    isSubmitting={isSubmitting || captchaStatus.loading}
                    disabled={
                      Object.entries(values).some((item) =>
                        item.includes('')
                      ) ||
                      Object.entries(errors).length > 0 ||
                      (apiErrors && Object.entries(apiErrors)?.length > 0) ||
                      isSubmitting ||
                      submitSuccess ||
                      !captchaStatus.solution ||
                      captchaStatus.loading
                    }
                    buttonLabel={formButtonLabel}
                  />
                  <CaptchaWidget className={cx('captcha')} />
                  <BackButton />
                  <ServiceLinks data={serviceLinks} />
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </FormCheckEmailHandler>
  );
}

FormCheckEmail.propTypes = propTypes;
FormCheckEmail.defaultProps = defaultProps;

export default FormCheckEmail;
