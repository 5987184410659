import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import MobileDetect from 'mobile-detect';

import scrollLock from 'common/utils/scrollLock';
import useIsomorphicLayoutEffect from 'common/hooks/useIsomorphicLayoutEffect';
import useLazyRef from 'common/hooks/useLazyRef';
import checkIsScrollExist, {
  setScrollBarPaddingAction,
  resetScrollBarPaddingAction,
} from 'common/utils/scrollBarActions';

const UiContext = createContext();
const useUiContext = () => useContext(UiContext);
const UiContextConsumer = UiContext.Consumer;
const UiContextProvider = ({ children, xdevice }) => {
  const userAgent =
    typeof window === 'undefined'
      ? 'Mozilla/5.0 (Windows NT 10.0; Trident/7.0; rv:11.0) like Gecko'
      : navigator.userAgent;
  const scroll = useScroll();
  const device = useDevice(userAgent, xdevice);

  return (
    <UiContext.Provider
      value={{
        ...device,
        ...scroll,
      }}
    >
      {children}
    </UiContext.Provider>
  );
};

function useDevice(userAgent, xdevice) {
  const md = useLazyRef(() => new MobileDetect(userAgent));
  const [currentBreakpoint, setCurrentBreakpoint] = useState(
    xdevice || 'desktop'
  );
  const isTouchDevice = useLazyRef(() =>
    typeof window !== 'undefined'
      ? /Windows Phone/.test(navigator.userAgent) ||
        'ontouchstart' in window ||
        !!(window.DocumentTouch && document instanceof window.DocumentTouch)
      : false
  ).current;

  const isIOS = useLazyRef(() => md.current.os() === 'iOS').current;

  const isDesktop = useMemo(
    () => currentBreakpoint === 'desktop',
    [currentBreakpoint]
  );
  const isMobile = useMemo(
    () => currentBreakpoint === 'mobile',
    [currentBreakpoint]
  );

  // Since 'updateCurrentBreakpoint' is implemented in _app's didMount phase,
  // we should start listening it in useLayoutEffect:
  useIsomorphicLayoutEffect(() => {
    setCurrentBreakpoint(() => {
      // md.mobile() returns truthy for tablets and desktops but
      // sometimes md.phone() and md.tablet() value could be null
      // while mobile true, so in this case we will return 'mobile' as
      // initial value:
      if (md.current.mobile()) {
        if (md.current.tablet()) {
          return 'tablet';
        } else {
          return 'mobile';
        }
      } else {
        return 'desktop';
      }
    });
    const breakpointChangeHandler = (event) => {
      setCurrentBreakpoint(event.detail.currentBreakpoint);
    };

    window.addEventListener('breakpoint-change', breakpointChangeHandler);

    return () => {
      window.removeEventListener('breakpoint-change', breakpointChangeHandler);
    };
  }, []);

  return {
    userAgent,
    isTouchDevice,
    isIOS,
    currentBreakpoint,
    isDesktop,
    isMobile,
  };
}

function useScroll() {
  const pageScrollTop = useRef(0);
  const savedPageScrollTop = useRef(0);
  const [isPageScrolled, setPageScrolledStatus] = useState(false);
  const [scrollLockStatus, setScrollLockStatus] = useState(false);
  const isScrollBarPaddingApplied = useRef(false);

  // Fix overflow.
  const enableIosOverflow = () => {
    document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    savedPageScrollTop.current = pageScrollTop.current; // save current scrollTop
  };

  const disableIosOverflow = () => {
    document.getElementsByTagName('html')[0].style.overflow = '';
    document.getElementsByTagName('body')[0].scrollTop =
      savedPageScrollTop.current;
  };

  useEffect(() => {
    let lastKnownScrollPosition = 0;
    let ticking = false;

    const handler = () => {
      lastKnownScrollPosition = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          setPageScrolledStatus(lastKnownScrollPosition !== 0);
          ticking = false;
        });

        ticking = true;
      }
    };

    document.addEventListener('scroll', handler);

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    if (scrollLockStatus && checkIsScrollExist()) {
      isScrollBarPaddingApplied.current = true;
      setScrollBarPaddingAction();
    }

    if (!scrollLockStatus && isScrollBarPaddingApplied.current) {
      isScrollBarPaddingApplied.current = false;
      resetScrollBarPaddingAction();
    }

    scrollLock(scrollLockStatus);
  }, [scrollLockStatus]);

  const lockScroll = () => {
    setScrollLockStatus(true);
  };

  const unlockScroll = () => {
    setScrollLockStatus(false);
  };

  const setHtmlBodyOverflow = (value = '') => {
    document.body.style.overflow = value;
  };

  return {
    isPageScrolled,
    lockScroll,
    unlockScroll,
    enableIosOverflow,
    disableIosOverflow,
    setHtmlBodyOverflow,
  };
}

export { UiContextConsumer, UiContextProvider, useUiContext };

export default UiContext;
