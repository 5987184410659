import { useRef } from 'react';

const UNSET = Symbol('unset');

export default function useLazyRef(fn) {
  const value = useRef(UNSET);

  if (typeof fn === 'function') {
    if (value.current === UNSET) {
      value.current = fn();
    }
  } else {
    console.warn(
      '`useLazyRef` should be used only with function argument, if you want set calculated value just use regular `useRef`'
    );
    value.current = fn;
  }

  return value;
}
