import React, { useState } from 'react';
import { object } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import dateFormat from '~/utils/dateFormat';
import { useLocalisationContext } from '~/context/LocalisationContext';

import SearchResultsLegend from './components/SearchResultsLegend';
import SearchResultsHeader from './components/SearchResultsHeader';
import StatusUser from './components/StatusUser';
import DeleteUser from './components/DeleteUser';
import styles from './SearchResults.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  data: object.isRequired,
};

const modifiers = [
  'id',
  'creationDate',
  'status',
  'firstName',
  'lastName',
  'email',
  'creationSource',
  'delete',
];

function SearchResults({ data }) {
  const { t } = useTranslation('maintenance');

  const { language } = useLocalisationContext();

  const [columnVisibility, setColumnVisibility] = useState(
    Object.fromEntries(modifiers.map((key) => [key, true]))
  );
  const [temporaryDeletedUsers, setTemporaryDeletedUsers] = useState([]);

  if (!data) {
    return <div>{t('noResultsMessage')}</div>;
  }

  const { count, items } = data;

  if (count > 0) {
    return (
      <>
        {count > 50 && (
          <div className={cx('message')}>{t('tooManyResultsMessage')}</div>
        )}

        <SearchResultsLegend />

        <table className={cx('root')}>
          <SearchResultsHeader
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            modifiers={modifiers}
          />
          <tbody>
            {items.map(
              ({
                id,
                errors,
                warnings,
                created,
                firstName,
                lastName,
                email,
                creationSource,
                status,
              }) => {
                return email.includes('sbm.mc') ||
                  temporaryDeletedUsers.find(
                    (deletedId) => deletedId === id
                  ) ? null : (
                  <tr className={cx('row')} key={id}>
                    <td className={cx('cell')}>
                      {columnVisibility.id ? (
                        <a
                          href={`/${language}/user/${id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={cx('id-link')}
                        >
                          {id}
                        </a>
                      ) : (
                        '...'
                      )}
                    </td>
                    <td className={cx('cell')}>
                      {columnVisibility.creationDate
                        ? dateFormat(created)
                        : '...'}
                    </td>
                    <td className={cx('cell', 'status')}>
                      {columnVisibility.status ? (
                        <StatusUser
                          status={status}
                          warnings={warnings}
                          errors={errors}
                        />
                      ) : (
                        '...'
                      )}
                    </td>
                    <td className={cx('cell')}>
                      {columnVisibility.firstName ? firstName : '...'}
                    </td>
                    <td className={cx('cell')}>
                      {columnVisibility.lastName ? lastName : '...'}
                    </td>
                    <td className={cx('cell')}>
                      {columnVisibility.email ? email : '...'}
                    </td>
                    <td className={cx('cell')}>
                      {columnVisibility.creationSource ? creationSource : '...'}
                    </td>
                    <td className={cx('cell')}>
                      {columnVisibility.delete ? (
                        <DeleteUser
                          user={{ id, firstName, lastName, email }}
                          setTemporaryDeletedUsers={setTemporaryDeletedUsers}
                        />
                      ) : (
                        '...'
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </>
    );
  }

  return <div>{t('noResultsForCriteriasMessage')}</div>;
}

SearchResults.propTypes = propTypes;

export default SearchResults;
