import { useState, useEffect, useCallback } from 'react';
import { node } from 'prop-types';

import { useEffectOnMount } from 'common/hooks';
import { MYACC_LANGUAGE, BRIDGE_MSG, BRIDGE_LSKEY } from 'common/constants';

import { useAuthContext } from '~/context/AuthContext';
import { useLocalisationContext } from '~/context/LocalisationContext';
import history from '~/history';

const { REACT_APP_SBM_DOMAIN } = process.env;

const propTypes = {
  children: node.isRequired,
};

const parent = window.parent;
const messageToParent = (message) => {
  parent.postMessage(message, REACT_APP_SBM_DOMAIN);
};

const PostMessageSBMListener = ({ children }) => {
  const [isIframe, setIsIframe] = useState(window.self !== window.top);
  const { signOut } = useAuthContext();

  const { changeLanguage } = useLocalisationContext();

  const [bridgeData, setBridgeData] = useState(null);
  const [bridgeUserSignedIn, setBridgeUserSignedIn] = useState(
    localStorage.getItem(BRIDGE_LSKEY.authorized)
  );
  const [gtmDataSubmitted, setGtmDataSubmitted] = useState(false);

  useEffect(() => {
    if (bridgeUserSignedIn) {
      setBridgeData({
        signedin: bridgeUserSignedIn,
      });
    }
  }, [bridgeUserSignedIn]);

  const submitUserData = useCallback(() => {
    messageToParent(`${BRIDGE_MSG.objPrefix}${JSON.stringify(bridgeData)}`);
  }, [bridgeData]);

  useEffect(() => {
    if (isIframe && bridgeData) {
      submitUserData();
    }
  }, [isIframe, bridgeData, submitUserData]);

  useEffectOnMount(() => {
    if (isIframe) {
      // Wakeup iframe
      messageToParent('Ping?');
    }

    if (
      isIframe &&
      localStorage.getItem(BRIDGE_LSKEY.userData) &&
      !gtmDataSubmitted
    ) {
      setGtmDataSubmitted(true);
      messageToParent(
        `${BRIDGE_MSG.gtmObjPrefix}${localStorage.getItem(
          BRIDGE_LSKEY.userData
        )}`
      );
    }

    const messageCallback = (e) => {
      const { origin, data: message } = e;

      if (!REACT_APP_SBM_DOMAIN.includes(origin)) return null;

      if (message === BRIDGE_MSG.signout) {
        signOut();
      }

      if (message.startsWith(BRIDGE_MSG.objPrefix)) {
        const { langId } = JSON.parse(
          message.slice(BRIDGE_MSG.objPrefix.length)
        );

        if (langId && MYACC_LANGUAGE.find((lang) => lang.langId === langId)) {
          localStorage.setItem('i18nextLng', langId);
        }
      }

      setIsIframe(true);

      return null;
    };

    const storageCallback = (e) => {
      const { key: storageKey, oldValue, newValue } = e;

      if (oldValue !== newValue) {
        // This part of handler will be called just in passive browser tabs or iframes
        if (
          isIframe &&
          storageKey === BRIDGE_LSKEY.authorized &&
          !oldValue &&
          newValue
        ) {
          setBridgeUserSignedIn(newValue);
        }
        if (
          isIframe &&
          storageKey === BRIDGE_LSKEY.userData &&
          !gtmDataSubmitted &&
          !!newValue
        ) {
          messageToParent(`${BRIDGE_MSG.gtmObjPrefix}${newValue}`);
        }
        if (
          isIframe &&
          storageKey === BRIDGE_LSKEY.userData &&
          gtmDataSubmitted &&
          oldValue &&
          !newValue
        ) {
          setGtmDataSubmitted(false);
        }
        if (
          isIframe &&
          storageKey === BRIDGE_LSKEY.authorized &&
          oldValue &&
          !newValue
        ) {
          // Send message to SBM: user signed out
          setBridgeUserSignedIn(newValue);
          setBridgeData({
            signedin: false,
          });
          signOut();
        }

        // This part of handler will be called just in active browser tab
        if (!isIframe) {
          if (storageKey === BRIDGE_LSKEY.authorized) {
            if (oldValue && !newValue) {
              signOut();
            }

            if (!oldValue && newValue) {
              history.push('/');
              window.location.reload();
            }
          }

          // synchronyze MyAcc interface language with SBM language
          if (storageKey === 'i18nextLng') {
            const langId = localStorage.getItem('i18nextLng');
            const {
              location: { pathname },
            } = history;

            changeLanguage(langId);

            history.replace(
              `/${langId}${
                pathname.slice(3).length > 0 ? pathname.slice(3) : ''
              }`
            );
          }
        }
      }
    };

    window.addEventListener('message', messageCallback, false);
    window.addEventListener('storage', storageCallback);

    return () => {
      window.removeEventListener('message', messageCallback);
      window.removeEventListener('storage', storageCallback);
    };
  });

  return isIframe ? null : children;
};

PostMessageSBMListener.propTypes = propTypes;

export default PostMessageSBMListener;
