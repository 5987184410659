import React, { useState } from 'react';
import { func, bool } from 'prop-types';

import useEffectOnUpdate from 'common/hooks/useEffectOnUpdate';

const propTypes = {
  children: func.isRequired,
  status: bool,
  defaultStatus: bool,
  onChange: func,
};

const defaultProps = {
  status: false,
  defaultStatus: false,
  onChange: null,
};

const Toggle = ({ children, status: propStatus, defaultStatus, onChange }) => {
  const [status, setStatus] = useState(defaultStatus || propStatus);

  const toggle = () => setStatus(!status);

  useEffectOnUpdate(() => {
    if (typeof onChange === 'function') {
      onChange({ status });
    }
  }, [status]);

  useEffectOnUpdate(() => {
    setStatus(propStatus);
  }, [propStatus]);

  return children({ status, toggle });
};

Toggle.propTypes = propTypes;
Toggle.defaultProps = defaultProps;

export default Toggle;
