import React from 'react';
import { string, bool, func } from 'prop-types';
import classNames from 'classnames/bind';

import Button from '~/components/Button';

import styles from './PushRightColButton.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  url: string,
  theme: string,
  size: string,
  color: string,
  label: string.isRequired,
  disabled: bool,
  inverted: bool,
  onClick: func,
};

const defaultProps = {
  className: null,
  url: null,
  disabled: false,
  inverted: false,
  onClick: null,
  theme: 'rounded',
  size: 'm',
  color: 'tallow-invert',
};

const PushRightColButton = ({
  className,
  url,
  label,
  disabled,
  inverted,
  onClick,
  theme,
  size,
  color,
}) => {
  if (disabled) {
    return (
      <Button
        className={cx('root', className, theme)}
        disabled={true}
        inverted={inverted}
        theme={theme}
        size={size}
        color={color}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      to={url}
      className={cx('root', className, theme)}
      inverted={inverted}
      onClick={onClick}
      theme={theme}
      size={size}
      color={color}
    >
      {label}
    </Button>
  );
};

PushRightColButton.propTypes = propTypes;
PushRightColButton.defaultProps = defaultProps;

export default PushRightColButton;
