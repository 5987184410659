import React from 'react';
import { string, node, bool } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './PushRightColFooter.module.scss';

const cx = classNames.bind(styles);

// PushRightColFooter PropTypes
const pushRightColFooterPropTypes = {
  className: string,
  children: node,
  isLeft: bool,
  longStyle: bool,
};

const pushRightColFooterDefaultProps = {
  className: null,
  children: null,
  isLeft: false,
  longStyle: true,
};

// PushRightColFooterLeftCol PropTypes
const pushRightColFooterLeftColPropTypes = {
  children: node,
};

const pushRightColFooterLeftColDefaultProps = {
  children: null,
};

// PushRightColFooterRightCol PropTypes
const pushRightColFooterRightColPropTypes = {
  children: node,
  pullRight: bool,
  isStretch: bool,
  className: string,
};

const pushRightColFooterRightColDefaultProps = {
  children: null,
  pullRight: false,
  isStretch: false,
  className: null,
};

const PushRightColFooter = ({
  className,
  isLeft,
  longStyle,
  children,
  ...attributes
}) => {
  return (
    <div
      className={cx(
        'root',
        { odd: isLeft },
        { 'long-style': longStyle },
        className
      )}
      {...attributes}
    >
      {children}
    </div>
  );
};

const PushRightColFooterLeftCol = ({ children }) => {
  return (
    <div
      className={cx(
        'column-2-bottom-col',
        'column-2-bottom-col-1',
        'column-2-bottom-col-text'
      )}
    >
      {children}
    </div>
  );
};

const PushRightColFooterRightCol = ({
  children,
  pullRight,
  isStretch,
  className,
}) => {
  return (
    <div
      className={cx(
        'column-2-bottom-col',
        'column-2-bottom-col-2',
        {
          'pull-right': pullRight,
          stretch: isStretch,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

PushRightColFooter.propTypes = pushRightColFooterPropTypes;
PushRightColFooter.defaultProps = pushRightColFooterDefaultProps;

PushRightColFooterLeftCol.propTypes = pushRightColFooterLeftColPropTypes;
PushRightColFooterLeftCol.defaultProps = pushRightColFooterLeftColDefaultProps;

PushRightColFooterRightCol.propTypes = pushRightColFooterRightColPropTypes;
PushRightColFooterRightCol.defaultProps =
  pushRightColFooterRightColDefaultProps;

export { PushRightColFooterLeftCol, PushRightColFooterRightCol };
export default PushRightColFooter;
