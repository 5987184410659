import { EUROPE_PARIS_TIME_ZONE_NAME } from 'common/constants/time-zones';

import moment from 'common/momentWithTimeZonesData';

const getUnixTime = (time) => {
  const timeArray = time.split(':');
  return timeArray[0] * 60 * 60 + timeArray[1] * 60;
};

const checkIfOpenStatus = (openingHours) => {
  if (!openingHours) return false;

  const calculatedFullDate = moment.tz(EUROPE_PARIS_TIME_ZONE_NAME);
  const currentDayNumber = calculatedFullDate.day();
  const currentTime = calculatedFullDate.format('HH:mm');
  const currentUnixTime = getUnixTime(currentTime);

  const periodDates = openingHours.find(({ periodStart, periodEnd }) => {
    const dateStart = moment.tz(periodStart, EUROPE_PARIS_TIME_ZONE_NAME);
    const dateEnd = moment.tz(periodEnd, EUROPE_PARIS_TIME_ZONE_NAME);

    return calculatedFullDate.isBetween(dateStart, dateEnd);
  });

  if (!!periodDates) {
    const dayFiltered = periodDates.days.find((item) => {
      return item.day === currentDayNumber;
    });

    if (dayFiltered && dayFiltered.open) {
      const hoursFiltered = dayFiltered.hours.find(({ start, end }) => {
        if (start !== 'all') {
          return currentUnixTime >= start && currentUnixTime <= end;
        } else {
          return true;
        }
      });

      return !!hoursFiltered;
    }
  }

  return false;
};

export default checkIfOpenStatus;
