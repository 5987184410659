import { useMemo } from 'react';

import newArray from 'common/utils/newArray';

import { getYear } from '../utils/calendarParams';

export default function useDays({ lastAvailableDate }) {
  const lastAvailableYear = useMemo(
    () => (lastAvailableDate ? getYear(lastAvailableDate) : getYear()),
    [lastAvailableDate]
  );

  const yearsList = useMemo(
    () =>
      newArray(lastAvailableYear - getYear() + 100).map(
        (_, index) => lastAvailableYear - index
      ),
    [lastAvailableYear]
  );

  return { yearsList, lastAvailableYear };
}
