import React from 'react';
import { shape, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import ContentLoader from 'common/components/ContentLoader';
import Container from 'common/components/Container';
import { useEffectOnMount } from 'common/hooks';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUserContext } from '~/context/UserContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import BlockTitle from '~/components/BlockTitle';
import ButtonBack from '~/components/ButtonBack';
import GTM from '~/components/GTM';

import FormIdentity from './components/FormIdentity';
import FormIdentityRegions from './components/FormIdentityRegions';
import styles from './EditIdentity.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  match: shape({
    path: string,
  }).isRequired,
};

function EditIdentity() {
  const { t } = useTranslation('edit-identity');

  const { language } = useLocalisationContext();
  const {
    userSbmIdentity,
    userSbmPreferences,
    userSbmLoyalty,
    loyaltyStatus,
    userSbmGdpr,
    refetchUserData,
    loadingUserData,
    isFailSafeMode,
  } = useUserContext();

  useEffectOnMount(() => {
    refetchUserData();
  });

  return (
    <>
      <Helmet>
        <title>{t('pageTitle')}</title>
      </Helmet>

      <Container className={cx('root')} width={760}>
        <div className={cx('content-wrapper')}>
          <ButtonBack to={`/${language}/account-information`} />
          <BlockTitle title={t('blockIdentityTitle')} className={cx('title')} />
          {loadingUserData ? (
            <ContentLoader />
          ) : (
            <FormIdentity
              data={userSbmIdentity}
              redirectTo={`/${language}/account-information`}
            />
          )}
        </div>
      </Container>

      {!loadingUserData && (
        <Container className={cx('root')} width={760}>
          <div className={cx('content-wrapper')}>
            <ButtonBack to={`/${language}/account-information`} />
            <BlockTitle
              title={t('blockNationalityTitle')}
              className={cx('title')}
            />
            <FormIdentityRegions data={userSbmIdentity} />
          </div>
        </Container>
      )}

      {!isFailSafeMode && (
        <GTM
          isMyAC
          userIdentity={userSbmIdentity}
          userPreferences={userSbmPreferences}
          userLoyalty={userSbmLoyalty}
          loyaltyStatus={loyaltyStatus}
          userGdpr={userSbmGdpr}
          loadingUserData={loadingUserData}
        />
      )}
    </>
  );
}

EditIdentity.propTypes = propTypes;

export default withErrorBoundary(EditIdentity);
