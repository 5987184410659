import React from 'react';
import classNames from 'classnames/bind';

import styles from './PasswordPlaceholder.module.scss';

const cx = classNames.bind(styles);

const PasswordPlaceholder = () => (
  <div className={cx('root')}>************</div>
);

export default PasswordPlaceholder;
