import React, { useState } from 'react';
import { bool, object, func, string } from 'prop-types';
import classNames from 'classnames/bind';
import moment from 'moment';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconLeftArrow } from 'common/media/icons/left-arrow.icon.svg';
import { ReactComponent as IconRightArrow } from 'common/media/icons/right-arrow.icon.svg';
import { isBeforeDay, isBeforeMonth } from 'common/utils/dates';

import styles from './DayPickerSingleControllerWrapper.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  initialDate: object,
  onDateChange: func,
  onFocusChange: func,
  onOutsideClick: func,
  datesStartRange: string,
  datesEndRange: string,
  withMonthYearSelect: bool,
};

const defaultProps = {
  initialDate: moment(),
  onDateChange: null,
  onFocusChange: () => {},
  onOutsideClick: () => {},
  datesStartRange: moment().subtract(100, 'years').format('YYYY-MM-DD'),
  datesEndRange: null,
  withMonthYearSelect: false,
};

const DayPickerSingleControllerWrapper = ({
  initialDate,
  onDateChange,
  onFocusChange,
  onOutsideClick,
  datesStartRange,
  datesEndRange,
  withMonthYearSelect,
}) => {
  const [date, setDate] = useState(() => {
    const data = isBeforeDay(moment(datesEndRange, 'YYYY-MM-DD'), initialDate)
      ? moment(datesEndRange)
      : initialDate.clone();
    // Fix bug with calendar language if we pass some date
    data.locale(moment.locale());
    return data;
  });
  const [isPrevDisabled, setPrevDisabled] = useState(
    !datesStartRange
      ? false
      : !isBeforeMonth(moment(), moment(datesStartRange, 'YYYY-MM-DD'))
  );
  const [isNextDisabled, setNextDisabled] = useState(
    !datesEndRange
      ? false
      : !isBeforeMonth(moment(), moment(datesEndRange, 'YYYY-MM-DD'))
  );

  const setNewDate = (date) => {
    if (onDateChange) {
      onDateChange(date);
    }
    setDate(date);
  };

  const renderSelectMonthYear = ({
    month: datepickerMonth,
    onMonthSelect,
    onYearSelect,
  }) => {
    const currentYear = moment().year();
    const startYear = datesStartRange
      ? moment(datesStartRange).year()
      : currentYear - 99;
    const endYear = datesEndRange
      ? moment(datesEndRange).year()
      : currentYear + 99;
    const yearsCount = endYear - startYear + 1;
    const years = new Array(yearsCount)
      .fill(0)
      .map((_, index) => startYear + index);
    const month = datepickerMonth.month();
    const year = datepickerMonth.year();

    return (
      <div className={cx('select-month-year')}>
        <div className={cx('select-wrapper')}>
          <select
            className={cx('select')}
            value={month}
            onChange={(e) => {
              onMonthSelect(datepickerMonth, e.target.value);
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <span className={cx('arrow')} />
        </div>
        <div className={cx('select-wrapper')}>
          <select
            className={cx('select')}
            value={year}
            onChange={(e) => {
              onYearSelect(datepickerMonth, e.target.value);
            }}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          <span className={cx('arrow')} />
        </div>
      </div>
    );
  };

  const renderDayContents = (m) => {
    return <span>{m.format('D')}</span>;
  };

  const isOutsideRange = (day) => {
    return (
      (datesStartRange &&
        isBeforeDay(day, moment(datesStartRange, 'YYYY-MM-DD'))) ||
      (datesEndRange &&
        !isBeforeDay(day, moment(datesEndRange, 'YYYY-MM-DD').add(1, 'day')))
    );
  };

  const onPrevMonthClick = (date) => {
    setPrevDisabled(
      isBeforeMonth(
        date.subtract(1, 'months'),
        moment(datesStartRange, 'YYYY-MM-DD')
      )
    );
    setNextDisabled(
      !datesEndRange
        ? false
        : !isBeforeMonth(
            date.add(1, 'months'),
            moment(datesEndRange, 'YYYY-MM-DD')
          )
    );
  };

  const onNextMonthClick = (date) => {
    setPrevDisabled(
      isBeforeMonth(
        date.subtract(1, 'months'),
        moment(datesStartRange, 'YYYY-MM-DD')
      )
    );
    setNextDisabled(
      !datesEndRange
        ? false
        : !isBeforeMonth(
            date.add(1, 'months'),
            moment(datesEndRange, 'YYYY-MM-DD')
          )
    );
  };

  return (
    <div
      className={cx('root', {
        'prev-disabled': isPrevDisabled,
        'next-disabled': isNextDisabled,
      })}
    >
      <DayPickerSingleDateController
        date={date}
        hideKeyboardShortcutsPanel={true}
        onDateChange={setNewDate}
        onFocusChange={onFocusChange}
        renderMonthElement={withMonthYearSelect ? renderSelectMonthYear : null}
        daySize={30}
        navPrev={<IconContainer icon={IconLeftArrow} />}
        navNext={<IconContainer icon={IconRightArrow} />}
        weekDayFormat={'dd'}
        onOutsideClick={onOutsideClick}
        renderDayContents={renderDayContents}
        isOutsideRange={isOutsideRange}
        noBorder={true}
        onNextMonthClick={onNextMonthClick}
        onPrevMonthClick={onPrevMonthClick}
        focused
      />
    </div>
  );
};

DayPickerSingleControllerWrapper.propTypes = propTypes;
DayPickerSingleControllerWrapper.defaultProps = defaultProps;

export default DayPickerSingleControllerWrapper;
