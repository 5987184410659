import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconArrowBack } from 'common/media/icons/arrow-back.icon.svg';

import styles from './ButtonBack.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  to: string.isRequired,
  className: string,
  iconClassName: string,
};

const defaultProps = {
  className: null,
  iconClassName: null,
};

function ButtonBack({ to, className, iconClassName }) {
  return (
    <Link to={to} className={cx('root', className)}>
      <IconContainer
        icon={IconArrowBack}
        className={cx('icon-arrow-back', iconClassName)}
      />
    </Link>
  );
}

ButtonBack.propTypes = propTypes;
ButtonBack.defaultProps = defaultProps;

export default ButtonBack;
