import React from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';

import MyAccLink from '~/components/MyAccLink';

import styles from './UserMenuLink.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  path: string.isRequired,
  text: string.isRequired,
};

const UserMenuLink = ({ text, path }) => {
  return (
    <MyAccLink to={path} className={cx('root')}>
      <span className={cx('label')}>{text}</span>
    </MyAccLink>
  );
};

UserMenuLink.propTypes = propTypes;

export default UserMenuLink;
