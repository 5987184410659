import { gql } from '@apollo/client';

export const SyntheticStatusQuery = gql`
  query getContextStatus($token: String) {
    getContextStatus(token: $token)
  }
`;

export const NonConfirmedUserLoginQuery = gql`
  query informSbmNonConfirmedUserLogin($email: String!) {
    informSbmNonConfirmedUserLogin(email: $email) {
      result
    }
  }
`;

export const UserAddToWishlistMutation = gql`
  mutation addToWishlist($itemId: Int!) {
    addToWishlist(itemId: $itemId) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const UserRemoveFromWishlistMutation = gql`
  mutation removeFromWishlist($itemId: Int!) {
    removeFromWishlist(itemId: $itemId) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;

export const UserChangeWishlistVisibilityMutation = gql`
  mutation changeWishlistVisibility($published: Boolean!) {
    changeWishlistVisibility(published: $published) {
      result
      messages {
        info
        status
        warning
        error
      }
    }
  }
`;
