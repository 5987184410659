import React from 'react';
import { node, object, string } from 'prop-types';
import classNames from 'classnames/bind';
import { CSSTransition } from 'react-transition-group';

import { useToggle } from 'common/hooks';

import SubtitleButtonSlideUpDown from '~/components/SubtitleButtonSlideUpDown';

import styles from './SlideDownWrapper.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  subtitleButtonLabel: string.isRequired,
  className: string,
  parentRef: object.isRequired,
  children: node.isRequired,
};

const defaultProps = {
  className: null,
};

function SlideDownWrapper({ parentRef, subtitleButtonLabel, children }) {
  const [contentVisiblity, toggleContentVisiblity] = useToggle(false);

  const scrollToHandler = () => {
    parentRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <div className={cx('root')}>
      <SubtitleButtonSlideUpDown
        onClick={toggleContentVisiblity}
        isOpen={contentVisiblity}
        label={subtitleButtonLabel}
      />

      <CSSTransition
        in={contentVisiblity}
        timeout={150}
        classNames={{
          enter: cx('transition-enter'),
          enterActive: cx('transition-enter-active'),
          enterDone: cx('transition-done-enter'),
          exit: cx('transition-exit'),
          exitActive: cx('transition-exit-active'),
        }}
        onEntered={scrollToHandler}
        onExited={scrollToHandler}
        unmountOnExit
      >
        <div className={cx('content-wrapper')}>{children}</div>
      </CSSTransition>
    </div>
  );
}

SlideDownWrapper.propTypes = propTypes;
SlideDownWrapper.defaultProps = defaultProps;

export default SlideDownWrapper;
