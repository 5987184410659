import { func, array, object } from 'prop-types';

import { PHONE_DEFAULT_CATEGORY } from '~/scenes/FormContacts/constants';

const propTypes = {
  children: func.isRequired,
  formDispatch: func.isRequired,
  formValues: object,
  emailList: array,
  phoneList: array,
  addressList: array,
};

const defaultProps = {
  formValues: null,
  emailList: [],
  phoneList: [],
  addressList: [],
};

const PhoneFieldsHandler = ({
  children,
  emailList,
  phoneList,
  addressList,
  formValues,
  formDispatch,
}) => {
  const onPhoneAdd = (id) =>
    formDispatch({
      type: 'addPhone',
      emailList: emailList,
      phoneList: phoneList,
      addressList: addressList,
      formValues,
      newField: {
        name: `phone_${id}`,
        value: '',
        catName: `phone_${id}_cat`,
        catValue: PHONE_DEFAULT_CATEGORY,
        isMutable: true,
        isDeletable: true,
        validationStatus: true,
      },
    });

  const onPhoneRemove = (id) =>
    formDispatch({
      type: 'deletePhone',
      formValues,
      emailList: emailList,
      phoneList: phoneList,
      addressList: addressList,
      name: id,
    });

  const resetPhone = ({ name, catName }) => {
    formDispatch({
      type: 'resetPhone',
      emailList: emailList,
      phoneList: phoneList,
      addressList: addressList,
      newField: {
        name,
        value: '',
        catName,
        catValue: PHONE_DEFAULT_CATEGORY,
        isMutable: true,
        isDeletable: true,
        validationStatus: true,
      },
    });
  };

  return children({
    onPhoneAdd,
    onPhoneRemove,
    resetPhone,
  });
};

PhoneFieldsHandler.propTypes = propTypes;
PhoneFieldsHandler.defaultProps = defaultProps;

export default PhoneFieldsHandler;
