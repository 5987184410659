import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { parse } from 'query-string';

import { usePrevious } from 'common/hooks';

import OfferCard from '~/components/OfferCard';
import HotelCard from '~/components/HotelCard';
import RoomCard from '~/components/RoomCard';
import RestaurantCard from '~/components/RestaurantCard';
import NightlifeCard from '~/components/NightlifeCard';
import CasinoCard from '~/components/CasinoCard';
import CasinoGameCard from '~/components/CasinoGameCard';
import WellnessProductCard from '~/components/WellnessProductCard';
import WellnessPropertyCard from '~/components/WellnessPropertyCard';
import EventCard from '~/components/EventCard';
import FestivalCard from '~/components/FestivalCard';
import { sbmClient } from '~/apolloClient';
import { useUserContext } from '~/context/UserContext';
import { useMyaccHistory } from '~/hooks';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { useAuthContext, SIGNED_IN_STATE } from '~/context/AuthContext';

const WishlistDataQuery = gql`
  query Wishlist($id: String!) {
    wishlist(id: $id) {
      __typename
      ... on WellnessProduct {
        ...wellnessProductCardFields
      }
      ... on WellnessProperty {
        ...wellnessPropertyCardFields
      }
      ... on RoomCategory {
        ...hotelRoomCardFieldsFragment
      }
      ... on Restaurant {
        ...restaurantCardFields
      }
      ... on Offer {
        ...offerCardFragment
      }
      ... on Nightlife {
        ...myaccNightlifeCardFields
      }
      ... on Hotel {
        ...myaccHotelCardFields
      }
      ... on Festival {
        ...festivalCardFields
      }
      ... on Event {
        ...eventCardFields
      }
      ... on CasinoGame {
        ...casinoGameCardFields
      }
      ... on Casino {
        ...casinoCardFields
      }
    }
    siteSettings {
      globalLabels {
        closedLabel: closed
      }
    }
  }
  ${OfferCard.fragments.offerCardFragment}
  ${HotelCard.fragment.myaccHotelCardFieldsFragment}
  ${RoomCard.fragment.hotelRoomCardFieldsFragment}
  ${RestaurantCard.fragment.restaurantCardFieldsFragment}
  ${NightlifeCard.fragment.myaccNightlifeCardFieldsFragment}
  ${CasinoCard.fragment.casinoCardFieldsFragment}
  ${CasinoGameCard.fragment.casinoGameCardFieldsFragment}
  ${WellnessProductCard.fragment.wellnessProductCardFieldsFragment}
  ${WellnessPropertyCard.fragment.wellnessPropertyCardFieldsFragment}
  ${EventCard.fragment.eventCardFieldsFragment}
  ${FestivalCard.fragment.festivalCardFieldsFragment}
`;

const PAGINATION_COUNT = 6;

const WishlistHandler = ({ children }) => {
  const { t } = useTranslation('wishlist');
  const {
    loyaltyStatus,
    wishlistIds,
    wishlistIdsPendingToUpdate,
    setWishlistIdsPendingToUpdate,
    getWishlistIdsData,
  } = useUserContext();

  const { authState } = useAuthContext();

  const { language } = useLocalisationContext();
  const prevLanguage = usePrevious(language);

  const {
    location: { search },
  } = useMyaccHistory();
  const { unique_share_id: uniqueShareId } = parse(search);

  const [wishlist, setWishlist] = useState([]);

  const [getWishlistData, { data, loading: wishlistDataLoading }] =
    useLazyQuery(WishlistDataQuery, {
      variables: {
        id: uniqueShareId || wishlistIds?.hash,
      },
      client: sbmClient,
      context: {
        headers: {
          'X-Acc-Grp': authState === SIGNED_IN_STATE ? loyaltyStatus : 0,
        },
      },
    });

  useEffect(() => {
    if (wishlistIdsPendingToUpdate && !uniqueShareId) {
      setWishlistIdsPendingToUpdate(false);
      getWishlistIdsData().then(({ data }) => {
        getWishlistData({ id: data.getWishlist.hash });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      authState === SIGNED_IN_STATE &&
      prevLanguage &&
      language !== prevLanguage
    ) {
      getWishlistIdsData().then(({ data }) => {
        getWishlistData({ id: data.getWishlist.hash });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, prevLanguage]);

  useEffect(() => {
    if (wishlistIds || uniqueShareId) {
      getWishlistData();
    }
  }, [getWishlistData, uniqueShareId, wishlistIds]);

  const [wishlistItemsToShow, setWishlistItemsToShow] =
    useState(PAGINATION_COUNT);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [activeFilter, setActiveFilter] = useState({
    type: 'all',
    id: '',
  });

  useEffect(() => {
    setWishlist(data?.wishlist.slice(0, PAGINATION_COUNT) || []);
  }, [data, wishlistDataLoading]);

  useEffect(() => {
    setWishlist(
      activeFilter.type === 'all'
        ? data?.wishlist.slice(0, wishlistItemsToShow) || []
        : data.wishlist
            .filter(({ __typename }) => __typename === activeFilter.id)
            .slice(0, wishlistItemsToShow)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlistItemsToShow]);

  const typenamesToListItemsMap = {
    WellnessProduct: WellnessProductCard,
    WellnessProperty: WellnessPropertyCard,
    RoomCategory: RoomCard,
    Restaurant: RestaurantCard,
    Offer: OfferCard,
    Nightlife: NightlifeCard,
    Hotel: HotelCard,
    Festival: FestivalCard,
    Event: EventCard,
    CasinoGame: CasinoGameCard,
    Casino: CasinoCard,
  };

  const allFilter = {
    id: '',
    count: data?.wishlist?.length,
    name: t('filter.all'),
  };

  const getWishlistFilterTypes = (wishlistItems) => {
    const wishlistItemsTypes = wishlistItems.reduce(
      (acc, { __typename: id }) => {
        if (acc[id]) {
          acc[id]++;
        } else {
          acc[id] = 1;
        }
        return acc;
      },
      {}
    );

    return Object.entries(wishlistItemsTypes).map(([type, count]) => ({
      id: type,
      count,
      name: t(`filter.${type}`),
    }));
  };

  const typeFilters = data?.wishlist
    ? getWishlistFilterTypes(data.wishlist)
    : [];

  const listFilters = {
    all: [allFilter],
    wishlist_types: typeFilters,
  };

  const onFilterToggle = (id, type) => {
    setActiveFilter({ type, id });
    setWishlist(
      type === 'all'
        ? data.wishlist.slice(0, PAGINATION_COUNT)
        : data.wishlist
            .filter(({ __typename }) => __typename === id)
            .slice(0, PAGINATION_COUNT)
    );
    setWishlistItemsToShow(PAGINATION_COUNT);
  };

  useEffect(() => {
    if (!data?.wishlist) {
      return;
    }

    const filteredCount =
      activeFilter.type === 'all'
        ? data.wishlist.length
        : data.wishlist.filter(
            ({ __typename }) => __typename === activeFilter.id
          ).length;

    setHasNextPage(wishlistItemsToShow < filteredCount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wishlist, activeFilter, wishlistItemsToShow]);

  const handleLoadMore = () => {
    if (activeFilter.type === 'all') {
      setHasNextPage(wishlist.length + PAGINATION_COUNT < data.wishlist.length);
    } else {
      setHasNextPage(
        wishlist.length + PAGINATION_COUNT <
          data.wishlist.filter(
            ({ __typename }) => __typename === activeFilter.id
          ).length
      );
    }
    setWishlistItemsToShow(wishlistItemsToShow + PAGINATION_COUNT);
  };

  const [isUnauthorisedNotifyVisible, setUnauthorisedNotifyVisible] =
    useState(false);
  const closeUnauthorisedNotifyModal = () => {
    setUnauthorisedNotifyVisible(false);
  };
  const handleUnauthorisedCardClick = (e) => {
    e.preventDefault();

    if (
      !(e.target.closest('.js-slider-dots') || e.target.closest('.slick-arrow'))
    ) {
      setUnauthorisedNotifyVisible(true);
    }
  };

  return children({
    typenamesToListItemsMap,
    wishlist,
    wishlistDataLoading,
    listFilters,
    activeFilter,
    onFilterToggle,
    hasNextPage,
    handleLoadMore,
    isUnauthorisedNotifyVisible,
    closeUnauthorisedNotifyModal,
    handleUnauthorisedCardClick,
    siteSettings: data?.siteSettings,
  });
};

export default WishlistHandler;
