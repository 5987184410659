import React from 'react';
import { object, func, arrayOf, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import ColumnHeader from '../ColumnHeader';

const propTypes = {
  columnVisibility: object.isRequired,
  setColumnVisibility: func.isRequired,
  modifiers: arrayOf(string).isRequired,
};

const SearchResultsHeader = ({
  columnVisibility,
  setColumnVisibility,
  modifiers,
}) => {
  const { t } = useTranslation('maintenance');

  return (
    <thead>
      <tr>
        {modifiers.map((modifier) => (
          <ColumnHeader
            key={modifier}
            label={t(`${modifier}Label`)}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
            modifier={modifier}
          />
        ))}
      </tr>
    </thead>
  );
};

SearchResultsHeader.propTypes = propTypes;

export default SearchResultsHeader;
