import React from 'react';
import { oneOfType, number, string, object } from 'prop-types';
import classNames from 'classnames/bind';

import styles from './Bulb.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  value: oneOfType([number, string]).isRequired,
  className: string,
  bulbRef: object,
};

const defaultProps = {
  className: '',
  bulbRef: null,
};

const Bulb = ({ className, value, bulbRef }) => (
  <div ref={bulbRef} className={cx('root', className)}>
    {value}
  </div>
);

Bulb.propTypes = propTypes;
Bulb.defaultProps = defaultProps;

export default Bulb;
