import React, { forwardRef } from 'react';
import { array, string, func, bool } from 'prop-types';

import { EMPTY_IMAGE_BASE64 } from 'common/constants';

const propTypes = {
  sources: array,
  src: string,
  alt: string,
  className: string,
  onLoad: func,
  lazy: bool,
};

const defaultProps = {
  sources: null,
  alt: null,
  className: null,
  src: EMPTY_IMAGE_BASE64,
  onLoad: null,
  lazy: false,
};

const Picture = forwardRef(
  ({ className, sources, alt, lazy, ...rest }, ref) => {
    return (
      <picture className={className}>
        {sources &&
          sources.map((source, index) => <source key={index} {...source} />)}
        <img loading={lazy ? 'lazy' : 'eager'} alt={alt} {...rest} ref={ref} />
      </picture>
    );
  }
);

Picture.propTypes = propTypes;
Picture.defaultProps = defaultProps;

export default Picture;
