import { useHistory } from 'react-router-dom';

import { useLocalisationContext } from '~/context/LocalisationContext';

const useMyaccHistory = function () {
  const history = useHistory();

  const { language } = useLocalisationContext();

  const push = (path, state) => {
    history.push(
      typeof path === 'string'
        ? `/${language}${path}`
        : { ...path, pathname: `/${language}${path.pathname}` },
      state
    );
  };

  return { ...history, push };
};

export default useMyaccHistory;
