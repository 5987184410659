import React, { useState, useEffect } from 'react';
import { bool, func, number, string } from 'prop-types';
import { momentObj } from 'react-moment-proptypes';
import classNames from 'classnames/bind';
import moment from 'moment';
import DayPickerRangeController from 'react-dates/lib/components/DayPickerRangeController';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import IconContainer from 'common/components/IconContainer';
import { ReactComponent as IconLeftArrow } from 'common/media/icons/left-arrow.icon.svg';
import { ReactComponent as IconRightArrow } from 'common/media/icons/right-arrow.icon.svg';
import { isBeforeDay } from 'common/utils/dates';

import styles from './DayPickerRangeControllerWrapper.module.scss';

const cx = classNames.bind(styles);

const START_DATE = 'startDate';
// @TODO: prepare logic for initial START_DATE/END_DATE relation with open button
// const END_DATE = 'endDate';

// @TODO: Warning: Failed prop type: CalendarWeek presended.
// waiting for fix: https://github.com/airbnb/react-dates/issues/950

const propTypes = {
  anyDateButtonLabel: string,
  applyDatesButtonLabel: string,
  datesEndRange: string,
  datesStartRange: string,
  focusedInput: string,
  initialEndDate: momentObj,
  initialStartDate: momentObj,
  isAnyDateButtonDisabled: bool,
  isFixedRange: bool,
  numberOfMonths: number,
  toggleDatesTouchedState: func,
  onDatesChange: func,
  onDatesSelected: func,
  onFocusChange: func,
  onOutsideClick: func,
  minimumNights: number,
  maximumNights: number,
};

const defaultProps = {
  anyDateButtonLabel: null,
  applyDatesButtonLabel: null,
  datesEndRange: null,
  datesStartRange: null,
  focusedInput: START_DATE,
  initialEndDate: null,
  initialStartDate: null,
  isAnyDateButtonDisabled: null,
  isFixedRange: false,
  numberOfMonths: 2,
  toggleDatesTouchedState: null,
  onDatesChange: null,
  onDatesSelected: null,
  onFocusChange: null,
  onOutsideClick: () => {},
  minimumNights: 0,
  maximumNights: null,
};

const DayPickerRangeControllerWrapper = ({
  anyDateButtonLabel,
  applyDatesButtonLabel,
  datesEndRange,
  datesStartRange,
  focusedInput: focusedInputProp,
  initialEndDate,
  initialStartDate,
  isAnyDateButtonDisabled,
  isFixedRange,
  numberOfMonths,
  toggleDatesTouchedState,
  isDatesSelected,
  onDatesChange: onDatesChangeProp,
  onDatesSelected,
  onFocusChange: onFocusChangeProp,
  onOutsideClick,
  minimumNights,
}) => {
  const currentMonth = moment().endOf('month');
  const isOutsideCurrentMonth = (date) => {
    return date.format('MM') <= currentMonth.format('MM');
  };

  const [startDate, setStartDate] = useState(initialStartDate.clone());
  const [endDate, setEndDate] = useState(
    initialEndDate ? initialEndDate.clone() : startDate
  );

  const [focusedInput, setFocusedInput] = useState(focusedInputProp);

  const [isNextDisabled, setNextDisabledStatus] = useState(
    isOutsideCurrentMonth(initialStartDate)
  );

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput ? focusedInput : START_DATE);

    if (onFocusChangeProp) {
      onFocusChangeProp(focusedInput);
    }
  };

  const onDatesChange = ({ startDate, endDate }) => {
    if (isFixedRange) {
      endDate = startDate.clone().add(minimumNights, 'days');
    }

    setStartDate(startDate);
    setEndDate(endDate);

    if (onDatesSelected && endDate) {
      onDatesSelected();
    }

    if (onDatesChangeProp) {
      onDatesChangeProp({ startDate, endDate });
    }
  };

  const isOutsideRange = (day) => {
    return !isBeforeDay(day, moment().add(1, 'days'));
  };

  const renderDayContents = (m) => {
    return <span>{m.format('D')}</span>;
  };

  const onMonthClick = (date) => {
    setNextDisabledStatus(isOutsideCurrentMonth(date));
  };

  useEffect(() => {
    setFocusedInput(focusedInputProp);
  }, [focusedInputProp]);

  return (
    <div className={cx('root', { 'next-disabled': isNextDisabled })}>
      <DayPickerRangeController
        keepOpenOnDateSelect={false}
        onDatesChange={onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        numberOfMonths={numberOfMonths}
        enableOutsideDays={false}
        // @TODO: investigate issue and this hack:
        onOutsideClick={onOutsideClick}
        isOutsideRange={isOutsideRange}
        noBorder={true}
        hideKeyboardShortcutsPanel={true}
        renderDayContents={renderDayContents}
        daySize={30}
        navPrev={<IconContainer icon={IconLeftArrow} />}
        navNext={<IconContainer icon={IconRightArrow} />}
        weekDayFormat={'dd'}
        onNextMonthClick={onMonthClick}
        onPrevMonthClick={onMonthClick}
        minimumNights={minimumNights}
      />
      {toggleDatesTouchedState && (
        <button
          className={cx('any-day-button')}
          onClick={toggleDatesTouchedState}
        >
          {isDatesSelected ? anyDateButtonLabel : applyDatesButtonLabel}
        </button>
      )}
    </div>
  );
};

DayPickerRangeControllerWrapper.propTypes = propTypes;
DayPickerRangeControllerWrapper.defaultProps = defaultProps;

export default DayPickerRangeControllerWrapper;
