import { useReducer, useMemo } from 'react';
import uniqueId from 'lodash/uniqueId';
import { useTranslation } from 'react-i18next';

import { stringifyFieldData, contactsReducer } from '../utils';
import {
  EMAIL_DEFAULT_CATEGORY,
  PHONE_DEFAULT_CATEGORY,
  ADDRESS_DEFAULT_CATEGORY,
  ADDRESS_UNKNOWN_DATA_CATEGORY_KEY,
} from '~/scenes/FormContacts/constants';

const getInitialCatValue = ({ categoriesList, category }) =>
  category
    ? categoriesList.find(({ code }) => code === category.toString())
    : '';

const getCategoriesMap = (categoriesLabels, metaCategoriesLabels) =>
  Object.entries(categoriesLabels).reduce((valuesArr, labelItem) => {
    const findItem = Object.entries(metaCategoriesLabels).find(
      (codeItem) => codeItem[0] === labelItem[0]
    );
    findItem && valuesArr.push({ label: labelItem[1], code: findItem[1] });
    return valuesArr;
  }, []);

const getCategoryWithCode = (labels) =>
  labels.map(({ label, code }) => ({
    value: code,
    label: label,
  }));

export default function useContacts({ email, phone, address }) {
  const { t } = useTranslation('contacts-form');
  const emailCategoriesLabels = t('emailCategoriesLabels', {
    returnObjects: true,
  });
  const phoneCategoriesLabels = t('phoneCategoriesLabels', {
    returnObjects: true,
  });
  const addressCategoriesLabels = t('addressCategoriesLabels', {
    returnObjects: true,
  });
  const metaCategoriesLabels = t('_meta', { returnObjects: true });

  const emailCategoriesArray = useMemo(
    () =>
      getCategoriesMap(
        emailCategoriesLabels,
        metaCategoriesLabels.emailCategoriesLabels
      ),
    [emailCategoriesLabels, metaCategoriesLabels.emailCategoriesLabels]
  );

  const phoneCategoriesArray = useMemo(
    () =>
      getCategoriesMap(
        phoneCategoriesLabels,
        metaCategoriesLabels.phoneCategoriesLabels
      ),
    [phoneCategoriesLabels, metaCategoriesLabels.phoneCategoriesLabels]
  );

  const addressCategoriesArray = useMemo(
    () =>
      getCategoriesMap(
        addressCategoriesLabels,
        metaCategoriesLabels.addressCategoriesLabels
      ),
    [addressCategoriesLabels, metaCategoriesLabels.addressCategoriesLabels]
  );

  const initialContacts = useMemo(() => {
    return {
      emailList: email?.length
        ? email.map(
            ({ isMutable, isDeletable, email, category, id: idData }) => {
              const id = uniqueId();
              const catValueInitial = getInitialCatValue({
                categoriesList: emailCategoriesArray,
                category,
              });

              return {
                name: `email_${id}`,
                value: stringifyFieldData(email),
                catName: `email_${id}_cat`,
                catValue: catValueInitial ? catValueInitial.code : '',
                isMutable: isMutable,
                isDeletable: isDeletable,
                idData,
              };
            }
          )
        : (() => {
            // email data fallback for cases when email list is []
            const id = uniqueId();

            return [
              {
                name: `email_${id}`,
                value: '',
                catName: `email_${id}_cat`,
                catValue: EMAIL_DEFAULT_CATEGORY,
                isMutable: true,
                isDeletable: true,
                idData: '',
              },
            ];
          })(),
      phoneList: phone?.length
        ? phone.map(
            ({ isMutable, isDeletable, phone, category, id: idData }) => {
              const id = uniqueId();
              const catValueInitial = getInitialCatValue({
                categoriesList: phoneCategoriesArray,
                category,
              });

              return {
                name: `phone_${id}`,
                value: stringifyFieldData(phone),
                catName: `phone_${id}_cat`,
                catValue: catValueInitial ? catValueInitial.code : '',
                isMutable: isMutable,
                isDeletable: isDeletable,
                idData,
                validationStatus: true,
              };
            }
          )
        : (() => {
            const id = uniqueId();

            return [
              {
                name: `phone_${id}`,
                value: '',
                catName: `phone_${id}_cat`,
                catValue: PHONE_DEFAULT_CATEGORY,
                isMutable: true,
                isDeletable: true,
                idData: '',
                validationStatus: true,
              },
            ];
          })(),
      addressList: address?.length
        ? address.map(
            ({
              isMutable,
              isDeletable,
              address1,
              address2,
              zipCode,
              city,
              state,
              country,
              category,
              id: idData,
            }) => {
              const id = uniqueId();
              const catValueInitial = getInitialCatValue({
                categoriesList: addressCategoriesArray,
                category,
              });

              return {
                name: `address1_${id}`,
                value: stringifyFieldData(address1),
                secondaryName: `address2_${id}`,
                secondaryValue: stringifyFieldData(address2),
                zipCodeName: `zipCode_${id}`,
                zipCodeValue: stringifyFieldData(zipCode),
                cityName: `city_${id}`,
                cityValue: stringifyFieldData(city),
                stateName: `state_${id}`,
                stateValue: stringifyFieldData(state),
                countryName: `country_${id}`,
                countryValue: stringifyFieldData(country),
                catName: `address_${id}_cat`,
                catValue: catValueInitial
                  ? catValueInitial.code
                  : ADDRESS_UNKNOWN_DATA_CATEGORY_KEY,
                isMutable: isMutable,
                isDeletable: isDeletable,
                idData,
              };
            }
          )
        : (() => {
            const id = uniqueId();

            return [
              {
                name: `address1_${id}`,
                value: '',
                secondaryName: `address2_${id}`,
                secondaryValue: '',
                zipCodeName: `zipCode_${id}`,
                zipCodeValue: '',
                cityName: `city_${id}`,
                cityValue: '',
                stateName: `state_${id}`,
                stateValue: '',
                countryName: `country_${id}`,
                countryValue: '',
                catName: `address_${id}_cat`,
                catValue: ADDRESS_DEFAULT_CATEGORY,
                isMutable: true,
                isDeletable: true,
                idData: '',
              },
            ];
          })(),
    };
  }, [
    email,
    phone,
    address,
    emailCategoriesArray,
    phoneCategoriesArray,
    addressCategoriesArray,
  ]);

  const [{ emailList, phoneList, addressList }, dispatch] = useReducer(
    contactsReducer,
    initialContacts
  );

  const emailCategoryList = useMemo(
    () => getCategoryWithCode(emailCategoriesArray),
    [emailCategoriesArray]
  );

  const phoneCategoryList = useMemo(
    () => getCategoryWithCode(phoneCategoriesArray),
    [phoneCategoriesArray]
  );
  const addressCategoryList = useMemo(
    () => getCategoryWithCode(addressCategoriesArray),
    [addressCategoriesArray]
  );

  return {
    emailList,
    emailCategoryList,
    phoneList,
    phoneCategoryList,
    addressList,
    addressCategoryList,
    formDispatch: dispatch,
  };
}
