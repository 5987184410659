import { useState, useCallback } from 'react';

export default function useOnNewFieldAdd(
  formElementRef,
  initialCounterValue = null
) {
  const [counter, setCounter] = useState(initialCounterValue);

  const onNewFieldAdd = useCallback(() => {
    if ('IntersectionObserver' in window) {
      let config = {
        root: null,
        rootMargin: '0px',
        threshold: 0,
      };

      let observer = new IntersectionObserver(onChange, config);

      if (!!formElementRef.current) {
        observer.observe(formElementRef.current);
      }

      function onChange(changes, observer) {
        if (changes[0].isIntersecting === true) {
          formElementRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
          });

          observer.unobserve(changes[0].target);
        }
      }
    } else {
      formElementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [formElementRef]);

  return { onNewFieldAdd, counter, setCounter };
}
