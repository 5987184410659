import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import flatToNestedNSortedMenu from 'common/utils/flatToNestedNSortedMenu';

import Toggle from 'common/containers/Toggle';
import { MYACC_LANGUAGE } from 'common/constants';

import { useUiContext } from 'common/context/UiContext';
import { useLocalisationContext } from '~/context/LocalisationContext';
import MyAccLink from '~/components/MyAccLink';
import { useMyaccHistory } from '~/hooks';

import styles from './MainMenu.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  footerMainMenu: object.isRequired,
};

const renderFooterMainMenu = (item, currentBreakpoint) => {
  const getLink = () =>
    item.onClick ? (
      <button type="button" onClick={item.onClick}>
        {item.title}
      </button>
    ) : (
      <MyAccLink to={item.url} target={item.target}>
        {item.title}
      </MyAccLink>
    );
  const getButton = () =>
    currentBreakpoint === 'desktop' ? (
      <div className={cx('main-menu-list-head')}>{item.title}</div>
    ) : (
      <Toggle>
        {({ toggle: toggleActive, status: isActive }) => {
          return (
            <button
              className={cx('main-menu-list-head', {
                'main-menu-list-head-active': isActive,
              })}
              onClick={toggleActive}
            >
              {item.title}
            </button>
          );
        }}
      </Toggle>
    );

  return (
    <li key={item.id} className={cx('main-menu-list-item')}>
      {item.isLink ? getLink() : getButton()}
      {item.children && (
        <ul>
          {item.children.map((el) =>
            renderFooterMainMenu(el, currentBreakpoint)
          )}
        </ul>
      )}
    </li>
  );
};

const MainMenu = ({ footerMainMenu, colCount }) => {
  const { t } = useTranslation('common');
  const toggleTitle = t('lang-menu-title');

  const history = useMyaccHistory();
  const { changeLanguage } = useLocalisationContext();
  const { currentBreakpoint } = useUiContext();
  const footerMainMenuNested = flatToNestedNSortedMenu(
    footerMainMenu.menuItems
  );

  const {
    location: { pathname },
  } = history;

  const toggleLang = function (langId) {
    changeLanguage(langId);

    history.replace(
      `/${langId}${pathname.slice(3).length > 0 ? pathname.slice(3) : ''}`
    );
  };

  const langItems = {
    title: toggleTitle,
    children: MYACC_LANGUAGE.map((lang) => ({
      isLink: true,
      onClick: toggleLang.bind(this, lang.langId),
      title: lang.title,
      id: lang.langId,
    })),
  };

  return (
    <div className={cx('root')}>
      <ul className={cx('main-menu-list')}>
        {renderFooterMainMenu(langItems, currentBreakpoint)}
        {footerMainMenuNested
          .slice(0, 4)
          .map((el) => renderFooterMainMenu(el, currentBreakpoint))}
      </ul>
    </div>
  );
};

MainMenu.propTypes = propTypes;

export default MainMenu;
