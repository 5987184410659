import React from 'react';
import { string, bool } from 'prop-types';

import Container from 'common/components/Container';
import styles from './HeroInfo.module.scss';

import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const propTypes = {
  title: string.isRequired,
  subtitle: string,
  subtitleFirst: bool,
  className: string,
};

const defaultProps = {
  subtitle: null,
  subtitleFirst: false,
  className: null,
};

const HeroInfo = ({ title, subtitle, subtitleFirst, className }) => (
  <div className={cx('root')}>
    <Container className={cx('hero-info-container', className)} width={1140}>
      {subtitleFirst && subtitle && (
        <h2 className={cx('subtitle')}>{subtitle}</h2>
      )}
      <h1 className={cx('title')}>{title}</h1>
      {!subtitleFirst && subtitle && (
        <h2 className={cx('subtitle')}>{subtitle}</h2>
      )}
    </Container>
  </div>
);

HeroInfo.propTypes = propTypes;
HeroInfo.defaultProps = defaultProps;

export default HeroInfo;
