import { useCallback, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';

import {
  isBulbVisible,
  enhanceUserMenuItems,
} from 'common/utils/userMenuUtils';
import { usePrevious } from 'common/hooks';
import { MYAC_MODE, MYCS_MODE, BRIDGE_LSKEY } from 'common/constants';
import { setCookiesCrossDomain } from 'common/utils/cookies';

import { sbmClient } from '~/apolloClient';
import { useAuthContext } from '~/context/AuthContext';
import { useUserContext } from '~/context/UserContext';
import { useLocalisationContext } from '~/context/LocalisationContext';
import { withErrorBoundary } from '~/containers/ErrorBoundary';
import { LINK_EXTERNAL, LINK_SBM, LINK_MYACC } from '~/constants';

const { REACT_APP_SBM_DOMAIN, REACT_APP_MYACC_DOMAIN } = process.env;

const NEW = 'New';

const UserMenuDataQuery = gql`
  query UserMenuData {
    userMenu: menu(name: user_menu) {
      menuItems {
        id
        title
        isLink
        url
        target
        weight
      }
    }
  }
`;

const ServiceMenuDataQuery = gql`
  query ServiceMenuData {
    serviceMenu: menu(name: service_menu) {
      menuItems {
        id
        title
        isLink
        url
        target
      }
    }
  }
`;

const UserMenuDataHandler = ({ children }) => {
  const { language } = useLocalisationContext();
  const prevLanguage = usePrevious(language);
  const {
    loyaltyStatus,
    loadingUserData,
    loyaltyProgramJoined,
    notificationsData,
  } = useUserContext();
  const [notificationsCount, setNotificationsCount] = useState(null);
  const { appMode, signOut } = useAuthContext();
  const [menuItems, setMenuItems] = useState([]);
  const [isNotificationsBulbVisible, setNotificationsBulbVisible] =
    useState(false);

  const [
    getMenuData,
    { loading: menuDataLoading, data: menuData, refetch: refetchMenuData },
  ] = useLazyQuery(
    appMode === MYCS_MODE ? ServiceMenuDataQuery : UserMenuDataQuery,
    {
      client: sbmClient,
      fetchPolicy: 'no-cache',
    }
  );

  const enhanceMenuItems = useCallback((menuItems) => {
    const enhancedItems = menuItems.reduce((items, item) => {
      const { url } = item;

      let type = LINK_EXTERNAL;

      if (
        (!url.includes(REACT_APP_MYACC_DOMAIN) && url.startsWith('/')) ||
        url.includes(REACT_APP_SBM_DOMAIN)
      ) {
        type = LINK_SBM;
      }

      if (url.includes(REACT_APP_MYACC_DOMAIN)) {
        type = LINK_MYACC;
      }

      items.push({
        linkType: type,
        ...item,
      });
      return items;
    }, []);

    return enhancedItems;
  }, []);

  useEffect(() => {
    if (
      (appMode === MYAC_MODE || appMode === MYCS_MODE) &&
      !!loyaltyStatus &&
      !loadingUserData
    ) {
      getMenuData();
    }
  }, [appMode, getMenuData, loyaltyStatus, loadingUserData]);

  useEffect(() => {
    if (menuData) {
      const { userMenu, serviceMenu } = menuData;

      if (userMenu) {
        setNotificationsBulbVisible(isBulbVisible(userMenu.menuItems));
        let menuItems = enhanceMenuItems(
          enhanceUserMenuItems(userMenu.menuItems)
        );
        menuItems = menuItems.sort((a, b) => (a.weight > b.weight ? 1 : -1));
        // hide home menu link from main menu
        // if user are not joined to loyalty program.
        if (loadingUserData || !loyaltyProgramJoined) {
          menuItems = menuItems.filter((el) => {
            return (
              el.url !== '/' &&
              el.url !== REACT_APP_MYACC_DOMAIN &&
              el.url !== REACT_APP_MYACC_DOMAIN + '/'
            );
          });
        }
        setMenuItems(menuItems);
      }
      if (serviceMenu) {
        setMenuItems(serviceMenu.menuItems);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuData, loadingUserData, loyaltyProgramJoined]);

  useEffect(() => {
    if (notificationsData?.getNotifications) {
      const { getNotifications } = notificationsData;

      setNotificationsCount(
        getNotifications.filter(({ status }) => status === NEW).length
      );
    }
  }, [notificationsData]);

  useEffect(() => {
    if (prevLanguage && language !== prevLanguage) {
      refetchMenuData();
    }
  }, [language, prevLanguage, refetchMenuData]);

  const signOutHandler = useCallback(() => {
    signOut();
    setCookiesCrossDomain(BRIDGE_LSKEY.signoutSBMTrigger, true);
  }, [signOut]);

  return children({
    menuDataLoading,
    menuItems,
    isNotificationsBulbVisible,
    notificationsCount,
    signOutHandler,
  });
};

export default withErrorBoundary(UserMenuDataHandler);
