import React, { useCallback } from 'react';
import { Formik, Form, Field } from 'formik';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { func, bool } from 'prop-types';

import Input from 'common/components/FormComponents/Input';
import SpinLoader from 'common/components/SpinLoader';
import InformationPanel from 'common/components/InformationPanel';

import Button from '~/components/Button';
import InfoMessages from '~/components/InfoMessages';

import AddMymcNumberFormHandler from './containers/AddMymcNumberFormHandler';
import styles from './AddMymcNumberForm.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  isMymcNumberFormVisible: bool.isRequired,
  toggleMymcNumberForm: func.isRequired,
  setMymcNumberVerificationData: func.isRequired,
  setIsLoyaltyMembership: func.isRequired,
  isMyCS: bool,
};

const defaultProps = {
  isMyCS: false,
};

const AddMymcNumberForm = ({
  isMymcNumberFormVisible,
  toggleMymcNumberForm,
  setMymcNumberVerificationData,
  setIsLoyaltyMembership,
  isMyCS,
}) => {
  const { t } = useTranslation(['loyalty-program', 'common']);

  const addMymcNumberLabels = t('addMymcNumberForm', {
    returnObjects: true,
  });
  const infoLabels = t('messages', {
    returnObjects: true,
  });
  const saveButtonLabel = t('common:save');
  const cancelButtonLabel = t('common:cancel');

  const onCancelClickHandler = useCallback(() => {
    toggleMymcNumberForm();
    setMymcNumberVerificationData(null);
  }, [setMymcNumberVerificationData, toggleMymcNumberForm]);

  return (
    <CSSTransition
      in={isMymcNumberFormVisible}
      timeout={150}
      classNames={{
        enter: cx('transition-enter'),
        enterActive: cx('transition-enter-active'),
        enterDone: cx('transition-done-enter'),
        exit: cx('transition-exit'),
        exitActive: cx('transition-exit-active'),
      }}
      unmountOnExit
    >
      <div className={cx('add-mymcnumber')}>
        <>
          <div className={cx('spacer')}></div>
          <AddMymcNumberFormHandler
            setMymcNumberVerificationData={setMymcNumberVerificationData}
            setIsLoyaltyMembership={setIsLoyaltyMembership}
          >
            {({
              handleSubmit,
              initialValues,
              validationSchema,
              errors,
              submitSuccess,
              resetMessage,
              isSubmitting,
            }) => (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isValid, values }) => (
                  <Form onChange={resetMessage}>
                    <Field
                      name="mymc_number"
                      component={Input}
                      placeholder={addMymcNumberLabels.mymcNumberFieldLabel}
                      maxLength={254}
                      className={cx('input')}
                    />
                    <div className={cx('add-mymcnumber-hint')}>
                      {addMymcNumberLabels.mymcNumberFieldHint}
                    </div>
                    <div className={cx('add-mymcnumber-buttons-wrap')}>
                      <Button
                        className={cx('button-cancel')}
                        type="button"
                        onClick={onCancelClickHandler}
                        inverted
                      >
                        {cancelButtonLabel}
                      </Button>
                      <Button
                        type="submit"
                        className={cx('button-save', {
                          loading: isSubmitting,
                        })}
                        disabled={
                          !values.mymc_number ||
                          !isValid ||
                          isSubmitting ||
                          submitSuccess ||
                          isMyCS
                        }
                      >
                        {isSubmitting && (
                          <SpinLoader className={cx('loader')} size={18} />
                        )}
                        {saveButtonLabel}
                      </Button>
                    </div>
                    {submitSuccess && (
                      <InformationPanel withIcon successMode>
                        {addMymcNumberLabels.messageSuccess}
                      </InformationPanel>
                    )}
                    {!!errors && (
                      <InformationPanel isComponentChildren withIcon errorMode>
                        <InfoMessages
                          isTextMode
                          messages={errors}
                          labels={infoLabels}
                        />
                      </InformationPanel>
                    )}
                  </Form>
                )}
              </Formik>
            )}
          </AddMymcNumberFormHandler>
        </>
      </div>
    </CSSTransition>
  );
};

AddMymcNumberForm.propTypes = propTypes;
AddMymcNumberForm.defaultProps = defaultProps;

export default AddMymcNumberForm;
