import { Component } from 'react';
import { string, func } from 'prop-types';
import { connect, getIn } from 'formik';

class ErrorMessageCustom extends Component {
  static propTypes = {
    name: string.isRequired,
    children: func.isRequired,
  };

  static defaultProps = {
    className: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      getIn(this.props.formik.values, this.props.name) !==
        getIn(nextProps.formik.values, this.props.name) ||
      getIn(this.props.formik.errors, this.props.name) !==
        getIn(nextProps.formik.errors, this.props.name) ||
      getIn(this.props.formik.touched, this.props.name) !==
        getIn(nextProps.formik.touched, this.props.name) ||
      Object.keys(this.props).length !== Object.keys(nextProps).length
    );
  }

  render() {
    let { formik, children, name } = this.props;

    const touch = getIn(formik.touched, name);
    const error = getIn(formik.errors, name);
    let value = getIn(formik.values, name);
    if (Array.isArray(value)) {
      value = value.find((item) => !!item);
    }

    const msg = (!!touch || !!value) && !!error ? error : null;

    return !!msg ? children({ msg }) : null;
  }
}

export default connect(ErrorMessageCustom);
