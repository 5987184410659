import React from 'react';
import { string, number } from 'prop-types';
import classNames from 'classnames/bind';

import { useUiContext } from 'common/context/UiContext';

import ProgressBarHandler from './containers/ProgressBarHandler';
import ProgressBarPoint from './components/ProgressBarPoint';
import PointSubLabel from './components/PointSubLabel';
import styles from './ProgressBar.module.scss';

const cx = classNames.bind(styles);

const propTypes = {
  className: string,
  myPoints: number.isRequired,
  currentStatus: number.isRequired,
  expirationDate: string.isRequired,
};

const defaultProps = {
  className: null,
};

const ProgressBar = ({
  className,
  myPoints,
  currentStatus,
  expirationDate,
}) => {
  const { isMobile } = useUiContext();

  return (
    <div className={cx('root', className)}>
      <ProgressBarHandler
        myPoints={myPoints}
        currentStatus={currentStatus}
        expirationDate={expirationDate}
      >
        {({
          percentStatusLine,
          cardsStatusList,
          pointsToReach,
          pointsStatus,
          loyaltyStatusComputed,
        }) => (
          <div className={cx('progress-bar-container')}>
            {!isMobile ? (
              <div className={cx('progress-bar-line-container')}>
                <div className={cx('progress-bar-line')}>
                  <div
                    className={cx('progress-bar-line-filled')}
                    style={{
                      width: `${percentStatusLine}%`,
                    }}
                  />
                </div>
                <div className={cx('progress-bar-points-container')}>
                  {cardsStatusList.map(
                    ({ status, loyaltyPoints, percent, width }) => (
                      <ProgressBarPoint
                        key={status}
                        status={status}
                        width={width}
                        percent={percent}
                        loyaltyPoints={loyaltyPoints}
                        active={status <= currentStatus}
                      >
                        <PointSubLabel
                          pointsStatus={pointsStatus}
                          status={status}
                          loyaltyStatusComputed={loyaltyStatusComputed}
                          currentStatus={currentStatus}
                          expirationDate={expirationDate}
                          pointsToReach={pointsToReach}
                        />
                      </ProgressBarPoint>
                    )
                  )}
                </div>
              </div>
            ) : (
              cardsStatusList.map(({ status }, index) => (
                <PointSubLabel
                  key={index}
                  pointsStatus={pointsStatus}
                  status={status}
                  currentStatus={currentStatus}
                  expirationDate={expirationDate}
                  pointsToReach={pointsToReach}
                />
              ))
            )}
          </div>
        )}
      </ProgressBarHandler>
    </div>
  );
};

ProgressBar.propTypes = propTypes;
ProgressBar.defaultProps = defaultProps;

export default ProgressBar;
