import { stringify } from 'query-string';

// to replace with backend data
const TRAVELCLICK_LANGUAGE_IDS = {
  en: '1',
  fr: '3',
  'zh-hans': '5',
  de: '7',
  it: '1', // ex. 8
  ru: '9',
};

const setTravelclickUrl = ({
  hotelId,
  offerType,
  travelclickId,
  travelclickPackageId,
  travelclickUrlToken,
  langId,
  adults,
  children,
  dateIn,
  dateOut,
  rooms,
}) => {
  return (
    `https://reservations.travelclick.com/${hotelId}?` +
    stringify(
      {
        identifier: travelclickUrlToken ? travelclickUrlToken : undefined,
        hotelid: hotelId,
        rateplanid: offerType.value === 'rate' ? travelclickId : undefined,
        prodid:
          offerType.value === 'package' ? travelclickPackageId : undefined,
        LanguageID: TRAVELCLICK_LANGUAGE_IDS[langId] || '1',
        adults,
        children,
        dateIn,
        dateOut,
        rooms,
      },
      { sort: false }
    )
  );
};

export default setTravelclickUrl;
