import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import { gql, useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';

import flatToNestedNSortedMenu from 'common/utils/flatToNestedNSortedMenu';
import Burger from 'common/components/Burger';
import { useOnClickOutside } from 'common/hooks';
import useEffectOnUpdate from 'common/hooks/useEffectOnUpdate';

import { useLocalisationContext } from '~/context/LocalisationContext';
import { useUiContext } from 'common/context/UiContext';
import { sbmClient } from '~/apolloClient';
import { withErrorBoundary } from '~/containers/ErrorBoundary';

import styles from './UniverseMenu.module.scss';

const { REACT_APP_SBM_DOMAIN } = process.env;

const cx = classNames.bind(styles);

const UniverseMenuDataQuery = gql`
  query UniverseMenuData {
    mainMenu: menu(name: main_menu) {
      menuItems {
        id
        title
        isLink
        url
        target
        parent
        weight
        description
      }
    }
  }
`;

const UniverseMenu = () => {
  const { language } = useLocalisationContext();
  const { isDesktop } = useUiContext();

  const [isMenuMobileOpen, setMenuMobileOpen] = useState(false);

  const toggleMenuMobileOpen = useCallback(() => {
    setMenuMobileOpen(!isMenuMobileOpen);
  }, [isMenuMobileOpen]);

  const handleClickOutside = useCallback(() => {
    if (isMenuMobileOpen) {
      return toggleMenuMobileOpen();
    }
  }, [isMenuMobileOpen, toggleMenuMobileOpen]);

  const userMenuList = useOnClickOutside(handleClickOutside);

  const { loading, data, refetch } = useQuery(UniverseMenuDataQuery, {
    fetchPolicy: 'cache-first',
    client: sbmClient,
  });

  useEffectOnUpdate(() => {
    refetch();
  }, [language]);

  if (loading)
    return (
      <div className={cx('root')}>
        <Burger
          className={cx('burger', 'loading')}
          isOpen={false}
          onClick={() => {}}
        />
      </div>
    );

  const universeMenuTitle = data?.mainMenu?.menuItems[0].title;
  const universeMenu = flatToNestedNSortedMenu(data?.mainMenu?.menuItems)[0];

  return (
    <div ref={userMenuList} className={cx('root')}>
      {isDesktop && universeMenuTitle ? (
        <button
          className={cx('universe-menu-trigger')}
          onClick={toggleMenuMobileOpen}
        >
          <span>{universeMenuTitle}</span>
        </button>
      ) : (
        <Burger
          className={cx('burger')}
          isOpen={isMenuMobileOpen}
          onClick={toggleMenuMobileOpen}
        />
      )}
      <CSSTransition
        in={isMenuMobileOpen}
        timeout={150}
        classNames={{
          enter: cx('transition-enter'),
          enterActive: cx('transition-enter-active'),
          enterDone: cx('transition-done-enter'),
          exit: cx('transition-exit'),
          exitActive: cx('transition-exit-active'),
        }}
        unmountOnExit
      >
        <div className={cx('mobile-menu-wrapper')}>
          {universeMenuTitle && (
            <button
              className={cx('universe-menu-trigger', 'inversed')}
              onClick={toggleMenuMobileOpen}
            >
              <span>{universeMenuTitle}</span>
            </button>
          )}
          <ul className={cx('menu-list')}>
            {universeMenu?.children?.map(({ id, title, isLink, url }, index) =>
              isLink && url.startsWith('/') ? (
                <li key={`${id}_${index}`}>
                  <a
                    href={`${REACT_APP_SBM_DOMAIN}${url}`}
                    className={cx('menu-item')}
                    target="_self"
                  >
                    <span>{title}</span>
                  </a>
                </li>
              ) : null
            )}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};

export default withErrorBoundary(UniverseMenu);
