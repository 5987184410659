import numberWithSpaces, { separator } from './numberWithSpaces';

/**
 Add number sufixes for big numbers.
 For example: 9999999 => 9 million or 9 999 thousand
**/
export default function numberWithSufix(number, sufixes) {
  const possibleSufixes = [
    'quadrillion',
    'trillion',
    'billion',
    'million',
    'thousand',
  ];
  const formatedNumber = numberWithSpaces(number).split(separator);

  const sufixIndex = possibleSufixes.findIndex((suf, i) => {
    return (
      sufixes[suf] && possibleSufixes.length - formatedNumber.length + 1 <= i
    );
  });

  if (sufixIndex < 0) {
    return formatedNumber.join(separator);
  }
  const sufixWording = sufixes[possibleSufixes[sufixIndex]];
  const endIndex = possibleSufixes.length - sufixIndex;
  return (
    formatedNumber.splice(0, formatedNumber.length - endIndex).join(separator) +
    sufixWording
  );
}
