import React from 'react';
import { oneOf, object, string } from 'prop-types';
import classNames from 'classnames/bind';

import IconContainer from 'common/components/IconContainer';
import Img from 'common/components/Img';
import { ReactComponent as IconMonogramme } from 'common/media/icons/monogramme.icon.svg';

import styles from './BrandLogo.module.scss';

const cx = classNames.bind(styles);

BrandLogo.propTypes = {
  logo: object,
  brandName: string,
  className: string,
  theme: oneOf(['round', 'rhomb']),
  path: string,
};

BrandLogo.defaultProps = {
  logo: IconMonogramme,
  brandName: 'Monte Carlo',
  theme: 'rhomb',
  className: null,
  path: null,
};

function BrandLogo({ logo, brandName, theme, className, path }) {
  return (
    <div className={cx('branding', theme, className)}>
      <span className="screen-reader">{brandName}</span>
      <div className={cx('branding-logo')}>
        {path ? (
          <Img src={path} alt="Site logo" />
        ) : (
          <IconContainer icon={logo} />
        )}
      </div>
    </div>
  );
}

export default BrandLogo;
